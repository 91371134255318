import './App.css';
import React from "react";
import DefaultContainer from "./components/parent-component/main-page/DefaultContainer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import GMS from "./components/parent-component/GMS";
import NoUIDefined from "./components/general-features/NoUIDefined";
import DashBoard from "./components/general-components/dashboard/DashBoard";
import {connect} from "react-redux";
import FaQ from "./components/general-components/help-and-support/FaQ";
import AgentRegistration
    from "./components/interfaces/component-input-fields/operator-declaration/operator-collector-sections/AgentRegistration";
import Login from "./components/interfaces/login/Login";

/**
 *
 * Main application
 */
class App extends GMS {

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({email: nextProps.email, role: nextProps.role});
    }

    /**
     *
     * Define all routes in the parent app then navigate amongst them from nested components_to_delete
     * @returns {JSX.Element} element containing all possible routes
     *
     */
    render = () => {
        return <div className="App">
            {/*usb trials*/}
            {/*<UsbTrials deviceHexId={'12d1:108a'}/>*/}
            {/*usb trials end*/}
            <BrowserRouter>
                <Routes>
                    {/*Add the asterisk in order to make a render possible*/}
                    <Route element={<DefaultContainer/>} path={'/*'}/>
                    <Route element={<Login/>} path={'representative/*'}/>
                    <Route element={<FaQ/>} path={'representative/frequently-asked-questions'}/>
                    <Route element={<AgentRegistration/>} path={'representative/self-declaration'}/>
                    <Route element={<DashBoard/>} path={'dashboard/*'}/>
                    <Route element={<NoUIDefined/>} path={'nothing'}/>
                </Routes>
                {this.state.currentRoute}
            </BrowserRouter>
        </div>;
    }
}

const mapStateToProps = localStorageState => {
    let p = {};
    const itemsOfInterest = ['email', 'role'];
    let x = 0;
    do {
        p[itemsOfInterest[x]] = localStorageState ? localStorageState[itemsOfInterest[x]] : null;
        x += 1;
    }
    while (x < itemsOfInterest.length)
    return p;
}
export default connect(mapStateToProps)(App)

