import GMS from "../../../../../parent-component/GMS";
import {SectionColumn, SectionRow, SectionTitle} from "../../../../../rotm-custom-views/view-utils/SectionUtils";
import {arrayOfJSONsToJSON, notify} from "../../../../../../MiscUtils";
import {Component} from "react";
import {connect} from "react-redux";
import DynamicTable from "../../../../../general-components/tables/DynamicTable";
import SingletonRow from "../../../../../general-components/singleton-row/SingletonRow";
import CheckBoxField from "../../../../../general-components/input-field/CheckBoxField";
import {Dispatch} from "../../../../../general-components/redux/app-storage";
import {SUBTITLE} from "../../../../../general-components/redux/allowed-actions";
import SubmitButton from "../../../../../general-components/controls/buttons/SubmitButton";

/**
 *
 * A row that is selectable via clicking
 *
 */
class SelectableEmployeeRow extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {

    }

    render = () => {
        return <div className={'selectable-row'}>
            // items here
        </div>
    }
}

export class EmployeesAtPremiseFields extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'Select and assign employees to this premise'});
        this.sendRequest('get', 'company/operator/employees', employeeList => {
            if (employeeList.type === 'Success') {
                if (employeeList.data.length > 0) {
                    let t = 0, v = 0;
                    let y = [], z = [];
                    const shop_premise_employees_size = this.props.shop_premise_employees ?
                        this.props.shop_premise_employees.length : 0;
                    // console.log(gender)
                    do {
                        let a = {};
                        let u = 0;
                        a['id'] = employeeList.data[t]['id'];
                        a['employee_name'] = `${employeeList.data[t]['e_first_name']} ${employeeList.data[t]['e_middle_name']} ${employeeList.data[t]['e_surname']}`;
                        a['e_first_name'] = employeeList.data[t]['e_first_name'];
                        a['e_middle_name'] = employeeList.data[t]['e_middle_name'];
                        a['e_surname'] = employeeList.data[t]['e_surname'];
                        a['gender'] = employeeList.data[t]['gender'];
                        a['e_email'] = employeeList.data[t]['e_email'];
                        a['e_postal_address'] = employeeList.data[t]['e_postal_address'];
                        a['e_physical_address'] = employeeList.data[t]['e_physical_address'];
                        a['phone_number'] = employeeList.data[t]['e_phone_number'];
                        a['e_nationality'] = this.props.country[employeeList.data[t]['e_nationality']];
                        a['e_national_id'] = employeeList.data[t]['e_national_id'];
                        a['e_pin_number'] = employeeList.data[t]['e_pin_number'];
                        // a['declaration date'] = employeeList.data['date_created'];
                        // a['is_contact_person'] = employeeList.data['is_contact_person'];
                        a['contact_person'] = employeeList.data[t]['is_contact_person'];
                        // ? 'Yes' : 'No';
                        y.push(a);
                        if (shop_premise_employees_size > 0)
                            do {
                                // if the stored element is exactly the same as this one right now.
                                if (this.props.shop_premise_employees[u].e_email === y[t]['e_email']) {
                                    z.push(this.showSelectedEmployeeRow(this.props.shop_premise_employees[u], v));
                                    v += 1;
                                }
                                u += 1;
                            } while (u < shop_premise_employees_size)
                        t += 1;
                    }
                    while (t < employeeList.data.length) ;
                    // console.log(z)
                    this.setState({
                        dataItem1: [...y],
                        dataItem2: [...z]
                    });
                    this.props.shop_premise_employees && this.setState({
                        dataItem3: [...this.props.shop_premise_employees]
                    });
                }
            } else notify(<>
                No list of employees found/uploaded!
                <br/>
                <br/>
                Declare employees using the "Employees" menu to the left of this page.
                <br/>
            </>, 2, false, 'List of employees is absent');
        }, {})
    }
    updateSelectedShopEmployees = (employeeObject, belongingTo = 0) => {
        let employeeField = {}; //this is just a pre-construction for new elements within the array
        // check if the current field name exists in state e operator_shareholders array elements for the belongingTo attribute
        if (employeeObject === true || employeeObject === false) // this can only be a checkbox
            employeeField['is_contact_person'] = employeeObject;
        else if (employeeObject.constructor.name === {}.constructor.name) {
            employeeField = {...employeeObject, 'is_contact_person': false}
        }
        let emP = [];
        if (this.state.e["shop_premise_employees"]) {
            emP = this.state.e["shop_premise_employees"];
            try {
                // emP[belongingTo]['email'] = employeeObject['email'];
                emP[belongingTo] = {...emP[belongingTo], ...employeeField};
            } catch (newRecordCountFound) {
                emP.push(employeeField);
            }
        } else emP.push(employeeField);
        // update state then sort out the classes of shareholders
        // check the shareholder type. If this field is in the state.e object, update it else create one.
        // check if this is the first shareholder director
        // this is the first one
        this.setState(state => {
            state.e["shop_premise_employees"] = [...emP];
            return state;
        });
        return true;
    };
    /**
     *
     * Show selected employee row by constructing a singleton row (call the superclass's SingletonRow component with the necessary arguments
     *
     * @param employee
     * @param belongingTo
     * @returns {JSX.Element}
     */
    showSelectedEmployeeRow = (employee, belongingTo) => {
        return <SingletonRow
            itemIsObject
            item={
                <span>{employee.employee_name} <CheckBoxField
                    fontSize={12}
                    placeholder={'Mark as contact person'}
                    name={'is_contact_person'}
                    changeCallback={(e) => {
                        this.updateSelectedShopEmployees(e.target.checked, belongingTo);
                    }}/>
                </span>
            }
            callback={() => {
                // get list of all employees in dataItem3
                // for removal.
                let findEmployeeIndex = f => f === employee;
                let k = this.state.dataItem3.findIndex(findEmployeeIndex);
                let y = []; // remove the employee at that index
                let q = []; // empty array to host the new list
                let idx = 0;
                // reconstruct array of singletons
                this.state.dataItem3.map((emp, index) => {
                    console.log('employee "emp" who is currently being iterated ', emp, 'employee "employee" to remove ', employee)
                    if (emp.e_email !== employee.e_email) {
                        y.push(emp);
                        q.push(this.showSelectedEmployeeRow(emp, idx));
                        idx += 1;
                    }
                    // console.log('mapper email ',emp.e_email, ' employee email ',employee.e_email, ' not equal ? ', emp.e_email !== employee.e_email, y)
                });
                // a stupid hack in order to allow the list to reset
                const p = [];
                // console.log('actual employees ',y, 'singleton rows ',q)
                this.setState({
                    dataItem3: [...y], // actual employee data
                    dataItem2: [...p]
                }, () => {
                    this.setState(state => {
                        state.e['shop_premise_employees'] = [...this.state.dataItem3];
                        state.dataItem2 = [...q] // employee SingletonRow components
                        return state;
                    });
                });
            }}/>

    }
    render = () => {
        return <SectionRow>
            <SectionColumn>
                <SectionRow>
                    <SectionColumn boxedColour>
                        <SectionRow>
                            <SectionTitle>
                                List of available employees
                            </SectionTitle>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <ul>
                                    <li>
                                        <span>
                                            This is a list of all <b>employees not currently assigned to a premise or shop</b>.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Click on the desired employee to select them, from the table below.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                             That selection will appear in a separate table to the right of this window.
                                        </span>
                                    </li>
                                </ul>
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <DynamicTable
                                    noParaphrase
                                    context={'employees'}
                                    pageSize={12}
                                    columnsOfInterest={['employee_name', 'phone_number']}
                                    tableData={this.state.dataItem1}
                                    showRowData={employee => {
                                        // take the data and append it to the next object dataItem3
                                        // make sure no duplicate additions.
                                        let belongingTo = this.state.e['shop_premise_employees'] === undefined ?
                                            0 : this.state.e['shop_premise_employees'].length;
                                        this.updateSelectedShopEmployees(employee, belongingTo);
                                        if (!this.state.dataItem3.includes(employee))
                                            this.setState({dataItem3: [...this.state.dataItem3, employee]}, () => {
                                                // prepareSingletonRow
                                                // get the index of the most recent employee in dataItem2
                                                console.log(employee, this.state.dataItem3)
                                                this.setState({
                                                    dataItem2: [...this.state.dataItem2, this.showSelectedEmployeeRow(employee, belongingTo)]
                                                })
                                            });
                                        else
                                            notify(`This employee ${employee.employee_name} is already selected!`, 3, true);
                                        // copy over to the space to the right
                                    }}
                                />
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                    {
                        this.state.dataItem2.length > 0 &&
                        <SectionColumn boxedColour>
                            <SectionRow>
                                <SectionTitle>
                                    {this.state.dataItem2.length > 1 ? `${this.state.dataItem2.length} employees` : `${this.state.dataItem2.length} employee`}
                                    &nbsp;to assign to this premise:
                                </SectionTitle>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <span>
                                        The employees selected from the table on the left,
                                        are personnel whom you intend to associate with this premise
                                        upon its declaration.
                                    </span>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow boxedColour style={{color: 'red'}}>
                                <SectionColumn>
                                    <span>
                                        If the selections within this list need changing, click the <b>X</b> tool of the concerned entries, or
                                    </span>
                                </SectionColumn>
                                {/*// facilitate clearing of employee list*/}
                                <SectionColumn>
                                    <SubmitButton
                                        commandText={'Clear this list'}
                                        callback={() => {
                                            const p = [];
                                            this.setState({dataItem3: [...p], dataItem2: [...p]},() => {
                                                this.setState(state => {
                                                    state.e['shop_premise_employees'] = [...p];
                                                    return state;
                                                })
                                            });
                                        }}/>

                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    {this.state.dataItem2}
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    }
                </SectionRow>
                {/*<SectionRow boxedColour>*/}
                {/*    <SectionColumn>*/}
                {/*        /!*<SectionRow>*!/*/}
                {/*        /!*    <SectionColumn/>*!/*/}
                {/*        /!*    <SectionColumn>*!/*/}
                {/*        /!*        <SubmitButton commandText={this.state.visible ? 'Clear' : 'Declare employees'}*!/*/}
                {/*        /!*                      callback={e => {*!/*/}
                {/*        /!*                          // allow declaration of employees in line*!/*/}
                {/*        /!*                          this.setState({visible: !this.state.visible});*!/*/}
                {/*        /!*                      }}/>*!/*/}
                {/*        /!*    </SectionColumn>*!/*/}
                {/*        /!*    <SectionColumn/>*!/*/}
                {/*        /!*</SectionRow>*!/*/}
                {/*        /!*{this.state.visible && *!/*/}
                {/*        <SectionRow>*/}
                {/*            <SectionColumn>*/}
                {/*                <OperatorEmployeeSection dataFieldName={'shop_premise_employees'}*/}
                {/*                                         applicable={this.state.variable}*/}
                {/*                                         setMandatoryFields={[]}/>*/}
                {/*            </SectionColumn>*/}
                {/*        </SectionRow>*/}
                {/*        /!*}*!/*/}
                {/*    </SectionColumn>*/}
                {/*</SectionRow>*/}
            </SectionColumn>
        </SectionRow>
    }
}

const mapStateToProps = (reduxState) => {
    let data = {}
    if (reduxState.LOCAL_BACKUP)
        // switch the key-value pairs of nationality in order for easier lookup
        data = {
            ...reduxState.LOCAL_BACKUP,
            gender: reduxState.gender,
            country: arrayOfJSONsToJSON(reduxState.country, true)
        };
    return data;
}
export default connect(mapStateToProps)(EmployeesAtPremiseFields);