/**
 * a data reducer for use in whatever is required here regarding persistance
 * and access to local storage.
 * @param state current state
 * @param action the action to perform on the given state.
 * @returns {*} anything
 */
import {
    APPLICATION_STATUS,
    APPLICATION_TYPE,
    AUTH,
    AUTH_UPD,
    BANKS,
    BCLB,
    BLUR_FIELDS_FOR,
    BRANCHES,
    CLEAR_RECORD_TO_TASK,
    COMPANY_POSITIONS,
    COUNTRY,
    COUNTS,
    COUNTY,
    CURRENT_NOTIFICATION,
    DONOTRENEW,
    FEE_PAYMENT_STATUS,
    FEE_TYPE,
    FEES,
    GAME,
    GAME_CAUSE,
    GAME_PRIZES,
    GAMES_PRIZE_CATEGORY,
    GENDER,
    HARDWAREDEVICES,
    LETTER,
    LICENSE_PERMITS,
    LOCAL_BACKUP,
    LOGIN,
    LOGOUT,
    MACHINE,
    MESSAGES,
    MISC_ID,
    NOTIFICATIONS,
    NULL_BACKUP,
    NULL_CURRENT_NOTIFICATION,
    OPERATOR,
    PAYFOR,
    PAYMENT_REASONS,
    PAYMENTS,
    PERSONS,
    PREV_ROUTE,
    REASON_FOR_LICENSE,
    RECORD_TO_TASK,
    RELOAD,
    ROUTEINDEX,
    SECTIONS,
    SELECTION_FORMULAE,
    SHAREHOLDER_TYPE,
    SOFTWAREDEVICES,
    SUBTITLE,
    TASK_OBJECT,
    TITLE,
    URL,
    VIEWABLE_ITEM
} from "./allowed-actions";
import {getStorageObject} from "../../../MiscUtils";
/*
  this initial state is meant for the operator-registration.
  their whereabouts are unknown at this time: therefore,
  record their previous county, business name,
  agent email address, operator-registration - the operator-registration they represent, operatorStatus - the status of operator-registration at the time
  fields of the form they were currently filling in at that time
 */
export let initialState = {
    email: null,
    LOCAL_BACKUP: {},
    role: null,
    device: 'desktop' // meant for desktop
    // empty initial state
};
/**
 *
 * Encrypter value extractor using the session cookie
 *
 */
let eK = '';
try {
    let c = document.cookie.split(';');
    let e = 0;
    do {
        const p = c[e].split('=')
        if ('jwt' === p[1]) {
            // this is what is being looked for
            eK = p[1];
        }
        e += 1;
    } while (e < c.length);
} catch (noDataFound) {
    // no encrypt
}
/**
 *
 * dataReducer for reducing. Perhaps look into using spreaders
 * In this reducer, in order to update data, first read the store then parse
 * the json content, then update the desired fields and later commit the changes
 * back to the store.
 * @param state state to write into local storage
 * @param action the action to do or update.
 * @returns {{}} the state that has been updated
 *
 */

export const bclbReducer = (state = initialState, action) => {
    let b;
    //check whether the state is set for backup
    switch (action.type) {
        case DONOTRENEW:
            b = {...state,'DO_NOT_RENEW':action.payload}
            break;
        case ROUTEINDEX:
            b = {...state, 'route_index': action.payload};
            break;
        case HARDWAREDEVICES:
            b = {...state, 'hardware_devices': action.payload};

            break;
        case SOFTWAREDEVICES:
            b = {...state, 'software_devices': action.payload};
            break;
        case VIEWABLE_ITEM:
            b = {...state, 'viewable_item': action.payload};
            break;
        case TITLE:
            b = {...state, title: action.payload}
            break;
        case SUBTITLE:
            b = {...state, sub_title: action.payload}
            break;
        case PAYFOR:
            b = {...state, pay_for: action.payload}
            break;
        case MISC_ID:
            b = {...state, misc_id: action.payload}
            break;
        case URL:
            b = {...state, url: action.payload}
            break;
        case PAYMENTS:
            b = {...state, payments: action.payload};
            break;
        case PAYMENT_REASONS:
            b = {...state, payment_reasons: action.payload};
            break;
        case BLUR_FIELDS_FOR:
            b = {...state, blur_fields: action.payload}
            break;
        case LETTER:
            b = {...state, letter: action.payload}
            break;
        // record various types of fees
        case FEE_TYPE:
            b = {...state, fee_type: action.payload};
            break;
        case FEES:
            b = {...state, fees: action.payload};
            break;
        case PREV_ROUTE:
            b = {...state, previous_route: action.payload}
            break;
        case FEE_PAYMENT_STATUS:
            b = {...state, fee_payment_status: action.payload};
            break;
        case LOGOUT:
            b = {...initialState}; //empty store
            break;
        // keep track of various counts as recorded by the user in redux
        case COUNTS:
            if (action.payload.constructor !== {}.constructor)
                throw new TypeError(`Reducer error in dataReducer method: expected {} but found ${action.payload.constructor.name}`);
            // go through and find out if there are counts registered in REDUX
            let counts = {};
            if (Object.getOwnPropertyNames(state).includes('counts')) {
                // iterate through them and transfer over the contents into the counts list
                counts = {...state.counts, ...action.payload};
            } else {
                counts = {...action.payload};// copy over directly because all payloads must be JSON objects
            }
            b = {...state, counts}; //{...state, counts: action.payload} // the payload field name is given by the user
            break;
        // this is used in reloading a item or page
        case RELOAD:
            // if removal is necessary, remove it else simply update it.
            // Note: removal requires an action of false
            if (action === false) {
                let k = state;
                delete k['reload'];
                b = {...k}
            } else
                b = {...state, 'reload': true}
            break;

        case LOGIN:
            //record email address and password into redux
            b = {
                ...state,
                section_head: action.payload.section_head,
                role: action.payload.role,
                email: action.payload.email,
                user_id: action.payload.user_id
            };
            break;
        case AUTH:
            b = {
                ...state,
                role: action.payload.role,
                email: action.payload.email,
                auth: action.payload.auth,
                access: action.payload.access
            }
            break;
        /*The payload items below are named after the endpoints that they are extracted from. Keep Note!*/
        //this case is deleting LOCAL_BACKUP
        case COUNTRY:
            b = {...state, country: action.payload};
            break;
        case COUNTY:
            b = {...state, county: action.payload};
            break;
        case GENDER:
            b = {...state, gender: action.payload};
            break;
        case MACHINE:
            b = {...state, machines: action.payload};
            break;
        case GAME:
            b = {...state, game: action.payload};
            break;
        /*This must be used with plenty of care in order to not abuse the operators present in the system*/
        case OPERATOR:
            b = {...state, operator: action.payload};
            break;
        case BRANCHES:
            b = {...state, branches: action.payload};
            break;
        case BANKS:
            b = {...state, banks: action.payload};
            break;
        case SECTIONS:
            b = {...state, sections: action.payload};
            break;
        case PERSONS:
            b = {...state, persons: action.payload};
            break;
        case REASON_FOR_LICENSE:
            b = {...state, run_reason: action.payload}
            break;
        case SELECTION_FORMULAE:
            b = {...state, selection_formulae: action.payload};
            break;
        case GAME_PRIZES:
            b = {...state, prize: action.payload};//check with what has been used as the endpoint
            break;
        case GAME_CAUSE:
            b = {...state, game_cause: action.payload};
            break;
        case APPLICATION_TYPE:
            b = {...state, application_type: action.payload};
            break;
        case GAMES_PRIZE_CATEGORY:
            b = {...state, prize_category: action.payload};
            break;
        case SHAREHOLDER_TYPE:
            b = {...state, shareholder_type: action.payload};//to be changed later
            break;
        /*END endpoint naming convention*/
        case LICENSE_PERMITS:
            b = {...state, licenses: action.payload}
            break;
        case COMPANY_POSITIONS:
            b = {...state, company_positions: action.payload}//to be changed
            break;
        case AUTH_UPD:
            b = {
                ...state,
                role: action.payload.role,
                email: action.payload.email,
                auth: action.payload.auth,
                access: action.payload.access
            }
            break;
        case TASK_OBJECT:
            b = {...state, task_object: action.payload}
            break;
        case RECORD_TO_TASK:
            b = {...state, record_id: action.payload}
            break;
        case CLEAR_RECORD_TO_TASK:
            delete state['record_id'];
            delete state['task_object'];
            b = {...state}
            break;
        case APPLICATION_STATUS:
            b = {...state, application_status: action.payload};
            break;
        case MESSAGES:
            break;
        case LOCAL_BACKUP:
            if (Object.getOwnPropertyNames(action.payload).length === 0)
                b = {...state, LOCAL_BACKUP: action.payload}
            else {
                //get the storage
                let localData = getStorageObject(BCLB)
                let payloadKeys = Object.getOwnPropertyNames(action.payload);
                let payload = {}
                if (localData !== undefined) {
                    //do a json out of it and look for storage
                    let localBackup = localData[LOCAL_BACKUP];
                    //check if incoming data has been written into storage
                    if (localBackup !== undefined) {
                        // const localBackupKeys = Object.getOwnPropertyNames(localBackup);
                        //there's local storage
                        //go through it and find out whether the current payload key is inside the local backup
                        // does one need to loop through the keys to find the component that needs to get
                        // changed? YES! otherwise local-backup gets overwritten
                        for (const key of payloadKeys) {
                            // if (localBackupKeys.includes(key))
                            localBackup[key] = action.payload[key];
                        }
                        payload = {...state, LOCAL_BACKUP: localBackup};
                        //console.log('there is storage: ', payload);
                    } else {
                        //in thi case, localBackup is undefined
                        //create a json object of the incoming data
                        payload = {
                            ...state, LOCAL_BACKUP: action.payload
                        };
                        //console.log('there is no local storage: beginning afresh', payload);
                    }
                    b = {...state, ...payload};
                }
            }

            break;
        case NULL_BACKUP:
            //console.log('null backup')
            const keys = Object.getOwnPropertyNames(state.LOCAL_BACKUP)
            let k = 0;
            do {
                delete state.LOCAL_BACKUP[keys[k]];
                k += 1;
            } while (k < keys.length);
            //console.log('state ', state);
            b = {...state} //Do not assign: delete will return what it removed. find out if this is true
            break;
        case NOTIFICATIONS:
            b = {...state, notifications: action.payload};
            break;
        case CURRENT_NOTIFICATION:
            b = {...state, current_notification: action.payload};
            break;
        case NULL_CURRENT_NOTIFICATION:
            delete state['current_notification'];
            b = {...state}
            break;
        default:
            b = initialState;
            break;
    }
    //write to local storage
    //check whether there's a backup key in the local storage
    //check whether there exists a previous record
    //console.log('setting item to storage: ', b)
    // encrypt data here using the jwt then use it to encrypt user data
    localStorage.setItem(BCLB, JSON.stringify(b));
    return b;
}