import React, {Component} from "react";
import {
    col1,
    col11,
    col12,
    col3,
    col9,
    container,
    fullWindowInterface,
    nameFromVariableName,
    paraphrase,
    readFromLocalStorage,
    row
} from "../../../MiscUtils";
import "./table.css";

import Paginator from "./Paginator";
import ContextMenu from "../../rotm-custom-views/context/ContextMenu";
import DocumentPrinter from "../document-viewer/DocumentPrinter";
import {BCLB} from "../redux/allowed-actions";
import TextField from "../input-field/TextField";
import SelectField from "../input-field/SelectField";

/**
 *
 * Creates a table and pre-populates it with data from a given url, or from data passed to it through a property.
 * List of properties:
 *      enablePrint - show the print icon on the table
 *      callback - a callback method or reference that is executed when a row is clicked.
 *      updateTitleContext - a callback method or reference that executed when the component loads and adds
 *                          context-variables information to its host component.
 *
 *      rowsOfData - <Optional> a property consisting of an array of JSON objects representing row-data.
 *
 *      pageSize  - the size of pages in terms of record count. This is passed directly to the paginator which
 *                  wields control over how the table behaves in terms of records display
 *
 */
export default class DynamicTable extends Component {
    constructor(props) {
        super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
        this.removeFloatWindow = props.removeFloatWindow; //this is a method reference to remove the floatWindow component reference. It nullifies the state
        //variable responsible for blocking the workarea
        //other components_to_delete
        this.callback =
            props.callback === undefined
                ? () => {
                    console.warn(
                        `<${this.constructor.name} /> has not been issued with a row-callback property 
                            called 'callback'! this row has nothing to do!`
                    );
                }
                : props.callback;
        //set title context-variables manager
        this.updateTitleContext =
            props.updateTitleContext === undefined
                ? () => {
                    console.warn(`No title manager is set for <${this.constructor.name} />. 
                          Pass a 'titleManager' property when calling this component.`);
                }
                : props.updateTitleContext;

        this.titleContext =
            props.titleContext === undefined ? null : props.titleContext;
        this.state = {
            dataItem1: [],

            // state is by default an object
            destructuringColumnNames: null,
            tableColumnNames: [], //column names
            search: [],
            //
            // table consisting of data
            // this data is a array of JSON objects
            // with each object representing a row.
            //
            rowsOfData:
                props.tableData === undefined || props.tableData.length === 0
                    ? []
                    : props.tableData,
            columns: [],
            searchInput: "",
            tableRows: null,
        };
        this.role = readFromLocalStorage('role', BCLB);
        this.section_head = readFromLocalStorage('section_head', BCLB);
    }

    /**
     *
     * Searches for a given term and returns a list of all terms with such a regular expression.
     *
     * @param data array of JSON objects to search within
     * @param searchTerm The regular expression to form the search operation with
     * @returns {*[]} The list of items that satisfy the searchTerm
     *
     */
    search = (data, searchTerm) => {
        if(searchTerm.length === 0)
            return;
        const searcher = new RegExp(`${searchTerm}*`); // looks like the regexp works
        let items = [];
        /**
         * Internal function to test a given object for values
         * @param object
         * @returns {boolean}
         */
        const searchInObject = (object) => {
            let isPresent = false;
            Object.values(object).forEach(value => {
                if (typeof value === 'string') {
                    // console.log('searcher ', searcher, ' value ', value, ' test ', searcher.test(value))
                    if (searcher.test(value.toLowerCase()) || searcher.test(value) || searcher.test(value.toUpperCase())) {
                        isPresent = true;
                        return;
                    }
                }
            });
            // console.log(isPresent)
            return isPresent;
        }
        if (data.constructor.name === {}.constructor.name) {
            if (searchInObject(data))
                items.push(data);
        } else if (data.constructor.name === [].constructor.name) {
            data.filter((datum) => {
                if (searchInObject(datum))
                    items.push(datum);
            });
        }
        // console.log('search item => ', searchTerm, ' data -> ', items);
        return items;
    }

    /**
     *
     * enable refresh of table data as required
     * @param nextProps
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.tableData) {
            this.setState({rowsOfData: nextProps.tableData}, () => {
                // the rest of the default arguments already set for this method call
                this.createTableRow(this.state.rowsOfData, true);
            });
        }
    }

    /**
     *
     * Fetch data from the server's endpoint as noted in the 'this.withDataFrom' variable.
     *
     */
    componentDidMount = () => {

        this.createTableRow(this.state.rowsOfData, true);
    };

    /**
     *
     * Creates rows of data based on the last argument columnsOfInterest which
     * is an array of columns to view,  and their callbacks;
     * from data fetched from server.
     *
     * That data looks like:
     *  [{columnName: <String>,anotherColumnName:<String>,...},...]
     *   eg, if a licensor wishes to see a report on inspection, they will see a
     *        1. date of inspection
     *        2. operator-registration who was inspected
     *        3. county of inspection
     *        4. premise of inspection
     *        5. who conducted the inspection (an individual or committee name)
     *        6. findings
     *        7. recommendations (might include ceo's recommendations as well)
     * The items above would be represented as follows:
     * [
     *  {
     *    'inspection date': <Date-string>,
     *    'operator-registration': <Operator-name>,
     *    'county': <county name>,
     *    'inspection-team': <inspection-team-name>,
     *    'findings': <findings-list>,
     *    'recommendations': <recommendations-list>,...
     *  },
     *  ...
     * ]
     *
     * @param {*} rowData array of jsons representing the desired data as illustrated in the example above.
     *
     * @param header a boolean telling the method what sort of row it's creating. Header or not header
     *              (the latter being the default) - by default, its set to false.
     *
     * @param callback the method to execute on clicking a given row. The row will implicitly pass the data
     *                  associated with it.
     *
     * @param columnsOfInterest an array of columns whose data should be viewed in the table. Note: the
     *                      original data remains untempered-with. If an empty array (default state) is passed,
     *                      all columns will be shown
     *
     * Note: all methods that implement an argument list with default values can be called with any configuration
     * of arguments. Just make sure that when calling this method with arguments such as:
     *      1. it's not a header but it has a callback (in this case, the scenario of having a row of data inside
     *      the table), make sure that even the argument with default values is called with the default value (if
     *      not up for change)
     *
     * example:
     *      create a row that has a callback but no specific arguments:
     *          NOTES on this - row creates data out of something
     *                        - row is not a header row
     *                        - row has a callback method
     *                        - row has no specific items to give preference when viewing
     *     The method signature/definition looks like this by default
     *          <myDesiredMethodName>(rowData = my_default_source_of_data, header = false, callback = () => {}, columnsOfInterest = [])
     *
     *     With the task as described above, call it as follows:
     *          <myDesiredMethodName>(rowData = <my_source>, false, <my_method_reference>);
     *     Note that with the above call, the last argument is left out. So long as the non-desired argument
     *     is ahead of the intended arguments in the declaration, it can be left out.
     *
     *
     * find a way for limiting the number of pages that are shown
     */
    createTableRow = (
        rowData = this.state.rowsOfData,
        header = false,
        callback = (tableRowData) => {
            console.log("No callback passed to this row. Nothing to do when clicked");
        },
        columnsOfInterest = this.props.columnsOfInterest === undefined ? [] :
            this.props.columnsOfInterest
    ) => {

        // do a check whether rowData is empty in chich case
        if (rowData.length === 0) {
            // window.alert('no data to show')
            // console.log('@DynamicTable, data from server (rowData) does not exist');
            return;
        }
        let rows = [];
        const columnNames = Object.getOwnPropertyNames(rowData[0]);
        // array of json keys which are then used to
        // extract columns from the data returned from the server.
        // Just use the first entry of the array
        if (header) {
            // form the first row of column titles
            let rowsOfDataColumnHeaders = [];
            //iterate through the rows
            //seems repetition but this logic works better than a fancy one at this time...
            columnsOfInterest.map((name, index) => {
                if (columnNames.includes(name)) {
                    // allow an event listener for appending a drop-down upon click
                    const td = <td onClick={() => {
                        window.alert(name)
                        this.setState({
                            dataItem2: <SelectField name={`column_selection_${name}`}
                                                    placeholder={''}
                                                    changeCallback={() => {
                                                    }}/>
                        })
                    }}>
                        {this.state.dataItem2 && this.state.dataItem2}
                        {nameFromVariableName(name)}
                    </td>
                    rowsOfDataColumnHeaders.push(td);
                }
            });
            //record them in state
            this.setState({tableColumnNames: rowsOfDataColumnHeaders});
        } else {
            //iterate through all rows in the rowsOfData and create rows out of them
            for (const row of rowData) {
                //
                // In the current row, pick the columns in order that's stated in
                // the array of columnsOfInterest
                //
                let rowItem = [];
                let t = 0;
                do {
                    //
                    rowItem.push(<td>
                        {
                            String(row[columnsOfInterest[t]]).length > 0 ?
                                this.props['noParaphrase'] ? row[columnsOfInterest[t]] :
                                    paraphrase(row[columnsOfInterest[t]], this.props.paraphrase ?
                                        this.props.paraphrase : 30) :
                                '--'
                        }
                    </td>);
                    t += 1;
                } while (t < columnsOfInterest.length)
                rows.push(
                    <tr
                        onClick={() => {
                            if (this.props.showRowData)
                                callback(row);
                        }}
                    >
                        {rowItem}
                    </tr>
                );
            }
        }
        // nullify the previous rows and proceed to show the next ones
        if (!header)
            this.setState({tableRows: [...rows]});
        return rows.length > 0 ? rows : null;
    };

    /**
     *
     * Generate printable content
     *
     * @param heightInVh
     * @param forPrint
     * @param fullTableContent
     * @returns {JSX.Element}
     *
     */
    generatePrintableContent = (heightInVh, forPrint, fullTableContent = false) => {
        const k = forPrint && heightInVh === null ? {height: heightInVh} : heightInVh === null ? {
            height: '65vh',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
        } : {height: heightInVh};
        return <div className={row}>
            <div className={col12}>

                <table className={"table"} style={{border: 'none'}}>

                    <tr className={"table-row-header tr-no-hover"}>
                        {this.state.tableColumnNames}
                    </tr>
                    <tbody style={{overflowY: 'scroll'}}>
                    {
                        fullTableContent ?
                            this.createTableRow(this.props.tableData, false) :
                            this.state.tableRows
                    }
                    {/*// do pagination here*/}
                    </tbody>
                </table>
            </div>
            {
                fullTableContent ? null : <div className={col12}>
                    <Paginator  // this is meant to allow pagination
                        pageSize={this.props.pageSize ? this.props.pageSize : 8}
                        recordsList={this.state.dataItem1.length > 0 ? this.state.dataItem1 : this.props.tableData}
                        executeOnRowPopulation={(rowsOfData) => {
                            this.createTableRow(rowsOfData, false, (dataToShow) => {
                                // pass a method that accepts the data in question, showing
                                // the table data
                                // If using routes, the callback method (showRowData)
                                // is a method that navigates to the url in question
                                this.props.showRowData(dataToShow);
                            })

                        }}/>
                </div>
            }
        </div>;
    }
    render = () => {
        return (
            <div className={container}
                 style={{
                     border: '1px solid #cfcfd5', padding: 12,
                     borderRadius: 4,
                     background: "#FFFFFF",
                     width: '100%'
                 }}>
                {
                    this.state.rowsOfData.length > 0 ? <>
                        <div className={row}>
                            <div className={col11}/>
                            <div className={col1}>
                                {
                                    this.role !== 4 && this.section_head && !this.props.noPrint &&
                                    <ContextMenu print={() => {
                                        const currentLocation = window.location;
                                        fullWindowInterface(<DocumentPrinter
                                            note={`${this.props.tableData.length} entries of ${this.props.context}  listed.`}
                                            noSignature
                                            content={this.generatePrintableContent(null, false, true)}/>, 'Tabulated data', () => {
                                            window.location = currentLocation;
                                        });
                                    }}/>
                                }
                            </div>
                        </div>
                        &nbsp;
                        <div className={row}>
                            <div className={col9}/>
                            <div className={col3}>
                                {!this.props.noSearch &&
                                <TextField name={'searchTerm'} bold
                                           placeholder={'Search'}
                                           changeCallback={e => {
                                               const rows = this.search(this.state.rowsOfData, e.target.value);
                                               this.createTableRow(rows, false, (dataToShow) => {
                                                   this.setState({dataItem1: [...rows]});
                                                   // pass a method that accepts the data in question, showing
                                                   // the table data
                                                   // If using routes, the callback method (showRowData)
                                                   // is a method that navigates to the url in question
                                                   this.props.showRowData(dataToShow);
                                               }, this.props.columnsOfInterest)
                                           }}/>}
                            </div>
                        </div>
                        {this.generatePrintableContent(null)}
                    </> : <>
                        <div className={row}>
                            <div className={col12} style={{
                                fontFamily: 'monospace',
                                fontSize: 28,
                                fontWeight: 800,
                                color: '#c5c6d5',
                                textAlign: 'center'
                            }}>
                                No data found!
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}
