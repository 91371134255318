/*an avatar class*/

import GMS from "../../parent-component/GMS";
import React, {Component} from "react";
import someimage from "../../general-components/BCLB_icons/nature-test-image.jpeg";
import ImageViewer from "../../general-components/image-provider/ImageComponent";
import FileSelectFieldIcon from "../../general-components/input-field/FileSelectFieldIcon";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";

export default class Avatar extends GMS {
    constructor(props) {
        super(props);
        this.fileSelectFieldReference = React.createRef();
        this.state = {
            newAvatar: null,
            avatarFileBase64: null,
        };
        this.avatar = props.avatar === undefined ? someimage : props.avatar;
    }

    /**
     *
     * Show avatar file in imageview with the currently selected file.
     * @param fileContent the file content to be rendered and admitted
     * for transfer to server.
     *
     */
    upgradeAvatar = (fileContent) => {
        //console.log(fileContent)
        this.setState((state) => {
            state.newAvatar = null;
            state.avatarFileBase64 = null;
            return state;
        });
        this.setState((state) => {
            state.avatarFileBase64 = fileContent;
            state.newAvatar = fileContent;
            // state.newAvatar = (
            //     <ImageViewer width={400} height={"auto"} src={fileContent}/>
            // ); //no callback method at this time
            return state;
        });
    };
    /**
     *
     * Get avatar file from FileSelectFieldIcon.
     * Only give priority to the first file that's selected
     * (should the user pick multiple files)
     * @param input the input field or component from which to fetch
     * the file.
     * Should you pass anything other than
     *
     */
    getAvatarFile = (
        input = this.fileSelectFieldReference | Component | HTMLElement
    ) => {
        //only care for the first file. check its content, attempt to convert to base64 then
        //test
        let avatarFile;
        if (input.current.constructor.name === FileSelectFieldIcon.name) {
            avatarFile = input.current.internalFieldReference.current.files[0];
            // pass callback and see if this works
            this.readToBase64(avatarFile, this.upgradeAvatar);
        } else if (
            input.current.internalFieldReference instanceof HTMLInputElement &&
            input.current.internalFieldReference.type === "file"
        ) {
            this.readToBase64(input.target.files[0], this.upgradeAvatar);
        } else
            throw new TypeError(`${this.getAvatarFile.name} method expected HTMLInputElement, 
            React component, or a React component reference. 
            Found ${input.constructor.name}`);
        //clear this field
        //console.log('before clearing field: ', this.fileSelectFieldReference.current.internalFieldReference.current.files)
        this.fileSelectFieldReference.current.clearField();
        //console.log('after clearing field: ', this.fileSelectFieldReference.current.internalFieldReference.current.files)
    };
    /**
     *
     * Method gets the avatar data and updates form data in the GMS component
     * then sends the data to the server using the particular endpoint
     *
     */
    finalizeUpdateAvatar = () => {
        this.updateFormData("avatar", this.state.avatarFileBase64);
        //
        //update avatar and listen for response
        //
        this.sendRequest("updateavatar");
    };
    /**
     *
     * profile viewer component structure
     * @returns {JSX.Element} profile viewer
     *
     */
    render = () => {
        return (<Section>
                <SectionTitle>
                    Add or Update your avatar
                </SectionTitle>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        Current
                    </SectionColumn>
                    <SectionColumn>
                        New
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <ImageViewer
                            src={this.avatar}
                            width={400}
                            height={"auto"}
                            callback={null}
                        />
                    </SectionColumn>
                    {/*new avatar*/}
                    <SectionColumn>
                        <SectionRow>
                            <SectionColumn>
                                <ImageViewer
                                    src={this.state.newAvatar}
                                    width={400}
                                    height={"auto"}
                                    callback={null}
                                />
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <FileSelectFieldIcon bold fontSize={14}
                                                     name={"newAvatar"}
                                                     changeCallback={() => {
                                                         this.getAvatarFile(this.fileSelectFieldReference);
                                                     }}
                                                     ref={this.fileSelectFieldReference}
                                />
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <SubmitButton
                            commandText={"Update avatar"}
                            callback={this.finalizeUpdateAvatar}
                        />
                    </SectionColumn>
                </SectionRow>
            </Section>
        );
    };
}
