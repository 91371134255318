import React from "react";
import {connect} from "react-redux";

import GMS from "../../../../parent-component/GMS";
import {getStorageObject, readFromLocalStorage} from "../../../../../MiscUtils";
import TextField from "../../../../general-components/input-field/TextField";
import SelectField from "../../../../general-components/input-field/SelectField";
import {BCLB, LOCAL_BACKUP} from "../../../../general-components/redux/allowed-actions";
import {Section, SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";

const kenyanDomain = new RegExp(/^[a-zA-Z0-9].co.ke$/);

/**
 * The following sub-classes all are implemented on bootstrap columns as the parent elements.
 */

class OperatorOriginLocation extends GMS {
    constructor(props) {
        super(props);
        //do nothing at this time other than record data
        this.storage = readFromLocalStorage(LOCAL_BACKUP, BCLB);
        const c = readFromLocalStorage('county', BCLB);
        this.counties = [];
        // remove All from the list
        let k = 0;
        if (c !== undefined)
            do {
                if (Object.values(c[k])[0] !== 'All')
                    this.counties.push(c[k]);
                k += 1;
            } while (k < c.length);
    }

    /**
     *
     * receive prop updates from local storage. only the country is required
     * @param nextProps
     * @param nextContext
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        //set state with props
        this.setState({
            e: nextProps.LOCAL_BACKUP,
            countries: nextProps.country
        });
    }
    /**
     *
     * fetch country data.
     *
     */

    componentDidMount = () => {
        let storageData = getStorageObject(BCLB);
        if (storageData.length > 0) {
            storageData = JSON.parse(storageData);
            if (Object.getOwnPropertyNames(storageData).length > 0) {
                // get LOCAL_BACKUP item
                this.setState({e: storageData['LOCAL_BACKUP']});
            }
        }
        // this.props.setMandatoryFields([
        //     'county',
        //     'town',
        //     'street',
        //     'plot_no',
        //     'building_name',
        // ]);
    }

    render = () => {
        return <Section>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <legend className={"legend-section-title no-border"}>
                                Company Details
                            </legend>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SelectField isRequired
                                         bold
                                         options={this.counties}
                                         fontSize={14}
                                         defaultValue={this.storage.county && this.storage.county}
                                         name={'county'}
                                         placeholder={'County'}
                                         changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField isRequired
                                       bold
                                       fontSize={14}
                                       defaultValue={this.storage.town && this.storage.town}
                                       name={'town'}
                                       placeholder={'Town/City'}
                                       changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField isRequired
                                       bold
                                       fontSize={14}
                                       defaultValue={this.storage.street && this.storage.street}
                                       name={'street'}
                                       placeholder={'Street/road'}
                                       changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>

                        <SectionColumn>
                            <TextField isRequired
                                       bold
                                       fontSize={14}
                                       name={'plot_no'}
                                       defaultValue={this.storage.plot_no && this.storage.plot_no}
                                       placeholder={'L/R Number'}
                                       changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField isRequired
                                       bold
                                       fontSize={14}
                                       defaultValue={this.storage.building_name && this.storage.building_name}
                                       name={'building_name'}
                                       placeholder={'building name'}
                                       changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField bold
                                       fontSize={14}
                                       defaultValue={this.storage.floor && this.storage.floor}
                                       name={'floor'}
                                       placeholder={'Floor No.'}
                                       changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>

                        <SectionColumn>
                            <TextField
                                // isRequired
                                bold
                                maxLength={20}
                                fontSize={14}
                                defaultValue={this.storage.coordinates && this.storage.coordinates}
                                name={'coordinates'}
                                placeholder={'Cooordinates(lat,long)'}
                                changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            {/*<TextField*/}
                            {/*    bold*/}
                            {/*    // isRequired*/}
                            {/*    fontSize={14}*/}
                            {/*    defaultValue={this.storage.room && this.storage.room}*/}
                            {/*    name={'room'}*/}
                            {/*    placeholder={'Room No.'}*/}
                            {/*    changeCallback={this.collectFieldData}*/}
                            {/*/>*/}
                        </SectionColumn>
                        <SectionColumn/>
                    </SectionRow>
                    {/*<SectionRow>*/}
                    <SectionColumn>
                        <legend className={"legend-section-title no-border"}>
                            Websites
                        </legend>
                    </SectionColumn>
                    <SectionRow>

                        <SectionColumn>
                            <TextField
                                // isRequired
                                bold
                                maxLength={60}
                                fontSize={14}
                                defaultValue={this.storage.local_website_address && this.storage.local_website_address}
                                name={'local_website_address'}
                                placeholder={'Type in websites, separated by commas'}
                                changeCallback={(e) => {
                                    let addresses = [];
                                    if (e.target.value.indexOf(",") !== -1)
                                        addresses = e.target.value.split(',');
                                    else
                                        addresses = [e.target.value];
                                    this.collectFieldData([e.target.name, addresses]);
                                }}
                            />
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}


/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = ['country', 'LOCAL_BACKUP'];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
}
export default connect(mapStateToProps)(OperatorOriginLocation);
