/*a operator-registration profile*/

import GMS from "../../parent-component/GMS";
import {col12, col3, col6, getStorageObject, notify, row} from "../../../MiscUtils";
import React from "react";
import {BCLB, LOGOUT} from "../../general-components/redux/allowed-actions";
import {Dispatch} from "../../general-components/redux/app-storage";
import {initialState} from "../../general-components/redux/reducer";

/**
 *
 * Component clears out the local storage or resets the local storage
 */
export default class Logout extends GMS {
    componentDidMount = () => {
        //update titlebar with new title
        const whoIsLoggedIn = getStorageObject(BCLB)
        this.sendRequest('post', `account/logout`, () => {
            // proceed to default container
            Dispatch({type: LOGOUT, payload: initialState});
            window.setTimeout(() => {
                notify('You have logged out - Have a good day!', 1, true)
                // clear the history
                this.navigateTo(whoIsLoggedIn === 4 ? '/representative' : '/representative', true); // default login
            }, 700)
        }); // default: logout
    };
    render = () => {
        return (
            <div className={row}>
                <div className={col12}>
                    <div className={`${row} big-space-row`}/>
                    <div className={row}>
                        <div className={col3}/>
                        <div className={col6}>
                            logging you out...
                        </div>
                        <div className={col3}/>
                    </div>
                </div>
                {/*// enable logout via routing*/}
                {this.state.currentRoute}
            </div>
        );
    };
}
