import React from "react";
import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow} from "../view-utils/SectionUtils";
import SimpleLabel from "../../general-components/labels/SimpleLabel";
import TextAreaField from "../../general-components/input-field/TextAreaField";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";

import '../custom-views-css.css'
import TextField from "../../general-components/input-field/TextField";
import {notify, readFromLocalStorage} from "../../../MiscUtils";
import {BCLB, NULL_BACKUP} from "../../general-components/redux/allowed-actions";
import {Dispatch} from "../../general-components/redux/app-storage";
import EmailField from "../../general-components/input-field/EmailField";

/**
 *
 * A composer of letters as communication to operator representative. This
 * is also replicated on the operator's system but with the formDataURL
 * method call being accorded priority
 *
 */
export default class LetterComposer extends GMS {
    constructor(props) {
        super(props);
        // props include
        // 1. withSignature - if this is set with a value, the signature shows up in the <SignaturePad /> else it shows the pad
        // 2. message
        // 3. letterHead properly formatted
        // 4. referenceNumber if available
        // 5. from optional
        // 6. to optional
        // 7. topic
        // 8. user
        // this.signaturePadRef = React.createRef();
        // read from local storage and get the letter object
        // This is a convenience assignment
        this.email = readFromLocalStorage('email', BCLB);
        this.letterData = this.props.defaultRead ? readFromLocalStorage('letter', BCLB) : {};
        if (!this.props.from && !this.props.to)
            this.letterData = readFromLocalStorage('letter', BCLB);
        // else if (this.props.from) this.letterData['from'] = this.props.from;
        // else if (this.props.to) this.letterData['to'] = this.props.to;
    }

    /**
     *
     * Get user id for sending emails
     *
     * @param email
     */
    getUserId = (email, context) => {
        this.sendRequest('get', `account/user/?email=${email}`, user => {
            //console.log(user);
            if (user.type === 'Success') {
                if (context === 'from_id')
                    this.setState({
                        dataItem3: {
                            ...this.state.dataItem3,
                            from_id: user[0].id
                        }
                    });
                else if (context === 'to_id')
                    this.setState({
                        dataItem3: {
                            ...this.state.dataItem3,
                            to_id: user[0].id
                        }
                    });
            }
            // else this.letterData = undefined;
        }, {});
    }

    componentDidMount = () => {
        this.setState({dataItem3: {...this.letterData}}, () => {
            if (!this.props.defaultRead) {
                // this.collectFieldData(['from', this.letterData.from]).then(r => {
                // });
                // this.collectFieldData(['to', this.letterData.to]).then(r => {
                // });
                // if role is 4, the sender is this person's email and the recepient is the CEO
                if (this.letterData) {
                    this.letterData['reference_number'] && this.collectFieldData(['reference_number', this.letterData.reference_number]).then(r => {
                    });
                    this.collectFieldData(['response_id', this.state.randomData ? this.state.randomData : 1]).then(r => {
                    });
                    if (isNaN(this.letterData.to_id)) {
                        // search the user with the 'email' value found
                        this.sendRequest('get', `account/user/?email=${this.letterData.correspondence_to}`, user => {
                            //console.log(user);
                            if (user.type === 'Success') this.setState({
                                dataItem3: {
                                    ...this.state.dataItem3,
                                    to_id: user[0].id
                                }
                            });
                            // else this.letterData = undefined;
                        }, {});
                    }
                    if (isNaN(this.letterData.from_id)) {
                        // search the user with the 'email' value found
                        // get the CEO's id. This is very dangerous
                        this.sendRequest('get', `account/users/?email=${this.letterData.correspondence_from}`, user => {
                            //console.log(user);
                            if (user.type === 'Success') this.setState({
                                dataItem3: {
                                    ...this.state.dataItem3,
                                    from_id: user[0].id
                                }
                            });
                            // else this.letterData = undefined;
                        }, {});
                    }
                }
            }
        })

    }
    render = () => {
        //console.log(this.letterData)
        //console.log('this defaultRead, visible', this.props.defaultRead !== undefined && !this.state.visible)
        return this.state.dataItem3 ? <Section>
            {this.state.currentRoute}
            {this.props.letterHead}
            <SectionRow>
                <SectionColumn>{this.state.dataItem3.reference_number}</SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        {/*switch out the correspondence recepients on the representative's side*/}
                        {/*This is doing the inverse of everything.*/}
                        <SectionColumn>
                            {
                                this.email && <span>
                        <SimpleLabel text={'From'} bold fontSize={16}/>
                                    {/*<SimpleLabel text={this.state.dataItem1} fontSize={15}/>*/}
                                    <SimpleLabel text={this.email} fontSize={15}/>
                        </span>
                            }
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            {
                                this.state.dataItem3['correspondence_from'] ? <span>
                        <SimpleLabel text={'To'} bold fontSize={16}/>
                            <SimpleLabel text={this.state.dataItem3['correspondence_from']} fontSize={16}/>
                        </span> : <EmailField name={'correspondence_from'}
                                              isRequired
                                              bold
                                              fontSize={14}
                                              placeholder={'To'}
                                    // will only work after the mouse blurs out of the field
                                              changeCallback={e => this.collectFieldData(e).then(r => {
                                                  this.getUserId(this.state.e['correspondence_from'], 'to_id');
                                              })}/>
                            }
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn style={{width: '4vw', paddingTop: 30}}>

                            <SimpleLabel text={'Subject'}
                                         capitalize
                                         bold
                                         withColon
                                         fontSize={16}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField style={{width: '72vw', fontSize: 14, fontWeight: 500, color: '#292b31'}}
                                       name={'subject'}
                                       defaultValue={this.state.dataItem3['subject'] && this.state.dataItem3['subject']}
                                       placeholder={''}
                                       changeCallback={this.collectFieldData}/>

                        </SectionColumn>
                        <SectionColumn style={{width: 100}}/>
                    </SectionRow>
                    {
                        this.props.defaultRead && <SectionRow>
                            <SectionColumn style={{width: '5vw'}}>
                                <a style={{
                                    border: '1px solid #606164',
                                    padding: 4,
                                    borderRadius: 4,
                                    color: '#057a47',
                                    fontWeight: 500,
                                    fontSize: 16
                                }}
                                   href={'#'}
                                   onClick={() => this.setState({visible: !this.state.visible})}>{this.state.visible ? 'Hide' : 'Respond'}</a>
                            </SectionColumn>
                            <SectionColumn/>
                        </SectionRow>
                    }
                    <SectionRow>

                        {
                            this.props.defaultRead !== undefined && !this.state.visible ? <SectionColumn style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    border: '1px solid #e2e3e7',
                                    borderRadius: 3,
                                    padding: 8,
                                    width: '70vw',
                                    margin: '0 auto',
                                    textAlign: 'justify'
                                }}>{this.state.dataItem3.message}</SectionColumn>
                                :

                                <SectionColumn>
                                    <TextAreaField name={'message'}
                                                   noResize
                                                   bold
                                                   isRequired
                                                   placeholder={'Type in a message'}
                                                   changeCallback={this.collectFieldData}
                                                   style={{width: '76vw', height: 200}}
                                    />
                                </SectionColumn>
                        }
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            {/*<SectionRow>*/}
            {/*    <SectionColumn>*/}
            {/*        <SectionRow>*/}
            {/*            <SectionColumn>*/}
            {/*                <div className={'box'}/>*/}
            {/*            </SectionColumn>*/}
            {/*        </SectionRow>*/}
            {/*        <SectionRow>*/}
            {/*        </SectionRow>*/}
            {/*    </SectionColumn>*/}
            {/*    <SectionColumn/>*/}
            {/*    <SectionColumn>*/}
            {/*        /!*{*!/*/}
            {/*        /!*    this.props.withSignature === undefined ? <SignaturePad*!/*/}
            {/*        /!*            options={{minWidth: 180, maxWidth: 320, penColor: 'rgb(66, 133, 244)'}}/> :*!/*/}
            {/*        /!*        <SignaturePad*!/*/}
            {/*        /!*            ref={this.signaturePadRef}*!/*/}
            {/*        /!*            options={{minWidth: 180, maxWidth: 320, penColor: 'rgb(66, 133, 244)'}}/>*!/*/}
            {/*        /!*}*!/*/}
            {/*    </SectionColumn>*/}
            {/*    <SectionColumn>*/}
            {/*        <div className={'box'}/>*/}
            {/*        /!*QR code pad here using the reference number, company name, license number(if any),*!/*/}
            {/*        /!*operator_registration_ref_number*!/*/}
            {/*    </SectionColumn>*/}
            {/*</SectionRow>*/}
            <SectionRow>
                <SectionColumn>
                    <br/>
                    <br/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>

                <SectionColumn/>
                <SectionColumn>
                    <SubmitButton commandText={'cancel'} callback={() => {
                        //redirect to
                        this.navigateTo('../correspondences-list');
                        // clear state.e
                        this.setState(state => {
                            delete state.e['message'];
                            delete state.e['subject'];
                            delete state.e['reference_number'];
                            return state;
                        })
                    }}/>
                </SectionColumn>
                <SectionColumn/>
                <SectionColumn>
                    <SubmitButton commandText={'Send letter'} callback={() => {
                        this.sendRequest('post', `notifications/correspondence/?correspondence_from=${this.state.dataItem3.to_id}&correspondence_to=${this.state.dataItem3.from_id}`, response => {
                            // call the callback
                            if (response.type === 'Success') {
                                // notify then navigate to correspondencelist
                                notify(`Letter sent to ${this.state.dataItem3.correspondence_from}`, 4, true);
                                this.navigateTo('../correspondences-list');
                            }
                            Dispatch({type: NULL_BACKUP});
                            // if (this.letterData.action === 'activate') {
                            //     const objectType = this.letterData.object === 'license' ? 'operator/license/license/' : 'company/operator/';
                            //     this.sendRequest('put', `${objectType}${this.letterData.id}`,
                            //         response => {
                            //             //console.log(response)
                            //             // }, {"status": 3})
                            //             if (response.type === 'Success') this.navigateTo('../task-view');
                            //         }, {"status": 6})
                            // }

                            // clear state.e
                        })
                    }}/>
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>
        </Section> : <Section>
            <SectionRow><SectionColumn><span style={{color: '#f12139', fontSize: 18, fontWeight: 700}}>Cannot compose this letter!</span>
                <br/>Neither the sender nor recepient of this
                letter is specified.</SectionColumn></SectionRow>
        </Section>
    }
}