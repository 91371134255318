import GMS from "../../parent-component/GMS";

/**
 *
 * Allow documents details to be viewed. Distinguish between/among all uploaded documents
 *
 */
export default class DocumentDetailsView extends GMS {
    render = () => {
        return null
    }
}