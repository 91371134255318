import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import {arrayOfJSONsToJSON, readFromLocalStorage} from "../../../MiscUtils";
import {BCLB} from "../../general-components/redux/allowed-actions";
import SimpleLabel from "../../general-components/labels/SimpleLabel";
import GMS from "../../parent-component/GMS";

/**
 *
 * View gateways
 *
 */
export default class GatewayView extends GMS {
    constructor(props) {
        super(props);
        this.gateway = readFromLocalStorage('misc_id', BCLB);
        this.banks = arrayOfJSONsToJSON(readFromLocalStorage('banks', BCLB));
    }

    /**
     *
     * Facilitate a reverse-lookup for the branch name using the branch id.
     *
     * @param branchId
     * @returns {Promise<null>}
     */
    bankBranchReverseLookup = async (branchId) => {
        let k = null;
        await this.sendRequest('get', `selections/bankbranch/${branchId}`, branches => {
            k = branches.data[0].name
        }, {});
        return k;
    }
    render = () => {
        return <Section>
            <SectionTitle>
                View gateway
            </SectionTitle>
            {this.gateway.gateway === 'Bank' ? <SectionRow>
                    <SectionColumn>
                        <SectionRow>
                            <SectionColumn>
                    <span>
                        <SimpleLabel text={'Gateway type'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={this.gateway.gateway} fontSize={14} capitalize/>
                    </span>
                            </SectionColumn>
                        </SectionRow>
                        {/*// loop to illustrate the banks*/}
                        {
                            this.gateway.bank.map((bankEntry, index) => {
                                return <SectionRow style={{background: index & 2 == 0 ? '#e9eeff' : 'FFFFFF'}}>
                                    <SectionColumn>
                    <span>
                        <SimpleLabel text={'Bank name'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={this.banks[bankEntry]} fontSize={14} capitalize/>
                    </span>
                                    </SectionColumn>
                                    <SectionColumn>
                    <span>
                        <SimpleLabel text={'Branch'} fontSize={14} bold capitalize/>
                        {
                            this.bankBranchReverseLookup(bankEntry.bank_branch).then(bName => {
                                return <SimpleLabel
                                    text={bName}
                                    fontSize={14} capitalize/>
                            })
                        }
                    </span>
                                    </SectionColumn><SectionColumn>
                    <span>
                        <SimpleLabel text={'Account'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={bankEntry
                        .bank_account} fontSize={14} capitalize/>
                    </span>
                                </SectionColumn>
                                </SectionRow>
                            })
                        }

                    </SectionColumn>
                </SectionRow>
                :
                <SectionRow>
                    <SectionColumn>
                        <SectionRow>
                            <SectionColumn>
                    <span>
                        <SimpleLabel text={'Gateway type'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={this.gateway.gateway} fontSize={14} capitalize/>
                    </span>
                            </SectionColumn>
                            <SectionColumn>
                    <span>
                        <SimpleLabel text={'Mobile provider'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={this.gateway.mobile_provider} fontSize={14} capitalize/>
                    </span>
                            </SectionColumn>
                            <SectionColumn>
                    <span>
                        <SimpleLabel text={'Channel'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={this.gateway.mobile_channel} fontSize={14} capitalize/>
                    </span>
                            </SectionColumn><SectionColumn>
                    <span>
                        <SimpleLabel text={'Account'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={this.gateway.account_name} fontSize={14} capitalize/>
                    </span>
                        </SectionColumn>
                        </SectionRow>
                        <SectionRow style={{background: '#e9eeff'}}>
                            <SectionColumn>

                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>}


        </Section>
    }
}