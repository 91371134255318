import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import SimpleLabel from "../../general-components/labels/SimpleLabel";
import {BCLB, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {fullWindowInterface, readFromLocalStorage} from "../../../MiscUtils";
import ContextMenu from "../context/ContextMenu";
import {Dispatch} from "../../general-components/redux/app-storage";
import RespondToIssue from "./MakeResponse";

/**
 *
 * Have a dynamic table for listing issues.
 * Take note of the styling that's used here. A significant amount of it is CSS2,
 * especially work relating to the right-hand column that indicates data on the company that's involved
 *
 */
export default class IssueView extends GMS {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        // get the id of the company being viewed currently
        this.issueObject = readFromLocalStorage('misc_id', BCLB);
        // get also the issue number that was dispatched
    }

    loadCoresondenceWithData = (_id, data) => {
        return <div style={{borderBottom: '1px solid #de8484'}}>
            <SectionRow>
                <SectionColumn>
                    <div style={{
                        textAlign: 'center',
                        width: 20,
                        height: 20,
                        padding: 2,
                        borderRadius: 2,
                        background: '#d32020',
                        color: '#FFFFFF',
                        fontSize: 12
                    }}>
                        {_id}
                    </div>

                </SectionColumn>
            </SectionRow>
            <SectionRow>

                <SectionColumn>
                    <SimpleLabel text={'Sender'} colour={'#800080'} fontSize={14} bold capitalize/>
                    <SimpleLabel text={data['author']} fontSize={14} colour capitalize/>
                </SectionColumn>
                <SectionColumn/>
                {/*<SectionColumn>*/}
                {/*    <SimpleLabel text={'To'} colour={'#800080'} fontSize={14} bold capitalize/>*/}
                {/*    <SimpleLabel text={data['assignee']} fontSize={14} colour capitalize/>*/}
                {/*</SectionColumn>*/}
                <SectionColumn/>
                <SectionColumn/>
                <SectionColumn>
                    <SimpleLabel text={data['date_created']}
                                 colour={'#696666'}
                                 fontSize={14}
                                 capitalize/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn/>
            </SectionRow>
            {/*<SectionRow>*/}
            {/*    <SectionColumn>*/}
            {/*        <SimpleLabel text={data['text']}*/}
            {/*                     bold*/}
            {/*                     capitalize*/}
            {/*                     fontSize={14}*/}
            {/*                     colour={'#800080'}*/}
            {/*        />*/}
            {/*    </SectionColumn>*/}
            {/*</SectionRow>*/}
            <SectionRow>
                <SectionColumn>
                    <SimpleLabel text={data['text']}
                                 fontSize={14}
                                 colour
                    />
                </SectionColumn>
            </SectionRow>
        </div>
    }

    loadCorrespondence = (corespondences = []) => {
        let x = 0;
        let k = [];
        if (corespondences !== undefined) {
            if (corespondences.constructor.name === [].constructor.name && corespondences.length > 0)
                do {
                    k.push(this.loadCoresondenceWithData(x + 1, corespondences[x]));
                    x += 1;
                } while (x < corespondences.length);
        }
        return k;
    }

    render = () => {
        Dispatch({type: SUBTITLE, payload: 'List of registered issues'});
        return <Section>
            {this.state.currentRoute}
            <SectionRow>
                <SectionColumn>
                    <SectionRow style={{borderBottom: '1px solid #A9A1A1FF'}}>
                        <SectionColumn style={{width: '220vw'}}/>
                        <SectionColumn>
                            <ContextMenu
                                task={[() => {
                                    // navie to task and set the object
                                    //console.log('object of issue ', this.issueObject)
                                    this.navigateTo('../create-task', false);
                                }]}
                                respond={() => {
                                    fullWindowInterface(<RespondToIssue
                                        element={this.issueObject}/>, 'Respond to this issue', null);
                                }}
                                // delete={[() => {
                                //     window.alert('trash')
                                // }]}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow><SectionColumn/></SectionRow>
                    <SectionTitle style={{marginBottom: 20}}>
                        Issue: <SimpleLabel text={this.issueObject.title.length > 0 ?
                        this.issueObject.title : `No ${this.issueObject.id}`}
                                            colour={'#800080'}
                                            bold
                                            capitalize
                                            fontSize={18}/>
                    </SectionTitle>
                    <SectionRow>
                        <SectionColumn>
                            <SimpleLabel text={'Source'} colour={'#800080'} fontSize={12} bold capitalize/>
                            <SimpleLabel text={this.issueObject.creator} fontSize={12} colour capitalize/>
                        </SectionColumn>
                        <SectionColumn>
                            <SimpleLabel text={'Assigned to'} colour={'#800080'} fontSize={12} bold capitalize/>
                            <SimpleLabel text={this.issueObject['assigned']} fontSize={12} colour capitalize/>
                        </SectionColumn>
                        <SectionColumn/>
                        <SectionColumn/>
                        <SectionColumn>
                            {/*<SimpleLabel text={'Registered on'} colour={'#800080'} bold capitalize/>*/}
                            <SimpleLabel text={this.issueObject.date_created}
                                         colour={'#696666'}
                                         fontSize={12}
                                         capitalize/>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn/>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SimpleLabel text={this.issueObject['text']}
                                         bold
                                         capitalize
                                         fontSize={12}
                                         colour={'#800080'}
                            />
                        </SectionColumn>
                    </SectionRow>
                    {/*<SectionRow>*/}
                    {/*    <SectionColumn>*/}
                    {/*        <SimpleLabel text={this.issueObject.description}*/}
                    {/*                     fontSize={12}*/}
                    {/*                     colour*/}
                    {/*        /><SimpleLabel text={this.issueObject.description}*/}
                    {/*                       fontSize={12}*/}
                    {/*                       colour*/}
                    {/*    />*/}
                    {/*    </SectionColumn>*/}
                    {/*</SectionRow>*/}
                </SectionColumn>
            </SectionRow>
            <SectionTitle>
                Correspondences
            </SectionTitle>
            <SectionRow>
                <SectionColumn style={{
                    overflow: 'hidden',
                    borderTop: '1px dashed',
                    overflowY: 'scroll',
                    scrollbarWidth: 'thin',
                    height: 600
                }}>
                    {
                        this.loadCorrespondence(this.issueObject['comment'])
                    }
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}