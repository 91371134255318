import React, {Component} from "react";
import CloseControl from "../../general-components/controls/svg-controls/CloseControl";
import './map.css';
import {Section, SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";
import TextField from "../../general-components/input-field/TextField";
import CheckBoxField from "../../general-components/input-field/CheckBoxField";
import {arrayOfJSONsToJSON, extractValueFromJSON, readFromLocalStorage} from "../../../MiscUtils";
import {BCLB} from "../../general-components/redux/allowed-actions";
import SelectField from "../../general-components/input-field/SelectField";

/**
 *
 * Component for showing information on a location.
 *        coordinates, name, country, nearest road etc
 *        callback (optional) what happens when this location component is clicked
 *
 */
export default class SelectedLocation extends Component {
    constructor(props) {
        super(props);
        this.locationId = this.props.locationIndex;
        // get the local json
        this.games = readFromLocalStorage('game', BCLB);
        this.county = readFromLocalStorage('county', BCLB);
    }

    /**
     *
     * Allow games to be picked
     *
     * @param chosenGameTypes the games in question
     * @returns {*[]} rows of data
     *
     */
    presentGameTypes = (chosenGameTypes = this.props.chosenGameTypes) => {
        let y = 0;
        let across = 0;
        let t = []; // rows
        let j = []; // columns
        do {
            j.push(<SectionColumn>
                <CheckBoxField
                    fontSize={14}
                    name={'game_type'}
                    placeholder={extractValueFromJSON(chosenGameTypes[y], arrayOfJSONsToJSON(this.games))}
                    capitalize
                    data={chosenGameTypes[y]}
                    changeCallback={(d, data) => {
                        this.props.updatePremiseData(d, this.locationId, data);
                    }}/>
            </SectionColumn>);
            if (y === chosenGameTypes.length - 1 || across === 4) {
                t.push(<SectionRow>{j}</SectionRow>);
                j = []; // reset columns
            }
            across = j.length;
            y += 1;
        } while (y < chosenGameTypes.length);
        return t;
    }
    // get gametypes supported
    render = () => {
        let countyName, countyKey;
        // if (this.props.locationData['county']) {
        //     if (!isNaN(this.props.locationData['county'])) {
        //         countyName = arrayOfJSONsToJSON(readFromLocalStorage('county', BCLB))[this.props.locationData['county']];
        //     } else {
        //         countyName = this.props.locationData['county'] ? this.props.locationData['county'].indexOf(' ') !== -1 ? this.props.locationData['county'].split(' ') : this.props.locationData['county'] : [];
        //         // countyName.pop();
        //         countyName = countyName[0];
        //         countyKey = extractKeyFromJSON(countyName, arrayOfJSONsToJSON(this.county));
        //     }
        // }
        //console.log('county name ', countyName, 'key ',extractKeyFromJSON(countyName, arrayOfJSONsToJSON(this.county)))
        return <div className={'selected-location'}>
            <div className={'location-data-title'}>
                <div style={{width: '96%'}}>
                    {!isNaN(parseInt(this.props.title)) ?
                        extractValueFromJSON(parseInt(this.props.title),
                            arrayOfJSONsToJSON(readFromLocalStorage('county', BCLB))) : this.props.title}
                </div>
                <CloseControl callback={
                    () => {
                        this.props.removeItem(this.locationId)
                    }}
                              width={14}
                              height={14}
                              colour={'#434242'}
                    // style={{position: 'relative', right: -86}}
                />
            </div>
            <div className={'selected-location-content'}>
                <Section style={{boxShadow: 'none', borderRadius: 0}}>
                    {/*<SectionRow>*/}
                    {/*    <SectionColumn style={{fontSize: 12}}>*/}
                    {/*        <b>This premise is a Head quarter (or office)</b>*/}
                    {/*    </SectionColumn>*/}
                    {/*    <SectionColumn>*/}
                    {/*        <CheckBoxField*/}
                    {/*            name={`premise_or_outlet`}*/}
                    {/*            placeholder={""}*/}
                    {/*            // // defaultValue={this.props.locationData['premise_or_outlet'] === true}*/}
                    {/*            changeCallback={(e) => {*/}
                    {/*                // update current selected location*/}
                    {/*                this.props.updatePremiseData(e, this.locationId);*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </SectionColumn>*/}
                    {/*</SectionRow>*/}
                    {
                        this.props.chosenGameTypes ? <>
                            <SectionRow>
                                <SectionColumn style={{fontSize: 12, fontWeight: 600}}>
                                    Games deployed in this outlet (optional):
                                </SectionColumn>
                            </SectionRow>
                            {this.presentGameTypes()}
                        </> : null
                    }
                    <SectionRow>
                        <SectionColumn>
                            <SelectField bold
                                         isRequired
                                         name={'county'}
                                         placeholder={'Select county'}
                                         fontSize={14}
                                         defaultValue={countyKey}
                                         options={this.county.slice(0)} // remove the first element "All"
                                         changeCallback={e => this.props.updatePremiseData(e, this.locationId, null, true)}/>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <TextField
                                bold
                                fontSize={14}
                                capitalize
                                isRequired
                                // defaultValue={this.props.locationData['plot_number'] && this.props.locationData['plot_number']}
                                name={"plot_number"}
                                placeholder={"L/R Number"}
                                changeCallback={e => this.props.updatePremiseData(e, this.locationId)}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <TextField
                                bold
                                fontSize={14}
                                capitalize
                                name={"shop_name"}
                                placeholder={"Name"}
                                // defaultValue={this.props.locationData['shop_name'] && this.props.locationData['shop_name']}
                                changeCallback={e => this.props.updatePremiseData(e, this.locationId)}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <TextField
                                bold
                                fontSize={14}
                                capitalize
                                name={"building_name"}
                                placeholder={"Host building name"}
                                // defaultValue={this.props.locationData['building_name'] && this.props.locationData['building_name']}
                                changeCallback={e => this.props.updatePremiseData(e, this.locationId)}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn className={'reformatted-location-data'}>
                            {/*<SectionRow>*/}
                            {/*    <SectionColumn>*/}
                            {/*        <b>County</b>*/}
                            {/*    </SectionColumn>*/}
                            {/*    <SectionColumn>*/}
                            {/*        <span*/}
                            {/*            style={{color: '#2c5ebc'}}>{this.props.locationData && this.props.locationData['county']}</span>*/}
                            {/*    </SectionColumn>*/}
                            {/*</SectionRow>*/}
                            {/*            <SectionRow>*/}
                            {/*                <SectionColumn>*/}
                            {/*                    <b>Globe coords</b>*/}
                            {/*                </SectionColumn>*/}
                            {/*                <SectionColumn>*/}
                            {/*           <span*/}
                            {/*               style={{color: '#2c5ebc'}}>{this.props.locationData &&*/}
                            {/*           this.props.locationData['geoCords'] && this.props.locationData['geoCoords'][0]}*/}
                            {/*</span>, <span*/}
                            {/*                    style={{color: '#2c5ebc'}}>{this.props.locationData &&*/}
                            {/*                this.props.locationData['geoCords'] && this.props.locationData['geoCoords'][1]}*/}
                            {/*</span>*/}
                            {/*                </SectionColumn>*/}
                            {/*            </SectionRow>*/}
                            <SectionRow>
                                {/*     <SectionColumn>*/}
                                {/*<span style={{color: '#3c3939'}}>*/}
                                {/*     <b>latitude</b>*/}
                                {/*     </span>*/}
                                {/*     </SectionColumn>*/}
                                <SectionColumn>
                                    <TextField bold
                                               isRequired
                                               name={'lat'}
                                               placeholder={'Latitude'}
                                               fontSize={14}
                                               changeCallback={this.props.updatePremiseData}/>
                                    {/*<span*/}
                                    {/*    style={{color: '#ea1111'}}>{this.props.locationData &&*/}
                                    {/*this.props.locationData['gps_location']*/}
                                    {/*&& this.props.locationData['gps_location'][0]['lat'].toFixed(5)}*/}
                                    {/*    /!*&& this.props.locationData['gps_location'][0].toFixed(5)}*!/*/}
                                    {/*</span>*/}
                                </SectionColumn>
                                {/*            <SectionColumn>*/}
                                {/*                <span style={{color: '#3c3939'}}>*/}
                                {/*<b>longitude</b>*/}
                                {/*                </span>*/}
                                {/*            </SectionColumn>*/}
                                <SectionColumn>
                                    <TextField bold
                                               isRequired
                                               name={'long'}
                                               placeholder={'Longitude'}
                                               fontSize={14}
                                               changeCallback={this.props.updatePremiseData}/>
                                    {/*<span*/}
                                    {/*    style={{color: '#ea1111'}}>{this.props.locationData &&*/}
                                    {/*this.props.locationData['gps_location']*/}
                                    {/*&& this.props.locationData['gps_location'][0]['long'].toFixed(5)}</span>*/}
                                    {/*&& this.props.locationData['gps_location'][1].toFixed(5)}</span>*/}
                                </SectionColumn>

                            </SectionRow>
                            <SectionRow>
                                {/*<SectionColumn>*/}
                                {/*    <b>Address</b>*/}
                                {/*</SectionColumn>*/}
                                <SectionColumn>
                                    <TextField bold
                                               isRequired
                                               name={'address'}
                                               placeholder={'Address'}
                                               fontSize={14}
                                               changeCallback={this.props.updatePremiseData}/>

                                    {/*<span*/}
                                    {/*    style={{color: '#2c5ebc'}}>{this.props.locationData &&*/}
                                    {/*this.props.locationData['address']*/}
                                    {/*&& this.props.locationData && this.props.locationData['address']}</span>*/}
                                </SectionColumn>
                            </SectionRow>
                            {/*{*/}
                            {/*    this.props.locationData['nearestRoad'] && <SectionRow>*/}
                            {/*        <SectionColumn>*/}
                            {/*            <b>Nearest road</b>*/}
                            {/*        </SectionColumn>*/}
                            {/*        <SectionColumn>*/}
                            {/*         <span*/}
                            {/*             style={{color: '#2c5ebc'}}> {this.props.locationData &&*/}
                            {/*         this.props.locationData['nearestRoad']*/}
                            {/*         && this.props.locationData['nearestRoad']}</span>*/}
                            {/*        </SectionColumn>*/}
                            {/*    </SectionRow>*/}
                            {/*}*/}
                        </SectionColumn>
                    </SectionRow>
                </Section>
            </div>
        </div>
    }
}
