import GMS from "../../../../parent-component/GMS";
import {arrayOfJSONsToJSON, col12, readFromLocalStorage} from "../../../../../MiscUtils";
import SelectField from "../../../../general-components/input-field/SelectField";
import React from "react";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {connect} from "react-redux";
import {BCLB, BLUR_FIELDS_FOR, LOCAL_BACKUP} from "../../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";

/**
 *
 * Current operations that this company is running.
 *
 */
class CurrentOperations extends GMS {
    constructor(props) {
        super(props);
        this.games = readFromLocalStorage('game', BCLB);
        this.omitFields = readFromLocalStorage('blur_fields', BCLB);
    }

    /**
     *
     * Populate state with data from local storage
     * @param nextProps
     * @param nextContext
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            e: nextProps.LOCAL_BACKUP, //also load previous data from backup
            games: nextProps.game,
            // machines: nextProps.machines,
        });
    };
    /**
     *
     *
     * add item to list of games selected
     * @param itemToAdd the select field (select option)
     *
     */
    addToGameList = (itemToAdd) => {
        //console.log(this.state.e, this.state.e[itemToAdd.target.name], itemToAdd.target.name, itemToAdd.target.value)
        // if the current element in e includes the value, return

        if (this.state.e[itemToAdd.target.name]) {
            if (!(this.state.e[itemToAdd.target.name].includes(parseInt(itemToAdd.target.value)))) {
                //console.log(this.state.e[itemToAdd.target.name], ' does not include ', String(itemToAdd.target.value), !(this.state.e[itemToAdd.target.name].includes(String(itemToAdd.target.value))))
                let games = this.state.e[itemToAdd.target.name];
                games.push(parseInt(itemToAdd.target.value));
                games = [itemToAdd.target.name, games]; // it's an array with key and value items
                this.collectFieldData(games).then(r => {
                });
            }
        } else {
            this.collectFieldData([itemToAdd.target.name, [parseInt(itemToAdd.target.value)]]).then(r => {
            });
        }
        let item = {}; //item that hosts the final item to add as a JSON object.
        const key = itemToAdd.target.value;
        item[key] = itemToAdd.target[itemToAdd.target.value].innerText;
        this.updatedataItem1(item).then((r) =>
                this.prepareSingleTonRows(this.deleteSingleTonRow)
            // {}
        );

    };
    /**
     *
     *
     * add item to list of games selected
     * @param itemToAdd the select field (select option)
     *
     */
    addToGameMachinesList = (itemToAdd) => {
        let item = {}; //item that hosts the final item to add as a JSON object.
        const key = itemToAdd.target.selectedIndex;
        item[key] = itemToAdd.target.value;
        this.updateGamesMachinesdataItem1(item).then((r) =>
            this.prepareMachineSingleTonRows(
                this.deleteMachinedataItem1,
                this.state.dataItem3
            )
        );
        // this.gamesSelections.push(item);
    };
    /**
     *
     * Prepare a singleTonRow object that will be used to prepare singleTon rows
     * using the prepareSingleTonRows
     *
     * @param array array of values to read from game object
     *
     */
    loadSingleTonRowData = array => {
        //console.log('array ', array)
        let t = 0;
        let y = []; // the object that will have singleTonRow data
        try {
            const game = arrayOfJSONsToJSON(readFromLocalStorage('game', BCLB));
            do {
                const z = {};
                z[parseInt(array[t])] = game[parseInt(array[t])];
                y.push(z);
                t += 1;
            } while (t < array.length)
        } catch (noGamesListFound) {/*do nothing*/
        }
        return y;
    }
    /**
     *
     * Load all games in
     *
     */
    componentDidMount = () => {
        // load misc items on mount
        // set state with the data from backup
        const y = readFromLocalStorage(LOCAL_BACKUP, BCLB).game_type;
        if (y) {
            this.setState({dataItem1: [...this.loadSingleTonRowData(readFromLocalStorage(LOCAL_BACKUP, BCLB).game_type)]}, () => {
                this.prepareSingleTonRows(this.deleteSingleTonRow);
            });
            // this.props.setMandatoryFields(['game_type']);
        }
    }
    render = () => {
        // console.log(this.state.dataItem2, ' data item 1 ', this.state.dataItem1);
        return <Section>
            <SectionTitle>
                Nature of operations
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    <SelectField bold fontSize={14}
                                 capitalize
                                 isRequired
                                 defaultValue={this.state.e['game_type'] ? this.state.e['game_type'][this.state.e['game_type'].length - 1] : []}
                                 options={this.games ? this.games : []}
                                 name={"game_type"}
                                 placeholder={"Select the desired gaming operations"}
                                 changeCallback={(d) => {
                                     this.addToGameList(d);
                                     let blur_fields = this.omitFields;
                                     const fText = d.target.options[d.target.selectedIndex].textContent
                                     if (blur_fields === undefined)
                                         blur_fields = [];
                                     // odds only applies to Bookmaking, Public Gaming and Public Lottery
                                     // any of these games must be in local backup, game type
                                     // Any others, odds do not apply
                                     if (fText !== 'Bookmaking' || fText !== 'Public Gaming'
                                         || fText !== "Public Lottery (Short Term)"
                                         || fText !== "Public Lottery (Long Term)") {
                                         //console.log(this.state.e['game_type'], d.target.options[d.target.selectedIndex], this.state.e['game_type'].includes(d.target.options[d.target.selectedIndex]), fText)
                                         // if current selected index is in game type or if BookMakign or public gaming or public lottery is in game_type, do not push odds
                                         if (this.state.e['game_type'])
                                             if ((this.state.e['game_type'].includes(d.target.options[d.target.selectedIndex]) && fText === 'Bookmaking')
                                                 || (this.state.e['game_type'].includes(d.target.options[d.target.selectedIndex]) && fText === 'Public Gaming')
                                                 || (this.state.e['game_type'].includes(d.target.options[d.target.selectedIndex]) && fText === "Public Lottery (Short Term)")
                                                 || (this.state.e['game_type'].includes(d.target.options[d.target.selectedIndex]) && fText === "Public Lottery (Long Term)"))
                                                 // do not withhold odds providers being registered
                                                 blur_fields.splice(blur_fields.indexOf('odds'), 1);
                                     } else if (blur_fields.length > 0)
                                         blur_fields.splice(blur_fields.indexOf('odds'), 1);
                                     Dispatch({type: BLUR_FIELDS_FOR, payload: blur_fields});
                                 }}
                    />
                </SectionColumn>
            </SectionRow>
            <SectionRow scrollable>
                <SectionColumn>
                    <div className={`${col12} bottom-streak`}>
                                <span className={"highlighted-text"}>
                                  {/*an inline counter for games selected*/}
                                    {this.state.dataItem1 ?
                                        this.state.dataItem1.length
                                        : 0
                                    }
                                </span>
                        <span className={"inverse-highlighted-text"}>
                                            &ensp;operations selected
                                </span>
                    </div>
                </SectionColumn>
            </SectionRow>
            {this.state.dataItem2}
            <SectionRow>
                <SectionColumn>
                    <Section>
                        <SectionTitle>
                            Declaration of gaming devices
                        </SectionTitle>
                        <SectionRow>
                            <SectionColumn/>
                            <SectionColumn>
                                <SimpleLabel text={'Not available at this time'} colour={"#c9700a"} bold fontSize={24}/>
                            </SectionColumn>
                            <SectionColumn/>
                        </SectionRow>
                    </Section>
                </SectionColumn>
            </SectionRow>
        </Section>
        {/*only show if */
        }

    }
}

/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only games, banks, branches and instruments are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = [
        "game",
        "LOCAL_BACKUP",
    ];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
};
export default connect(mapStateToProps)(CurrentOperations);
