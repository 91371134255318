import {Component} from "react";
import {ResponsivePie} from '@nivo/pie';

import './pie-chart-css.css';

/**
 *
 * Component for showing data
 *
 */
export default class PieChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data ? props.data.length > 0 ? props.data : [
                {id: 'no-data-provided', label: 'no-data-provided', value: 100}] : [
                {id: 'no-data-provided', label: 'no-data-provided', value: 100}]
        }
    }

    /**
     *
     * Receive a new list of data from backend detailing what should be shown
     *
     * @param nextProps
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: nextProps.data ?
                nextProps.data.length === 0 ?
                    [
                        {id: 'no-data-provided', label: 'no-data-provided', value: 100}] :
                    nextProps.data :
                [{
                    id: 'no-data-provided', label: 'no-data-provided',
                    value: 100
                },
                    {
                        id: 'no-data-provided-2', label: 'no-data-provided-2',
                        value: 100
                    }
                ]
        });
    }

    render = () => {
        return (
            <div className={'pie-space'}>
                <ResponsivePie
                    data={this.state.data}
                    colors={this.props.colours ? this.props.colours : ['#252447', '#351735', '#cca00a', '#63e500', '#da2929', '#663e75', '#ac5a6f']}
                    margin={{top: 40, right: 300, bottom: 80, left: 40}}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{from: "color", modifiers: [["darker", 0.2]]}}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: "color"}}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{from: "color", modifiers: [["darker", 2]]}}
                    legends={[
                        {
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 280,
                            translateY: 0,
                            itemsSpacing: 10,
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#999',
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 18,
                            symbolShape: 'square',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                    activeInnerRadiusOffset={0}/>
            </div>
        );
    }
};