import GMS from "../../parent-component/GMS";

import React from "react";
import {readFromLocalStorage} from "../../../MiscUtils";
import {BCLB} from "../../general-components/redux/allowed-actions";
import LetterComposer from "../operator/LetterComposer";
import BCLBLetterHead from "../operator/BCLBLetterHead";

export default class CorrespondenceView extends GMS {
    constructor(props) {
        super(props);
        this.correspondence = readFromLocalStorage('misc_id', BCLB);
    }

    render = () => {
        // return <Section><SectionRow>
        //     <SectionColumn>
        //         // finalising correspondnece view and testing
        //     </SectionColumn>
        // </SectionRow></Section>

        return <LetterComposer defaultRead
            // withSignature
                               letterHead={<BCLBLetterHead/>}
                               message={this.correspondence.message}
                               from={this.correspondence['correspondence_from']}
                               to={this.correspondence['correspondence_to']}
                               subject={this.correspondence.subject}
            // callback={() => this.sendRequest('put', `company/operator/${this.operator.id}`,
            //     response => {
            //         //console.log(response)
            //         // }, {"status": 3})
            //     }, {"status": 6})}
        />
    }
}