import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import {notify} from "../../../MiscUtils";
import DynamicTable from "../../general-components/tables/DynamicTable";
import LoadingData from "../../general-components/controls/data-loading/LoadingData";
import {SUBTITLE} from "../../general-components/redux/allowed-actions";
import {Dispatch} from "../../general-components/redux/app-storage";

/**
 *
 * Show list of beneficiaries
 *
 */
export default class ListBOFMembers extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // fetch all members of the BOF list
        Dispatch({type: SUBTITLE, payload: 'List Beneficial Owners of this company'});
        this.sendRequest('get', 'company/operator/bof', bofList => {
            if (bofList.type === 'Success')
                this.setState({dataItem1: [...bofList.data]})
            else
                notify('Could not get list of beneficiary owners', 2, false, 'BO list absent');
        }, {})
    }
    render = () => {
        return <Section>
            <SectionTitle>
                List of Beneficial owners
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    {
                        this.state.dataItem1.length === 0 ?
                            <LoadingData hasData={this.state.dataItem1.length === 0} messaage={'Fetching list of Beneficial Owners...'}/> :
                            <DynamicTable
                                context={'beneficial owners list'}
                                tableData={this.state.dataItem1}
                                columnsOfInterest={
                                    [
                                        'id',
                                        'first_name',
                                        'last_name',
                                        'email',
                                        'percentage_of_voting_rights',
                                        'type_of_influence',
                                    ]
                                }
                                showRowData={row => {
                                    // navigate to bof member view
                                }}
                            />
                    }
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}