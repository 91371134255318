import GMS from "../parent-component/GMS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {readFromLocalStorage} from "../../MiscUtils";
import {BCLB, MISC_ID} from "../general-components/redux/allowed-actions";
import NotificationBubble from "../general-components/notification-component/NotificationBubble";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import '../general-components/notification-component/notification-css.css';

/**
 *
 * Notification component allows for the view or summarization
 * of notifications by counting the number of notifications/
 * messages passed through props.
 * Props:
 *      - notifications [{
 *                          from,
 *                          to,
 *                          topic,
 *                          text,
 *                          time_sent,
 *                          is_read
 *                      },
 *                      ...]
 *      - onSelect
 *      - classifyByTopic This is an option that tells the notification
 *                          component to classify notifications listing by
 *                          topic
 *      - classifyByTime This is an option that tells the notification
 *                          component to classify notifications listing by
 *                          time. This is the default classification (Last
 *                          Come First Displayed).
 *
 */
export default class Profile extends GMS {
    constructor(props) {
        super(props);
        // this state miscellaneousItems constitutes all notifications
        // this state dataItem1 constitutes any classifications
        // this state variable constitutes the count of notifications
        this.ls = readFromLocalStorage(MISC_ID, BCLB);
    }

    /**
     *
     * update props (dataItem4, miscellaneousItems, variable)
     * @param nextProps
     * @param nextContext
     * @constructor
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            dataItem4: nextProps.notifications ? nextProps.notifications.length > 0 ?
                    '#FFFFFF' :
                    '#4f4d4d' :
                '#4f4d4d',
            miscellaneousItems: nextProps.notifications ? [...nextProps.notifications] : [],
            variable: nextProps.notifications ? nextProps.notifications.length : 0
        });
    }
    /**
     *
     * method to remove notification bubble
     *
     */
    removeNotificationBubble = () => {
        this.setState({visible: false});
    }
    /**
     *
     * load state with props
     *
     */
    componentDidMount = () => {
        this.setState({
            dataItem4: this.props.notifications ? this.props.notifications.length > 0 ?
                    '#FFFFFF' :
                    '#4f4d4d' :
                '#4f4d4d',
            miscellaneousItems: this.props.notifications ? [...this.props.notifications] : [],
            variable: this.props.notifications ? this.props.notifications.length : 0
        });
        // update the notifications endpoint to allow is_read set to true

    }

    /**
     *
     * Update the notifications counter.
     *
     */
    updateCounter = () => {

    }
    /**
     *
     * Fires when classifyByTopic is specified.
     *
     */
    classifyByTime = () => {

    }
    /**
     *
     * Fires when classifyByTopic is specified.
     *
     */
    classifyByTopic = () => {

    }
    /**
     *
     * Show a notification bubble when notifications is clicked
     *
     */
    toggleNotificationBubble = (toggle = this.state.visible) => {
        this.setState({visible: !toggle});
    }

    render = () => {
        return <div className={'profile'} style={{padding: 8, cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faUser}
                             style={{margin: '4px auto', height: 36, color: '#FFFFFF'}}
                             onClick={() => {
                                 // show notificationBubble
                                 // this.state.variable > 0 && this.toggleNotificationBubble()
                                 this.toggleNotificationBubble()
                                 // this.props.onClick();
                             }}
            />
            {
                this.state.visible ?
                    this.props.customUI ? <NotificationBubble removeNotificationBubble={this.removeNotificationBubble}
                                                              menuItems={this.props.menuItems}
                                                              customUI={this.props.customUI}
                                                              viewNotificationCallback={this.props.onClick}/>
                        :
                        <NotificationBubble
                            removeNotificationBubble={this.removeNotificationBubble}
                            notifications={this.props.notifications && this.props.notifications}
                            menuItems={this.props.menuItems && this.props.menuItems}
                            viewNotificationCallback={this.props.onClick}/> : null

            }
        </div>
    }
}