/**
 *
 * Hosts a table for showing list of operators.
 * Provide a UI that enables alternation of views between piecharts and table
 *
 *
 *
 */
import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import InfoTab from "../../general-components/info-ed-tab/InfoTab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie, faTable} from "@fortawesome/free-solid-svg-icons";
import DynamicTable from "../../general-components/tables/DynamicTable";
import PieChart from "../../general-components/charts/PieChart";
import {BCLB, PREV_ROUTE, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {readFromLocalStorage} from "../../../MiscUtils";
import {Dispatch} from "../../general-components/redux/app-storage";

export default class RepresentativesList extends GMS {
    constructor() {
        super();
        Dispatch({type: PREV_ROUTE, payload: 'list-representatives'});
    }

    /**
     *
     * Method switches views by updating state 'switchableView'
     * @param view
     *
     */
    switchView = (view = null) => {
        this.setState({switchableView: null}, () => {
            this.setState({switchableView: view})
        });
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'List of representatives of companies'});
    }

    render = () => {
        return <Section>
            <SectionTitle>
                list of operators
            </SectionTitle>
            {/*// only show this if the currently logged in person is anyone other than a representative*/}
            {
                readFromLocalStorage('role', BCLB) !== 4 &&

                <SectionRow>
                    {/*provide an entire count of all operators by their statuses*/}
                    <SectionColumn style={{width: '20vw'}}/>
                    <SectionColumn>
                        <InfoTab callback={() => {
                        }} label={`all reps`} value={30} valueColour={'#800080'}/>
                    </SectionColumn>
                    <SectionColumn>
                        <InfoTab callback={() => {
                        }} label={`new`} value={7} valueColour={'#929295'}/>
                    </SectionColumn>
                    <SectionColumn>
                        <InfoTab callback={() => {
                        }} label={`active`} value={8} valueColour={'#279C03FF'}/>
                    </SectionColumn>
                    <SectionColumn>
                        <InfoTab callback={() => {
                        }} label={`suspended`} value={12} valueColour={'#DD1128FF'}/>
                    </SectionColumn>
                    <SectionColumn>
                        <InfoTab callback={() => {
                        }} label={`retrenched`} value={3} valueColour={'#f88027'}/>
                    </SectionColumn>
                </SectionRow>
            }
            {/*// actual view*/}
            <SectionRow style={{borderTop: '1px solid #929295'}}>
                <SectionColumn style={{width: '20vw'}}/>
                <SectionColumn style={{fontSize: '1.4em', color: '#'}}>
                    View as:
                </SectionColumn>
                {/*do an onclick to switch the components_to_delete loaded between a table and a pie-chart*/}
                <SectionColumn onClick={() => {
                    this.switchView('table')
                }}>
                    <FontAwesomeIcon icon={faTable} style={{height: 30, color: '#800080'}}/>
                    {/*<i className={'fa-solid fa-table'} />*/}
                </SectionColumn>
                <SectionColumn/>
                <SectionColumn onClick={this.switchView}>
                    <FontAwesomeIcon icon={faChartPie} style={{height: 30, color: '#800081'}}/>
                    {/*<i className={'fa-solid fa-chart-pie'} />*/}
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                {
                    this.state.switchableView === 'table' ?
                        <DynamicTable context={'representatives'}
                                      tableData={this.state.miscellaneousItems === null ? [] : this.state.miscellaneousItems}
                                      showRowData={(dataToShow) => {/*navigate to the operator viewer with the data in question*/
                                          // Dispatch({type: PREV_ROUTE, payload: `rep-view/${dataToShow.id}`});
                                          this.navigateTo(`../rep-view/${dataToShow.id}`);
                                      }}/> :
                        <PieChart data={this.state.miscellaneousItems === null ? [] : this.state.miscellaneousItems
                        }/>
                }
            </SectionRow>
        </Section>
    }
}