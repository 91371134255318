import {Component} from "react";
import './list-style.css';
import CloseControl from "../controls/svg-controls/CloseControl";

/**
 *
 * A list element
 *
 */
class ListElement extends Component {
    constructor(props) {
        super(props);
        this.element = props.element;
    }

    /**
     *
     * Construct list element
     *
     * @param element element text or object
     * @param elementId the element id to remove
     * @returns {JSX.Element} the element, fully styled
     *
     */
    constructListElement = (element = this.props.element, elementId = this.props.elementId) => {
        elementId = String(elementId); // type-cast to string
        return <div className={'list-element'} id={elementId}>
            {element}
            &ensp;
            <CloseControl colour={'#da3636'}
                          callback={() => {
                              // this.props.removeFromList(this.props.elementId ? this.props.elementId : 0)
                              this.props.removeFromList(elementId);
                          }}/>
        </div>
    }
    render = () => {
        return this.constructListElement()
    }
}

/**
 *
 * A list that's updated by a selection field or other component and shows it in a list
 * Props:
 *      elements - (the elements/texts to display) an array of strings
 *      elementIds - (optional) an array of identifiers (user-defined)
 *      orientation - the format to show the list. either 'landscape' or 'potrait'
 *      removeFromList - (method) a callback function that's called when the 'X' button seleccted.
 *
 */
export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // existingElements: this.props.existingElements,
            elements: [],
            elementIds: this.props.elementIds ? this.props.elementIds : [],
            orientation: this.props.potrait ? 'potrait' : 'landscape',
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //console.log('next props elements ', nextProps.elements, ' element ids ', nextProps.elementIds);
        if (nextProps.elements)
            this.constructListElements(nextProps.elements);
        if (nextProps.elementIds)
            this.setState({elementIds: [...nextProps.elementIds]});
    }

    componentDidMount = () => {
        this.constructListElements();
    }

    /**
     *
     * Construct list elements
     *
     * @param propsElements
     *
     */
    constructListElements = (propsElements = this.props.elements) => {
        let elementsJSX = [];
        if (propsElements.length > 0) {
            if (propsElements.constructor.name === [].constructor.name) {
                if (propsElements.length > 0) {
                    let x = 0;
                    do {
                        if (propsElements[x] !== undefined)
                            elementsJSX.push(<ListElement
                                elementId={this.props.elementIds && this.props.elementIds[x]}//optional
                                removeFromList={this.props.removeFromList}
                                element={propsElements[x]}
                            />);
                        x += 1;
                    } while (x < propsElements.length);
                }
            } else
                elementsJSX.push(<ListElement elementId={this.props.elementIds && this.props.elementIds[0]}//optional
                                              removeFromList={this.removeFromList}
                                              element={propsElements}/>);
            this.setState({elements: [...elementsJSX]}); // only works for the first run
        }
    }
    /**
     *
     *
     *
     * @returns {JSX.Element}
     *
     */
    render = () => {
        return <div className={'list'}>
            <label className={'list-title'}>
                {this.props.title}
            </label>
            <div className={'list-elements'}>
                {this.state.elements}
            </div>
            <br/>
        </div>;
    }
}
