import "./base/svg-controls.css";
import {Section, SectionColumn, SectionRow} from "../../../rotm-custom-views/view-utils/SectionUtils";
import GMS from "../../../parent-component/GMS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";

/**
 *
 * Class provides a means for navigation through content supplied through an array of components_to_delete.
 * It offers 2 possible capabilities through instantiating of the same navigation with different props.
 *  1. forwards - which shows a navigation component with the '>' marker.
 *  2. backwards - which shows a navigation with the '<' marker.
 *
 *  A 'callback' method through the quoted property is used to act on a list of navigableComponents
 *  supplied to the Navigation component to switch (in desired direction) to the component that
 *  follows the current one.
 *
 *  List of applicable properties:
 *
 *  NOTE: Though all the properties are optional, the 'navigableComponents' property is necessary. Otherwise
 *          the instantiated component does nothing useful other than look beautiful.
 *
 *
 *      zIndex - a integer telling the component how shallow it should be in the DOM tree's z-axis view;
 *               that is, how far away it is from you.
 *               The higher the number the shallower it is. This mirrors and uses the
 *               CSS 'z-index' attribute. It defaults to 2050.
 *
 *
 *      @deprecated position -  (ignored by default, overtaken by <SectionRow component which positions itself far better>)
 *                   An array of strings telling the component where in the viewport
 *                   it should position itself. In either <Navigation <other-props-list> backwards />
 *                   or <Navigation <other-props-list> forwards />, it defaults to a top of 50% and
 *                   a left of 0% and 90% respectively.
 *
 *
 *      @deprecated forwards - (ignored by default) an attribute that tells the navigation component to show a forward navigation
 *                   component and mount it to the right of the viewport. Defaults to showing it
 *                   at the center (along the viewport's height) -> top: 50%, left: 90% passed
 *                   in the position property as an array (by default) of ['50%','94%'].
 *
 *
 *      @deprecated backwards - (ignored by default) an attribute that tells the navigation component to show a backward navigation
 *                   component and mount it to the left of the viewport. Defaults to showing it
 *                   at the center (along the viewport's height)-> top: 50%, left: 90% passed
 *                   in the position property as an array (by default) of ['50%','0%'].
 *
 *
 *       navigableRoutes - this is an array of routes by which nagivation across various components is achieved.
 *
 *
 *       callback - the method to call when the navigation is clicked. Note that the callback property
 *                   assumes that it's concerned with switching of navigableComponents. The engineer
 *                   should provide mechanisms of conducting additional functionality if desired.
 *
 *
 *       @deprecated navigateTo (this method is called internally, since the component now inherits GMS
 *                               superclass)
 *               - this is an integer telling the navigation component which component (through
 *               its index) to navigate to. Defaults to navigableComponents halfway mark (when it's absent it becomes 0).
 *
 *       forwardColour - a string of HEX digits or colour name that tells the colour that is to be applied
 *                      to the forwards control.
 *
 *       backwardColour - a string of HEX digits or colour nam that tells the colour that is to be applied to
 *                      the backwards control.
 *
 *       noBorder - a property that tells the navigation to not show a border around the shevron.
 *
 */
export default class Navigation extends GMS {
    constructor(props) {
        super(props);
        this.className = String('svg-control');
        this.width = props.width === undefined ? 30 : props.width;
        this.height = props.height === undefined ? 30 : props.height;
        if (props.callback === undefined)
            throw new ReferenceError(`No callback is passed as a property with the 
            'callback' property to <${this.constructor.name} /> component call`);
    }

    /**
     * Allow options change when navigation data to either display or hide navigation buttons is updated
     * @param nextProps
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps = (nextProps: Readonly<P>, nextContext: any) => {
        //console.log('next props show backwards ',  nextProps.showBackwards, ' show forwards ',nextProps.showForwards);
        this.setState({
            showForwards: nextProps['showForwards'],
            showBackwards: nextProps['showBackwards']
        });
    }
    /**
     *
     * Figure out where you are relative to storage.
     * Do this by checking whether the window url includes the current
     * indexed route
     *
     */
    componentDidMount = () => {
        this.setState({showForwards: this.props['showForwards'], showBackwards: this.props['showBackwards']});
    }
    render = () => {
        return <Section>
            <SectionRow>
                {
                    this.state.showBackwards && <SectionColumn>
                    <span onClick={this.props.onNavigation[0]}
                          style={{cursor: 'pointer', fontSize: 18}}>
    {/*<FontAwesomeIcon icon={faChevronLeft}/>*/}
                        <FontAwesomeIcon icon={faChevronLeft}/>
                        {/*<FontAwesomeIcon icon={faChevronLeft}/>&ensp;Back*/}
                        <FontAwesomeIcon icon={faChevronLeft}/>Back
                                        </span>
                    </SectionColumn>}
                <SectionColumn/>
                <SectionColumn/>
                {
                    this.state.showForwards && <SectionColumn>
                    <span onClick={this.props.onNavigation[1]}
                          style={{cursor: 'pointer', fontSize: 18}}>
                                            {/*Next &ensp;<FontAwesomeIcon icon={faChevronRight}/>*/}
                        Next<FontAwesomeIcon icon={faChevronRight}/>
                                        <FontAwesomeIcon icon={faChevronRight}/>
                        {/*<FontAwesomeIcon icon={faChevronRight}/>*/}
                                        </span>
                    </SectionColumn>}
            </SectionRow>
        </Section>
    }
}
