import {notify} from "../../../../MiscUtils";

/**
 *
 * This is a list of callbacks that are invoked when clicked by a user from the side-menu or from any other tool
 * that is desired
 *
 */
const setContextOfThisClassInstantiationError = (theHostClassToExecMethodsFrom) => {
    return `SideMenuCallbacks: ${theHostClassToExecMethodsFrom.constructor.name} is not a class reference. 
    Instantiate this class in a react component that extends GMS class!`
}

/**
 *
 * This is an instantiatable class with the <this> being expected for use, that allows access to user-defined
 * MenuList-class-compatible Menu actions assembly. This class is completely user-defined and serves to
 * separately define some anonymous callback functions and return them in an array to the
 * MenuList's generalMenuList(callbacksArray, <boolean>) static method call.
 *
 * If it's to be used, one can chose to use it AS IS knowing full well its a custom class, or have it as an
 * educational tool to construct yours. See commentary on the 'licensesAndOperatorsCallbacks' method for
 * some fine details on the same.
 *
 */
export class SideMenuCallbacks {
    // the reason this is so is because
    constructor(theHostClassToExecMethodsFrom) {
        if (!theHostClassToExecMethodsFrom.navigateTo) {
            notify(setContextOfThisClassInstantiationError(theHostClassToExecMethodsFrom), 2, true);
            // console.error(setContextOfThisClassInstantiationError(theHostClassToExecMethodsFrom));
            throw new Error(setContextOfThisClassInstantiationError(theHostClassToExecMethodsFrom));
        }
        this.theHostClassToExecMethodsFrom = theHostClassToExecMethodsFrom;
    }

    /**
     *
     * Method allows checking out various menu based on the role of the current user
     * @param theRoleInQuestion
     * @returns {*} a method that returns a array of callback methods.
     *
     */
    getAppropriateMenuCallbacks = (theRoleInQuestion) => {
        const appropriateMenu = {
            4: this.representativeCallbacks,
        };
        return appropriateMenu[theRoleInQuestion];

    }
    /**
     *
     * Menu callbacks for documents. Since these will be used across the system, its better to separate them and assume
     * one entity
     *
     */
    documentsCallbacks = () => {
        return [() => {
            this.theHostClassToExecMethodsFrom.navigateTo('upload-supporting-documentation', false) // allow documents uploads
        },
            () => {
                this.theHostClassToExecMethodsFrom.navigateTo('list-uploaded-documents', false) // list all documents
            }]
    }
    //
    /**
     *
     * This is used by operator representative.
     * Filter the menu callbacks based on the presence of operators (only allow the "declare-operator" navigation if operator is absent)
     *  or  "list-operator" if the operator exists but their status is not active.
     * Role code: 4
     * @returns {[((function(): void)|*), ((function(): void)|*), ((function(): void)|*), ((function(): void)|*)]}
     *          array of callbacks doing custom navigationTo
     *
     */
    representativeCallbacks = (hasOperator = false, isActivated = false) => {
        /**/
        //console.log('has operator ', hasOperator, ' isActivated ', isActivated)
        if (hasOperator && isActivated)
            return [
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-operators', false); // only one operator-registration per representative
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('rep-license-application', false);
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('rep-permit-application', false);
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('rep-shops-application', false);
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('license-permits-list', false); // view list of all licenses for the operator-registration of this representative
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-shareholder', false); // list all premises
                }, () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('update-shareholder-list', false); // list all premises
                }, () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('shareholders-list', false); // list all premises
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-office-premise', false); // declare new premise
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-business-premise', false); // declare new premise
                },
                // () => {
                //     this.theHostClassToExecMethodsFrom.navigateTo('change-premise', false); // change premise
                // },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-premises', false); // change premise
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-employees', false); // change premise
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-employees', false); // change premise
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-odds-providers', false); // change premise
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-providers', false); // change premise
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('upload-returns', false); // upload returns document
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-return-uploads', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('rep-no-objections-to', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-payments', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('bclb-service-charter', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('correspondences-list', false); // list return documents
                },
                // () => {
                //     this.theHostClassToExecMethodsFrom.navigateTo('contacts', false); // list return documents
                // },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('faq', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('glossary', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('user-manual', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('license-renewal-application', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-bof', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-bof-members', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-gateway', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-gateways', false); // list return documents
                },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('list-renewal-applications', false); // list return documents
                },
                // ...this.documentsCallbacks(),
                // () => {
                //     this.theHostClassToExecMethodsFrom.navigateTo('chat', false); // list return documents
                // }
            ];
        else if (hasOperator)
            return [() => {
                this.theHostClassToExecMethodsFrom.navigateTo('list-operators', false); // only one operator-registration per representative
            },
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('correspondences-list', false); // list all premises
                }
                // () => {
                //     this.theHostClassToExecMethodsFrom.navigateTo('declare-shareholder', false); // list all premises
                // }
                //         () => {
                //             this.theHostClassToExecMethodsFrom.navigateTo('rep-license-application', false);
                //         },
                //         () => {
                //             this.theHostClassToExecMethodsFrom.navigateTo('license-permits-list', false); // view list of all licenses for the operator-registration of this representative
                //         },

            ];
        else
            return [
                () => {
                    this.theHostClassToExecMethodsFrom.navigateTo('declare-operator/operator-introduction', false); // only show this if an operator-registration does not exist for this representative
                }
            ]
    }
}

export class ToolsCallbacks {
    constructor(theHostClassToExecMethodsFrom) {
        if (!theHostClassToExecMethodsFrom.navigateTo) {
            notify(setContextOfThisClassInstantiationError(theHostClassToExecMethodsFrom), 2, true);
            console.error(setContextOfThisClassInstantiationError(theHostClassToExecMethodsFrom));
        }
    }
}