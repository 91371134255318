import GMS from "../../../../parent-component/GMS";

import React from "react";
import {connect} from "react-redux";

import TextAreaField from "../../../../general-components/input-field/TextAreaField";
import {SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {getStorageObject} from "../../../../../MiscUtils";
import {BCLB, LOCAL_BACKUP} from "../../../../general-components/redux/allowed-actions";

/**
 * The following sub-classes all are implemented on bootstrap columns as the parent elements.
 */

class IntroductoryProposal extends GMS {
    constructor(props) {
        super(props);
        //do nothing at this time other than record data
    }

    /**
     *
     * receive prop updates from local storage. only the country is required
     * @param nextProps
     * @param nextContext
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        //set state with props
        this.setState({
            e: {...nextProps.LOCAL_BACKUP},
        });
    }
    /**
     *
     * When component mounts, fill in data from this state e if redux is already filled in
     *
     */
    componentDidMount = () => {
        this.setState({e: getStorageObject(BCLB)[LOCAL_BACKUP]});
        // this.setState({mandatoryFields:['proposal']});
    }

    render = () => {
        return (
            <SectionColumn>
                <SectionRow>
                    <SectionColumn style={{
                        borderBottom: '1px solid #B87AB8FF',
                        fontWeight: 400,
                        fontSize: '1.3em',
                        lineHeight: 1.9,
                        width: '90%',
                        // color: '#800080', //393a3e
                        textAlign: 'left'
                    }}>
                        In the field provided below, write a short essay introducing your company to the Betting Control
                        and Licensing Board.
                        State the name of the company and the nature/natures of business that it wishes to engage in
                        within the Republic of Kenya.<br/><br/> The essay should not exceed an equivalent of one
                        page in word processors.
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextAreaField isRequired
                            // noPaste
                                       defaultValue={this.state.e['proposal'] && this.state.e['proposal']}
                                       style={{height: 400}}
                                       noResize
                                       name={'proposal'}
                                       placeholder={''}
                                       changeCallback={this.collectFieldData}/>
                        {/*changeCallback={e => this.collectFieldData(['proposal', e.target.value],true)}/>*/}

                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
        );
    };
}


/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = ['LOCAL_BACKUP'];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
}
export default connect(mapStateToProps)(IntroductoryProposal);
