import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
import DynamicTable from "../tables/DynamicTable";
import {Dispatch} from "../redux/app-storage";
import {connect} from "react-redux";
import {BCLB, CURRENT_NOTIFICATION} from "../redux/allowed-actions";
import {readFromLocalStorage} from "../../../MiscUtils";

/**
 *
 * Notification viewer for viewing data about notifications
 *
 */
class NotificationsList extends GMS {
    constructor(props) {
        super(props);
        this.notifications_list = readFromLocalStorage('notifications', BCLB);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.notifications)
            this.setState({dataItem1: [...this.processNotifications(nextProps.notifications)]})
    }
    /**
     *
     * Process notifications such that the from and to are restored
     * @param t
     *
     */
    processNotifications = (t = this.notifications_list ? this.notifications_list : []) => {
        let v = [];
        let y = 0;
        if (t.length === 0)
            return v;
        else
            do {
                let d = {};
                let okeys = Object.getOwnPropertyNames(t[y]);
                for (const o of okeys) {
                    const e = o.split('_')[1];
                    if (e === undefined)
                        d[o] = t[y][o];
                    else
                        d[e] = t[y][o];
                }
                v.push(d);
                y += 1;
            } while (y < t.length);
        return v;
    }

    componentDidMount = () => {
        this.setState({dataItem1: [...this.processNotifications(this.notifications_list)]});
    }

    render = () => {
        return <Section>
            {this.state.currentRoute}
            <SectionTitle>
                List of notifications
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    <DynamicTable
                        noSearch
                        noPrint
                        pageSize={14}
                        context={'Notifications'}
                        tableData={this.state.dataItem1} //an array of tasks-tool
                        columnsOfInterest={
                            [
                                'id',
                                'topic',
                                'from',
                                // 'topic',
                                'to',
                                // 'created'
                            ]
                        }
                        showRowData={(rowData) => {
                            let k = {...rowData};
                            Dispatch({type: CURRENT_NOTIFICATION, payload: k});
                            // Dispatch({type: PREV_ROUTE, payload: `notification-view/${rowData.id}`});
                            this.navigateTo(`../notification-view/${rowData.id}`, false)
                        }}
                    />
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}

const mapStateToProps = (state, e) => {
    return {notifications: state['notifications']};
}

export default connect(mapStateToProps)(NotificationsList);
