import GMS from "../../../../parent-component/GMS";
import React, {Component} from "react";
import {connect} from "react-redux";
import {col1, col4, col6, container, getStorageObject, notify, row} from "../../../../../MiscUtils";
import NumberField from "../../../../general-components/input-field/NumberField";
import {BCLB, LOCAL_BACKUP, NULL_BACKUP, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import RadioField from "../../../../general-components/input-field/RadioField";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import ShareholderFieldSet from "./micro-components/ShareholderFieldSet";
import {Dispatch} from "../../../../general-components/redux/app-storage";

/**
 *
 * Creates shareholders based on the number that is selected from a range field.
 * Defaults to 10
 *
 */
class OperatorShareHoldersSection extends GMS {
    constructor(props) {
        super(props);
        /**
         * a colour dictionary that's used to mark the background of a given fieldset or a bar on the left of that fieldset...
         * @type {{red: string, orange: string, green: string, blue: string, grey: string}} hex codes for various shades of the colours
         *          listed.
         * Note: this object is no-longer useful on account of it being proposed for move to an alternate component specifically
         * designed to handle this extra data regarding to nesting of shareholders of a shareholding operator-registration.
         *
         */
        this.allowedDistinguishingColours = {
            grey: "#d3d3d8",
            green: "#D1E3C1FF",
            blue: "#B8CBF4FF",
            red: "#F4B8BEFF",
            orange: "#F4E8B8FF",
        };
        this.coloursInUse = []; //update this variable when a colour is picked from the list of allowedDistinguishingColours
        this.storage = getStorageObject(BCLB);
    }


    /**
     *
     * update state with necessary component data from this component's props
     * @param nextProps
     * @param nextContext
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            dataItem4: null, // country list
            dataItem1: null, // shareholder type options
            dataItem2: null, // shareholder field data
            dataItem3: null
        }, () => {
            this.setState({
                e: nextProps.LOCAL_BACKUP,
                dataItem4: nextProps.country,
                dataItem3: nextProps.gender,
                dataItem1: nextProps.shareholder_type === undefined ?
                    [] : nextProps.shareholder_type, // used universally for multiple items})
                //check the state for shareholder data
            });
        });
        //state updates but wont rerender
        //set misc
    }
    /**
     *
     * check whether the shareholders object of an array is set. If not, create one..
     *
     */
    componentDidMount = () => {
        Dispatch({
            type: SUBTITLE,
            payload: "Account opening process - declare shareholders"
        });
        let l = [];
        this.setState({e: {...this.storage.LOCAL_BACKUP}})
        // if the operator_shareholders field has something, create the shareholder fieldset using the data in question.
        //
        this.createShareHolderFieldSets();
    };


    // component will unmount, go through the elements here and look for sh_company_name. if it's there, copy it out into a new object
    // else copy it out into another individual object
    // place the objects into e then backup
    sortOutShareholderClasses = () => {
        let operator_company_shareholders = [];
        let operator_individual_shareholders = [];
        const shareholders = this.state.e['operator_shareholders'];
        this.props.bufferStateData && this.props.bufferStateData('shareholders', shareholders); // in order to buffer files uploaded
        // iretare through shareholders
        let e = 0;
        do {
            const names = Object.getOwnPropertyNames(shareholders[e]);
            // if current shareholder keys includes sh_is_company and sh_company_name
            if (names.includes('sh_company_name')) {
                operator_company_shareholders.push(shareholders[e]);
            } else {
                operator_individual_shareholders.push(shareholders[e]);
            }
            e += 1;
        } while (e < shareholders.length);

        // update state e
        this.setState(state => {
            if (operator_company_shareholders.length > 0)
                state.e['operator_company_shareholders'] = operator_company_shareholders;
            if (operator_individual_shareholders.length > 0)
                state.e['operator_individual_shareholders'] = operator_individual_shareholders;
            return state;
        });
    }
    /**
     *
     * update shareholder data inside state.dataItem1.
     * @param shareHolderFieldReference the reference to the input field from which to extract
     *                                   the data belonging to a shareholder
     *
     * @param belongingTo Integer representing which shareholder is being edited.
     *                      this Integer is an indexer (but it is not array-based) for
     *                      the JSON that is currently being updated.
     * @param shType The type of shareholder being recorded.
     *
     // * @param isCompany
     *
     */
    updateShareholderData = (shareHolderFieldReference, belongingTo = 0, shType) => {
        let shareHolderField = {}; //this is just a pre-construction for new elements within the array
        let shareholderType = {
            operator: "operator_shareholders",
            director: "operator_individual_shareholders",
            company: "operator_company_shareholders"
        };
        // check if the current field name exists in state e operator_shareholders array elements for the belongingTo attribute
        // if (shareHolderFieldReference.target.name === 'sh_shares'){
        //     console.log('lpo shares ', shareHolderFieldReference.target.name, shareHolderFieldReference.target.value)
        //     shareHolderField[shareHolderFieldReference.target.name] = parseInt(shareHolderFieldReference.target.value);
        // }
        //
        //
        // else
        shareHolderField[shareHolderFieldReference.target.name] = shareHolderFieldReference.target.type === "checkbox"
            ? shareHolderFieldReference.target.checked : shareHolderFieldReference.target.type === 'file'
                ? shareHolderFieldReference.target.files[0] : shareHolderFieldReference.target.type === 'date' ?
                    shareHolderFieldReference.target.value
                    // formatDate(shareHolderFieldReference.target.value, 'ymd', 'dmy', '-')
                    : shareHolderFieldReference.target.value;
        if (shareHolderFieldReference) {
            // append it to operatorshareholders
            let sh = [];
            if (this.state.e[shareholderType.operator]) {
                sh = this.state.e[shareholderType.operator];
                try {
                    sh[belongingTo - 1][shareHolderFieldReference.target.name] = shareHolderField[shareHolderFieldReference.target.name];
                } catch (newRecordCountFound) {
                    sh.push(shareHolderField);
                }
            } else sh.push(shareHolderField)
            // update state then sort out the classes of shareholders
            // check the shareholder type. If this field is in the state.e object, update it else create one.
            // check if this is the first shareholder director

            // this is the first one
            this.setState(state => {
                state.e[shareholderType.operator] = sh;
                return state;
            }, this.sortOutShareholderClasses)
        }
        if (this.state.e['operator_shareholders'])
            if (belongingTo > this.state.e['operator_shareholders'].length) {
                notify('You must start with the first shareholder when filling in their field data',
                    2,
                    false,
                    'Shareholder sequencing error!');
                return false;
            }
        // compile the data into operator_shareholders
        return true;
    };

    /**
     *
     * creates a list of shareholder data collection fields from a number
     * desired by the user.
     * @param rangeReference integer representing desired number of fields
     * @returns {*[]} collection of fields. In this case, because the state
     * is going to be updated, theres no (mandatory) need to return anything.
     * This might just end up being for show.
     *
     * Make sure that original data is retained
     *
     */
    createShareHolderFieldSets = (rangeReference = HTMLElement | Component | Number) => {
        let p = [];
        let x = 1;
        let numberOfFieldSets = 0;
        if (Object.getOwnPropertyNames(this.storage.LOCAL_BACKUP).includes('operator_shareholders')) {
            // window.alert('shareholders present');
            numberOfFieldSets = this.storage.LOCAL_BACKUP['operator_shareholders'].length;

        } else if (rangeReference instanceof Object) {
            numberOfFieldSets = rangeReference.target.value;
            if (parseInt(numberOfFieldSets) > 50) {
                //console.log('number of fieldsets ', numberOfFieldSets)
                window.alert('The maximum number of shareholders allowed is 50')
                notify('The maximum number of shareholders allowed is 50',
                    2,
                    false,
                    'Shareholder count too high',
                    false);
                return p;
            }
        }
        if (numberOfFieldSets > 0) {
            if (parseInt(numberOfFieldSets) > 50) {

                notify('The maximum number of shareholders allowed is 50',
                    2,
                    false,
                    'Shareholder count too high',
                    false);
                return;
            }
            if (rangeReference instanceof Object) {
                let r = parseInt(rangeReference.target.value);
                if (r < numberOfFieldSets) // if it's a number
                {
                    notify('Minimum number of shareholders are already short-listed! ' +
                        'You cannot decrease the number unless you clear the whole list and short-list again',
                        3, true);
                    return false;
                } else
                    numberOfFieldSets = rangeReference instanceof Object ? rangeReference.target.value : rangeReference;
            }
        }

        do {
            const fieldSet = <ShareholderFieldSet
                //     onClose={
                //     (viewComponent, identifier) => {
                //         // this.popDataAndUI(viewComponent, identifier);
                //         // clear the data in the this.state.e state using the identifier
                //         // update the counter on the UI
                //         // notify that there are now xyz shareholders declared
                //         // go through this.state.dataItem2 and pop the element from it
                //
                //     }
                // }
                fieldMode={false}
                gender={this.storage.gender}
                country={this.storage.country}
                shareTypes={this.storage.shareholder_type}
                updateShareholderData={this.updateShareholderData}
                identifier={x}
                fieldData={this.storage.LOCAL_BACKUP['operator_shareholders'] ?
                    this.storage.LOCAL_BACKUP['operator_shareholders'][x - 1] : {}}
            />;
            // if (!p.includes(fieldSet)) p.push(fieldSet);
            p.push(fieldSet)
            x += 1;
        }
        while (x <= numberOfFieldSets);
        this.setState({dataItem2: null}, () => {
            this.setState({dataItem2: p});
        });
        return p;
    };

    popDataAndUI = (uiComponent, id) => {
        let x = 0;
        // scrub the interface from the view
        let residualViews = [];
        let residualData = [];
        do {
            if (this.state.dataItem2[x] !== uiComponent)
                residualViews.push(this.state.dataItem2[x]);
            x += 1
        } while (x < this.state.dataItem2.length);
        // after that, scrub data from operator_shareholders state
        x = 0;
        do {
            if (x !== id - 1)
                residualData.push(this.state.e["operator_shareholders"][x])
            x += 1;
        } while (x < this.state.e["operator_shareholders"]);
        // attach them back to state then do a reclassification
        this.setState(state => {
            state.e["operator_shareholders"] = null;
            state.dataItem2 = null;
            try {
                delete state.e["operator_individual_shareholders"];
                delete state.e["operator_company_shareholders"];
            } catch (deleteError) {
                // nothing to do here...
            }
            return state;
        }, () => this.setState(state => {
            state.e["operator_shareholders"] = residualData;
            state.dataItem2 = residualViews;
            return state;
        }), this.sortOutShareholderClasses);
    }

    render = () => {
        // return <Section>
        //     </Section>
        return <Section className={`${container}`}>
            <div className={'small-space-row'}/>
            {
                this.props.separateDeclaration &&
                // show a table and declare operators button
                // <Section>
                <SectionTitle>Declare new Directors/Shareholders</SectionTitle>
                // </Section>
            }
            {
                window.location.toString().split('/')[4] === 'rep-license-application' ?
                    <div className={row}>
                        <div className={col4}>
                            Has the list of shareholders changed?
                        </div>
                        {/*// check if it's operator declaration or license application*/}

                        <div className={col1}>
                            <RadioField bold capitalize name={'shareholder_has_changed'}
                                        placeholder={'Yes'}
                                        changeCallback={e => {
                                            this.setState({visible: true, counts: true});
                                        }}/>
                        </div>
                        <div className={col1}>
                            {/*<SubmitButton commandText={'No'} callback={e => {*/}
                            {/*                this.setState({visible: false, counts: false});*/}
                            {/*            }}/>*/}
                            <RadioField bold capitalize name={'shareholder_has_changed'}
                                        placeholder={'No'}
                                        changeCallback={e => {
                                            this.setState({visible: false, counts: false});
                                        }}/>
                        </div>
                    </div> :
                    <div className={row}>
                        {/*<div className={col6}>*/}
                        {/*    <span style={{color: '#800080', fontWeight: 700, fontSize: 14, textAlign: 'center'}}>*/}
                        {/*        How many shareholders do you have to declare?*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                        <div className={col6}>
                            <NumberField
                                // bold
                                // capitalize
                                // ref={this.rangeRef}
                                defaultValue={Object.getOwnPropertyNames(this.storage.LOCAL_BACKUP).includes('operator_shareholders') ?
                                    this.storage.LOCAL_BACKUP["operator_shareholders"].length : 1}
                                fontSize={16}
                                // bold
                                style={{color: "#800080", fontWeight: 500}}
                                name={'shareholders_number'}
                                // placeholder={'Number of directors/shareholders to declare'}
                                placeholder={'Input the number of shareholders to be declared as per your CR12'}
                                changeCallback={this.createShareHolderFieldSets}
                            />
                        </div>
                        <div className={col1}/>
                        {
                            this.state.e['operator_shareholders'] && this.state.e['operator_shareholders'].length > 0 &&
                            <div className={col4}>
                                <br/>
                                <br/>
                                <a href={'#'} onClick={e => {
                                    // clear shareholder data in redux and state.e
                                    Dispatch({type: NULL_BACKUP})
                                    this.setState(state => {
                                        delete (state.e['operator_shareholders']);
                                        delete (state.e['operator_individual_shareholders']);
                                        delete (state.e['operator_company_shareholders']);
                                        notify('Cleared existing declaration list of shareholders', 4, true);
                                        return state;
                                    }, this.createShareHolderFieldSets);

                                }
                                }><b>Clear existing shareholder data</b></a>
                            </div>
                        }
                    </div>
                // <NumberField
                //     bold
                //     capitalize
                //     name={'shareholders_number'}
                //     placeholder={'Number of shareholders to declare'}
                //     changeCallback={this.createShareHolderFieldSets}
                // />
            }
            {
                this.state.visible ?
                    <div className={row}>
                        <div className={col6}>
                            <NumberField
                                bold
                                // capitalize
                                // ref={this.rangeRef}
                                defaultValue={1}
                                name={'shareholders_number'}
                                placeholder={'Directors/Shareholders to declare'}
                                changeCallback={this.createShareHolderFieldSets}
                            />
                        </div>
                        <div className={col1}/>
                        {
                            this.state.e['operator_shareholders'] && this.state.e['operator_shareholders'].length > 0 &&
                            <div className={col4}>
                                <br/>
                                <br/>
                                <a href={'#'} onClick={e => {
                                    // clear shareholder data in redux and state.e
                                    Dispatch({type: NULL_BACKUP})
                                    this.setState(state => {
                                        delete (state.e['operator_shareholders']);
                                        delete (state.e['operator_individual_shareholders']);
                                        delete (state.e['operator_company_shareholders']);
                                        return state;
                                    }, () => this.createShareHolderFieldSets(1));
                                }
                                }><b>Clear existing shareholder data</b></a>
                            </div>
                        }
                    </div> : null
            }
            <SectionRow>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            {this.state.dataItem2}
                        </SectionColumn>
                    </SectionRow>
                    <div className={'space-row'}/>
                    {
                        this.props.separateDeclaration &&
                        <SectionRow>
                            <SectionColumn/>
                            <SectionColumn>
                                <SubmitButton
                                    commandText={'Declare'}
                                    callback={() => {
                                        if (Object.getOwnPropertyNames(this.state.e).includes('operator_shareholders')) {
                                            let x = this.state.e.operator_shareholders.length;
                                            this.state.e.operator_shareholders.map((shareholder, idx) => {
                                                let target_point = 'individual-shareholder';
                                                let shareholderData = shareholder;
                                                let fd = new FormData();
                                                // get the file object sh_kra_doc, sh_cogc_file, company_kra_doc
                                                // This is a individual shareholder document
                                                if (!Object.getOwnPropertyNames(shareholderData).includes('sh_first_name'))
                                                    target_point = 'company-shareholder';
                                                if (Object.getOwnPropertyNames(shareholderData).includes('sh_kra_doc')
                                                    || Object.getOwnPropertyNames(shareholderData).includes('sh_cogc_file')
                                                    || Object.getOwnPropertyNames(shareholderData).includes('sh_passport_doc')
                                                    || Object.getOwnPropertyNames(shareholderData).includes('shareholder_kra_tcc_file')) {
                                                    if (Object.getOwnPropertyNames(shareholderData).includes('sh_passport_doc'))
                                                        fd.append('shareholder_id_file', shareholderData['sh_passport_doc']);
                                                    if (Object.getOwnPropertyNames(shareholderData).includes('sh_kra_doc'))
                                                        fd.append('shareholder_kra_file', shareholderData['sh_kra_doc']);
                                                    if (Object.getOwnPropertyNames(shareholderData).includes('sh_cogc_file'))
                                                        fd.append('shareholder_cogc_file', shareholderData['sh_cogc_file']);
                                                    // if (Object.getOwnPropertyNames(shareholderData).includes('sh_passport_doc'))
                                                    //     fd.append('sh_passport_doc', shareholderData['sh_passport_doc']);
                                                    if (Object.getOwnPropertyNames(shareholderData).includes('shareholder_kra_tcc_file'))
                                                        fd.append('shareholder_kra_tcc_file', shareholderData['shareholder_kra_tcc_file']);
                                                    // append data to it and remove the two items
                                                    // remove files from the locally-buffered data
                                                    delete shareholderData['sh_kra_doc'];
                                                    delete shareholderData['sh_cogc_file'];
                                                    delete shareholderData['shareholder_kra_tcc_file'];
                                                    target_point = 'individual-shareholder';
                                                } else if (Object.getOwnPropertyNames(shareholderData).includes('company_kra_doc')
                                                    || Object.getOwnPropertyNames(shareholderData).includes('company_notarized_sh_doc')) {
                                                    if (shareholderData['company_kra_doc'] !== undefined)
                                                        fd.append('company_kra_file', shareholderData['company_kra_doc']);
                                                    if (shareholderData['company_notarized_sh_doc'] !== undefined)
                                                        fd.append('company_notarized_sh_doc', shareholderData['company_notarized_sh_doc']);
                                                    // delete shareholderData['company_kra_doc'];
                                                }
                                                // append data to it and remove the two items
                                                // remove files from the locally-buffered data
                                                fd.append('data', JSON.stringify(shareholderData));
                                                // upload shareholders with their documents
                                                this.sendRequest(
                                                    "post",
                                                    `company/operator/${target_point}`,
                                                    // the identity of the operator-registration that was created
                                                    response => {
                                                        if (response.type === 'Success') {
                                                            notify(response.message, response.priority, true);
                                                            if (x === 1 || idx === x - 1) {
                                                                this.nullPOSTData();
                                                                this.navigateTo('../shareholders-list');
                                                            }
                                                        } else {
                                                            // window.alert(x);
                                                            notify(response.message, response.priority, true);
                                                        }
                                                    }, fd // the formData with the files and shareholder data in there...
                                                );
                                                x += 1;

                                                // if (x === this.state.e.operator_shareholders.length) {
                                                //     this.nullPOSTData();
                                                //     this.navigateTo('../shareholders-list');
                                                // }
                                                this.nullPOSTData();
                                            });
                                        }
                                    }}
                                />
                            </SectionColumn>
                            {this.state.currentRoute}
                            <SectionColumn/>
                        </SectionRow>
                    }
                </SectionColumn>
            </SectionRow>
            <div className={'space-row'}/>
        </Section>;
    };
}


/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const
    mapStateToProps = (localStorageState) => {
        let p = {};
        const itemsOfInterest = ['gender', 'country', 'shareholder_type', 'LOCAL_BACKUP'];
        for (const item of itemsOfInterest) {
            p[item] = localStorageState[item];
        }
        return {...p};
    }
export default connect(mapStateToProps)(OperatorShareHoldersSection);
