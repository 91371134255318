import React, {Component} from "react";
import "./document-viewer-style.css";
import {col12, col4, notify, row} from "../../../MiscUtils";
import TextField from "../input-field/TextField";
import SelectField from "../input-field/SelectField";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
import SubmitButton from "../controls/buttons/SubmitButton";
import FileSelectField from "../input-field/FileSelectField";
import DocumentDetailsCard from "./DocumentDetailsCard";


/**
 *
 * Document viewer component provides a facility to view documents passed
 * to it through the 'documents' property.
 *
 * Properties in use:
 *  - documents {an array of arrays consisting of document names and base64 strings or paths}
 *  - withCurtain {a reference attribute whose presence determines whether the document viewer
 *  is drawn on a z-indexed div above all fields, or it inherits it's current caller's parent
 *  position. This is a-kin-to boolean true or false (only that it's implied).}
 *
 * The documents property has a structure like the one that follows:
 *  [[document_name:base64_document_string],...]
 *
 * In this component, the state will be heavily used to depict or host components_to_delete
 * being shown to the user but for a short duration.
 */
export default class DocumentUploader extends Component {
    constructor(props) {
        super(props);
        /*
         * This is the index whose document is currently being viewed
         */
        // this.thumbNailRef = React.createRef();// for rerendering ThumbNailList component
        // this.categoriesRef = React.createRef();// for resetting the categories selector
        this.currentDocumentIndex = 0;
        /*
         * makes sure that the document viewer has
         */
        this.withCurtain = props.withcurtain === undefined;
        this.closecallback = props.callback;
        this.uploadcallback = props.uploadcallback; //called by 'next' icon
        /**
         * This is used to label the kind of document that's being uploaded.
         * Its specific to a given operator-registration's or organization's structure of documents
         * @type {null|string[]|string}
         */
        this.docCategory = '';
        /*end documentCategoriesOptions*/
        this.documents = [];
        if (props.documents === undefined)
            console.warn(`The document viewer cannot list 0 documents. Use the 'documents' property to pass
            a array of JSON objects containing '{filename: document_blob_or_image_path_or_base64_string}'`);
        else this.documents = props.documents;
        /*
         * Hosts a list of documents that are currently known to this document
         * viewer.
         */
        //when a document list is passed, create thumbnails to represent these thumbnails of documents
        this.state = {
            documentsList: props.documents !== undefined ? props.documents : [],
            documentCategoriesOptions: this.props.categories !== undefined ? this.props.categories : [],
            renameTool: null,
            currentFileName: null,
            currentFile: null,
            fileType: null,
            fileObjects: [],
            totalFileSize: 0
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps: Readonly<P>, nextContext: any) => {
        nextProps['categories'] && this.setState({documentCategoriesOptions: [...nextProps.categories]})
    }
    /**
     *
     *
     *
     *
     */
    listDocuments = () => {
        let documents = [];
        let i = 0;
        //console.log('documents list ', this.state.documentsList)
        do {
            if (Object.getOwnPropertyNames(this.state.documentsList[i]).includes('file')) {
                documents.push(this.state.documentsList[i].file);
            }
            i += 1;
        } while (i < this.state.documentsList.length);
        this.setState({fileObjects: [...documents]});
    }

    /**
     *
     * @deprecated overriden by drawDocumentInViewer
     * sets the file-viewer with the filename of the currently viewed file
     * @param filename filename in question
     *
     */
    setTitleBarWithFileName = (filename) => {
        this.setState((state) => {
            state.currentFileName = filename;
            return state;
        });
    };
    /**
     * Removes the renameTool toolitem that shows ontop of the viewer.
     * This is done either through a command to effect the rename, the pressing
     * of the escape key, or clicking the close buttons.
     */
    removeRenameTool = () => {
        if (this.state.renameTool != null)
            this.setState((state) => {
                state.renameTool = null;
                return state;
            });
    };
    /**
     *
     * remove current file/document from list of document list.
     *
     */
    removeCurrentDocument = () => {
        let revisedDocuments = [];
        //iterate through the current state na get the document with the current filename
        for (const document of this.state.documentsList) {
            // documents list contains name, content and category. iterate and ask whether the current
            // document has this name.
            if (document.name !== this.state.currentFileName)
                revisedDocuments.push(document);
            // else break off
        }
        // nullify current state of documentsList then rewrite it with updated
        // documentsList, and select first document in the updated list.
        this.setState({documentsList: null}, () => {
            if (revisedDocuments.length > 0)
                this.setState({
                    documentsList: revisedDocuments,
                    currentFile: revisedDocuments[0].content,
                    currentFileName: revisedDocuments[0].name
                });
        });
    };

    /**
     *
     * Populates the selected file into a list of documents
     * @param base64FileString string
     * @param originalFileObject the file object that was converted to base64FileString
     *
     */
    populateFile = (base64FileString, originalFileObject) => {
        // pick the first item in the files selected
        let fileObject = {};
        let d = this.state.documentsList;
        fileObject = {
            name: this.state.currentFileName,
            content: originalFileObject,
            category: this.docCategory,
            fileContent: base64FileString
        };
        this.setState(state => {
            if (!state.documentsList.includes(fileObject)) {
                state.currentFile = base64FileString;
                d.push(fileObject);
            }
            return state;
        }, () => {
            this.setState({documentList: d});
        });
        if (this.docCategory !== null)
            this.categoriesRef.current.selectedIndex = 0;
    }

    /**
     *
     * Draw document in the document viewer.
     * @param documentName the document name to be reflected
     * in the title-bar
     *
     */
    drawDocumentInViewer = (documentName = String()) => {
        let cFile;
        let cFileType;
        let cFileName;
        let x = 0;
        do {
            //get the name and content in current viewer
            if (this.state.documentsList[x].name === documentName) {
                cFile = this.state.documentsList[x].fileContent; // file content as base64
                cFileName = this.state.documentsList[x].name;
                cFileType = this.state.documentsList[x].fileContent.split(';')[0].split(':')[1];
                // set state and abort
                this.setState({
                    currentFile: cFile,
                    currentFileType: cFileType,
                    currentFileName: cFileName
                });
                break;
            }
            x += 1;
        } while (x < this.state.documentsList);
        //update state with new items
    };

    /**
     * Method updates the state of the current tool that's being viewed
     */
    showRenameTool = () => {
        //window event listener for escape
        this.renameTool = (
            <div className={`${row} rename-tool`}>
                <div className={col12}>
                    <div className={`${row} rename-title`}>Rename current file...</div>
                    <div className={`${row}`}>
                        <div className={`${col12} rename-input-field-space`}>
                            <TextField bold fontSize={14}
                                       capitalize
                                       name={"file_rename"}
                                       onChange={this.rename}
                                       placeholder={"New File Name"}
                                       focus
                            />
                        </div>
                    </div>
                    <div className={row}>
                        <div className={col4}/>
                        <div className={col4}>
                            <button onClick={this.rename} className={"btn btn-primary"}>
                                rename
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
        this.setState((state) => {
            state.renameTool = this.renameTool;
            return state;
        });
    };
    /**
     * Provides a renaming operation when a document is double-clicked on the viewer
     * area.
     *
     * @param document_index the array index that this document has been viewed from.
     * @returns {boolean} whether the document has been renamed from its old name
     * to the new name
     */
    rename = (document_index = Number | this.currentDocumentIndex) => {
        let is_renamed = false;
        if (is_renamed) is_renamed = true;
        return is_renamed;
    };
    /**
     *
     * Calculate the total file sizes to upload.
     * This is just a convenience tool.
     *
     */
    calculateFileSizes = () => {
        // loop through all selected files and select the size attribute. Add it to this.state.totalFileSize, then
        // calculate the total bytes and bytesClass
        let i = 0;
        let bytes = 0;
        do {
            bytes += this.state.documentsList[i].file.size;
            i += 1;
        } while (i < this.state.documentsList.length)
        bytes = (bytes / 977).toFixed(3);
        const bytesClass = String(bytes).split('.')[0].length > 3 ? 'MB' : 'kB';
        this.setState({totalFileSize: `${bytes}${bytesClass}`}, () => {
        }); //console.log(this.state.totalFileSize));
    }
    /**
     *
     *Upload documents to remote server using account/upload/?obj=operator
     * @param documentsList The list to upload
     *
     */
    uploadDocument = (documentsList = this.state.documentsList) => {
        let i = 0;
        do {
            let d = new FormData();
            d.append(documentsList[i].category, documentsList[i].file);
            this.props.upload('post', `account/upload/?obj=${this.props.context === undefined ? 'operator' : this.props.context}`, outcome => {
                // return true if type is success
                notify(outcome.message, outcome.type === 'Fail' ? 2 : 4, outcome.type !== 'Fail', outcome.type === 'Fail' ? 'Document upload failed' : null);
                //console.log('document upload outcome ', outcome);
            }, d);
            i += 1;
        } while (i < documentsList.length);
    }

    render = () => {
        //console.log('this.state.file objects', this.state.fileObjects)
        return <Section boxedColour={'#3e95ff'}>
            <SectionTitle>
                Select documents for Upload
            </SectionTitle>
            <SectionRow boxedColour={'#3e95ff'}>
                <SectionColumn style={{width: '20vw'}}>
                    <b style={{color: 'rgba(87,83,83,0.62)', fontSize: 60, margin: '0 auto', textAlign: 'center'}}>
                        {
                            this.state.documentsList.length
                        }
                    </b>
                </SectionColumn>
                <SectionColumn>
                    <SelectField bold
                                 isRequired
                                 name={'doc_upload_category'}
                                 fontSize={14}
                                 placeholder={'Select a file category'}
                                 options={this.state.documentCategoriesOptions}
                                 changeCallback={category => {
                                     let categoryValues = [];
                                     const doc_upload_category = {category: category.target.options[category.target.selectedIndex].value};
                                     // populate the documentsList array
                                     if (this.state.documentsList.length === 0)
                                         this.setState({documentsList: [doc_upload_category]});
                                     else {
                                         let i = 0;
                                         do {
                                             if (Object.getOwnPropertyNames(this.state.documentsList[i]).includes('category')) {
                                                 categoryValues.push(this.state.documentsList[i].category);
                                             }
                                             i += 1;
                                         } while (i < this.state.documentsList.length);
                                         // check whether the current selected index value is in the categoryValues array. If not,
                                         // push the doc_upload_category object into documentsList
                                         if (!categoryValues.includes(category.target.options[category.target.selectedIndex].value)) {
                                             this.setState({documentsList: [...this.state.documentsList, doc_upload_category]});
                                         }
                                         // if included, negate or ignore.
                                         // check whether the doc_upload_cagetory.cagetory is in the documentsList
                                     }
                                 }}/>
                </SectionColumn>
                <SectionColumn>
                    <FileSelectField bold
                                     isRequired
                                     name={'file_uploads'}
                                     fontSize={14}
                                     placeholder={'File'}
                                     changeCallback={(e) => {
                                         if (this.state.documentsList.length === 0) {
                                             notify('A category of the file must be selected first before selecting the file', 2, false, 'File category not selected');
                                             e.preventDefault();
                                             return;
                                         }
                                         // populate the document into documentsList object
                                         let categorizedFileSelections = this.state.documentsList;
                                         let i = 0;
                                         do {
                                             // for each categorizedFileSelections, check if it consists of the file object
                                             if (!Object.getOwnPropertyNames(categorizedFileSelections[i]).includes('file')) {
                                                 categorizedFileSelections[i]['file'] = e.target.files[0];
                                             }
                                             i += 1;
                                         } while (i < categorizedFileSelections.length);
                                         // then create thumbnails for each,
                                         // allowing the removal of the documents from that list.
                                         // populate the file selected into this.state.documentsList
                                         this.setState({documentsList: [...categorizedFileSelections]}, () => {
                                             this.listDocuments();
                                             this.calculateFileSizes();
                                         });
                                     }}
                    />
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn/>
                <SectionColumn/>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn style={{color: '#171f2f', fontWeight: 600}}>
                            {this.state.totalFileSize}
                        </SectionColumn>

                        <SectionColumn>
                            <SubmitButton commandText={'upload'} callback={() => {
                                // send request to upload documents for this user with the categories specified, creating
                                // a form-data object and each an entry in accordance with each of the documentList entry.
                                // conduct uploads here
                                notify('Uploading documents...', 1, true)
                                this.uploadDocument();
                            }}/>
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    gridTemplateRows: 'repeat(2, 150px)',
                    gap: 20,
                    overflow: 'hidden',
                    overflowY: 'scroll'
                }}>
                    {this.state.fileObjects.length > 0 && <DocumentDetailsCard noDownload={this.props.noDownload}
                                                                               documentList={this.state.fileObjects}/>}
                </SectionColumn>
                {/*<SectionColumn boxedColour>*/}
                {/*    document viewer using a normal iframe viewer*/}
                {/*</SectionColumn>*/}
            </SectionRow>

            <SectionColumn>

            </SectionColumn>
        </Section>
    };
}
