import {Component} from "react";
import '../custom-views-css.css';

export class Section extends Component {
    constructor(props) {
        super(props);
        this.boxedStyle = props.boxedColour ? {
            border: `1px solid ${typeof props.boxedColour !== 'string' ? '#838588' : props.boxedColour}`,
            borderRadius: 4,
            margin: '4px'
        } : null
    }

    render = () => {
        const scrollableStyling = {
            overflow: 'hidden',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            height: this.props.scrollable ? this.props.scrollable : 120
        };
        const style = this.props.scrollable && scrollableStyling;
        const styling = this.props.noBoxShadow ? {boxShadow: 'none', ...style} : style;
        return <div onClick={this.props.onClick}
                    className={`section-item ${this.props.className ? this.props.className : ''}`}
                    style={{...styling, ...this.props.style, ...this.boxedStyle}}>
            {this.props.children}
            {
                this.props.tools ? <SectionRow id={this.props.id} onClick={this.props.onclick} style={this.props.style}>
                    {/*// provide a contextual menu for menu-items to show*/}
                </SectionRow> : null
            }
        </div>
    }
}

export class SectionRow extends Component {
    constructor(props) {
        super(props);
        // colours for border perimeter line:
        // CFCFD3FF very light grey
        //#838588 medium light grey
        this.boxedStyle = props.boxedColour ? {
            border: `1px solid ${typeof props.boxedColour !== 'string' ? '#CFCFD3FF' : props.boxedColour}`,
            borderRadius: 4,
            margin: '4px'
        } : null
    }

    render = () => {
        const scrollableStyling = {
            overflow: 'hidden',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            height: this.props.scrollable ? this.props.scrollable : 120
        };
        const styling = this.props.scrollable && scrollableStyling;
        //console.log('id is ', this.props.id)
        return <div id={this.props.id} onClick={this.props.onClick}
                    className={`section-item-row ${this.props.className ? this.props.className : ''}`}
                    style={{...styling, ...this.props.style, ...this.boxedStyle}}>
            {this.props.children}
        </div>
    }
}

export class SectionColumn extends Component {
    constructor(props) {
        super(props);
        this.boxedStyle = props.boxedColour ? {
            border: `1px solid ${typeof props.boxedColour !== 'string' ? '#838588' : props.boxedColour}`,
            borderRadius: 4,
            margin: '4px'
        } : null
    }

    render = () => {

        const styling = this.props.scrollable ? {
            overflow: 'hidden',
            overflowY: 'auto',
            // height: '79vh',
            scrollbarWidth: 'thin'
        } : null;
        return <div id={this.props.id} onClick={this.props.onClick}
                    className={`section-item-col ${this.props.className ? this.props.className : ''}`}
                    style={{...styling, ...this.props.style, ...this.boxedStyle}}>
            {this.props.children}
        </div>
    }
}

export class SectionTitle extends Component {
    render = () => {
        return <div id={this.props.id} onClick={this.props.onClick} className={'section-head'} style={this.props.style}>
            {this.props.children}
        </div>
    }
}