/**
 *
 * This should contain a historical analyses of this based on time-operator-view-sections.
 * It shows:
 *          OPTIONAL-PRIVILEGED: [operator-registration transactional records as per BCLB, targeted tax calculations etc]
 * Transactional data can provide a mechanism to select the appropriate time to view returns.
 * Fields concerning transactions include:
 *  - payment id
 *  - channel
 *  - amount
 *  - receipt no
 *  - date
 *  - from (phone number/account no)
 *
 * This transactions include
 *
 *
 */
import GMS from "../../../parent-component/GMS";
import {Section, SectionRow, SectionTitle} from "../../view-utils/SectionUtils";
import DynamicTable from "../../../general-components/tables/DynamicTable";

export default class TransactionsData extends GMS {
    componentDidMount = () => {
        // get all transactional data for this company
        // update random data with this data
    }

    getTransactionData = (from, to = null) => {
        // update random data with this
        // update url with the times selected
    }

    render = () => {
        return <Section>
            <SectionTitle>
                player engagement transactions
            </SectionTitle>
            <SectionRow>
                {/*<SectionColumn>*/}
                {this.state.miscellaneousItems ? <DynamicTable context={'transactions'}
                                                               tableData={this.state.miscellaneousItems}
                                                               showRowData={() => {
                                                                   // window.alert('show row data')
                                                               }}/> : 'no data on transactions'}
                {/*</SectionColumn>*/}
            </SectionRow>
        </Section>
    }
}