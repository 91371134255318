import React from 'react';
import {connect} from "react-redux";
import GMS from "../../../parent-component/GMS";
//import fields
import NatureFields from "./field-sets/NatureFields";
import JurisdictionAndAccessFields from "./field-sets/JurisdictionAndAccessFields";
import PrizesFields from "./field-sets/PrizesFields";
import {BCLB, LOCAL_BACKUP, NULL_BACKUP, ROUTEINDEX, SUBTITLE} from "../../../general-components/redux/allowed-actions";
import {Outlet, Route, Routes} from "react-router-dom";
import {extractValueFromJSON, notify, readFromLocalStorage} from "../../../../MiscUtils";
import Navigation from "../../../general-components/controls/svg-controls/Navigation";
import ApplicationDocumentsUploadFields from "./field-sets/ApplicationDocumentsUploadFields";
import CompleteLicenseApplication from "./field-sets/CompleteLicenseApplication";
import {Dispatch} from "../../../general-components/redux/app-storage";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../rotm-custom-views/view-utils/SectionUtils";

import '../../../rotm-custom-views/custom-views-css.css';
import EcitizenPaymentInitiator from "../../../general-features/eCitizen-integrator/EcitizenPaymentInitiator";
// import OddsProviderSection from "../company-declaration/company-collector-sections/OddsProviderSection";
// import OperatorEmployeeSection from "../company-declaration/company-collector-sections/OperatorEmployeeSection";

/**
 *
 * Provides a mechanism of applying for a license. Items needed to pre-fill
 * the license form for this agent (excluding the license application data that
 * can be considered redundant) are:
 *  1. company-registration name
 *  2. preceding license number
 *  3. company-registration jurisdiction
 *  4. current game application
 *  5. license types [so that choice of license or permit/promotion is made]
 *      5a. the instruments associated with this type of license and game application being made.
 *              Those selected as a result of the games selected during registration.
 *
 *
 */
class PermitApplication extends GMS {
    constructor(props) {
        super(props);
        // fetch from storage, operator[0]
        // null existing data in backup
        // Dispatch({type: NULL_BACKUP});
        //navigable components_to_delete are represented by text data
        this.navigableRoutes = [
            'permit-prerequisites',
            'jurisdiction-of-operation',
            'prizes-to-be-won',
            // 'shareholder-changes-declarations',
            'upload-supporting-documentation',
            // 'company-odds-providers',
            // 'company-employees',
            'complete-application',
            // 'pay-via-ecitizen'
        ];
        //
        this.currentSubmenuSelection = null;//for tracking the current submenu that's selected
        this.selectedClassName = "tab-menu-selected";//styling for a selected menu-item
        //create a reference for a div that's clicked when the page loads. (of course it's clicked
        // by the document on load...
    }

    /**
     *
     * Method makes available data set in local storage
     * to the component in order to build a OperatorSummary
     * @param nextProps properties containing the local backed-up data
     * @param nextContext irrelevant at this time
     * @constructor the instance field filledInData to be filled
     *
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            ...nextProps.county, // because this is already a JSON of the format {county:[array-of-jsons-representing-counties]}
            email: nextProps.email,
            variable: nextProps.variable !== null ? nextProps.variable : this.state.variable,
            runReasons: nextProps.run_reason, //reasons for running this game
            gameTypes: nextProps.game,
            miscellaneousItems: nextProps.LOCAL_BACKUP,
            applicationTypes: nextProps.application_type // list of application types
        }, () => {
            /*
            * populate various state run-time variables
             */
            // do not do anything if any of these is not yet back
            if (this.state.county === undefined ||
                // this.state.runReasons === undefined ||
                this.state.gameTypes === undefined ||
                this.state.applicationTypes === undefined)
                return;
            this.setState({
                promotion_url: nextProps.LOCAL_BACKUP.promotion_url,
                paybill_or_ussd_code: nextProps.LOCAL_BACKUP.paybill_or_ussd_code,
                jurisdiction: extractValueFromJSON(nextProps.LOCAL_BACKUP.jurisdiction_array, this.state.county),
                gameType: extractValueFromJSON(nextProps.LOCAL_BACKUP.game_type, this.state.gameTypes),
                applicationType: extractValueFromJSON(nextProps.LOCAL_BACKUP.application_type, this.state.applicationTypes),
            });
        });
    }

    /**
     *
     * Acquire data about jurisdictions, game types (according to selection that was made during
     * registration), causes (for which this game is running for) and
     * various formulae that are in use when selecting a winner for a game.
     *
     * NOTES:
     *      1. this.state.dataItem4 will host games in question (in context of the natures fields components_to_delete)
     *      2. this.state.dataItem3 will host formulae in question
     *      3. this.state.dataItem1 will host the cause for which this game in question runs for
     *      4. this.state.dataItem2 will host the jurisdictions this game is running in
     *
     */
    componentDidMount = () => {
        this.nullPOSTData();
        Dispatch({type: SUBTITLE, payload: 'Apply for a permit'});
        this.navigateTo('permit-prerequisites', false);
        // label the current tab as necessary
        this.switchSelection(document.getElementsByClassName(`_0`)[0], true);
        // do the check for company here
        this.sendRequest('get', 'company/operator', operator => {
            //console.log(operator)
            if (operator.type === 'Success') {
                if (operator.data.length === 0) {
                    notify('An  operator has not been declared! Close this message window then proceed to declare one...', 2,
                        false, 'No operator found!');
                    this.navigateTo('../declare-operator')
                } else {
                    //console.log('operator ', operator.data[0].status !== 'Active')
                    // operator.data[0].status !== 3 ||
                    if (operator.data[0].status === 'New') {
                        notify('The company you declared is currently being analysed. License application is therefore disabled.',
                            1, false, 'Operator is being evaluated');
                        window.setTimeout(() => this.navigateTo('../list-operators/'), 3500);
                    } else if (operator.data[0].status === 'Suspended') {
                        notify('The operator which you represent is currently suspended. Contact the board for further advice',
                            2, false, 'Operator Suspended!');
                        window.setTimeout(() => this.navigateTo('../list-operators/'), 3500);
                    } else if (operator.data[0].status === 'Inactive') {
                        notify('Operator is currently inactive. It has has been dormant for too long hence this state.' +
                            'You\'ll need to contact the board in order to have the operator re-activated.',
                            3, false, 'Operator deactivated!');
                        window.setTimeout(() => this.navigateTo('../list-operators/'), 3500);
                    } else if (operator.data[0].status === 'Defunct' || operator.data[0].status === 'Cancelled') {
                        notify('This company is defunct. It cannot be allowed to conduct business any longer.' +
                            'Contact the board on this matter',
                            2, false, 'Defunct operator!');
                        window.setTimeout(() => this.navigateTo('../list-operators/'), 3500);
                    }
                }
                // get the licenses
                this.sendRequest('get', 'company/operator/license/license', records => {
                    if (records.type === 'Success') {
                        if (records.data.length > 0) {
                            // get the permits
                            let k = 0;
                            records.data.map((record, index) => {
                                if (record['application_type'] === 'Permit' && record['status'] !== 'closed') {
                                    const promotionName = record.promotion_type.length > 0 ? ` - with the promotion name ${record.promotion_type}` : '';
                                    this.setState({
                                        visible: true,
                                        dataItem1: `You have one for ${record.jurisdiction}, currently in the ${record.status} stage ${promotionName}`
                                    }) // enable application
                                }
                            })
                        }
                    }
                    //
                })
            } else {
                notify(operator.message,
                    2, false, 'Problem getting operator information!');
                this.navigateTo('../list-operators');
            }
        });
        this.sendRequest("get", 'account/user', user => {
            //console.log(user)
            this.setState({randomData: user.data});
            // record the following fields:
            // first_name last_name surname - as one field
            // user_identification_id
            // phone_number clientMSISDN
            // email
            // Dispatch({type: OPERATOR, payload: operator.data});
        });
    }

    /**
     *
     * Allow navigation to the left
     *
     */
    navigateLeft = () => {
        if (this.state.currentRouteIndex < 1) {
            this.setState({showBackwards: false, showForwards: true}, () => {
                notify('End of navigation', 1, true);
            });
        } else {
            let routeIndex = this.state.currentRouteIndex - this.state.variable === 1 && this.state.currentRouteIndex === 3 ? 2 : 1;
            // navigate to the next url, and add 1 to the counter
            this.setState({
                showBackwards: true,
                currentRouteIndex: this.state.currentRouteIndex - 1
            }, () => {
                //console.log('LEFT current route index as after state update ', this.state.currentRouteIndex);
                Dispatch({type: ROUTEINDEX, payload: this.state.currentRouteIndex});
                this.navigateTo(this.navigableRoutes[this.state.currentRouteIndex], false);
                this.switchSelection(document.getElementsByClassName(`_${this.state.currentRouteIndex}`)[0]);
            });
        }
    }

    /**
     *
     * Allow navigation to the right
     *
     */
    navigateRight = () => {
        if (this.state.currentRouteIndex === this.navigableRoutes.length - 1) {
            this.setState({showBackwards: true, showForwards: false}, () => {
                notify('End of navigation', 1, true);
            });
        } else {
            // navigate to the next url, and add 1 to the counter
            //console.log('variable ', this.state.variable);
            let routeIndex = this.state.currentRouteIndex + this.state.variable === 1 && this.state.currentRouteIndex === 1 ? 2 : 1;
            this.setState({
                showForwards: true,
                currentRouteIndex: this.state.currentRouteIndex + 1
            }, () => {
                //console.log('RIGHT current route index as after state update ', this.state.currentRouteIndex);
                Dispatch({type: ROUTEINDEX, payload: this.state.currentRouteIndex});
                this.navigateTo(this.navigableRoutes[this.state.currentRouteIndex], false);
                this.switchSelection(document.getElementsByClassName(`_${this.state.currentRouteIndex}`)[0]);
            });
        }
    }
    render = () => {
        if (readFromLocalStorage(LOCAL_BACKUP, BCLB)['id'])
            Dispatch({type: NULL_BACKUP});
        // if not visible, return current else return a notification then navigate to permits applications
        // if the permit is issued, allow permit re-application else
        // if (this.state.visible) {
        //     notify(<span>Only one permit application and use is allowed. Wait until the current permit expires/runs its course!<br/><br/>{this.state.dataItem1}</span>, 1, false, 'Permit applications limit');
        //     // this.navigateTo('../license-permits-list');
        //     return <Navigate to={'../license-permits-list'}/>;
        // } else
        return (
            <Section>
                <SectionTitle>
                    Permit application process
                    <span style={{textTransform: 'none', color: 'rgba(8,111,211,0.75)'}}>
                        {this.props.sub_title}
                    </span>
                </SectionTitle>

                <SectionRow>
                    <SectionColumn className={'tab-menu _0'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_0`)[0]);
                        this.setState({currentRouteIndex: 0}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[0], false);
                    }}>
                        Nature of application
                    </SectionColumn>
                    <SectionColumn className={'tab-menu _1'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_1`)[0]);
                        this.setState({currentRouteIndex: 1}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[1], false);
                    }}>
                        Where to run
                    </SectionColumn>
                    <SectionColumn className={'tab-menu _2'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_2`)[0]);
                        this.setState({currentRouteIndex: 2}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[2], false);
                    }}>
                        prizes to award
                    </SectionColumn>

                    <SectionColumn className={'tab-menu _3'} onClick={(e) => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_3`)[0]);
                        this.setState({currentRouteIndex: 3}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[3], false);
                    }}>
                        Associated documents
                    </SectionColumn>

                    <SectionColumn className={'tab-menu _4'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_6`)[0]);
                        this.setState({currentRouteIndex: 4}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[4], false);
                    }}>
                        complete application
                    </SectionColumn>
                </SectionRow>
                <SectionRow/>
                {/*</SectionRow>*/}
                <Outlet/>
                {this.state.currentRoute}
                <SectionRow>
                    <Routes>
                        <Route element={<NatureFields applicationType={2}/>} // for licenses
                               path={'permit-prerequisites'}
                        />
                        <Route element={<JurisdictionAndAccessFields/>}
                               path={'jurisdiction-of-operation'}
                        />
                        <Route element={<PrizesFields applicable={this.state.variable}/>}
                               path={'prizes-to-be-won'}
                        />
                        <Route path={'upload-supporting-documentation'}
                               element={<ApplicationDocumentsUploadFields disableUpload bufferData={k => {
                                   this.setState({passableData: null}, () => {
                                       this.setState({passableData: k});
                                   });
                               }}/>
                               }
                        />
                        {/*<Route element={<OddsProviderSection applicable={this.state.variable}*/}
                        {/*                                     bufferStateData={this.props.bufferStateData}*/}
                        {/*                                     setMandatoryFields={this.setMandatoryFields}/>}*/}
                        {/*       path={'company-odds-providers'}/>*/}
                        {/*<Route element={<OperatorEmployeeSection applicable={this.state.variable}*/}
                        {/*                                         setMandatoryFields={this.setMandatoryFields}/>}*/}
                        {/*       path={'company-employees'}/>*/}
                        <Route element={<CompleteLicenseApplication bufferData={this.state.passableData}
                                                                    navigateToPayOnCompletion={this.navigateRight}/>}
                               path={'complete-application'}
                        />
                        <Route element={<EcitizenPaymentInitiator
                            service={readFromLocalStorage('game', BCLB)[readFromLocalStorage('LOCAL_BACKUP', BCLB).game_type]}
                            userIdentity={{
                                clientMSISDN: this.state.randomData && this.state.randomData[0].phone_number,
                                clientEmail: this.state.randomData && this.state.randomData[0].email,
                                clientName: this.state.randomData && `${this.state.randomData[0]['first_name']} ${this.state.randomData[0]['last_name']} ${this.state.randomData[0]['surname']}`,
                                clientIDNumber: this.state.randomData && this.state.randomData[0]['user_identification_id'] //'27136430'//
                            }}
                        />}
                               path={'pay-via-ecitizen'}
                        />
                    </Routes>
                </SectionRow>
                <SectionRow>
                    <Navigation showBackwards={this.state.currentRouteIndex > 0}
                                showForwards={this.state.currentRouteIndex < this.navigableRoutes.length - 1}
                                onNavigation={[this.navigateLeft, this.navigateRight]}
                                callback={() => {
                                }}/>
                    {/*<Navigation onNavigation={[this.navigateLeft, this.navigateRight]} callback={() => {}}/>*/}
                </SectionRow>
            </Section>
        )
    }
}

/**
 *
 * MapViewer all state that is recorded in local storage to the state of the current component.
 * Any class that wields this state (through inheritance) has access to the mapped
 * state from local-storage.
 *
 * @param localStorageState the state as recorded in the local storage
 * Do not connect the parent class because connected classes MUST NEVER BE SUBCLASSED!
 *
 */
    // load data from redux and populate it into the GMS state. Licensor is not used anywhere else
const
    mapStateToProps = (localStorageState) => {
        let props = {};
        try {
            props['variable'] = parseInt(localStorageState.LOCAL_BACKUP.application_type);
            props[LOCAL_BACKUP] = localStorageState.LOCAL_BACKUP;
        } catch (err) {
        }
        return props;
    }

// connect will return the GMS having been connected to the local storage.
// find a way for inheriting a connected component...
// learned from https://daveceddia.com/redux-connect-export/ (does not work!)
// this is a wrapped component with the GMS as part of it...
//
// Note: Connected components_to_delete MUST NEVER BE SUB-CLASSED!
//                      :: https://www.scrivito.com/connect-component-1c81c8d3b87493af
//
export default connect(mapStateToProps)(PermitApplication);//component must have a render method

