import React, {Component} from "react";
import {connect} from "react-redux";
import {Section, SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import TextField from "../../../../general-components/input-field/TextField";
import DateField from "../../../../general-components/input-field/DateField";
import SelectField from "../../../../general-components/input-field/SelectField";
import PhoneNumberField from "../../../../general-components/input-field/PhoneNumberField";
import EmailField from "../../../../general-components/input-field/EmailField";
import GMS from "../../../../parent-component/GMS";
import NumberField from "../../../../general-components/input-field/NumberField";

import {
    csvNotify,
    formatDate,
    getStorageObject,
    notify,
    parseCsv,
    readFromLocalStorage,
    serializeDataWithColumns
} from "../../../../../MiscUtils";
import {BCLB, LOCAL_BACKUP, NULL_BACKUP, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import BlurTabContents from "../../../floating-layout-views/blur/BlurTabContents";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import CheckBoxField from "../../../../general-components/input-field/CheckBoxField";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";
import FileSelectField from "../../../../general-components/input-field/FileSelectField";
import DynamicTable from "../../../../general-components/tables/DynamicTable";

class _EmployeeFieldSet extends Component {
    constructor(props) {
        super(props);
        // props available:
        // identifier that identifies the shareholder position in the overall count
        // viewOnly that helps toggle between view and list. Edit also avails
        // fieldData that populates the fieldset with its respective data. If data is present, viewOnly is ignored, and edit mode is off.
        // updateShareholderData that updates shareholding data
        // shareTypes a list of shareholder types
        // gender which is the list of genders
        // country which is the list of countries
        this.state = {
            fieldMode: false,
            companyFields: this.props.fieldData ? !!this.props.fieldData["sh_company_name"] : false,
            name: null,
            contact: null,
            position: null,
            fieldData: props.fieldData
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        // this.setState({fieldData:  null}, () => this.setState({fieldData: nextProps.fieldData}));
    }

    /**
     *
     * Enables toggle between edit mode and view mode.
     */
    toggleEdit = () => {
        this.setState({fieldMode: this.state.fieldMode});
    }

    componentDidMount = () => {
        this.setState({
            isNotKenya: this.props.fieldData && this.props.fieldData['work_permit_number'],
            fieldMode: !!this.props.data || this.props.userVisibleOnly
        });
    }
    render = () => {
        return <Section style={{boxShadow: '0 12px 8px -8px', border: "1px solid #c8c2ec"}}>
            <SectionRow>
                <SectionColumn style={{
                    // background: "#800080",
                    background: '#59788E',
                    color: '#FFFFFF',
                    fontWeight: 600,
                    fontSize: 14,
                    width: 64,
                    textAlign: "center",
                    borderRadius: 4
                }}>
                    {/*Employee &ensp; */}
                    {this.props.identifier}
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <CheckBoxField name={'is_contact_person'}
                                   bold
                                   fontSize={14}
                                   placeholder={'Check, if this employee is a contact person'}
                                   changeCallback={d => this.props.updateEmployeeData(d, this.props.identifier)}/>
                </SectionColumn>
                <SectionColumn/>
                <SectionColumn/>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               defaultValue={this.props.fieldData && this.props.fieldData['e_first_name']}
                               isRequired
                               name={"e_first_name"}
                               placeholder={"first name"}
                               changeCallback={(d) => {
                                   this.props.updateEmployeeData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               defaultValue={this.props.fieldData && this.props.fieldData['e_middle_name']}
                               isRequired
                               name={"e_middle_name"}
                               placeholder={"middle name"}
                               changeCallback={(d) => {
                                   this.props.updateEmployeeData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               defaultValue={this.props.fieldData && this.props.fieldData['e_surname']}
                               name={"e_surname"}
                               placeholder={"surname"}
                               changeCallback={(d) => {
                                   //d is the target returned as an argument by this component.
                                   // extract the name and value items
                                   this.props.updateEmployeeData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
            </SectionRow>
            {/*//second set of fields*/}
            <SectionRow>
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               defaultValue={this.props.fieldData && this.props.fieldData['e_pin_number']}
                               isRequired
                               name={"e_pin_number"}
                               placeholder={"Tax pin number"}
                               changeCallback={(d) => {
                                   //d is the target returned as an argument by this component.
                                   // extract the name and value items
                                   this.props.updateEmployeeData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <EmailField bold
                                capitalize
                                fontSize={14}
                                defaultValue={this.props.fieldData && this.props.fieldData['e_email']}
                                name={"e_email"}
                                placeholder={"email address"}
                                changeCallback={(d) => {
                                    //d is the target returned as an argument by this component.
                                    // extract the name and value items
                                    this.props.updateEmployeeData(d, this.props.identifier);
                                }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <PhoneNumberField bold
                                      fontSize={14}
                                      capitalize
                                      defaultValue={this.props.fieldData && this.props.fieldData['e_phone_number']}
                                      isRequired
                                      name={"e_phone_number"}
                                      placeholder={"phone number"}
                                      changeCallback={(d) => {
                                          //d is the target returned as an argument by this component.
                                          // extract the name and value items
                                          this.props.updateEmployeeData(d, this.props.identifier);
                                      }}
                    />
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <SelectField bold fontSize={14}
                                 capitalize
                                 isRequired
                                 defaultValue={this.props.fieldData && this.props.fieldData['e_nationality']}
                                 options={this.props.country ? this.props.country : []}
                                 name={"e_nationality"}
                                 placeholder={"nationality"}
                                 changeCallback={d => {
                                     //d is the target returned as an argument by this component.
                                     // extract the name and value items
                                     this.props.updateEmployeeData(d, this.props.identifier);
                                     // if option is other, update the state and if it's 3
                                     this.setState({
                                         isNotKenya: d.target.options[d.target.selectedIndex].textContent !== "Kenya",
                                         nationality: d.target.options[d.target.selectedIndex].textContent
                                     });
                                 }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               defaultValue={this.props.fieldData && this.props.fieldData['e_national_id']}
                               isRequired
                               name={"e_national_id"}
                               placeholder={"Kenyan ID/Alien ID/Passport"}
                               changeCallback={(d) => {
                                   //d is the target returned as an argument by this component.
                                   // extract the name and value items
                                   this.props.updateEmployeeData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <SelectField bold fontSize={14}
                                 capitalize
                                 defaultValue={this.props.fieldData && this.props.fieldData['gender']}
                                 options={this.props.genderList ? this.props.genderList : []}
                                 name={'gender'}
                                 placeholder={'gender'}
                                 changeCallback={(d) => {
                                     this.props.updateEmployeeData(d, this.props.identifier);
                                 }}/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                {
                    // dont know whats goin on here lakini inafanya...
                    this.state.isNotKenya ?
                        <SectionColumn>
                            {/*// this field is optional*/}
                            <TextField bold fontSize={14}
                                       capitalize
                                       isRequired
                                       defaultValue={this.props.fieldData && this.props.fieldData['work_permit_number']}
                                       name={'work_permit_number'}
                                       placeholder={'work permit number'}
                                       changeCallback={(d) => {
                                           this.props.updateEmployeeData(d, this.props.identifier);
                                       }}
                            />
                        </SectionColumn> : null
                }
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               isRequired
                               defaultValue={this.props.fieldData && this.props.fieldData['e_position']}
                               name={"e_position"}
                               placeholder={"position at this company"}
                               changeCallback={(d) => {
                                   this.props.updateEmployeeData(d, this.props.identifier);
                               }}
                    />
                    {/*<SelectField bold fontSize={14}*/}
                    {/*             capitalize*/}
                    {/*             isRequired*/}
                    {/*             defaultValue={this.props.fieldData && this.props.fieldData['e_position']}*/}
                    {/*             name={"e_position"}*/}
                    {/*             placeholder={"position at this company"}*/}
                    {/*             options={this.props.companyPositions ? this.props.companyPositions : []}*/}
                    {/*             changeCallback={(d) => {*/}
                    {/*                 this.props.updateEmployeeData(d, this.props.identifier);*/}
                    {/*             }}*/}
                    {/*/>*/}
                </SectionColumn>
                <SectionColumn>
                    <DateField bold
                               onlyPastDates
                               fontSize={14}
                               defaultValue={this.props.fieldData && this.props.fieldData['date_admitted']}
                               name={"date_admitted"}
                               placeholder={"when did employment start?"}
                               changeCallback={(d) => {
                                   this.props.updateEmployeeData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
                {/*{*/}
                {/*    this.state.dataItem4 ?*/}
                {/*        <SectionColumn>*/}
                {/*            <SelectField bold fontSize={14}*/}
                {/*                         capitalize*/}
                {/*                         isRequired*/}
                {/*                         name={'e_station'}*/}
                {/*                         options={this.state.dataItem4 ? this.state.dataItem4 : []}*/}
                {/*                         placeholder={'stationed at?'}*/}
                {/*                         changeCallback={(d) => {*/}
                {/*                             this.props.updateEmployeeData(d, this.employeeCountId).then(r => {*/}
                {/*                                 Dispatch({*/}
                {/*                                     type: LOCAL_BACKUP,*/}
                {/*                                     payload: this.state.e*/}
                {/*                                 });*/}
                {/*                             });*/}
                {/*                         }}*/}
                {/*            />*/}
                {/*        </SectionColumn> :*/}
                {/*        null*/}
                {/*}*/}
            </SectionRow>
        </Section>


    }
}

/**
 *
 * Collect employee data
 *
 */
class OperatorEmployeeSection extends GMS {
    constructor(props) {
        super(props);
        //callback methods
        // this.rangeRef = React.createRef();
        /**
         * a colour dictionary that's used to mark the background of a given fieldset or a bar on the left of that fieldset...
         * @type {{red: string, orange: string, green: string, blue: string, grey: string}} hex codes for various shades of the colours
         *          listed.
         * Note: this object is no-longer useful on account of it being proposed for move to an alternate component specifically
         * designed to handle this extra data regarding to nesting of shareholders of a shareholding operator-registration.
         *
         */
        this.allowedDistinguishingColours = {
            grey: "#d3d3d8",
            green: "#D1E3C1FF",
            blue: "#B8CBF4FF",
            red: "#F4B8BEFF",
            orange: "#F4E8B8FF",
        };
        // Fields used to serialize all the employee data, and prepare it for table viewing and sending to the backend.
        this.serializingFields = [
            'is_contact_person',
            'e_first_name',
            'e_middle_name',
            'e_surname',
            'e_pin_number',
            'e_email',
            'e_phone_number',
            'e_nationality_text',
            'e_national_id',
            'gender_text',
            'work_permit_number',
            'e_position',
            'date_joined'
        ];
        this.showableFields = [
            'contact_person',
            'first_name',
            'middle_name',
            'surname',
            'pin_number',
            'email',
            'phone_number',
            'nationality',
            'national_id/passport',
            'gender',
            'wp_number',
            'position',
            'date_joined'
        ];

        this.csvFieldsLine = 'IS CONTACT PERSON (yes/no),FIRST NAME,MIDDLE NAME,SURNAME,TAX NUMBER,EMAIL,PHONE NUMBER (THEIR PERSONAL NUMBER),COUNTRY,NATIONAL ID NUMBER/PASSPORT,GENDER (male/female),WORK PERMIT NUMBER (default 0),POSITION IN COMPANY,DATE OF APPOINTMENT';
        this.coloursInUse = []; //update this variable when a colour is picked from the list of allowedDistinguishingColours
        ///////////////////////////////////////
        ///////////////////////////////////////
        // get the outlet names
        this.premises = readFromLocalStorage(LOCAL_BACKUP, BCLB).premises;
        this.storage = getStorageObject(BCLB);
        let shopNames = [];
        if (this.premises) {

            // loop through the outlets and take within that object, the shop name "shop_name".
            // Also indicate the shop id as the current index count + 1
            let i = 0;
            do {
                const d = {};
                d[i + 1] = this.premises[i]['shop_name'];
                //console.log(d, this.premises[i], Object.getOwnPropertyNames(this.premises))
                shopNames.push(d);
                i += 1;
            } while (i < this.premises.length)
            this.premises = shopNames;
        }
    }

    // /**
    //  *
    //  * This is a counter viewer. The viewer updates its numbers when some data
    //  * is detected inside state.dataItem1
    //  *
    //  * @returns {JSX.Element} a viewer consisting of shareholder data. those filled in
    //  *                          vs those that were selected.
    //  */
    // employeeCounterView = () => {
    //     return (
    //         <div className={`${container} separate-contextual-viewer`}>
    //             <div className={row}>
    //                 <div className={`${col12} highlighted-text corner-numbers-viewer`}>
    //                     <div className={"space-row"}/>
    //                     <div className={row}>
    //                         <div className={col12}>
    //                             {
    //                                 Object.getOwnPropertyNames(this.state.e).includes("operator_employees") ?
    //                                     (this.state.e.operator_employees.length === 0 ? (0) :
    //                                         (<span>{this.state.e.operator_employees.length}</span>)) :
    //                                     (0)
    //                             }
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className={row}>
    //                 <div
    //                     className={`${col12} inverse-highlighted-text inverse-corner-numbers-viewer`}
    //                 >
    //                     {this.state.dataItem2.length === 0 ? null : (
    //                         <span>{this.state.dataItem2.length}</span>
    //                     )}
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
    /**
     *
     * update state with necessary component data from this component's props
     * @param nextProps
     * @param nextContext
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {

        this.setState({
                e: nextProps.LOCAL_BACKUP,
                countries: nextProps.country,
                genderList: nextProps.gender,
                dataItem5: nextProps.applicable,
                miscellaneousItems: nextProps.company_positions,
                dataItem3: nextProps.shareholder_type === undefined ?
                    [] : nextProps.shareholder_type, // used universally for multiple items
                // jwt: nextProps.jwt -> not needed here
            },
            //     () => {
            //     // check for locations by receiving state data
            //     if (this.state.e['operator_shops']) {
            //         // iterate through them and update dataItem4 with the names of the stations, as well as
            //         let y = 0;
            //         let premiseNames = [];
            //         do {
            //             let premiseOption = {}
            //             premiseOption[this.state.e['operator_shops'][y]['name'] ? this.state.e['operator_shops'][y]['name'] : y] =
            //                 this.state.e['operator_shops'][y]['name'] ? this.state.e['operator_shops'][y]['name'] : `premise no ${y + 1}`;
            //             premiseNames.push(premiseOption)
            //             y += 1;
            //         } while (y < this.state.e['operator_shops'].length);
            //         this.setState({dataItem4: [...premiseNames]});
            //     }
            // }
        );
    }
    // /**
    //  *
    //  * updates the maximum allowed employees available from the dataItem1 array of employee data
    //  * @param maxAllowed Integer representing the maximum number of employees required.
    //  *
    //  */
    // updateMaximumAllowedEmployeeData = (
    //     maxAllowed = this.state.e.operator_employees.length
    // ) => {
    //     const fieldSetsToRemove = this.state.e.operator_employees.length - maxAllowed : max;
    //     let shareholderItems = this.state.e.operator_employees;
    //     this.setState((state) => {
    //         state.e.operator_employees = null;
    //         return state;
    //     }, () => {
    //         let y = 1;
    //         //pop items from shareholderItems
    //         while (y <= fieldSetsToRemove) {
    //             shareholderItems.pop();
    //             y += 1;
    //         }
    //         //there is nothing to remove if the maximum allowed is what should be removed.
    //         // By default,
    //         this.setState((state) => {
    //             state.e.operator_employees = shareholderItems; // new list of items after pops
    //             return state;
    //         });
    //     });
    // };


    updateEmployeeData = (employeeFieldReference, belongingTo = 0) => {
        let employeeField = {}; //this is just a pre-construction for new elements within the array
        // check if the current field name exists in state e operator_shareholders array elements for the belongingTo attribute
        employeeField[employeeFieldReference.target.name] = employeeFieldReference.target.type === "checkbox"
            ? employeeFieldReference.target.checked : employeeFieldReference.target.type === 'file'
                ? employeeFieldReference.target.files[0] : employeeFieldReference.target.type === 'date' ?
                    formatDate(employeeFieldReference.target.value, 'ymd', 'dmy', '-')
                    : employeeFieldReference.target.value;
        if (employeeFieldReference) {
            // append it to operatorshareholders
            let emP = [];
            if (this.state.e['operator_employees']) {
                emP = this.state.e['operator_employees'];
                try {
                    if (!Object.getOwnPropertyNames(emP[belongingTo - 1]).includes('is_contact_person'))
                        emP[belongingTo - 1]['is_contact_person'] = false;
                    else
                        emP[belongingTo - 1][employeeFieldReference.target.name] = employeeField[employeeFieldReference.target.name];
                    //console.log("sh belonging to is ", sh, " belongingTo ", belongingTo)
                } catch (newRecordCountFound) {
                    emP.push(employeeField);
                    // sh[0][employeeFieldReference.target.name] = shareHolderField;
                }
            } else emP.push(employeeField)
            // update state then sort out the classes of shareholders
            // check the shareholder type. If this field is in the state.e object, update it else create one.
            // check if this is the first shareholder director

            // this is the first one
            this.setState(state => {
                state.e['operator_employees'] = emP;
                return state;
            });
        }

        if (this.state.e['operator_employees'])
            if (belongingTo > this.state.e['operator_employees'].length) {
                notify('You must start with the first employee when filling in their field data',
                    2,
                    false,
                    'Employee sequencing error!');
                return false;
            }
        // compile the data into operator_shareholders
        return true;
    };
    /**
     *
     * creates a fieldset of the shareholder's data collection fields.
     * @returns {JSX.Element} a bootstrap-styled row consisting of the fields in question.
     *
     */
    employeeFieldSet = (identifier = null, fieldData = {}, background = "#FFFFFF") => {
        //look for a way to update data about fields dynamically.
        //these numbers should be
        return <_EmployeeFieldSet identifier={identifier}
                                  country={this.state.countries}
                                  genderList={this.state.genderList}
                                  companyPositions={this.state.miscellaneousItems}
                                  stations={this.state.dataItem4}
                                  employeeCountId={identifier}
                                  fieldData={fieldData}
                                  updateEmployeeData={this.updateEmployeeData}
        />
    }
    /**
     *
     * Create employee field sets based on selection of number of employees
     * @param rangeReference
     * @returns {*[]}
     *
     */
    createEmployeeFieldSets = (rangeReference = HTMLElement | Component | Number) => {
        let p = [];
        let x = 1;
        let numberOfFieldSets = 0;
        // rangeReference.current.target.value;
        //console.log(numberOfFieldSets)
        if (Object.getOwnPropertyNames(this.storage.LOCAL_BACKUP).includes("operator_employees"))
            numberOfFieldSets = this.storage.LOCAL_BACKUP["operator_employees"].length;
        else if (rangeReference instanceof Object)
            // window.alert(rangeReference)
            numberOfFieldSets = rangeReference.target.value;
        if (numberOfFieldSets > 0) {
            if (rangeReference instanceof Object) {
                let r = parseInt(rangeReference.target.value);
                if (r < numberOfFieldSets) // if it's a number
                {
                    notify('Minimum number of employees are already short-listed! ' +
                        'You cannot decrease the number unless you clear the whole list and short-list again',
                        3, true);
                    return false;
                } else
                    numberOfFieldSets = rangeReference instanceof Object ? rangeReference.target.value : rangeReference;
            }
        }
        do {
            //console.log('do while loop ', x, this.storage.LOCAL_BACKUP['operator_employees'][x - 1])
            const fieldSet = <_EmployeeFieldSet identifier={x}
                                                country={this.state.countries}
                                                genderList={this.state.genderList}
                                                companyPositions={this.state.miscellaneousItems}
                                                stations={this.state.dataItem4}
                                                employeeCountId={x}
                                                fieldData={this.storage.LOCAL_BACKUP['operator_employees'] ?
                                                    this.storage.LOCAL_BACKUP['operator_employees'][x - 1] : {}}
                                                updateEmployeeData={this.updateEmployeeData}
            />;
            // const fieldSet = this.employeeFieldSet(x);
            if (!p.includes(fieldSet))
                p.push(fieldSet);
            //console.log(x, numberOfFieldSets)
            x += 1;
        } while (x <= numberOfFieldSets)

        this.setState((state) => {
            state.dataItem2 = [...p];
            return state;
        });
        return p;
    }
    /**
     *
     * Data concerning where they are stationed/employed
     *
     */
    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: `Declare employees`});
        let storage = getStorageObject(BCLB);
        this.setState({
            e: storage.LOCAL_BACKUP,
            countries: storage.country,
            genderList: storage.gender,
            miscellaneousItems: storage.company_positions,
            dataItem5: this.props.applicable,
            dataItem3: storage.shareholder_type === undefined ?
                [] : storage.shareholder_type, // used universally for multiple items

        }, this.createEmployeeFieldSets)
        // let k = this.storage.LOCAL_BACKUP['operator_employees'] ? this.storage.LOCAL_BACKUP['operator_employees'].length > 0 ?
        //     this.createEmployeeFieldSets(this.storage.LOCAL_BACKUP['operator_employees']) :
        //     <span>No employees declared</span> : <span/>;
        //
        // this.setState({
        //     // e: this.storage.LOCAL_BACKUP,
        //     countries: this.storage.country,
        //     genderList: this.storage.gender,
        //     miscellaneousItems: this.storage.company_positions,
        //     dataItem2: k,
        //     dataItem4: this.premises ?
        //         this.premises.constructor.name === [].constructor.name ? this.premises : [] : [],
        //     dataItem3: this.storage.shareholder_type === undefined ?
        //         [] : this.storage.shareholder_type, // used universally for multiple items
        //     // jwt: nextProps.jwt -> not needed here
        // });

        // this.props.setMandatoryFields([
        //     'e_first_name',
        //     'e_middle_name',
        //     'e_surname',
        //     'e_pin_number',
        //     'e_shares',
        //     'e_type',
        //     'e_nationality',
        //     'e_email',
        //     'e_phone_number',
        //     'e_national_id',
        //     'e_position',
        //     'e_date_admitted',
        //     'e_station'
        // ]);
        // this.createEmployeeFieldSets();
    }


    render = () => {
        let ui = (
                <Section boxedColour>
                    <div className={'small-space-row'}/>
                    <SectionRow boxedColour>
                        {this.state.currentRoute}
                        <SectionColumn>
                            <SimpleLabel text={'How do you wish to declare your employees?'}
                                         fontSize={18}
                                         bold/>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow boxedColour>
                        <SectionColumn/>
                        <SectionColumn>
                            <a href={'#'}
                               onClick={
                                   () => {
                                       // switch visible to true
                                       //switch variable to false
                                       this.setState({variable: false, visible: true});
                                       Dispatch({type: SUBTITLE, payload: 'Declare employees by CSV file upload'});
                                   }}>By CSV upload</a>
                        </SectionColumn>
                        <SectionColumn>
                            <a href={'#'}
                               onClick={
                                   () => {
                                       // switch variable to true
                                       // switch visible to false
                                       this.setState({variable: true, visible: false});
                                       Dispatch({type: SUBTITLE, payload: 'Declare employees by manual declaration'});
                                   }}>
                                By Manual declaration
                            </a>
                        </SectionColumn>
                        <SectionColumn/>
                    </SectionRow>
                    {
                        this.state.visible === true && this.state.variable === false &&
                        <SectionRow>
                            <SectionColumn>
                                <SectionRow>
                                    <SectionColumn>
                                        In this section, you can upload a list of all your employees by populating a csv
                                        file
                                        then uploading it into GMS.
                                        <br/>
                                        Download and use the template below, fill it with your employee information
                                        according to the format within, then upload it.
                                        <br/>
                                    </SectionColumn>
                                </SectionRow>
                                <ol>
                                    <li>
                                        <SectionRow>
                                            <SectionColumn>
                                                <span><b>Ensure</b> that <b>the entire file (ie, all the cells) MUST USE THE TEXT FORMAT</b>.</span>
                                            </SectionColumn>
                                        </SectionRow>
                                    </li>
                                    <li>
                                        <SectionRow>
                                            <SectionColumn>
                                                <a href={`../downloads/employee_template.csv`}
                                                   download={`${this.props.operator[0].company_name}-employees.csv`}>
                                                    Download the employee CSV template
                                                </a>
                                            </SectionColumn>
                                        </SectionRow>
                                    </li>
                                    <li>
                                        <SectionRow>
                                            <SectionColumn>
                                                <span>
                                        <FileSelectField name={'employee_csv'}
                                            // bold
                                                         fontSize={16}
                                                         isRequired
                                                         placeholder={'Select CSV containing the employee list'}
                                                         changeCallback={e => {

                                                             let resetArray = [];
                                                             this.setState({
                                                                 dataItem6: [...resetArray],
                                                                 miscField: e.target.files[0]
                                                             }, () => {
                                                                 // a custom wrapper for notify in order to allow
                                                                 // specific calls to the notifier about errors
                                                                 // in csv file.
                                                                 // const reg = new RegExp(/^[0-3]{0,1}[0-9]{0,1}-[0-1]{0,1}[1-2]{1}|[1-9]{1}-[1]{}$/);

                                                                 // parse the csv file
                                                                 parseCsv(e.target.files[0], fileData => {
                                                                     // get rid of the last character in fileData items
                                                                     let p = [];
                                                                     let q = [];
                                                                     const invalidateArray = [];
                                                                     let i = 0;
                                                                     let cont = false;
                                                                     fileData = fileData.split('\n');
                                                                     // console.log(fileData.length)
                                                                     do {
                                                                         if (fileData[i].length === 0 || fileData[i] === '\n')
                                                                             break;
                                                                         let fd = fileData[i];
                                                                         if (fd !== this.csvFieldsLine) {
                                                                             try {
                                                                                 // fd = fd.replace('\n', '');
                                                                                 fd = fd.replace('\t', '');
                                                                                 let r = fd.split(',');
                                                                                 q.push(fd.split(','));
                                                                                 r.map((item, index) => {
                                                                                     if (index === 0) {
                                                                                         // console.log(item, ['yes', 'no'].includes(item.toLowerCase()))
                                                                                         if (['yes', 'no'].includes(item.toLowerCase())) {
                                                                                             r[index] = item.toLowerCase() === 'yes';
                                                                                             cont = true;
                                                                                         }
                                                                                         // console.log('index at ', index, cont)
                                                                                     } else if (index === 9) {
                                                                                         if (['male', 'female'].includes(item.toLowerCase())) {
                                                                                             r[index] = item.toLowerCase() === 'male' ? 1 : 2;
                                                                                             cont = true;
                                                                                         }
                                                                                         // console.log('index at ', index, cont)
                                                                                     } else if (index === 7) {
                                                                                         // kama ni kenyan, 10 ni 0
                                                                                         if (item.toLowerCase() === 'kenyan' || item.toLowerCase() === 'kenya')
                                                                                             r[index] = '0';
                                                                                     } else if (index === 12) {
                                                                                         // parse the date into milliseconds
                                                                                         // thereafter, check whether the result is Not a Number
                                                                                         // if it returns false, that is a valid date else it is not.
                                                                                         // [NaN returns true if a value is not a number else false]
                                                                                         // console.log(!isNaN(Date.parse(item)))
                                                                                         if (item.indexOf('/') > 0)
                                                                                             item.replace('/', '-');
                                                                                         const k = formatDate(item, 'dmy', 'ymd', '-');
                                                                                         cont = !isNaN(Date.parse(k));
                                                                                         // console.log(item)
                                                                                         r[index] = formatDate(item, 'dmy', 'ymd', '-');
                                                                                         // console.log(r[index]);
                                                                                     }
                                                                                 })
                                                                                 // console.log(r)
                                                                                 if (cont === true) {
                                                                                     p.push(r);
                                                                                 } else {
                                                                                     csvNotify(fd, i);
                                                                                 }
                                                                             } catch (InvalidString) {
                                                                                 // console.log('invalid string ', InvalidString)
                                                                                 csvNotify(fd, i);
                                                                                 this.setState({
                                                                                     dataItem6: [...invalidateArray],
                                                                                     dataItem4: [...invalidateArray]
                                                                                 }, () => {
                                                                                     return;
                                                                                 });
                                                                                 // break;
                                                                             }
                                                                         }

                                                                         i += 1;
                                                                     } while (i < fileData.length);
                                                                     // serialize them
                                                                     // console.log('p[0] is ', p[0]);
                                                                     const tableData = serializeDataWithColumns(this.showableFields, q);
                                                                     const serializedData = serializeDataWithColumns(this.serializingFields, p);
                                                                     this.setState({
                                                                         dataItem6: [...tableData],
                                                                         dataItem4: [...serializedData]
                                                                     });
                                                                 });
                                                             });

                                                         }}/>
                                                    {
                                                        this.state.dataItem6.length > 0 &&
                                                        <SimpleLabel
                                                            text={`Employees found: ${this.state.dataItem6.length}`}/>
                                                    }
                                    </span>
                                            </SectionColumn>
                                            <SectionColumn/>
                                            <SectionColumn>
                                                {
                                                    this.state.dataItem4.length > 0 &&
                                                    <SubmitButton commandText={'Upload'} callback={() => {
                                                        // send file then send request with all employees
                                                        // let fd = new FormData();
                                                        // fd.append
                                                        // this.sendRequest('post', 'account/upload',response => {}, {});
                                                        let u = new FormData();
                                                        u.append('1', this.state.miscField);
                                                        this.sendRequest('post',
                                                            'account/upload/?obj=employees',
                                                            response => {
                                                                if (response.type === 'Success') {
                                                                    this.sendRequest('post', 'company/operator/employees',
                                                                        response => {
                                                                            if (response.type === 'Success') {
                                                                                notify(`Declared ${this.state.dataItem6.length} employees`, 4, false, 'Employees declared');
                                                                                window.setTimeout(() => {
                                                                                    this.navigateTo('../list-employees');
                                                                                }, 1800);
                                                                            } else
                                                                                notify(`Cannot do an employee upload. The error reported was: ${response.message}`,
                                                                                    2,
                                                                                    false,
                                                                                    `${this.state.dataItem6.length} employees not uploaded!`);
                                                                        }, this.state.dataItem4);
                                                                }

                                                            }, u);
                                                    }}/>
                                                }
                                            </SectionColumn>

                                        </SectionRow>
                                    </li>
                                </ol>
                                {
                                    this.state.dataItem6.length > 0 &&
                                    <SectionRow>
                                        <SectionColumn>
                                            <DynamicTable
                                                noSearch
                                                context={'Employees uploaded'}
                                                tableData={this.state.dataItem6}
                                                // columnsOfInterest={this.showableFields}
                                                columnsOfInterest={[
                                                    'first_name',
                                                    'middle_name',
                                                    'pin_number',
                                                    // 'gender',
                                                    'nationality',
                                                    'national_id/passport',
                                                    'date_joined',
                                                    'contact_person',
                                                ]}
                                                pageSize={24}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                }
                            </SectionColumn>
                        </SectionRow>
                    }
                    {
                        this.state.variable === true && this.state.visible === false &&
                        <SectionRow>
                            <SectionColumn>
                                <SectionRow>
                                    <SectionColumn>
                                        <NumberField
                                            bold
                                            fontSize={14}
                                            defaultValue={Object.getOwnPropertyNames(this.storage.LOCAL_BACKUP).includes('operator_employees') ?
                                                this.storage.LOCAL_BACKUP["operator_employees"].length : 1}
                                            capitalize
                                            name={'shareholders_number'}
                                            placeholder={'How many employees do you wish to declare?'}
                                            changeCallback={this.createEmployeeFieldSets}
                                        />
                                    </SectionColumn>
                                    <SectionColumn/>
                                    {
                                        this.state.e["operator_employees"] ? this.state.e['operator_employees'].length > 0 &&
                                            <SectionColumn>
                                                <br/>
                                                <br/>
                                                <a href={'#'}
                                                   onClick={e => {
                                                       // clear shareholder data in redux and state.e
                                                       this.setState(state => {
                                                           delete (state.e['operator_employees']);
                                                           notify('Cleared employee data', 4, true);
                                                           return state;
                                                       }, this.createEmployeeFieldSets);

                                                   }
                                                   }><b>Clear existing
                                                    employee
                                                    data</b></a>
                                            </SectionColumn> : null
                                    }
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn style={{
                                        fontWeight: 500,
                                        fontSize: 16,
                                        color: "#800080"
                                    }}>
                                        Fill in the details of
                                        employees listed.
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow style={{padding: 24}}
                                            scrollable={'69vh'}>
                                    <SectionColumn>
                                        {this.state.dataItem2}
                                    </SectionColumn>
                                </SectionRow>
                                <div className={'space-row'}/>
                                {
                                    this.props.separateDeclaration &&
                                    <SectionRow>
                                        <SectionColumn/>
                                        <SectionColumn>
                                            <SubmitButton
                                                commandText={'Declare'}
                                                callback={() => {
                                                    let x = 0;
                                                    this.sendRequest(
                                                        "post",
                                                        `company/operator/employees`,
                                                        // the identity of the operator-registration that was created
                                                        response => {
                                                            //console.log(response)
                                                            if (response.type === 'Success') {
                                                                notify(response.message, response.priority, true);
                                                                this.navigateTo('../list-employees');
                                                                // null state in order to prevent backing up this data upon sending
                                                                let nullState = {};
                                                                this.setState({e: {...nullState}});
                                                                Dispatch({type: NULL_BACKUP});
                                                            } else {
                                                                // window.alert(x);
                                                                notify(response.message, response.priority, true);
                                                            }
                                                        },
                                                        this.state.e['operator_employees']
                                                    );
                                                }}
                                            />
                                        </SectionColumn>

                                        <SectionColumn/>
                                    </SectionRow>
                                }
                            </SectionColumn>
                        </SectionRow>
                    }

                    {/*{this.employeeCounterView()}*/
                    }
                </Section>
            )
        ;
        return this.state.dataItem5 === null || this.state.dataItem5 === 1 ? ui :
            <BlurTabContents/>
            ;
    }
}

/**
 *
 * Method is called when redux local-storage changes or updates.
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = ['operator', 'company_positions', 'gender', 'country', 'shareholder_type', 'LOCAL_BACKUP'];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
}
export default connect(mapStateToProps)(OperatorEmployeeSection);