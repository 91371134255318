import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import DynamicTable from "../../general-components/tables/DynamicTable";
import LoadingData from "../../general-components/controls/data-loading/LoadingData";
import {notify} from "../../../MiscUtils";
import {Dispatch} from "../../general-components/redux/app-storage";
import {MISC_ID} from "../../general-components/redux/allowed-actions";

/**
 *
 * Get a list of gateways.
 *
 */
export default class ListGateways extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.sendRequest('get', 'company/operator/payment-gateway', gateways => {

            if (gateways.type === 'Success') {
                this.setState({dataItem1: [...gateways.data]});
            } else notify(<span>Could not get list of gateways. The reported error was:<br/><br/>
                {gateways.msg}</span>, 2, false, 'No list of gateways');
        }, {})
    }

    render = () => {
        return <Section>
            {this.state.currentRoute}
            <SectionTitle>
                List gateways in use
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    {
                        this.state.dataItem1.length === 0 ?
                            <LoadingData hasData={this.state.dataItem1.length === 0} message={'Loading payment gateways...'}/> :
                            <DynamicTable context={'Gateways in use'}
                                          tableData={this.state.dataItem1}
                                          columnsOfInterest={[
                                              'id',
                                              'gateway',
                                              'account_name',
                                              'date_created'
                                          ]}
                                          noSearch
                                          showRowData={rowData => {
                                              Dispatch({type: MISC_ID, payload: rowData});
                                              this.navigateTo('../view-gateway');
                                          }}
                            />}
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}