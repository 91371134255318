import GMS from "../../parent-component/GMS";
import './document-viewer-style.css';
import {Section, SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";
import {paraphrase} from "../../../MiscUtils";

/**
 *
 * A document entry component that behaves like a row element with specific styling to depict
 * its status.
 * properties used here are as follows:
 *      name: <String>
 *      document: <Blob>
 *      size: <Integer/string which auto-calculates size>
 *      uploadDate: <String representing a date>
 *      actions: <Array of jsons of command texts and their respective callback methods>
 *      count: <A counter value>
 *      -------
 *      active: <optional with colour code showing this status> #8EFF7DFF - light green
 *      inactive: <optional with colour code showing this status> #9D9CA6FF - light grey
 *      suspended: <optional with colour code showing this status> #F6D466FF - medium light-grey
 *      pending: <optional with colour code showing this status> #91B5F8FF - light blue
 *
 *
 */
export default class xDocumentEntry extends GMS {
    constructor(props) {
        super(props);
        this.status = {active: '#8EFF7DFF', inactive: '#9D9CA6FF', suspended: '#F6D466FF', pending: '#91B5F8FF'};
        this.status = Object.freeze(this.status);
        this.state = {
            path: this.props.path,
            name: this.props.name,
            count: this.props.count,
            uploaded: this.props.uploaded,
            currentStatus: props.suspended ?
                this.status['suspended'] : props.pending ?
                    this.status['pending'] : props.inactive ?
                        this.status['inactive'] : this.status['active'],
            size: this.props.isLabel ? this.props.size : 'Unknown'

        }

        if (props.isLabel !== true)
            this.getHeaders(this.props.path, e => {
                this.setState({
                    path: this.props.path,
                    name: this.props.name,
                    count: this.props.count,
                    uploaded: this.props.uploaded,
                    currentStatus: props.suspended ?
                        this.status['suspended'] : props.pending ?
                            this.status['pending'] : props.inactive ?
                                this.status['inactive'] : this.status['active'],
                    size: e['headers']['content-length'] ? `${(parseInt(e['headers']['content-length']) / 1000).toFixed(3)}kB` : 'Unknown'
                });
            });
        // These are used to style the document background to denote the status of the object in question
        // default status is active for this entry else take the current statu
    }

    render = () => {
        return <Section className={'document-entry'}
                        style={{
                            boxShadow: this.props.isLabel ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : 'none',
                            background: this.props.isLabel ? '#FFFFFF' : this.state.currentStatus
                        }}>
            <SectionRow>
                {this.state.count &&
                <SectionColumn style={{fontWeight: 800, fontSize: 14, width: 48}}>{this.state.count}</SectionColumn>}
                <SectionColumn>{paraphrase(this.state.name, 20)}</SectionColumn>
                <SectionColumn>{this.state.size}</SectionColumn>
                <SectionColumn>{this.state.uploaded}</SectionColumn>
                {/*<SectionColumn>{paraphrase(this.state.path, 52)}</SectionColumn>*/}
            </SectionRow>
        </Section>
    }
}