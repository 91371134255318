import GMS from "../../parent-component/GMS";
import React from "react";
import PasswordField from "../../general-components/input-field/PasswordField";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
import {notify} from "../../../MiscUtils";

/**
 *
 * component updates passwords for a given agent or user
 *
 */
export default class PasswordUpdater extends GMS {
    constructor(props) {
        super(props);
        this.confirmNewpasswordReference = React.createRef(); // a reference used to acquire the newpassword confirmation component field
        this.oldPasswordReference = React.createRef(); //for checking old password against the new ones
        this.newPasswordReference = React.createRef(); //for doing the same as above.
    }

    /**
     *
     * evaluates collected passwords (especially the new and confirmation passwords, evaluates them
     * then sends them to the server, or notifies the user of the same.
     *
     */
    finalizePasswordUpdate = () => {

        // send request for PUT
        //check if the new password and confirmation password are the same. if they are,
        // get rid of the confirmation password from the object
        //check whether all passwords are the same
        const password = new RegExp(this.state.e["oldpassword"]);
        if (
            password.test(this.state.e["newpassword"]) ||
            password.test(this.state.e["confirmnewpassword"])
        ) {
            //show error on new password field that the pasword needs to be different from the old password
        }
        if (this.state.e["newpassword"] === this.state.e["confirmnewpassword"]) {
            delete this.state.e["conformnewpassword"];
            this.confirmNewpasswordReference.current.removeContextMessageWarning();
            //
            this.sendRequest("put", "account/users/password", response => {
                //console.log('Password reset response -> ', response.data);
            });
        } else {
            // show an error message stating passwords do not match
            this.confirmNewpasswordReference.current.showContextMessageWarning(
                "passwords don't match!"
            );
        }
    };

    componentDidMount = () => {
        this.setState({buttonIsVisible: false})
    }

    render = () => {
        return (
            <Section>
                <SectionTitle>
                    Update password
                </SectionTitle>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <PasswordField bold
                                       enablePasswordView
                                       doNotCapture
                                       fontSize={14}
                                       name={"oldpassword"}
                                       placeholder={"Type old password"}
                                       changeCallback={this.collectFieldData}
                        />
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <PasswordField bold
                                       enablePasswordView
                                       fontSize={14}
                                       name={"newpassword"}
                                       placeholder={"New password"}
                                       passwordCorrectCallback={this.registerIsPasswordValid}
                                       changeCallback={this.collectFieldData}
                        />
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <PasswordField bold
                                       fontSize={14}
                                       ref={this.confirmNewpasswordReference}
                                       name={"confirmnewpassword"}
                                       placeholder={"Confirm new password"}
                            // passwordCorrectCallback={this.registerIsPasswordValid}
                                       blurCallback={e => {
                                           if (e.target.value === this.state.e['newpassword']) {
                                               this.toggleSubmitButton(this.state.reload);
                                               this.collectFieldData(e).then();
                                           } else {
                                               this.setState({miscellaneousItems: false});
                                               notify('Passwords do not match!', 2, true);
                                               this.toggleSubmitButton(false);
                                           }
                                           //
                                       }}
                        />
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        {this.state.buttonIsVisible && <SubmitButton
                            callback={e => {

                                this.finalizePasswordUpdate();
                                this.sendRequest('put', '/account/users/password', response => {
                                    if (response.type === 'Success')
                                        notify('Password updated', 4, true);
                                    this.navigateTo('../list-operators');
                                }, {});
                            }}
                            commandText={"Change password"}
                        />}
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>

            </Section>
        );
    };
}
