import {Component} from "react";
import {SectionColumn, SectionRow} from "../../../../../rotm-custom-views/view-utils/SectionUtils";
import SelectField from "../../../../../general-components/input-field/SelectField";
import NumberField from "../../../../../general-components/input-field/NumberField";
import TextAreaField from "../../../../../general-components/input-field/TextAreaField";
import CloseControl from "../../../../../general-components/controls/svg-controls/CloseControl";
import TextField from "../../../../../general-components/input-field/TextField";

/**
 *
 *
 *
 */
export default class PrizesFieldSet extends Component {
    constructor(props) {
        super(props);
        this.setNumber = props.setNumber;
        this.state = {prizeName: null}
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    }

    render = () => {
        return <SectionRow boxedColour>
            {/*// this is a spacer objet*/}
            <SectionColumn style={{width: '80%'}}>
                <SectionRow style={{borderBottom: '1px solid #800080'}}>
                    <SectionColumn
                        style={{
                            fontWeight: 700,
                            color: '#800080',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>Prize {this.setNumber + 1}
                        {this.state.prizeName &&
                        <div>&ensp;>> <span style={{fontStyle: 'italic'}}>  {this.state.prizeName}</span></div>}
                    </SectionColumn>
                    <SectionColumn/>
                    <SectionColumn style={{width: 60}}>
                        <CloseControl colour={'#a80404'}
                                      callback={() => window.alert(
                                          `call to close ${this.setNumber + 1}`
                                      )}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <SelectField bold
                                     fontSize={14}
                                     capitalize
                                     defaultValue={this.props.fieldData && this.props.fieldData['winner_prize']}
                                     isRequired
                                     name={'winner_prize'}
                                     options={this.props.prize_category}
                                     placeholder={'select prize to be won'}
                                     changeCallback={e => {
                                         this.setState({
                                             prizeName:
                                             e.target.options[e.target.selectedIndex].textContent
                                         });
                                         this.props.collectFieldData(e, this.setNumber);
                                     }}/>

                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextField
                            maxLength={80}
                            defaultValue={this.props.fieldData && this.props.fieldData['prize_name']}
                            isRequired name={'prize_name'}
                            placeholder={'what name/phrase is used to refer to it?'}
                            changeCallback={e => this.props.collectFieldData(e, this.setNumber)}/>
                    </SectionColumn>
                    <SectionColumn>
                        <NumberField
                            capitalize
                            defaultValue={this.props.fieldData && this.props.fieldData['prize_valued_at']}
                            isRequired
                            name={'prize_valued_at'}
                            placeholder={'prize valued at'}
                            changeCallback={e => this.props.collectFieldData(e, this.setNumber)}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextAreaField
                            noResize
                            defaultValue={this.props.fieldData && this.props.fieldData['prize_description']}
                            name={'prize_description'} placeholder={'brief description of prize'}
                            changeCallback={e => this.props.collectFieldData(e, this.setNumber)}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow/>
                <SectionRow/>
            </SectionColumn>
        </SectionRow>;
    }
}