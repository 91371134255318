import "./login.css";
import "bootstrap-css";
import {notify,} from "../../../MiscUtils";
import PasswordField from "../../general-components/input-field/PasswordField";
import GMS from "../../parent-component/GMS";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import EmailField from "../../general-components/input-field/EmailField";
import OtpField from "../../general-components/input-field/OtpField";
import {Dispatch} from "../../general-components/redux/app-storage";
import {LOGIN, LOGOUT, SUBTITLE,} from "../../general-components/redux/allowed-actions";
import {Section, SectionColumn, SectionRow,} from "../../rotm-custom-views/view-utils/SectionUtils";
import {initialState} from "../../general-components/redux/reducer";
import letterHead from "../../general-components/BCLB_icons/bclb-letter-head.png";
import React from "react";

/**
 *
 * Login page will be used in multiple users logins.
 * Properties to use:
 *  - internal -> dictates whether this login page is going to be used
 *  within an organization, or outside. If the property is present, then
 *  it's internal else its external. This property does not require an argument.
 */

export default class Login extends GMS {
    constructor(props) {
        super(props);
        this.communicate = props.communicate;
        /**
         * @deprecated('this property is no longer relevant!')
         */
        // this.isInternalLogin = props.internal !== undefined;
    }

    /**
     *
     * Checks the role whose user interface is configured for. This is used in all user interfaces.
     * Should the role be not defined in the local storage, proceed to show a window-wide
     * message and return a false.
     *             user interface
     * @returns {boolean} a true or false should the mount have occurred properly
     *              (if the role was found or not)
     *
     * // the following roles are supported at the backend
     *
     * *
     *  1. Admin ad
     *  2. CEO ce
     *  3. Licensor li
     *  4. Operator op
     *  5. Inspector in
     *  6. Compliance co
     *  7. Legal le
     *  8. Procurement pro
     *  9. Registry re
     *  10. Secretary se
     * *
     *
     * @param lgn the login response data to evaluate
     */
    verifyAndLoadRole = (lgn) => {
        // console.log(lgn)
        if (new RegExp(/wrong/).test(lgn.message))
            this.notifyInactive(lgn.message);
        else if (new RegExp(/OTP/).test(lgn.message))
            this.notifyInactive(lgn.message);
        else if (lgn.type ==='Fail')
            this.notifyInactive();
        else if (lgn === undefined)
            notify(
                "No communication from System",
                2,
                `${this.constructor.name} has not received any communication
                                                    from the server!\n Program has now been stopped`,
            );
        else {
            if (lgn.priority === 3)
                notify(
                    `Cannot log you in: email/password may be invalid! Try again`,
                    3,
                    true
                );
            else {
                if (lgn.data === undefined || lgn.data.length === 0)
                    notify(
                        `Cannot log you in: email/password may be invalid! Try again`,
                        2,
                        false,
                        "Login Error"
                    );
                // because: ${lgn.message}
                else {
                    // check if this person represents a company
                    // if (lgn.data["role_id"] === 4)
                    //     this.sendRequest("get", 'company/operator', (operator) => {
                    //         Dispatch({type: OPERATOR, payload: operator.data});
                    //     });
                    const identity = {
                        type: LOGIN,
                        payload: {
                            role: lgn.data[0]["role_id"],
                            email: lgn.data[0].user,
                            user_id: lgn.data[0].id,
                            section_head: lgn.data[0].section_head
                        },
                    };
                    Dispatch(identity); //record it into local storage
                    // this.navigateTo(`${roledDashes[lgn.data.role_id].path}/*`, true);
                    // check whether it's a representative who is logging in
                    const absoluteUrlForTheseLogins = [4, 10, 11];
                    this.navigateTo(
                        `${
                            absoluteUrlForTheseLogins.includes(lgn.data[0].role_id) ? "/" : "list-operators"
                        }`,
                        true
                    );
                    notify(`Welcome ${lgn.data[0].user.split("@")[0]}`, 1, true);
                }
            }
        }
    };

    notifyInactive = (message = null) => {
        notify(message === null ? <span>
                Thank you for having created an account on GMS
                <br/>
                <br/>
                We ask that you be patient as the board reviews your account for activation.
                <br/>
                <br/>
                Thank you.
            </span> : message, message === null ? 3 : 2, false,
            message !== null ? 'Cannot log in' : `${this.state.e['email']} not activated!`);
    }

    /**
     *
     *
     * Clear the local storage
     *
     */
    componentDidMount() {
        Dispatch({type: LOGOUT, payload: initialState});
    }

    render = () => {
        return (
            <Section className={'full-window casino-table-background'}>
                <SectionRow className={'p-2 top-banner'}>
                    <SectionColumn>
                        <div className={'gms_title'}>
                            Gaming Management System
                        </div>
                    </SectionColumn>

                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <Section className={"login-container"}>
                            {this.state.currentRoute && this.state.currentRoute}
                            <SectionRow>
                                <SectionColumn>
                                    <img className={'bclb-letter-head'} src={letterHead}/>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow style={{height: '60vh', border: '60px solid #FFFFFF'}}/>
                            {/*<SectionTitle>Login</SectionTitle>*/}
                            <SectionRow>
                                <SectionColumn>
                                    <EmailField bold
                                                capitalize
                                                name={"email"}
                                                placeholder={"email"}
                                                changeCallback={this.collectFieldData}
                                    />
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <PasswordField ref={this.passwordRef}
                                                   capitalize
                                                   enablePasswordView
                                                   doNotCapture
                                                   bold
                                                   fontSize={14}
                                                   name={"password"}
                                                   placeholder={"password"}
                                                   changeCallback={this.collectFieldData}
                                    />
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <OtpField bold
                                              capitalize
                                              fontSize={14}
                                              name={"otp_code"}
                                              placeholder={"OTP code"}
                                              changeCallback={(e) => {
                                                  this.collectFieldData(e).then((a) => {
                                                      // count the number of characters available. if they are more than 4,
                                                      // set miscellaneousItems to true
                                                      if (
                                                          this.state.e.otp_code.length > 3 &&
                                                          this.state.e.otp_code.length <= 5
                                                      )
                                                          this.setState({
                                                              miscellaneousItems: true,
                                                              buttonIsVisible: false
                                                          });
                                                      else this.setState({
                                                          miscellaneousItems: null,
                                                          buttonIsVisible: false
                                                      });
                                                  });
                                              }}
                                    />
                                </SectionColumn>
                                <div
                                    className={
                                        "d-flex justify-content-center align-items-center pt-2 mt-1"
                                    }
                                >
                                    <SubmitButton
                                        disabled={this.state.visible}
                                        commandText={"request OTP"}
                                        style={{
                                            background: "none",
                                            // color: "#800080",
                                            color: "#06163A",
                                            // border: "1px solid #800080",
                                            border: "1px solid #06163A",
                                            textTransform: "capitalize",
                                        }}
                                        callback={() => {
                                            this.state.e.otp_log = "otp";
                                            this.sendRequest("post", `account/${LOGIN}`, response => {
                                                if (response.type === 'Fail')
                                                    if (new RegExp(/wrong/).test(response.message))
                                                        this.notifyInactive(response.message);
                                                    else if (response.data.constructor.name === {}.constructor.name)
                                                        // notify(response.data.message, 4, true);
                                                        this.notifyInactive();
                                                    else if (response.data.length === 0) // user not found
                                                        notify(`Error! The user "${this.state.e['email']}" does not exist`, 2, false, 'User not found');
                                                    else
                                                        notify(response.message, response.priority, true);

                                                    // hide this button until otp comes back
                                                // compare the otp codes with what is sent to the client
                                                else
                                                    notify(response.data.message, response.priority, true);
                                            });
                                            // illustrate a delayed response
                                            window.setTimeout(() => {
                                                this.setState({visible: true});
                                            }, 1600);
                                        }}
                                    />
                                </div>
                            </SectionRow>
                            <div className={"d-grid gap-2"}>
                                <SubmitButton
                                    style={{width: "100%", height: "45px"}}
                                    commandText={"login"}
                                    callback={() => {
                                        this.state.e.otp_log = "login";
                                        // no local persist for this component. Make sure that sendRequest does
                                        // nullify state.e items after axios response.
                                        // this item is getting executed before the response comes back
                                        //show message as an information
                                        notify("logging you in...", 1, true);
                                        this.sendRequest("post", `account/${LOGIN}`, this.verifyAndLoadRole);
                                    }}
                                />
                            </div>
                            <SectionRow>
                                <SectionColumn/>
                                <SectionColumn style={{textAlign: 'center', fontWeight: 600, fontSize: 16, margin: 28}}>
                                    or
                                </SectionColumn>
                                <SectionColumn/>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn/>
                                <SectionColumn>
                                    <u><a href={'#'}
                                          onClick={
                                              () => {
                                                  this.navigateTo('../representative/self-declaration');
                                                  Dispatch({
                                                      type: SUBTITLE,
                                                      payload: "Create an account with BCLB",
                                                  });
                                              }
                                          }

                                    >Create an account</a></u>
                                </SectionColumn>
                                <SectionColumn/>
                            </SectionRow>
                            <SectionRow>
                                {this.state.currentRoute}
                                <SectionColumn/>
                                <SectionColumn>
                                    <a href={'#'} onClick={() => {
                                        this.navigateTo('../representative/frequently-asked-questions')
                                    }}>Help and support</a>
                                </SectionColumn>
                                <SectionColumn/>
                            </SectionRow>
                        </Section>
                    </SectionColumn>
                </SectionRow>

            </Section>
        );
    };
}
