import React, {Component} from "react";
import axios from "axios";
import SelectField from "../input-field/SelectField";

/**
 *
 * Component is responsible for paginating data inside a DynamicTable component
 *
 */
export default class Paginator extends Component {
    constructor(props) {
        super(props);
        this.activeClassName = 'paginator-control-box-active';
        this.state = {
            // currentRecordNumber: 0,
            // declare default page size either dynamically through props or statically through state declaration
            pageSize: props.pageSize ? props.pageSize : 5,
            // pageSize: props.pageSize ? props.pageSize : 5,
            /*
            by default this should be a array, null (load the records list from the list
            of records returned on page load by the dynamic table without resetting the recordsList variable
            */
            recordsList: props.recordsList ? props.recordsList : null,
            // no url to fetch. Use a method to trigger a data fetch to the server
            url: props.url ? props.url : null,
            // the current active component to de-link from the styling queue .paginator-control-box-active
            activeLink: null,
            // these two are updated when data is loaded form the given url
            currentPage: 0,
            maxPages: 0,
            previousPage: 0,
            // this should be an array comprising page groups of: array-groups of records
            pages: []
            // class
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.recordsList) this.setState({recordsList: [...nextProps.recordsList]});
    }

    /**
     *
     * create groups of arrays of pages
     * @param pageSize the number of records of each group of records
     *
     */
    createPages = async (pageSize = this.state.pageSize) => {
        // create groups based on page-sizes
        let pages = [];
        // console.log('createPages ', 'size of records ', this.state.recordsList.length, 'pageSIze ', pageSize, ' outcome of modulo ', this.state.recordsList.length % pageSize)
        // const maxPages = parseInt(this.state.recordsList.length / pageSize) === 0 ? 1 : this.state.recordsList.length % pageSize > 0 ? parseInt(this.state.recordsList.length / pageSize) + 1 : parseInt(this.state.recordsList.length / pageSize) ;
        // const maxPages = this.state.recordsList.length % pageSize > 0 ? parseInt(this.state.recordsList.length / pageSize) + 1 : parseInt(this.state.recordsList.length / pageSize)// const maxPages = this.state.recordsList.length % pageSize > 0 ? this.state.recordsList.length % pageSize + 1 : parseInt(this.state.recordsList.length / pageSize);

        const maxPages = parseInt(this.state.recordsList.length / pageSize) === 0 ? 1 : this.state.recordsList.length % pageSize === 0 ? parseInt(this.state.recordsList.length / pageSize) : parseInt(this.state.recordsList.length / pageSize) + 1;
        // console.log('max pages ', maxPages, ' pageSize ', pageSize, ' records ', this.state.recordsList.length)
        let i = 0;
        let maxPageCounter = 0;
        let recordIndexCount = 0;
        let pageGroupItems = [];
        // loop through the maximun number of pages
        do {
            // fill each page group with items according to the declared pageSize
            const nextMaxIndex = i + pageSize;
            do {
                if (this.state.recordsList[i] === undefined)
                    break;
                pageGroupItems.push(this.state.recordsList[i]);
                i += 1;
            } while (i < nextMaxIndex)
            pages.push(pageGroupItems);
            pageGroupItems = [];
            maxPageCounter += 1;
        } while (maxPageCounter < maxPages);
        return {pages: [...pages], maxPages: pages.length, lastPage: 0};
    }

    /**
     *
     * fetch data if there is a url that's supplied else show the recordsList declared else show no data toshow
     *
     */
    componentDidMount = () => {
        if (this.props.url === undefined && this.props.recordsList === undefined) return;
        // check whether there is a url that is mentioned in the url state variable to fetch data. If there is,
        // fetch data from that url else go through the recordsList variable and call a method to draw the
        // data on the DynamicTable component through it
        if (this.state.url !== null) {
            axios.get(this.state.url, {
                withCredentials: true,
                headers: {"Content-Type": "*/*"}
                , //accept plain text as well just because.
            }).then(response => {
                // host the data into the recordsList state variable
                this.setState({recordsList: response.data}, () => {
                    // create pages
                    this.createPages().then(() => {
                        this.props.executeOnRowPopulation(this.state.pages[0]); // load the first page
                    });
                    // show page 1 with the records desired
                    //initially show the list of records as prescribed in next
                });
            });
        } else this.configurePagination()
        // calculate
    }
    /**
     *
     * Configure pagination using updated elements in pageSizes
     *
     */
    configurePagination = () => {
        this.createPages().then((pageData) => {
            // set state then load the first page
            this.setState({...pageData}, () => {
                this.props.executeOnRowPopulation(this.state.pages[this.state.currentPage]);
                this.setState({previousPage: this.state.currentPage}, () => {
                    // set active to the current page
                    this.setActive();
                });
            });
        });
    }
    /**
     *
     * load next page
     *
     */

    next = () => {
        if (this.state.currentRecordNumber === this.state.recordsList.length || this.state.currentPage === this.state.maxPages - 1)
            // if the previous page is the last page, hint that this is the last page
            return;

        else {
            console.log(this.state.currentPage, this.state.pages[this.state.currentPage + 1])
            this.props.executeOnRowPopulation(this.state.pages[this.state.currentPage + 1]);
            // navigate to the next page: lastPage += 1
            this.setState({previousPage: this.state.currentPage, currentPage: this.state.currentPage + 1}, () => {
                // console.log("current page ", this.state.currentPage)
                // set active to the current page
                this.setActive();
            });
        }
    }
    /**
     * load previous page
     */
    previous = () => {
        if (this.state.currentPage < 0) {
            // hint this is the first page
            // remove the next button

        } else {
            this.props.executeOnRowPopulation(this.state.pages[this.state.currentPage - 1]);

            this.setState({previousPage: this.state.currentPage, currentPage: this.state.currentPage - 1}, () => {
                this.setActive();
            });
        }
    }
    /**
     *
     * load a given page number from selection of pages labelled in the divs
     * @param pageNumber
     *
     */
    page = (pageNumber = 1) => {
        console.log('page number ', pageNumber)
        this.setState({currentPage: pageNumber, lastPage: pageNumber - 1}, () => {
            this.props.executeOnRowPopulation(this.state.pages[pageNumber])
        });
    }
    /**
     *
     * sets active or inactive based on arguments passed
     * @param e the element in question
     * @param removeActive whether to remvoe active class and not update the current element in state
     */
    setActive = (e, removeActive = false) => {
        const showActive = () => {
            const currentPage = document.getElementById(`page-${this.state.currentPage}`);
            const previousPage = document.getElementById(`page-${this.state.previousPage}`);
            currentPage !== null && currentPage.classList.add(this.activeClassName);
            previousPage !== null && previousPage.classList.remove(this.activeClassName);
            // this.props.executeOnRowPopulation(this.state.pages[this.state.currentPage]);
            // this.setState({previousPage: this.state.currentPage});
            // set url to active page
        }
        if (e)
            this.setState({currentPage: parseInt(e.target.textContent)}, showActive);
        e !== undefined ? e.target.classList.add(this.activeClassName) : showActive();
    }
    /**
     *
     * Draws a box necessary for clicking events in order to view what to do when a user clicks it
     * @param boxValue - the number or value on the box
     * @returns {JSX.Element}
     */
    box = (boxValue) => {
        return <div id={`page-${boxValue}`}
                    className={'paginator-control-box'}
                    style={{marginTop: 36, borderLeft: boxValue === '<' || boxValue === 1 ? '1px solid #d3d3d8' : null}}
            // style={{borderLeft: boxValue === '<' || boxValue === 1 ? '1px solid #d3d3d8' : null}}
                    onClick={(e) => {
                        // if value is not a string, do not call page nor set active
                        this.props.executeOnRowPopulation(this.state.pages[parseInt(boxValue)]);
                        if (boxValue.constructor.name !== 'String') {
                            this.page(parseInt(boxValue) - 1); // compensate for array selection
                            // set this page to active, set previous box to inactive
                            if (boxValue !== '<' || boxValue !== '>') {
                                this.setActive(e);
                            }
                        } else {
                            if (boxValue === '>')
                                this.next();
                            else if (boxValue === '<')
                                this.previous();
                        }
                    }}>
            {boxValue}
        </div>
    }
    /**
     *
     * Draw the number of boxes required to show the pages there are
     * @returns {JSX.Element}
     */
    render = () => {
        if (this.props.url === undefined && this.props.recordsList === undefined) return null;
        if (this.state.recordsList === null || this.state.recordsList.length === 0) {
            return null;
        } else {
            let boxes = [];
            if (this.state.pages.length > 0) {
                // let numberOfPages = Math.round(this.state.recordsList.length / this.state.pageSize);
                let k = 0
                do {
                    boxes.push(this.box(k + 1));
                    k += 1;
                } while (k < this.state.pages.length);
            }
            //
            return this.state.maxPages > 1 && <div className={'paginator-control-bar'}>
                {/*{this.state.currentPage === 1 ? null : this.box('<')}*/}
                {/*// show the first 6 boxes*/}
                <SelectField
                    name={'pageSize'}
                    bold
                    guideText={'select page'}
                    options={[{5: 5}, {10: 10}, {20: 20}, {50: 50}]}
                    defaultValue={this.state.pageSize}
                    placeholder={'Page size'}
                    changeCallback={e => {
                        this.setState({pageSize: e.target.options[e.target.selectedIndex].value}, this.configurePagination)
                    }}/>&ensp;&ensp;
                {boxes}
                {/*the last page must be the same as the maximum number of pages (because lastPage starts with 0)*/}
                {/*{this.state.currentPage === this.state.maxPages ? null : this.box('>')}*/}
            </div>;
        }
    }
}
