/**
 *
 * Class shows how payouts have been made and to whom and for what game. This file consists
 * of two classes: one for displaying payout data (akin to shareholder data) and the other
 * for creating a payout (strictly for operators)
 *
 */
import GMS from "../../../parent-component/GMS";
import {Section, SectionRow, SectionTitle} from "../../view-utils/SectionUtils";
import DynamicTable from "../../../general-components/tables/DynamicTable";

export class CreatePayout extends GMS {
    render = () => {
        // create payout interfaces here
        // who is getting paid, what amount, witholding tax percentage (which calculates true payout), payout receipt no,
        // payment channel, payout receipient account (phone number or bank account), why the payout is being made,
        // for which game/promotion
        return null;
    }
}

// shows the winnings payouts
export class WinningsPayouts extends GMS {
    render = () => {
        return <Section>
            <SectionTitle>
                list of payouts to winners
            </SectionTitle>
            <SectionRow>
                {/*<SectionColumn>*/}
                {this.state.miscellaneousItems ? <DynamicTable
                    context={'winning payouts'}
                    columnsOfInterest={[
                        'amount',
                        'payout channel',
                        'reciept_no',
                        'payer',
                        'recipient',
                        'game'
                    ]}
                    showRowData={() => {
                        // window.alert('show winners payout data')
                    }} tableData={this.state.miscellaneousItems}/> : 'no data on payouts'}
                {/*</SectionColumn>*/}
            </SectionRow>
        </Section>
    }
}