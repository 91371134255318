import GMS from "../../../parent-component/GMS";
import React from "react";
import {col12, col6, getStorageObject, row} from "../../../../MiscUtils"
import TextField from "../../../general-components/input-field/TextField";
import EmailField from "../../../general-components/input-field/EmailField";
import SubmitButton from "../../../general-components/controls/buttons/SubmitButton";
import {BCLB} from "../../../general-components/redux/allowed-actions";


/**
 *
 * Class for updating contact informaton. Only three items are supplied at this time:
 * this component expects three properties:
 *  phonenumber - the phone number to show
 *  emailaddress - email address to show
 *  postaddr - the postal address to show
 *
 */

export default class OperatorOfficeInfoUpdater extends GMS {
    constructor(props) {
        super(props);
        this.noFieldsSet = false;
        if (
            props.officeaddress !== undefined

        )
            this.noFieldsSet = true;
        else {
            console.warn(
                `NOTE: phonenumber property is missing from  <${this.constructor.name} .../> component call. Not showing phone number!`
            );
        }
        //proceed to make sure that despite these items missing, either of them, that default values are shown
        this.officeaddress =
            props.officeaddress === undefined
                ? "--no-office-address--"
                : props.officeaddress;

        //
        //references used to set the input fields with data, but also disable them.
        //
        this.officeAddressRef = React.createRef();
    }

    /**
     * this item will update the contact info
     */
    componentDidMount = () => {
        // fetch data from server regarding contact info, or from localStorage
        let contactInfo = getStorageObject(BCLB)
        if (contactInfo) {
            let k = JSON.parse(contactInfo);
            k = k.operator; // the operator-registration should be returned
            // get the contacts of the operator-registration
            // email, office contacts, postal address
        }
        //update title bar with new title
        //console.log('current phone number', this.phoneNumberReference.current);
        this.officeAddressRef.current.internalFieldReference.current.value =
            this.officeaddress;
        // /////////////////////////////
        // //nullify these input fields which have references.
        this.officeAddressRef.current.internalFieldReference.current.disabled = true;
        //console.log(this.phoneNumberReference.current.internalFieldReference.current.disabled)

    };

    /**
     *
     * does preliminary checks for data existance in this.state.e (from the GMS class/component)
     *
     */
    finalizeContactInfo = () => {
        this.updateFormData("jwt", "some-jwt-string"); //collect this from the sendRequest method if necessary
        this.sendRequest("updatecontactinfo");
    };
    render = () => {
        return (
            <div className={row}>
                <div className={col12}>
                    <div className={row}>
                        <div className={col6}>
                            <div className={row}>
                                <div className={`${row} image-subtitle`}>
                                    current contact information
                                </div>
                            </div>
                            <div className={row}>
                                <div className={col12}>
                                    <TextField bold fontSize={14}
                                               capitalize
                                               ref={this.officeAddressRef}
                                               name={""}
                                               callback={null}
                                               placeholder={"current office address"}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*//editable content here*/}
                        <div className={col6}>
                            <div className={`${row} image-subtitle`}>
                                <div className={col12}>new contacts</div>
                            </div>
                            <div className={row}>
                                <div className={col12}>
                                    <EmailField bold
                                                capitalize
                                                name={"office_address"}
                                                changeCallback={this.collectFieldData}
                                                placeholder={"new office address"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={row}>
                        <div className={col6}/>
                        <div className={col6}>
                            <SubmitButton
                                callback={this.finalizeContactInfo}
                                commandText={"update office address"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
