import GMS from "../../parent-component/GMS";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './notification-css.css';
import {notify, readFromLocalStorage} from "../../../MiscUtils";
import {BCLB, MISC_ID} from "../redux/allowed-actions";
import NotificationBubble from "./NotificationBubble";

/**
 *
 * Notification component allows for the view or summarization
 * of notifications by counting the number of notifications/
 * messages passed through props.
 * Props:
 *      - notifications [{
 *                          from,
 *                          to,
 *                          topic,
 *                          text,
 *                          time_sent,
 *                          is_read
 *                      },
 *                      ...]
 *      - onSelect
 *      - classifyByTopic This is an option that tells the notification
 *                          component to classify notifications listing by
 *                          topic
 *      - classifyByTime This is an option that tells the notification
 *                          component to classify notifications listing by
 *                          time. This is the default classification (Last
 *                          Come First Displayed).
 *
 */
export default class Notification extends GMS {
    constructor(props) {
        super(props);
        this.ls = readFromLocalStorage(MISC_ID, BCLB);
    }

    /**
     *
     * update props (dataItem4, miscellaneousItems, variable)
     * @param nextProps
     * @param nextContext
     * @constructor
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            dataItem4: nextProps.notifications ? nextProps.notifications.length > 0 ?
                    '#FFFFFF' :
                    '#4f4d4d' :
                '#4f4d4d',
            miscellaneousItems: nextProps.notifications ? [...nextProps.notifications] : [],
            variable: nextProps.notifications ? nextProps.notifications.length : 0
        });
    }
    /**
     *
     * load state with props
     *
     */
    componentDidMount = () => {
        this.setState({
            dataItem4: this.props.notifications ? this.props.notifications.length > 0 ?
                    '#FFFFFF' :
                    '#4f4d4d' :
                '#4f4d4d',
            miscellaneousItems: this.props.notifications ? [...this.props.notifications] : [],
            variable: this.props.notifications ? this.props.notifications.length : 0
        });
        // update the notifications endpoint to allow is_read set to true
    }

    /**
     *
     * Update the notifications counter.
     *
     */
    updateCounter = () => {

    }
    /**
     *
     * Fires when classifyByTopic is specified.
     *
     */
    classifyByTime = () => {

    }
    /**
     *
     * Fires when classifyByTopic is specified.
     *
     */
    classifyByTopic = () => {

    }
    /**
     *
     * Show a notification bubble when notifications is clicked
     *
     */
    toggleNotificationBubble = (toggle = this.state.visible) => {
        this.setState({visible: !toggle});
    }

    /**
     *
     * method to remove notification bubble
     *
     */
    removeNotificationBubble = () => {
        // fetch notifications
        this.props.queryNotifications();
        this.setState({visible: false});
    }

    render = () => {
        return <div id={'notification'}
                    className={'notification'}
                    style={{cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faBell}
                             style={{height: 32, color: this.state.dataItem4}}
                             onClick={() => {
                                 // show notificationBubble
                                 // this.state.variable > 0 && this.toggleNotificationBubble()
                                 // fetch notifications
                                 this.props.queryNotifications().then(() => {
                                     // read notifications from redux
                                     this.setState({miscellaneousItems: [...readFromLocalStorage('notifications', BCLB)]}, () => {
                                         this.state.miscellaneousItems.length !== 0 ?
                                             // this.toggleNotificationBubble() :
                                             this.props.onClick() :
                                             notify('No unread notifications found', 1, true);
                                     })
                                 });

                             }}
            />
            {
                this.state.miscellaneousItems && this.state.miscellaneousItems.length > 0 &&
                <div className={'notification-count'}
                     style={{
                         background:
                             this.state.miscellaneousItems && this.state.miscellaneousItems.length > 0 ?
                                 '#1259e6' : '#4f4d4d'
                     }}>
                    {this.state.variable > 1000 ? `${parseFloat(this.state.variable / 1000).toFixed(1)}k` : this.state.variable}
                </div>
            }
            {
                this.state.visible ?
                    this.props.customUI ? <NotificationBubble
                            queryNotifications={this.props.queryNotifications}
                            removeNotificationBubble={this.removeNotificationBubble}
                            customUI={this.props.customUI}
                            viewNotificationCallback={this.props.onClick}/>
                        :
                        <NotificationBubble
                            queryNotifications={this.props.queryNotifications}
                            removeNotificationBubble={this.removeNotificationBubble}
                            notifications={this.props.notifications}
                            viewNotificationCallback={this.props.onClick}/> : null
            }
        </div>
    }
}