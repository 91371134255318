import {Component} from "react";
import {paraphrase} from "../../../MiscUtils";

/**
 *
 * A labelling component meant to unify styling of labels.
 * Accepts the following properties:
 *  1. colour: #905965 colour code that's desired
 *  2. text: <String> the text to display
 *
 */
export default class SimpleLabel extends Component {
    constructor(props) {
        super(props);
        this.text = props.paraphrase ? paraphrase(this.props.text, props.paraphrase) : this.props.text;
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.text = nextProps.paraphrase ? paraphrase(nextProps.text, nextProps.paraphrase) : nextProps.text;
    }

    render = () => {
        return (<span style={{
            width: 'wrap-content !important',
            padding: this.props.noPadding ? 0 : 4,
            fontSize: this.props.fontSize === undefined ? '1em' : this.props.fontSize,
            fontWeight: this.props.bold ? 800 : 400,
            textTransform: this.props.uppercase ?
                'uppercase' : this.props.lowercase ?
                    'lowercase' : this.props.capitalize ?
                        'capitalize' : 'normal',
            color: this.props.colour !== undefined ? this.props.colour : '#221922',
            height: "56px!important"
        }}>{
            this.props.underlined ?
                <u style={{color: this.props.underlined}}>
                    {this.text === undefined ? '-no-data-' : this.text}
                    {this.props.withColon ? ':' : null}</u> :
                this.text === undefined ? '-no-data-' : this.text}
            {this.props.withColon ? ':' : null}
        </span>)
    }
}