import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../labels/SimpleLabel";
import {connect} from "react-redux";
import {Dispatch} from "../redux/app-storage";
import {BCLB, NULL_CURRENT_NOTIFICATION} from "../redux/allowed-actions";
import {readFromLocalStorage} from "../../../MiscUtils";

/**
 *
 * Notification viewer to view notifications
 *
 *
 */
class NotificationView extends GMS {
    constructor(props) {
        super(props);
        this.current_notification = readFromLocalStorage('current_notification', BCLB);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({miscellaneousItems: nextProps.notification});
    }

    componentWillUnmount = () => {
        Dispatch({type: NULL_CURRENT_NOTIFICATION});
    }

    componentDidMount = () => {
        this.sendRequest('get', `notifications/mark-as-read/${this.current_notification.id}`, () => {
            // this is read to true
        }, {});
    }

    render = () => {
        return <Section>
            <SectionTitle>
                {
                    this.current_notification === undefined ?
                        <>Viewing notification</> :
                        <>Viewing notification No. {this.current_notification.id} - {this.current_notification.topic}</>
                }
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    <SimpleLabel/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    {this.current_notification.text}
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}

const mapStateToProps = (state, e) => {
    return {notification: state['current_notification']}
}

export default connect(mapStateToProps)(NotificationView);