import GMS from "../../parent-component/GMS";
import React from "react";
import TextField from "../../general-components/input-field/TextField";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";


/**
 *
 * Class for updating contact informaton. Only three items are supplied at this time:
 * this component expects three properties:
 *  phonenumber - the phone number to show
 *  emailaddress - email address to show
 *  postaddr - the postal address to show
 *
 */

export default class ContactInfoUpdater extends GMS {
    constructor(props) {
        super(props);
        this.noFieldsSet = false;
        if (
            props.phonenumber !== undefined &&
            props.emailaddress !== undefined &&
            props.postaddr !== undefined
        )
            this.noFieldsSet = true;
        else {
            if (props.phonenumber === undefined)
                console.warn(
                    `NOTE: phonenumber property is missing from  <${this.constructor.name} .../> component call. Not showing phone number!`
                );
            if (props.emailaddress === undefined)
                console.warn(
                    `NOTE: emailaddress property is missing from  <${this.constructor.name} .../> component call. Not showing email address!`
                );
            if (props.phonenumber === undefined)
                console.warn(
                    `NOTE: postaddr property is missing from  <${this.constructor.name} .../> component call. Not showing post address!`
                );
        }
        //proceed to make sure that despite these items missing, either of them, that default values are shown
        this.phoneNumber =
            props.phonenumber === undefined
                ? "--no-phone-number--"
                : props.phonenumber;
        this.emailAddress =
            props.emailaddress === undefined
                ? "--no-email-address--"
                : props.emailaddress;
        this.postAddr =
            props.postaddr === undefined ? "--no-post-address--" : props.postaddr;
        //
        //references used to set the input fields with data, but also disable them.
        //
        this.emailFieldsReference = React.createRef();
        this.phoneNumberReference = React.createRef();
        this.postAddrReference = React.createRef();
    }

    /**
     * this item will update the contact info
     */
    componentDidMount = () => {
        //update title bar with new title
        //console.log('current phone number', this.phoneNumberReference.current);
        this.phoneNumberReference.current.internalFieldReference.current.value =
            this.phoneNumber;
        this.emailFieldsReference.current.internalFieldReference.current.value =
            this.emailAddress;
        this.postAddrReference.current.internalFieldReference.current.value =
            this.postAddr;
        // /////////////////////////////
        // //nullify these input fields which have references.
        this.phoneNumberReference.current.internalFieldReference.current.disabled = true;
        //console.log(this.phoneNumberReference.current.internalFieldReference.current.disabled)
        this.phoneNumberReference.current.internalFieldReference.current.disabled = true;
        this.emailFieldsReference.current.internalFieldReference.current.disabled = true;
        this.postAddrReference.current.internalFieldReference.current.disabled = true;
    };

    /**
     *
     * does preliminary checks for data existance in this.state.e (from the GMS class/component)
     *
     */
    finalizeContactInfo = () => {
        this.updateFormData("jwt", "some-jwt-string"); //collect this from the sendRequest method if necessary
        this.sendRequest("updatecontactinfo");
    };
    render = () => {
        return (
            <Section>
                <SectionTitle>
                    Update contact information
                </SectionTitle>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        current information
                    </SectionColumn>
                    <SectionColumn>
                        Updating information
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextField bold fontSize={14}
                                   capitalize
                                   ref={this.emailFieldsReference}
                                   name={""}
                                   callback={null}
                                   placeholder={"email address"}
                        />
                        <TextField bold fontSize={14}
                                   capitalize
                                   ref={this.postAddrReference}
                                   name={""}
                                   callback={null}
                                   placeholder={"postal address"}
                        />
                        <TextField bold fontSize={14}
                                   capitalize
                                   ref={this.phoneNumberReference}
                                   name={""}
                                   callback={null}
                                   placeholder={"phone number"}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <TextField bold fontSize={14}
                                   capitalize
                            // ref={this.emailFieldsReference}
                                   name={""}
                                   changeCallback={null}
                                   placeholder={"email address"}
                        />
                        <TextField bold fontSize={14}
                                   capitalize
                            // ref={this.phoneNumberReference}
                                   name={""}
                                   changeCallback={null}
                                   placeholder={"postal address"}
                        />
                        <TextField bold fontSize={14}
                                   capitalize
                            // ref={this.phoneNumberReference}
                                   name={""}
                                   changeCallback={null}
                                   placeholder={"phone number"}
                        />
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <SubmitButton
                            callback={this.finalizeContactInfo}
                            commandText={"update contacts"}
                        />
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
            </Section>
        );
    };
}
