import GMS from "../../parent-component/GMS";
import {BCLB, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import NameField from "../../general-components/input-field/NameField";
import DateField from "../../general-components/input-field/DateField";
import {Dispatch} from "../../general-components/redux/app-storage";
import TextField from "../../general-components/input-field/TextField";
import KRAPinField from "../../general-components/input-field/KRAPinField";
import SelectField from "../../general-components/input-field/SelectField";
import {notify, readFromLocalStorage} from "../../../MiscUtils";
import React from "react";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import NumberField from "../../general-components/input-field/NumberField";
import CheckBoxField from "../../general-components/input-field/CheckBoxField";
import FileSelectField from "../../general-components/input-field/FileSelectField";

/**
 *
 *
 * UI enables declaration of (the terminal or final list of )Beneficiaries of the company that is declared.
 */
export default class DeclareBOF extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'Declare a Beneficiary member.'});
        let countries = readFromLocalStorage('country', BCLB);
        if (countries.length > 0 && countries.constructor.name === [].constructor.name)
            this.setState({dataItem1: [...countries]});
        this.setState(state => {
            state.e['appointment_rights'] = false;
            state.e['type_of_appointment_right'] = "2";
            state.e['significant_influence'] = false;
            state.e['significant_influence_type'] = "2";
            return state;
        });
    }

    render = () => {
        return <Section>
            <SectionTitle>
                Declare BOF
            </SectionTitle>
            <SectionRow boxedColour>
                <SectionColumn>
                    <span>
                        In this section, you are required to disclose and declare the beneficial owner of this operator.
                        <br/>
                        Please note that:
                        <ul>
                            <li>This provision is required under the <a
                                href={'http://libraryir.parliament.go.ke/handle/123456789/22121?show=full'}
                                target={'_blank'}><b> Legal Notice No. 32 of 2022</b></a> of the Republic of Kenya;</li>
                            <li>The information declared herein must match that found at the registrar of companies;</li>
                            <li>BCLB is required to decline applications/renewals upon failure to, or inappropriately declare BOF members.</li>
                        </ul>
                    </span>
                    <br/>
                    <span>
                        Fill in the fields under each subsection below.
                    </span>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                Bio
                            </h4>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <NameField name={'first_name'}
                                       isRequired
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'First name'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <NameField name={'last_name'}
                                       isRequired
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'middle name'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <NameField name={'surname'}
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'Surname'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <DateField name={'dob'}
                                       isRequired
                                       onlyPastDates
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'date of birth'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField name={'national_id'}
                                       isRequired
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'National ID/passport/Alien ID'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <KRAPinField name={'pin_number'}
                                         isRequired
                                         bold
                                         capitalize
                                         fontSize={14}
                                         placeholder={'Tax Pin number'}
                                         changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SelectField name={'nationality'}
                                         isRequired
                                         bold
                                         capitalize
                                         options={this.state.dataItem1}
                                         fontSize={14}
                                         placeholder={'Nationality'}
                                         changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField name={'resident_address'}
                                       isRequired
                                       bold
                                       capitalize
                                       maxLength={80}
                                       fontSize={14}
                                       placeholder={'Address of residence'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>

                        <SectionColumn>
                            <TextField name={'occupation'}
                                       isRequired
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'Current occupation'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <TextField name={'email'}
                                       isRequired
                                       bold
                                       capitalize
                                       maxLength={60}
                                       fontSize={14}
                                       placeholder={'email address'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField name={'phone_number'}
                                       isRequired
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'phone number'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn/>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                Shares in company
                            </h4>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            Declare the number of shares that the beneficial owner owns in your company:
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SelectField name={'shares_type'}
                                         isRequired
                                         bold
                                         options={[{1: 'Direct'}, {2: 'Indirect'}]}
                                         fontSize={14}
                                         placeholder={'Describe the type of shares'}
                                         changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField name={'number_of_shares'}
                                       isRequired
                                       bold
                                       capitalize
                                       fontSize={14}
                                       placeholder={'Number of shares'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn/>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                Voting rights
                            </h4>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <NumberField name={'percentage_of_voting_rights'}
                                         isRequired
                                         bold
                                         capitalize
                                         maxLength={3}
                                         fontSize={14}
                                         placeholder={'Declare the percentage of voting rights'}
                                         changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <SelectField name={'percentage_of_voting_rights_type'}
                                         isRequired
                                         bold
                                         capitalize
                                         options={[{1: 'Direct'}, {2: 'Indirect'}]}
                                         fontSize={14}
                                         placeholder={'Describe the type of voting right'}
                                         changeCallback={e => this.collectFieldData(e, true)}
                            />
                        </SectionColumn>
                        <SectionColumn/>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                Appointment rights and influence
                            </h4>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn>
                                    {/*// these are pre-filled fields*/}
                                    <CheckBoxField name={'right_to_appoint'}
                                                   fontSize={14}
                                                   bold
                                                   placeholder={'This beneficiary has the right to appoint board members'}
                                                   changeCallback={e => this.collectFieldData(e, true)}
                                    />
                                </SectionColumn>
                                <SectionColumn>
                                    <SelectField name={'type_of_appointment_right'}
                                        // isRequired
                                                 bold
                                                 capitalize
                                                 options={[{1: 'Direct'}, {2: 'Indirect'}]}
                                                 fontSize={14}
                                                 placeholder={'Select the type of appointments-making rights'}
                                                 changeCallback={e => this.collectFieldData(e, true)}
                                    />
                                </SectionColumn>
                                <SectionColumn/>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    {/*// these are pre-filled fields*/}
                                    <CheckBoxField name={'has_significant_influence_over_company'}
                                                   fontSize={14}
                                                   bold
                                                   placeholder={'This beneficiary has significant influence over our company'}
                                                   changeCallback={e => this.collectFieldData(e, true)}
                                    />
                                </SectionColumn>
                                <SectionColumn>
                                    <SelectField name={'type_of_influence'}
                                        // isRequired
                                                 bold
                                                 capitalize
                                                 options={[{1: 'Direct'}, {2: 'Indirect'}]}
                                                 fontSize={14}
                                                 placeholder={'Select the type of influence of this beneficiary'}
                                                 changeCallback={e => this.collectFieldData(e, true)}
                                    />
                                </SectionColumn>
                                <SectionColumn/>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                Documents
                            </h4>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <FileSelectField name={'bof_id_file'}
                                             placeholder={'ID file'}
                                             bold
                                             isRequired
                                             changeCallback={e => this.collectFieldData([e.target.name, e.target.files[0]])}

                            />
                        </SectionColumn>
                        <SectionColumn>
                            <FileSelectField name={'bof_kra_file'}
                                             placeholder={'KRA pin certificate'}
                                             bold
                                             isRequired
                                             changeCallback={e => this.collectFieldData([e.target.name, e.target.files[0]])}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <FileSelectField name={'bof_cogc_file'}
                                             placeholder={'Certificate of Good Conduct'}
                                             bold
                                             isRequired
                                             changeCallback={e => this.collectFieldData([e.target.name, e.target.files[0]])}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <FileSelectField name={'bof_kra_tcc_file'}
                                             placeholder={'TCC file'}
                                             bold
                                             isRequired
                                             changeCallback={e => this.collectFieldData([e.target.name, e.target.files[0]])}
                            />
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                {this.state.currentRoute}
                <SectionColumn/>
                <SectionColumn>
                    <SubmitButton commandText={'Declare'} callback={() => {
                        // send request to backend and receive a response. if proper, navigate to list bof members
                        // also, if true, null backup before navigating
                        // create a formdata object
                        let fd = new FormData();
                        let k = 0;
                        let stringifiableObject = {}; // stores all stringifiable data
                        let p = Object.getOwnPropertyNames(this.state.e);
                        // const fileRegExp = new RegExp(/^[a-z_]{0,}_file$/);
                        p.map((propertyName, index) => {
                            // fd.append(propertyName, this.state.e[propertyName]);
                            console.log(propertyName.search('_file'))
                            if (propertyName.search('_file') > -1) // if a file has been found,
                                // pick it else put the item found in the list of stringifiable objects.
                                fd.append(propertyName, this.state.e[propertyName]);
                            else
                                stringifiableObject[propertyName] = this.state.e[propertyName];
                        });
                        if (Object.getOwnPropertyNames(stringifiableObject).length > 0)
                            fd.append('data', JSON.stringify(stringifiableObject));
                        // send request to backend with this data then go to list bof
                        this.sendRequest('post', 'company/operator/bof', response => {
                            if (response.type === 'Success') {
                                notify(response.message, 4, true);
                                this.navigateTo('../list-bof-members');
                            } else
                                notify(
                                    <span>
                                        This BOF member has not been declared.<br/>The reported error was: {response.message}
                                    </span>,
                                    2,
                                    false,
                                    'BOF member not declared!')
                        }, fd);
                    }}/>
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>

        </Section>
    }
}