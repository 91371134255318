import React from "react";
import BaseField from "./base/BaseField";
import {notify} from "../../../MiscUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

/**
 *
 * NumberField defines a type where the props
 *
 */
const hasNonAllowedSymbols = new RegExp(/^[~`\[\]'"\w\t{}()\/\\]$/);
const hasMandatoryPasswordSymbols = new RegExp(/[!@#%^&*+_]/);
const hasMandatoryPasswordFormat = new RegExp(/[A-Za-z0-9!@#%^&*+_]/);
export default class PasswordField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    /**
     *
     * Notify that the password is wrong
     *
     * @param message the message text to display
     * @param priority the colour to accompany that message
     */
    notifyPasswordState = (message = 'Password is of the wrong format', priority = 2) => {
        // notify and draw a bar below
        notify(message, priority, true);
    }
    /**
     *
     * Check password strength. Password must:
     *  - contain at least one capital letter, at least one small letter
     *  - contain at least one special character
     *  - no quotation marks
     *  - no '~' nor tick marks mark
     *
     * @param e
     * @returns {boolean}
     */
    checkPasswordStrength = () => {
        let isPasswordStrong = false;
        if (this.props.doNotCapture || this.props.disablePasswordTest || this.props.disableTest)
            return true;
        //
        if (hasNonAllowedSymbols.test(this.state.currentValue))
            // notify password has invalid characters.Remove white spaces,
            this.notifyPasswordState('Password has invalid characters. ' +
                'Remove any of the following: ~`[, ], \', ", white space, tabs, { or }', 2);
        // check whether there are special symbols in the password
        else if (!hasMandatoryPasswordSymbols.test(this.state.currentValue))
            // notify password is lacking mandatory special symbols
            this.notifyPasswordState('Password is lacking a special symbol. It must include at least one ' +
                '!, @, #, %, ^, &, *, +, - or _', 2);

        // check whether the password has text
        else if (!hasMandatoryPasswordFormat.test(this.state.currentValue))
            // notify password has the wrong format
            this.notifyPasswordState('Password does not have the correct format', 2);
        else {
            isPasswordStrong = true;
            // notify password is Ok
            // this.notifyPasswordState('Password is valid', 4);
        }
        if (this.props.passwordCorrectCallback)
            this.props.passwordCorrectCallback(isPasswordStrong)
        return isPasswordStrong;
    }
    /**
     *
     * Method is used from the react reference of this component in order that password field is switched between
     * visible and hidden.
     *
     */
    togglePasswordView = () => {
        const activeControl = String('#6ac417');
        const inactiveControl = String('#646262');
        this.setState({
            eyeSlash: !activeControl,
            colour: this.state.colour === activeControl ? inactiveControl : activeControl,
            _switch: !this.state._switch
        });
    }

    render = () => {
        /*
         * onFocus allows the legend to be changed
         */
        return (
            <div className={`form-group`}>
                <label
                    className={`${this.state.selection} form-label`}
                    style={{
                        textAlign: "left",
                        ...this.textStyling
                    }}
                >
                    {this.fieldPlaceHolder}
                    {this.isRequired}
                </label>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {
                        this.state._switch ?
                            <input
                                {...this.required}
                                ref={this.internalFieldReference}
                                className={"form-control"}
                                style={{width: "100%"}}
                                name={this.name}
                                value={this.state.currentValue}
                                type={"text"}
                                onFocus={this.highlightOnFocus}
                                onChange={e => {
                                    if (!this.props.doNotCapture)
                                        this.setState({currentValue: e.target.value});
                                    this.changeCallback(e);
                                }}
                                onBlur={e => {
                                    this.evaluateControlOnRequired(e);
                                    this.checkPasswordStrength();
                                    this.blurCallback(e);
                                }}
                                onMouseOut={e => {
                                    this.evaluateControlOnRequired(e);
                                    this.checkPasswordStrength();
                                    this.mouseOutCallback(e);
                                }}
                            /> :
                            <input
                                {...this.required}
                                ref={this.internalFieldReference}
                                className={"form-control"}
                                style={{width: "100%"}}
                                name={this.name}
                                type={"password"}
                                onFocus={this.highlightOnFocus}
                                onChange={e => {
                                    if (!this.props.doNotCapture)
                                        this.setState({currentValue: e.target.value});
                                    this.changeCallback(e);
                                }}
                                onBlur={e => {
                                    this.evaluateControlOnRequired(e);
                                    this.checkPasswordStrength();
                                    this.blurCallback(e);
                                }}
                                onMouseOut={e => {
                                    this.evaluateControlOnRequired(e);
                                    this.checkPasswordStrength();
                                    this.mouseOutCallback(e);
                                }}
                            />
                    }
                    {
                        this.props.enablePasswordView &&
                        <FontAwesomeIcon icon={this.props.enablePasswordView ? faEye : faEyeSlash}
                                         style={{
                                             cursor: 'pointer',
                                             padding: 8,
                                             width: 32,
                                             height: 24,
                                             color: this.state.colour
                                         }}
                                         onClick={() => {
                                             // show password text
                                             this.togglePasswordView();
                                         }}
                        />
                    }
                </div>

                {this.state.possibleContextMessageBox}
                {/*the above is more useful in password confirmation activities*/}
            </div>
        );
    };

    /**
     * kazi ya monday:
     *
     *
     *
     */
}
