import React from "react";
import {render} from 'react-dom';

import CloseControl from "./components/general-components/controls/svg-controls/CloseControl";
// import {createBrowserHistory} from 'history';
import './components/general-components/messenger/messenger.css';
// import * as CryptoJS from "crypto";

const CRYPTOHMAC_SHA256 = require('crypto-js/hmac-sha256');
const CRYPTOHMAC_SHA512 = require('crypto-js/hmac-sha512');
// messaging behaviour globally so long as it's imported
/*********************************************************/
const listOfPriorityColours = {
    1: "#1259e6",//blue for information
    2: "#cc0000", //red for error
    3: "#fca708", //warning
    4: "#188e09", //success
    5: "#e9e8ed", //general-components_to_delete info
};
//
// const mMessageColour = {
//     info: '#1821f2',
//     warn: '#f5a90d',
//     danger: '#d20101',
//     notification: '#f0e7e7'
// };
/*********************************************************/
export const col1 = "lg-1 xs-1 sm-1 col-md-1",
    col2 = "lg-2 sm-2 xs-2 col-md-2",
    col3 = "lg-3 xs-3 sm-3 col-md-3",
    col4 = "lg-4 xs-4 sm-4 col-md-4",
    col5 = "lg-5 xs-5 sm-5 col-md-5",
    col6 = "lg-6 xs-6 sm-6 col-md-6",
    col7 = "lg-7 xs-7 sm-7 col-md-7",
    col8 = "col-md-8 lg-9 xs-8 sm-8",
    col9 = "col-md-9 lg-9 xs-9 sm-9",
    col10 = "lg-10 xs-10 sm-10 col-md-10",
    col11 = "lg-11 xs-11 sm-11 col-md-11",
    col12 = "lg-12 xs-12 sm-12 col-md-12",
    row = "row",
    container = "container",
    containerFluid = "container-fluid";

/**
 *
 * Create a prototype for a String object prototype method that allows the capitalization
 * of the first letter in a sentence
 *
 */
export const sentenceCase = (strValue) => {
    let d = '';
    let x = 0;
    const bannedChars = ['\w', '\n', '\\', '\'', '\"', '\0', '\t', '\v', '\D'];
    do {
        if (x === 0) {
            if (!bannedChars.includes(d[0])) d += strValue[0].toUpperCase();
        } else d += strValue[x];
        x += 1;
    } while (x < strValue.length)
    return d;
}
/**
 *
 * Convert array of jsons into a json object
 * @param arrayOfJSONS array of jsons in question [{},{},...]
 * @param switchValuesWithKeys whether to switch keys and values (like reverse them)
 * @returns {{}} json with consolidated json data in one object
 */
export const arrayOfJSONsToJSON = (arrayOfJSONS, switchValuesWithKeys = false) => {
    let json = {};
    let k = 0;
    let reversedJSON = {};
    do {
        const currentJsonKey = Object.getOwnPropertyNames(arrayOfJSONS[k])[0];
        json[currentJsonKey] = arrayOfJSONS[k][currentJsonKey];
        if (switchValuesWithKeys)
            reversedJSON[arrayOfJSONS[k][currentJsonKey]] = currentJsonKey;
        k += 1;
    }
    while (k < arrayOfJSONS.length)
    //console.log(json, reversedJSON)
    return switchValuesWithKeys ? reversedJSON : json;
}

/**
 *
 * Create a sentence out of an array of items
 *
 * @param arrayOfData array containing data items to convert to string
 * @param asSentence allow it to come up as a sentence by adding a 'and' joiner word
 *                      before the last word or element of the arrayOfData
 * @returns {string} the sentence-ed content/string.
 *
 */
export const arrayToSentence = (arrayOfData = [], asSentence = false) => {
    let k = 0;
    let sentence = "";
    if (arrayOfData.constructor.name === [].constructor.name && arrayOfData.length > 0)
        do {
            if (asSentence) {
                if (k > 0 && k < arrayOfData.length - 1)
                    sentence += ', ';
                if (k === arrayOfData.length - 1) {
                    sentence += ' and ';
                }
            } else {
                if (k > 0 && k < arrayOfData.length - 1)
                    sentence += ',';
            }
            // outside the asSentence check
            if (k > 0 && k < arrayOfData.length - 1)
                sentence += ',';
            sentence += arrayOfData[k];
            k += 1;
        } while (k < arrayOfData.length)
    return sentence;
}
/**
 *
 * Convert array of jsons into a array object containing all values of the object in question
 * @param arrayOfJSONs array of jsons in question [{},{},...]
 * @returns {{}} json with consolidated json data in one object
 */
export const arrayOfJSONsToValuesARRAY = arrayOfJSONs => {
    return Object.values(arrayOfJSONsToJSON(arrayOfJSONs));
}
/**
 *
 * gets a sentence out of symbols and a json object containing the keys of these symbols and respective valyes.
 * @returns {string}
 *
 */
export const getStringifiedDataAsSentence = (arrayData, source) => {
    let dataSentence = '';
    let x = 0;
    do {
        if (x === arrayData.length - 1)
            dataSentence += ' and ';
        if (x > 0 && x < arrayData.length - 1)
            dataSentence += ', ';
        dataSentence += `${extractValueFromJSON(arrayData[x], arrayOfJSONsToJSON(source))}`;
        x += 1;
    } while (x < arrayData.length)
    return dataSentence;
}
/**
 *
 * Serialize an array of data items, comma-separated into specified columns.
 * After that, return a array of json objects, each object having a key-value pair
 * matching the column:data items.
 *
 * @param columns array of column names
 * @param data array of comma-separated data (preferably). Alternately, via an array of arrays
 * @returns {[]} a array of jsons
 */
export const serializeDataWithColumns = (columns = [], data = []) => {
    let serializedData = []; // an array of jsons
    // loop through the data and use that to serialize each column with its data
    const splitData = data => {
        return data.split(',');
    }
    let i = 0;
    data.map((datum, index) => {
        let d = datum;
        if (datum.indexOf(',') !== -1)
            d = splitData(datum);
        let k = {};// the object, value that will record these items.
        columns.map((column, index) => {
            k[column] = d[index];
        });
        serializedData.push(k);
    });
    return serializedData;
}
/**
 *
 * Show a notification specifically for csv files
 * @param record the record in question
 * @param line the line that is affected
 */
export const csvNotify = (record, line) => {
    notify(<span>Encountered an error while processing the record <br/>
                 <i>{record}</i>, at line {line + 1}!
                 <br/>
                 Ensure that <b>all csv records</b> in this file <b>follow the format shown in the illustration</b>
            &nbsp;provided in this page.
                 </span>,
        2,
        false,
        'Error processing CSV');
}
/**
 *
 * Parse a csv file in order to facilitate population of employees and their data
 *
 * @param csvFile
 *
 * @param callback The callback to call when the file is completed being read
 */
export const parseCsv = (csvFile, callback) => {
    let records = [];
    let fR = new FileReader();
    if (csvFile instanceof File || csvFile instanceof Blob) {
        let fileString;
        fR.readAsText(csvFile, 'UTF-8');
        fR.onloadend = (e) => {
            fileString = e.target.result;
            if (callback !== undefined) {
                callback(fileString);
            }
        };
        return records;
    }
}
/**
 *
 * Generate a hmac message in hex format sha256.
 *
 * @param hash_key the key to hash the message with
 * @param message the message to hash
 * @param encoding the encoding to use. Either use SHA-256 or SHA-512
 * @returns {Promise<string>}
 *
 */
const hmacShaHex = async (message, hash_key, encoding = 'SHA-256' | 'SHA-512') => {
    const enc = new TextEncoder("utf-8");
    const algorithm = {name: "HMAC", hash: encoding};
    const key = await crypto.subtle.importKey(
        "raw",
        enc.encode(hash_key),
        algorithm,
        false, ["sign", "verify"]
    );
    const hashBuffer = await crypto.subtle.sign(
        algorithm.name,
        key,
        enc.encode(message)
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(
        b => b.toString(16).padStart(2, '0')
    ).join('');
    return hashHex;
}


export const secureHashedData = (data = [],
                                 hashing_algorithm = 'hmac_256' | 'hmac_sha256',
                                 secret = null,
                                 key = null,
                                 add_hash_to_data = true) => {
    // check if data is a series of objects. if not, throw a type error
    let c = 0;
    let hashable_string = String();
    let hash_string = String();
    //concatenate all data in the objects of the payload in question.
    do {
        // check type (future proof this)
        if (typeof data[c] !== 'object')
            throw new TypeError(`Data object ${data[c]} in index ${c} is not a JSON object of data`);
        else if (Object.keys(data[c]).length > 1)
            throw new TypeError(`Data object ${data[c]} in index ${c} MUST have exactly one object item. 
            Found ${Object.keys(data[c]).length} items!`);
        else { //actual concatenation
            hashable_string += Object.values(data[c])[0];
        }
        c += 1;
    } while (c < data.length);
    // add the secret
    if (secret)
        hashable_string += secret;
    // check out the hashing algorithm
    if (key === null)
        throw new SyntaxError(`Argument 4 of this function expected a valid key. Found ${key}!`);
    //console.log('BUK1234UK hash is ', btoa(CRYPTOHMAC_SHA256('10BUK1234UK','6Lb+OWKRnPbEGPb9')))
    if (hashing_algorithm === 'hmac_256')
        hash_string = CRYPTOHMAC_SHA256(hashable_string, key);
    // hash_string = CryptoJS.HmacSHA256(hashable_string,key);
    else if (hashing_algorithm === 'hmac_512')
        hash_string = CRYPTOHMAC_SHA512(hashable_string, key);

    //...
    //...
    //...(add other algorithms on a need basis
    //...
    //...
    //console.log('hashable string ', hash_string)
    let d = [...data];
    if (add_hash_to_data)
        d.push({secureHash: btoa(hash_string)}); // encode to base64
    // d.push({secureHash: Buffer.from(hash_string.words).toString('base64')}); // encode to base64
    else d = {
        secureHash: btoa(hash_string),
        // secureHash: Buffer.from(hash_string.words).toString('base64'),
        // secureHash: Base64.stringify(hash_string),
        data: data
    }
    return d;
}
/**
 *
 * Shows a full message window.
 *
 * Take note of the setState callback method there
 *
 * @param itemToShow the component or items to show in the full window
 * @param title title of the window to set as context
 *
 * @param navigateBackCallback tell the fullWindowInterface to replace root with message-curtain and allow CloseControl
 *                    component to switching.
 *
 */
export const fullWindowInterface = (itemToShow, title, navigateBackCallback = null) => {
    const overlay = document.querySelector("#overlay");
    const root = document.querySelector('#root');
    const removeMessage = () => {
        //() => {
        //     //console.log('Full window interface method/function does not have a callback method enabling naigation back to the component')
        // }
        navigateBackCallback ? navigateBackCallback() :
            render(null, overlay);
    };
    let styling = {
        position: 'relative',
        top: 0,
        left: 0,
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        height: 'fit-content',
        minBackground: 'auto'

    };
    //
    // Check if itemsToShow is a function. if it is, execute the funciton
    if (itemToShow instanceof Function) {
        itemToShow = itemToShow();
    }
    //
    const fullWindowOnCurtain =
        <div style={navigateBackCallback === null ? null : styling} className={'message-curtain'}>
            <div className={'full-window-interface'}>
                <div className={'title-bar-full-window-interface'}>
                    <div className={'message-title'}>
                        {title}
                    </div>
                    {/*<div className={'spacer-column-32'}/>*/}
                    {/*<div className={'spacer-column-32'}/>*/}
                    <div className={'no-print'}>

                        <CloseControl callback={removeMessage}/>
                    </div>
                </div>
                <div className={'small-space-row'}/>
                {itemToShow}
            </div>
        </div>;
    // render(fullWindowOnCurtain, overlay);
    render(fullWindowOnCurtain, navigateBackCallback !== null ? root : overlay);
}

/**
 *
 * Shows a message window with a message for confirmation purposes. Its not meant to distract the user, rather acts like a notification.
 * @param priority An integer (1 - 5) representing some pre-defined
 *                  colour-coding of the message (think of it like a theme)
 *
 * Take note of the setState callback method there
 *
 * @param title title of the messenger title bar.
 *
 * @param messageElement - A html element styling the message to show
 *
 * @param priority - The colour of the side flag desired
 *
 * @param callbacks - an array of objects of callback functions[{text: button text: callback: callbackFunction, colour: <int>},...]
 *
 */
export const confirmAction = (title, messageElement, priority = 5, callbacks = []) => {
    // document body appendChild: message then fade it out slowly fo 8 seconds. remove it after 10 seconds
    let nextLeft = 5;
    // const overlay = document.querySelector("#overlay");
    const overlayPersistentWindow = document.querySelector("#overlay-persistent-window");
    const removeMessage = () => {
        // set the remove class for curtain then remove element by 0.35s
        let a = document.getElementsByClassName('slow-curtain-in')[0];
        if (a) {
            a.classList.add('slow-curtain-out');
            // a.classList.remove('slow-curtain-in');
            window.setTimeout(() => {
                render(null, overlayPersistentWindow);
            }, 360);
        }
    }
    // change the styling for message priority 5
    // const priority5Style = priority === 5 ? {color: '#1313E1FF'} : null;
    // check whether it's a message bubble or a entire window
    //
    const messageWithCurtain = <div className={'slow-curtain-in'}>
        <div className={'message-window'}>
            {/*// side bar*/}
            <div className={'indicator-bar'} style={{background: listOfPriorityColours[priority]}}/>
            {/*// title*/}
            <div className={'message-space'}>
                <div className={'title-bar'}>
                    <div className={'message-title'}>
                        {title}
                    </div>
                    <div>
                        <CloseControl callback={removeMessage}/>
                    </div>
                </div>
                <div className={'message'}>
                    {messageElement}
                </div>
                <br/>
                {
                    callbacks.map((item, index) => {
                        const width = 100 / callbacks.length;
                        nextLeft = width + 5;
                        return <div className={'btn submit-buttons'} style={{
                            position: 'relative',
                            margin: 4,
                            // left: `${nextLeft}%`,
                            width: `${(100 / callbacks.length) - 2}%`,
                            background: listOfPriorityColours[item['priority']]
                        }} onClick={() => {
                            //if(['cancel','no'].includes(item['text'].toLowerCase()))
                            item['callback']();
                            removeMessage();
                        }}>
                            {item['text']}
                        </div>
                    })
                }
            </div>
        </div>
    </div>;
    render(messageWithCurtain, overlayPersistentWindow);
}

/**
 *
 * Shows a message bubble with a message. Its not meant to distract the user, rather acts like a notification.
 * @param message the message text to show
 * @param priority An integer (1 - 5) representing some pre-defined
 *                  colour-coding of the message (think of it like a theme
 *
 * Take note of the setState callback method there
 * @param bubble
 *
 * @param title title of the messenger whtn the bubble is false
 *
 * @param allowAutoRemove
 *
 */
export const notify = (message, priority = 5, bubble = false, title = null, allowAutoRemove = true) => {
    // document body appendChild: message then fade it out slowly fo 8 seconds. remove it after 10 seconds
    const overlay = document.querySelector("#overlay");
    const overlayPersistentWindow = document.querySelector("#overlay-persistent-window");
    const removeMessage = () => {
        if (bubble)
            render(null, overlay)
        else {
            // set the remove class for curtain then remove element by 0.35s
            let a = document.getElementsByClassName('slow-curtain-in')[0];
            if (a) {
                a.classList.add('slow-curtain-out');
                // a.classList.remove('slow-curtain-in');
                window.setTimeout(() => {
                    render(null, overlayPersistentWindow);
                }, 360);
            }
        }
    };
    // change the styling for message priority 5
    const priority5Style = priority === 5 ? {color: '#1313E1FF'} : null;
    // check whether it's a message bubble or a entire window
    //
    const messageWithCurtain =
        <div className={'slow-curtain-in'}>
            <div className={'message-window'}>
                {/*// side bar*/}
                <div className={'indicator-bar'} style={{background: listOfPriorityColours[priority]}}/>
                {/*// title*/}
                <div className={'message-space'}>
                    <div className={'title-bar'}>
                        <div className={'message-title'}>
                            {title}
                        </div>
                        {/*<div>*/}
                        {/*    <CloseControl callback={removeMessage}/>*/}
                        {/*</div>*/}
                    </div>
                    <div className={'message'}>
                        {message}
                    </div>
                    <br/>

                    <div className={'btn submit-buttons'} style={{
                        position: 'relative',
                        left: '24%',
                        width: '40%',
                        background: listOfPriorityColours[priority]
                    }} onClick={removeMessage}>
                        Close
                    </div>
                </div>
            </div>
        </div>;
    // declare a messageWindow
    // className={'bubble-message-window'}
    const mWindow = <div className={`${row} bubble-message-window`}
                         style={{border: `1px solid ${listOfPriorityColours[priority]}`}}>
        {
            priority === 1 ?
                <div className={`${col2} info message-symbol`}/> :
                priority === 2 ?
                    <div className={`${col2} error message-symbol`}/> :
                    priority === 3 ?
                        <div className={`${col2} warn message-symbol`}/> :
                        priority === 4 ?
                            <div className={`${col2} success message-symbol`}/> :
                            <div className={`${col2} info message-symbol`}/>
        }
        <div style={{borderRight: `1px solid ${listOfPriorityColours[priority]}`}}/>
        <div className={`${col9} message-bubble`}
             style={{
                 background: listOfPriorityColours[priority],
                 color: '#FFFFFF'
             }}>
            {message}
        </div>
        {/*<div*/}
        {/*    className={col12}*/}
        {/*    style={{height: '100%'}}*/}
        {/*>*/}
        {/*    <div className={`${row} embossed-text`}*/}
        {/*         style={{*/}
        {/*             background: listOfPriorityColours[priority], ...priority5Style, color: priority === 5 ?*/}
        {/*                 '#242425' : '#FFFFFF'*/}
        {/*         }}>*/}
        {/*        <div className={'message-bubble'}>*/}
        {/*            {message}*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>
    //
    //
    //
    if (allowAutoRemove) {
        if (bubble) {
            render(mWindow, overlay);
            window.setTimeout(removeMessage, 4800)
        } else
            render(messageWithCurtain, overlayPersistentWindow)
    } else {
        // return a function to allow removal of the notification interface
        if (bubble) return () => {
            removeMessage()
        }
        //     return () => {
        //     if(bubble)render(messageWithCurtain, overlayPersistentWindow)
        // }
    }

}
/**
 *
 * Remove drawer layout: This is a utility method without passing around references
 *
 */
export const removeDrawerLayout = () => {
    const overlay = document.querySelector("#overlay");
    render(null, overlay);
}
/**
 *
 * Shows a message bubble with a message. Its not meant to distract the user, rather acts like a notification.
 * @param customUI the customUI to show
 * Take note of the setState callback method there
 *
 * @param withoutClose enable or disable close button
 */
export const setDrawerLayout = (customUI = <>no-customUI-found</>, withoutClose = false) => {
    // document body appendChild: message then fade it out slowly fo 8 seconds. remove it after 10 seconds
    const overlay = document.querySelector("#overlay");
    const removeMessage = () => {
        // set the remove class for curtain then remove element by 0.35s
        let a = document.getElementsByClassName('slow-curtain-in')[0];
        if (a) {
            a.classList.add('slow-curtain-out');
            // a.classList.remove('slow-curtain-in');
            window.setTimeout(() => {
                render(null, overlay);
            }, 360);
        }
    };
    const layoutOnCurtain =
        <div className={'slow-curtain-in'}>
            <div style={{display: 'flex', flexDirection: 'row', paddingTop: 20}}>
                <div style={{width: '96%', height: 20}}/>
                {!withoutClose && <CloseControl colour={'#d21919'} callback={removeMessage}/>}
            </div>
            {customUI}
        </div>;
    render(layoutOnCurtain, overlay)
}
/**
 *
 * Get the value out of some json data by specifying the key
 * @param e the key to use in getting that data
 * @param sourceData the array of json arrays which contains the source of this data
 * @returns {null|*} the <string> value of this item
 *
 */
export const extractValueFromJSON = (e, sourceData) => {
    //e is list of games
    const keys = Object.getOwnPropertyNames(sourceData);
    if (keys.includes(String(e)))
        return sourceData[e];
    // for (const t of keys) {
    //     if (keys.includes(String(e))) {
    //         return sourceData[e];
    //     }
    // }
    // do not return a game
    return null; //return <span style={{fontSize: '0.4em'}}><i>to be selected</i></span>;
}
/**
 *
 * Do a 'reverse lookup' on a json object when the value is specified in order to
 * extract and return its key.
 *
 * @param e the value whose key is required
 * @param sourceData the json data to look up in
 * @returns {null} the key if present, or null
 */
export const extractKeyFromJSON = (e, sourceData) => {
    //e is list of games
    const values = Object.values(sourceData);
    const keys = Object.getOwnPropertyNames(sourceData);
    let key = null;
    if (values.includes(e)) {
        key = values.indexOf(e);
        key = keys[key];
    }
    return key;
}
/**
 *
 * Creates a JSONified stirng but without escape symbols >> \" for all items
 *
 * @param miscData the json to reformat
 * @returns {string} the stringLikeJSON string object
 */
export const getStringLikeJSON = miscData => {
    let x = 0;
    const propNames = Object.getOwnPropertyNames(miscData);
    let stringLikeJSON = '{'
    do {
        stringLikeJSON += `"${propNames[x]}":"${miscData[propNames[x]]}"`
        if (x < propNames.length - 1)
            stringLikeJSON += ','
        x += 1;
    } while (x < propNames.length)
    stringLikeJSON += '}';
    return stringLikeJSON;
}
/**
 *
 * Get current date of the day, based off of the time on the local machine.
 * NOTE: Use with extra care because this number is dependent on the user's
 * machine's time.
 * @param likeConversation a boolean value setting the format of the date to
 *                      a conversation-driven format.
 * @returns {JSX.Element} comprising of {number<sup>text</sup> month, year} if like conversation is true or
 *                        {`${number}/${number}/${number}`} the date in question
 *
 *
 */

export const getDate = (likeConversation = false) => {
    const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    let dateFormat = null; //jsx
    let dateObj = new Date();
    //NOTE: getDay means something but not the actual date number!
    if (likeConversation) {
        const dateNumber = dateObj.getDate();
        const havingRd = [3, 23];
        const havingNd = [2, 22];
        const havingSt = [1, 21, 31];
        dateFormat = <span>
            {dateNumber}
            <sup>
                {
                    havingRd.includes(dateNumber) ?
                        'rd' : havingNd.includes(dateNumber) ?
                            'nd' : havingSt.includes(dateNumber) ?
                                'st' : 'th'
                }
            </sup>
            &nbsp;
            {monthList[dateObj.getMonth()]}, {dateObj.getFullYear()}
        </span>;
    } else
        dateFormat = <span>{dateObj.getDate()}/{dateObj.getMonth()}/{dateObj.getFullYear()}</span>;
    //console.log('date format for ', dateFormat)
    return dateFormat;
}
/**
 *
 * Read from local storage and fetch data or return entire storage if no specific item is required
 *
 * @param itemToFetch specific item to read
 * @param fromStorageName the name of the local storage object
 * @returns {null} the entire storage or the specified item else null (if the specified storage name is absent.
 *
 *
 */
export const readFromLocalStorage = (itemToFetch = null, fromStorageName) => {
    let storageData = null;
    if (fromStorageName) {
        storageData = localStorage.getItem(fromStorageName);
        if (storageData)
            storageData = JSON.parse(storageData);
        if (itemToFetch)
            storageData = storageData[itemToFetch];
    }
    return storageData;
}
/**
 *
 * gets the storage data
 * @param fromName
 *
 */
export const getStorageObject = fromName => {
    const storage = JSON.parse(localStorage.getItem(fromName));
    return storage
}
/**
 *
 * Function converts base64 strings to file objects
 * @param base64 the base64 string to convert
 * @param filename the file name to append to the file
 * @returns {File} the file object
 */
export const base64ToFileObject = (base64, filename) => {

    let arr = base64.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
}
/**
 *
 * Formats date based on the desired format. Defaults to dmy (day month year)
 * @param date the date to reformat
 * @param currentFormat the current format of this date
 * @param desiredFormat the desired format
 * @param desiredSeparator the desired separator to put between the dates
 * @returns {string} reformatted date.
 *
 */
export const formatDate = (date = String(), currentFormat = 'dmy', desiredFormat = 'dmy', desiredSeparator = '/') => {
    const separators = ['/', '.', '-', ' ', '\w'];
    let formattedDate = String();
    let splitDate = [];
    let k = 0;
    do {
        if (date.indexOf(separators[k]) > 0) {
            splitDate = date.split(separators[k]);
            break;
        }
        k += 1;
    } while (k < separators.length);
    // one, two, three represent the current format that's known.
    if (desiredFormat === currentFormat)
        formattedDate = date;
    else if (currentFormat === 'dmy' && desiredFormat === 'dmy')
        formattedDate = `${splitDate[0]}${desiredSeparator}${splitDate[1]}${desiredSeparator}${splitDate[2]}`;
    else if (currentFormat === 'dmy' && desiredFormat === 'ymd')
        formattedDate = `${splitDate[2]}${desiredSeparator}${splitDate[1]}${desiredSeparator}${splitDate[0]}`;
    else if (currentFormat === 'dmy' && desiredFormat === 'ydm')
        formattedDate = `${splitDate[2]}${desiredSeparator}${splitDate[0]}${desiredSeparator}${splitDate[1]}`;
    //////////////////////////////////////////
    else if (currentFormat === 'ymd' && desiredFormat === 'ydm')
        formattedDate = `${splitDate[0]}${desiredSeparator}${splitDate[2]}${desiredSeparator}${splitDate[1]}`;
    else if (currentFormat === 'ymd' && desiredFormat === 'dmy')
        formattedDate = `${splitDate[2]}${desiredSeparator}${splitDate[1]}${desiredSeparator}${splitDate[0]}`;
    else if (currentFormat === 'ymd' && desiredFormat === 'mdy')
        formattedDate = `${splitDate[1]}${desiredSeparator}${splitDate[0]}${desiredSeparator}${splitDate[2]}`;
    //////////////////////////////////////////
    else if (currentFormat === 'mdy' && desiredFormat === 'ydm')
        formattedDate = `${splitDate[2]}${desiredSeparator}${splitDate[1]}${desiredSeparator}${splitDate[0]}`;
    else if (currentFormat === 'mdy' && desiredFormat === 'ymd')
        formattedDate = `${splitDate[2]}${desiredSeparator}${splitDate[0]}${desiredSeparator}${splitDate[1]}`;
    else if (currentFormat === 'mdy' && desiredFormat === 'dmy')
        formattedDate = `${splitDate[1]}${desiredSeparator}${splitDate[0]}${desiredSeparator}${splitDate[2]}`;
    //
    //
    return formattedDate;
}
/**
 *
 * Deconstructs a name from its constituent symbols. By default, it'll deconstruct along a '_' symbol.
 * Alternatively, it'll do so along the capital letters in a given snake-cased word.
 *
 * @param variableName the variable name to deconstruct
 * @param separator the symbol to deconstruct along...
 * @returns {*[]} the deconstructed name.
 *
 */
export const nameFromVariableName = (variableName, separator = '_') => {
    if (variableName.indexOf(separator) === -1)
        return variableName;
    let deconstructedName = variableName;//default return item
    const k = variableName.split(separator);
    if (k.length === 0) {
        //attempt at splitting along the camel case
        let words = [];
        let currentIndex = 0;
        const uppercaseRegExp = new RegExp(/^[A-Z]*$/);
        for (const k of variableName) {
            if (uppercaseRegExp.test(k)) {
                currentIndex += 1
            }
            //by default, add it to current
            words[currentIndex] += k;
        }
        deconstructedName = words;
    }
    //if k is greater than zero
    else {
        // this is an array
        // loop through and add a space in between where the symbol is
        deconstructedName = variableName.replaceAll(separator, ' ');
        // deconstructedName = k;
    }
    return deconstructedName;
}

//
/**
 *
 * Paraphrases a given string by returning a string with '...' (alama za dukuduku)
 * @param item the string to paraphrase
 * @param length the desired length defaults to 5 characters in the string
 * @returns {string|string} a paraphrased string, or the original string
 *
 */
export const paraphrase = (item, length = 5) => {
    if (item === undefined)
        return '';
    let _item_ = '';
    let k = 0;
    let isParaphrased = false;
    if (item.length >= length) {
        while (k <= length) {
            _item_ += item[k];
            k += 1;
            isParaphrased = true;
        }
    }
    return isParaphrased ? `${_item_}...` : item;
}
/**
 *
 * function to test equality of objects without using references, but instead
 * comparing the contents of these objects using their keys or attribute properties
 *
 * @param a object 1
 * @param b object 2
 * @returns {boolean} true for similarity else false
 *
 */
export const equalObjects = (a, b) => {
    const entries_a = Object.getOwnPropertyNames(a);
    const entries_b = Object.getOwnPropertyNames(b);
    if (entries_a.length !== entries_b.length) {
        return false;
    }
    for (let i in entries_a.length) {
        // Keys
        if (entries_a[i][0] !== entries_b[i][0]) {
            return false;
        }
        // Values
        if (entries_a[i][1] !== entries_b[i][1]) {
            return false;
        }
    }

    return true;
}
/**
 *
 * read file contents into a base64 string
 * @param _file the file object to read as base64
 * @param callback a callback method to execute when read is complete
 * @returns {string} the file in form of a base64 string
 *  that the file contents are translated to.

 */
export const convertToBase64 = (_file = File | Blob, callback = undefined) => {
    //console.log('file or array of files is ', _file, 'is blob ', _file instanceof Blob, ' is array ', _file instanceof Array)
    // Declare a conversion error message
    const conversionErrorMessage = `Argument 1 (_file) of Method 
        'convertToBase64' expected a file object or a base64 string (if already converted).
         Found "${_file.constructor.name}" instead!`
    // declare a ReferenceError message
    const callbackReferenceErrorMessage = `convertToBase64 requires a callback as a second argument.
        This is because this method's file-read activity is asynchronous and does not do well with returning
        its output. Pass a method reference(--without-arguments--) which will execute when
        the internal file-read-operation is complete.`;
    if (callback === undefined) {
        console.warn(callbackReferenceErrorMessage)
        notify(callbackReferenceErrorMessage, 3, false, 'file read callback recommended!');
        // throw new ReferenceError();
    }
    let fR = new FileReader();
    if (_file instanceof File || _file instanceof Blob) {
        let fileString;
        fR.readAsDataURL(_file);
        fR.onload = (e) => {
            fileString = e.target.result;
            if (callback !== undefined) {
                callback(fileString, _file);
            }
        };
    } else if (_file instanceof String) {
        // find out if it's a base64 object
        let base64String = _file.split(',')[0].split(';')[0]
        if (base64String === 'base64')
            callback(_file)// this is a base64 object
    }
    /*This is an array of objects of the format: {category: <some-number>, content: <File-or-Blob-object>}*/
    else if (_file instanceof Array) {
        let files = [];
        let y = 0;
        let fileString;
        _file.forEach.call(_file, (currentFile) => {
            let fRD = new FileReader(); // initialize a new file-reader all the time
            fRD.readAsDataURL(currentFile.content);
            fRD.onloadend = () => {
                fileString = fRD.result;
                files.push(fileString);
            }
        });
        // call the callback on the data in question
        if (callback)
            callback(files);
    } else {
        console.error('|| convert-to-base64 error ->> ', conversionErrorMessage);
        notify(conversionErrorMessage,
            2, false, 'Base64 conversion error');
    }

    // throw new TypeError(`Argument 1 (_file) of Method 'convertToBase64' expected a file object or a base64 string (if already converted). Found ${typeof _file}`);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    container,
    containerFluid,
    col1,
    col2,
    col3,
    col4,
    col5,
    col6,
    col7,
    col8,
    col9,
    col10,
    col11,
    col12,
    // browserHistory,
    // withClassComponentNavigationSupport,
    notify,
    confirmAction,
    getDate,
    paraphrase,
    nameFromVariableName,
    equalObjects,
    convertToBase64,
    base64ToFileObject,
    extractValueFromJSON,
    extractKeyFromJSON,
    getStringLikeJSON,
    getStorageObject,
    readFromLocalStorage,
    arrayOfJSONsToValuesARRAY,
    getStringifiedDataAsSentence,
    fullWindowInterface,
    arrayToSentence,
    sentenceCase,
    removeDrawerLayout,
    secureHashedData,
    serializeDataWithColumns,
    parseCsv,
    csvNotify
};

/*"@babel/plugin-proposal-private-property-in-object"*/
