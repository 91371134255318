import React from "react";
import BaseField from "./base/BaseField";
import {notify} from "../../../MiscUtils";

/**
 *
 * NumberField defines a type where the props
 *
 */
export default class TextAreaField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.maxLetters = this.props.maxLetters ? this.props.maxLetters : 5600; // 1100 words including punctuation
    }

    //
    //
    /**
     *
     * Check for limit of words by spliting the content against white spaces then finding the length
     *
     */
    checkForWordsLimit = () => {
    }
    /**
     *
     * Calculate remaining letters
     *
     * @type {number}
     */
    calculateCurrentCapacity = (currentLetterCount) => {
        //currentLetterCount === this.state.maxCount ||
        if (this.state.count === 0)
            return;
        this.setState({count: this.state.maxCount - currentLetterCount})
    }
    /**
     *
     * set max count
     *
     */
    componentDidMount = () => {
        this.setState({
            enabled: Object.getOwnPropertyNames(this.props).includes('noPaste'),
            maxCount: this.maxLetters,
            count: this.maxLetters
        }, () => this.props.defaultValue && this.calculateCurrentCapacity(this.props.defaultValue.length));
    }
    /**
     *
     *
     *
     */
    checkForCharactersLimit = () => {
    }
    // impose limit based on characters or words
    render = () => {
        const resize = this.props.noResize ? {resize: 'none'} : null;
        /*
         * onFocus allows the legend to be changed
         */
        return (
            <fieldset
                style={{...this.props.style, minHeight: "140px !important", minWidth: "200px !important"}}
                className={` form-group border`}
            >
                <label
                    className={`${this.state.selection} form-label`}
                    style={{textAlign: "left", ...this.textStyling}}
                >
                    {this.fieldPlaceHolder}
                    {this.isRequired}
                    <span
                        style={{fontSize: '0.8em'}}> {this.state.count} left</span>
                    {/*/${this.state.maxCount}*/}
                </label>
                {
                    <textarea {...this.required}
                              name={this.name}
                              className={"form-control"}
                              style={{minWidth: '100%', ...resize, ...this.props.style}}
                              ref={this.internalFieldReference}
                              defaultValue={this.props.defaultValue}
                              onFocus={this.highlightOnFocus}
                        // onKeyDown={this.setNoCopyPaste}
                              onChange={e => {
                                  let content = e.target.value;
                                  this.calculateCurrentCapacity(content.length);
                                  // count the letters that are declared
                                  // this.changeCallback(e);
                                  if (this.state.count >= 0) {
                                      this.changeCallback(e);
                                  } else {
                                      this.stopTyping();
                                      notify(`You have ran out of space!`, 2, true);
                                      // take the current data and write it into the text area
                                      // e.target.value= content;
                                  }
                                  // if (!this.state.enabled) {
                                  //     // this.stopTyping();
                                  //     e.preventDefault();
                                  // } else {
                                  //     document.removeEventListener('keydown', this.internalCopyPasteMethodChecker)
                                  //     let content = e.target.value;
                                  //     this.calculateCurrentCapacity(content.length);
                                  //     // count the letters that are declared
                                  //     // this.changeCallback(e);
                                  //     console.log('count ', this.state.count)
                                  //     if (this.state.count >= 0) {
                                  //         this.changeCallback(e);
                                  //     } else {
                                  //         this.stopTyping();
                                  //         notify(`You have ran out of space!`, 2, true);
                                  //         // take the current data and write it into the text area
                                  //         // e.target.value= content;
                                  //     }
                                  // }
                              }}
                              onBlur={() => {
                                  this.evaluateControlOnRequired()
                                  this.blurCallback();
                              }}
                    />
                }

                {this.state.possibleContextMessageBox}
            </fieldset>
        );
    };
}
