import React from "react";
import {Section, SectionColumn, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
import DocumentDetailsCard from "../../general-components/document-viewer/DocumentDetailsCard";
import GMS from "../../parent-component/GMS";

/**
 *
 * List all documents in rows of data
 *
 */
export default class DocumentsList extends GMS {
    constructor(props) {
        super(props);
        this.state = {
            documentLinks: props.documentLinks ? props.documentLinks : [],
            dataItem1: []
        };
    }

    /**
     *
     * Prepare document list view by getting.
     *
     * @private a private method
     *
     */
    _prepareDocumentListView = () => {
        let p = [];
        // let actual_document_objects = [];
        let documentPaths = [];
        if (this.state.dataItem5.length > 0) {
            let counter = 0;
            // fetch the urls
            do {
                if (this.state.dataItem5[counter]['file_upload_status'] === true)
                    // documentPaths.push(this.state.dataItem5[counter]['file_url']);
                    documentPaths.push(this.state.dataItem5[counter]['file_url']);
                // documentPaths.push(this.state.dataItem5[counter]['file ']); // This is the original url with the IP address
                counter += 1;
            } while (counter < this.state.dataItem5.length)
            this.setState({
                dataItem4: documentPaths.length > 0 ?

                    <DocumentDetailsCard disableDownload documentList={documentPaths}/> : [...documentPaths]
            });

            // this.state.dataItem5.map(link => {
            //     if (link['file_upload_status'] === true) {
            //         //console.log(link['file_url'])
            //         this.getHeaders(link['file_url'], t => {
            //             const nameArray = link['file_url'].split('/');
            //             actual_document_objects.push({
            //                 name: nameArray[nameArray.length - 1],
            //                 size: t['headers']['content-length']
            //             });
            //             //console.log('actual document objects ',actual_document_objects)
            //             this.setState({
            //                 // dataItem1: [...p],
            //                 dataItem4: actual_document_objects.length > 0 ?
            //                     <DocumentDetailsCard disableDownload documentList={actual_document_objects}/> : [...p]
            //             });
            //         });
            //         counter += 1;
            //     }
            // });
        } else {
            p.push(<span>
                <div style={{
                    fontFamily: 'monospace',
                    fontSize: 28,
                    fontWeight: 800,
                    color: '#c5c6d5',
                    textAlign: 'center'
                }}>
                                No documents found/listed!
                </div>
                </span>);
        }
    }
    /**
     *
     * list rows of documents
     *
     *
     */
    componentDidMount = () => {
        this.setState({dataItem5: [...this.state.documentLinks]}, this._prepareDocumentListView);
    }


    render = () => {
        return <Section noBoxShadow>
            {
                !this.props.noTitle && <SectionTitle>List of Documents</SectionTitle>
            }
            {/*<div className={`${row} bottom-streak`} style={{height: 4, background: '#800080'}}/>*/}
            <SectionColumn style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>
                {this.state.dataItem4 === undefined ? 'No documents uploaded/found' : this.state.dataItem4}
            </SectionColumn>
        </Section>
    }
}