import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow} from "../view-utils/SectionUtils";
import DynamicTable from "../../general-components/tables/DynamicTable";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import {notify} from "../../../MiscUtils";
import {Dispatch} from "../../general-components/redux/app-storage";
import {LETTER, MISC_ID, SUBTITLE} from "../../general-components/redux/allowed-actions";
import LoadingData from "../../general-components/controls/data-loading/LoadingData";

export default class CorrespondencesList extends GMS {

    constructor(props) {
        super(props);
    }

    /**
     *
     * Go through the correspondences and sample them by response id. for the first item, if
     * there is a response set to it, loop through until you find the response with that id. When found,
     * insert them into list of responses.
     * @param correspondences
     *
     */
    threadUpCorrespondences = (correspondences = this.state.dataItem1) => {

        let groups = [];//array of arrays
        let i = 0;
        let lastGroup = [];
        do {
            if (correspondences[i]['response'] !== undefined || correspondences[i]['response'] !== '') {
                //
                lastGroup.push(correspondences[i]);
            } else groups.push(correspondences[i]);
        } while (i < correspondences.length);
    }
    /**
     * get list of correspondences from the backend. It comprises an array of
     * JSON data with the following format:
     * {
     *     subject: <string - 300>,
     *     is_read: <boolean>,
     *     from: <string - email/name>,
     *     to: <string - email/name>,
     *     date_created: <date>,
     *     from: <string - 3000>,
     *     response: <implicit self-reference key - like foreign key but internal. Used to link up various correspondences into a thread>,
     *     reference_number: <string - 20>
     *
     *  Use the state's dataItem1 to store outcome.
     * }
     */
    componentDidMount = () => {
        //
        this.sendRequest('get', 'notifications/correspondence', correspondences => {
            this.setState({visible: true});
            if (correspondences.type === 'Success')
                this.setState({dataItem1: [...correspondences.data]});
            else
                notify(correspondences.message, 1, false, 'Error getting correspondences');
        }, {})
    }

    render = () => {
        return <Section>
            {this.state.currentRoute}
            <SectionRow style={{alignItems: 'right'}}>
                <SectionColumn style={{width: 180}}>
                    <SubmitButton style={{background: '#4467d2'}}
                                  commandText={'Create New'}
                                  callback={() => this.navigateTo('../compose-letter')}/>
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    {!this.state.visible ? <LoadingData hasData={this.state.dataItem1.length === 0} message={'Retrieving correspondences'}/> :
                        <DynamicTable context={'List correspondences'}
                                      columnsOfInterest={['reference_number', 'subject', 'correspondence_from', 'correspondence_to']}
                                      tableData={this.state.dataItem1}
                                      showRowData={correspondence => {
                                          // Dispatch({type: RECORD_TO_TASK, payload: correspondence.id});
                                          // Dispatch({type: MISC_ID, payload: correspondence.id})
                                          Dispatch({
                                              type: SUBTITLE,
                                              payload: <span>
                                              View correspondence on {correspondence.subject}
                                      </span>
                                          });
                                          Dispatch({type: MISC_ID, payload: correspondence});
                                          Dispatch({type: LETTER, payload: correspondence});
                                          this.navigateTo(`../correspondence-view/${correspondence.id}`, true);
                                      }}/>
                    }
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}