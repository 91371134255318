import GMS from "../../../../parent-component/GMS";
import {Outlet, Route, Routes} from 'react-router-dom';
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {ROUTEINDEX, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import Navigation from "../../../../general-components/controls/svg-controls/Navigation";
import {notify} from "../../../../../MiscUtils";
import PremiseFields from "./layout/PremiseFields";
import LocationFields from "./location/LocationFields";
import EmployeesAtPremiseFields from "./employee-selections/EmployeesAtPremiseFields";
import CompletePremiseDeclaration from "./CompletePremiseDeclaration";
import UtilitiesFields from "./utilities/UtilitiesFields";
import DeviceFields from "./devices/DeviceFields";

/**
 *
 *
 *
 */
export default class DeclarePremise extends GMS {
    constructor(props) {
        super(props);
        this.navigableRoutes = [
            'layout-and-dimensions',
            'location',
            'employees-at-premise',
            'utilities-amenities-security',
            'devices',
            'complete-application'
        ];
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: `Declare a new premise`});
        this.navigateTo('layout-and-dimensions');
    }

    /**
     *
     * Allow navigation to the left
     *
     */
    navigateLeft = () => {
        if (this.state.currentRouteIndex < 1) {
            this.setState({showBackwards: false, showForwards: true}, () => {
                notify('End of navigation', 1, true);
            });
        } else {
            let routeIndex = this.state.currentRouteIndex - this.state.variable === 1 && this.state.currentRouteIndex === 3 ? 2 : 1;
            // navigate to the next url, and add 1 to the counter
            this.setState({
                showBackwards: true,
                currentRouteIndex: this.state.currentRouteIndex - 1
            }, () => {
                Dispatch({type: ROUTEINDEX, payload: this.state.currentRouteIndex});
                this.navigateTo(this.navigableRoutes[this.state.currentRouteIndex], false);
                this.switchSelection(document.getElementsByClassName(`_${this.state.currentRouteIndex}`)[0]);
            });
        }
    }

    /**
     *
     * Allow navigation to the right
     *
     */
    navigateRight = () => {
        if (this.state.currentRouteIndex === this.navigableRoutes.length - 1) {
            this.setState({showBackwards: true, showForwards: false}, () => {
                notify('End of navigation', 1, true);
            });
        } else {
            // navigate to the next url, and add 1 to the counter
            //console.log('variable ', this.state.variable);
            let routeIndex = this.state.currentRouteIndex + this.state.variable === 1 && this.state.currentRouteIndex === 1 ? 2 : 1;
            this.setState({
                showForwards: true,
                currentRouteIndex: this.state.currentRouteIndex + 1
            }, () => {
                //console.log('RIGHT current route index as after state update ', this.state.currentRouteIndex);
                Dispatch({type: ROUTEINDEX, payload: this.state.currentRouteIndex});
                this.navigateTo(this.navigableRoutes[this.state.currentRouteIndex], false);
                this.switchSelection(document.getElementsByClassName(`_${this.state.currentRouteIndex}`)[0]);
            });
        }
    }
    render = () => {
        return (
            <Section>
                <SectionTitle>
                    Declare a {this.props.type} premise
                </SectionTitle>
                <SectionRow>
                    <SectionColumn className={'tab-menu _0'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_0`)[0]);
                        this.setState({currentRouteIndex: 0}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[0], false);
                    }}>
                        Layout
                    </SectionColumn>
                    <SectionColumn className={'tab-menu _1'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_1`)[0]);
                        this.setState({currentRouteIndex: 1}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[1], false);
                    }}>
                        Location
                    </SectionColumn>
                    <SectionColumn className={'tab-menu _2'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_2`)[0]);
                        this.setState({currentRouteIndex: 2}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[2], false);
                    }}>
                        Employees
                    </SectionColumn>

                    <SectionColumn className={'tab-menu _3'} onClick={(e) => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_3`)[0]);
                        this.setState({currentRouteIndex: 3}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[3], false);
                    }}>
                        Utilities
                    </SectionColumn>

                    <SectionColumn className={'tab-menu _4'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_6`)[0]);
                        this.setState({currentRouteIndex: 4}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[4], false);
                    }}>
                        Devices
                    </SectionColumn>
                    <SectionColumn className={'tab-menu _5'} onClick={() => { //menu-item horizontal-menu-items
                        this.switchSelection(document.getElementsByClassName(`_6`)[0]);
                        this.setState({currentRouteIndex: 4}, () => Dispatch({
                            type: ROUTEINDEX,
                            payload: this.state.currentRouteIndex
                        }));
                        this.navigateTo(this.navigableRoutes[5], false);
                    }}>
                        complete declaration
                    </SectionColumn>
                </SectionRow>
                <SectionRow/>
                <Outlet/>
                {this.state.currentRoute}
                {/*<SectionRow>*/}
                <Routes>
                    <Route element={<PremiseFields/>}
                           path={'layout-and-dimensions'}/>
                    <Route element={<LocationFields/>}
                           path={'location'}/>
                    <Route element={<EmployeesAtPremiseFields/>}
                           path={'employees-at-premise/*'}/>
                    <Route element={<UtilitiesFields/>}
                           path={'utilities-amenities-security'}/>
                    <Route element={<DeviceFields/>}
                           path={'devices'}/>
                    <Route element={<CompletePremiseDeclaration/>}
                           path={'complete-application'}/>
                </Routes>
                {/*</SectionRow>*/}
                <SectionRow>
                    <Navigation showBackwards={this.state.currentRouteIndex > 0}
                                showForwards={this.state.currentRouteIndex < this.navigableRoutes.length - 1}
                                onNavigation={[this.navigateLeft, this.navigateRight]}
                                callback={() => {
                                }}/>
                </SectionRow>
            </Section>
        )

    }
}