import GMS from "../../parent-component/GMS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie, faTable} from "@fortawesome/free-solid-svg-icons";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import InfoTab from "../../general-components/info-ed-tab/InfoTab";
import {AppStorage, Dispatch} from "../../general-components/redux/app-storage";
import DynamicTable from "../../general-components/tables/DynamicTable";
import PieChart from "../../general-components/charts/PieChart";
import {readFromLocalStorage} from "../../../MiscUtils";
import {
    BCLB,
    CLEAR_RECORD_TO_TASK,
    COUNTS,
    MISC_ID,
    PREV_ROUTE,
    RECORD_TO_TASK,
    SUBTITLE,
    TASK_OBJECT
} from "../../general-components/redux/allowed-actions";
import React from "react";
import LoadingData from "../../general-components/controls/data-loading/LoadingData";

/**
 *
 * Hosts a table for showing list of operators.
 * Provide a UI that enables alternation of views between pie-charts and table.
 *
 *
 *
 */
export default class OperatorsList extends GMS {
    constructor(props) {
        super(props);
        if (window.location.href.split('/').includes('new-operator-declarations'))
            Dispatch({type: PREV_ROUTE, payload: 'new-operator-declarations'});
        else
            Dispatch({type: PREV_ROUTE, payload: 'list-operators'});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        // if (this.state.dataItem2.length < 1)
        if (nextProps.operatorList)
            this.setState({visible: true, dataItem2: [...nextProps.operatorList]});
    }

    /**
     *
     * Method switches views by updating state 'switchableView'
     * @param view
     *
     */
    switchView = (view = null) => {
        this.setState({switchableView: null}, () => {
            this.setState({switchableView: view})
        });
    }
    /**
     * Counts all items by their categories
     *
     * @param data
     */
    sortOutCounts = (data) => {
        if (
            data.priority !== 2 &&
            !Object.getOwnPropertyNames(data).includes("notification") &&
            !Object.getOwnPropertyNames(data).includes("error")
        ) {
            //this is a error or warning. Do nothing
            // remove this unwanted key
            // for (const operator of data) {
            //     delete operator["id"];
            // }

            //
            // keep track of counts of operators from the server
            // after all: all this data cannot fit in redux
            //
            this.setState({dataItem2: [...data], variable: data.length}, () => {
                const operator_spelling = this.state.dataItem2.length < 1 ?
                    'operator' : this.state.dataItem2.length === 0 || this.state.dataItem2.length > 1 ? 'operators' :
                        'operator';

                Dispatch({
                    type: SUBTITLE,
                    payload:
                        `listing ${this.state.variable} ${operator_spelling} in file`
                });
            });
            ////////////////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////
            // operator status is absent
            let counts = {
                All: 0,
                Application: 0,
                Suspended: 0,
                Active: 0,
                Inactive: 0,
                Cancelled: 0,
                Defunct: 0,
            };
            // count all documents
            counts['All'] = data.length;
            let k = 0;
            do {
                // check the status of the operators
                if (Object.getOwnPropertyNames(counts).includes(data[k].application_status))
                    counts[data[k]['application_status']] += 1;
                k += 1;
            } while (k < data.length);
            // write to redux
            Dispatch({type: COUNTS, payload: counts});
            // update list of operators and call the necessary dynamic table
            //
            // prepare PieChart data array.
            // format for JSON data
            /**
             *
             * Prepare data for the PieChart component. The format below shows how it is arranged.
             * The data in question is an array of JSON objects.
             * [{'id': 'active operators', 'label': 'active operators', 'value': 2},...]
             */
            if (data.length > 0) {
                k = 1; // do not count for all...
                let y = [];
                y = this.state.dataItem1; // in case dataItem1 has something,...
                const t = Object.getOwnPropertyNames(counts);
                do {
                    y.push({
                        id: t[k],
                        label: t[k],
                        value: counts[t[k]]
                    })
                    k += 1;
                } while (k < t.length);
                this.setState({dataItem1: [...y]});

            }
        }
    }
    /**
     *
     * Fetch all operators
     *
     */
    componentDidMount = () => {
        // clear record to task
        Dispatch({type: CLEAR_RECORD_TO_TASK});
        Dispatch({type: SUBTITLE, payload: 'List of operators'});
        // onlyNewApplications if this is set, show only the table
        // notify('Please wait...',1,true);
        if (this.props.operatorList === null || this.props.operatorList === undefined) {

            this.sendRequest('get', 'company/operator', operatorList => {
                let msg;
                let level = 1;
                this.setState({visible: true});
                if (operatorList.data) {

                    if (operatorList.data.length > 0)
                        this.setState({dataItem2: [...operatorList.data[0]], witchableView: 'table'}, () => {
                            level = 4;
                            msg = operatorList.message;
                            // sort out counts
                            // this.sortOutCounts(this.state.miscellaneousItems);
                        });

                }

            }, {})
        } else {
            this.setState({miscellaneousItems: this.props.operatorList, switchableView: 'table'});
        }
    }

    // load the data in a state object that will then shift these views dynamically when selected.
    render = () => {

        return this.state.dataItem2.length > 0 && <>
            {/*end viewing area*/}
            {this.state.currentRoute}
            <Section>
                <SectionTitle>
                    {readFromLocalStorage('role', BCLB) === 4 ? 'operator' : 'List of operators'}
                </SectionTitle>
                {/*// only show this if the currently logged in person is anyone other than a representative*/}
                {
                    AppStorage.getState()['role'] !== 4 && !this.props.onlyNewApplications &&
                    <SectionRow>
                        <SectionColumn style={{width: '20vw'}}/>
                        <SectionColumn>
                            <InfoTab callback={() => {
                            }} label={`operators`} value={this.props.All} valueColour={'#800080'}/>
                        </SectionColumn>
                        <SectionColumn>
                            <InfoTab callback={() => {
                            }} label={`new`} value={this.props.Application} valueColour={'#929295'}/>
                        </SectionColumn>
                        <SectionColumn>
                            <InfoTab callback={() => {
                            }} label={`operating`} value={this.props.Active} valueColour={'#279C03FF'}/>
                        </SectionColumn>
                        <SectionColumn>
                            <InfoTab callback={() => {
                            }} label={`suspended`} value={this.props.Inactive} valueColour={'#D78E37'}/>
                        </SectionColumn>
                        <SectionColumn>
                            <InfoTab callback={() => {
                            }} label={`cancelled`} value={this.props.Cancelled} valueColour={'#DD1128FF'}/>
                        </SectionColumn>
                        <SectionColumn style={{width: '6vw'}}/>
                        <SectionColumn>
                            <InfoTab callback={() => {
                            }} label={`defunct`} value={this.props.Defunct} valueColour={'#44385c'}/>
                        </SectionColumn>

                        {/*provide an entire count of all operators by their statuses*/}
                    </SectionRow>
                }
                {
                    readFromLocalStorage('role', BCLB) !== 4 &&
                    <SectionRow style={{marginTop: '2rem'}}>
                        <SectionColumn style={{fontSize: '1.4em', color: '#'}}>
                            <FontAwesomeIcon onClick={() => {
                                this.switchView('table')
                            }} icon={faTable} style={{height: 30, color: '#800080'}}/>
                            &emsp;
                            <FontAwesomeIcon onClick={this.switchView} icon={faChartPie}
                                             style={{height: 30, color: '#800081'}}/>
                        </SectionColumn>
                    </SectionRow>
                }
                <SectionRow>
                    {
                        readFromLocalStorage('role', BCLB) === 4 ?
                            !this.state.visible ?
                                <LoadingData hasData={this.state.dataItem2.length === 0} message={'Loading operator...'}/> :
                                <DynamicTable pageSize={1}
                                              context={'list of operators'}
                                              columnsOfInterest={[
                                                  'company_name',
                                                  'foreign_company_origin',
                                                  'operator_phone_number',
                                                  // 'local_website_address',
                                                  'local_building_address',
                                              ]}
                                              tableData={this.state.dataItem2}
                                              showRowData={(dataToShow) => {
                                                  Dispatch({
                                                      type: SUBTITLE,
                                                      payload: `Viewing operator ${dataToShow.company_name}`
                                                  });
                                                  Dispatch({type: MISC_ID, payload: dataToShow})
                                                  // mark that this is a new operator declaration
                                                  // remember to clear it
                                                  // see createTask <submit-button> for the task objects allowed.
                                                  // this is a array-based indexing scheme//
                                                  Dispatch({type: TASK_OBJECT, payload: 0});
                                                  Dispatch({
                                                      type: PREV_ROUTE,
                                                      payload: `operator-view/${dataToShow.company_name}`
                                                  })
                                                  this.navigateTo(`../operator-view/${dataToShow.company_name}`, false)
                                                  // dispatch the subtitle
                                              }}/>
                            :
                            this.state.switchableView === 'table' ?
                                <DynamicTable pageSize={10}
                                              context={'operators'}
                                              columnsOfInterest={[
                                                  // "id",
                                                  'company_name',
                                                  'operator_phone_number',
                                                  'local_building_address',
                                                  'operator_status',
                                                  'local_website_address',
                                                  'foreign_company_origin'
                                              ]}
                                              tableData={this.state.dataItem2}
                                              showRowData={dataToShow => {
                                                  Dispatch({
                                                      type: SUBTITLE,
                                                      payload: `Viewing operator ${dataToShow.company_name}`
                                                  })
                                                  Dispatch({type: MISC_ID, payload: dataToShow});
                                                  // mark that this is a new operator declaration
                                                  // remember to clear it
                                                  Dispatch({type: TASK_OBJECT, payload: 0}); // see createTask <submit-button> for the task objects allowed.
                                                  Dispatch({type: RECORD_TO_TASK, payload: dataToShow.id}); // used for tasking
                                                  //
                                                  Dispatch({
                                                      type: PREV_ROUTE,
                                                      payload: `operator-view/${dataToShow.company_name}`
                                                  })
                                                  this.navigateTo(`../operator-view/${dataToShow.company_name}`, false)
                                                  // dispatch the subtitle
                                              }}/> :
                                // pie chart data is created separately
                                <PieChart
                                    colours={['#929295', '#d78e37', '#279C03FF', '#4C69BCFF', '#DD1128FF', '#44385c']}
                                    data={!this.state.dataItem1 ? [] : this.state.dataItem1}/>
                    }
                </SectionRow>
            </Section>
        </>
    }
}
//
// const getDataFromStorage = storage => {
//     return storage.counts | {};
// }
//
// export default connect(getDataFromStorage)(OperatorsList);