import {Component} from "react";
import {Section, SectionColumn, SectionRow} from "../../../../../rotm-custom-views/view-utils/SectionUtils";
import CheckBoxField from "../../../../../general-components/input-field/CheckBoxField";
import TextField from "../../../../../general-components/input-field/TextField";
import NumberField from "../../../../../general-components/input-field/NumberField";
import SelectField from "../../../../../general-components/input-field/SelectField";
import EmailField from "../../../../../general-components/input-field/EmailField";
import PhoneNumberField from "../../../../../general-components/input-field/PhoneNumberField";
import DateField from "../../../../../general-components/input-field/DateField";
import FileSelectField from "../../../../../general-components/input-field/FileSelectField";
import NameField from "../../../../../general-components/input-field/NameField";
import KRAPinField from "../../../../../general-components/input-field/KRAPinField";

export default class ShareholderFieldSet extends Component {
    constructor(props) {
        super(props);
        // props available:
        // identifier that identifies the shareholder position in the overall count
        // viewOnly that helps toggle between view and list. Edit also avails
        // fieldData that populates the fieldset with its respective data. If data is present, viewOnly is ignored, and edit mode is off.
        // updateShareholderData that updates shareholding data
        // shareTypes a list of shareholder types
        // gender which is the list of genders
        // country which is the list of countries
        this.state = {
            fieldMode: false,
            companyFields: this.props.fieldData ? !!this.props.fieldData["sh_company_name"] : false,
            name: null,
            contact: null,
            position: null,
            fieldData: props.fieldData
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        // this.setState({fieldData:  null}, () => this.setState({fieldData: nextProps.fieldData}));
    }

    /**
     *
     * Enables toggle between edit mode and view mode.
     */
    toggleEdit = () => {
        this.setState({fieldMode: this.state.fieldMode});
    }

    componentDidMount = () => {
        this.setState({
            isNotKenya: this.props.fieldData && this.props.fieldData['work_permit_number'],
            fieldMode: !!this.props.data || this.props.userVisibleOnly
        });
    }
    renderField = (data) => {
        let fieldSet = [];
        if (data === undefined) {
            fieldSet.push(<Section>
                <SectionRow>
                    <SectionColumn style={{
                        // background: "#800080",
                        background: '#59788E',

                        color: '#FFFFFF',
                        fontWeight: 600,
                        fontSize: 14,
                        width: 64,
                        textAlign: "center",
                        borderRadius: 4
                    }}>
                        {this.props.identifier}
                    </SectionColumn>
                    &ensp;
                    &ensp;
                    &ensp;
                    <SectionColumn>
                        <CheckBoxField name={`declare_as_company${this.props.identifier}`}
                                       fontSize={16}
                            // bold
                                       defaultValue={!!(this.props.fieldData && this.props.fieldData['sh_company_name'])}

                                       style={{borderBottom: "2px solid #195ae7", color: "#5e8df3"}}
                                       placeholder={this.state.companyFields ?
                                           "This is a shareholding company" : "If the shareholder is a company, check."}
                                       changeCallback={() => this.setState({companyFields: !this.state.companyFields})}
                        />
                    </SectionColumn>
                </SectionRow>{
                !this.state.fieldMode ?
                    <SectionColumn>
                        {
                            // These are company fields
                            this.state.companyFields ?
                                <SectionRow>
                                    <SectionColumn
                                        style={{
                                            boxShadow: '0 12px 8px -8px #c8c2ec',
                                            border: "1px solid #c8c2ec",
                                            background: "rgba(239,238,239,0.55)"
                                        }}>
                                        {
                                            this.state.fieldData ? Object.getOwnPropertyNames(this.state.fieldData).length === 0 &&
                                                <SectionRow>
                                                    <SectionColumn/>
                                                </SectionRow> : null
                                        }
                                        {/*<SectionRow>*/}
                                        {/*    <SectionColumn/>*/}
                                        {/*    <SectionColumn>*/}
                                        {/*        <CheckBoxField*/}
                                        {/*            capitalize*/}
                                        {/*            fontSize={14}*/}
                                        {/*            // defaultValue={!!(this.props.fieldData && this.props.fieldData['is_parent_company'])}*/}
                                        {/*            changeCallback={*/}
                                        {/*                d => {*/}
                                        {/*                    this.props.updateShareholderData(d, this.props.identifier, "company");*/}
                                        {/*                    // this.setState({visible: e.target.checked});*/}
                                        {/*                }}*/}
                                        {/*            name={'is_parent_company'}*/}
                                        {/*            placeholder={'Company is owned or associated with another company'}/>*/}
                                        {/*    </SectionColumn>*/}
                                        {/*</SectionRow>*/}
                                        <SectionRow>
                                            <SectionColumn>
                                                <NameField bold
                                                           fontSize={14}
                                                           capitalize
                                                           isRequired
                                                           maxLength={100}
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_company_name']}
                                                           name={'sh_company_name'}
                                                           placeholder={'company name'}
                                                           changeCallback={d => {
                                                               this.props.updateShareholderData(d, this.props.identifier, "company");
                                                           }}/>
                                            </SectionColumn>
                                            <SectionColumn>
                                                <NameField bold
                                                           fontSize={14}
                                                           capitalize
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_trade_name']}
                                                           name={'sh_trade_name'}
                                                           placeholder={'trade name'}
                                                           changeCallback={d => {
                                                               this.props.updateShareholderData(d, this.props.identifier, "company");
                                                           }}/>
                                            </SectionColumn>
                                            <SectionColumn>
                                                <TextField bold
                                                           fontSize={14}
                                                           capitalize
                                                           isRequired
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_company_reg_no']}
                                                           name={'sh_company_reg_no'}
                                                           placeholder={'Registration number'}
                                                           changeCallback={d => {
                                                               this.props.updateShareholderData(d, this.props.identifier, "company");
                                                           }}/>
                                            </SectionColumn>
                                        </SectionRow>
                                        {/*//second set of fields*/}
                                        <SectionRow>
                                            <SectionColumn>
                                                <KRAPinField bold fontSize={14}
                                                             capitalize
                                                             forCompany
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_company_pin']}
                                                    // isRequired
                                                             name={"sh_company_pin_number"}
                                                             placeholder={"Tax Pin number"}
                                                             changeCallback={(d) => {
                                                                 //d is the target returned as an argument by this component.
                                                                 // extract the name and value items
                                                                 this.props.updateShareholderData(d, this.props.identifier, "company");
                                                             }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <SelectField bold fontSize={14}
                                                             capitalize
                                                             isRequired
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_type']}
                                                             options={this.props.shareTypes ? this.props.shareTypes : []}
                                                             name={"sh_company_type"}
                                                             placeholder={"type of shares"}
                                                             changeCallback={(d) => {
                                                                 //d is the target returned as an argument by this component.
                                                                 // extract the name and value items
                                                                 this.props.updateShareholderData(d, this.props.identifier, "company");
                                                             }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <NumberField
                                                    capitalize
                                                    bold
                                                    fontSize={14}
                                                    defaultValue={this.props.fieldData && this.props.fieldData['`sh_company_number_of_shares`']}
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_company_shares']}
                                                    isRequired
                                                    // name={"sh_company_shares"}
                                                    name={"sh_company_number_of_shares"}
                                                    placeholder={"number of shares"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                        </SectionRow>
                                        <SectionRow>
                                            <SectionColumn>
                                                <DateField
                                                    isRequired
                                                    bold
                                                    fontSize={14}
                                                    capitalize
                                                    onlyPastDates
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_shareholding_start']}
                                                    name={"sh_shareholding_start"}
                                                    placeholder={"date of start of shareholding"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <FileSelectField
                                                    isRequired
                                                    bold
                                                    fontSize={14}
                                                    capitalize
                                                    onlyPastDates
                                                    name={"company_kra_doc"}
                                                    placeholder={"Company Tax Compliance document"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <FileSelectField
                                                    isRequired
                                                    bold
                                                    fontSize={14}
                                                    capitalize
                                                    onlyPastDates
                                                    name={"company_notarized_sh_doc"}
                                                    placeholder={"Notarized list of shareholders"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                        </SectionRow>
                                        <div className={"space-row"}/>
                                        {/*while this section has some important fields, they shall remain*/}
                                        {/*unmarked as required because not all companies*/}

                                        <div className={'small-space-row'}/>
                                    </SectionColumn>
                                </SectionRow>
                                :
                                // These are individual fields
                                <SectionColumn
                                    style={{boxShadow: '0 12px 8px -8px #c8c2ec', border: "1px solid #c8c2ec",}}>
                                    <SectionRow>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_first_name']}
                                                       isRequired
                                                       name={"sh_first_name"}
                                                       placeholder={"first name"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_middle_name']}
                                                       isRequired
                                                       name={"sh_middle_name"}
                                                       placeholder={"middle name"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_surname']}
                                                       name={"sh_surname"}
                                                       placeholder={"surname"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    {/*//second set of fields*/}
                                    <SectionRow>
                                        <SectionColumn>
                                            <KRAPinField bold fontSize={14}
                                                         capitalize
                                                         forIndividual
                                                         defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_pin_number']}
                                                // isRequired
                                                         name={"sh_individual_pin_number"}
                                                         placeholder={"Tax pin number"}
                                                         changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <SelectField bold fontSize={14}
                                                         capitalize
                                                         isRequired
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_type']}
                                                         options={this.props.shareTypes ? this.props.shareTypes : []}
                                                         name={"sh_individual_type"}
                                                         placeholder={"type of shares"}
                                                         changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <NumberField
                                                capitalize
                                                bold
                                                fontSize={14}
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_number_of_shares']}
                                                isRequired
                                                name={"sh_individual_number_of_shares"}
                                                placeholder={"number of shares"}
                                                changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <SelectField bold fontSize={14}
                                                         capitalize
                                                         isRequired
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_nationality']}
                                                         options={this.props.country ? this.props.country : []}
                                                         name={"sh_nationality"}
                                                         placeholder={"nationality"}
                                                         changeCallback={d => {
                                                             //d is the target returned as an argument by this component.
                                                             // extract the name and value items
                                                             this.props.updateShareholderData(d, this.props.identifier, "director");
                                                             // if option is other, update the state and if it's 3
                                                             this.setState({
                                                                 isNotKenya: d.target.options[d.target.selectedIndex].textContent !== "Kenya",
                                                                 nationality: d.target.options[d.target.selectedIndex].textContent
                                                             });
                                                         }}
                                            />
                                        </SectionColumn>
                                        {
                                            this.state.isNotKenya ?
                                                <SectionColumn>
                                                    {/*// this field is optional*/}
                                                    <NumberField bold fontSize={14}
                                                                 isRequired
                                                                 capitalize
                                                        // defaultValue={this.props.fieldData && this.props.fieldData['work_permit_number']}
                                                                 name={'work_permit_number'}
                                                                 placeholder={'work permit number'}
                                                                 changeCallback={(d) => {
                                                                     this.props.updateShareholderData(d, this.props.identifier, "director")
                                                                 }}
                                                    />
                                                </SectionColumn> : null
                                        }
                                        <SectionColumn>
                                            <PhoneNumberField bold fontSize={14}
                                                              capitalize
                                                              defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_phone_number']}
                                                              isRequired
                                                              name={"sh_individual_phone_number"}
                                                              placeholder={"phone number"}
                                                              changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_national_id']}
                                                       isRequired
                                                       name={"sh_national_id"}
                                                       placeholder={"Kenyan ID/Alien ID/Passport"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <SelectField bold
                                                         isRequired
                                                         fontSize={14}
                                                         capitalize
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_gender']}
                                                         options={this.props.gender ? this.props.gender : []}
                                                         name={'sh_gender'}
                                                         placeholder={'gender'}
                                                         changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}/>
                                        </SectionColumn>
                                        <SectionColumn>
                                            <DateField bold
                                                       fontSize={14}
                                                       onlyPastDates
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_shareholding_start']}
                                                       name={"sh_shareholding_start"}
                                                       placeholder={"Date of start of shareholding"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                // isRequired
                                                             capitalize
                                                             name={'sh_passport_doc'}
                                                             placeholder={'Kenyan ID/Alien ID/Passport'}
                                                             changeCallback={d => {
                                                                 this.props.updateShareholderData(d, this.props.identifier, "director")
                                                             }}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                             isRequired
                                                             capitalize
                                                             name={'sh_kra_doc'}
                                                             placeholder={'KRA document'}
                                                             changeCallback={d => {
                                                                 this.props.updateShareholderData(d, this.props.identifier, "director")
                                                             }}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField
                                                isRequired
                                                bold
                                                fontSize={14}
                                                capitalize
                                                onlyPastDates
                                                name={"company_notarized_sh_doc"}
                                                placeholder={"TCC-uliza-jina"}
                                                changeCallback={(d) => {
                                                    //d is the target returned as an argument by this component.
                                                    // extract the name and value items
                                                    this.props.updateShareholderData(d, this.props.identifier, "company");
                                                }}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                             isRequired
                                                             capitalize
                                                             name={'sh_cogc_file'}
                                                             placeholder={'Good conduct'}
                                                             changeCallback={d => {
                                                                 this.props.updateShareholderData(d, this.props.identifier, "director")
                                                             }}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <EmailField bold
                                                        isRequired
                                                        fontSize={14}
                                                        capitalize
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_email']}
                                                        name={"sh_individual_email"}
                                                        placeholder={"email address"}
                                                        changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                </SectionColumn>
                        }
                    </SectionColumn> :
                    <SectionColumn style={{border: "1px solid"}}>
                        <SectionRow>
                            <SectionColumn>
                                {/*//this.props.fieldData. shareholding company if true else individual*/}
                            </SectionColumn>
                            <SectionColumn/>
                        </SectionRow>
                    </SectionColumn>
            }

            </Section>)
        } else {
            fieldSet.push(<Section>
                <SectionRow>
                    <SectionColumn style={{
                        // background: "#800080",
                        background: '#59788E',
                        color: '#FFFFFF',
                        fontWeight: 600,
                        fontSize: 14,
                        width: 64,
                        textAlign: "center",
                        borderRadius: 4
                    }}>
                        {this.props.identifier}
                    </SectionColumn>
                    &ensp;
                    &ensp;
                    &ensp;
                    <SectionColumn>
                        <CheckBoxField name={`declare_as_company${this.props.identifier}`}
                                       fontSize={16}
                            // bold
                                       defaultValue={!!(this.props.fieldData && this.props.fieldData['sh_company_name'])}

                                       style={{borderBottom: "2px solid #195ae7", color: "#5e8df3"}}
                                       placeholder={this.props.fieldData && this.props.fieldData['sh_company_name'] ?
                                           "This is a shareholding company" : "If the shareholder is a company, check."}
                                       changeCallback={() => this.setState({companyFields: !this.state.companyFields})}
                        />
                    </SectionColumn>
                </SectionRow>{
                !this.state.fieldMode ?
                    <SectionColumn>
                        {
                            // These are company fields
                            this.state.companyFields ? <SectionRow>
                                    <SectionColumn
                                        style={{
                                            boxShadow: '0 12px 8px -8px #c8c2ec',
                                            border: "1px solid #c8c2ec",
                                            background: "rgba(239,238,239,0.55)"
                                        }}>
                                        {
                                            Object.getOwnPropertyNames(this.state.fieldData) ?
                                                Object.getOwnPropertyNames(this.state.fieldData).length === 0 &&
                                                <SectionRow>
                                                    {/*<SectionColumn>*/}
                                                    {/*    <SubmitButton style={{fontWeight: 500, background: "#272828"}}*/}
                                                    {/*                  commandText={"Declare director"}*/}
                                                    {/*                  callback={() => {*/}
                                                    {/*                      this.setState({companyFields: !this.state.companyFields});*/}
                                                    {/*                  }}/>*/}
                                                    {/*</SectionColumn>*/}
                                                    <SectionColumn/>
                                                </SectionRow> : null
                                        }
                                        {/*<SectionRow>*/}
                                        {/*    <SectionColumn/>*/}
                                        {/*    <SectionColumn>*/}
                                        {/*        <CheckBoxField*/}
                                        {/*            capitalize*/}
                                        {/*            fontSize={14}*/}
                                        {/*            defaultValue={!!(this.props.fieldData && this.props.fieldData['is_parent_company'])}*/}

                                        {/*            changeCallback={*/}
                                        {/*                d => {*/}
                                        {/*                    this.props.updateShareholderData(d, this.props.identifier, "company");*/}
                                        {/*                    // this.setState({visible: e.target.checked});*/}
                                        {/*                }}*/}
                                        {/*            name={'is_parent_company'}*/}
                                        {/*            placeholder={'Company is owned or associated with another company'}/>*/}
                                        {/*    </SectionColumn>*/}
                                        {/*</SectionRow>*/}
                                        <SectionRow>
                                            <SectionColumn>
                                                <NameField bold
                                                           fontSize={14}
                                                           capitalize
                                                           isRequired
                                                           defaultValue={this.props.fieldData && this.props.fieldData['sh_company_name']}
                                                           name={'sh_company_name'}
                                                           placeholder={'company name'}
                                                           maxLength={100}
                                                           changeCallback={d => {
                                                               this.props.updateShareholderData(d, this.props.identifier, "company");
                                                           }}/>
                                            </SectionColumn>
                                            <SectionColumn>
                                                <NameField bold
                                                           fontSize={14}
                                                           capitalize
                                                           defaultValue={this.props.fieldData && this.props.fieldData['sh_trade_name']}
                                                           name={'sh_trade_name'}
                                                           placeholder={'trade name'}
                                                           changeCallback={d => {
                                                               this.props.updateShareholderData(d, this.props.identifier, "company");
                                                           }}/>
                                            </SectionColumn>
                                            <SectionColumn>
                                                <TextField bold
                                                           fontSize={14}
                                                           capitalize
                                                           isRequired
                                                           defaultValue={this.props.fieldData && this.props.fieldData['sh_company_reg_no']}
                                                           name={'sh_company_reg_no'}
                                                           placeholder={'Registration number'}
                                                           changeCallback={d => {
                                                               this.props.updateShareholderData(d, this.props.identifier, "company");
                                                           }}/>
                                            </SectionColumn>
                                        </SectionRow>
                                        {/*//second set of fields*/}
                                        <SectionRow>
                                            <SectionColumn>
                                                <KRAPinField bold fontSize={14}
                                                             capitalize
                                                             forCompany
                                                             defaultValue={this.props.fieldData && this.props.fieldData['sh_company_pin_number']}
                                                    // isRequired
                                                             name={"sh_company_pin_number"}
                                                             placeholder={"Tax Pin number"}
                                                             changeCallback={(d) => {
                                                                 //d is the target returned as an argument by this component.
                                                                 // extract the name and value items
                                                                 this.props.updateShareholderData(d, this.props.identifier, "company");
                                                             }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <SelectField bold fontSize={14}
                                                             capitalize
                                                             isRequired
                                                             defaultValue={this.props.fieldData && this.props.fieldData['sh_type']}
                                                             options={this.props.shareTypes ? this.props.shareTypes : []}
                                                             name={"sh_type"}
                                                             placeholder={"type of shares"}
                                                             changeCallback={(d) => {
                                                                 //d is the target returned as an argument by this component.
                                                                 // extract the name and value items
                                                                 this.props.updateShareholderData(d, this.props.identifier, "company");
                                                             }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <NumberField
                                                    capitalize
                                                    bold
                                                    fontSize={14}
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_shares']}
                                                    defaultValue={this.props.fieldData && this.props.fieldData['sh_company_number_of_shares']}
                                                    // defaultValue={this.props.fieldData && this.props.fieldData['sh_company_shares']}
                                                    isRequired
                                                    // name={"sh_shares"}
                                                    name={"sh_company_number_of_shares"}
                                                    // name={"sh_company_shares"}
                                                    placeholder={"number of shares"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                        </SectionRow>
                                        <SectionRow>
                                            <SectionColumn>
                                                <DateField
                                                    isRequired
                                                    bold
                                                    fontSize={14}
                                                    capitalize
                                                    onlyPastDates
                                                    defaultValue={this.props.fieldData && this.props.fieldData['sh_shareholding_start']}
                                                    name={"sh_shareholding_start"}
                                                    placeholder={"date of start of shareholding"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <FileSelectField
                                                    isRequired
                                                    bold
                                                    fontSize={14}
                                                    capitalize
                                                    onlyPastDates
                                                    name={"company_kra_doc"}
                                                    placeholder={"Company Tax Compliance document"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                            <SectionColumn>
                                                <FileSelectField
                                                    isRequired
                                                    bold
                                                    fontSize={14}
                                                    capitalize
                                                    onlyPastDates
                                                    name={"company_notarized_sh_doc"}
                                                    placeholder={"Notarized list of shareholders"}
                                                    changeCallback={(d) => {
                                                        //d is the target returned as an argument by this component.
                                                        // extract the name and value items
                                                        this.props.updateShareholderData(d, this.props.identifier, "company");
                                                    }}
                                                />
                                            </SectionColumn>
                                        </SectionRow>
                                        <div className={"space-row"}/>
                                        {/*while this section has some important fields, they shall remain*/}
                                        {/*unmarked as required because not all companies*/}

                                        <div className={'small-space-row'}/>
                                    </SectionColumn>

                                </SectionRow> :
                                // These are individual fields
                                <SectionColumn
                                    style={{boxShadow: '0 12px 8px -8px #c8c2ec', border: "1px solid #c8c2ec",}}>
                                    <SectionRow>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                       defaultValue={this.props.fieldData && this.props.fieldData['sh_first_name']}
                                                       isRequired
                                                       name={"sh_first_name"}
                                                       placeholder={"first name"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                       defaultValue={this.props.fieldData && this.props.fieldData['sh_middle_name']}
                                                       isRequired
                                                       name={"sh_middle_name"}
                                                       placeholder={"middle name"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                       defaultValue={this.props.fieldData && this.props.fieldData['sh_surname']}
                                                       name={"sh_surname"}
                                                       placeholder={"surname"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <TextField bold fontSize={14}
                                                       capitalize
                                                       defaultValue={this.props.fieldData && this.props.fieldData['sh_national_id']}
                                                       isRequired
                                                       name={"sh_national_id"}
                                                       placeholder={"Kenyan ID/Alien ID/Passport"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <SelectField bold
                                                         isRequired
                                                         fontSize={14}
                                                         capitalize
                                                         defaultValue={this.props.fieldData && this.props.fieldData['sh_gender']}
                                                         options={this.props.gender ? this.props.gender : []}
                                                         name={'sh_gender'}
                                                         placeholder={'gender'}
                                                         changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}/>
                                        </SectionColumn>
                                        <SectionColumn>
                                            <DateField bold
                                                       fontSize={14}
                                                       onlyPastDates
                                                       defaultValue={this.props.fieldData && this.props.fieldData['sh_shareholding_start']}
                                                       name={"sh_shareholding_start"}
                                                       placeholder={"Date of start of shareholding"}
                                                       changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <SelectField bold fontSize={14}
                                                         capitalize
                                                         isRequired
                                                         defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_nationality']}
                                                         options={this.props.country ? this.props.country : []}
                                                         name={"sh_individual_nationality"}
                                                         placeholder={"nationality"}
                                                         changeCallback={d => {
                                                             //d is the target returned as an argument by this component.
                                                             // extract the name and value items
                                                             this.props.updateShareholderData(d, this.props.identifier, "director");
                                                             // if option is other, update the state and if it's 3
                                                             this.setState({
                                                                 isNotKenya: d.target.options[d.target.selectedIndex].textContent !== "Kenya",
                                                                 nationality: d.target.options[d.target.selectedIndex].textContent
                                                             });
                                                         }}
                                            />
                                        </SectionColumn>
                                        {
                                            this.state.isNotKenya ?
                                                <SectionColumn>
                                                    {/*// this field is optional*/}
                                                    <NumberField bold fontSize={14}
                                                                 isRequired
                                                                 capitalize
                                                                 defaultValue={this.props.fieldData && this.props.fieldData['work_permit_number']}
                                                                 name={'work_permit_number'}
                                                                 placeholder={'work permit number'}
                                                                 changeCallback={(d) => {
                                                                     this.props.updateShareholderData(d, this.props.identifier, "director")
                                                                 }}
                                                    />
                                                </SectionColumn> : null
                                        }
                                        <SectionColumn>
                                            <PhoneNumberField bold fontSize={14}
                                                              capitalize
                                                              defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_phone_number']}
                                                              isRequired
                                                              name={"sh_individual_phone_number"}
                                                              placeholder={"phone number"}
                                                              changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <KRAPinField bold fontSize={14}
                                                         forIndividual
                                                         capitalize
                                                         defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_pin_number']}
                                                // isRequired
                                                         name={"sh_individual_pin_number"}
                                                         placeholder={"Tax pin number"}
                                                         changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <SelectField bold fontSize={14}
                                                         capitalize
                                                         isRequired
                                                         defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_type']}
                                                         options={this.props.shareTypes ? this.props.shareTypes : []}
                                                         name={"sh_individual_type"}
                                                         placeholder={"type of shares"}
                                                         changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <NumberField
                                                capitalize
                                                bold
                                                fontSize={14}
                                                defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_number_of_shares']}
                                                isRequired
                                                name={"sh_individual_number_of_shares"}
                                                placeholder={"number of shares"}
                                                changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <EmailField bold
                                                        isRequired
                                                        fontSize={14}
                                                        capitalize
                                                        defaultValue={this.props.fieldData && this.props.fieldData['sh_individual_email']}
                                                        name={"sh_individual_email"}
                                                        placeholder={"email address"}
                                                        changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <br/>&ensp;
                                            <br/>
                                            <CheckBoxField bold
                                                // isRequired
                                                           fontSize={16}
                                                           capitalize
                                                // defaultValue={this.props.fieldData && this.props.fieldData['sh_is_director']}
                                                           name={"sh_is_director"}
                                                           placeholder={"Check if shareholder is director"}
                                                           changeCallback={d => this.props.updateShareholderData(d, this.props.identifier, "director")}
                                            />
                                        </SectionColumn>
                                        <SectionColumn/>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                // isRequired
                                                             capitalize
                                                             name={'sh_passport_doc'}
                                                             placeholder={'Kenyan ID/Alien ID/Passport'}
                                                             changeCallback={d => {
                                                                 this.props.updateShareholderData(d, this.props.identifier, "director")
                                                             }}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                             isRequired
                                                             capitalize
                                                             name={'sh_kra_doc'}
                                                             placeholder={'KRA pin certificate'}
                                                             changeCallback={d => {
                                                                 this.props.updateShareholderData(d, this.props.identifier, "director")
                                                             }}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField
                                                isRequired
                                                bold
                                                fontSize={14}
                                                capitalize
                                                onlyPastDates
                                                name={"shareholder_kra_tcc_file"}
                                                placeholder={"Tax Compliance certificate"}
                                                changeCallback={(d) => {
                                                    //d is the target returned as an argument by this component.
                                                    // extract the name and value items
                                                    this.props.updateShareholderData(d, this.props.identifier, "company");
                                                }}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                             isRequired
                                                             capitalize
                                                             name={'sh_cogc_file'}
                                                             placeholder={'Good conduct'}
                                                             changeCallback={d => {
                                                                 this.props.updateShareholderData(d, this.props.identifier, "director")
                                                             }}
                                            />
                                        </SectionColumn>
                                    </SectionRow>
                                </SectionColumn>
                        }
                    </SectionColumn> :
                    <SectionColumn style={{border: "1px solid"}}>
                        <SectionRow>
                            {/*<SectionColumn>*/}
                            {/*</SectionColumn>*/}
                            <SectionColumn/>
                        </SectionRow>
                    </SectionColumn>
            }
            </Section>);
        }
        return fieldSet;
    }
    render = () => {
        // default view is edit mode if data absent else present
        return this.renderField(this.state.fieldData);
    }
}
