import GMS from "../../../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import DynamicTable from "../../../../general-components/tables/DynamicTable";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {MISC_ID, SUBTITLE} from "../../../../general-components/redux/allowed-actions";

/**
 *
 * List my shareholders. Get the operator, get the operator_shareholders data
 *
 */
export default class UpdateShareholdersList extends GMS {
    constructor(props) {
        super(props)
        let t = 0;

        let z = [];
        let a = {};
        let y = [];
        this.sendRequest('get', 'company/operator/individual-shareholder', shareholders => {
            if (shareholders.data.length > 0) {
                do {
                    a = {};
                    a['id'] = shareholders.data[t]['id'];
                    a['shareholder_name'] = `${shareholders.data[t]['sh_first_name']} ${shareholders.data[t]['sh_middle_name']} ${shareholders.data[t]['sh_surname']}`;
                    a['gender'] = shareholders.data[t]['sh_gender'];
                    a['company_or_individual'] = 'individual';
                    a['email'] = shareholders.data[t]['sh_individual_email'];
                    a['postal_address'] = shareholders.data[t]['sh_individual_postal_address'];
                    a['physical_address'] = shareholders.data[t]['sh_individual_physical_address'];
                    a['contact'] = shareholders.data[t]['sh_individual_phone_number'];
                    a['nationality'] = shareholders.data[t]['sh_individual_nationality'];
                    a['shareholder_type'] = shareholders.data[t]['sh_individual_type'] ?
                        shareholders.data[t]['sh_individual_type'] : shareholders.data[t]['sh_company_type'];
                    a['director'] = shareholders.data[t]['sh_is_director'];
                    a['start_of_shareholding'] = shareholders.data[t]['sh_shareholding_start'];
                    a['national_id'] = shareholders.data[t]['sh_national_id'];
                    a['tax_pin'] = shareholders.data[t]['sh_individual_pin_number'];
                    a['shares'] = shareholders.data[t]['sh_shares'];
                    y.push(a);
                    t += 1;
                } while (t < shareholders.data.length);
                // conduct the next request:
                this.sendRequest('get', 'company/operator/company-shareholder', shareholders => {
                    t = 0; // to have the next index
                    do {
                        a = {};
                        a['id'] = shareholders.data[t]['id'];
                        a['shareholder_name'] = shareholders.data[t]['sh_company_name'];
                        a['gender'] = 'N/A';
                        a['company_or_individual'] = 'company';
                        a['email'] = shareholders.data[t]['sh_company_email'];
                        a['contact'] = shareholders.data[t]['sh_individual_phone_number'];
                        a['nationality'] = shareholders.data[t]['sh_company_nationality'];
                        a['shareholder_type'] = shareholders.data[t]['sh_company_type'];
                        a['director'] = 'no';
                        a['postal_address'] = shareholders.data[t]['sh_company_postal_address'];
                        a['physical_address'] = shareholders.data[t]['sh_company_physical_address'];
                        a['tax_pin'] = shareholders.data[t]['sh_company_pin_number'];
                        a['start_of_shareholding'] = shareholders.data[t]['sh_shareholding_start'];
                        a['national_id'] = `reg-no ${shareholders.data[t]['sh_company_reg_no']}`;
                        a['tax_pin'] = shareholders.data[t]['sh_individual_pin_number'];
                        a['shares'] = shareholders.data[t]['sh_company_shares'];
                        // a['shares_type'] = shareholders.data[t]['sh_company_shares'];
                        y.push(a);
                        t += 1;
                    } while (t < shareholders.data.length);
                    this.setState({dataItem3: [...y]});
                });
            }
        }, {});

    }

    componentDidMount = () => {
        Dispatch({
            type: SUBTITLE,
            payload: "List shareholders"
        });

        // this.sendRequest('get', 'company/operator', operator => {
        //     // if(operator.da)
        //     // if (operator.data[0].operator_shareholders.length > 0) {
        //     // go through the list of shareholders and divide them up into two parts: active and inactive
        //     let active = [], inactive = [], d = 0;
        //     // check if operator_individual_shareholders and operator_company_shareholders is listed
        //     let shlders = [];
        //     let shareholders;
        //     // window.alert('yes operator_shareholders key');
        //     if (Object.getOwnPropertyNames(operator.data[0]).includes('operator_shareholders')) {
        //         // window.alert('yes operator_shareholders key');
        //         shareholders = operator.data[0]['operator_shareholders'];
        //     } else {
        //         // window.alert('no operator_shareholders key')
        //         if (Object.getOwnPropertyNames(operator.data[0]).includes('operator_individual_shareholders'))
        //             shlders = [...operator.data[0]['operator_individual_shareholders']];
        //         if (Object.getOwnPropertyNames(operator.data[0]).includes('operator_company_shareholders'))
        //             shlders = [...operator.data[0]['operator_company_shareholders']];
        //         shareholders = shlders;
        //     }
        //     do {
        //         if (shareholders[d]['status'] === 'active')
        //             active.push(shareholders[d])
        //         else if (shareholders[d]['status'] === 'inactive')
        //             inactive.push(shareholders[d])
        //         d += 1;
        //     } while (d < operator.data[0].length);
        //     this.setState({dataItem1: shareholders});
        //     // this.setState({dataItem1: [...active], dataItem2: [...inactive]});
        //     // } else notify('No shareholders list found!', 3, true);
        // });
    }

    render = () => {
        return <Section>
            <SectionTitle>
                List of shareholders
            </SectionTitle>
            <SectionRow>
                {/*<SectionTitle>*/}
                {/*    Active*/}
                {/*</SectionTitle>*/}
            </SectionRow>
            <SectionRow>
                {/*<SectionColumn onClick={() => this.setState({visible: true})}*/}
                {/*               style={{cursor: 'pointer', fontWeight: 600, color: '#203e64'}} boxedColour>*/}
                {/*    Existing shareholders*/}
                {/*</SectionColumn>*/}
                {/*<SectionColumn onClick={() => this.setState({visible: false})}*/}
                {/*               style={{cursor: 'pointer', fontWeight: 600, color: '#0d3596'}} boxedColour>*/}
                {/*    Declare new shareholders*/}
                {/*</SectionColumn>*/}
            </SectionRow>
            {/*{*/}
            {/*    !this.state.visible && <OperatorShareHoldersSection/>*/}
            {/*}*/}
            {/*{*/}
            {/*    this.state.visible && */}
            <SectionRow>
                <DynamicTable
                    // noSearch
                    // noParaphrase
                    // context={'shareholders'}
                    pageSize={6}
                    columnsOfInterest={[
                        // 'id',
                        'shareholder_name',
                        'director',
                        'company_or_individual',
                        'gender',
                        'shareholder_type',
                        'start_of_shareholding'
                    ]}
                    tableData={this.state.dataItem3}
                    showRowData={shareholder => {
                        Dispatch({type: MISC_ID, payload: shareholder});
                        this.navigateTo('../shareholder-view',
                            false,
                            {row: shareholder})
                    }}

                />
            </SectionRow>
            {/*}*/}

            <SectionRow/>
            {this.state.dataItem4.length > 0 && <>
                <SectionRow>
                    <SectionTitle>
                        inactive
                    </SectionTitle>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <DynamicTable
                            noSearch
                            context={'shoulders'}
                            pageSize={8}
                            tableData={this.props.separateView && this.state.dataItem4}
                            showRowData={shareholder => {
                                Dispatch({type: MISC_ID, payload: shareholder});
                                this.navigateTo('../shareholder-view', false, {row: shareholder})
                            }}
                            columnsOfInterest={[
                                'sh_first_name',
                                'sh_middle_name',
                                'sh_uindividual_phone_number',
                                'sh_national_id',
                                // 'sh_shares']}
                                'sh_company_shares']}
                        />
                    </SectionColumn>
                </SectionRow></>}

            {this.state.currentRoute}
        </Section>
    }
}