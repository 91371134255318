import React, {Component} from 'react';
import CloseControl from "../controls/svg-controls/CloseControl";
import {SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";

import './singleton.css';

/**
 *
 * singleton row
 *
 */
export default class SingletonRow extends Component {
    constructor(props) {
        super(props);
        this.item = props.item === undefined ? null : props.item; // a json object with key and value
        this.currentItem = this.item.constructor.name === [].constructor.name ?
            Object.getOwnPropertyNames(this.item)[0] : this.item.constructor.name === {}.constructor.name ?
                Object.getOwnPropertyNames(this.item) : this.item.constructor.name === ''.constructor.name ?
                    this.item : TypeError(`<${this.constructor.name} /> component expected
                                    the 'item' property to either have a array with exactly one 
                                    json object, a json object or a plain string to show. 
                                    Instead, found ${this.item.constructor.name}`);
    }

    render = () => {
        return (
            <SectionRow className={'singleton-row'}>
                {
                    this.props.itemIsObject ? <SectionColumn>
                        {typeof this.item === 'object' ? this.item : this.item.constructor.name === {}.constructor.name ? this.item[this.currentItem] : this.currentItem}
                    </SectionColumn> : <SectionColumn>
                        {
                            this.item.constructor.name === {}.constructor.name ? this.item[this.currentItem] : this.currentItem}
                    </SectionColumn>
                }
                {
                    this.props.callback !== undefined ? <SectionColumn style={{width: 30}}>
                        <CloseControl style={{width: 16, height: 16}}
                                      callback={() => {
                                          this.props.callback(this.item);
                                      }}/>
                    </SectionColumn> : null
                }
            </SectionRow>
        );
    }
}