import React from "react";
import BaseField from "./base/BaseField";
import {formatDate} from "../../../MiscUtils";

/**
 *
 * DateField is used to collect dates. NOTE: the international format: dd/mm/yyyy
 * is always used to report these dates to the server. However, the format for the display
 * is always determined by the locale that the showing browser is set to. In this case,
 * it's set to be in line with the AMERIKITES way of reading dates: mm/dd/yyyy which sucks!
 *
 * The display format can only be overriden when the locale is changed to proper sensible
 * international formats.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
 *
 */
export default class DateField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        const date = new Date();
        const currentMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const currentDate = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
        this.currentDate = date.getFullYear() + '-' + currentMonth + '-' + currentDate;
        //${date.getFullYear()'-'${currentMonth}'-'${currentDate}}
        // this.currentDate = `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}`
        // : date.getMonth() + 1}/${date.getDate() > 9 ? date.getDate():
        // `0${date.getDate()}`/`${date.getFullYear()}`}`;
        if (props.onlyPastDates && props.onlyFutureDates) {
            // this is an impossibility
            const error = 'Cannot use the properties "onlyPastDates" and "onlyFutureDates" at the same time. ' +
                'Only one is used. ' +
                'Defaulting to using onlyPastDates...';
            console.error(error);
            // this.minimumDate = '07/01/1965'; // july first 1965 (amerikan stupid date format
            this.maximumDate = this.currentDate;
            // throw new DOMException(error);
        } else if (props.onlyFutureDates) {
            this.minimumDate = this.currentDate;
            this.maximumDate = null;
        } else if (props.onlyPastDates) {
            this.minimumDate = '07/01/1965';
            this.maximumDate = this.currentDate;
        }
        this.isRequired = props.required === undefined;
        // if only future dates, minimum date is today
    }

    render = () => {
        /*
         * onFocus allows the legend to be changed
         */
        return (
            <div className={`form-group`} style={this.props.style}>
                <label className={`${this.state.selection} form-label`} style={this.textStyling}>
                    {this.fieldPlaceHolder}
                    {this.isRequired}
                </label>
                <input
                    {...this.required}
                    type={"date"}
                    className={"form-control"}
                    required={this.isRequired}
                    // pattern={'[1-31]{2}/[1-12]{2}/{4}'}
                    min={this.minimumDate}
                    placeholder={'dd/mm/yyyy'}
                    max={this.maximumDate}
                    // check why setting the value kills off memory recall
                    value={this.props.defaultValue && formatDate(this.props.defaultValue, 'dmy', 'ymd', '-')}
                    ref={this.internalFieldReference}
                    name={this.name}
                    onFocus={this.highlightOnFocus}
                    onChange={(e) => {
                        this.changeCallback(e);
                    }}
                    onBlur={() => {
                        this.evaluateControlOnRequired();
                        this.blurCallback();
                    }}
                />
                {this.state.possibleContextMessageBox}
            </div>
        );
    };
}
