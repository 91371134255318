import GMS from "../../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../rotm-custom-views/view-utils/SectionUtils";
import DeclarePremise from "../../user-ui/representative/premises/DeclarePremise";
import React from "react";

export default class OutletApplication extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
    }
    render = () => {
        return <Section>
            <SectionTitle>
                Declare Premises
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    Select from the map the premises you intend to apply a license for, then fill in the fields of each
                    card as required.
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <DeclarePremise/>
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}