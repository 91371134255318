// import GMS from "../../../DataContainer";
import {secureHashedData} from "../../../MiscUtils";

// @deprecated
export const recordPotentialPayment = (potentialPaymentEndpoint, paymentPayload) => {
    this.sendRequest("post", potentialPaymentEndpoint, outcome => {
        let d = outcome.data[0];
        // if ("billRefNumber" in Object.keys(d))
        //     return d.billRefNumber;
        // else throw new ReferenceError(`No bill description found for the potential payement with payload ${paymentPayload}`)
    }, paymentPayload)
}
export const prepareIframePayload = (commonData, dataToHash, billRefNumber, secret, key) => {
// export const prepareIframePayload = () => {
    // do a crypto for the credentials within this array of objects then compare with
    // secureHash field content
    /**
     *
     * @type {string}
     */
    let hashableData = [...dataToHash];
    let hData = [];
    let d = [...commonData];
    let y = 0;
    do {
        if (y === 5)
            hData.push({billRefNumber: billRefNumber});
        // hData.push({billRefNumber: "BCLBSB088801B"});
        hData.push(hashableData[y]);
        // }
        y += 1;
    } while (y < hashableData.length)
    // let hData = [];
    //console.log(hData)
    let x = [...d, {billRefNumber: billRefNumber}]
    // let x = [...d, {billRefNumber: "BCLBSB088801B"}]
    const arrayWithHash = secureHashedData(hData, 'hmac_256', secret, key, false);
    // const arrayWithHash = secureHashedData(hData, 'hmac_256', s, k, false);
    d = [...x, {secureHash: arrayWithHash.secureHash}];
    //console.log('payload',arrayOfJSONsToJSON(d))
    // make sure you trigger the backend to fetch payment information data within the callbackOnResponse.
    // Send the JSON directly
    return d;
    //
    //
    //

}

export default {
    prepareIframePayload,
    recordPotentialPayment
}
