import GMS from "../../../../parent-component/GMS";
import {connect} from "react-redux";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import DocumentUploader from "../../../../general-components/document-viewer/DocumentUploader";

/**
 *
 * Creates and provides callbacks to fields relating to the matrix/formulae used to select
 * winners of a given game or rewards schemes.
 * Fields include:
 *  1. formulae (drop-down list) for selection of what constitutes a win.
 *  2. description (text-area) for description of a custom formulae
 *
 */
class ApplicationDocumentsUploadFields extends GMS {
    constructor(props) {
        super(props);
        //this regular expression is used to look for the words default, custom or multiple, a
        //inherent signal that a textarea needs to be shown to accompany this field's selection
        this.decideIfDescriptionFieldIsNecessary = new RegExp(/^[default|custom|none|multiple]$/);
    }

    /**
     *
     * Load the property game_formulae onto the selection field
     * @param nextProps the property list from local storage
     * @param nextContext
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        //'game_type', 'application_type', 'game_cause'
        this.setState({
            e: nextProps.LOCAL_BACKUP,
            dataItem4: nextProps.application_type,
            dataItem3: nextProps.game,
            dataItem1: nextProps.run_reason,
        });
    }
    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'upload required documentation'});
        // this.sendRequest('get', 'selections/operatordocuments', (data) => {
        this.sendRequest('get', this.props.documentCategoriesURL ? this.props.documentCategoriesURL : 'selections/operatordocuments', (data) => {
            data.type === "Success" &&
            this.setState({dataItem3: [...data.data]});
        });
    }

    render = () => {
        return <DocumentUploader noDownload
                                 upload={this.sendRequest}
                                 categories={this.state.dataItem3}
                                 context={'license'}
        />

    }
}

/**
 *
 * MapViewer all state that is recorded in local storage to the state of the current component.
 * Any class that wields this state (through inheritance) has access to the mapped
 * state from local-storage.
 *
 * @param localStorageState the state as recorded in the local storage
 * Do not connect the parent class because connected classes MUST NEVER BE SUBCLASSED!
 *
 */
// load data from redux and populate it into the GMS state. Licensor is not used anywhere else
const mapStateToProps = (localStorageState) => {
    let p = {};
    //the jwt, email of agent, operator-registration details
    const itemsOfInterest = ['counties', 'countries'];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    // seems kila kitu hapa kinaloadiwa
    return p;//seems there are efficiency problems here...
}

// connect will return the GMS having been connected to the local storage.
// find a way for inheriting a connected component...
// learned from https://daveceddia.com/redux-connect-export/ (does not work!)
// this is a wrapped component with the GMS as part of it...
//
// Note: Connected components_to_delete MUST NEVER BE SUB-CLASSED!
//                      :: https://www.scrivito.com/connect-component-1c81c8d3b87493af
//
export default connect(mapStateToProps)(ApplicationDocumentsUploadFields);//component must have a render method

//fetch from connect and find out whether the following items are available