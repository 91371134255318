import GMS from "../../parent-component/GMS";
import {BCLB} from "../../general-components/redux/allowed-actions";
import {readFromLocalStorage} from "../../../MiscUtils";

import './operator-view-related-css.css';
import '../custom-views-css.css';
import ContextMenu from "../context/ContextMenu";
import OperatorSummary
    from "../../interfaces/component-input-fields/operator-declaration/operator-collector-sections/OperatorSummary";
import React from "react";

/**
 *
 *NOTE -> : In the event of the 2019 National Game Authority Act passes, all instances of the symbol BCLB shall henceforth
 * become NGA
 *
 * This component is either called from a menu click or it is loaded by default
 *
 * This class provides an interface for viewing operator-registration data such as:
 * 1. operator-registration name, business, location, OPTIONAL-PRIVILEGED: [shops, past licenses, payment information (including cheques)]
 * 2. List of games offered, OPTIONAL-PRIVILEGED: [associated instruments and where instruments are found]
 * 3. OPTIONAL-PRIVILEGED: [operator-registration payments (for licenses or other relative to the board)]
 * 4. OPTIONAL-PRIVILEGED: [operator-registration transactional records as per BCLB, targeted tax calculations etc]
 * 5. OPTIONAL-PRIVILEGED: [operator-registration documents]
 * 6. PRIVILEGED: [operator-registration representatives (if anyone within BCLB is accessing this record)
 *          - start to finish times
 *          - names
 *          - contacts
 *          - shareholder resolution document authorizing this representation
 *          - for the operator-registration in question
 *          - currently representing which operator-registration
 *          ]
 * 7. OPTIONAL--CONTEXT-PRIVILEGED: [communications/chats on this operator-registration: each chat representing a different topic/context]
 *
 * NOTE 2 -> on the "CAPITALIZED LABELS":
 *       PRIVILEGED - means that only members of BCLB can see this information.
 *       OPTIONAL-PRIVILEGED - means that depending on the clearance of BCLB staff and/or including the representative
 *                             in question, this information is available to them
 *       OPTIONAL-CONTEXT-PRIVILEGED - means that this information is available to the public but must be in context
 *                             (meaning that this information is availed as a result of a just-in-time requirement).
 *
 * It may also provide actions (based on the user who is logged in) on the said View
 * as a actions component that floats on top of the current view and can be minimized or closed
 * when action is in progress.
 *
 */
export default class OperatorView extends GMS {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        // get the id of the company being viewed currently
        this.operator = readFromLocalStorage('operator', BCLB)[0];
        this.role = readFromLocalStorage('role', BCLB);
        // check whether the operator shareholders are individual or company. Then list them
        // accordingly/make sure that the fields are added to this.operator instance field.
        //////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////
        this.statusesToEnableTasking = ['complete', 'stopped', 1, 5];
        // this.sendRequest('get','account/user', user => {
        // },{})
    }

    /**
     *
     * Fetch this company's data
     */
    componentDidMount = () => {
        // when mounted, pass the data through miscellaneousItems variable
    }
    /**
     *
     * Allow the draggable window to close
     *
     */
    closeDraggableWindow = () => {
        this.setState({contextWindow: null});
    }

    render = () => {
        const cMenu = this.role === 4 ? null :
            <ContextMenu
                task={'../create-task'}
            />
        // if the role of this person is a section head, load the OperatorSummary with a checklist if the checklist was not already done
        return <>{this.state.checkList}<OperatorSummary
            summaryData={this.operator}
            contextMenu={cMenu}/>
        </>
    }
}
