import GMS from "../../../../parent-component/GMS";

import React from "react";
import {connect} from "react-redux";
import {BCLB, LOCAL_BACKUP} from "../../../../general-components/redux/allowed-actions";

import EmailField from "../../../../general-components/input-field/EmailField";
import PhoneNumberField from "../../../../general-components/input-field/PhoneNumberField";
import TextField from "../../../../general-components/input-field/TextField";
import NumberField from "../../../../general-components/input-field/NumberField";
import {getStorageObject} from "../../../../../MiscUtils";
import RadioField from "../../../../general-components/input-field/RadioField";
import FileSelectField from "../../../../general-components/input-field/FileSelectField";
import DocumentDetailsCard from "../../../../general-components/document-viewer/DocumentDetailsCard";
import {Section, SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import NameField from "../../../../general-components/input-field/NameField";
import KRAPinField from "../../../../general-components/input-field/KRAPinField";

const kenyanDomain = new RegExp(/^[a-zA-Z0-9].co.ke$/);

/**
 *
 * This section consists of sub-components_to_delete of operator-view-sections. All these are private.
 *
 */

class OperatorBioSection extends GMS {
    /**
     *
     *
     * add item to list of games selected
     * @param itemToAdd the select field (select option)
     *
     */
    constructor(props) {
        super(props);
        this.storage = getStorageObject(BCLB);
    }

    addToGameMachinesList = (itemToAdd) => {
        let item = {}; //item that hosts the final item to add as a JSON object.
        const key = itemToAdd.target.selectedIndex;
        item[key] = itemToAdd.target.value;
        this.updateGamesMachinesdataItem1(item).then((r) =>
            this.prepareMachineSingleTonRows(
                this.deleteMachinedataItem1,
                this.state.dataItem3
            )
        );
        // this.gamesSelections.push(item);
    };

    /**
     *
     * Populate state with data from local storage
     * @param nextProps
     * @param nextContext
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            e: nextProps.LOCAL_BACKUP, //also load previous data from backup
            banks: nextProps.banks,
            branches: nextProps.branches,
            games: nextProps.game,
            machines: nextProps.machines,
        });
    };

    /**
     *
     * Use component will receive props to acquire props from redux
     *
     */
    componentDidMount = () => {
        // this.props.setMandatoryFields([
        //     // 'company_type',
        //     'company_name',
        //     'local_company_reg_number',
        //     'local_company_reg_number',
        //     'local_pin_number',
        //     'operator_phone_number',
        //     ]);
        // let storageData = getStorageObject(BCLB);
        this.setState({
            e: this.storage.LOCAL_BACKUP,
            miscellaneousItems: true,
            variable: this.storage.LOCAL_BACKUP.company_type
        }, () => {
        });
        this.sendRequest('get', 'account/user', user => {
            if (user.type !== 'Fail' && user.data.length > 0)
                this.setState({dataItem5: [...user.data]},
                    () => this.collectFieldData(['company_name', this.state.dataItem5[0]['company_name']]).then(() => {
                        this.collectFieldData(['company_registration_number', this.state.dataItem5[0]['company_name']])
                    }));
        }, {});
    };

    /**
     *
     * Create bank fieldsets
     *
     */
    // createBankFieldSets = () => {
    //     //console.log("createbankfieldsets dataItem2", this.state.dataItem2)
    //     let x = 0;
    //     let y = [];
    //     const counted = this.state.e['banks'] ? this.state.e['banks'].length : this.state.dataItem2
    //     do {
    //         y.push(<BankFieldSet fieldData={this.state.e && this.state.e['banks'] && this.state.e['banks'][x]}
    //                              updateBankData={this.updateBankData}
    //                              banks={this.storage['banks']}
    //                              branches={this.storage['branches']}
    //                              identifier={x + 1}
    //         />);
    //         x += 1;
    //     } while (x < counted)
    //     this.setState({dataItem4: [...y]});
    // }
    /**
     *
     * The UI for OperatorBioSection
     *
     * @returns {JSX.Element}
     */
    render = () => {
        // prepopulate the operator name into collect field data using the array notation
        //defaultValue={this.state.dataItem5.length > 0 ? this.state.dataItem5[0].company_name : this.state.e['company_name'] && this.state.e['company_name']}

        return <Section>
            <SectionRow boxedColour>
                <SectionColumn>
                    {/*<SectionRow>*/}
                    {/*    <SectionColumn>*/}
                    {/*        <legend className={"legend-section-title no-border"}>*/}
                    {/*            Type of {this.state.e && this.state.e['company_type'] === 'LLP' ? 'Partnership' : 'Company'}*/}
                    {/*        </legend>*/}
                    {/*    </SectionColumn>*/}
                    {/*</SectionRow>*/}
                    <SectionRow>
                        <SectionColumn style={{width: 40}}/>
                        <SectionColumn>
                            {/*<SelectField isRequried*/}
                            {/*             bold*/}
                            {/*             name={'company_type'}*/}
                            {/*             placeholder={'Type of company'}*/}
                            {/*             options={[{'company_type': 'LLC'}, {'company_type': 'LLP'}]}/>*/}
                            <RadioField name={'company_type'}
                                        placeholder={'Limited Liability Company (LLC/LTD)'}
                                // defaultValue={this.state.e && this.state.e['company_type'] === 'LLC'}
                                        changeCallback={e => {
                                            this.collectFieldData([e.target.name, 'LLC']).then(() => {
                                                // remove LLP and remove the data about company data about llp-ness
                                                this.setState({
                                                    // variable: e.target.checked,
                                                    visible: false,
                                                    miscellaneousItems: true
                                                })
                                            })
                                        }}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <RadioField name={'company_type'}
                                // bordered
                                        placeholder={'Limited Liability Partnership (LLP)'}
                                // defaultValue={this.state.e && this.state.e['company_type'] === 'LLP'}
                                        changeCallback={e => {
                                            this.collectFieldData([e.target.name, 'LLP']).then(() => {
                                                // choose to show the company type
                                                this.setState({
                                                    // variable: e.target.checked,
                                                    visible: true,
                                                    miscellaneousItems: false
                                                })
                                            })
                                        }}
                            />
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow boxedColour>
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn>
                            <legend className={"legend-section-title no-border"}>
                                {/*{this.state.e && this.state.e['company_type'] === 'LLP' ? 'Partnership' : 'Company'} Details*/}
                                Company Details
                            </legend>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <NameField bold fontSize={14}
                                       isRequired
                                       capitalize
                                       maxLength={100}
                                       readOnly={this.state.dataItem5.length > 0} // make sure the readonly attribute is optional
                                       defaultValue={this.state.dataItem5.length > 0 ?
                                           this.state.dataItem5[0].company_name :
                                           this.state.e['company_name'] && this.state.e['company_name']}
                                       name={"company_name"}
                                       placeholder={"company name"}
                                // changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField bold fontSize={14}
                                       capitalize
                                       defaultValue={this.state.e && this.state.e['local_business_name']}
                                // isRequired
                                       name={"local_business_name"}
                                       placeholder={"trade name"}
                                       changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <TextField isRequired
                                       bold
                                       fontSize={14}
                                       maxLength={100}
                                       readOnly={this.state.dataItem5.length > 0} // make sure the readonly attribute is optional
                                       defaultValue={this.state.dataItem5.length > 0 ?
                                           this.state.dataItem5[0].company_registration_number :
                                           this.state.e['company_registration_number'] && this.state.e['company_registration_number']}
                                       name={'company_registration_number'}
                                       placeholder={'Registration number'}
                                // changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                            />
                        </SectionColumn>

                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <KRAPinField isRequired
                                         bold
                                         forCompany
                                         fontSize={14}
                                         defaultValue={this.state.e && this.state.e['local_pin_number'] && this.state.e['local_pin_number']}
                                         name={'local_pin_number'}
                                         placeholder={'Company K.R.A. pin'}
                                         changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <EmailField bold
                                        capitalize
                                // isRequired
                                        fontSize={14}
                                        defaultValue={this.state.e && this.state.e['operator_email'] && this.state.e["operator_email"]}
                                        name={"operator_email"}
                                        placeholder={"Company email address"}
                                        changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                                // changeCallback={e => {
                                //     let outcome = [];
                                //     // check if this email is a multiple list
                                //     if (e.target.value.indexOf(',') > 2) {
                                //         // split along the comma and iterate through
                                //         // them and look for the .co.ke domain
                                //         const s = e.target.value.split(',');
                                //         let y = 0;
                                //         do {
                                //             if (!(kenyanDomain.test(s[y]))) {
                                //                 notify(`${s[y]} is not a kenyan domain. We recommend
                                //         you get a kenyan domain`, 1, true);
                                //             }
                                //             outcome.push(s[y]);
                                //             y += 1;
                                //         }
                                //         while (y < s.length)
                                //         //console.log('this is a array of emails', e.target.value)
                                //     } else {
                                //         //console.log('normal value ', e.target.value)
                                //         outcome.push(e.target.value);
                                //     }
                                //     this.collectFieldData([e.target.name, outcome]);
                                // }}
                            />
                        </SectionColumn>
                        <SectionColumn>
                            <PhoneNumberField bold fontSize={14}
                                              capitalize
                                              isRequired
                                              defaultValue={this.state.e && this.state.e['operator_phone_number'] && this.state.e["operator_phone_number"]}
                                              name={"operator_phone_number"}
                                              placeholder={"Office Contact"}
                                              changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <TextField bold
                                       fontSize={14}
                                       capitalize
                                // isRequired
                                       defaultValue={this.state.e && this.state.e['operator_postal_address'] && this.state.e["operator_postal_address"]}
                                       name={"operator_postal_address"}
                                       placeholder={"postal address"}
                                       changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                            />

                        </SectionColumn>
                        <SectionColumn>
                            <NumberField
                                bold
                                fontSize={14}
                                capitalize
                                // isRequired
                                defaultValue={this.state.e && this.state.e['operator_zip_code'] && this.state.e["operator_zip_code"]}
                                name={"operator_zip_code"}
                                placeholder={"postal (zip) code"}
                                changeCallback={d => this.collectFieldData([d.target.name, d.target.value])}
                            />
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            {
                this.state.visible ? <SectionRow id={'LLP-data-here'}>
                    <SectionColumn>
                        <br/>
                        <SectionRow>
                            <SectionColumn>
                                <legend className={"legend-section-title no-border"}>
                                    Certificate of registration
                                </legend>
                            </SectionColumn>
                        </SectionRow>
                        {/*<SimpleLabel text={'Upload the certificate of registration'} bold/>*/}
                        <FileSelectField
                            name={'llp_agreement'}
                            placeholder={''}
                            changeCallback={e => {
                                // buffer the document into
                                // this.setState({dataItem1: [...e.target.files[0]]})
                                this.props.bufferStateData('biosection', e.target.files[0]);
                            }}/>
                    </SectionColumn>
                    <SectionColumn>
                        <DocumentDetailsCard disableDownload
                                             document={this.state.dataItem1}/>
                    </SectionColumn>
                </SectionRow> : null
            }
            <SectionRow>
                <SectionColumn style={{height: 156}}/>
            </SectionRow>
        </Section>
    };
}

/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only games, banks, branches and instruments are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = [
        "game",
        "banks",
        "branches",
        "machines",
        LOCAL_BACKUP,
    ];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
};
export default connect(mapStateToProps)(OperatorBioSection);
