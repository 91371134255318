import GMS from "../../../../parent-component/GMS";
import {Section, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import DynamicTable from "../../../../general-components/tables/DynamicTable";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {MISC_ID, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import LoadingData from "../../../../general-components/controls/data-loading/LoadingData";
import React from "react";

/**
 *
 * List my shareholders. Get the operator, get the operator_shareholders data
 *
 */
export default class ShareholdersList extends GMS {
    constructor(props) {
        super(props)
        let t = 0;

        let z = [];

        let y = [];
        if (!props.shareholdersList) {
            this.sendRequest('get', 'company/operator/individual-shareholder', shareholders => {
                if (shareholders.data.length > 0) {
                    if (shareholders.type === 'Success') {
                        if (shareholders.data.length > 0) {
                            do {
                                let a = {};
                                a['id'] = shareholders.data[t]['id'];
                                a['shareholder_name'] = `${shareholders.data[t]['sh_first_name']} ${shareholders.data[t]['sh_middle_name']} ${shareholders.data[t]['sh_surname']}`;
                                a['gender'] = shareholders.data[t]['sh_gender'];
                                a['type'] = 'individual';
                                a['email'] = shareholders.data[t]['sh_individual_email'];
                                a['postal_address'] = shareholders.data[t]['sh_individual_postal_address'];
                                a['physical_address'] = shareholders.data[t]['sh_individual_physical_address'];
                                a['contact'] = shareholders.data[t]['sh_individual_phone_number'];
                                a['nationality'] = shareholders.data[t]['sh_individual_nationality'];
                                a['shareholder_type'] = shareholders.data[t]['sh_individual_type'] ?
                                    shareholders.data[t]['sh_individual_type'] : shareholders.data[t]['sh_company_type'];
                                a['director'] = shareholders.data[t]['sh_is_director'] ? 'yes' : 'no';
                                a['start_of_shareholding'] = shareholders.data[t]['sh_shareholding_start'];
                                a['national_id'] = shareholders.data[t]['sh_national_id'];
                                a['tax_pin'] = shareholders.data[t]['sh_individual_pin_number'];
                                a['shareholder_kra_file'] = shareholders.data[t]['shareholder_kra_file'];
                                a['shareholder_cogc_file'] = shareholders.data[t]['shareholder_cogc_file'];
                                a['shareholder_kra_tcc_file'] = shareholders.data[t]['shareholder_kra_tcc_file'];
                                a['shareholder_id_file'] = shareholders.data[t]['shareholder_id_file'];
                                a['shares'] = shareholders.data[t]['sh_individual_number_of_shares'];
                                y.push(a);
                                t += 1;
                            }
                            while (t < shareholders.data.length) ;
                        }
                    }
                    // conduct the next request:
                    this.sendRequest('get', 'company/operator/company-shareholder', shareholders => {
                        if (shareholders.type === 'Success') {
                            if (shareholders.data.length > 0) {
                                t = 0; // to have the next index
                                do {
                                    let a = {};
                                    a['id'] = shareholders.data[t]['id'];
                                    a['shareholder_name'] = shareholders.data[t]['sh_company_name'];
                                    a['gender'] = 'N/A';
                                    a['type'] = 'company';
                                    a['email'] = shareholders.data[t]['sh_company_email'];
                                    a['contact'] = shareholders.data[t]['sh_individual_phone_number'];
                                    a['nationality'] = shareholders.data[t]['sh_company_nationality'];
                                    a['shareholder_type'] = shareholders.data[t]['sh_company_type'];
                                    a['director'] = 'no';
                                    a['postal_address'] = shareholders.data[t]['sh_company_postal_address'];
                                    a['physical_address'] = shareholders.data[t]['sh_company_physical_address'];
                                    a['tax_pin'] = shareholders.data[t]['sh_company_pin_number'];
                                    a['start_of_shareholding'] = shareholders.data[t]['sh_shareholding_start'];
                                    a['national_id'] = `reg-no ${shareholders.data[t]['sh_company_reg_no']}`;
                                    a['tax_pin'] = shareholders.data[t]['sh_company_pin_number'];
                                    a['company_kra_doc'] = shareholders.data[t]['sh_company_number_of_shares'];
                                    a['company_notarized_sh_doc'] = shareholders.data[t]['company_notarized_sh_doc'];
                                    a['shares'] = shareholders.data[t]['sh_company_number_of_shares'];
                                    // a['shares_type'] = shareholders.data[t]['sh_company_shares'];
                                    y.push(a);
                                    t += 1;
                                } while (t < shareholders.data.length);
                            }
                        }
                        this.setState({visible: true, dataItem3: [...y]});
                    });
                }
            }, {});
        }
    }

    /**
     *
     *
     * Format shareholder information such that it can be taken up by the ShareholdersList table
     * @returns {[]}
     *
     */
    resequenceShareholders = () => {
        let y = [];
        if (this.props.shareholdersList.length > 0) {
            let t = 0;

            do {
                let a = {};
                console.log(this.props.shareholdersList[t]['sh_company_shares'], this.props.shareholdersList[t]['sh_shares'])
                let k = Object.getOwnPropertyNames(this.props.shareholdersList[t]);
                if (k.indexOf('sh_first_name') > -1) // this is an individual shareholder
                {
                    a['id'] = this.props.shareholdersList[t]['id'];
                    a['shareholder_name'] = `${this.props.shareholdersList[t]['sh_first_name']} ${this.props.shareholdersList[t]['sh_middle_name']} ${this.props.shareholdersList[t]['sh_surname']}`;
                    a['gender'] = this.props.shareholdersList[t]['sh_gender'];
                    a['type'] = 'individual';
                    a['email'] = this.props.shareholdersList[t]['sh_individual_email'];
                    a['postal_address'] = this.props.shareholdersList[t]['sh_individual_postal_address'];
                    a['physical_address'] = this.props.shareholdersList[t]['sh_individual_physical_address'];
                    a['contact'] = this.props.shareholdersList[t]['sh_individual_phone_number'];
                    a['nationality'] = this.props.shareholdersList[t]['sh_individual_nationality'];
                    a['shareholder_type'] = this.props.shareholdersList[t]['sh_individual_type'] ?
                        this.props.shareholdersList[t]['sh_individual_type'] :
                        this.props.shareholdersList[t]['sh_company_type'];
                    a['director'] = this.props.shareholdersList[t]['sh_is_director'] ? 'yes' : 'no';
                    a['start_of_shareholding'] = this.props.shareholdersList[t]['sh_shareholding_start'];
                    a['national_id'] = this.props.shareholdersList[t]['sh_national_id'];
                    a['tax_pin'] = this.props.shareholdersList[t]['sh_individual_pin_number'];
                    a['shares'] = this.props.shareholdersList[t]['sh_shares'] === undefined ?
                        this.props.shareholdersList[t]['sh_individual_number_of_shares'] :
                        this.props.shareholdersList[t]['sh_shares'];
                } else if (k.indexOf('sh_company_name') > -1) // this is a company
                {
                    a['id'] = this.props.shareholdersList[t]['id'];
                    a['shareholder_name'] = this.props.shareholdersList[t]['sh_company_name'];
                    a['gender'] = 'N/A';
                    a['type'] = 'company';
                    // a['email'] = this.props.shareholdersList[t]['sh_company_email'];
                    a['director'] = 'no';
                    a['tax_pin'] = this.props.shareholdersList[t]['sh_company_pin_number'];
                    a['start_of_shareholding'] = this.props.shareholdersList[t]['sh_shareholding_start'];
                    a['national_id'] = `reg-no ${this.props.shareholdersList[t]['sh_company_reg_no']}`;
                    a['shares'] = this.props.shareholdersList[t]['sh_company_shares'] === undefined ?
                        this.props.shareholdersList[t]['sh_company_number_of_shares'] :
                        this.props.shareholdersList[t]['sh_company_shares'];
                }
                y.push(a);
                t += 1;
            } while (t < this.props.shareholdersList.length)
        }
        return y;
    }

    componentDidMount = () => {
        Dispatch({
            type: SUBTITLE,
            payload: "List shareholders"
        });
        // if list is coming from the operator summary, render that as a matter of priority.
        if (this.props.shareholdersList) {
            this.setState({dataItem3: [...this.resequenceShareholders()]})
        }
        //
        // Check whether there are custom payments
    }

    render = () => {
        // console.log(this.state.dataItem3)
        const columnOfInterest = [
            'shareholder_name',
            'type',
            'director',
            'gender',
            'shares'
        ]
        return <Section>
            {
                !this.props.noTitle && <span>
                    <SectionTitle>
                List of shareholders
            </SectionTitle>
            <SectionRow>
                <SectionTitle>
                    Active
                </SectionTitle>
            </SectionRow>
                    </span>
            }
            <SectionRow>
                {
                    !this.state.visible ?
                        <LoadingData hasData={this.state.dataItem3.length === 0} message={'Loading shareholders...'}/> : <DynamicTable
                            // noSearch
                            // noParaphrase
                            // context={'shareholders'}
                            pageSize={16}
                            columnsOfInterest={columnOfInterest}
                            tableData={this.state.dataItem3}
                            showRowData={shareholder => {
                                Dispatch({type: MISC_ID, payload: shareholder});
                                this.navigateTo('../shareholder-view',
                                    false,
                                    {row: shareholder})
                            }}

                        />
                }

            </SectionRow>
            <SectionRow/>
            {/*{this.state.dataItem4.length > 0 && <>*/}
            {/*    <SectionRow>*/}
            {/*        <SectionTitle>*/}
            {/*            inactive*/}
            {/*        </SectionTitle>*/}
            {/*    </SectionRow>*/}
            {/*    <SectionRow>*/}
            {/*        <SectionColumn>*/}
            {/*            <DynamicTable*/}
            {/*                noSearch*/}
            {/*                context={'shoulders'}*/}
            {/*                pageSize={8}*/}
            {/*                tableData={this.props.separateView && this.state.dataItem4}*/}
            {/*                showRowData={shareholder => {*/}
            {/*                    Dispatch({type: MISC_ID, payload: shareholder});*/}
            {/*                    this.navigateTo('../shareholder-view', false, {row: shareholder})*/}
            {/*                }}*/}
            {/*                columnsOfInterest={[*/}
            {/*                    'sh_first_name',*/}
            {/*                    'sh_middle_name',*/}
            {/*                    'sh_individual_phone_number',*/}
            {/*                    'sh_national_id',*/}
            {/*                    // 'sh_shares']}*/}
            {/*                    'sh_company_shares']}*/}
            {/*            />*/}
            {/*        </SectionColumn>*/}
            {/*    </SectionRow></>}*/}

            {this.state.currentRoute}
        </Section>
    }
}