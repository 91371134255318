import GMS from "../../../../../parent-component/GMS";
import {SectionColumn, SectionRow, SectionTitle} from "../../../../../rotm-custom-views/view-utils/SectionUtils";
import SelectField from "../../../../../general-components/input-field/SelectField";
import TextField from "../../../../../general-components/input-field/TextField";
import TextAreaField from "../../../../../general-components/input-field/TextAreaField";
import {connect} from "react-redux";
import NumberField from "../../../../../general-components/input-field/NumberField";
import React from "react";
import {formatDate} from "../../../../../../MiscUtils";
import {Dispatch} from "../../../../../general-components/redux/app-storage";
import {SUBTITLE} from "../../../../../general-components/redux/allowed-actions";


class DrawnDeviceviceFields extends GMS {
    constructor(props) {
        super(props);
        // this.identifier = props.identifier;
    }

    render = () => {
        return <SectionRow boxedColour>
            <SectionColumn>
                <SectionRow>
                    <SectionColumn style={{
                        // background: "#800080",
                        background: '#59788E',
                        color: '#FFFFFF',
                        fontWeight: 600,
                        fontSize: 14,
                        width: 'fit-content',
                        textAlign: "center",
                        borderRadius: 4
                    }}>
                        Device {this.props.identifier + 1}
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <SelectField name={'device_id'}
                                     isRequired
                                     bold
                                     fontSize={14}
                                     defaultValue={this.props.data && this.props.data['device_id']}
                                     placeholder={'Select device type'}
                                     options={[{
                                         'shop_and_premise_hardware_devices': 'Hardware'
                                     }, {
                                         'shop_and_premise_software_devices': 'Software'
                                     }]}
                                     changeCallback={e => {
                                         // this.setState({variable: e.target.options[e.target.selectedIndex].value}, () => {
                                         this.props.updateDeviceData(e, this.props.identifier);
                                         let url = 'selections/hardwaredevicetype';
                                         if (e.target.options[e.target.selectedIndex].value === 'shop_and_premise_software_devices')
                                             url = "selections/softwaredevicetype";
                                         // else url;
                                         this.sendRequest('get', url, devices => {
                                             if (devices.type === 'Success')
                                                 this.setState({dataItem1: [...devices.data]});
                                         }, {});
                                         // })


                                     }}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <SelectField name={'device'}
                                     isRequired
                                     fontSize={14}
                                     bold
                                     defaultValue={this.props.data && this.props.data['device']}
                                     options={this.state.dataItem1}
                                     placeholder={'Specific device'}
                            // changeCallback={e => this.props.updateDeviceData(this.state.variable, e, this.props.identifier)}
                                     changeCallback={e => this.props.updateDeviceData(e, this.props.identifier)}
                        />
                    </SectionColumn>
                    <SectionColumn>
                        <TextField name={'serial_number'}
                                   isRequired
                                   bold
                                   fontSize={14}
                                   defaultValue={this.props.data !== undefined ? this.props.data['serial_number'] : ''}
                                   placeholder={'Serial number ( - version number, if applicable)'}
                            // changeCallback={e => this.props.updateDeviceData(this.state.variable, e, this.props.identifier)}
                                   changeCallback={e => this.props.updateDeviceData(e, this.props.identifier)}
                        />
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextAreaField name={'description'}
                                       noResize
                                       bold
                                       fontSize={14}
                                       defaultValue={this.props.data !== undefined ? this.props.data['description'] : ''}
                                       placeholder={'Description of the device'}
                            // changeCallback={e => this.props.updateDeviceData(this.state.variable, e, this.props.identifier)}
                                       changeCallback={e => this.props.updateDeviceData(e, this.props.identifier)}
                        />
                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
        </SectionRow>
    }
}

/**
 *
 * Device declaration component.
 * The component provides a UI for declaring devices/machines employed
 * by operators in their bid to present business to punters.
 *
 * It collects an array of datadata in the following fields:
 *      [
 *          {
 *              deviceId,
 *              serialNo,
 *              description
 *          },
 *          ...
 *      ]
 */
export class DeviceFields extends GMS {
    constructor(props) {
        super(props);
    }

    /**
     *
     * Create device data fields to collect devices
     *
     * @param number
     * @param deviceData Data of each respective device
     */
    createDeviceFields = (number, deviceData) => {
        let p = 0;
        let y = [];
        do {
            y.push(<DrawnDeviceviceFields
                data={deviceData !== undefined && deviceData['devices'] && deviceData['devices'][p]}
                identifier={p}
                updateDeviceData={this.updateDeviceData}/>);
            p += 1;
        } while (p < number);
        this.setState({dataItem3: [...y]});
    }


    updateDeviceData = (deviceDataObject, belongingTo) => {
        let deviceField = {}; //this is just a pre-construction for new elements within the array
        // check if the current field name exists in state e operator_shareholders array elements for the belongingTo attribute
        deviceField[deviceDataObject.target.name] = deviceDataObject.target.type === "checkbox"
            ? deviceDataObject.target.checked : deviceDataObject.target.type === 'file'
                ? deviceDataObject.target.files[0] : deviceDataObject.target.type === 'date' ?
                    formatDate(deviceDataObject.target.value, 'ymd', 'dmy', '-')
                    : deviceDataObject.target.value;
        // if (classOfDevices) {
        // append it to operatorshareholders
        let devices = this.state.e['devices'] ? this.state.e['devices'] : [];
        // if (this.state.e[classOfDevices]) {
        try {
            devices[belongingTo][deviceDataObject.target.name] = deviceField[deviceDataObject.target.name];
        } catch (newRecordCountFound) {
            devices.push(deviceField);
        }
        // } else devicesInClass.push(deviceField);
        // update state then sort out the classes of shareholders
        // check the shareholder type. If this field is in the state.e object, update it else create one.
        // check if this is the first shareholder director

        // this is the first one
        this.setState(state => {
            state.e['devices'] = [...devices];
            return state;
        });
        // }

        // if (this.state.e[classOfDevices])
        //     if (belongingTo > this.state.e[classOfDevices].length) {
        //         notify('You must start with the first employee when filling in their field data',
        //             2,
        //             false,
        //             'Employee sequencing error!');
        //         return false;
        //     }
        // compile the data into operator_shareholders
        return true;
    };

    constructListOfDevices = () => {
        // set dataItem2
    }
    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'Devices available for use'});
        // send request to get the list of devices.
        this.setState({e: {...this.props}}, () =>
            this.createDeviceFields(this.state.e.devices ? this.state.e.devices.length : 1, this.state.e));
    }

    render = () => {
        return <SectionRow>
            {/*<SectionColumn/>*/}
            <SectionColumn>
                <SectionRow>
                    <SectionTitle>
                        Declare device
                    </SectionTitle>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <NumberField name={'number_devices'}
                                     placeholder={'Number of devices to declare'}
                                     fontSize={14}
                                     defaultValue={this.state.e.number_devices ? this.state.e.number_devices.length : 1}
                                     changeCallback={e => this.createDeviceFields(e.target.value)}
                        />
                    </SectionColumn>
                </SectionRow>
                {this.state.dataItem3}
            </SectionColumn>
            {/*<SectionColumn>*/}
            {/*    {*/}
            {/*        this.state.dataItem2.length > 0 ? this.state.dataItem2 : 'No devices declared'*/}
            {/*    }*/}
            {/*</SectionColumn>*/}
            {/*<SectionColumn/>*/}
        </SectionRow>

    }
}

const mapStateToProps = (reduxState) => {
    let data = {}
    if (reduxState.LOCAL_BACKUP)
        data = {...reduxState.LOCAL_BACKUP};
    return data;
}
export default connect(mapStateToProps)(DeviceFields);