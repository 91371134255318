import './base/svg-controls.css'
import BaseControl from "./base/BaseControl";

/**
 * Class defines a svg-based buttons to remove content from something of interest.
 * The callback passed to it during its call defines its behaviour.
 */
export default class DoubleTick extends BaseControl {
    constructor(props) {
        super(props);
        this.callback = props.callback;
        //square width and height of 60 px
        this.style = props.style === undefined ? {width: 'wrap-content', height: 'wrap-content'} : props.style;
        //original colour of the control
        // #141124
    }

    render = () => {
        return (
            <div style={{width: 'wrap-content', height: 'wrap-content'}}
                 onClick={this.callback}
                 className={this.className}
            >
                <svg style={{...this.style}}
                     xlink="http://www.w3.org/1999/xlink"
                     width="20px" height="16px"
                     viewBox="0 0 469.184 469.185"
                     fill={"#038CFFFF"}
                     space="preserve">
                    <g>
                        <g>
                            <path d="M509.313,67.45c-4.16-4.16-10.88-4.267-15.04-0.107L134.06,422.33l-115.2-121.6c-3.733-4.48-10.453-5.12-15.04-1.387
			c-4.48,3.733-5.12,10.453-1.387,15.04c0.32,0.32,0.533,0.64,0.853,0.96l122.667,129.493c1.92,2.133,4.693,3.307,7.573,3.307h0.213
			c2.773,0,5.44-1.067,7.467-3.093l368-362.667C513.473,78.33,513.473,71.61,509.313,67.45z"/>
                        </g>
                    </g>
                </svg>
                {/*/////////////*/}
                <svg style={{...this.style}}
                     xlink="http://www.w3.org/1999/xlink"
                     width="20px"
                     height="16px"
                     viewBox="0 0 469.184 469.185"
                     fill={"#038CFFFF"}
                     space="preserve">
                    <g>
                        <g>
                            <path d="M509.313,67.45c-4.16-4.16-10.88-4.267-15.04-0.107L134.06,422.33l-115.2-121.6c-3.733-4.48-10.453-5.12-15.04-1.387
			c-4.48,3.733-5.12,10.453-1.387,15.04c0.32,0.32,0.533,0.64,0.853,0.96l122.667,129.493c1.92,2.133,4.693,3.307,7.573,3.307h0.213
			c2.773,0,5.44-1.067,7.467-3.093l368-362.667C513.473,78.33,513.473,71.61,509.313,67.45z"/>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}
