import GMS from "../../../../parent-component/GMS";
import {connect} from "react-redux";
import {
    col12,
    container,
    formatDate,
    getStorageObject,
    notify,
    readFromLocalStorage,
    row
} from "../../../../../MiscUtils";
import TextField from "../../../../general-components/input-field/TextField";
import SelectField from "../../../../general-components/input-field/SelectField";
import React, {Component} from "react";
import {Section, SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {BCLB, LOCAL_BACKUP, NULL_BACKUP, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import NumberField from "../../../../general-components/input-field/NumberField";
import WebAddressField from "../../../../general-components/input-field/WebAddressField";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import FileSelectField from "../../../../general-components/input-field/FileSelectField";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";


class _ProviderFieldSet extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return <Section>
            <SectionRow>
                <SectionColumn>
                    <legend>
                    <span className={"inverse-highlighted-text"}>
                      Service/odds provider&nbsp;
                    </span>
                        <span className={"inverse-highlighted-text"}>
                          {this.props.identifier}
                    </span>
                    </legend>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               defaultValue={this.props.fieldData && this.props.fieldData['provider_name']}
                               isRequired
                               name={"provider_name"}
                               placeholder={"Provider name"}
                               changeCallback={d => {
                                   //d is the target returned as an argument by this component.
                                   // extract the name and value items
                                   this.props.updateOddsData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <SelectField bold fontSize={14}
                                 capitalize
                                 defaultValue={this.props.fieldData ? this.props.fieldData['provider_country'] : []}
                                 isRequired
                                 options={this.props.countries}
                                 name={"provider_country"}
                                 placeholder={"Provider country of origin"}
                                 changeCallback={d => {
                                     this.props.updateOddsData(d, this.props.identifier);
                                 }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <WebAddressField bold fontSize={14}
                                     capitalize
                                     defaultValue={this.props.fieldData && this.props.fieldData['provider_website']}
                                     name={"provider_website"}
                                     placeholder={"Provider domain name"}
                                     changeCallback={d => {
                                         //d is the target returned as an argument by this component.
                                         // extract the name and value items
                                         this.props.updateOddsData(d, this.props.identifier)
                                     }}
                    />
                </SectionColumn>
            </SectionRow>
            {/*///////////////////////////////////////////////////////////*/}
            {/*///////////////////////////////////////////////////////////*/}
            {/*///////////////////////////////////////////////////////////*/}
            {/*///////////////////////////////////////////////////////////*/}
            <SectionRow>
                <SectionColumn>
                    <legend>
                                        <span className={"inverse-highlighted-text"}>
                                          Platform provider
                                        </span>
                        {/*<span className={"inverse-highlighted-text"}>*/}
                        {/*  {identifier}*/}
                        {/*</span>*/}
                    </legend>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <TextField bold fontSize={14}
                               capitalize
                               defaultValue={this.props.fieldData && this.props.fieldData['platform_name']}
                               isRequired
                               name={"platform_name"}
                               placeholder={"Platform provider name"}
                               changeCallback={d => {
                                   //d is the target returned as an argument by this component.
                                   // extract the name and value items
                                   this.props.updateOddsData(d, this.props.identifier);
                               }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <SelectField bold fontSize={14}
                                 capitalize
                                 defaultValue={this.props.fieldData ? this.props.fieldData['platform_country'] : []}
                                 isRequired
                                 options={this.props.countries}
                                 name={"platform_country"}
                                 placeholder={"Country of origin"}
                                 changeCallback={d => {
                                     this.props.updateOddsData(d, this.props.identifier);
                                 }}
                    />
                </SectionColumn>
                <SectionColumn>
                    <WebAddressField bold fontSize={14}
                                     capitalize
                                     defaultValue={this.props.fieldData && this.props.fieldData['platform_website']}
                                     name={"platform_website"}
                                     placeholder={"platform domain names"}
                                     changeCallback={d => {
                                         //d is the target returned as an argument by this component.
                                         // extract the name and value items
                                         this.props.updateOddsData(d, this.props.identifier);
                                     }}
                    />
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <legend>
                                        <span className={"inverse-highlighted-text"}>
                                          System certifications
                                        </span>
                        {/*<span className={"inverse-highlighted-text"}>*/}
                        {/*  {identifier}*/}
                        {/*</span>*/}
                    </legend>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <FileSelectField
                        fontSize={14}
                        capitalize
                        name={"system_certifications"}
                        style={{fontWeight: 400}}
                        placeholder={"Attach system certifications document"}
                        changeCallback={d => {
                            //d is the target returned as an argument by this component.
                            // extract the name and value items
                            this.props.updateOddsData(d, this.props.identifier)
                        }}
                    />
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn style={{borderBottom: '2px solid #800080'}}/>
            </SectionRow>
            {/*//second set of fields*/}
        </Section>
    }
}


/**
 *
 * Collect employee data
 *
 */
class OddsProviderSection extends GMS {
    constructor(props) {
        super(props);
        //callback methods
        /**
         * a colour dictionary that's used to mark the background of a given fieldset or a bar on the left of that fieldset...
         * @type {{red: string, orange: string, green: string, blue: string, grey: string}} hex codes for various shades of the colours
         *          listed.
         * Note: this object is no-longer useful on account of it being proposed for move to an alternate component specifically
         * designed to handle this extra data regarding to nesting of shareholders of a shareholding operator-registration.
         *
         */
        this.allowedDistinguishingColours = {
            grey: "#d3d3d8",
            green: "#D1E3C1FF",
            blue: "#B8CBF4FF",
            red: "#F4B8BEFF",
            orange: "#F4E8B8FF",
        };

        this.coloursInUse = []; //update this variable when a colour is picked from the list of allowedDistinguishingColours
        ///////////////////////////////////////
        ///////////////////////////////////////
        // get the outlet names
        this.oddsProviders = readFromLocalStorage(LOCAL_BACKUP, BCLB)['odds_provider'];
        this.blurFieldsFor = readFromLocalStorage('blur_fields', BCLB);
        this.storage = getStorageObject(BCLB);
        let shopNames = [];
        if (this.oddsProviders) {

            // loop through the oddsProviders and take within that object, the shop name "shop_name".
            // Also indicate the shop id as the current index count + 1
            let i = 0;
            do {
                const d = {};
                d[i + 1] = this.oddsProviders[i]['shop_name'];
                //console.log(d, this.oddsProviders[i], Object.getOwnPropertyNames(this.oddsProviders))
                shopNames.push(d);
                i += 1;
            } while (i < this.oddsProviders.length)
            this.oddsProviders = shopNames;
        }
    }

    /**
     *
     * This is a counter viewer. The viewer updates its numbers when some data
     * is detected inside state.dataItem1
     *
     * @returns {JSX.Element} a viewer consisting of shareholder data. those filled in
     *                          vs those that were selected.
     */
    oddsCounterView = () => {
        return (
            <div className={`${container} separate-contextual-viewer`}>
                <div className={row}>
                    <div className={`${col12} highlighted-text corner-numbers-viewer`}>
                        <div className={"space-row"}/>
                        <div className={row}>
                            <div className={col12}>
                                {
                                    Object.getOwnPropertyNames(this.storage.LOCAL_BACKUP).includes("odds_providers") ?
                                        (this.storage.LOCAL_BACKUP.odds_provider.length === 0 ? (0) :
                                            (<span>{this.storage.LOCAL_BACKUP.odds_provider.length}</span>)) :
                                        (0)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={row}>
                    <div
                        className={`${col12} inverse-highlighted-text inverse-corner-numbers-viewer`}
                    >
                        {this.state.dataItem2.length === 0 ? null : (
                            <span>{this.state.dataItem2.length}</span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    /**
     *
     * update state with necessary component data from this component's props
     * @param nextProps
     * @param nextContext
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {

        this.setState({
            e: nextProps.LOCAL_BACKUP,
            countries: nextProps.country,
            dataItem3: nextProps.applicable
        });
        //state updates but wont rerender
        //set misc
    }
    /**
     *
     * updates the maximum allowed employees available from the dataItem1 array of employee data
     * @param maxAllowed Integer representing the maximum number of employees required.
     *
     */
    updateMaximumAllowedEmployeeData = (
        maxAllowed = this.storage.LOCAL_BACKUP.odds_provider.length
    ) => {
        const fieldSetsToRemove = this.storage.LOCAL_BACKUP.odds_provider.length - maxAllowed;
        let shareholderItems = this.storage.LOCAL_BACKUP.odds_provider;
        this.setState((state) => {
            state.e.odds_provider = null;
            return state;
        }, () => {
            let y = 1;
            //pop items from shareholderItems
            while (y <= fieldSetsToRemove) {
                shareholderItems.pop();
                y += 1;
            }
            //there is nothing to remove if the maximum allowed is what should be removed.
            // By default,
            this.setState((state) => {
                state.e.odds_provider = shareholderItems; // new list of items after pops
                return state;
            });
        });
    };


    /**
     *
     * update shareholder data inside state.dataItem1.
     * @param oddsProviderFieldReference the reference to the input field from which to extract
     *                                   the data belonging to a shareholder
     *
     * @param belongingTo Integer representing which shareholder is being edited.
     *                      this Integer is an indexer (but it is not array-based) for
     *                      the JSON that is currently being updated.
     *
     */
    updateOddsData = async (oddsProviderFieldReference, belongingTo = 0) => {
        let oddsProviderField = {}; //this is just a pre-construction for new elements within the array
        // check if the current field name exists in state e operator_shareholders array elements for the belongingTo attribute
        oddsProviderField[oddsProviderFieldReference.target.name] = oddsProviderFieldReference.target.type === "checkbox"
            ? oddsProviderFieldReference.target.checked : oddsProviderFieldReference.target.type === 'file'
                ? oddsProviderFieldReference.target.files[0] : oddsProviderFieldReference.target.type === 'date' ?
                    formatDate(oddsProviderFieldReference.target.value, 'ymd', 'dmy', '-')
                    : oddsProviderFieldReference.target.value;
        if (oddsProviderFieldReference) {
            let oP = [];
            if (this.state.e) {
                if (this.state.e['odds_provider']) {
                    oP = this.state.e['odds_provider'];
                    try {
                        oP[belongingTo - 1][oddsProviderFieldReference.target.name] = oddsProviderField[oddsProviderFieldReference.target.name];
                    } catch (newRecordCountFound) {
                        oP.push(oddsProviderField);
                    }
                }
            } else {
                oP.push(oddsProviderField)
            }
            // update state then sort out the classes of shareholders
            // check the shareholder type. If this field is in the state.e object, update it else create one.
            // check if this is the first shareholder director

            // this is the first one
            this.setState(state => {
                if (state.e)
                    state.e['odds_provider'] = oP;
                else {

                }
                return state;
            }, () => this.props.bufferStateData && this.props.bufferStateData('odds_providers', this.state.e['odds_provider']));
        }

        if (this.state.e) {
            if (this.state.e['odds_provider'])
                if (belongingTo > this.state.e['odds_provider'].length) {
                    notify('You must start with the first odds provider when filling in their field data',
                        2,
                        false,
                        'Odds provider sequencing error!');
                    return false;
                }
        }
        // compile the data into operator_shareholders
        return true;
    };

    /**
     *
     * creates a fieldset of the shareholder's data collection fields.
     * @returns {JSX.Element} a bootstrap-styled row consisting of the fields in question.
     *
     */
    oddsProvidersFieldSet = (identifier = null, fieldData = {}, countries = this.state.dataItem1, background = "#FFFFFF") => {
        //look for a way to update data about fields dynamically.
        //these numbers should be

    }
    /**
     *
     * Create employee field sets based on selection of number of employees
     * @param rangeReference
     * @returns {*[]}
     *
     */
    createOddsProvidersFieldSets = (rangeReference = HTMLElement | Component | Number) => {
        let p = [];
        let x = 1;
        let numberOfFieldSets = 0;
        if (Object.getOwnPropertyNames(this.storage.LOCAL_BACKUP).includes('odds_provider'))
            numberOfFieldSets = this.storage.LOCAL_BACKUP['odds_provider'].length;
        else if (rangeReference instanceof Object)
            // window.alert(rangeReference)
            numberOfFieldSets = rangeReference.target.value;
        if (numberOfFieldSets > 0) {
            if (rangeReference instanceof Object) {
                let r = parseInt(rangeReference.target.value);
                if (r < numberOfFieldSets) // if it's a number
                {
                    notify('Minimum number of odds providers are already short-listed! ' +
                        'You cannot decrease the number unless you clear the whole list and short-list again',
                        3, true);
                    return false;
                } else
                    numberOfFieldSets = rangeReference instanceof Object ? rangeReference.target.value : rangeReference;
            }
        }
        do {
            // const fieldSet = this.oddsProvidersFieldSet(x, this.storage.LOCAL_BACKUP['odds_provider'][x - 1]);
            const fieldSet = <_ProviderFieldSet identifier={x}
                                                countries={this.storage.country}
                                                fieldData={this.storage.LOCAL_BACKUP['odds_provider'] ?
                                                    this.storage.LOCAL_BACKUP['odds_provider'][x - 1] : {}}
                                                updateOddsData={this.updateOddsData}/>;
            if (!p.includes(fieldSet)) p.push(fieldSet);
            x += 1;
        } while (x <= numberOfFieldSets)
        this.setState((state) => {
            state.dataItem2 = p;
            return state;
        });
        return p;
    }
    /**
     *
     * Data concerning where they are stationed/employed
     *
     */
    componentDidMount = () => {
        const d = this.storage.LOCAL_BACKUP;
        let x = 0;
        const e = Object.keys(this.storage.LOCAL_BACKUP);
        do {
            try {
                if (!NaN(parseInt(e[x])))
                    delete this.storage.LOCAL_BACKUP[e[x]];
            } catch (noTypeCasts) {
                // set state to current
            }
            x += 1;
        } while (x < e.length)

        this.setState({
            e: this.storage.LOCAL_BACKUP['odds_provider'] && this.storage.LOCAL_BACKUP['odds_provider'],
            dataItem1: this.storage.country,
            dataItem3: this.props.applicable
        });
        this.setState({
            // dataItem2: this.createOddsProvidersFieldSets(this.storage.LOCAL_BACKUP['odds_provider'] ?
            //     this.storage.LOCAL_BACKUP['odds_provider'].length : 0),
            dataItem4: this.oddsProviders ?
                this.oddsProviders.constructor.name === [].constructor.name ? this.oddsProviders : [] : []
        });
        Dispatch({
            type: SUBTITLE,
            payload: `Account opening process - declare odds providers ${this.blurFieldsFor &&
            this.blurFieldsFor.includes('odds') ?
                <i>does not apply</i> : ''}`
        });
        this.createOddsProvidersFieldSets();
    }
    /**
     *
     * A utility for
     *
     * @returns {JSX.Element}
     * @private
     */
    _oddsProvisionUI = () => {
        return <Section>
            <SectionRow>
                {/*<SectionColumn>*/}
                {/*        <span style={{color: '#800080', fontWeight: 700, fontSize: 14, textAlign: 'center'}}>*/}
                {/*            How many odds/platform provider do you have for declaration?</span>*/}
                {/*</SectionColumn>*/}
                <SectionColumn>
                    <NumberField
                        bold
                        fontSize={16}
                        capitalize
                        style={{color: '#800080', fontWeight: 500}}
                        defaultValue={Object.getOwnPropertyNames(this.storage.LOCAL_BACKUP).includes('odds_provider') ?
                            this.storage.LOCAL_BACKUP["odds_provider"].length : 1}
                        name={'platforms_number'}
                        placeholder={'List the number of odds/service providers'}
                        changeCallback={this.createOddsProvidersFieldSets}
                    />
                </SectionColumn>
                <SectionColumn>
                    {
                        this.state.e ?
                            this.state.e['odds_provider'] && this.state.e['odds_provider'].length > 0 &&
                            <span>
                                &ensp;
                                &ensp;
                                <br/>
                                <SubmitButton style={{background: 'rgba(231,30,30,0.82)'}}
                                              commandText={'Clear data'} callback={e => {
                                    // clear shareholder data in redux and state.e
                                    this.setState(state => {
                                        delete (state.e['odds_provider']);
                                        return state;
                                    }, () => {
                                        notify('Cleared existing declaration list of service and platform providers', 4, true);
                                        // this.createoddsProviderFieldSets();
                                    });
                                }}/>
                            </span>
                            : null

                    }
                </SectionColumn>
            </SectionRow>
            <SectionRow style={{padding: 24}} scrollable={'69vh'}>
                <SectionColumn>
                    {/*//odds providers fields*/}
                    {this.state.dataItem2}
                </SectionColumn>
            </SectionRow>
            {/*{this.employeeCounterView()}*/}
            {
                                    this.props.separateDeclaration &&
                                    <SectionRow>
                                        <SectionColumn/>
                                        <SectionColumn>
                                            <SubmitButton
                                                commandText={'Declare'}
                                                callback={() => {
                                                    let x = 0;
                                                    this.sendRequest(
                                                        "post",
                                                        `company/operator/odds-providers`,
                                                        // the identity of the operator-registration that was created
                                                        response => {
                                                            //console.log(response)
                                                            if (response.type === 'Success') {
                                                                notify(response.message, response.priority, true);
                                                                this.navigateTo('../list-odds-providers');
                                                                // null state in order to prevent backing up this data upon sending
                                                                let nullState = {};
                                                                this.setState({e: {...nullState}});
                                                                Dispatch({type: NULL_BACKUP});
                                                            } else {
                                                                // window.alert(x);
                                                                notify(response.message, response.priority, true);
                                                            }
                                                        },
                                                        this.state.e['operator_employees']
                                                    );
                                                }}
                                            />
                                        </SectionColumn>
                                        {this.state.currentRoute}
                                        <SectionColumn/>
                                    </SectionRow>
                                }
        </Section>
    }

    render = () => {
        // if (this.blurFieldsFor === undefined)
            return this._oddsProvisionUI();
        // else if (this.blurFieldsFor.includes('odds')) {
        //     // return a section with a picture
        //     return <>
        //         {this.state.currentRoute}
        //         <BlurTabContents executeAlternative={() => {
        //             // notify that prizes is unselectable
        //             notify('Odds providers does not apply based on the operations selected! Skipping...',
        //                 1,
        //                 true);
        //             window.setTimeout(() => {
        //                 this.navigateTo('../mapper', false)
        //             }, 1500)
        //         }}
        //         /></>
        //     // applicable must be null for this to work
        // } else return this.state.dataItem3 === null || this.state.dataItem3 === 1 ? this._oddsProvisionUI() :
        //     <BlurTabContents/>;
    }
}

/**
 *
 * Method is called when redux local-storage changes or updates.
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = ['country', 'LOCAL_BACKUP'];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
}
export default connect(mapStateToProps)(OddsProviderSection);