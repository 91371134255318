/**
 * app storage declaration, storage subscription etc etc
 */
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {BCLB} from "./allowed-actions";
import {bclbReducer} from "./reducer";
import {persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import {getStorageObject} from "../../../MiscUtils";
import {composeWithDevTools} from "redux-devtools-extension";

const persistConfig = {
    key: BCLB,
    storage,
    // transforms:[encryptTransform({secretKey:'sample key',onError:(err) => {//console.log('Error in transforming redux',err)}})]
}
export const storageMiddleWare = applyMiddleware(thunk);
const persistedReducer = persistReducer(persistConfig, bclbReducer)
//
/**
 *
 * Have a method for setting storage
 *
 */
const _setStorage = (state) => {
    const s = JSON.stringify(state);
    localStorage.setItem(BCLB, s);
}
/**
 *
 * Load initial state from local  storage
 * @returns {null|any}
 *
 */
// eslint-disable-next-line no-unused-vars
const _loadStorage = () => {
    let initialState = null;
    try {
        initialState = getStorageObject(BCLB);
    } catch (noStateSaved) {
        console.error(noStateSaved)
    }
    return initialState;
}

//
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// The method to act as the reducer, the initial state, the enhancer
//
// remember to comment out the line on importing composeWithDevTools at the top of this file
// export const AppStorage = createStore(persistedReducer, composeWithDevTools(storageMiddleWare)); // use this for development
export const AppStorage = createStore(persistedReducer, storageMiddleWare); // use this when building for production
export const AppPersistStorage = persistStore(AppStorage);
export const Dispatch = AppStorage.dispatch;
//look for ways of setting middleware
//construct
/**
 *
 * Subscription for the application to local storage.
 * This method will only be invoked when the local storage
 * changes.
 *
 * Note: This only works for the time that redux state changes.
 *
 */

AppStorage.subscribe(() => {
    _setStorage(AppStorage.getState());
});

// eslint-disable-next-line import/no-anonymous-default-export
