import React from "react";
import {connect} from "react-redux";
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import coatOfArms from '../../general-components/BCLB_icons/coat_of_arms.png';
import GMS from "../../parent-component/GMS";
import QRCode from "qrcode";
import bclbPartners from './receipt-images/partners-2.png';

/**
 *
 * Component to print data.
 *
 */

class PDFDocument extends GMS {
    constructor(props) {
        super(props);
        this.annotations = {
            'Bookmaking': 'BK',
            'Public Gaming': 'PG',
            'Public Lottery (Long Term)': 'PL',
            'Public Lottery (Short Term)': 'PL-S',
            'Totalisator': 'TL',
            'Pool Betting': 'PBT'
        }
        this.globalStyling = StyleSheet.create({
            crown: {
                width: 100,
                height: 100,
                margin: '0 auto'
            },
            qrCodePosition: {
                position: 'absolute',
                right: 0,
                // bottom:16,
                // border: '1px solid rgba(76,76,80,0.84)',
                // borderRadius: 3,
                height: 120,
                width: 160
            },
            issuanceDate: {
                fontSize: 10,
                marginLeft: 4,
            },
            emphasizedContent: {
                fontSize: 14,
                fontWeight: 800,
                color: 'rgba(39,39,40,0.84)'
            },
            documentContent: {
                fontSize: 12,
                fontWeight: 400,
                color: 'rgba(76,76,80,0.84)',
                lineHeight: 1.6,
                display: 'inline-block',
                textAlign: 'justify',
                overflow: 'break-word',

            },
            // ORIGINAL_documentNumber: {
            //     fontSize: 12,
            //     color: '#f60c0c',
            //     fontWeight: 1000,
            //     position: 'absolute',
            //     right: -10,
            //     top: 120,
            //     transform: 'rotate(-90deg)',
            // },
            documentNumber: {
                fontSize: 12,
                color: '#f60c0c',
                fontWeight: 1000,
                position: 'absolute',
                // right: -10,
                right: 10,
                // top: 36,
                top: 4,
                // transform: 'rotate(-90deg)',
            },
            documentTitle: {
                fontSize: 14,
                textAlign: 'center',
                padding: 8,
                fontWeight: 600

            },
            lineSeparatorView: {
                padding: 4,
                borderTop: '0.6pt solid #000000',
                marginTop: 14
            },
            superlativeText: {
                verticalAlign: 'super',
                fontSize: 8
            },
            letterHead: {
                fontSize: 16,
                fontWeight: 700,
                paddingTop: 8,
                // fontFamily:'google-sans-norton, Serif',
                // margin: '0 auto',
                textAlign: 'center',
                textTransform: 'uppercase'
            },
            letterHeadContacts: {
                fontSize: 10,
                fontWeight: 500,
                // fontFamily:'Helvitica',
                // margin: '0 auto',
                textAlign: 'center',
                lineHeight: 1.6,
                // textTransform:'uppercase'
            },
            signatureSection: {
                // border: '1px solid #e7ebee',
                width: 200,
                padding: 4,
                borderRadius: 2
                // height: 'fit-content'
            },
            signatureName: {
                textAlign: 'left',
                fontSize: 12
            },
            signatureBox: {
                border: '1px solid #747577',
                height: 60,
                marginTop: 4,
                borderRadius: 1
            },
            page: {
                // margin: '8px 8px',
                padding: 20,
                border: '1pt solid #7a7a7a',
                borderRadius: 2
            },
            transformed90: {
                transform: 'rotate(90deg)'
            }
        });
        // props.itemsToPDF['qr_code'] && this.qrCodeToSVGProcess();
    }

    /**
     *
     *
     * Allow the condition update of the state variable 'visible' in order to render the paymentInvoicePDF.
     * Any other state updates are ignored.
     * @param prevProps
     * @param prevState
     * @param snapshot
     * @returns {null}
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.context === 'invoice')
            this.qrCodeToSVGProcess().then(r => {
                if (this.state.variable === null)
                    this.setState({visible: this.paymentInvoicePDFView(), variable: true}, () => {
                    });
                else {
                    // console.log('state variable is not null. Not updating...')
                    return null
                }
                ;
            })
    }

    /**
     *
     * Method to convert a component into an svg object.
     *
     *
     * @returns {JSX.Element}
     * @param qrCode
     */
    qrCodeToSVGProcess = async (qrCode = this.state.dataItem4['qr_code']) => {
        if (this.state.dataItem2.constructor.name !== 'String')
            await QRCode.toDataURL(qrCode).then(qrSvg => {
                this.setState({dataItem2: qrSvg});
            });

    }
    componentDidMount = () => {
        if (this.props.context === 'invoice') {
            this.sendRequest('get',
                `company/operator/?company_name=${this.props.itemsToPDF['operator']}`,
                company => {
                    let types = [];
                    company.data[0].game_type.map((t, idx) => {
                        types.push(`${idx + 1}. ${this.gameType[t]}`);
                    });
                    this.setState({
                        dataItem1: company.data[0],
                        dataItem3: types.join('\n')
                    }, () => {
                        this.setState({visible: this.paymentInvoicePDFView()});
                    });
                }, {});
        }

    }

    invoiceLetterHead = (bclbReceiptNumber) => {
        const invoiceStyle = StyleSheet.create({
            crown: {
                // position: 'absolute',
                left: '36%',
                top: '-48%',
                width: 120,
                height: 120,
            },
        });
        return <View style={this.globalStyling.transformed90}>
            <View>
                <Image style={invoiceStyle.crown} src={coatOfArms}/>
            </View>
            <View>
                <Text style={{
                    color: this.props.itemsToPDF.status === true ? '#35A108FF' : '#f60c0c',
                    fontWeight: 800,
                    fontSize: 14,
                    left: '36%',
                }}>
                    {bclbReceiptNumber}
                </Text>
            </View>
        </View>
    }

    /**
     *
     * PDF letter head
     *
     * @returns {JSX.Element}
     */
    pdfLetterHead = (invoice = false) => {
        return <View>
            {!invoice && <Text>
                <Text>Original</Text>
                <Text style={{fontSize: 5.6,}}>&nbsp;&nbsp;NON-TRANSFERABLE</Text>
                <Text style={{
                    ...this.globalStyling.documentNumber,
                    transform: 'rotate(-0deg)',
                    top: 30,
                    left: 36,
                    color: '#000000',
                    fontWeight: 900
                }}>[{this.annotations[this.props.itemsToPDF.game_type]}]</Text>
            </Text>}
            <View style={this.globalStyling.crown}>
                <Image src={coatOfArms}/>
            </View>
            <View>
                <View>
                    <Text style={this.globalStyling.letterHead}>
                        The betting control and licensing board
                    </Text>
                </View>
                <View><Text>&ensp;</Text></View>
                <View>
                    <Text style={this.globalStyling.letterHeadContacts}>
                        ACK Garden Annex 7<Text style={this.globalStyling.superlativeText}>th</Text> Floor
                        1<Text style={this.globalStyling.superlativeText}>st</Text> Ngong Avenue
                    </Text>
                </View>
                <View>
                    <Text style={this.globalStyling.letterHeadContacts}>
                        Call +254-111021400 or +254-111021407
                    </Text>
                </View>
                <View>
                    <Text style={this.globalStyling.letterHeadContacts}>
                        Email info@bclb.go.ke
                    </Text>
                </View>
            </View>
            {
                invoice && this.props.itemsToPDF.issuance_number ?
                    <Text style={this.globalStyling.documentNumber}>
                        {
                            this.props.itemsToPDF.issuance_number
                        }
                    </Text> :
                    <Text style={this.globalStyling.documentNumber}>
                        {
                            this.props.itemsToPDF.billRefNumber
                        }
                    </Text>

            }
        </View>
    }
    /**
     *
     * Payment invoice printed in A5 paper size, landscape
     * @returns {JSX.Element}
     */
    /**
     *
     * Payment invoice printed in A5 paper size, landscape
     * @returns {JSX.Element}
     */
    paymentInvoicePDFView = (paid = this.state.dataItem4['status']) => {
        const tableStyle = StyleSheet.create({
            page: {
                padding: 20,
            },
            table: {
                display: 'block',
                width: '100%',
                margin: '0 auto',
                // border:'1px solid green'
                // borderStyle: 'solid',
                // borderWidth: 1,
            },
            tableRow: {
                display: 'flex',
                flexDirection: 'row',
            },
            tableCell: {
                // display: 'table-cell',
                padding: 5,
                width: '30% !important',
                border: '0.5px solid #919293',
                borderTop: 'none'
                // borderStyle: 'solid',
                // borderWidth: 1,
            },
            tableDataCell: {
                fontWeight: 400,
                padding: 5,
                width: '30% !important',
                border: '0.5px solid #919293',
                borderTop: 'none'
            },
            midCell: {
                width: '45%'
            },
            rightCell: {
                borderRight: 'none'
            },
            header: {
                backgroundColor: '#2e2f31',
                fontWeight: 'bold',
            },
        });
        const paymentInvoicePDFStyle = {
            crown: {
                width: 60,
                height: 60,
                margin: '0 auto'
            },
            documentFooter: {
                position: 'absolute',
                bottom: 0,
                width: '100%',
                marginTop: 20,
                height: 60,
                padding: 4
            },

            rowView: {
                display: 'flex',
                flexDirection: 'row',
                fontSize: 10,
                borderBottom: '1px solid #ebecee',
                borderTop: '1px solid #ebecee'
                //    #919293,#ebecee
            },
            textBox: {
                width: '30%',
                height: 48,
                margin: 10,
                padding: 4,
                textAlign: 'justify',
            },
            smallBoxTitle: {
                fontWeight: 400,
                fontSize: 8,
                textTransform: 'capitalize',
                textAlign: 'left',
                lineHeight: 1.5
            },
            smallBoxMainText: {
                fontWeight: 700,
                fontSize: 11,
                textTransform: 'uppercase',
                textAlign: 'center'
            },
            spacer: {
                height: 16
            },
            documentTitle: {
                textAlign: 'center',
                width: 'auto',
                color: '#FFFFFF',
                fontWeight: 800,
                fontSize: 12,
                // backgroundColor: '#292967',
                padding: 8
            },
            styleContent: {
                // top: 20,
                margin: 28,
                fontSize: 12,
                textAlign: 'justify'
                // height:'72%'
            },
            waterMark: {
                position: 'absolute',
                top: '48%',
                left: '16%',
                transform: 'translate(-50%, -50%) rotate(-45deg)', // A4
                // transform: 'translate(-50%, -50%) rotate(45deg)', // A5
                fontSize: 64,
                opacity: 0.2, // Adjust opacity as needed
                color: '#747577', // Adjust color as needed
                zIndex: -1, // Make sure watermark stays behind the content
            }
        };
        let totalPriceForServices = 0;
        return <Document>
            <Page
                size={'A4'}
                pageNumber={1}
                key={1}
                style={this.globalStyling.page}>
                {/*{this.pdfLetterHead(true)}*/}
                <View style={this.globalStyling.mainView}>
                    <View>
                        {/*// coat of arms*/}
                        <Image src={coatOfArms} style={paymentInvoicePDFStyle.crown}/>
                    </View>
                    <View>
                        <Text style={{...this.globalStyling.letterHead, fontWeight: 800, fontSize: 12}}>
                            The Republic of Kenya
                        </Text>
                    </View>
                    <View style={paymentInvoicePDFStyle.spacer}/>
                    <View style={paymentInvoicePDFStyle.rowView}>
                        <View style={paymentInvoicePDFStyle.textBox}>
                            <Text style={paymentInvoicePDFStyle.smallBoxTitle}>
                                BCLB reference number
                            </Text>
                            <Text style={paymentInvoicePDFStyle.smallBoxMainText}>
                                {this.state.dataItem4['billRefNumber']}
                            </Text>
                        </View>
                        <View style={paymentInvoicePDFStyle.textBox}>
                            <Text style={paymentInvoicePDFStyle.smallBoxTitle}>
                                payment {paid ? 'receipt' : 'invoice'}
                            </Text>
                            <Text style={paymentInvoicePDFStyle.smallBoxMainText}>
                                {this.state.dataItem4['invoice_number']}
                            </Text>
                        </View>
                        <View style={paymentInvoicePDFStyle.textBox}>
                            <Text style={paymentInvoicePDFStyle.smallBoxTitle}>
                                Issued on
                            </Text>
                            <Text style={paymentInvoicePDFStyle.smallBoxMainText}>
                                {this.state.dataItem4['created_date']}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View>
                            <View style={{width: '42%', textAlign: 'left', paddingTop: 8}}>
                                <View>
                                    <Text style={{
                                        fontWeight: 900,
                                        textTransform: 'uppercase',
                                        fontSize: 10,
                                        textAlign: 'left',
                                        paddingBottom: 8
                                    }}>
                                        betting control and licensing board
                                    </Text>
                                </View>
                                <View>
                                    <Text style={this.globalStyling.letterHeadContacts}>
                                        ACK Garden Annex 7<Text
                                        style={this.globalStyling.superlativeText}>th</Text> Floor
                                        1<Text style={this.globalStyling.superlativeText}>st</Text> Ngong Avenue
                                    </Text>
                                </View>
                                <View>
                                    <Text style={this.globalStyling.letterHeadContacts}>
                                        Call +254-111021400 / +254-111021407
                                    </Text>

                                </View>
                                <View>
                                    <Text style={this.globalStyling.letterHeadContacts}>
                                        Email info@bclb.go.ke
                                    </Text>

                                </View>
                            </View>
                        </View>
                        <View style={{width: '38vw', height: 10}}/>
                        <View style={{paddingTop: 4, display: 'flex', flexDirection: 'column'}}>
                            <Text style={this.globalStyling.letterHeadContacts}>OPERATOR COPY</Text>
                            <Image style={{width: 84, height: 84}}
                                   src={this.state.dataItem2}/>
                            {/*// QR scanner must be between 11.5 to 12cm from QR code, parpendicular*/}
                        </View>
                    </View>

                    {/*<View style={this.globalStyling.lineSeparatorView}/>*/}
                    <View style={{
                        // ...paymentInvoicePDFStyle.rowView,
                        ...paymentInvoicePDFStyle.documentTitle,
                        alignItems: 'center',
                        backgroundColor: paid ? '#2e9808' : '#292967',
                        // fontWeight: 600,

                    }}>
                        <Text>
                            {
                                paid ? 'RECEIPT' : `INVOICE`
                            }
                        </Text>
                    </View>
                    <View>
                        <View style={paymentInvoicePDFStyle.rowView}>
                            <View style={paymentInvoicePDFStyle.textBox}>
                                <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Company</Text>
                                <Text
                                    style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.company_name}</Text>
                            </View>
                            {
                                this.state.dataItem1.trade_name.length > 0 &&
                                <View style={paymentInvoicePDFStyle.textBox}>
                                    <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Company</Text>
                                    <Text
                                        style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.trade_name}</Text>
                                </View>
                            }
                            <View style={paymentInvoicePDFStyle.textBox}>
                                <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Reg:</Text>
                                <Text
                                    style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.local_company_reg_number}</Text>
                            </View>
                        </View>
                        <View style={paymentInvoicePDFStyle.rowView}>
                            <View style={paymentInvoicePDFStyle.textBox}>
                                <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Contact</Text>
                                <Text
                                    style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.operator_phone_number}</Text>
                            </View>
                            <View style={paymentInvoicePDFStyle.textBox}>
                                <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Operations</Text>
                                <Text style={{
                                    ...paymentInvoicePDFStyle.smallBoxMainText,
                                    fontSize: 8,
                                    textAlign: 'left'
                                }}>{this.state.dataItem3}</Text>
                            </View>
                        </View>

                    </View>
                    <View style={tableStyle.table}>
                        {
                            Object.getOwnPropertyNames(this.state.dataItem4).includes('payments') ?
                                <View>
                                    <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
                                          className={'table-header'}>
                                        <Text className={'table-cell-data'} style={tableStyle.tableCell}>Service</Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableCell, ...tableStyle.midCell}}>Description</Text>
                                        {/*<Text className={'table-cell-data'}*/}
                                        {/*      style={{...tableStyle.tableCell, ...tableStyle.midCell}}>Payment*/}
                                        {/*    status</Text>*/}
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>
                                            Charge amount
                                        </Text>
                                    </View>
                                    {
                                        this.state.dataItem4['payments'].map((payment, index) => {
                                            // find the payment first
                                            totalPriceForServices += payment.amountExpected;
                                            const realPayment = payment.amountExpected - 50;
                                            return <View>
                                                <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
                                                      className={'table-header'}>
                                                    <Text className={'table-cell-data'}
                                                          style={tableStyle.tableCell}>1</Text>
                                                    <Text className={'table-cell-data'}
                                                          style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>
                                                        Ecitizen service charge
                                                    </Text>
                                                    {/*<Text className={'table-cell-data'}*/}
                                                    {/*      style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>*/}
                                                    {/*    {this.state.dataItem4['payments'][0].status === true ? 'Paid' : 'invoiced'}*/}
                                                    {/*</Text>*/}
                                                    <Text className={'table-cell-data'}
                                                          style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>
                                                        {this.state.dataItem4['payments'][0].currency}. {this.state.dataItem4['payments'][0].amountExpected.slice(-1, this.state.dataItem4['payments'][0].amountExpected.length)}
                                                    </Text>
                                                </View>
                                                <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
                                                      className={'table-header'}>
                                                    <Text className={'table-cell-data'}
                                                          style={tableStyle.tableCell}>{index}</Text>
                                                    <Text className={'table-cell-data'}
                                                          style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>{payment.billDesc}</Text>
                                                    <Text className={'table-cell-data'}
                                                          style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>
                                                        {payment.status === true ? 'Paid' : 'invoiced'}
                                                    </Text>
                                                    <Text className={'table-cell-data'}
                                                          style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>
                                                        {realPayment}
                                                    </Text>
                                                </View>
                                            </View>
                                        })}
                                    <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 10}}>
                                        <Text className={'table-cell-data'} style={tableStyle.tableDataCell}>
                                            {this.state.dataItem4['serviceID']}
                                        </Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>
                                            Total
                                        </Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableDataCell, ...tableStyle.rightCell}}>
                                            {this.state.dataItem4['payments'][0]['currency']} {totalPriceForServices}
                                        </Text>
                                    </View>
                                </View> :
                                <View>
                                    <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
                                          className={'table-header'}>
                                        <Text className={'table-cell-data'} style={tableStyle.tableCell}>Service</Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableCell, ...tableStyle.midCell}}>Description</Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>Amount</Text>
                                    </View>
                                    <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 10}}>
                                        <Text className={'table-cell-data'}
                                              style={tableStyle.tableCell}>1</Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>Ecitizen
                                            service charge</Text>
                                        {/*<Text className={'table-cell-data'}*/}
                                        {/*      style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>*/}
                                        {/*    {this.state.dataItem4.status === true ? 'Paid' : 'invoiced'}*/}
                                        {/*</Text>*/}
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>
                                            {this.state.dataItem4.currency} {this.state.dataItem4.amountExpected.slice(-2, this.state.dataItem4.amountExpected.length)}
                                        </Text>
                                    </View>
                                    <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 10}}>
                                        <Text className={'table-cell-data'} style={tableStyle.tableDataCell}>
                                            {this.state.dataItem4['serviceID']}
                                        </Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>
                                            {this.state.dataItem4['billDesc']}
                                        </Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableDataCell, ...tableStyle.rightCell}}>
                                            {/*{this.state.dataItem4['currency']} {this.graduateValue(parseInt(this.state.dataItem4['amountExpected']) - 50)}*/}
                                            {this.state.dataItem4['currency']} {this.graduateNumeral(String(parseInt(this.state.dataItem4['amountExpected']) - 50))}
                                        </Text>
                                    </View>
                                    <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 10}}>
                                        <Text className={'table-cell-data'} style={tableStyle.tableDataCell}>
                                            {/*{this.state.dataItem4['serviceID']}*/}
                                        </Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>
                                            Total
                                        </Text>
                                        <Text className={'table-cell-data'}
                                              style={{...tableStyle.tableDataCell, ...tableStyle.rightCell}}>
                                            {/*{this.state.dataItem4['currency']} {this.graduateValue(this.state.dataItem4['amountExpected'])}*/}
                                            {this.state.dataItem4['currency']} {this.graduateNumeral(String(this.state.dataItem4['amountExpected']))}
                                        </Text>
                                    </View>
                                </View>
                        }
                    </View>
                    {/*// put some space here*/}
                    {/*<View>*/}
                    {/*    <Text style={{marginTop: 4, fontSize: 7, textAlign: 'right', textTransform: 'italic'}}>*/}
                    {/*        Note this fee is inclusive of an eCitizen platform access charge of Ksh. 50*/}
                    {/*    </Text>*/}
                    {/*</View>*/}
                    <View style={paymentInvoicePDFStyle.spacer}/>
                    <View>
                        {/*// signature box if*/}
                        {
                            paid && <View style={this.globalStyling.signatureSection}>
                                <View style={this.globalStyling.signatureBox}/>
                                <Text style={this.globalStyling.superlativeText}>
                                    <View>
                                        <Text>
                                            &nbsp;
                                        </Text>
                                    </View>
                                    F/O signature and stamp
                                </Text>
                            </View>
                        }
                    </View>

                    <View style={paymentInvoicePDFStyle.spacer}/>
                    <View>
                        <View>
                            <Text style={{marginTop: 4, fontSize: 6, textTransform: 'italic'}}>
                                This document is generated by GMS. Visit www.bclb.go.ke and operator.bclb.go.ke for
                                more.
                            </Text>
                        </View>
                    </View>
                    <View style={{...paymentInvoicePDFStyle.documentFooter, borderTop: '1px solid #c2bdf1'}}>
                        <View>
                            <Image src={bclbPartners}/>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    }
    // /**
    //  *
    //  *
    //  *
    //  * @returns {JSX.Element}
    //  */
    // paymentInvoicePDFView = (paid = this.props.itemsToPDF['status']) => {
    //     const tableStyle = StyleSheet.create({
    //         page: {
    //             padding: 20,
    //         },
    //         table: {
    //             display: 'block',
    //             width: '100%',
    //             margin: '0 auto',
    //             // border:'1px solid green'
    //             // borderStyle: 'solid',
    //             // borderWidth: 1,
    //         },
    //         tableRow: {
    //             display: 'flex',
    //             flexDirection: 'row',
    //         },
    //         tableCell: {
    //             // display: 'table-cell',
    //             padding: 5,
    //             width: '30% !important',
    //             border: '0.5px solid #919293',
    //             borderTop: 'none'
    //             // borderStyle: 'solid',
    //             // borderWidth: 1,
    //         },
    //         tableDataCell: {
    //             fontWeight: 400,
    //             padding: 5,
    //             width: '30% !important',
    //             border: '0.5px solid #919293',
    //             borderTop: 'none'
    //         },
    //         midCell: {
    //             width: '45%'
    //         },
    //         rightCell: {
    //             borderRight: 'none'
    //         },
    //         header: {
    //             backgroundColor: '#2e2f31',
    //             fontWeight: 'bold',
    //         },
    //     });
    //     const paymentInvoicePDFStyle = {
    //         crown: {
    //             width: 60,
    //             height: 60,
    //             margin: '0 auto'
    //         },
    //         documentFooter: {
    //             position: 'absolute',
    //             bottom: 0,
    //             width: '100%',
    //             marginTop: 20,
    //             height: 60,
    //             padding: 4
    //         },
    //
    //         rowView: {
    //             display: 'flex',
    //             flexDirection: 'row',
    //             fontSize: 10,
    //             borderBottom: '1px solid #ebecee',
    //             borderTop: '1px solid #ebecee'
    //             //    #919293,#ebecee
    //         },
    //         textBox: {
    //             width: '30%',
    //             height: 48,
    //             margin: 10,
    //             padding: 4,
    //             textAlign: 'justify',
    //         },
    //         smallBoxTitle: {
    //             fontWeight: 400,
    //             fontSize: 8,
    //             textTransform: 'capitalize',
    //             textAlign: 'left',
    //             lineHeight: 1.5
    //         },
    //         smallBoxMainText: {
    //             fontWeight: 700,
    //             fontSize: 11,
    //             textTransform: 'uppercase',
    //             textAlign: 'center'
    //         },
    //         spacer: {
    //             height: 16
    //         },
    //         documentTitle: {
    //             textAlign: 'center',
    //             width: 'auto',
    //             color: '#FFFFFF',
    //             fontWeight: 800,
    //             fontSize: 12,
    //             // backgroundColor: '#292967',
    //             padding: 8
    //         },
    //         styleContent: {
    //             // top: 20,
    //             margin: 28,
    //             fontSize: 12,
    //             textAlign: 'justify'
    //             // height:'72%'
    //         },
    //         waterMark: {
    //             position: 'absolute',
    //             top: '48%',
    //             left: '16%',
    //             transform: 'translate(-50%, -50%) rotate(-45deg)', // A4
    //             // transform: 'translate(-50%, -50%) rotate(45deg)', // A5
    //             fontSize: 64,
    //             opacity: 0.2, // Adjust opacity as needed
    //             color: '#747577', // Adjust color as needed
    //             zIndex: -1, // Make sure watermark stays behind the content
    //         }
    //     };
    //
    //     return <Document>
    //         <Page
    //             size={'A4'}
    //             pageNumber={1}
    //             key={1}
    //             style={this.globalStyling.page}>
    //             {/*{this.pdfLetterHead(true)}*/}
    //             <View style={this.globalStyling.mainView}>
    //                 <View>
    //                     {/*// coat of arms*/}
    //                     <Image src={coatOfArms} style={paymentInvoicePDFStyle.crown}/>
    //                 </View>
    //                 <View>
    //                     <Text style={{...this.globalStyling.letterHead, fontWeight: 800, fontSize: 12}}>
    //                         The Republic of Kenya
    //                     </Text>
    //                 </View>
    //                 <View style={paymentInvoicePDFStyle.spacer}/>
    //                 <View style={paymentInvoicePDFStyle.rowView}>
    //                     <View style={paymentInvoicePDFStyle.textBox}>
    //                         <Text style={paymentInvoicePDFStyle.smallBoxTitle}>
    //                             BCLB reference number
    //                         </Text>
    //                         <Text style={paymentInvoicePDFStyle.smallBoxMainText}>
    //                             {this.props.itemsToPDF['billRefNumber']}
    //                         </Text>
    //                     </View>
    //                     <View style={paymentInvoicePDFStyle.textBox}>
    //                         <Text style={paymentInvoicePDFStyle.smallBoxTitle}>
    //                             payment {paid ? 'receipt': 'invoice'}
    //                         </Text>
    //                         <Text style={paymentInvoicePDFStyle.smallBoxMainText}>
    //                             {this.props.itemsToPDF['invoice_number']}
    //                         </Text>
    //                     </View>
    //                     <View style={paymentInvoicePDFStyle.textBox}>
    //                         <Text style={paymentInvoicePDFStyle.smallBoxTitle}>
    //                             Issued on
    //                         </Text>
    //                         <Text style={paymentInvoicePDFStyle.smallBoxMainText}>
    //                             {this.props.itemsToPDF['created_date']}
    //                         </Text>
    //                     </View>
    //                 </View>
    //                 <View style={{display: 'flex', flexDirection: 'row'}}>
    //                     <View>
    //                         <View style={{width: '42%', textAlign: 'left', paddingTop: 8}}>
    //                             <View>
    //                                 <Text style={{
    //                                     fontWeight: 900,
    //                                     textTransform: 'uppercase',
    //                                     fontSize: 10,
    //                                     textAlign: 'left',
    //                                     paddingBottom: 8
    //                                 }}>
    //                                     betting control and licensing board
    //                                 </Text>
    //                             </View>
    //                             <View>
    //                                 <Text style={this.globalStyling.letterHeadContacts}>
    //                                     ACK Garden Annex 7<Text
    //                                     style={this.globalStyling.superlativeText}>th</Text> Floor
    //                                     1<Text style={this.globalStyling.superlativeText}>st</Text> Ngong Avenue
    //                                 </Text>
    //                             </View>
    //                             <View>
    //                                 <Text style={this.globalStyling.letterHeadContacts}>
    //                                     Call +254-111021400 / +254-111021407
    //                                 </Text>
    //
    //                             </View>
    //                             <View>
    //                                 <Text style={this.globalStyling.letterHeadContacts}>
    //                                     Email info@bclb.go.ke
    //                                 </Text>
    //
    //                             </View>
    //                         </View>
    //                     </View>
    //                     <View style={{width: '38vw', height: 10}}/>
    //                     <View style={{paddingTop: 4, display: 'flex', flexDirection: 'column'}}>
    //                         <Text style={this.globalStyling.letterHeadContacts}>OPERATOR COPY</Text>
    //                         <Image style={{width: 84, height: 84}}
    //                                src={this.state.dataItem2}/>
    //                         {/*// QR scanner must be between 11.5 to 12cm from QR code, parpendicular*/}
    //                     </View>
    //                 </View>
    //                 {/*<View style={this.globalStyling.lineSeparatorView}/>*/}
    //                 <View style={{
    //                     // ...paymentInvoicePDFStyle.rowView,
    //                     ...paymentInvoicePDFStyle.documentTitle,
    //                     alignItems: 'center',
    //                     backgroundColor: paid ? '#2e9808' : '#292967',
    //                     // fontWeight: 600,
    //
    //                 }}>
    //                     <Text>
    //                         {
    //                             paid ? 'GOVERNMENT RECEIPT' : `INVOICE`
    //                         }
    //                     </Text>
    //                 </View>
    //                 <View>
    //                     <View style={paymentInvoicePDFStyle.rowView}>
    //                         <View style={paymentInvoicePDFStyle.textBox}>
    //                             <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Company</Text>
    //                             <Text
    //                                 style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.company_name}</Text>
    //                         </View>
    //                         <View style={paymentInvoicePDFStyle.textBox}>
    //                             <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Trading as</Text>
    //                             <Text
    //                                 style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.trade_name}</Text>
    //                         </View>
    //                         <View style={paymentInvoicePDFStyle.textBox}>
    //                             <Text style={paymentInvoicePDFStyle.smallBoxTitle}>REG:</Text>
    //                             <Text
    //                                 style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.local_company_reg_number}</Text>
    //                         </View>
    //                         <View style={paymentInvoicePDFStyle.textBox}>
    //                             <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Contact</Text>
    //                             <Text
    //                                 style={paymentInvoicePDFStyle.smallBoxMainText}>{this.state.dataItem1.operator_phone_number}</Text>
    //                         </View>
    //                         <View style={paymentInvoicePDFStyle.textBox}>
    //                             <Text style={paymentInvoicePDFStyle.smallBoxTitle}>Operation</Text>
    //                             <Text style={{
    //                                 ...paymentInvoicePDFStyle.smallBoxMainText,
    //                                 fontSize: 8,
    //                                 textAlign: 'center'
    //                             }}>{this.state.dataItem3}</Text>
    //                         </View>
    //                     </View>
    //
    //                 </View>
    //                 <View style={tableStyle.table}>
    //                     {
    //                         this.props.itemsToPDF['payments'] ? <View>
    //                                 <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
    //                                       className={'table-header'}>
    //                                     <Text className={'table-cell-data'} style={tableStyle.tableCell}>Service</Text>
    //                                     <Text className={'table-cell-data'}
    //                                           style={{...tableStyle.tableCell, ...tableStyle.midCell}}>Description</Text>
    //                                     <Text className={'table-cell-data'}
    //                                           style={{...tableStyle.tableCell, ...tableStyle.midCell}}>Payment status</Text>
    //                                     <Text className={'table-cell-data'}
    //                                           style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>Charge
    //                                         amount</Text>
    //                                 </View>
    //                                 <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
    //                                                      className={'table-header'}>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={tableStyle.tableCell}>1</Text>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>Ecitizen service charge</Text>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>{this.props.itemsToPDF['payments'][0].status === true ? 'Paid' : 'invoiced'}</Text>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>{this.props.itemsToPDF['payments'][0].currency}. {this.props.itemsToPDF['payments'][0].amountExpected.slice(-1,-2)}</Text>
    //                                         </View>
    //                                 {
    //                                     this.props.itemsToPDF['payments'].map((payment, index) => {
    //                                         return <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
    //                                                      className={'table-header'}>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={tableStyle.tableCell}>{index}</Text>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>{payment.billDesc}</Text>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>{payment.status === true ? 'Paid' : 'invoiced'}</Text>
    //                                             <Text className={'table-cell-data'}
    //                                                   style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>{payment.amountExpected}</Text>
    //                                         </View>
    //                                     })}
    //                             </View> :
    //                             <View>
    //
    //                                 <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 14}}
    //                                       className={'table-header'}>
    //                                     <Text className={'table-cell-data'} style={tableStyle.tableCell}>Service</Text>
    //                                     <Text className={'table-cell-data'}
    //                                           style={{...tableStyle.tableCell, ...tableStyle.midCell}}>Description</Text>
    //                                     <Text className={'table-cell-data'}
    //                                           style={{...tableStyle.tableCell, ...tableStyle.rightCell}}>Charge
    //                                         amount</Text>
    //                                 </View>
    //                             </View>
    //                     }
    //
    //                     <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 10}}
    //                           className={'table-header'}>
    //                         <Text className={'table-cell-data'}
    //                               style={tableStyle.tableDataCell}>1</Text>
    //                         <Text className={'table-cell-data'}
    //                               style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>Ecitizen service charge</Text>
    //                         <Text className={'table-cell-data'}
    //                               style={{...tableStyle.tableDataCell, ...tableStyle.rightCell}}>{this.props.itemsToPDF['currency']}. {this.props.itemsToPDF['amountExpected'].slice(-1,-2)}</Text>
    //                     </View>
    //                     <View style={{...tableStyle.tableRow, fontWeight: 800, fontSize: 10}}
    //                           className={'table-header'}>
    //                         <Text className={'table-cell-data'}
    //                               style={tableStyle.tableDataCell}>
    //                             {this.props.itemsToPDF['serviceID']}
    //                         </Text>
    //                         <Text className={'table-cell-data'}
    //                               style={{...tableStyle.tableDataCell, ...tableStyle.midCell}}>{this.props.itemsToPDF['billDesc']}
    //                         </Text>
    //                         <Text className={'table-cell-data'}
    //                               style={{...tableStyle.tableDataCell, ...tableStyle.rightCell}}>
    //                             {this.props.itemsToPDF['currency']}. {this.graduateNumeral(this.props.itemsToPDF['amountExpected'])}
    //                         </Text>
    //                     </View>
    //
    //                 </View>
    //                 {/*// put some space here*/}
    //                 {/*<View>*/}
    //                 {/*    <Text style={{marginTop: 4, fontSize: 7, textAlign: 'right', textTransform: 'italic'}}>*/}
    //                 {/*        Note this fee is inclusive of an eCitizen platform access charge of Ksh. 50*/}
    //                 {/*    </Text>*/}
    //                 {/*</View>*/}
    //                 <View style={paymentInvoicePDFStyle.spacer}/>
    //                 <View>
    //                     {/*// signature box if*/}
    //                     {
    //                         paid && <View style={this.globalStyling.signatureSection}>
    //                             <View style={this.globalStyling.signatureBox}/>
    //                             <Text style={this.globalStyling.superlativeText}>
    //                                 <View>
    //                                     <Text>
    //                                         &nbsp;
    //                                     </Text>
    //                                 </View>
    //                                 F/O signature and stamp
    //                             </Text>
    //                         </View>
    //                     }
    //                 </View>
    //
    //                 <View style={paymentInvoicePDFStyle.spacer}/>
    //                 <View>
    //                     <View>
    //                         <Text style={{marginTop: 4, fontSize: 6, textTransform: 'italic'}}>
    //                             This document is generated by GMS. Visit www.bclb.go.ke and operator.bclb.go.ke for
    //                             more.
    //                         </Text>
    //                     </View>
    //                 </View>
    //                 <View style={{...paymentInvoicePDFStyle.documentFooter, borderTop: '1px solid #c2bdf1'}}>
    //                     <View>
    //                         <Image src={bclbPartners}/>
    //                     </View>
    //                 </View>
    //             </View>
    //         </Page>
    //     </Document>
    // }
    /**
     *
     * Draw a license or permit view. Data available for this is:
     *        id: 92
     *        application_status: "Application"
     *        progress: "draft"
     *        county_business: "Mombasa"
     *        jurisdiction: "Mombasa"
     *        status: "draft"
     *        game_type: "Bookmaking"
     *        operator: "irenes company"
     *        application_type: "License"
     *        operator_individual_shareholders: operator_company_shareholders:
     *        operator_name: "irenes company"
     *        next_payment: "Application fee"
     *        license_or_permit_name: "BCLB-f70d68ec-f58d-11ee-9e6b-0242ac120004"
     *        expiry_date: "08-04-2024"
     *        application_fee: 0
     *        is_extended: false
     *        issuance_date: "08-04-2024"
     *        campaign_type: ""
     *        promotion_type: ""
     *        extension_number: 0
     *        description: "dcfcdfdffgvfgbfbggfbbgfbgfbg"
     *        issuance_number: ""
     *        is_deleted: false
     *
     * @returns {JSX.Element}
     */


    render = () => {
        return <PDFViewer width={1496} height={840}>
            {this.state.visible}
        </PDFViewer>;
    }
}

const stateToProps = initialState => {
    return {
        role: initialState.role,
        context: initialState['task_object'],
        itemsToPDF: initialState['misc_id']
    };
}

export default connect(stateToProps)(PDFDocument);