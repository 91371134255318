import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import TextAreaField from "../../general-components/input-field/TextAreaField";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import {BCLB, PREV_ROUTE, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {Dispatch} from "../../general-components/redux/app-storage";
import TextField from "../../general-components/input-field/TextField";
import {notify} from "../../../MiscUtils";

/**
 *
 * Have a UI for creating issues. These issues are like a ticket launch
 *
 */
export default class CreateNewIssue extends GMS {
    constructor() {
        super();
        this.storageData = JSON.parse(localStorage.getItem(BCLB));
        Dispatch({type: PREV_ROUTE, payload: 'raise-issue'});
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'raise a new issue'});
    }

    render = () => {

        return <Section>
            <SectionTitle>
                raise a new issue
            </SectionTitle>
            <SectionRow>
                <SectionColumn style={{width: '9vw'}}/>
                <SectionColumn style={{width: '51vw'}}>
                    <TextField fontSize={14}
                               bold
                               capitalize
                               name={'title'}
                               placeholder={'title of issue'}
                               changeCallback={this.collectFieldData}
                    />
                </SectionColumn>
                {/*<SectionColumn style={{width: '19vw'}}/>*/}
                {/*<SectionColumn style={{width: '20vw'}}>*/}
                {/*    <SelectField bold fontSize={14}*/}
                {/*                 capitalize*/}
                {/*                 defultValue={Object.getOwnPropertyNames(this.storageData.LOCAL_BACKUP).length > 0 && this.storageData.LOCAL_BACKUP['connected_to']}*/}
                {/*                 name={'connected_to'}*/}
                {/*                 options={[]}*/}
                {/*                 placeholder={'concerned with'}*/}
                {/*                 changeCallback={this.collectFieldData}*/}
                {/*    />*/}
                {/*</SectionColumn>*/}
            </SectionRow>
            <SectionRow>
                <SectionColumn style={{width: '9vw'}}/>
                <SectionColumn style={{width: '20vw'}}>
                    <TextAreaField
                        isRequired
                        noResize
                        bold
                        capitalize
                        fontSize={14}
                        style={{width: '50vw', height: '26vh'}}
                        defaultValue={Object.getOwnPropertyNames(this.storageData.LOCAL_BACKUP).length > 0 &&
                        this.storageData.LOCAL_BACKUP['issue_description']}
                        name={'description'}
                        placeholder={'description of the issue'}
                        changeCallback={this.collectFieldData}
                    />
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn/>
            </SectionRow>
            {/*// this is used for spacing out the items presented here*/}
            {/*<SectionRow/>*/}
            {/*<SectionRow>*/}
            {/*    <SectionColumn style={{width: '19vw'}}/>*/}
            {/*    <SectionColumn style={{width: '20vw'}}>*/}
            {/*        <SimpleLabel text={'Are there any document attachments?'}/>*/}
            {/*        <FileSelectFieldIcon bold fontSize={14} name={'documents'} placeholder={'attach document'}*/}
            {/*                             callback={(e) => {*/}
            {/*                                 this.setState(state => {*/}
            {/*                                     state.e['attachments'] = e.target.files[0];*/}
            {/*                                     return state;*/}
            {/*                                 });*/}
            {/*                             }}/>*/}
            {/*    </SectionColumn>*/}
            {/*</SectionRow>*/}
            <SectionRow>
                {/*// button to create issue*/}
                <SectionColumn style={{width: '19vw'}}/>
                <SectionColumn style={{width: '20vw'}}>
                    <SubmitButton commandText={'create'}
                                  callback={() => {
                                      if (this.state.e['description'])
                                          this.sendRequest('post',
                                              'helpdesk/tickets',
                                              response => {
                                                  if (response.data)
                                                      notify('New ticket/issue has been created', 4, true);
                                                  else
                                                      notify('Error in creating issue', 2, true);
                                              })
                                  }}
                    />
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}