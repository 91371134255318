import React from "react";
import {connect} from "react-redux";
import GMS from "../../../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";

/**
 *
 * Class is used to view employee
 *
 */
class EmployeeView extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.setState({dataItem1: this.props.viewable_item});
    }
    render = () => {
        return <Section>
            <SectionTitle>
                View employee
            </SectionTitle>
            {/*<SectionRow>*/}
            {/*    <SectionColumn>*/}
            {/*        <SelectField name={'actions'}*/}
            {/*                     bold*/}
            {/*                     fontSize={14}*/}
            {/*                     placeholder={`Options for ${this.props.employee.employee_name}`}*/}
            {/*                     options={[{1: 'mark as dismissed'},{2: 'reinstate'}]}*/}
            {/*                     changeCallback={e => {*/}
            {/*                         // PUT for employees that you have fired them.*/}
            {/*                     }}*/}
            {/*        />*/}
            {/*    </SectionColumn>*/}
            {/*    <SectionColumn />*/}
            {/*</SectionRow>*/}
            <SectionRow>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'Name'} withColon
                                      fontSize={14} bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={this.props.employee.employee_name}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'ID/Passport/work permit'} withColon
                                      fontSize={14} bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={this.props.employee.national_id}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'Nationality'}
                                      withColon
                                      fontSize={14}
                                      bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={this.props.employee.nationality}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'Contacts'} withColon
                                      fontSize={14} bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={`${this.props.employee.contact}, ${this.props.employee.email}`}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'Tax pin'} withColon
                                      fontSize={14} bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={this.props.employee.tax_pin}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'Contact person?'}
                                      withColon
                                      fontSize={14}
                                      bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={this.props.employee.contact_person}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'Declaration date'}
                                      withColon
                                      fontSize={14}
                                      bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={this.props.employee.declaration_date}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
                <SectionColumn>
                     <span>
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={'Stationed at'}
                                      withColon
                                      fontSize={14}
                                      bold
                                      capitalize
                         />
                         <SimpleLabel colour={'#1e1b1e'}
                                      text={this.props.employee.station.length > 0 ? this.props.employee.station : <i>Not in any shop/premise!</i>}
                                      fontSize={14}
                         />
                     </span>
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>
        </Section>
    }
}

const stateToProps = initialState => {
    let p = {};
    p['employee'] = initialState.viewable_item;
    return p;
}
export default connect(stateToProps)(EmployeeView);