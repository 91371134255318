import GMS from "../../../../parent-component/GMS";
import {arrayOfJSONsToJSON, extractValueFromJSON, formatDate, readFromLocalStorage} from '../../../../../MiscUtils'
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";
import {BCLB} from "../../../../general-components/redux/allowed-actions";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import React from "react";
import DocumentDetailsCard from "../../../../general-components/document-viewer/DocumentDetailsCard";


/**
 *
 * Show shareholderList information
 *  Admit sh_first_name, sh_last_name, sh_surname, sh_gender, sh_individual_number_of_shares, sh_status
 */
export default class ListOfShareholders extends GMS {
    constructor(props) {
        super(props);
        //console.log('check for documents ', props.shareholderList)
        // props consists of a list of shareholderLists. invent rows capturing each shareholderList
        // gain access to local storage
        this.gender = readFromLocalStorage('gender', BCLB);
        this.shareType = readFromLocalStorage('shareholder_type', BCLB);
        this.countries = readFromLocalStorage('country', BCLB);
        // if this is separateView, replace props.['shareholders_list'] with data from
        // the shareholders list endpoint
    }

    shareholderListView = (rowData = this.props['shareholderList']) => {
        let shareholderLists = [];
        let y = 0;
        if (rowData !== undefined) {
            let companyDocuments = [];
            let individualDocuments = [];
            if (rowData.length > 0)
                do {
                    const datum = rowData[y];
                    // for documents, use the ***_file field
                    // if(rowData[y][''])
                    // company docs
                    if (rowData[y]['company_kra_doc'])
                        companyDocuments.push(rowData[y]['company_kra_doc']);
                    if (rowData[y]['company_notarized_sh_doc'])
                        companyDocuments.push(rowData[y]['company_notarized_sh_doc']);
                    // individual documents

                    // if (rowData[y]['sh_cogc_file'] || rowData[y]['shareholder_cogc_file_url'])
                    //     rowData[y]['shareholder_cogc_file_url'] ? individualDocuments.push(rowData[y]['shareholder_cogc_file_url']) : individualDocuments.push(rowData[y]['sh_cogc_file']);
                    // if (rowData[y]['sh_kra_doc'] || rowData[y]['shareholder_kra_file_url'])
                    //     rowData[y]['shareholder_kra_file_url'] ? individualDocuments.push(rowData[y]['shareholder_kra_file_url']) : individualDocuments.push(rowData[y]['sh_kra_doc']);
                    // if (rowData[y]['sh_passport_doc'] || rowData[y]['shareholder_id_file_url'])
                    //     rowData[y]['shareholder_id_file_url'] ? individualDocuments.push(rowData[y]['shareholder_id_file_url']) : individualDocuments.push(rowData[y]['sh_passport_doc']);
                    // if (rowData[y]['shareholder_kra_tcc_file_url'])
                    //     individualDocuments.push(rowData[y]['shareholder_kra_tcc_file_url']);
                    if (rowData[y]['sh_cogc_file'])
                        individualDocuments.push(rowData[y]['sh_cogc_file']);
                    if (rowData[y]['sh_kra_doc'] || rowData[y]['shareholder_kra_file'])
                        rowData[y]['shareholder_kra_file'] ? individualDocuments.push(rowData[y]['shareholder_kra_file']) : individualDocuments.push(rowData[y]['sh_kra_doc']);
                    if (rowData[y]['sh_passport_doc'] || rowData[y]['shareholder_id_file'])
                        rowData[y]['shareholder_id_file'] ? individualDocuments.push(rowData[y]['shareholder_id_file']) : individualDocuments.push(rowData[y]['sh_passport_doc']);
                    if (rowData[y]['shareholder_kra_tcc_file'])
                        individualDocuments.push(rowData[y]['shareholder_kra_tcc_file']);

                    if (Object.getOwnPropertyNames(datum).includes('sh_company_name')) {
                        shareholderLists.push(<SectionColumn
                            style={{
                                background: 'rgba(201,241,182,0.47)',
                                borderBottom: '1px solid #C8B5BAFF',
                                marginBottom: 4
                            }}>
                            <SectionRow>
                                <SectionColumn>
                                   <span>
                                        <SimpleLabel colour={'#1e1b1e'} text={'Company name'} withColon fontSize={14}
                                                     bold
                                                     capitalize/>
                                    <SimpleLabel colour={'#1e1b1e'} text={datum['sh_company_name']} fontSize={14}/>
                                   </span>
                                </SectionColumn>
                                <SectionColumn>
                                   <span>
                                        <SimpleLabel colour={'#1e1b1e'} text={'Shareholder type'} withColon
                                                     fontSize={14} bold
                                                     capitalize/>
                                    <SimpleLabel colour={'#1e1b1e'}
                                                 text={datum['sh_company_name'] ? 'company' : 'individual'}
                                                 fontSize={14}/>
                                   </span>
                                </SectionColumn>
                                <SectionColumn>
                                    <span>
                                        <SimpleLabel colour={'#1e1b1e'} text={'Registration number'} withColon
                                                     fontSize={14} bold
                                                     capitalize/>
                                    <SimpleLabel colour={'#1e1b1e'} text={datum['sh_company_reg_no']}
                                                 fontSize={14}/>
                                    </span>
                                </SectionColumn>
                                <SectionColumn>
                                    <span>
                                        <SimpleLabel colour={'#1e1b1e'} text={'shares'} withColon fontSize={14} bold
                                                     capitalize/>
                                    <SimpleLabel colour={'#1e1b1e'}
                                                 text={datum['sh_company_number_of_shares'] ?
                                                     `${datum['sh_company_number_of_shares']} - [${datum['sh_company_type'] ? datum['sh_company_type'] :
                                                         extractValueFromJSON(datum['sh_type'], arrayOfJSONsToJSON(this.shareType))}]` : `${datum['sh_company_number_of_shares']}
                                                          (${datum['sh_company_type']})`}
                                                 fontSize={14}/>
                                    </span>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <span>
                                        <SimpleLabel colour={'#1e1b1e'} text={'Start date'} withColon fontSize={14}
                                                     bold
                                                     capitalize/>
                                    <SimpleLabel colour={'#1e1b1e'} text={datum['sh_shareholding_start'] &&
                                    formatDate(datum['sh_shareholding_start'].split('T')[0],
                                        'dmy',
                                        'dmy')
                                    }
                                                 fontSize={14}/>
                                    </span>
                                </SectionColumn>
                                {
                                    datum['sh_company_nationality'] && <SectionColumn style={{
                                        borderRight: datum['sh_nationality'] && datum['sh_nationality'].toLowerCase() !== 'kenya' ?
                                            '3px solid rgba(195,252,158,0.46)' : null
                                    }}>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'Nationality'} withColon fontSize={14}
                                                         bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'} text={
                                            datum['sh_company_nationality']
                                        }
                                                     fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                }
                            </SectionRow>
                            {
                                companyDocuments.length > 0 && <SectionRow>
                                    <SectionColumn>
                                        {/*<SectionRow>*/}
                                        {/*    <SectionColumn style={{width: '10vw'}}>*/}
                                        {/*        <SubmitButton*/}
                                        {/*            style={{*/}
                                        {/*                border: '2px solid rgba(18,77,217,0.71)',*/}
                                        {/*                background: 'rgba(124,157,238,0.71)'*/}
                                        {/*            }}*/}
                                        {/*            commandText={this.state.visible ? 'Hide' : 'Show documents'}*/}
                                        {/*            callback={() => {*/}
                                        {/*                // window.alert(this.state.visible)*/}
                                        {/*                this.setState({visible: !this.state.visible}, () => {*/}
                                        {/*                    document.getElementById(`company-${y}`).setAttribute('display', this.state.visible ? 'none' : 'block');*/}
                                        {/*                })*/}
                                        {/*            }}/>*/}
                                        {/*    </SectionColumn>*/}
                                        {/*</SectionRow>*/}
                                        <SectionRow id={`company-${y}`}>
                                            <SectionColumn>
                                                <DocumentDetailsCard noDownload={this.props['allowDownload']}
                                                                     documentList={companyDocuments}
                                                />
                                            </SectionColumn>

                                        </SectionRow>
                                    </SectionColumn>

                                </SectionRow>
                            }
                        </SectionColumn>);
                    } else {
                        if (datum['sh_first_name'] || datum['sh_last_name'] || datum['sh_surname'])
                            shareholderLists.push(<SectionColumn
                                style={{
                                    background: 'rgba(82,139,239,0.27)',
                                    borderBottom: '1px solid #C8B5BAFF',
                                    marginBottom: 4
                                }}>
                                <SectionRow>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'name'} withColon fontSize={14}
                                                         bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'}
                                                     text={`${datum['sh_first_name']} ${datum['sh_middle_name']}`}
                                                     fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'Gender'} withColon fontSize={14} bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'}
                                                     text={!isNaN(datum['gender']) ? extractValueFromJSON(datum['gender'],
                                                         arrayOfJSONsToJSON(this.gender)) : !isNaN(datum['sh_gender']) ?
                                                         extractValueFromJSON(datum['sh_gender'],
                                                             arrayOfJSONsToJSON(this.gender)) : datum['sh_gender']}
                                                     fontSize={14}
                                                     capitalize/>
                                        </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                       <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'Kenyan ID/Alien ID/Passport'}
                                                         withColon fontSize={14}
                                                         bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'} text={datum['sh_national_id'] ?
                                            datum['sh_national_id'] : datum['sh_national_id']} fontSize={14}/>
                                       </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'shares'} withColon fontSize={14} bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'}
                                            // return the commented item below to
                                            // extractValueFromJSON(datum['sh_individual_type'], arrayOfJSONsToJSON(this.shareType))
                                                     text={datum['sh_individual_number_of_shares'] ? `${datum['sh_individual_number_of_shares']}
                                                  - [${typeof datum['sh_individual_type'] === 'string' ? datum['sh_individual_type'] :
                                                             extractValueFromJSON(datum['sh_individual_type'],
                                                                 arrayOfJSONsToJSON(this.shareType))}]` :
                                                         `${datum['sh_individual_number_of_shares']} (${datum['sh_individual_type']})`}
                                                     fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'Start date'} withColon fontSize={14}
                                                         bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'} text={
                                            datum['sh_shareholding_start'] &&
                                            formatDate(datum['sh_shareholding_start'].split('T')[0],
                                                'dmy',
                                                'dmy')
                                        }
                                                     fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'Shareholder type'} withColon
                                                         fontSize={14}
                                                         bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'} text={'individual'}
                                                     fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'Director'} withColon fontSize={14}
                                                         bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'} text={datum['sh_is_director'] ? 'Yes' : 'No'}
                                                     fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel colour={'#1e1b1e'} text={'Nationality'} withColon fontSize={14}
                                                         bold
                                                         capitalize/>
                                        <SimpleLabel colour={'#1e1b1e'} text={

                                            arrayOfJSONsToJSON(readFromLocalStorage('country', BCLB))[datum['sh_individual_nationality']]
                                        }
                                                     fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                </SectionRow>
                                {
                                    individualDocuments.length > 0 && <SectionRow>
                                        <SectionColumn>
                                            {/*<SectionRow>*/}
                                            {/*    <SectionColumn style={{width: '10vw'}}>*/}
                                            {/*        <SubmitButton*/}
                                            {/*            style={{*/}
                                            {/*                border: '2px solid rgba(18,77,217,0.71)',*/}
                                            {/*                background: 'rgba(124,157,238,0.71)'*/}
                                            {/*            }}*/}
                                            {/*            commandText={this.state.visible ? 'Hide' : 'Show documents'}*/}
                                            {/*            callback={() => {*/}
                                            {/*                // window.alert(this.state.visible)*/}
                                            {/*                this.setState({visible: !this.state.visible}, () =>{*/}
                                            {/*                  let item = document.getElementById(`individual-${y}`);*/}
                                            {/*                //console.log('item is ', item)*/}
                                            {/*                //     item.setAttribute('display', this.state.visible ? 'none' : 'block');*/}
                                            {/*                })*/}
                                            {/*            }}/>*/}
                                            {/*    </SectionColumn>*/}
                                            {/*</SectionRow>*/}
                                            <SectionRow id={`individual-${y}`}>
                                                <SectionColumn>
                                                    <DocumentDetailsCard noDownload={this.props['allowDownload']}
                                                                         documentList={individualDocuments}
                                                    />
                                                </SectionColumn>

                                            </SectionRow>

                                        </SectionColumn>
                                    </SectionRow>
                                }
                            </SectionColumn>);
                    }
                    y += 1;
                } while (y < rowData.length)
            else shareholderLists = <SectionRow>
                <SectionColumn style={{
                    fontFamily: 'monospace',
                    fontSize: 28,
                    fontWeight: 800,
                    color: '#c5c6d5',
                    textAlign: 'center'
                }}>
                    No shareholders listed!
                </SectionColumn>
            </SectionRow>
        }
        return shareholderLists;
    }

    render = () => {
        return <Section boxedColour noBoxShadow>
            {
                !this.props.noTitle && <SectionTitle>
                    shareholders
                </SectionTitle>
            }
            {/*<div className={`${row} bottom-streak`} style={{height: 4, background: '#800080'}}/>*/}
            {this.shareholderListView()}

        </Section>
    }
}