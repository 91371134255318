import {Component} from "react";
import {container, readFromLocalStorage} from "../../../MiscUtils";

import './messagin-component-css.css';
import MessageRow from "./MessageRow";
import {BCLB} from "../redux/allowed-actions";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";

/**
 *
 * Creates a messaging UI meant to send and receive messages from and to ppl.
 * It optionally provides a mechanism to enable selection of target people (
 * especially if used internally) in an organization.
 *
 * It accepts properties such as
 *  1. messages - a array of JSONs of messages consisting of the message string,
 *                time it was sent, recipient's email address (which determines
 *                whether it was theirs or the person who is logged in.
 *                This is used to colour-code the message. Last a status of
 *                whether it has been received.
 *                [
 *                  {
 *                      message: <String>,
 *                      recipient: <String -> email address for messages from outside the board:
 *                                  <Integer> foreign-key Integer for internal messages>,
 *                      delivered: <Boolean>,
 *                      datetime: <String>,
 *                      attachments: [
                                    {
                                        name: <String>,
                                        content:<String|base64-string>,
                                        category: <Integer>
                                    },
                                ...
                                ]
 *                  },
 *                  ...
 *                ]
 *                NOTE: messages are to be arranged by date of send
 *
 *  2. internalUse - a property that tells the component to show a target recipient
 *                  interface at the top of the chat
 *
 *  3. endPoint - a property that tells the component to use a given
 *                  endPoint in sending and receiving messages. NOTE: the
 *                  complete URL can also occupy this property should it be desired.
 *                  This is particularly of use when separate messaging servers are in use.
 *                  By default, the universal URL at GMS in conjunction
 *                  with the value in this property is used.
 *
 */
export default class MessagingComponent extends Component {
    constructor(props) {
        super(props);
        // socket connection to server
        this.socket = null;
        this.state = {
            messageRows: null,
            nextProps: null,
            message: null,
            attachments: null, // a list of documents,
            /*
            [
               {
                   message: <String>,
                   recipient: <String -> email address for messages from outside the board:<Integer> foreign-key Integer for internal messages>,
                   delivered: <Boolean>, datetime: <String>
                   attachments: [
                                    {
                                        name: <String>,
                                        content:<String|base64-string>,
                                        category: <Integer>
                                    },
                                ...
                                ]
               },
               ...
            ]
            */
        }
    }

    /**
     *
     * Should there be any communication from the parent component (DOM-wise)
     * then the update is done here
     * @param nextProps
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({nextProps: nextProps});// specify what should be
        // done here
        //
        // scroll down
        this.scrollMessageContainerDown();
    }

    /**
     *
     * Populate all chat messages from an array. This calls the chat message.
     * @param messages the array of messages in question. Note the structure of the message below:
     *
     *  {
     *      message: <String>,
     *      recipient: <String -> email address for messages from outside the board:<Integer> foreign-key Integer for internal messages>,
     *      delivered: <Boolean>,
     *      datetime: <String>,
     *      attachments: [
     *          {
     *              name: <String>,
     *              content:<String|base64-string>,
     *              category: <Integer>
     *          },
     *          ...
     *      ]
     *  }
     *
     */
    populateChatMessages = (messages = []) => {
        // get who is logged in right now.
        const me = readFromLocalStorage('email', BCLB);
        //
        // create message rows concerning the message in question
        //
        let y = 0;
        let messagesObjectsArray = [];
        //
        //console.log('messages ', messages[y].attachments)
        do {
            const k = messages[y].recipient === me ?
                <MessageRow mine
                            attachmentscallback={() => {
                            }}
                            attachments={messages[y].attachments ? messages[y].attachments : null}
                            delivered={messages[y].delivered}
                            message={messages[y].message}/> :
                <MessageRow theirs
                            attachmentscallback={() => {
                            }}
                            attachments={messages[y].attachments ? messages[y].attachments : null}
                            delivered={messages[y].delivered}
                            message={messages[y].message}/>;
            // theirs or ours, check recipient field
            messagesObjectsArray.push(k);
            y += 1;
        } while (y < messages.length);
        // list of MessageRow
        this.setState({messageRows: messagesObjectsArray});
    }
    /**
     *
     * Populate the new chat message into the list of chat messages
     * @param message
     * @param theirs
     * @param attachments
     */
    populateChatMessage = (message = {} | String, theirs = false, attachments = this.state.attachments) => {
        // create message rows concerning the message in question
        // update state
        let currentMessageRows = this.state.messageRows ? this.state.messageRows : [];
        currentMessageRows.push(<MessageRow mine
                                            attachmentscallback={() => {
                                            }}
                                            attachments={attachments}
                                            delivered={false}
                                            message={message}/>);
        // update list of current messages
        this.setState({messageRows: null}, () => {
            this.setState({messageRows: currentMessageRows}, () => {
                this.scrollMessageContainerDown();
            });
        });
        // scroll down
    }
    /**
     *
     * Send a message to the server from the current user, addressed to
     * @param message
     */
    sendChatMessage = (message, attachments = null) => {
        // populate chat messages with the current message and theirs being false
        if (this.socket) {
            this.socket.send({message: message, attachments: attachments}); // send a string
            this.socket.onmessage = (msg) => {
                // is there a msg.data ? create
                //console.log('Q and A', msg);
            }
        }
    }

    /**
     *
     * scroll the message container down to the latest/last message.
     *
     */
    scrollMessageContainerDown = () => {
        //
        // find this component: chat-message-rows-box
        //
        //
        let d = document.getElementsByClassName('chat-message-rows-box')[0];
        if (d)
            d.scrollTop = d.scrollHeight

    }
    /**
     * Fetch all chat messages from the server, using the email concerning this person
     */
    componentDidMount = () => {
        if (this.props.messages) {
            if (this.props.messages.length > 0) {
                this.populateChatMessages(this.props.messages); // populate the chat messages here
                this.scrollMessageContainerDown()
            }
        }

        /// do a socket connection to the server
        if (this.props.socketServer) {
            this.socket = new WebSocket(`ws://${this.props.socketServer}`);
            // this.socket.open()
        }

    }

    /**
     *
     * read file contents into a base64 string
     * @param _file the file object to read as base64
     * @param callback a callback method to execute when read is complete
     * @returns {string} the file in form of a base64 string
     *  that the file contents are translated to.

     */
    convertToBase64 = (_file = File | Blob, callback = undefined) => {
        if (callback === undefined) {
            throw new ReferenceError(`'convertToBase64' method requires a callback as a second argument.
            This is because this method's file-read activity is asynchronous and does not do well with returning
            its output. Pass a method reference(--without-arguments--) which will execute when
            the internal file-read-operation is complete.`);
        }
        let fR;
        if (_file instanceof File || _file instanceof Blob) {
            fR = new FileReader();
            let fileString;
            fR.readAsDataURL(_file);
            fR.onload = (e) => {
                fileString = e.target.result;
                if (callback !== undefined) {
                    callback(fileString);
                }
                // return fileString;
            };
        } else
            throw new TypeError(`'convertToBase64' method expected a file object. Found ${typeof _file}`);
    }

    render = () => {
        return <div className={container}>
            {/*have a floating div on top here that kind of stays faded but can be activated on mouse hover*/}
            {/*<div className={`${row} utility-bar`}>*/}
            {/*    <div className={col12}>*/}
            {/*        utility bar*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={row}>*/}
            {/*    <div className={`${col12} chat-message-rows-box`}>*/}
            {/*        {*/}
            {/*            this.state.messageRows*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={row} style={{height: '6vh'}}>*/}
            {/*    <div className={col10}>*/}
            {/*        <TextField bold fontSize={14}*/}
            {/*                   capitalize name={'chat_message'} placeholder={''} changeCallback={(e) => {*/}
            {/*            this.setState({message: e.target.value})*/}
            {/*        }}/>*/}
            {/*    </div>*/}
            {/*    <div className={col1}>*/}
            {/*        <AttachFileField with_base64 name={null} changeCallback={*/}
            {/*            (e) => {*/}
            {/*                // attach to latest chat message that belongs to me.*/}
            {/*                // by updating the current message with attachments concerning the message*/}
            {/*                // update the attachments*/}
            {/*                const currentFile = e.target.files[0]; // only catch the first item*/}
            {/*                // attachments is a array*/}
            {/*                let currentAttachments = this.state.attachments === null ? [] : this.state.attachments;*/}
            {/*                // convert to currentAttachments then*/}
            {/*                this.convertToBase64(currentFile, (e) => {*/}
            {/*                    // name, content, category*/}
            {/*                    currentAttachments.push({name: currentFile.name, content: e, category: ''});*/}
            {/*                    // update state with the current file*/}
            {/*                    this.setState({attachments: null}, () => {*/}
            {/*                        this.setState({attachments: currentAttachments});*/}
            {/*                    });*/}
            {/*                });*/}
            {/*            }*/}
            {/*        }/>*/}
            {/*    </div>*/}
            {/*    <div className={col1}>*/}
            {/*        <SendMessageArrowControl callback={() => {*/}
            {/*            // populate this to the messaging component*/}
            {/*            // and check for attachments in state*/}
            {/*            if (this.state.message !== null || this.state.attachments !== null) {*/}
            {/*                this.populateChatMessage(this.state.message, true, this.state.attachments); // I am the one setting the chat message*/}
            {/*                // send to server but first, populate the data into the list of messages then send last*/}
            {/*                // message*/}
            {/*                this.sendChatMessage(this.state.message, this.state.attachments);*/}
            {/*                // the above method will check whether there is some communication from the backend*/}
            {/*                // then will chain it into the conversation.*/}
            {/*            }*/}
            {/*            // nullify the state message and attachments*/}
            {/*            this.setState({message: null, attachments: null});*/}
            {/*        }}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Section>
                <SectionTitle style={{color: 'red', fontWeight: 700,}}>
                    warning!!
                </SectionTitle>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        Communications (chats) are only allowed on the live system.
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>Ignoring request...</SectionColumn>
                    <SectionColumn/>
                </SectionRow>
            </Section>
        </div>
    }
}