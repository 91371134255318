import GMS from "../../../../parent-component/GMS";
import {SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {connect} from "react-redux";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";
import {arrayOfJSONsToJSON, notify} from "../../../../../MiscUtils";
import React from "react";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {SUBTITLE} from "../../../../general-components/redux/allowed-actions";

class CompletePremiseDeclaration extends GMS {
    constructor(props) {
        super(props);
        this.spanStyle = {
            fontFamily: 'monospace',
            fontSize: 28,
            fontWeight: 800,
            color: '#c5c6d5',
            textAlign: 'center'
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        this.setState({e: {...nextProps.backup}}, this.processSummaryView)
    }

    /**
     *
     *
     * Create the view for summary
     *
     */
    processSummaryView = () => {
        const deviceTypes = {
            'shop_and_premise_hardware_devices': 'hardware_devices',
            'shop_and_premise_software_devices': 'software_devices',
        };
        // go through the list of utilities/everything that has "on"
        // Loop through e and look for those that have values "on"
        let v = 0;
        let utilitiesList = [];
        let keys = Object.getOwnPropertyNames(this.state.e);
        do {
            if (this.props.backup[keys[v]] === true)
                if (keys[v] !== 'is_franchise') // get rid of this since it is not required
                    utilitiesList.push(<span
                        style={{textTransform: 'capitalize'}}>{keys[v].replaceAll('_', ' ').replaceAll('have', '')}</span>);
            v += 1;
        } while (v < keys.length);
        this.setState({dataItem2: [...utilitiesList]});
        //
        // process devices
        //
        let k = [];
        if (keys.includes('shop_and_premise_hardware_devices')) {
            let p = 0;
            // console.log(this.props, this.props.backup.devices[0].device_id)
            // console.log(this.props[deviceTypes[this.props.backup.devices[0].device_id]])
            this.state.e['shop_and_premise_hardware_devices'].map((device, index) => {
                k.push(<SectionRow>
                    <SectionColumn style={{width: 40, fontWeight: 800, fontSize: 16, color: '#2837e1'}}>
                        {k.length + 1}.
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Type'}/>
                                <SimpleLabel
                                    text={'Hardware'}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Device'}/>
                                <SimpleLabel
                                    text={Object.getOwnPropertyNames(this.state.e['shop_and_premise_hardware_devices'][index]).includes('device_id') && arrayOfJSONsToJSON(this.props[deviceTypes[this.state.e['shop_and_premise_hardware_devices'][index].device_id]])[device.device]}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Serial number'}/>
                                <SimpleLabel text={device.serial_number}/>
                            </span>
                    </SectionColumn>
                </SectionRow>);
            });
        }
        if (keys.includes('shop_and_premise_software_devices')) {
            let p = 0;
            // let k = [];
            // console.log(this.props, this.props.backup.devices[0].device_id)
            // console.log(this.props[deviceTypes[this.props.backup.devices[0].device_id]])
            this.state.e['shop_and_premise_software_devices'].map((device, index) => {
                k.push(<SectionRow>
                    <SectionColumn style={{width: 40}}>
                        {k.length + 1}
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Type'}/>
                                <SimpleLabel
                                    text={'Software'}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Device'}/>
                                <SimpleLabel
                                    text={Object.getOwnPropertyNames(this.state.e['shop_and_premise_software_devices'][index]).includes('device_id') && arrayOfJSONsToJSON(this.props[deviceTypes[this.state.e['shop_and_premise_software_devices'][index].device_id]])[device.device]}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Serial number'}/>
                                <SimpleLabel text={device.serial_no}/>
                            </span>
                    </SectionColumn>
                </SectionRow>);
            });
        }
        this.setState({dataItem1: [...k]});
    }
    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'Summary of this-premise declaration.'});

        // go through the devices object and create objects as a result of it, add them to this state e
        let v = {};
        this.setState({e: {...this.props.backup}}, () => {
            if (this.state.e['devices'])
                this.state.e['devices'].map((device, index) => {
                    if (Object.getOwnPropertyNames(v).includes(device['device_id']))
                        v[device['device_id']] = [...v[device['device_id']], device];
                    else {
                        v[device['device_id']] = [device];
                    }
                });
            this.setState(state => {
                state.e = {...this.state.e, ...v};
                return state;
            }, this.processSummaryView);
        })
    }

    render = () => {
        return <SectionRow>
            {this.state.currentRoute}
            <SectionColumn>
                <SectionRow>
                    <SectionColumn>
                        The premise declared has the following characteristics:
                    </SectionColumn>
                </SectionRow>
                <SectionRow boxedColour>
                    <SectionColumn>
                        <SectionRow>
                            <SectionTitle>
                                Nature of premise and state of franchise
                            </SectionTitle>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <span>
                                This is a <b>{this.state.e['is_franchise'] === "on" ? 'franchised' : 'non-franchised'}</b>
                                    &nbsp;
                                    <b>{this.state.e['type']}</b> entity partitioned <b>{this.state.e['partitions']}</b> times over,
                                and has <b>{this.state.e['windows']}</b> windows for ventilation.
                                    </span>
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
                <SectionRow boxedColour>
                    <SectionColumn>
                        <SectionRow>
                            <SectionTitle>
                                Employees at this premise
                            </SectionTitle>
                        </SectionRow>

                        {
                            this.state.e['shop_premise_employees'] ? this.state.e['shop_premise_employees'].map(employee => {
                                return (<SectionRow>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'Name'} bold capitalize fontSize={14}/>
                                            <SimpleLabel
                                                text={`${employee.e_first_name} ${employee.e_middle_name} ${employee.e_surname} `}
                                                capitalize fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'email'} bold capitalize fontSize={14}/>
                                            <SimpleLabel text={employee.e_email} fontSize={14}/>
                                        </span>
                                    </SectionColumn>
                                    <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'is a contact person'} bold capitalize fontSize={14}/>
                                            <SimpleLabel
                                                text={employee.is_contact_person === undefined ? 'No' : employee.is_contact_person === true ? 'Yes' : 'No'}
                                                capitalize fontSize={14}/>
                                        </span>
                                    </SectionColumn>

                                </SectionRow>)
                            }) : <span style={this.spanStyle}>No employees declared!</span>}

                    </SectionColumn>
                </SectionRow>
                <SectionRow boxedColour>
                    <SectionColumn>
                        <SectionRow>
                            <SectionTitle>
                                Devices assigned to this premise
                            </SectionTitle>
                        </SectionRow>
                        {
                            this.state.dataItem1.length > 0 ? this.state.dataItem1 :
                                <span style={this.spanStyle}>No devices declared!</span>
                        }
                    </SectionColumn>
                </SectionRow>
                <SectionRow boxedColour>
                    <SectionColumn>
                        <SectionRow>
                            <SectionTitle>
                                utilities available
                            </SectionTitle>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                {
                                    this.state.dataItem2 ? this.state.dataItem2 :
                                        <span style={this.spanStyle}>No utilities selected!</span>
                                }
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn className={'legal-notification'}>
                        <b>Note:</b>
                        <span>
                            Giving false information to the Board is an offence under section 5(A) of
                            the Betting, Lotteries and Gaming Act (<i>Cap 131</i>) laws of Kenya
                        </span>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <SubmitButton style={{background: 'rgba(231,30,30,0.82)'}}
                                      commandText={'Cancel'}
                                      callback={() => {
                                          // send this data to the shops/premises endpoint
                                          this.nullPOSTData();
                                          notify('Premise declaration process cancelled!', 2, true);
                                          window.setTimeout(() => this.navigateTo('../../list-premises'), 1800);
                                      }}/>
                    </SectionColumn>
                    <SectionColumn>
                        {
                            this.state.e['devices'] &&
                            // this.state.e['shop_name'] &&
                            // this.state.e['shop_premise_employees'] &&
                            <SubmitButton commandText={'Declare'}
                                          callback={() => {
                                              // send this data to the shops/premises endpoint
                                              this.sendRequest('post', 'company/operator/premise',
                                                  response => {
                                                      // navigate to list of premises
                                                      if (response.type === 'Success') {
                                                          // navigate to list of premises component
                                                          notify(response.message, 4, true);
                                                          this.nullPOSTData();
                                                          this.navigateTo('../../list-premises');
                                                      } else {
                                                          notify(
                                                              <span>Cannot declare premises. An error occurred:<br/><br/>
                                                                  {response.message}</span>, 2, false, 'Premise not declared');
                                                      }
                                                  });

                                          }}/>
                        }

                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
            </SectionColumn>
        </SectionRow>
    }
}

const mapStateToProps = (initialState) => {
    return {
        backup: initialState.LOCAL_BACKUP,
        hardware_devices: initialState.hardware_devices,
        software_devices: initialState.software_devices
    };
}
export default connect(mapStateToProps)(CompletePremiseDeclaration);