import {Component} from 'react';
import './info-tab.css';

/**
 *
 * Class defines a component meant to act as a label, but for showing a brief
 * OperatorSummary in the form of a number and a label.
 * It is clickable and can be used to filter-up data based on the callback method
 * supplied as one of its property lists.
 *
 * Property list includes:
 *      callback - a method (anonymous or a method-reference) that gets called when the component is clicked
 *
 *      label - a string representing what gets displayed at the bottom of the component. Its colour defaults to #FFFFFF.
 *
 *      value - a string/number representing some given count regarding the contents as described by the label
 *
 *      valueColour - a (HEX) string representing the colour of the value in question. NOTE, the background
 *                      colour of the label is also this colour. It defaults to #252525 - a shade of black and red.
 *
 *
 * Some conversion of colours from hex -(base16)- to base 10 is needed to ensure that the colours that the user
 * selects are as far away from white as possible. This is because the label texts are and will always be white by default.
 * To make them visible, the colour passed through the 'valueColour' property needs to be anything other than "close-to-white".
 *
 * An illustration and explanation has been borrowed from:
 *      https://javascript.plainenglish.io/convert-hex-to-rgb-with-javascript-4984d16219c3
 * at the time of writing this component class.
 *
 */
export default class InfoTab extends Component {
    constructor(props) {
        super(props);
        this.value = props.value === undefined ? '-no-value-' : props.value;
        this.label = props.label === undefined ? '-no-label-' : props.label;
        this.valueColour = props.valueColour === undefined ? String('#252525') : props.valueColour;
        this.hasCallback = false; // this is used to determine whether a pointer will be in use when hovered on or not
        this.callback = props.callback === undefined ? () => {
                console.warn(`No callback property issued to 
                    <${this.constructor.name} '<...props-list> callback = {???}'/>`);
            }
            : () => {
                this.hasCallback = true;
                props.callback();
                return props.callback;
            }
        /*
        *
        * To make things convenient, attempt at helping the user choose a colour that is far away from white by at least
        * 25 units. white is 255,255,255. any colour that is closer to or less than 255-55 then leads to a default
        * colour of #252525 for all the colours for value and background for labels.
        *
        */
        const rgBFromHEX = this.valueColour.match(/.{1,2}/g);
        /*
        *
        * this will yield an array of three items each representing R, G and B respectively.
        * Convert the array elements to integer/decimal notation from base16 which is the current case.
        * Use parseInt with first argument as the hex value and second argument as the base from which to switch.
        * The default if not passed is 10. pass 16 for the second argument.
        *
        */
        const rgbToDecimal = [
            parseInt(rgBFromHEX[0], 16),
            parseInt(rgBFromHEX[1], 16),
            parseInt(rgBFromHEX[2], 16)
        ];
        /*
        *
        * check if the individual values are as far away from white as possible. remember that white is
        * 255 for all colour variations.
        * if any 2 of the colours is closer to white, default to the original colour
        *
        */
        this.valueColour = (rgbToDecimal[0] >= 200 && rgbToDecimal[1] >= 200) ||
        (rgbToDecimal[1] >= 200 && rgbToDecimal[2] >= 200) ||
        rgbToDecimal[0] >= 200 && rgbToDecimal[2] >= 200 ? String('#252525') : this.valueColour;

        /**
         *
         * define a state that hosts the value as it is adjusted externally.
         *
         */
        this.state = {value: this.props.value};
    }

    /**
     *
     * Only the value is required to change in this context. Make room for admitting such changes
     *
     * @param nextProps containing the next value to show
     * @param nextContext irrelevant at this time
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({value: nextProps.value});
    }

    render = () => {
        return (
            <div className={'info-tab'} onClick={this.callback}
                 style={{cursor: this.hasCallback ? 'pointer' : 'default'}}>
                <div className={'value'} style={{color: this.valueColour}}>
                    {this.state.value}
                </div>
                <div className={'label'} style={{background: this.valueColour}}>
                    {this.label}
                </div>
            </div>
        );
    }
}