export const servicesMap = {
    "Bookmaking": {
        "context": "LA",
        "Bookmakers license application": "?service_name=bookmakers_license&is_application_new=true",
        "Bookmakers license new": "?service_name=bookmakers_license&is_license_new=true",
        "Bookmakers license renewal application": "?service_name=bookmakers_license&is_application_renewal=true",
        "Bookmakers license renewal": "?service_name=bookmakers_license&is_license_renewal=true",
        "Bookmakers retail shops new": "?service_name=bookmakers_retail_shops&is_shop_new=true", // PREM for premise
        "Bookmakers retail shops renewal": "?service_name=bookmakers_retail_shops&is_shop_renewal=true", //PREM for premise
        "Bookmakers license relocation of premises": "?service_name=bookmakers_retail_shops&is_relocation_of_premise=true", //PREM for premise
        "Bookmakers retail shops relocation of premises": "?service_name=bookmakers_retail_shops&is_relocation_of_premise=true", //PREM for premise
        "Bookmakers license due diligence": "?service_name=bookmakers_license&is_due_diligence=true",
        "Shareholder change": "?service_name=shareholder_change&is_application_new=true&is_application_renewal=true&is_foreigner=true&is_license_new=true&is_permit_new=true&is_due_diligence=true&is_license_renewal=true&is_relocation_of_premise=false&is_percentage=true", // specifically for shareholder change
        // support license application
    },// new or renew, premise move or new
    // "Poolbetting": {"": ""},//new or renew
    "Prize Competition": {
        "context": "LA",
        "Prize competition application": "?service_name=prize_competition&is_application_new=true&is_percentage=false",
        "Additional budget": "?service_name=prize_competition&is_permit_new=true&is_percentage=true",
        "Shareholder change": "?service_name=shareholder_change&is_application_new=true&is_application_renewal=true&is_foreigner=true&is_license_new=true&is_permit_new=true&is_due_diligence=true&is_license_renewal=true&is_relocation_of_premise=false&is_percentage=true", // specifically for shareholder change
        "Prize competition issuance": "?service_name=prize_competition&is_permit_new=true&is_percentage=true"
    }, //new or extension (which is treated as a new one
    "Public Gaming": {
        "context": "LA",
        "Public gaming (casino) application fee new": "?service_name=public_gaming_casino&is_application_new=true",
        "Public gaming (casino) new license fee": "?service_name=public_gaming_casino&is_license_new=true",
        "Public gaming (casino) due diligence": "?service_name=public_gaming_casino&is_due_diligence=true",
        "Public gaming (casino) renewal application fee": "?service_name=public_gaming_casino&is_application_renewal=true",
        "Public gaming (casino) renewal license fee": "?service_name=public_gaming_casino&is_license_renewal=true",
        "Public gaming relocation of premises": "?service_name=public_gaming_casino&is_relocation_of_premise=true",
        // "Premise relocation": "?service_name=public_gaming_casino&is_relocation_of_premise=true",
        "Shareholder change": "?service_name=shareholder_change&is_application_new=true&is_application_renewal=true&is_foreigner=true&is_license_new=true&is_permit_new=true&is_due_diligence=true&is_license_renewal=true&is_relocation_of_premise=false&is_percentage=true", // specifically for shareholder change

    }, // new or renew, premise new or relocate
    "Public Lottery (Short Term)": {
        "context": "LA",
        "Public lottery short-term application fee": "?service_name=public_lottery_short-term&is_application_new=true",
        "Public lottery short-term permit fee": "?service_name=public_lottery_short-term&is_permit_new=true&is_percentage=true"
        }, // new or renew
    "Public Lottery (Long Term)": {
        "context": "LA",
        "Public lottery long-term application": "?service_name=public_lottery_long-term&is_application_new=true",
        "Public lottery long-term new": "?service_name=public_lottery_long-term&is_license_new=true",
        "Public lottery long-term due diligence": "?service_name=public_lottery_long-term&is_due_diligence=true&is_percentage=false",
        "Shareholder change": "?service_name=shareholder_change&is_application_new=true&is_application_renewal=true&is_foreigner=true&is_license_new=true&is_permit_new=true&is_due_diligence=true&is_license_renewal=true&is_relocation_of_premise=false&is_percentage=true", // specifically for shareholder change
        "Public lottery long-term relocation of premise": "?service_name=public_lottery_long-term&is_relocation_of_premise=true",
        "Public lottery long-term renewal application fee": "?service_name=public_lottery_long-term&is_application_renewal=true",
        "Public lottery long-term renewal license fee": "?service_name=public_lottery_long-term&is_license_renewal=true"
    }, // new or renew
    "Totalizator": {
        "context": "LA",
        "Totalizator application": "?service_name=totalizator&is_application_new=true",
        "Totalizator new": "?service_name=totalizator&is_license_new=true",
        "Shareholder change": "?service_name=shareholder_change&is_application_new=true&is_application_renewal=true&is_foreigner=true&is_license_new=true&is_permit_new=true&is_due_diligence=true&is_license_renewal=true&is_relocation_of_premise=false&is_percentage=true", // specifically for shareholder change
        "Totalizator renewal application fee": "?service_name=totalizator&is_application_renewal=true",
        "Totalizator renewal": "?service_name=totalizator&is_license_renewal=true",
        "Totalizator relocation of premises": "?service_name=totalizator&is_relocation_of_premise=true",
        "Totalizator due diligence": "?service_name=totalizator&is_due_diligence=true"
    },
    'Pool Betting':{
        'Pool betting due diligence':'?service_name=pool_betting&is_due_diligence=true',
        'Pool betting application':'?service_name=pool_betting&is_application_new=true',
        'Pool betting new':'?service_name=pool_betting&is_license_new=true',
        'Pool betting renewal Application':'?service_name=pool_betting&is_application_renewal=true',
        'Pool betting renewal':'?service_name=pool_betting&is_license_renewal=true',
        'Pool betting relocation of premises':'?service_name=pool_betting&is_relocation_of_premise=true',
    },
    "Premise": {
        "context": "Premise",
        "New premise": "?service_name=premise&is_shop_new=true",
        "Premise renewal": "?service_name=premise&is_shop_renewal=true",
        "Premise relocation": "?service_name=public_lottery_long-term&is_relocation_of_premise=true"
    }

}
export default {servicesMap}
