import React, {Component} from "react";
import ThumbNail from "./ThumbNail";
import {col12, col4, col5, convertToBase64, row} from "../../../MiscUtils";

/**
 *
 * Thumbnail superclass. Defines how thumbnails look like and operate.
 * {'filename': 'the base64 description of a file'}
 *
 */
class ThumbNailList extends Component {
    constructor(props) {
        super(props);
        this.thumbnails = [];
        this.state = {
            documentsList: null,
            thumbnails: null
        }
        this.style = props.style ? props.style : null;
        this.thumbnailcallback = props.thumbnailcallback === undefined ? () => {
            //by default, load the document viewer
            console.warn(`<${this.constructor.name} /> has no callback underthe property name 'thumbnailcallback'`)
        } : props.thumbnailcallback;
        this.isAttachment = props.asAttachments;
        //console.log('document ', this.props.documents)
    }

    /**
     *
     * Received new documents from DocumentUploader or some other source.
     * @param nextProps
     * @param nextContext
     * @constructor
     *
     */

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        //
        //console.log('props thumbnails documents on receive props', this.props.documents)
        if (nextProps.documents !== null) {
            //console.log(nextProps.documents);
            if (nextProps.documents.constructor.name === [].constructor.name && nextProps.documents.length > 0 && nextProps.documents[0] !== undefined) {
                if (nextProps.documents[0].content instanceof File || nextProps.documents[0].content instanceof Blob) {
                    // by default, this is an array
                    convertToBase64(nextProps.documents, documents => {
                        //console.log(documents)
                        this.setState({documentsList: documents}, () => {
                            this.showDocumentThumbnails(this.state.documentsList, this.isAttachment);
                        });
                    });
                }
            }
        }
    }


    /**
     *
     * Update ThumbNailList component with new documents. use a react reference to update the child in the caller's
     * componentDidUpdate lifecycle method.
     *
     * @param documentsList list of documents to render and create thumbnails.
     *
     * @param asAttachments style this thumbnail that its an attachment thumbnail
     */
    showDocumentThumbnails = (documentsList = this.props.documents, asAttachments = this.props.asAttachments) => {
        this.thumbnails = [];//nullify existing thumbnails
        let t = 0;
        if (documentsList.length > 0) {
            do {
                const currentDocument = documentsList[t];//get the filename
                this.thumbnails[t] = asAttachments ?
                    <ThumbNail isAttachment
                               callback={this.thumbnailcallback}
                               filename={currentDocument.name}
                               fileContent={currentDocument.fileContent}/>
                    :
                    <ThumbNail callback={this.thumbnailcallback}
                               filename={currentDocument.name}
                               fileContent={currentDocument.fileContent}/>
                t += 1;
            } while (t < documentsList.length)
            // thumbnail list
            this.setState({documentsList: documentsList, thumbnails: this.thumbnails});
        }
    }
    /**
     *
     * show document thumbnails
     *
     */
    componentDidMount = () => {
        //console.log('props thumbnails documents on mount ', this.props.documents)
        if (this.props.documents)
            if (this.props.documents.length > 0)
                this.showDocumentThumbnails(this.props.documents);
    }
}

/**
 *
 * ThumbNailList component for vertical views.
 *
 */
export class VerticalThumbNailList extends ThumbNailList {
    render = () => {
        return (<div style={{...this.style}} className={'thumbnail-vertical-list'} onClick={this.thumbnailcallback}>
            <div className={`${row} file-name-label`} style={{borderBottom: '2px solid '}}>
                <div className={col12}>
                    <div className={row}>
                        <div className={col5}/>
                        <div className={`${col4} title`}
                             style={{
                                 bottom: 10,
                                 color: '#800080'
                             }}>
                            {
                                this.state.thumbnails === null ? 0 : this.state.thumbnails.length
                            } <span>Selected</span>
                        </div>
                    </div>
                </div>
            </div>

            {this.state.thumbnails}
        </div>);
    }
}

/**
 * ThumbNailList for horizontal views.
 * Horizontal ThumbNailList does not require a counter attached to it at this time.
 */
export class HorizontalThumbNailList extends ThumbNailList {
    render = () => {
        return (<div className={'thumbnail-horizontal-list'}>
            {this.state.thumbnails}
        </div>);
    }
}

/**
 *
 * ThumbNailList for attachments .Normally, it is at a horizontal orientation.
 *
 */

export class AttachmentsThumbNailList extends ThumbNailList {
    render = () => {
        return <div className={'thumbnail-attachments-list'}>
            {this.state.thumbnails}
        </div>
    }
}