import React, {Component} from "react";
import {connect} from "react-redux";

import './sidebar.css';
import LineControl from "../controls/svg-controls/LineControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**
 *
 * List of classes meant for styling and animations/selections for visual appeal
 *
 */
const currentSelectedMenuItemsCSSClass = String('side-bar-menu-item-selected');
const currentSelectedIconCSSClass = String('side-bar-menu-item-selected');
const stretchOff = 'side-bar-stretch-off';
const stretchOn = 'side-bar-stretch-on';
const slideOn = 'side-bar-slide-on';
const slideOff = 'side-bar-slide-off';

/**
 *
 * SideBar component for drawing menu and side menu items and actuates their clicks.
 * The structure of the menu object is:
 *
 * [
 *      {
 *          name: menu_name1,
 *          menu:{
 *              submenu1:
 *                  {
 *                      icon: BLOB1_A,
 *                      callback: callback
 *                  },
 *              submenu2:
 *                  {
 *                      icon: BLOB1_B,
 *                      callback: callback
 *                  },
 *                  ...
 *          },
 *          icon: BLOB1,
 *          callback: CALLBACK
 *      },
 *      {
 *          name: menu_name2,
 *          menu:{
 *              submenu1:
 *                  {
 *                      icon: BLOB1_A,
 *                      callback: callback
 *                  },
 *              submenu2:
 *                  {
 *                      icon: BLOB1_B,
 *                      callback: callback
 *                  },
 *                  ...
 *          },
 *          icon: BLOB1,
 *          callback: CALLBACK
 *      },{
 *          name: menu_name3,
 *          menu:[{
 *              submenu1:
 *                  {
 *                      icon: BLOB1_A,
 *                      callback: callback
 *                  },
 *              submenu2:
 *                  {
 *                      icon: BLOB1_B,
 *                      callback: callback
 *                  },
 *                  ...
 *          }],
 *          icon: BLOB1,
 *          callback: CALLBACK
 *      },
 *      ...
 * ]
 *
 * Get all updates from redux
 *
 */
class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSelectedMenuItem: null,
            is_selected: false,
            menuItems: [], //
            menuList: Object.values(props.menuList),
            menu_collapsed: false,
            lastExpandedMenu: null, // last menu to get loaded
            visibility_style: String('side-bar-stretch-off'),
            disabledMenu: this.props.disableMenu ? this.props.disableMenu : [] // menu items to disable
        }
        // the current top value of the menu-item
        this.currentSelectedMenuItems = null;
        this.t = null;
        this.sideBar = null; // this sidebar component AS IS)
    }

    /**
     *
     * Receive updates from redux concerning menu changes and updates
     *
     * @param nextProps from redux
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({disabledMenu: null}, () => {
            this.setState({disabledMenu: nextProps.disableMenu});
        })
    }

    /**
     * Switch selection style of the current item
     * @param e the target to change its styling.
     *
     */
    switchSelection = (e) => {
        if (this.currentSelectedMenuItems)
            if (this.currentSelectedMenuItems.target.classList.contains(currentSelectedMenuItemsCSSClass))
                this.currentSelectedMenuItems.target.classList.remove(currentSelectedMenuItemsCSSClass);
        e.target.classList.add(currentSelectedMenuItemsCSSClass);
        this.currentSelectedMenuItems = e;
    }

    /**
     *
     * These are opening/closing components_to_delete of calling the sidebar if it's called dynamically as opposed
     * to being called statically. These two are fired by a mouse event where the touch or mouse is 10pc
     * of the screen for large screens, or 4pc for small screens
     *
     */
    stretch = (_in = true) => {
    }
    /**
     *
     * Append a sliding style
     *
     */
    slide = () => {

    }
    /**
     *
     * Construct a menuItem component without cols so that it doe not show up as broken.
     * Structure of a menu item:
     *      {
     *          name: menu_name1,
     *          menu:{
     *              submenu1:
     *                  {
     *                      icon: BLOB1_A,
     *                      callback: callback
     *                  },
     *              submenu2:
     *                  {
     *                      icon: BLOB1_B,
     *                      callback: callback
     *                  },
     *                  ...
     *          },
     *          icon: BLOB1,
     *          callback: CALLBACK
     *      },
     *
     * @param menuEntry the menuEntry in question consisting of a name, <optionally> icon and callback
     * @param isSubMenu whether the menuItem is a submenu of a larger group of menu
     * @param forSubMenu indicate that this menu item is a meant for a sub-menu.
     *                   This is because this method is called recursively across the entire
     *                   list and hierarchies of the menuList object
     * @param menuItemId An id of the (sub)menu item in that list.
     * @returns [] the hierarchical menuItem symbols created
     *
     */
    constructMenuItem = (menuEntry, isSubMenu = false, forSubMenu = false, menuItemId = 1) => {

        let menu = [];
        // this is a sub-menu
        if (Object.getOwnPropertyNames(menuEntry).includes('menu')) {
            // from the current MenuList
            // go through the sub menu items
            let k = 0;
            let subMenuList = []; // list of submenu
            do {
                // call thyself and construct hierarchies of menu items and return them to the caller
                // / declare that is a sub-menu
                subMenuList.push(this.constructMenuItem(menuEntry.menu[k],
                    true,
                    false,
                    menuItemId));// this last argument must be present in order to prevent the main counter from resetting
                k += 1;
            } while (k < menuEntry.menu.length);
            //
            // create a main or parent menu item
            //
            menu.push(this.constructMenuItem({
                    name: menuEntry.name,
                    callback: menuEntry.callback,
                    icon: menuEntry.icon
                },
                false,
                true,
                menuItemId));
            //
            menu.push(subMenuList);
        } else {
            // this might be a main-menu or the end of
            //console.log(menuEntry)
            // no sub-menu item
            let entry = <div className={isSubMenu ? `sub-menu-item s_${menuItemId}` : 'side-bar-menu-item'}
                             onClick={forSubMenu ? e => {
                                 // check the last sub-menu item that was expanded and un-expand it
                                 this.switchSelection(e);
                                 let subMenuItems = document.getElementsByClassName(`s_${menuItemId}`);
                                 let y = 0;
                                 do {
                                     if (subMenuItems[y].style.display === 'block') {
                                         subMenuItems[y].setAttribute('style', 'display: none');
                                         // this.state.lastExpandedMenu.map(menuItem => menuItem.setAttribute('style','display: none'));
                                     } else {
                                         subMenuItems[y].setAttribute('style', 'display: block');
                                         // update state lastExpandedMenuList
                                         //    this.setState({lastExpandedMenu: subMenuItems});
                                     }
                                     y += 1;
                                 } while (y < subMenuItems.length);

                             } : (e) => {
                                 this.switchSelection(e);
                                 menuEntry.callback();
                             }}>
                {
                    <div className={'menu-icon'}>
                        {
                            Object.getOwnPropertyNames(menuEntry).includes('icon') ?
                                <FontAwesomeIcon icon={menuEntry.icon}
                                                 style={{height: 20, width: 20}}/> :
                                <LineControl horizontal
                                             style={{
                                                 width: 20,
                                                 height: 20,
                                                 background: 'none'
                                             }}
                                             callback={null}/>
                        }
                    </div>
                }
                <div className={'menu-text'}>
                    {menuEntry.name}
                </div>
            </div>;
            //
            if (!this.state.disabledMenu.includes(menuEntry.name))
                menu.push(entry);
        }
        return menu;
    }

    /**
     *
     * Compile a list of menuItems
     * @param menuEntryList
     *
     */
    compileMenuItems = (menuEntryList = this.state.menuList) => {
        // go through the menuList from state and construct a menu item from the first element
        let k = 0;
        let menuItems = [];
        do {
            menuItems.push(this.constructMenuItem(menuEntryList[k], false, false, k + 1));
            k += 1;
        } while (k < menuEntryList.length);
        this.setState({menuItems: null}, () => {
            this.setState({menuItems: menuItems});
        });
    }
    componentWillUnmount = () => {
        this.sideBar.removeEventListener("mouseover", this.mouseOverCallback, false);
        this.sideBar.removeEventListener("mouseout", this.mouseOverCallback, false);
    }

    /**
     *
     * An event listener for checking presence of classes within
     * By this time, this.sideBar is already known by the component itself
     * @param e the event that fired this mouseOverCallback
     *
     */
    mouseOverCallback = e => {
        //check all styles whether they are on or off
        if (this.props.stretch) {
            // check whether it's stretched. if not, toggle class to stretch
            if (this.sideBar.classList.contains(stretchOff)) {
                this.sideBar.classList.remove(stretchOff);
                this.sideBar.classList.add(stretchOn);
            } else if (this.sideBar.classList.contains(stretchOn)) {
                this.sideBar.classList.remove(stretchOn);
                this.sideBar.classList.add(stretchOff);
            }
        } else if (this.props.slide) {
            // check whether it's stretched. if not, toggle class to stretch
            if (this.sideBar.classList.contains(slideOff)) {
                this.sideBar.classList.remove(slideOff);
                this.sideBar.classList.add(slideOn);
            } else if (this.sideBar.classList.contains(slideOn)) {
                this.sideBar.classList.remove(slideOn);
                this.sideBar.classList.add(slideOff);
            }
        }
    }
    /**
     *
     * draw mounts
     *
     */
    componentDidMount = () => {
        this.compileMenuItems(); // by default, data is gotten from the state's menuList which is assigned from
        this.sideBar = document.getElementById('side-bar');
        // this class's props during construction
        // check whether a stretch has been set
        if (this.props.stretch) {
            this.sideBar.classList.add(stretchOff);
            // set an event listener for window and check where the mouse is relative to the view-port
            this.sideBar.addEventListener('mouseover', this.mouseOverCallback);
            this.sideBar.addEventListener('mouseout', this.mouseOverCallback);

        } else if (this.props.slide) {
            this.sideBar.classList.add(slideOff);
            this.sideBar.addEventListener('mouseover', this.mouseOverCallback);
            this.sideBar.addEventListener('mouseout', this.mouseOverCallback);

        } else {
            // show the menu as is. remove the visibility style from state
            this.sideBar.classList.remove()
            this.setState({visibility_style: null});
        }

    }
    /**
     *
     * render the sidebar menu and submenu
     * @returns {JSX.Element}
     */
    render = () => {
        return <div id={'side-bar'} className={`side-bar`}>
            <div className={'coat-of-arms-parent'}>
                <div className={'coat-of-arms-logo'}/>
            </div>
            {this.state.menuItems}
            <div className={'gms-label-sidebar'}>
                <b>GMS</b> &copy; 2024
                <br/>
                <sub>
                    The betting Control and Licensing Board
                </sub>
            </div>
            <div>

            </div>
        </div>

    }
}

/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = ['menu']; // these are ids and should be handled properly
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
}
export default connect(mapStateToProps)(SideBar);

