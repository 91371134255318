/**
 *
 * Document detail card shows details about a document with the link to download it
 * It accepts a document or file object and get the following itemd:
 *      name
 *      size
 *      type
 *      actual file object as a link
 *
 */
import {Section, SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../labels/SimpleLabel";
import {paraphrase} from "../../../MiscUtils";
import GMS from "../../parent-component/GMS";

export default class DocumentDetailsCard extends GMS {
    constructor(props) {
        super(props);
        //console.log('data ', this.props.documentList)
        this.state = {
            link_doc: '#',
            detailsCards: [],
            fileHeaderData: [],
            documentList: this.props.documentList === undefined || this.props.documentList === null ||
            this.props.documentList === false ? [] : this.props.documentList
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //console.log('next props documentList', nextProps.documentList)
        nextProps.documentList && this.setState({documentList: [...nextProps.documentList]},
            () => {
                let detailsCards = [];
                //console.log('state documents list in component will receive props ', this.state.documentList);
                this.state.documentList.map((document, idx) => {
                    detailsCards.push(this._constructDetailsCard(document, idx));
                });
                this.setState({detailsCards: [...detailsCards]});
            });
    }

    componentDidMount = () => {
        if (this.state.documentList === undefined || this.state.documentList === null)
            return null
        if (this.state.documentList.constructor.name === [].constructor.name
            && this.state.documentList.length === 0)
            return null;
        else {
            let idx = 0;
            let cards = [];
            do {
                let card = this._constructDetailsCard(this.state.documentList[idx], idx);
                if (card !== undefined)
                    cards.push(card);
                //console.log('cards are ', cards)
                idx += 1;
            } while (idx < this.state.documentList.length);
            this.setState({detailsCards: [...cards]});
        }
    }

    /**
     *
     * Construct details card that
     *
     * @param data the list of documents
     * @param idx the document element in question.
     * @returns {JSX.Element} the document card showing the details of the document in question
     * @private a private method for this DocumentDetailsCard
     *
     */
    _constructDetailsCard = (data, idx = 0) => {
        //console.log('data is ',data)
        const drawCard = detailsData => {
            const bytes = (parseInt(detailsData.size) / 977).toFixed(2);
            const bytesClass = String(bytes).split('.')[0].length > 3 ? 'MB' : 'kB';
            const card = <Section className={'document-details-card'}
                                  style={{display: 'flex', flexDirection: 'column'}}>
                <SectionRow style={{borderBottom: '1px solid #b4b5bb'}}>
                    {/*<SectionColumn/>*/}
                    <SectionColumn>
                    <span>
                        <SimpleLabel
                            text={paraphrase(detailsData.name.split('.')[0], this.props.paraphrase ? this.props.paraphrase : 14)}
                            bold
                            fontSize={12}
                            colour={'#3d5fe1'}
                        />
                        <SimpleLabel
                            bold
                            text={`[ ${bytes > 999 ? (bytes / 1000).toFixed(3) : bytes}${bytesClass} ]`}
                            fontSize={10} colour={'#171f2f'}/>
                    </span>
                    </SectionColumn>
                </SectionRow>
                {
                    detailsData.name && <SectionRow>
                        <SectionColumn>
                            <SimpleLabel text={detailsData.name.split('.')[1]}
                                         fontSize={64}
                                         style={{textAlign: 'center'}}
                                         colour={'#9d9b9b'}
                            />
                        </SectionColumn>
                    </SectionRow>
                }
                {
                    !this.props.noDownload && <SectionRow>
                        <SectionColumn style={{margin: '0 auto', cursor: 'pointer'}}>
                            {
                                detailsData.name && this.props.noDownload === undefined && <a href={`${detailsData.name}/`}
                                                       style={{
                                                           textAlign: 'center',
                                                           // background: '#171f2f',
                                                           border: '1px solid #171f2f',
                                                           // color: '#FFFFFF',
                                                           color: '#171f2f',
                                                           borderRadius: 4,
                                                           padding: 2,
                                                           width: 'auto',
                                                           fontSize: '0.8em'
                                                       }}
                                                       download>
                                    Download
                                    {/*<FontAwesomeIcon icon={faFileDownload}*/}
                                    {/*                 style={{*/}
                                    {/*                     border: '1px solid',*/}
                                    {/*                     height: 20,*/}
                                    {/*                     // color: '#8f8f93'*/}
                                    {/*                     color: '#800080'*/}
                                    {/*                 }}*/}
                                    {/*/>*/}
                                </a>}
                        </SectionColumn>
                    </SectionRow>
                }
                {/*<SectionRow style={{borderTop: '1px solid #b4b5bb'}}>*/}
                {/*    <SectionColumn className={'details-col size-in-kb'} style={{width: 80}}>*/}
                {/*        */}
                {/*    </SectionColumn>*/}
                {/*</SectionRow>*/}
            </Section>;
            return card;
            // this.setState({detailsCards: [...this.state.detailsCards, card]}, () => //console.log(this.state.detailsCards.length));
        }
        let detailsData = data;
        if (detailsData instanceof File) {
            return drawCard(detailsData);
        } else if (typeof data === 'string') {
            try {
                // this.getHeaders(encodeURIComponent(`${this.url}${data}`), response => {
                this.getHeaders(`${this.url}${data}`, response => {
                    const p = data.split('/');
                    // console.log('file url ', data.replaceAll('%20', ' '), 'response heaader ', response.headers['content-length']);
                    detailsData = {
                        name: p[p.length - 1].replaceAll('%20', ' '),
                        size: response.headers['content-length']
                    };
                    this.setState({fileHeaderData: [...this.state.fileHeaderData, drawCard(detailsData)]});
                });
            } catch (noFileFound) {
                console.error('Could not fetch file')
            }

        }
    }

    render = () => {
        //console.log('details cards ', this.state.detailsCards, ' header file data ', this.state.fileHeaderData)
        return this.state.detailsCards.length > 0 ? <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gridTemplateRows: 'repeat(2, 150px)',
                    gap: 20,
                    borderTop: '1px solid rgba(136,141,141,0.44)'
                }}
            >{
                this.state.fileHeaderData.length > 0 ? this.state.fileHeaderData : this.state.detailsCards
            }</div> :
            this.state.fileHeaderData.length > 0 ? <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gridTemplateRows: 'repeat(2, 150px)',
                        gap: 20,
                        borderTop: '1px solid rgba(136,141,141,0.44)'
                    }}
                >
                    {
                        this.state.fileHeaderData
                    }
                </div> :
                <div style={{color: 'rgba(136,141,141,0.44)', borderTop: '1px solid rgba(136,141,141,0.44)'}}>
                    No documents found/uploaded
                </div>;
    }
}