/**
 *
 * There is a list of classes representing various operator-view-sections
 * selectable from a side menu.
 * All these classes with a '_' marker are
 * private classes: meaning that they are not tobe accessed
 * outside the ProfileViewer js file.
 *
 */

import {col10, col2, container, row} from "../../../MiscUtils";
import OperatorShareHoldersSection
    from "../../interfaces/component-input-fields/operator-declaration/operator-collector-sections/OperatorShareHoldersSection";

import GMS from "../../parent-component/GMS";
import OperatorContactInfoUpdater from "./view-operator-profile-sections/OperatorContactInfoUpdater";
import OperatorOfficeInfoUpdater from "./view-operator-profile-sections/OperatorOfficeInfoUpdater";
import OperatorCompanyAndTradeNamesInfoUpdater
    from "./view-operator-profile-sections/OperatorCompanyAndTradeNamesInfoUpdater";
import {SUBTITLE} from "../../general-components/redux/allowed-actions";
import {Dispatch} from "../../general-components/redux/app-storage";
import DocumentUploader from "../../general-components/document-viewer/DocumentUploader";

export default class ViewOperatorProfile extends GMS {
    constructor(props) {
        super(props);
        this.currentSubmenuSelection = null;
        this.selectedClassName = "menu-item-selected";
    }

    componentDidMount = () => {
        //
        this.sendRequest('get', 'operatordocuments', data => {
            // attach to dataItem1
            this.setState({categories: data.data});
        });
    };

    closeDocumentUploader = () => {
        this.setState({currentWindowedComponent: null});
    }

    acquireDocuments = (documents = []) => {

    }


    /**
     *
     * Styles current button to immitate current selection.
     * @param e the element that calls this method.
     * Note: this is a repetition of code found in ProfileViewer js component.
     * Find a way of utilitying this method.
     *
     */
    switchSelection = (e = HTMLElement) => {
        if (this.currentSubmenuSelection !== null) {
            this.currentSubmenuSelection.classList.remove(this.selectedClassName);
        }
        this.currentSubmenuSelection = e.target;
        e.target.classList.add(this.selectedClassName);
    };

    /**
     * the user interface
     */

    render = () => {
        return <div className={container}>
            <div className={'space-row'}/>
            <div className={row}>
                <div className={col10}>
                    <div className={'space-row'}/>
                    {this.state.currentWindowedComponent}
                </div>
                <div className={col2}>
                    <div className={`${row} menu-item`}
                         onClick={(e) => {
                             this.switchSelection(e);
                             this.switchProfile(
                                 <OperatorShareHoldersSection
                                 />
                             );
                             Dispatch({
                                 type: SUBTITLE,
                                 payload: "manage my operator-registration's shareholders information"
                             })
                         }}>

                        Directors/Shareholders
                    </div>
                    <div className={`${row} menu-item`}
                         onClick={(e) => {

                             this.switchSelection(e);
                             this.switchProfile(
                                 <DocumentUploader
                                     upload={this.sendRequest}
                                     categories={this.state.categories}
                                     documents={this.state.dataItem1}
                                     callback={this.closeDocumentUploader}
                                     uploadcallback={this.acquireDocuments}
                                     asFileObjects
                                 />
                             );
                             Dispatch({type: SUBTITLE, payload: "manage my operator-registration's documents"});
                         }}>

                        documents
                    </div>
                    <div className={`${row} menu-item`}
                         onClick={(e) => {

                             this.switchSelection(e);
                             this.switchProfile(
                                 <OperatorContactInfoUpdater/>
                             );
                             Dispatch({
                                 type: SUBTITLE,
                                 payload: "manage my operator-registration's contact information"
                             })
                         }}>

                        contact info
                    </div>
                    <div className={`${row} menu-item`}
                         onClick={(e) => {

                             this.switchSelection(e);
                             this.switchProfile(
                                 <OperatorOfficeInfoUpdater/>
                             );
                             Dispatch({type: SUBTITLE, payload: "manage my operator-registration's office location"})
                         }}>

                        office location
                    </div>
                    <div className={`${row} menu-item`}
                         onClick={(e) => {

                             this.switchSelection(e);
                             this.switchProfile(
                                 <OperatorCompanyAndTradeNamesInfoUpdater/>
                             );
                             Dispatch({type: SUBTITLE, payload: "manage my operator-registration's trading names"})
                         }}>

                        trade name
                    </div>
                </div>
            </div>
        </div>;
    };
}

