import GMS from "../../parent-component/GMS";
import {SectionColumn, SectionRow} from "../view-utils/SectionUtils";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import {notify} from "../../../MiscUtils";
import TextAreaField from "../../general-components/input-field/TextAreaField";
import SimpleLabel from "../../general-components/labels/SimpleLabel";
import {render} from "@testing-library/react";

/**
 *
 * Show a respond to an issue using a floating window.
 *
 */
export default class MakeResponse extends GMS {
    constructor(props) {
        super(props);
        this.element = props.element && props.element;
    }

    render = () => {
        return <SectionRow
            style={{position: 'fixed', left: '16%', width: '70vw', top: 'inherit', background: '#FFFFFF'}}>
            <SectionColumn>
                {
                    this.element && <SectionRow>
                        <SectionColumn style={{width: '7vw'}}/>
                        <SectionColumn>
                            <SimpleLabel text={'Response to issue no'} bold withColon/>
                            <SimpleLabel text={this.element.id} colour={'#800080'} bold/>
                        </SectionColumn>
                    </SectionRow>
                }
                <SectionRow>
                    <SectionColumn style={{width: '10vw'}}/>
                    <SectionColumn>
                        <TextAreaField bold
                                       fontSize={14}
                                       noResize
                                       isRequired
                                       name={'response'}
                                       style={{height: '60vh', width: '65vw'}}
                                       placeholder={''}
                                       changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                    <SectionColumn style={{width: '27vw'}}/>
                </SectionRow>
                <div className={'big-space-row'}/>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn>
                        <SubmitButton commandText={'Respond'}
                                      callback={() => {
                                          //console.log(this.state.e['response'].length > 0)
                                          if (this.state.e['response'].length > 0)
                                              this.sendRequest('post',
                                                  `helpdesk/comment/${this.element['id']}`,
                                                  response => {
                                                      //console.log(response)
                                                      if (response.priority === 4)
                                                          notify(response.message, 4, true);
                                                      else
                                                          notify(response.message, 2, true);
                                                  }, {text: this.state.e['response']});
                                          else {
                                              render(null, document.getElementById('overlay'));
                                              notify(`No response to issue No: ${this.element['id']}`,
                                                  1, true);
                                          }

                                      }}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                </SectionRow>
            </SectionColumn>
        </SectionRow>
    }
}