import GMS from "../../../../parent-component/GMS";
import {Section, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import DynamicTable from "../../../../general-components/tables/DynamicTable";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {SUBTITLE, VIEWABLE_ITEM} from "../../../../general-components/redux/allowed-actions";

/**
 *
 * List my employees. Get the operator, get the operator_employees data
 *
 */
export default class EmployeeList extends GMS {
    constructor(props) {
        super(props)
    }

    componentDidMount = () => {
        Dispatch({
            type: SUBTITLE,
            payload: "List employees"
        });
        let t = 0;
        let a = {};
        let y = [];
        this.sendRequest('get', 'company/operator/employees', employees => {
            if (employees.data.length > 0) {
                if (employees.type === 'Success') {
                    if (employees.data.length > 0) {
                        do {
                            a = {};
                            a['id'] = employees.data[t]['id'];
                            a['employee_name'] = `${employees.data[t]['e_first_name']} ${employees.data[t]['e_middle_name']} ${employees.data[t]['e_surname']}`;
                            a['gender'] = employees.data[t]['gender'];
                            a['email'] = employees.data[t]['e_email'];
                            a['contact'] = employees.data[t]['e_phone_number'];
                            a['nationality'] = employees.data[t]['e_nationality'];
                            a['station'] = employees.data[t]['e_station'];
                            a['work_permit'] = employees.data[t]['work_permit_number'];
                            a['national_id'] = employees.data[t]['e_national_id'];
                            a['tax_pin'] = employees.data[t]['e_pin_number'];
                            a['declaration_date'] = employees.data[t]['date_created'];
                            a['contact_person'] = employees.data[t]['is_contact_person'] === true ? 'Yes' : 'No';
                            y.push(a);
                            t += 1;
                        }
                        while (t < employees.data.length) ;
                        this.setState({dataItem3: [...y]});
                    }
                }
            }
        }, {});
    }

    render = () => {
        return <Section>
            {
                !this.props.noTitle && <span>
                    <SectionTitle>
                List of employees
            </SectionTitle>
            <SectionRow>
                <SectionTitle>
                    Active
                </SectionTitle>
            </SectionRow>
                    </span>
            }
            <SectionRow>
                <DynamicTable
                    // noSearch
                    // noParaphrase
                    context={'employees'}
                    pageSize={12}
                    columnsOfInterest={[
                        'id',
                        'employee_name',
                        // 'nationality',
                        'email',
                        // 'gender',
                        'contact_person'
                    ]}
                    tableData={this.state.dataItem3}
                    showRowData={employee => {
                        Dispatch({type: VIEWABLE_ITEM, payload: employee});
                        this.navigateTo('../employee-view',
                            false,
                            {row: employee})
                    }}

                />
            </SectionRow>
            <SectionRow/>
            {/*{this.state.dataItem4.length > 0 && <>*/}
            {/*    <SectionRow>*/}
            {/*        <SectionTitle>*/}
            {/*            inactive*/}
            {/*        </SectionTitle>*/}
            {/*    </SectionRow>*/}
            {/*    <SectionRow>*/}
            {/*        <SectionColumn>*/}
            {/*            <DynamicTable*/}
            {/*                noSearch*/}
            {/*                context={'shoulders'}*/}
            {/*                pageSize={8}*/}
            {/*                tableData={this.props.separateView && this.state.dataItem4}*/}
            {/*                showRowData={shareholder => {*/}
            {/*                    Dispatch({type: MISC_ID, payload: shareholder});*/}
            {/*                    this.navigateTo('../shareholder-view', false, {row: shareholder})*/}
            {/*                }}*/}
            {/*                columnsOfInterest={[*/}
            {/*                    'e_first_name',*/}
            {/*                    'e_middle_name',*/}
            {/*                    'e_phone_number',*/}
            {/*                    'e_national_id',*/}
            {/*                    // 'e_shares']}*/}
            {/*                    'e_company_shares']}*/}
            {/*            />*/}
            {/*        </SectionColumn>*/}
            {/*    </SectionRow></>}*/}

            {this.state.currentRoute}
        </Section>
    }
}