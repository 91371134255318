import React from "react";
import Button from "./base/Button";
import "./base/button-control.css";

/**
 *
 * Component wraps the form button and provides default functionality
 * properties include
 *  commandText - a string
 *  callback - callback method to execute
 *
 */
export default class SubmitButton extends Button {
    constructor(props) {
        super(props);
        this.callback = props.callback;
        this.commandText = props.commandText;
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        // super.UNSAFE_componentWillReceiveProps(nextProps, nextContext);
        this.commandText = nextProps.commandText;
    }

    render = () => {
        return (
            this.props.icon ? <div className={'btn btn-with-icon'}>
                    <div className={'button-icon'}>
                        {this.props.icon}
                    </div>
                    <button
                        disabled={this.props.disabled === undefined ? false : this.props.disabled}
                        type={"button"}
                        className={"btn submit-buttons-with-icon"}
                        onClick={this.callback}
                        style={{
                            ...this.style,
                        }}
                    >
                        {
                            this.commandText === undefined ? `Submit` : this.commandText
                        }
                    </button>
                </div>
                :
                <button
                    disabled={this.props.disabled === undefined ? false : this.props.disabled}
                    className={"btn submit-buttons"}
                    style={{
                        ...this.style,
                    }}
                    onClick={this.callback}
                >
                    {this.commandText === undefined ? `Submit` : this.commandText}
                </button>
        );
    };
}
