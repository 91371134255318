import GMS from "../../../parent-component/GMS";
import {connect} from "react-redux";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../../../general-components/labels/SimpleLabel";
import React from "react";
import TextField from "../../../general-components/input-field/TextField";
import SubmitButton from "../../../general-components/controls/buttons/SubmitButton";
import {notify} from "../../../../MiscUtils";
import DocumentUploader from "../../../general-components/document-viewer/DocumentUploader";
import {SUBTITLE} from "../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../general-components/redux/app-storage";

/**
 *
 * License renewal
 *
 */
class LicenseRenewal extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // get categories for license renewal documents
        Dispatch({
            type: SUBTITLE,
            payload: `${this.props.renewalInfo.game_type} ${this.props.renewalInfo.application_type} renewal`
        });
        this.sendRequest('get', 'selections/licensedocuments', docuemnts => {
            if (docuemnts.type === 'Success') {
                this.setState({dataItem1: [...docuemnts.data]});
            }
        }, {});
        // Dispatch({type:SUBTITLE, payload: `Apply for ${this.props.renewalInfo.game_type} ${this.props.renewalInfo.application_type} renewal`})
    }

    render = () => {
        // load data from
        return <Section>
            <SectionTitle>
                Apply for renewal of this {this.props.renewalInfo.game_type} {this.props.renewalInfo.application_type}
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    <SimpleLabel text={'Application for renewal of '} fontSize={14} bold capitalize/>
                    <SimpleLabel
                        text={this.props.renewalInfo ? this.props.renewalInfo['application_type'] : '------'}
                        fontSize={14} colour={'#'}/>
                </SectionColumn>
                <SectionColumn>
                    <SimpleLabel text={'Owner'} fontSize={14} bold capitalize/>
                    <SimpleLabel
                        text={this.props.renewalInfo ? this.props.renewalInfo['operator_name'] : '------'}
                        fontSize={14} colour={'#'}/>
                </SectionColumn>
                {this.props.renewalInfo['issuance_number'] && <SectionColumn>
                    <SimpleLabel text={'Previous license number'} fontSize={14} bold capitalize/>
                    <SimpleLabel
                        text={this.props.renewalInfo && this.props.renewalInfo['issuance_number'] ? this.props.renewalInfo['issuance_number'] : '------'}
                        fontSize={14} colour={'#'}/>
                </SectionColumn>}
                <SectionColumn>
                    <SimpleLabel text={'Last issuance date'} fontSize={14} bold capitalize/>
                    <SimpleLabel
                        text={this.props.renewalInfo && this.props.renewalInfo['issuance_date'] ? this.props.renewalInfo['issuance_date'] : '------'}
                        fontSize={14} colour={'#'}/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <SimpleLabel text={'That expired on'} fontSize={14} bold capitalize/>
                    <SimpleLabel
                        text={this.props.renewalInfo ? this.props.renewalInfo['expiry_date'] : '------'}
                        fontSize={14} colour={'#'}/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn boxedColour>
                    <SectionTitle>License number</SectionTitle>
                    <SectionRow>
                        <SectionColumn>
                            <SimpleLabel
                                text={'Type-in the license number on the license leaflet issued to you for the year ' +
                                '2023/2024 gaming year.'}
                                fontSize={14}
                                colour={'#111c40'}/>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            {/*{this.props.renewalInfo['issuance_number'] && this.props.renewalInfo['issuance_number'].length < 3 &&*/}
                            <TextField name={'issuance_number'}
                                       fontSize={14}
                                       bold
                                       isRequired
                                       placeholder={'Type in the previous license number'}
                                       changeCallback={e => this.collectFieldData(e, true)}
                            />
                            {/*}*/}
                        </SectionColumn>
                        <SectionColumn/>
                        <SectionColumn/>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn boxedColour>
                    <SectionTitle>Any Proposed amendments?</SectionTitle>
                    <SectionRow>
                        <SectionColumn>
                            <SimpleLabel
                                text={<span>If there are any amendments to the information concerning the items listed below,
                                click on the link to make the adjustments<br/>
                                    <br/>
                                    Once you are done with them, navigate back
                                 to this renew page and proceed to finalize the renewal application.
                                </span>
                                }
                                fontSize={14}
                            />
                            <SimpleLabel
                                text={'In the event that there is no information to amend proceed to upload the documents in the section below.'}
                                colour={'#ea2846'}
                                bold
                                fontSize={14}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        {this.state.currentRoute}
                        <SectionColumn>
                            {/*<SimpleLabel text={'employees'} capitalize bold fontSize={16}/>*/}
                            <br/>
                            <ol>
                                <li>
                                    <a href={'#'} onClick={() => {
                                        this.navigateTo('../declare-employees')
                                    }}>Update your employees list</a>
                                </li>
                                <li>
                                    <a href={'#'} onClick={() => {
                                        this.navigateTo('../declare-office-premise/layout-and-dimensions')
                                    }}>Declare (alternative) premises</a>
                                </li>
                                <li>
                                    <a href={'#'} onClick={() => {
                                        this.navigateTo('../upload-returns')
                                    }}>Upload returns</a>
                                </li>
                                <li>
                                    <a href={'#'} onClick={() => {
                                        this.navigateTo('../declare-gateway')
                                    }}>Declare payment gateways</a>
                                </li>
                            </ol>
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <SectionRow boxedColour>
                        <SectionColumn>
                            {/*// do some work on getting categories for license renewal applications.*/}
                            {
                                this.state.dataItem1.length > 0 && <DocumentUploader context={'license'}
                                                                                     categories={this.state.dataItem1}
                                                                                     upload={this.sendRequest}
                                />
                            }
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn/>
                <SectionColumn>
                    &ensp;
                    <SubmitButton commandText={'Apply for renewal'}
                                  callback={() => {
                                      this.sendRequest('post', `company/operator/license/license/?previous_license_id=${this.props.renewalInfo.id}`,
                                          response => {
                                              if (response.type === 'Success') {
                                                  notify(`License no ${this.state.e['issuance_number']}`,
                                                      4,
                                                      false,
                                                      'Renewal application successful');
                                                  window.setTimeout(() => this.navigateTo('../list-renewal-applications'), 3000);
                                              } else
                                                  notify(response.data[0].msg, 2, false, 'Application for Renewal Failed');

                                              // }, {...this.state.e, application_status: 3});
                                          }, {...this.state.e, status: 8});
                                  }}/>
                    &ensp;
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>
        </Section>
    }
}

const stateToProps = initialState => {
    let p = {};
    // p['renewalInfo'] = initialState.task_object;
    p['renewalInfo'] = initialState.misc_id;
    return p;
}
export default connect(stateToProps)(LicenseRenewal);