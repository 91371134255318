import "./main-page.css";

import {connect} from "react-redux";
import GMS from "../GMS";
import React, {Component} from "react";

/**
 *
 * A utility class for checking appropriate routing conditions
 */
class _RouterCheck extends Component {
    constructor(props) {
        super(props)
    }

    render = () => {
        // also check if logout has happened
        // fetch state from local storge through the readLocalStorage method of GMS
        // <Navigate to={'/dashboard'} replace={true}/>
        // <Navigate to={'/representative'} replace={true}/>
        return this.props.email ? this.props.navigateTo('/dashboard', true) :
            this.props.navigateTo('/representative', true)
    }
}

/**
 *KUP, payload: {} });
 // set title
 // like conversation
 let likeConversation = true;

 * DefaultContainer draws
 *
 */
class DefaultContainer extends GMS {
    constructor(props) {
        super(props);
        // get local storage data
        // this.localStorageData = getStorageObject(BCLB); // get the entire storage as a json object
    }

    /**
     *
     * Minor updates to system
     * @param nextProps
     * @param nextContext
     * @constructor
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.role)
            this.setState({loggedIn: true, role: nextProps.role});
        else
            this.setState({loggedIn: false});
    }

    /**
     *
     *
     * Render routes that are to be used within the default container that hosts the main dashboard component.
     *
     * @returns {JSX.Element}
     */
    render = () => {
        // check for a heart beat by calling operator and check if the return has the key 'detail. if so, navigate to logout'
        return <div>
            {this.state.currentRoute}
            {this.props.email && this.props.role ? this.navigateTo('/dashboard', true) :
                this.navigateTo('/representative', true)}
            {/*<_RouterCheck navigateTo={this.navigateTo} email={this.props.email} role={this.props.role}/>*/}
        </div>
    }
}


/**
 *
 * MapViewer all state that is recorded in local storage to the state of the current component.
 * Any class that wields this state (through inheritance) has access to the mapped
 * state from local-storage.
 *
 * @param localStorageState the state as recorded in the local storage
 * Do not connect the parent class because connected classes MUST NEVER BE SUBCLASSED!
 *
 */
// load data from redux and populate it into the GMS state. Licensor is not used anywhere else
const mapStateToProps = (localStorageState) => {
        let
            props = {};
        const itemsOfInterest = ["role", "email"];

        for (const item of itemsOfInterest) {
            props[item] = localStorageState[item];
        }

        return props; //seems there are efficiency problems here...
    }
;

// connect will return the GMS having been connected to the local storage.
// find a way for inheriting a connected component...
// learned from https://daveceddia.com/redux-connect-export/ (does not work!)
// this is a wrapped component with the GMS as part of it...
//
// Note: Connected components_to_delete MUST NEVER BE SUB-CLASSED!
//                      :: https://www.scrivito.com/connect-component-1c81c8d3b87493af
//
export default connect(mapStateToProps)(DefaultContainer); //component must have a render method
