import {notify, readFromLocalStorage} from "../../../MiscUtils";
import {BCLB} from "../../general-components/redux/allowed-actions";
import {
    faArrowsSpin,
    faBook,
    faBoxArchive,
    faBriefcase,
    faBuilding,
    faCashRegister,
    faCertificate,
    faClipboardList,
    faDriversLicense,
    faEye,
    faFolderOpen,
    faHandsHelping,
    faInfo,
    faList,
    faMoneyBillTransfer,
    faPen,
    faPeopleLine,
    faPerson,
    faPlus,
    faQuestion,
    faUpload,
    faUsersBetweenLines
} from "@fortawesome/free-solid-svg-icons";

/**
 *
 * This class consists of menu items that are represented.
 * The Menu used looks like this list below
 *
 * MenuList structure
 * [
 *      {
 *          name: menu_name1,
 *          menu:{
 *              submenu1:
 *                  {
 *                      icon: BLOB1_A,
 *                      callback: callback
 *                  },
 *              submenu2:
 *                  {
 *                      icon: BLOB1_B,
 *                      callback: callback
 *                  },
 *                  ...
 *          },
 *          icon: BLOB1,
 *          callback: CALLBACK
 *      },
 *      ...
 * }]
 *
 *
 */
export default class MenuList {
    // have static methods
    static _callback = (menuName) => {
        const message = `No action is defined/issued for the menu-item "${menuName}"`;
        //console.log(message);
        console.warn(message);
        notify(message, 3, true);
    };

    static getAppropriateMenu = (role, callBacksList = [], operatorStatus) => {
        const rolesAndMenuReps = {
            4: MenuList.representativeMenuList,
        }
        //
        // return the menu dynamically
        //
        // Note: All of them have this. Only representatives can apply for companies but only once
        // (so long as the representative does not represent anyone at that time).
        //
        return rolesAndMenuReps[role](callBacksList, operatorStatus);
    }

    /**
     *
     * A general-components_to_delete menu list that is used across various users.
     *
     * This MenuList object consists of:
     *  1. Licenses and Permits
     *      a. apply for a new license
     *      b. view Licenses (by default it shows a table of licenses) - it will also track changes to this license
     *  2. My Operators
     *      a. view a operator-registration (subject to the operator-registration still being existent/active within the system)
     *      b. create new operator-registration (by default, subject to the current representative not representing a operator-registration.
     *         NOTE: This sub-menu can be turned off by passing true to this static method call
     *
     * NOTE 1 -> : In the event that respective callbacks are not issued, the menu will //console.log or notify?
     *              Go with notify for now...
     * NOTE 2 -> : In the event that <owner>MenuList defines a larger set of menu items together with the
     *              generalMenuList, one may return a spread of their custom menu with a spread of generalMenuList output:
     *
     *              return {...MenuList.generalMenuList(),...<yourCustomMenuListMethodCallOutput()>}
     *
     * NOTE 3 -> : If a main menu has a child menu, the Sidebar ignores its callback (for now).
     *
     * @param callBacksList (Array) a List  callbacks to fire when the menu is clicked or activated
     * @param doNotShowApplyCompanySubMenu (boolean) indicate whether a operator-registration exists and therefore
     *          the representative or bclb does not need to show the 'apply sub-menu' in the main menu hierarchy.
     *          Defaults to 'do not show this sub menu' (ie, false)
     * @returns {[]} a array of menu and submenu objects
     *
     *
     */
    static generalMenuList = (callBacksList = [], doNotShowApplyCompanySubMenu = false) => {
        // check whether the representative is the one logged in
        const role = readFromLocalStorage('role', BCLB);
        // check whether they represent  a company

        // calculate if the declare operator option should be part of the menu.
        // check whether the current user role is 4 and operator is set. if true, remove it
        return [{
            name: 'Operator',
            icon: faBriefcase,
            // callback: () => {
            //     callBacksList.length > 0 ? callBacksList[0]() : MenuList._callback('Operator')
            // },
            menu: [
                //     {
                //     name: 'Declare Operator',
                //     icon: faPlus,
                //     callback: () => {
                //         callBacksList.length > 0 ? callBacksList[2]() : MenuList._callback('Declare Operator')
                //     }
                // },
                {
                    name: role === 4 ? 'View operator' : 'View Operators',
                    icon: faEye,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[0]() : MenuList._callback('View operators')
                    }
                },

            ]
        }, {
            name: 'Licenses and Permits',
            icon: faCertificate,
            menu: [
                {
                    name: 'Apply for License',
                    icon: faDriversLicense,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[1]() : MenuList._callback('Apply for License')
                    }
                },
                //     {
                //     name: 'Apply for Renewals',
                //     icon: faDriversLicense,
                //     callback: () => {
                //         callBacksList.length > 0 ? callBacksList[24]() : MenuList._callback('Apply for License renewal')
                //     }
                // },
                {
                    name: 'Apply for permit',
                    icon: faDriversLicense,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[2]() : MenuList._callback('Apply for permit')
                    }
                },
                // {
                //     name: 'Apply for shops',
                //     icon: faDriversLicense,
                //     callback: () => {
                //         callBacksList.length > 0 ? callBacksList[3]() : MenuList._callback('Apply for shops')
                //     }
                // },
                {
                    name: 'View applications',
                    icon: faList,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[4]() : MenuList._callback('View applications')
                    }
                },
            {
                    name: 'View renewal apps.',
                    icon: faArrowsSpin,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[callBacksList.length - 1]() : MenuList._callback('View renewal applications')
                    }
                }]
        },
        ];
    }


    static chatMenu = (callBacksList) => {
        return [{
            name: 'Chat',
            icon: faPen,
            callback: () => {
                callBacksList.length > 0 ? callBacksList[callBacksList.length - 1]() : MenuList._callback('chat')
            }
        }]
    }

    /**
     *
     * This is a menu list that is specific to the representative (see generalMenuList above).
     * Filter the menu callbacks based on the presence of operators
     *              (only allow the showing of "declare operator" menu if operator is absent)
     *  or list operator if the operator exists but their status is not active.
     * @param callBacksList
     * @param operatorStatus
     * @returns {{}}
     */
    static representativeMenuList = (callBacksList = [], operatorStatus) => {
        //console.log('Showe view operator only ', showViewOperatorOnly);
        // read redux and get the person logged in (operator) and check the company they represent
        // if operator, do not show apply company sub menubar
        if (operatorStatus === 'Active')
            return [
                ...this.generalMenuList(callBacksList, true),
                {
                    name: 'Shareholders',
                    icon: faPerson,
                    menu: [
                        {
                            name: 'new shareholder',
                            icon: faPlus,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[5]() : MenuList._callback('new shareholder');
                            }
                        },
                        // , {
                        //     name: 'Amend info',
                        //     icon: faPlus,
                        //     callback: () => {
                        //         callBacksList.length > 0 ? callBacksList[6]() : MenuList._callback('Amend shareholders');
                        //     }
                        // },
                        {
                            name: 'List shareholders',
                            icon: faList,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[7]() : MenuList._callback('shareholder list');
                            }
                        },
                    ]
                },
                {
                    name: 'Beneficiary Owners',
                    icon: faUsersBetweenLines,
                    menu: [
                        {
                            name: 'Declare BO',
                            icon: 'some-menu',
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[25]() : MenuList._callback('declare BOF member');
                            }
                        },
                        {
                            name: 'List BOFs',
                            icon: 'some-menu',
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[26]() : MenuList._callback('BOF list');
                            }
                        },
                    ]

                },
                {
                    name: 'Employees',
                    icon: faPeopleLine,
                    menu: [{
                        name: 'Declare employees',
                        icon: faPerson,
                        callback: () => {
                            callBacksList.length > 0 ? callBacksList[11]() : MenuList._callback('Declare employees');
                        }
                    },
                        {
                            name: 'List employees',
                            icon: faList,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[12]() : MenuList._callback('list employees');
                            }
                        }]

                },
                {
                    name: 'Premises',
                    icon: faBuilding,
                    menu: [{
                        name: 'Declare Premise',
                        icon: faPlus,
                        callback: () => {
                            callBacksList.length > 0 ? callBacksList[8]() : MenuList._callback('new office premise');
                        }
                    },
                        {
                            name: 'List premises',
                            icon: faList,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[10]() : MenuList._callback('list premises');
                            }
                        }
                    ]
                },
                // {
                //     name: 'Odds provider',
                //     icon: faCloudRain,
                //     menu: [{
                //         name: 'Declare',
                //         icon: faPerson,
                //         callback: () => {
                //             callBacksList.length > 0 ? callBacksList[13]() : MenuList._callback('Declare odds providers');
                //         }
                //     },
                //         {
                //             name: 'List providers',
                //             icon: faList,
                //             callback: () => {
                //                 callBacksList.length > 0 ? callBacksList[14]() : MenuList._callback('list odds providers');
                //             }
                //         }]
                // },
                {
                    name: 'Returns',
                    icon: faCashRegister,
                    menu: [{
                        name: 'Upload Tx Returns',
                        icon: faUpload,
                        callback: () => {
                            callBacksList.length > 0 ? callBacksList[15]() : MenuList._callback('file tranxactional returns');
                        }
                    },
                        {
                            name: 'list Tx filed',
                            icon: faList,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[16]() : MenuList._callback('list tranxactional returns filed');
                            }
                        }]
                },
                {
                  // name:'Payment Gateways',
                  name:'Paybills',
                  icon:faMoneyBillTransfer,
                  menu:[{
                      // name:'Declare gateway',
                      name:'Declare',
                      icon:'some-icon',
                      callback:() => callBacksList.length > 0 ? callBacksList[27]() : MenuList._callback('Declare payment gateways')

                  },
                      {
                      // name:'List gateways',
                      name:'List declarations',
                      icon:'some-icon',
                      callback:() => callBacksList.length > 0 ? callBacksList[28]() : MenuList._callback('list payment gateways')

                  },
                  ]
                },
                // {
                //     name: 'No objections',
                //     icon: faFolderOpen,
                //     callback: () => {
                //         callBacksList.length > 0 ? callBacksList[17]() : MenuList._callback('No objections');
                //     }
                // },
                {
                    name: 'Payments',
                    icon: faFolderOpen,
                    menu: [{
                        name: 'List payments',
                        icon: faUpload,
                        callback: () => {
                            callBacksList.length > 0 ? callBacksList[18]() : MenuList._callback('list all payments made thus far');
                        }
                    }, {
                        name: 'Service charter',
                        icon: faClipboardList,
                        callback: () => {
                            callBacksList.length > 0 ? callBacksList[19]() : MenuList._callback('Show service charter');
                        }
                    }]
                },
                {
                    name: 'Correspondences',
                    icon: faBook,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[20]() : MenuList._callback('View correspondences')
                    }
                },
                {
                    name: 'Help & Support',
                    icon: faHandsHelping,
                    menu: [
                        // {
                        //     name: 'Contacts',
                        //     icon: faContactBook,
                        //     callback: () => {
                        //         callBacksList.length > 0 ? callBacksList[19]() : MenuList._callback('View contact information')
                        //     }
                        // },
                        {
                            name: 'FaQ',
                            icon: faQuestion,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[21]() : MenuList._callback('View frequently asked questions')
                            }
                        },
                        {
                            name: 'Glossary',
                            icon: faInfo,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[22]() : MenuList._callback('View glossary of terms/definition of terms')
                            }
                        },

                        {
                            name: 'User manual',
                            icon: faBook,
                            callback: () => {
                                callBacksList.length > 0 ? callBacksList[23]() : MenuList._callback('View user manual') // downloadable link included
                            }
                        },
                    ]
                }
                // ...MenuList.chatMenu(callBacksList)
            ];
        else if (callBacksList.length === 1 && operatorStatus === null) {
            return [{
                name: 'Declare Operator',
                icon: faPlus,
                callback: () => {
                    callBacksList.length > 0 ? callBacksList[0]() : MenuList._callback('Declare Operator')
                }
            }]
        } else
            return [
                {
                    name: 'View operator',
                    icon: faEye,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[0]() : MenuList._callback('View operators')
                    }
                },
                {
                    name: 'Correspondences',
                    icon: faBook,
                    callback: () => {
                        callBacksList.length > 0 ? callBacksList[1]() : MenuList._callback('View correspondences')
                    }
                },
                // {
                //     name: 'new shareholder',
                //     icon: faPlus,
                //     callback: () => {
                //         callBacksList.length > 0 ? callBacksList[2]() : MenuList._callback('new shareholder');
                //     }
                // }
                //     {
                //     name: 'Apply for License',
                //     icon: faDriversLicense,
                //     callback: () => {
                //         callBacksList.length > 0 ? callBacksList[1]() : MenuList._callback('Apply for License')
                //     }
                // },
                //     {
                //         name: 'View Licenses',
                //         icon: faList,
                //         callback: () => {
                //             callBacksList.length > 0 ? callBacksList[2]() : MenuList._callback('View Licenses')
                //         }
                //     }
            ];
    }
    /**
     *
     * Upload documents menu item.
     * @returns {[{name: string, icon: IconDefinition, menu: [{name: string, icon: IconDefinition, callback: callback}]}]}
     *
     */
    uploadDocumentsMenuList = (callBacksList) => {
        return [{
            // documents upload
            name: 'documents',
            icon: faBoxArchive,
            menu: [{
                name: 'upload documents',
                icon: faUpload,
                callback: () => {
                    callBacksList.length > 0 ? callBacksList[1]() : MenuList._callback('Upload documents')
                }
            }]
        }]
    }
}