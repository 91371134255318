/**
 *
 * Shows a list of shops and outlets managed by given operators. It also provides a mechanism for adding/managing shops
 * (add, remove, include machine, include service, remove the former two)
 *
 * The fields required for view (by DynamicTable component) include but may not be limited to:
 *   - outlet id(by company)
 *   - location
 *   - running license no
 *   - running which game(s)
 *   - instruments available in that shop/outlet
 *   - whether it's active
 *
 */
import GMS from "../../../parent-component/GMS";
import {Section, SectionRow, SectionTitle} from "../../view-utils/SectionUtils";
import DynamicTable from "../../../general-components/tables/DynamicTable";
import {Dispatch} from "../../../general-components/redux/app-storage";
import {PREV_ROUTE} from "../../../general-components/redux/allowed-actions";

export default class ShopsOutletsList extends GMS {
    componentDidMount = () => {
        // fetch shops list from server or data that has been buffered in redux...?
        // update miscellaneousItems state variable
    }

    render = () => {
        // show a dynamic table with these data items in question.
        // provide a means for
        return <Section>
            <SectionTitle>
                List of premises/shops
            </SectionTitle>
            <SectionRow>
                {
                    this.state.miscellaneousItems ?
                        // <SectionColumn>
                        <DynamicTable context={'outlets/premises'}
                                      tableData={this.state.miscellaneousItems}
                                      columnsOfInterest={['outlet_id', 'license_no', 'location', 'active']}
                                      showRowData={(premiseData) => {
                                          Dispatch({
                                              type: PREV_ROUTE,
                                              payload: `outlets-premises-view/${premiseData.id}`
                                          })
                                          // window.alert('show row data for shops list')
                                      }}/>
                        // </SectionColumn>
                        : 'no data on offices/premises/shops'
                }</SectionRow>
        </Section>
    }
}