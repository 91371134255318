import "./registrations.css";
import React from "react";
import {connect} from "react-redux";
import OperatorBioSection from "./operator-collector-sections/OperatorBioSection";
import OperatorOriginLocation from "./operator-collector-sections/OperatorOriginLocation";
import IntroductoryProposal from "./operator-collector-sections/IntroductoryProposal";
import {Section, SectionColumn, SectionRow} from "../../../rotm-custom-views/view-utils/SectionUtils";
import {ROUTEINDEX, SUBTITLE} from "../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../general-components/redux/app-storage";
import Navigation from "../../../general-components/controls/svg-controls/Navigation";


import './operator-registration-collectors-css.css';
import '../../../rotm-custom-views/custom-views-css.css';
import {Route, Routes} from "react-router-dom";
import GMS from "../../../parent-component/GMS";
import CurrentOperations from "./operator-collector-sections/CurrentOperations";
import OperatorShareHoldersSection from "./operator-collector-sections/OperatorShareHoldersSection";
import OperatorSummary from "./operator-collector-sections/OperatorSummary";
import {arrayOfJSONsToJSON, notify} from "../../../../MiscUtils";

/**
 *
 * Operator registration is a class used to describe registration of operator-registration
 * companies by their agent.
 * Properties:
 *  updateTitle - callback method provided by the DefaultContainer component for updating titles
 *  updateTitleContext - callback method provided by the same component in order to set
 *  the context-variables title portion of the main title (This is set in a different colour).
 *
 */
class OperatorRegistration extends GMS {
    constructor(props) {
        super(props);
        this.floatingWindowRef = React.createRef(); //for calling on the floating window to update on child state change
        this.floatWindow = props.floatWindow; //this is a reference to a method in the DefaultContainer
        ///////////////////////////////////////
        this.navRoutes = [
            {'operator-introduction': ['proposal']}, // proposal for company
            {'operator-bio-section': [/*'company_type', 'company_name', 'local_business_name', 'local_company_reg_number', 'operator_phone_number', 'operator_postal_address', 'operator_zip_code'*/]}, // bio section
            {'operator-shareholders': [/*'operator_shareholders'*/]}, // operator shareholders
            // {'operator-origin-location': [/*'county', 'town', 'street', 'floor', 'plot_no', 'coordinates', 'building_name', 'local_website_address'*/]}, // location of hq and parent company
            {'current-business-operations': [/*'game_type'*/]}, // current operations by company
            // 'operator-odds-providers', // shops and outlets. Show a message that these are not franchises. continue
            // 'mapper', // shops and outlets. Show a message that these are not franchises. continue
            // 'operator-employees', // list employees
            {'finalize-operator-registration': []}]; // finalize declaration
        Dispatch({
            type: SUBTITLE,
            payload: "Open an account for a new operator-registration/operator-registration"
        });
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        // props will host data about storage
        this.setState({e: nextProps.LOCAL_BACKUP, dataItem1: nextProps.blur_fields ? [...nextProps.blur_fields] : []});
    }

    /**
     * This property allows the setting of mandatory fields in context of the LicenseApplication
     * component that routes to components_to_delete within its route hierarchy
     */
    setMandatoryFields = (mandatoryFields) => {
        this.setState({mandatoryFields: [...mandatoryFields]});
    }
    /**
     *
     * Sets the main title
     *
     */
    componentDidMount = () => {
        // Dispatch({type:NULL_BACKUP})
        this.setState({dataItem2: [...Object.getOwnPropertyNames(arrayOfJSONsToJSON(this.navRoutes))]})
        if (this.state.currentRouteIndex > 0 && this.state.currentRouteIndex)
            this.setState({showForwards: true, showBackwards: true});
        else if (this.state.currentRouteIndex === 0)
            this.setState({showBackwards: false, showForwards: true});
        else
            this.setState({showBackwards: true, showForwards: false});
        this.navigateTo(Object.getOwnPropertyNames(this.navRoutes[this.state.currentRouteIndex]), false);
        // this.switchSelection(document.getElementsByClassName(`_${this.state.currentRouteIndex}`)[0], true);
        this.switchSelection(`_${this.state.currentRouteIndex}`, true);
        // });
    };
    /**
     *
     * switch navigation items from not including outlets to having one
     * @param withOutlets boolean indicating outlets should be included
     * @returns {JSX.Element}
     *
     */
    showNavs = (withOutlets) => {
        let d;
        // if (withOutlets) {
        d = <SectionRow>
            <SectionColumn className={`tab-menu _0`} onClick={(e) => {
                this.checkMandatoryFields().then(noMissingFields => {
                    if (noMissingFields) {
                        this.switchSelection('_0');
                        this.setState({currentRouteIndex: 0}, () => Dispatch({type: ROUTEINDEX, payload: 0}));
                        this.navigateTo(this.state.dataItem2[0], false);
                        Dispatch({
                            type: SUBTITLE,
                            payload: "Company introduction note"
                        });
                    }
                });

            }}
            >
                Company Introduction
            </SectionColumn>
            <SectionColumn className={`tab-menu _1`}
                           onClick={(e) => {
                               this.checkMandatoryFields().then(noMissingFields => {
                                   if (noMissingFields) {
                                       this.switchSelection('_1');

                                       this.setState({currentRouteIndex: 1}, () => Dispatch({
                                           type: ROUTEINDEX,
                                           payload: 1
                                       }));
                                       this.navigateTo(this.state.dataItem2[1], false);
                                       Dispatch({
                                           type: SUBTITLE,
                                           payload: "Basic company information"
                                       });
                                   }
                               });
                           }}
            >
                Company Biodata
            </SectionColumn>
            <SectionColumn className={`tab-menu _2`}
                           onClick={(e) => {
                               // this.switchSelection(document.getElementsByClassName(`_2`)[0]);
                               this.checkMandatoryFields().then(noMissingFields => {
                                   if (noMissingFields) {
                                       this.switchSelection('_2');

                                       this.setState({currentRouteIndex: 2}, () => Dispatch({
                                           type: ROUTEINDEX,
                                           payload: 2
                                       }));
                                       this.navigateTo(this.state.dataItem2[2], false);
                                       Dispatch({
                                           type: SUBTITLE,
                                           payload: "declare operator shareholders"
                                       });
                                   }
                               });
                           }}
            >
                {this.state.dataItem1.includes('shareholders') ? 'Partners' : 'Shareholders/Directors'}
            </SectionColumn>
            {/*<SectionColumn className={`tab-menu _3`}*/}
            {/*               onClick={(e) => {*/}
            {/*                   this.checkMandatoryFields().then(noMissingFields => {*/}
            {/*                       if (noMissingFields) {*/}
            {/*                           this.switchSelection('_3');*/}
            {/*                           this.setState({currentRouteIndex: 3}, () => Dispatch({*/}
            {/*                               type: ROUTEINDEX,*/}
            {/*                               payload: 3*/}
            {/*                           }));*/}
            {/*                           ;*/}
            {/*                           this.navigateTo(this.state.dataItem2[3], false);*/}
            {/*                           Dispatch({*/}
            {/*                               type: SUBTITLE,*/}
            {/*                               payload: "country of origin, foreign HQ, local HQ and " +*/}
            {/*                                   "operations"*/}
            {/*                           });*/}
            {/*                       }*/}
            {/*                   });*/}
            {/*               }}*/}
            {/*>*/}
            {/*    Business premise*/}
            {/*</SectionColumn>*/}
            <SectionColumn className={`tab-menu _3`}
                           onClick={(e) => {
                               // console.log('tab menu 4 ', this.state.e)
                               this.checkMandatoryFields().then(noMissingFields => {
                                   if (noMissingFields) {
                                       this.switchSelection('_3');

                                       this.setState({currentRouteIndex: 3}, () => Dispatch({
                                           type: ROUTEINDEX,
                                           payload: 3
                                       }));
                                       this.navigateTo(this.state.dataItem2[3], false);
                                       Dispatch({
                                           type: SUBTITLE,
                                           payload: "Nature of business operations"
                                       });
                                   }
                               });
                           }}
            >
                operations
            </SectionColumn>
            <SectionColumn className={`tab-menu _4`}
                           onClick={(e) => {
                               this.switchSelection('_4');
                               // Dispatch({type: ROUTEINDEX, payload: 7});
                               this.setState({currentRouteIndex: 4}, () => Dispatch({
                                   type: ROUTEINDEX,
                                   payload: 4
                               }));
                               this.navigateTo(this.state.dataItem2[4], false);
                               Dispatch({type: SUBTITLE, payload: "complete account opening"});
                           }}
            >
                Summary
            </SectionColumn>
        </SectionRow>
        // }
        return d;
    }
    /**
     *
     * Allow navigation to the left
     *
     */
    navigateLeft = () => {
        //console.log(this.state.currentRouteIndex);
        const navigate = () => {
            if (this.state.currentRouteIndex < 1) {
                this.setState({showBackwards: false, showForwards: true}, () => {
                    notify('End of navigation', 1, true);
                });
            } else {
                // navigate to the next url, and add 1 to the counter
                this.setState({
                    showBackwards: true,
                    currentRouteIndex: this.state.currentRouteIndex - 1
                }, () => {
                    Dispatch({type: ROUTEINDEX, payload: this.state.currentRouteIndex});
                    this.navigateTo(this.state.dataItem2[this.state.currentRouteIndex], false);
                    // this.switchSelection(document.getElementsByClassName(`_${this.state.currentRouteIndex}`)[0])
                    this.switchSelection(`_${this.state.currentRouteIndex}`)

                });
            }
        }
        this.checkMandatoryFields().then(noMissingFields => {
            if (noMissingFields) navigate()
        });
    }

    /**
     *
     * Allow navigation to the right
     *
     */
    navigateRight = () => {
        const navigate = () => {
            if (this.state.currentRouteIndex === this.navRoutes.length - 1) {
                this.setState({showBackwards: true, showForwards: false}, () => {
                    notify('End of navigation', 1, true);
                });
            } else {
                // navigate to the next url, and add 1 to the counter
                this.setState({
                    showForwards: true,
                    currentRouteIndex: this.state.currentRouteIndex + 1
                }, () => {
                    Dispatch({type: ROUTEINDEX, payload: this.state.currentRouteIndex});
                    //console.log(this.state.navigableRoutes[this.state.currentRouteIndex])
                    this.navigateTo(this.state.dataItem2[this.state.currentRouteIndex], false)
                    this.switchSelection(`_${this.state.currentRouteIndex}`);
                    // this.switchSelection(document.getElementsByClassName(`_${this.state.currentRouteIndex}`)[0])
                });
            }
        }
        this.checkMandatoryFields().then(noMissingFields => {
            if (noMissingFields) navigate()
        });

    }
    render = () => {
        // this.props.updateTitleContext('new operator-registration to represent');
        return (
            <Section scrollable={'91vh'}>
                {/*// used to redirect ppl who wish to register operator but already represent one*/}
                {
                    this.state.dataItem2.length > 0 && <SectionRow>
                        {this.showNavs(true)}
                    </SectionRow>
                }
                <SectionRow>
                    {this.state.currentRoute}
                    <Routes>
                        <Route element={<IntroductoryProposal setMandatoryFields={this.setMandatoryFields}/>}
                               path={'operator-introduction'}/>
                        <Route element={<OperatorBioSection bufferStateData={this.props.bufferStateData}
                                                            setMandatoryFields={this.setMandatoryFields}/>}
                               path={'operator-bio-section'}/>
                        <Route element={<OperatorOriginLocation setMandatoryFields={this.setMandatoryFields}/>}
                               path={'operator-origin-location'}/>
                        {/*// make sure that shops and outlets is selected or not*/}
                        <Route element={<CurrentOperations setMandatoryFields={this.setMandatoryFields}/>}
                               path={'current-business-operations'}/>
                        <Route element={<OperatorShareHoldersSection bufferStateData={this.props.bufferStateData}
                            // setMandatoryFields={this.setMandatoryFields}
                        />}
                               path={'operator-shareholders'}/>
                        {/*<Route element={<ShopsAndOutlets/>}*/}
                        {/*       path={'mapper'}/>*/}
                        <Route element={<OperatorSummary bufferredData={this.props.bufferredData}/>}
                               path={'finalize-operator-registration'}/>
                    </Routes>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <Navigation showBackwards={this.state.currentRouteIndex > 0}
                                    showForwards={this.state.currentRouteIndex < this.navRoutes.length - 1}
                                    onNavigation={[this.navigateLeft, this.navigateRight]}
                                    callback={() => {
                                    }}/>
                    </SectionColumn>
                </SectionRow>
            </Section>

        );
    }
    ;
}

/**
 *
 * return storage state
 * @param storageState
 * @returns {}
 *
 */
const mapStateToProps = storageState => {
    return storageState;
}

export default connect(mapStateToProps)(OperatorRegistration);