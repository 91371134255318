import {Component} from "react";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
import TextField from "../../general-components/input-field/TextField";
import {Accordion} from "react-bootstrap";

export default class FaQInternal extends Component {
    constructor(props) {
        super(props);
        this.headerStyle = {fontSize: 18, fontWeight: 600, color: '#204ff5'};
    }

    /**
     *
     * Facilitate
     *
     * @param e
     */
    indexQuestions = (e) => {

    }
    render = () => {
        return <Section>
            <SectionTitle>

            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    <TextField name={'search'}
                               placeholder={'Search'}
                               changeCallback={() => this.indexQuestions}
                    />
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <Accordion>
                        <Accordion.Item eventKey={0}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                Is there a time limit for using an OTP?
                            </Accordion.Header>
                            <Accordion.Body>
                                <br/>
                                Yes, OTPs typically have a limited validity period (5 minutes). After this period, the
                                OTP expires, and a new one must be generated for authentication.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={1}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                Is it safe to stay logged in on a public computer?
                            </Accordion.Header>
                            <Accordion.Body>
                                <br/>
                                It's not recommended. Always log out on public computers to prevent unauthorized access.
                                Use private browsing if available.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={2}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                How is my data secured in G.M.S?
                            </Accordion.Header>
                            <Accordion.Body>
                                <br/>
                                G.M.S employs standard security measures, including encryption and regular security
                                audits, to protect your data.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}