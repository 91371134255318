import {Component} from "react";
import './data-loading-css.css';

/**
 *
 * A data loader component for visual ethics.
 *
 */
export default class LoadingData extends Component {
    constructor(props) {
        super(props);
        this.state = {hasData:true}
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        // check if data is empty
        nextProps.hasData && this.setState({hasData:nextProps.hasData})
    }

    render = () => {
        return <div className={'data-loading'}>
            {this.state.hasData ? <span>{this.props.message ? this.props.message : 'Loading data...'}</span> :
                <span>{this.props.message ? this.props.message : 'No data found'}</span>}
            <br/>
            <div className={'line'}/>
        </div>
    }
}