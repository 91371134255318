import React, {Component} from "react";
//
import FileSelectField from "../general-components/input-field/FileSelectField";
import FileSelectFieldIcon from "../general-components/input-field/FileSelectFieldIcon";
import axios from "axios";
import {BCLB, LOCAL_BACKUP, LOGOUT, NULL_BACKUP, PREV_ROUTE} from "../general-components/redux/allowed-actions";
import {Dispatch} from "../general-components/redux/app-storage";
import SingletonRow from "../general-components/singleton-row/SingletonRow";
import {Navigate} from "react-router-dom";
import {nameFromVariableName, notify, readFromLocalStorage} from "../../MiscUtils";

let GMSData = Object();


/**
 *
 * Class defines methods for hosting and sending data to a server
 * and writing redux data into local storage on response.
 *
 */
export default class GMS extends Component {
    constructor(props) {
        super(props);
        this.url = String("https://operator.bclb.go.ke");
        // this.url = String("http://172.16.20.4:8082");
        const currentIndex = readFromLocalStorage('route_index', BCLB);
        this.state = {
            e: {},
            navigableRoutes: props.navigableRoutes,
            currentRouteIndex: currentIndex ? currentIndex : 0,
            currentView: null,
            currentRoute: null, // This is used for setting routes
            // loggedIn: false,
            // isLoggingIn: false,
            genderList: null,
            games: null,
            machines: null,
            countries: null,
            counties: null,
            visible: false,
            subtaskIsVisible: false,
            buttonIsVisible: true,
            sideBar: null,
            isEditing: false, // marks whether a given field is editable
            //
            // these are reusable variables. use them for whatever data manipulation you use.
            dataItem1: [], //make sure that misc items is always an array if rendering of components_to_delete is desired:audit
            dataItem2: [], //see commentary on dataItem1
            dataItem3: [],
            dataItem4: [],
            dataItem5: [],
            dataItem6: [],
            miscField: null,
            htmlIframeElement: null, // a html object element will be appended here.
            //
            mandatoryFields: [], // [] <- this is an array by default
            allMandatoryFieldsAreFulfilled: false,
            // missingMandatoryFields: null, // [] <- this is an array by default
            missingFieldNames: null, // This is used as a convenience tool for spelling out the items that are missing in a readable manner
            miscellaneousItems: null,
            currentWindowedComponent: null,
            variable: null,
            counts: null, // counts for all licenses and permits
            reload: false,
            role: null,
            /*This is used by the view-switching mechanisms employed by Views*/
            switchableView: null,
            showBackwards: false,
            showForwards: false
        };
        this.currentSubmenuSelection = null;//for tracking the current submenu that's selected
        this.selectedClassName = "tab-menu-selected";//styling for a selected menu-item
        /*
         * make sure that the instance reference refers to this class instead of the
         * input field.
         */
        GMSData = this;
        //
        this.command = null;
        this.initStorageDataFromLocalStorage().then(() => {
            // console.log(this.state.e);
        });
        // push history
    }

    /**
     *
     * Styles current button to immitate current selection.
     * @param e the element that calls this method.
     * Note: this is a repetition of code found in ProfileViewer js component.
     * Find a way of utilitying this method.
     *
     * @param firstRun indicate whether it's the first run calling this method.
     *
     */
    switchSelection = (e = HTMLElement, firstRun = false) => {

        if (this.currentSubmenuSelection && this.currentSubmenuSelection.classList)
            this.currentSubmenuSelection.classList.remove(this.selectedClassName);
        this.currentSubmenuSelection = e;
        if (this.currentSubmenuSelection.classList) this.currentSubmenuSelection.classList.add(this.selectedClassName);
    }

    /**
     *
     * Toggle submit button
     *
     * @param showButton
     *
     */
    toggleSubmitButton = (showButton = false) => {
        this.setState({buttonIsVisible: showButton});
    }

    /**
     *
     * Check or register the outcome of the password evaluation for the first password field.
     * This value is used by the cond password field (pass-by-reference operation from child component without
     * a reference to the said component).
     *
     * @param isValidPassword
     */
    registerIsPasswordValid = (isValidPassword = false) => {
        this.setState({reload: isValidPassword});
    }

    /**
     *
     * Check the mandatory fields listed in the state mandatoryFields field
     * loop through them and determine whether they are present in all of state e in all levels.
     * In the event that a navigation event is in progress, if mandatoryFields is set and
     * missingMandatoryField is set and allMandatoryFieldsAreFulfilled is true, navigate
     * else notify the person in question of the missing mandatory fields
     *
     */
    checkMandatoryFields = async () => {
        // check the mandatory fields declared in state mandatoryFields. Go through state.e and check
        // the values of mandatory fields in them
        if (this.state.mandatoryFields.length === 0)
            return true;
        const eKeys = Object.getOwnPropertyNames(this.state.e);
        console.log('state ', this.state.e, 'keys ', eKeys, ' mandatory fields ', this.state.mandatoryFields)
        let a = 0;
        let missingMandatoryFields = [], names = [];
        do {
            if (eKeys.indexOf(this.state.mandatoryFields[a]) === -1) {
                missingMandatoryFields.push(this.state.mandatoryFields[a]);
            }
            a += 1;
        } while (a < this.state.mandatoryFields.length);
        a = 0;
        if (missingMandatoryFields.length > 0) {
            names.push(
                <span>There are {missingMandatoryFields.length} fields with no data: <br/></span>);
            do {
                names.push(<span
                    style={{color: '#337cc6'}}><b>{a + 1}</b>.
                    &ensp;{nameFromVariableName(missingMandatoryFields[a])}<br/>
                                </span>); // default separator: '_'
                if (a === missingMandatoryFields.length - 1)
                    names.push(<br/>)
                a += 1;
            } while (a < missingMandatoryFields.length);
            notify(names, 2, false, "Missing mandatory fields");
        }
        return !missingMandatoryFields.length > 0;
    }

    /**
     *
     * This is a very dangerous operation that can easily lead to a continuous loop. If isEditing, be very careful that
     * you always come up with a exit condition (when updating the state). Check the desired state for what it should
     * contain and provide the referred condition therein.
     *
     * In this case,
     * @param prevProps
     * @param prevState
     * @param snapshot
     *
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.currentRoute)
            // remove current route
            this.setState({currentRoute: null});
    }


    /**
     *
     * Mount the desired component
     * @param d the component in question
     *
     */
    mountDesiredComponent = (d = Component) => {
        this.setState({currentWindowedComponent: null}, () => {
            this.setState({currentWindowedComponent: d})
        });
    }
    /**
     *
     * Enable component navigate to a given page.
     * @param route the route to navigate to
     *
     * @param replacePathInHistory
     *              this user-defined path AS IS. It defaults to false (that is, treat this url as a relative
     *              url to the current window.location value).
     *
     * @param state the state containing the data to carry forward into the new route. It defaults to the current state
     *              as written in the persisted (localStorage) object. Any additional state object passed is joined or
     *              spread into the existing localStorage values object.
     *
     */
    navigateTo = (route = 'nothing', replacePathInHistory = false, state = {}) => {
        const bypassableRoutes = ['/login', 'login', '/representative', 'representative', 'nothing'];
        // console.log('route ', route);
        this.setState({
            currentRoute: <Navigate to={`${route}`}
                                    replace={replacePathInHistory}
                                    state={{to: route}}
            />
        }, () => {
            Dispatch({type: PREV_ROUTE, payload: route});// record this route into redux
        });
        // check the current complete route whether it exists in the history object. if it does, return null
        // if state is null, navigate using the existing redux state
    }
    /**
     *
     * Locad the state from local storage
     * @returns {Promise<void>}
     */
    initStorageDataFromLocalStorage = async () => {
        await GMSData.setState((state) => {
            // console.log("changing state.e to useless data");
            state.e = "useless data";
            return state;
        });
    };

    /**
     *
     * Component will unmount
     * Note: You cant update state in componentWillUnmount because
     * the component is not re-rendered ever again! Unless mounted again.
     *
     */
    componentWillUnmount = () => {
        if (this.state.e === undefined) return;
        const propertyNames = Object.getOwnPropertyNames(this.state.e);
        // console.log(propertyNames)
        if (propertyNames.length > 0) {
            //check that there is no password in this state
            //if there is a password, nullify local backup
            if (propertyNames.includes("password") === false) {
                Dispatch({type: LOCAL_BACKUP, payload: this.state.e}); //nullify what exists in the backup
            } else {
                //nullify anything that was there, perhaps nullify jwt?
                Dispatch({type: NULL_BACKUP}); //nullify what exists in the backup
            }
            // else this is a login or password update. Bypass backing up this content...
        }
    };
    /**
     *
     * Nulls the redux LOCAL_BACKUP and state.e values
     *
     */
    nullPOSTData = () => {
        Dispatch({type: NULL_BACKUP});
        let nuller = {};
        this.setState({e: {...nuller}});
    }

    /**
     *
     * chains up miscellaneous items into singleton rows
     * @param callback a callback method that's called to effect what happens
     * to the created row
     *
     * @param data the data to form singletonrows out of...
     *
     */
    prepareSingleTonRows = (
        callback = () => {
            console.log(`Argument 1 of prepareSingleTonRows's callback method is not defined.
 Pass a callback method reference especially if its for 'closing' or 'row-removal' operations`);
        },
        data
    ) => {
        let p = [];
        let dataToProcess = data === undefined ? this.state.dataItem1 : data;
        // console.log('data to process ', dataToProcess);
        for (const item of dataToProcess) { // array of json objects
            // one json object
            p.push(<SingletonRow item={item} callback={callback}/>);
        }
        this.setState({dataItem2: [...p]});
    };

    /**
     *
     * Switches the components_to_delete currently being shown.
     * @param component the target component to show.
     *
     */
    switchProfile = (component) => {
        this.setState((state) => {
            state.currentWindowedComponent =
                component === undefined ? null : component;
            return state;
        });
    };
    /**
     *
     * chains up miscellaneous items into singleton rows
     * @param callback a callback method that's called to effect what happens
     * to the created row
     *
     * @param data the data to form singletonrows out of...
     */
    prepareMachineSingleTonRows = (
        callback = () => {
            //            console.log(`${this.prepareSingleTonRows.name}'s callback argument is not defined.
            // Pass a callback method reference especially if its for 'closing' or 'row-removal' operations`);
        }, data) => {
        let p = [];
        let dataToProcess = data === undefined ? this.state.dataItem1 : data;
        for (const item of dataToProcess) {
            p.push(<SingletonRow item={item} callback={callback}/>);
        }
        //
        this.setState({dataItem2: null, dataItem1: null}, () => {
            this.setState({dataItem2: p, dataItem1: dataToProcess})
        });
    };

    /**
     *
     * update miscellaneous items with data.
     * @param objectToUpdate the json object to update
     *
     */
    updatedataItem1 = async (objectToUpdate) => {
        if (objectToUpdate.constructor !== {}.constructor)
            throw new TypeError(`${objectToUpdate} should be of type '{}' or JSON object but 
            found ${objectToUpdate.constructor.name}`);
        else
            this.setState((state) => {
                if (!state.dataItem1.includes(objectToUpdate))
                    state.dataItem1.push(objectToUpdate);
                state.dataItem2 = [];
                return state;
            });
    };
    /**
     *
     * update miscellaneous items with data.
     * @param objectToUpdate the json object to update
     *
     */
    updateGamesMachinesdataItem1 = async (objectToUpdate) => {
        if (objectToUpdate.constructor !== {}.constructor)
            throw new TypeError(`${objectToUpdate} should be of type '{}' or JSON object but 
            found ${objectToUpdate.constructor.name}`);
        else
            this.setState((state) => {
                if (!state.gamesdataItem1.includes(objectToUpdate))
                    state.gamesdataItem1.push(objectToUpdate);
                state.dataItem4 = [];
                return state;
            });
    };

    /**
     *
     * deletes miscellaneous items.
     * Make this method asynchronous so that setState can be awaited for as it pre-clears the requried states.
     * @param itemToDelete the json object item to delete.
     *
     */
    deleteMachinedataItem1 = async (itemToDelete = undefined) => {
        let internalBuffer = [];
        let internalListBuffer = [];
        if (itemToDelete.constructor !== {}.constructor)
            throw new TypeError(`${itemToDelete} should be of type '{}' or JSON object but 
            found ${itemToDelete.constructor}`);
        else {
            if (this.state.gamesdataItem1.length !== 1)
                for (const item of this.state.gamesdataItem1) {
                    if (itemToDelete !== item) {
                        //if it is not to be removed, record it separately
                        internalBuffer.push(item); // this is going to be an empty buffer if there is only one element in
                        internalListBuffer.push(
                            <SingletonRow
                                item={item}
                                callback={this.deleteMachinedataItem1}
                            />
                        );
                        //dataItem1, and that that item is to be removed.
                    }
                }
            //do this to make sure that the state is initially nullified before update with new data.
            await this.setState((state) => {
                state.gamesdataItem1 = [];
                state.dataItem4 = [];
                return state;
            });
            // console.log(internalBuffer, internalListBuffer)
            this.setState((state) => {
                state.gamesdataItem1 = internalBuffer;
                state.dataItem4 = internalListBuffer;
                return state;
            });
        }
    };


    /**
     *
     * deletes miscellaneous items.
     * Make this method asynchronous so that setState can be awaited for as it pre-clears the requried states.
     * @param itemToDelete the json object item to delete.
     *
     */
    deleteSingleTonRow = async (itemToDelete = undefined) => {
        let internalBuffer = [];
        let internalListBuffer = [];
        if (itemToDelete.constructor !== {}.constructor)
            throw new TypeError(`${itemToDelete} should be of type '{}' or JSON object but found ${itemToDelete.constructor}`);
        else {
            if (this.state.dataItem1.length > 0) {
                let y = 0;
                do {
                    if (Object.getOwnPropertyNames(itemToDelete)[0] !== Object.getOwnPropertyNames(this.state.dataItem1[y])[0]) {
                        //if it is not to be removed, record it separately
                        // this is going to be an empty buffer if there is only one element in
                        internalBuffer.push(this.state.dataItem1[y]);
                        internalListBuffer.push(<SingletonRow item={this.state.dataItem1[y]}
                                                              callback={this.deleteSingleTonRow}/>);
                        //dataItem1, and that that item is to be removed.
                    }
                    y += 1;
                } while (y < this.state.dataItem1.length);
            }
            //do this to make sure that the state is initially nullified before update with new data.
            this.setState({dataItem1: [...internalBuffer], dataItem2: [...internalListBuffer]},
                () => {
                    this.updateArrayInFormData('game_type', String(Object.getOwnPropertyNames(itemToDelete)[0]), true);
                    // remove this item from redux
                });
        }
    };

    /**
     *
     * called on change to update a given item with form-data from a given item
     * @param key the key variable to identify this datum
     * @param value the datum in question
     *
     */
    updateFormData = async (
        key = String("default_key"),
        value = String("default_value")
    ) => {
        this.setState((state) => {
            state.e[key] = value;
            return state;
        });
    };

    /**
     *
     * Deletes an element from the array object in question
     * Note: This operation, just like the delete keyword of javascript,
     *      just frees up memory by invalidating the reference to the value in question.
     *
     * @param arrayInQuestion array to delete element from
     * @param valueToDelete the value to delete
     *
     */
    deleteFromArray = (arrayInQuestion, valueToDelete) => {
        return arrayInQuestion.indexOf(valueToDelete) !== -1 ?
            delete arrayInQuestion[arrayInQuestion.indexOf(valueToDelete)] : false;
    }
    /**
     *
     * updates values contained inside an array within the state.e object.
     * @param nameOfVariableInState the internal object's name
     * @param newValue the value to push
     *
     * @param removeThatElement tell this method to remove that element. by default, no
     */

    updateArrayInFormData = async (nameOfVariableInState, newValue, removeThatElement = false) => {
        let arr = [];
        // if (this.state.e && this.state.e[nameOfVariableInState]) {
        if (removeThatElement) {
            // loop through the object's data and copy it over to another array then replace the old array
            // with the new one
            let replacementArray = [];
            let k = 0;
            // console.log('new value ', newValue, ' State variable ', this.state.e[nameOfVariableInState]);
            do {
                // console.log(`inside loop with count ${k}`, String(this.state.e[nameOfVariableInState][k]), 'new value ',
                //     newValue, ' are different ', String(this.state.e[nameOfVariableInState][k]) !== String(newValue))
                if (this.state.e[nameOfVariableInState].length === 1) {
                    // scrub the item in state e
                    this.setState(state => {
                        delete state.e[nameOfVariableInState];
                        return state;
                    })
                    this.state.e[nameOfVariableInState] = [];
                } else if (String(this.state.e[nameOfVariableInState][k]) !== String(newValue))
                    replacementArray.push(this.state.e[nameOfVariableInState][k]);
                k += 1;
            } while (k < this.state.e[nameOfVariableInState].length);
            // replace the state that variable with the new array
            // console.log('new value ', newValue)
            this.setState(state => {
                state.e[nameOfVariableInState] = null;
                return state;
            }, () => {
                this.setState(state => {
                    state.e[nameOfVariableInState] = replacementArray;
                    return state;
                });
            });
        } else {
            //transfer array contents over to a device locally
            // nullify the state operator-view-sections
            this.setState(state => {
                state.e[nameOfVariableInState] = null;
                return state;
            });
            // if this is the first time that the element is being created.
            if (this.state.e[nameOfVariableInState] !== undefined) {
                arr = this.state.e[nameOfVariableInState];
                arr.push(newValue);
            } else {
                arr = [];
                arr.push(newValue);
            }
        }
        await this.updateFormData(nameOfVariableInState, arr);
    }
    /**
     *
     * @Deprecated
     * read file contents into a base64 string
     *
     * @param _file the file object to read as base64
     * @param callback a callback method to execute when read is complete
     * @returns {string} the file in form of a base64 string
     *  that the file contents are translated to.
     *  NOTE: any document viewer component will have a version of its own here
     */
    readToBase64 = (_file = File | Blob, callback = undefined) => {
        if (callback === undefined) {
            throw new ReferenceError(`readToBase64 method requires a callback as a second argument.
            This is because this method's file-read activity is asynchronous and does not do well with returning
            its output. Pass a method reference(--without-arguments--) which will execute when
            the internal file-read-operation is complete.`);
        }
        let fR;
        if (_file instanceof File || _file instanceof Blob) {
            fR = new FileReader();
            let fileString;
            fR.readAsDataURL(_file);
            fR.onload = (e) => {
                fileString = e.target.result;
                if (callback !== undefined) {
                    callback(fileString);
                }
                // return fileString;
            };
        } else
            throw new TypeError(
                `${
                    this.readToBase64.name
                } method expected a file object. Found ${typeof _file}`
            );
    };
    /**
     *
     * collect data from objects using keys like a given regular expression.
     * @param keyExpression the expression whose keys to retrieve
     * @param asJson whether this should return a JSON field
     * @param stateVariable optional, the variable from which to pick data from
     *
     * @returns {*[]} the data that has been found using this expression
     *
     */
    collectDataFromKeysLike = (keyExpression, asJson = false, stateVariable = 'e') => {
        let data = [];
        let asJsonData = {};
        const expression = new RegExp(`${keyExpression}`);
        // get the current state and retrieve from the given state
        const keys = Object.getOwnPropertyNames(this.state[stateVariable]);
        let k = 0;
        if (asJson) {
            do {
                if (expression.test(keys[k])) {
                    // push this data into data
                    const y = {};
                    asJsonData[keys[k]] = this.state[stateVariable][keys[k]];
                }
                k += 1;
            } while (k < keys.length);
        } else {
            do {
                if (expression.test(keys[k])) {
                    // push this data into data
                    const y = {};
                    y[keys[k]] = this.state[stateVariable][keys[k]];
                    data.push(y);
                }
                k += 1;
            } while (k < keys.length);
        }
        return asJson ? Object.getOwnPropertyNames(asJsonData).length > 0 ? asJsonData : data.length > 0 ? data : null : null;
    }

    /**
     * Populates data from form input tools into a data object.
     * Pass the input element (that is, use 'e.target' notation).
     * @param ipt the HTMLElement in question. This element has to be of the sub-classes:
     *      HTMLInputElement
     * or
     *      HTMLSelectElement
     *
     * @param dispatchToStorageImmediately allow this meth  od to backup content immediately during typing
     *
     */
    collectFieldData = async (ipt = HTMLElement | Component | [], dispatchToStorageImmediately = false) => {
        // initialize the state e value
        // console.log(ipt)
        let data_values = null; //an array of arrays for ease of referencing.
        if (ipt.target instanceof HTMLInputElement) {
            if (ipt.type === "radio") {
                if (ipt.target.checked === false) {
                    // remove this item from state.e and redux
                } else
                    data_values = [ipt.target.name, ipt.target.checked];
            } else if (ipt.type === "checkbox" || ipt.type === "radio") {
                //pick the checkbox whether it's checked
                data_values = [ipt.target.name, !!ipt.target.checked];
            } else if (ipt.type === "file") {
                if (ipt.target.files.length > 1) {
                    if (
                        ipt.current.constructor.name === FileSelectField.name ||
                        ipt.current.constructor.name === FileSelectFieldIcon.name
                    ) {
                        data_values = [ipt.current.inputField.name, ipt.base64StringArray]; //base64StringArray
                    } else {
                        data_values = [ipt.target.name, ipt.target.files];
                    }
                } else if (ipt.target.files.length === 1)
                    data_values = [ipt.target.name, ipt.target.files[0]]
            }
            // default: input type text
            else {
                data_values = [ipt.target.name, ipt.target.value];
            }
        } else if (
            ipt.target instanceof HTMLSelectElement ||
            ipt.target instanceof HTMLTextAreaElement
        ) {
            data_values = [ipt.target.name, ipt.target.value];
        } else if (!(ipt instanceof HTMLElement)) {
            data_values = ipt; // key, value
        }
        // assume that the content that was passed through
        else if (ipt.constructor.name === [].constructor.name) {
            // split along the comma
            // console.log('ipt 0 and 1 ', ipt[0], ipt[1]);
            data_values = [ipt[0], ipt[1]]; // first item is a string, second is a array or any other item
        }
        await this.setState(state => {
            let values = {};
            if (state.e !== undefined)
                values = state.e;
            values[data_values[0].toLowerCase()] = data_values[1];
            state.e = {...state.e, ...values};
            // state.e[data_values[0].toLowerCase()] = data_values[1];
            return state;
        }, () => {
            // console.log('this state e ', this.state.e)
            this.setState({e: GMSData.state.e},
                () => dispatchToStorageImmediately &&
                    Dispatch({type: LOCAL_BACKUP, payload: this.state.e})
            )
        })
        //
        /**
         *
         * @deprecated [GMS.setState(state =>{...})]
         *              This code meant to conduct indirect updates to state
         *              is no longer valid but will be retained for legacy reasons.
         *
         */
        //
        // await GMSData.setState((state) => {
        //     let values = state.e;
        //     values[data_values[0].toLowerCase()] = data_values[1];
        //     state.e = {...values};
        //     // state.e[data_values[0].toLowerCase()] = data_values[1];
        //     console.log(state.e)
        //     return state;
        // }, () => {
        //     this.setState({e: GMSdata.state.e}, () => dispatchToStorageImmediately &&
        //             Dispatch({type: LOCAL_BACKUP, payload: this.state.e}
        //             )
        //         // console.log('set ', this.state.e)
        //         // dispatch local backup
        //     );
        // });
        // make sure you flush this to redux because
        // each component will have its state unique to it.
        // Data is not being carried over!
    };
    /**
     * @deprecated overtaken by collectFieldData
     * @param select
     *
     */
    updateFormDataFromSelectTool = (select = HTMLSelectElement) => {
        GMSData.setState((state) => {
            state.data[select.name] = select.value;
            return state;
        });
    };

    /**
     *
     * Removes a given key from the state data
     * @param key the data whose key has been passed.
     * @returns {boolean}
     *
     */
    clearFieldDatum = (key = String()) => {
        let isRemoved = false;
        this.setState((state) => {
            delete state.e[key];
            isRemoved = true;
            return state;
        });
        return isRemoved;
    };

    /**
     *
     * Clears form data for this component
     *
     */
    clearFormData = () => {
        this.setState((state) => {
            state.e = {};
            return state;
        });
    };
    /**
     * @deprecated Not needed! if escaping is required, do a JSON.stringify instead.
     * Escapes json properties into string objects
     * @param jsonData the data whose contents are to get escaped.
     * @returns {{}} json object with escaped property names and values
     *
     */
    escapeJSON = (jsonData) => {
        let escapedJSONData = {};
        const k = Object.getOwnPropertyNames(jsonData);
        //loop through the objects
        for (let p of k) {
            escapedJSONData[p.toString()] = jsonData[p].toString();
        }
        console.log("escaped json data: ", escapedJSONData);
        return escapedJSONData;
    };
    /**
     *
     * This method constructs a HTMLIframe elementRequest and draw an IFrame onto the 'htmlElement'
     * state object, preferably a div.
     *
     * NOTE: This method HAS ACCESS TO AND SETS THE VALUE OF the state object 'htmlElement'. This means
     *          your code where the iFrame is desired must reference that object for it to show up. Use
     *          the format
     *                  {
     *                      this.state.htmlIframeElement
     *                  }
     *          To remove the element from that view, either set the element to null or call the
     *          asynchronous method 'removeIframe()'.
     * @param actionPathURI
     * @param data the data to send through the dynamically created HTMLFormElement
     * @param frameStyle
     * @param callbackOnNavigateBack callback method executed when the back button is selected.
     * @returns {boolean} whether the iframe was successfully
     */
    requestIFrame = async (actionPathURI, data = [], frameStyle = {}, callbackOnNavigateBack = () => {
    }) => {
        let returnableAttribute = SyntaxError(`A invalid url/actionPathURI used for this internal form's 'action'\
         attribute was supplied! Expected the format '[https://]some.domain.com[/additional-paths/...]' \
                but found ${actionPathURI}`);
        /*
        * A internal method to process fields and field data passed on
         */
        // console.log("some data",data)
        const populateFields = () => {
            let fields = [];
            let k = 0;
            do {
                const r = Object.keys(data[k]);
                const ipt = <input style={{height: 0, width: 0}}
                                   name={r[0]}
                                   value={data[k][r[0]]}
                                   readOnly={true}/>;
                fields.push(ipt);
                k += 1;
            } while (k < data.length)
            return fields;
        }
        // check the following elements in actionPathURI:
        //    -- must include the format [https://]some.domain.com[/additional-paths/...] within the domain name
        if (actionPathURI.split('.').length < 3)
            return returnableAttribute;
        let t = false;
        const krs = <div>
            <form target='frame_payment'
                  action={actionPathURI}
                  id={'virtualForm'}
                  style={{opacity: 0, height: 0, width: 0, zIndex: -10}}
                  method='post'>
                {
                    // data.length > 0 && data
                    data.length > 0 && populateFields()
                }
            </form>
            <iframe style={{
                marginLeft: 220,
                width: 580,
                height: 680,
                border: "none"
            }}
                    name="frame_payment"/>
        </div>;
        this.setState({
            htmlIframeElement: krs
        }, () => {
            t = true;
            const p = document.getElementById('virtualForm');
            p.submit(); // do submit upon loading
        });
        return t;
    }

    /**
     * The graduator method. appends commas where appropriate.
     * @param numeral
     * @returns {string}
     */
    graduateNumeral = (numeral = String(this.props.value)) => {
        // check if the value is a multiple of 3. if so, divide it up in groups of 3 from right to left
        // console.log('numeral ', numeral)
        if (typeof numeral !== 'string')
            numeral = String(numeral);
        let groups = [];
        let group = [];
        let c = numeral.length - 1;
        do {
            group.push(numeral[c]);
            if (c === 0 || group.length === 3) {
                groups.push(group);
                group = [];
            }
            c -= 1;
        } while (c > -1);
        // reverse the values
        c = 0;
        do {
            groups[c] = groups[c].reverse();
            c += 1;
        } while (c < groups.length);
        groups = groups.reverse();
        // reconstitute the value
        let graduatedNumeral = '';
        groups.map((group, index) => {
            if (index < groups.length - 1)
                graduatedNumeral += String(`${group.join('')},`);
            else graduatedNumeral += String(`${group.join('')}`);

        });
        // console.log('value ', numeral, 'groups', groups, 'graduated numeral ', graduatedNumeral);
        return graduatedNumeral;
    }
    /**
     *
     * A asynchronous method used to  remove a target IFrame held by the state object htmlIframeElement.
     * @returns {Promise<boolean>} Whether the IFrame was successfully removed.
     *
     */
    removeIFrame = async () => {
        await this.setState({htmlIframeElement: null});
        return this.state.htmlIframeElement === null;
    }
    /**
     *
     * Audits codes coming from the server. Unless data is explicit, this method prioritizes all other codes
     * than 200
     * @param re the response object from the server
     * @param endPoint the endpoint from which the data was extracted. This is purely used as a association object.
     * @param is_error tells audit codes whether it's dealing with an error (anything other than 200, 201, 204 and/or 304
     * @returns {{}|*} either a notification, or json data (properly parsed and escaped).
     *
     */

    auditCodes = (re, endPoint, is_error = false) => {
        let response = {};
        if (is_error === false) {
            // for 200 messages with data, provide data to calling endpoint using notification field
            if (re.status === 200 || re.status === 201) {
                //test if data is json object or stringify-ed
                if (re.data.data === undefined) {
                    response = {
                        priority: 2,
                        message: <>Fatal error. Data was not found in this response! Data (at least in its correct
                            format), was not found! <br/>Cannot proceed with working with the outcome AS IS.
                            <br/>Contact the administrator concerning this matter!<span style={{color: '#bc0909'}}>
                                Actual error: the field 'data' was not found in server response! </span></>,
                        title: 'Inconsistent response from server'
                    }
                } else {

                    /*
                     * acquire the endpoint with the last entity being non-empty
                     */
                    // response = re.data.constructor.name === {}.constructor.name ? [...re.data] : re.data;
                    let finalEndPoint = null;
                    // check the question mark and the '/' symbol and pick the endpoint name between these...
                    // check for the question mark or parameterization of the endpoint
                    let hasParameters = false;
                    if (endPoint.indexOf("?") !== -1)
                        hasParameters = true;
                    if (endPoint.indexOf("/") !== -1) {
                        endPoint = endPoint.split("/");
                        // // pick the second-last item
                        // finalEndPoint = endPoint[endPoint.length - 2];
                        // console.log(finalEndPoint)
                        // previous code

                        let k = endPoint.length - 1;
                        if (hasParameters)
                            k = endPoint.length - 2;

                        do {
                            if (endPoint[k].length !== 0) {
                                finalEndPoint = endPoint[k];
                                break;
                            }
                            k -= 1;
                        } while (k >= 0);
                    } else finalEndPoint = endPoint;
                    // check for type and message
                    if (['Fail', 'error', 'fail'].includes(re.data.type)) {
                        response = {
                            status: re.status,
                            // priority: re.data.priority,
                            priority: 2,
                            message: re.data.length === 0 ? re.data.message : re.data.data[0].msg,
                            data: re.data.data.constructor.name === {}.constructor.name ? [re.data.data] : re.data.data,
                            type: re.data.type && re.data.type
                        }
                    } else {

                        let miscFields = {};
                        // test for miscellaneous fields
                        if (re.data.count)
                            miscFields.count = re.data.count;
                        if (re.data.next)
                            miscFields.next = re.data.next;
                        if (re.data.previous)
                            miscFields.previous = re.data.previous;
                        response = {
                            status: re.status,
                            // priority: re.data.priority,
                            priority: 4,
                            // message: re.data.message ? re.data.message : re.data.data,
                            data: re.data.data.constructor.name === {}.constructor.name ? [re.data.data] : re.data.data,
                            // data: re.data.data,
                            type: re.data.type && re.data.type,
                            ...miscFields

                        }
                    }
                }
            } else if (re.status === 204) {
                //success in doing what was to be done
                response = {
                    status: 204,
                    message: endPoint === 'login' ? `${endPoint}: Could not log you in! Try again` :
                        `${endPoint} reports: Nothing to do.`,
                    priority: endPoint === 'login' ? 3 : 5,
                    type: re.data.type && re.data.type
                };
            } else if (re.status === 304)
                response = {
                    status: 304,
                    message: `${endPoint} has received data from you. Nothing to do`,
                    priority: 1,
                    type: re.data.type && re.data.type
                };
            // check whether the status is undefined: in this case, its a 404
            // NOTE: 404 might never show up explicitly! In some cases,
            // its disguised as a undefined in the status field (the status being absent)
            // response['data'] = re.data.data.length > 0 ? re.data.data[1] : [...response['data']];
        } else {
            const vfw = re.message.split(' ');
            if (vfw[0] === 'Network' && vfw[1] === 'Error') {
                response = {
                    status: 403,
                    message: <>CORs security evaluation failed on the endpoint <i>{endPoint}</i>. This might indicate
                        issues
                        with the application server or a general Network Error on the endpoint specified</>,
                    priority: 3,
                    title: 'Security Evaluation Failed',
                    type: 'CROSS_ORIGIN_REQUEST_BLOCKED'
                };
                notify(<>{response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:&nbsp;
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            } else if (vfw.includes('403')) {
                response = {
                    status: 403,
                    message: endPoint.split('/').includes('login') ? `Could not log you in! Username/password are invalid! Try again` :
                        <>The resource <i>{endPoint}</i> was denied access. Log in afresh (Perhaps your access token is
                            expired?)</>,
                    priority: 2,
                    title: 'Access denied',
                    type: endPoint === 'login' ? 'LOGIN_DENIED' : 'FORBIDDEN_ACTION [valid_access_token_required]'
                };
                endPoint !== 'notifications/unread' && notify(<>{response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:&nbsp;
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            } else if (vfw.includes('405')) {
                response = {
                    status: 405,
                    message: <>The request header specified by the user application is not allowed for the intended
                        resource</>,
                    priority: 3,
                    title: 'Request Declined',
                    type: 'DECLINED_ACTION[method_not_allowed]'
                };
                notify(<>{response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:&nbsp;
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            } else if (vfw.includes('404')) {
                response = {
                    status: 404,
                    message: `No data or endpoint on '${endPoint}' was found!`,
                    priority: 2,
                    title: 'URL or resource not found',
                    type: 'RESOURCE_NOT_FOUND'
                };
                endPoint !== 'account/logout' && notify(<><b>[{response.status}]</b> {response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            }
                //error. Catastrophic error
                //check other codes and provide a generic message about the post's experiences
                //notable codes are 401, 403, 404, 502 and 304
            //normal notification
            else if (vfw.includes('502')) {
                response = {
                    status: 502,
                    message: `${endPoint} is a bad request (${re.status}) 
                                and is not currently accessible on the specified server!`,
                    priority: 2,
                    title: 'Bad (or network-inaccessible) Server',
                    type: 'BAD_ACCESS_GATEWAY[has_valid_main_resource_on_server_been_changed?]'
                };
                notify(<><b>[{response.status}]</b> {response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            }
            //warning
            else if (vfw.includes('503')) {
                response = {
                    status: 503,
                    message: `Service is unavailable at this time`,
                    priority: 2,
                    title: 'service unavailable',
                    type: 'SERVICE_UNAVAILABLE'
                };
                notify(<><b>[{response.status}]</b> {response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            } else if (vfw.includes('504')) {
                response = {
                    status: 504,
                    message: `${endPoint} has timed out (${re.status}) ~ 
                                it's taking too long to respond`,
                    priority: 2,
                    title: 'Request has been timed out',
                    type: 'BROWSER_REQUEST_WAS_TIMED_OUT'
                };
                notify(<><b>[{response.status}]</b> {response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            }
                //warning
            //information
            else if (vfw.includes('500')) {
                response = {
                    status: 500,
                    message: `${endPoint} error on remote server
                 occurred. Contact your admin. Nothing to do!`,
                    priority: 2,
                    title: 'Server has some internal problems',
                    type: 'INTERNAL_SERVER_ERROR'
                };
                notify(<><b>[{response.status}]</b> {response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            } else if (vfw.includes('401')) {
                response = {
                    status: 401,
                    message: `${endPoint} reported that it is not authorized to serve your request`,
                    priority: 2,
                    title: 'unauthorized: probably missing "www-authenticate" header',
                    type: 'WWW-Authenticate_header_missing_?'
                }
                notify(<><b>[{response.status}]</b> {response.message}.
                    <br/>
                    <br/>
                    <span style={{fontSize: '0.7em', color: '#337cc6'}}>The reported error was:
                        <i>{response.type}</i></span></>, response.priority, false, response.title, response.status);
            }
                // general-components_to_delete notification
            // assume it's 400. deal with it
            else if (vfw.includes('400')) {
                //
                response = {
                    status: 400, // unknown error
                    message: <>There's a unknown problem with this request/process.<span><br/><br/>
                            <div style={{fontSize: '0.7em', textTransform: 'italic', color: '#438dd3'}}>
                                Status (Browser-generated code) code 400</div></span></>,
                    priority: 2, // error
                    title: "GMS detected problems!",
                    type: 'UNKNOWN_EVENT'
                };
                // console.log('response 400 ', response)
                notify(<>[{response['status']}] - A general error occurred in the system.
                        The reported error was: <br/>{response['message']}</>, response['priority'], false,
                    `General error - ${response.title !== undefined && response.title}`, response.status);
            }
        }
        return response;
    }
    /**
     *
     * Get the header from the url in question.
     * @param targetURL
     * @param callback_method
     *
     */
    getHeaders = (targetURL, callback_method = header => {
    }) => {
        try {
            axios.head(`${targetURL}/`, {"Content-Type": "*/*", withCredentials: true}).then(head => {
                callback_method((head));
            });
        } catch (malformedURI) {
            console.warn('The target URL ', targetURL, ' is malformed!');
        }
    }
    /**
     *
     * This method is for sending data to the server.
     * @param type type of request to send - get, post, iframe
     * @param endPoint the endpoint to go through
     * @param callbackOnResponse the callback method to call
     *          when the request has had a response from the server
     * @param requestData
     *          This is pre-filled by default.
     *
     */
    sendRequest = (
        type = "post",
        endPoint = `account/logout`,
        callbackOnResponse = (k) => {
            console.warn(
                `No callback has been issued for the sendRequest(a,b,c, callbackMethod) call`
            );
        },
        requestData = this.state.e
    ) => {
        // outcome field that stores data when a response comes back.
        if (endPoint === null || endPoint === "") {
            Dispatch({type: LOGOUT});
            throw new TypeError(`Mandatory logging you out because you attempted
             to maliciously request data from the server [No 'endPoint' was 
             issued. Found non-logout endpoint '${endPoint}']`);
        } else {
            const removeNotification = notify('Working...please wait...', 1, true, null, false);
            if (type === "iframe" || type === "frame") {
                this.requestIFrame(endPoint, requestData, null, callbackOnResponse);
            } else {
                // differentiate between object access and paremeter passing by
                // looking for a question mark in the endpoint string
                const slashed = endPoint.includes('?') ? '' : '/';
                // endPoint = endPoint.toLowerCase();
                // const baseheaders = {' X-Frame-Options': 'DENY'};
                const contentType = requestData instanceof FormData ?
                    {'Content-Type': 'multipart/form-data'/*,...baseheaders*/} :
                    type === 'get' ?
                        {"Content-Type": "*/*"/*,...baseheaders*/} :
                        {"Content-Type": "application/json"/*,...baseheaders*/};
                /**
                 *
                 * watch out for cors configs. Refer to this:
                 * Is Django doing something along the lines of:
                 *
                 * https://stackoverflow.com/questions/46230983/validate-and-get-the-user-using-the-jwt-token-inside-a-view-or-consumer#46257303
                 *
                 *
                 *
                 */
                if (type === "post") {
                    axios
                        .post(`${this.url}/api/${endPoint}${slashed}`, requestData, {
                            withCredentials: true,
                            headers: contentType,
                        })
                        .then((re) => {
                            // hasResponded = true;
                            callbackOnResponse(this.auditCodes(re, endPoint));
                        })
                        .catch((err) => {
                            // hasResponded = true;
                            // do an auditCodes
                            // removeNotification();
                            console.log(err)
                            callbackOnResponse(this.auditCodes(err, endPoint, true));
                        });
                } else if (type === "get") {
                    let formattedGETData = "";
                    if (!requestData) ;
                    else if (
                        requestData.constructor.name === {}.constructor.name &&
                        Object.getOwnPropertyNames(requestData).length !== 0
                    ) {
                        //do nothing
                        //create all fields there
                        const keys = Object.keys(requestData);
                        let counter = 0;
                        for (const key of keys) {
                            formattedGETData += `${key}=${requestData[key]}`;
                            if (counter < keys.length) formattedGETData += "&";
                        }
                        //insert a question mark
                        formattedGETData = `?${formattedGETData}`;
                    } else if (
                        requestData.constructor.name === "Number" ||
                        requestData.constructor.name === "".constructor.name
                    ) {
                        formattedGETData = `/${requestData}`;
                    }
                    axios
                        .get(`${this.url}/api/${endPoint}${formattedGETData}${slashed}`, {
                            withCredentials: true,
                            headers: contentType, //accept plain text as well just because.
                        })
                        .then((re) => {
                            // hasResponded = true;
                            // removeNotification();
                            callbackOnResponse(this.auditCodes(re, endPoint));
                        })
                        .catch((error) => {
                            // hasResponded = true;
                            //do an audit codes
                            // removeNotification();
                            callbackOnResponse(this.auditCodes(error, endPoint, true));
                        });
                } else if (type === "put") {
                    axios.put(`${this.url}/api/${endPoint}${slashed}`, requestData, {
                        withCredentials: true,
                        headers: contentType,
                    })
                        .then((re) => {
                            // hasResponded = true;
                            // removeNotification();
                            callbackOnResponse(this.auditCodes(re, endPoint));
                        })
                        .catch((err) => {
                            // hasResponded = true;
                            // do an auditCodes
                            // removeNotification();
                            callbackOnResponse(this.auditCodes(err, endPoint, true));
                        });
                } else
                    throw new Error(
                        `sendRequest(type,y,z,c) first argument expects either 'get' or 'post'. Found ${type}`
                    );
                removeNotification();
            }
        }

    };
}
