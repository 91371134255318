import DocumentEntry from "./DocumentEntry";
import GMS from "../../parent-component/GMS";
import {Section, SectionTitle} from "../../rotm-custom-views/view-utils/SectionUtils";
// import file_size_url from 'file_size_url';
import {notify} from "../../../MiscUtils";
import DocumentDetailsCard from "./DocumentDetailsCard";

/**
 *
 * List all documents passed to this class using the prop documentsList. Its job is just that!
 * Use the DocumentEntry component to visualize the documents listed here.
 * Document data is listed as follows:
 * [{
 *      name: <string>,
 *      path: <string>,
 *      document: <(optional) blob>,
 *      size: <string>,
 *      uploaded: <string>,
 *      status: <string>
 *
 * },...]
 */
export default class ListDocuments extends GMS {
    constructor(props) {
        super(props);
        notify('Preparing to list documents...', 1, true);
        this.sendRequest('get', 'account/upload', documents => {
            let priority = null
            // 404 is not allowing this elements to properly list
            if (documents.type === 'Success')
                priority = 4;
            else if (documents.priority)
                priority = documents.priority
            if (priority === 4) {
                documents.data.length > 0 ?
                    // notify(`Done. Found ${documents.data.length} documents`, 1, true) :
                    notify(`Done fetching documents`, 1, true) :
                    notify('No documents found for this operator', 3, true);
                // go through the documents.data and
                let t = [];
                let z = 0;
                do {
                    if (documents.data[z]['file_upload_status']) {
                        let v = {};
                        t.push(documents.data[z]['file_url']);
                        // const k = documents.data[z]['file_url'].split('/');
                        // v['id'] = documents.data[z]['id'];
                        // v['name'] = k[k.length - 1];
                        // v['belonging_to'] = documents.data[z]['object'];
                        // // v['size'] = this.getHeaders(documents.data[z]['file_url']);
                        // v['path'] = documents.data[z]['file_url'];
                        // v['uploaded'] = documents.data[z]['date_created'].split(' ')[0];
                        // v['status'] = 'active';
                        // v['file_upload_status'] = documents.data[z]['file_upload_status'];
                        // t.push(v);
                    }
                    z += 1;
                } while (z < documents.data.length);
                this.setState({dataItem1: [...t]});
            }
        });
    }

    /**
     *
     * A internal utility method that allows population of document elements
     *
     * @param documentEntries
     * @param k
     * @param size
     */
    _setFiles = (documentEntries, k, size) => {
        if (this.state.dataItem1[k].status === 'suspended')
            documentEntries.push(<DocumentEntry name={this.state.dataItem1[k].name}
                // size={this.state.dataItem1[k].size}
                                                path={this.state.dataItem1[k].path}
                                                uploaded={this.state.dataItem1[k].uploaded}
                                                suspended
                                                count={k + 1}
            />);
        else if (this.state.dataItem1[k].status === 'inactive')
            documentEntries.push(<DocumentEntry name={this.state.dataItem1[k].name}
                // size={this.state.dataItem1[k].size}
                                                path={this.state.dataItem1[k].path}
                                                uploaded={this.state.dataItem1[k].uploaded}
                                                inactive
                                                count={k + 1}
            />);
        else if (this.state.dataItem1[k].status === 'pending')
            documentEntries.push(<DocumentEntry name={this.state.dataItem1[k].name}
                // size={this.state.dataItem1[k].size}
                                                path={this.state.dataItem1[k].path}
                                                uploaded={this.state.dataItem1[k].uploaded}
                                                pending
                                                count={k + 1}
            />);
        else if (this.state.dataItem1[k].status === 'active')
            documentEntries.push(<DocumentEntry name={this.state.dataItem1[k].name}
                // size={this.state.dataItem1[k].size}
                                                path={this.state.dataItem1[k].path}
                                                uploaded={this.state.dataItem1[k].uploaded}
                                                active
                                                count={k + 1}
            />);
    }
    /**
     *
     * Populate document entries when they arrive
     *
     * @returns {*[]}
     *
     */
    populateDocumentEntries = () => {
        let documentEntries = [];
        let k = 0;
        documentEntries.push(<DocumentEntry name={'Document Name'}
                                            size={'Size'}
                                            path={'Path'}
                                            uploaded={'Date uploaded'}
                                            inactive
                                            isLabel
            // count={k + 1}
        />);
        do {
            //console.log(this.state.dataItem1[k])
            if (this.state.dataItem1[k]['file_upload_status'])
                this._setFiles(documentEntries, k, this.state.dataItem1[k].size);
            k += 1;
        } while (k < this.state.dataItem1.length);
        return documentEntries;
    }
    render = () => {
        //console.log('data item 1 ',this.state.dataItem1)
        return <Section>
            <SectionTitle>
                Showing {this.state.dataItem1.length} uploaded documents
            </SectionTitle>
            {/*<SectionRow style={{borderBottom: '1px solid #B6B9B6FF', margin: '12px 2px'}}>*/}
            {/*    <SectionColumn/>*/}
            {/*    /!*<SectionColumn/>*!/*/}
            {/*    <SectionColumn>*/}
            {/*        <div className={'legend-elements'} style={{background: '#8EFF7DFF'}}>*/}
            {/*            active*/}
            {/*        </div>*/}
            {/*    </SectionColumn>*/}
            {/*    <SectionColumn>*/}
            {/*        <div className={'legend-elements'} style={{background: '#9D9CA6FF'}}>*/}
            {/*            inactive*/}
            {/*        </div>*/}
            {/*    </SectionColumn>*/}
            {/*    <SectionColumn>*/}
            {/*        <div className={'legend-elements'} style={{background: '#91B5F8FF'}}>*/}
            {/*            pending*/}
            {/*        </div>*/}
            {/*    </SectionColumn>*/}
            {/*    <SectionColumn>*/}
            {/*        <div className={'legend-elements'} style={{background: '#F6D466FF'}}>*/}
            {/*            suspended*/}
            {/*        </div>*/}
            {/*    </SectionColumn>*/}
            {/*    <SectionColumn/>*/}
            {/*    /!*<SectionColumn/>*!/*/}
            {/*</SectionRow>*/}
            {/*<SectionRow>*/}
            {/*    <SectionColumn>*/}
            <DocumentDetailsCard
                disableDownload
                documentList={
                    this.state.dataItem1
                }/>
            {/*    </SectionColumn>*/}
            {/*</SectionRow>*/}
            {/*{*/}
            {/*    this.state.dataItem1 && this.state.dataItem1.length > 0 ? this.populateDocumentEntries() :*/}
            {/*        <span style={{fontSize: '1.6em', color: '#8e8989'}}>No documents found</span>*/}
            {/*}*/}
        </Section>
    }
}