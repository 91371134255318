import React from "react";
import BaseField from "./base/BaseField";

/**
 *
 * NumberField defines a type of input field specific to numbers ONLY
 *
 */
export default class NumberField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.callback = props.callback;
        if (props.maxLength)
            this.numberRegRxp = new RegExp(/^[0-9]{1,18}$/);//this takes care of bank account numbers as well...
        else
            this.numberRegRxp = new RegExp(/^[0-9]{1,18}$/);//this takes care of bank account numbers as well...
    }

    /**
     *
     * @param argument1
     * @param argument2
     * @returns {any[]}
     */
    exampleMethod = (argument1 = Array(), argument2 = Number) => {
        return argument1;

    }
    /**
     * Check if typed letter is a number and that it's 18 digits long!
     * @param e the value to test out.
     * @returns {boolean} the status of the test. true if it's a number within desired range else false.
     */
    checkIfNumber = (e) => {
        return this.numberRegRxp.test(e);
    }
    render = () => {
        /*
         * onFocus allows the legend to be changed
         */
        return (<fieldset className={`form-group`}>
            <label
                className={`${this.state.selection} form-label`}
                style={{textAlign: "left", ...this.textStyling, ...this.props.style}}
            >
                {this.fieldPlaceHolder}
                {this.isRequired}
            </label>
            <input
                className="form-control"
                {...this.required}
                ref={this.internalFieldReference}
                name={this.name}
                type={this.fieldType}
                maxLength={this.props.maxLength}
                defaultValue={this.props.defaultValue}
                onFocus={this.highlightOnFocus}
                onChange={(e) => {
                    if (this.checkIfNumber(e.target.value)) {
                        this.changeCallback(e);
                        this.removeContextMessageWarning();
                    } else {
                        //pass the target input field to perform this act on.
                        // this.stopTypingOn(e);
                        this.stopTyping();
                        //show error message box with message there
                        this.showContextMessageWarning("Type only numbers!")
                    }
                }
                }
                onBlur={() => {
                    this.evaluateControlOnRequired()
                    this.blurCallback();
                }}/>
            {this.state.possibleContextMessageBox}
        </fieldset>);
    };
}

