import GMS from "../../../../../parent-component/GMS";
import {SectionColumn, SectionRow, SectionTitle} from "../../../../../rotm-custom-views/view-utils/SectionUtils";
import TextAreaField from "../../../../../general-components/input-field/TextAreaField";
import SelectField from "../../../../../general-components/input-field/SelectField";
import {readFromLocalStorage} from "../../../../../../MiscUtils";
import {BCLB, SUBTITLE} from "../../../../../general-components/redux/allowed-actions";
import TextField from "../../../../../general-components/input-field/TextField";
import {connect} from "react-redux";
import {Dispatch} from "../../../../../general-components/redux/app-storage";

export class LocationFields extends GMS {
    constructor(props) {
        super(props);
        this.counties = readFromLocalStorage('county', BCLB);
    }

    componentDidMount() {
        this.setState({e: {...this.props}});
        this.counties.length > 0 && this.setState({dataItem1: [...this.counties]});
        Dispatch({type: SUBTITLE, payload: 'Location of this premise'});
    }

    render = () => {
        return <SectionRow>
            <SectionColumn boxedColour>
                <SectionRow>
                    <SectionTitle>
                        County of premise
                    </SectionTitle>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <SelectField name={'county'}
                                     bold
                                     placeholder={'Select county'}
                                     isRequired
                                     defaultValue={this.state.e && this.state.e['county']}
                                     options={this.state.dataItem1}
                                     fontSize={14}
                                     changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextField name={'lat'}
                                   bold
                                   placeholder={'Latitude (optional)'}
                            // isRequired
                            // defaultValue={this.state.e && this.state.e['lat']}
                                   fontSize={14}
                                   changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextField name={'long'}
                                   bold
                                   placeholder={'Longitude (optional)'}
                            // isRequired
                            // defaultValue={this.state.e && this.state.e['long']}
                                   fontSize={14}
                                   changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextField name={'building'}
                                   bold
                                   placeholder={'Host building'}
                                   isRequired
                                   defaultValue={this.state.e && this.state.e['building']}
                                   fontSize={14}
                                   changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextField name={'floor'}
                                   bold
                                   placeholder={'Floor number'}
                                   defaultValue={this.state.e && this.state.e['floor']}
                                   fontSize={14}
                                   changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextField name={'room'}
                                   bold
                                   placeholder={'Room number'}
                                   defaultValue={this.state.e && this.state.e['room']}
                                   fontSize={14}
                                   changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
            <SectionColumn boxedColour>
                <SectionRow>
                    <SectionTitle>
                        Description of location (optional)
                    </SectionTitle>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <TextAreaField name={'description'}
                                       noResize
                                       defaultValue={this.state.e && this.state.e['description']}
                                       style={{
                                           height: 240,
                                           marginBottom: 40,
                                           // border_radius: "2px solid black",
                                       }}
                                       fontSize={14}
                                       placeholder={''}
                                       changeCallback={this.collectFieldData}/>
                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
        </SectionRow>
    }
}

const mapStateToProps = (reduxState) => {
    let data = {}
    if (reduxState.LOCAL_BACKUP)
        data = {...reduxState.LOCAL_BACKUP};
    return data;
}
export default connect(mapStateToProps)(LocationFields);