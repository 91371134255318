import {Component} from "react";
import {SectionColumn, SectionRow} from "../view-utils/SectionUtils";
import coa from '../../general-components/BCLB_icons/coat_of_arms.png';

export default class BCLBLetterHead extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return <SectionRow>
            <SectionColumn>
                <SectionRow style={{alignItems: 'center'}}>
                    <SectionColumn/>
                    <SectionColumn style={{alignItems: 'center', height: 80}}>
                        <img src={coa} style={{width: 100, height: 100}}/>
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn style={{textAlign: 'center', fontWeight: 700, fontSize: 20}}>EXECUTIVE OFFICE OF THE
                        PRESIDENT</SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn style={{textAlign: 'center', fontWeight: 700, fontSize: 16}}>BETTING CONTROL AND
                        LICENSING BOARD</SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn/>
                    <SectionColumn
                        style={{textAlign: 'center'}}><span>ACK Garden Annex 7<sup>th</sup> Floor 1<sup>st</sup> Ngong Avenue</span></SectionColumn>
                    <SectionColumn/>
                </SectionRow>
            </SectionColumn>
        </SectionRow>
    }
}