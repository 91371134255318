import GMS from "../../../../parent-component/GMS";
import {connect} from "react-redux";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {BCLB, LOCAL_BACKUP, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import NumberField from "../../../../general-components/input-field/NumberField";
import {SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {notify, readFromLocalStorage} from "../../../../../MiscUtils";
import BlurTabContents from "../../../floating-layout-views/blur/BlurTabContents";
import PrizesFieldSet from "./groupable-fieldsets/PrizesFieldSet";

/**
 *
 * Creates and provides callbacks to fields relating to the matrix/formulae used to select
 * winners of a given game or rewards schemes.
 * Fields include:
 *  1. formulae (drop-down list) for selection of what constitutes a win.
 *  2. description (text-area) for description of a custom formulae
 *
 */
class PrizesFields extends GMS {
    constructor(props) {
        super(props);
        //this regular expression is used to look for the words default, custom or multiple, a
        //inherent signal that a textarea needs to be shown to accompany this field's selection
        this.decideIfDescriptionFieldIsNecessary = new RegExp(/^[default|custom|none|multiple]$/);
        this.blurFieldsFor = readFromLocalStorage('blur_fields', BCLB);
    }

    /**
     *
     * Load the property game_formulae onto the selection field
     * @param nextProps the property list from local storage
     * @param nextContext
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            e: nextProps.LOCAL_BACKUP,
            dataItem3: nextProps.counties,
            dataItem4: nextProps.prize_category
        });
    }
    /**
     *
     * get data from the server, if it's not present in local storage
     *
     */
    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'specify prizes'});
        if (readFromLocalStorage(LOCAL_BACKUP, BCLB)['prizes'])
            this.setState({
                dataItem4: this.props.prize_category,
                e: readFromLocalStorage(LOCAL_BACKUP, BCLB),
                dataItem5: this.props.applicable
            }, () => this.createFieldSets(this.state.e['prizes'].length));
        if (this.blurFieldsFor) {
            if (!this.blurFieldsFor.includes('prizes'))
                this.setState({
                    mandatoryFields: ['winner_prize', 'prize_name', 'prize_valued_at']
                });
        }
        //check whether the game formulae is set
        //look for prize categories

    }
    /**
     *
     * Update prize data
     *
     * @param prizeDataObject the html object containing the data to collect
     * @param prizeNumber the position of the prize data object in the collected data
     *
     */
    updatePrizeData = (prizeDataObject, prizeNumber) => {
        let y = this.state.e['prizes'] ? this.state.e['prizes'] : [];
        //console.log("y", y, "prize data object ", prizeDataObject.target.name, "prize number ", prizeNumber)
        // let currentPrizeData = y[prizeNumber];
        if (y[prizeNumber] === undefined) {
            // push new value into this element else just append it
            let prize = {}
            prize[prizeDataObject.target.name] = prizeDataObject.target.value;
            y.push(prize);
        } else y[prizeNumber][prizeDataObject.target.name] = prizeDataObject.target.value;
        this.collectFieldData(['prizes', [...y]]);
    }
    /**
     *
     * Remove fieldsets
     *
     * @param prizeNumber
     */
    removeFieldSets = prizeNumber => {

    }
    /**
     *
     * Create fieldsets
     *
     * @param nOFieldsets
     *
     */
    createFieldSets = (nOFieldsets = 1) => {
        let k = [];
        let p = 0;
        do {
            k.push(<PrizesFieldSet setNumber={p}
                                   fieldData={this.state.e['prizes'] && this.state.e['prizes'][p]}
                                   prize_category={this.state.dataItem4}
                                   collectFieldData={this.updatePrizeData}/>);
            p += 1;
        } while (p < nOFieldsets);
        this.setState({dataItem1: [...k]});
    }

    render = () => {
        let prizes = <div style={{display: 'flex', flexDirection: 'column'}}>
            <SectionRow>
                <SectionColumn style={{width: '60vw'}}/>
                <SectionColumn>
                    <NumberField name={''}
                                 bold
                                 defaultValue={this.state.e && this.state.e['prizes'] && this.state.e['prizes'].length}
                                 placeholder={'Number of prizes to be won'}
                                 changeCallback={e => this.createFieldSets(parseInt(e.target.value))}
                    />
                </SectionColumn>
            </SectionRow>
            {this.state.dataItem1}
        </div>;
        // check if blurFields is set in storage. if
        if (this.blurFieldsFor) {
            if (this.blurFieldsFor.includes('prizes')) {
                // return a section with a picture
                return <>
                    {this.state.currentRoute}
                    <BlurTabContents executeAlternative={() => {
                        // notify that prizes is unselectable
                        notify('Prizes fieldset does not apply to Licenses! Skipping...', 1, true);
                        window.setTimeout(() => {
                            this.navigateTo('../upload-supporting-documentation', false)
                        }, 1500)
                    }}
                    /></>
            } else
                return prizes
        }
        return this.state.dataItem5 !== null || this.state.dataItem5 !== 2 ? prizes : <BlurTabContents/>;
        // <SectionRow><SectionColumn><span>This section is not applicable in licenses</span></SectionColumn></SectionRow>;
    }
}

/**
 *
 * MapViewer all state that is recorded in local storage to the state of the current component.
 * Any class that wields this state (through inheritance) has access to the mapped
 * state from local-storage.
 *
 * @param localStorageState the state as recorded in the local storage
 * Do not connect the parent class because connected classes MUST NEVER BE SUBCLASSED!
 *
 */
    // load data from redux and populate it into the GMS state. Licensor is not used
const mapStateToProps = (localStorageState) => {
        let p = {};
        //the jwt, email of agent, operator-registration details
        const itemsOfInterest = ['game_prizes', 'prize_category', 'LOCAL_BACKUP'];
        for (const item of itemsOfInterest) {
            p[item] = localStorageState[item];
        }
        // seems kila kitu hapa kinaloadiwa
        return p;//seems there are efficiency problems here...
    }

// connect will return the GMS having been connected to the local storage.
// find a way for inheriting a connected component...
// learned from https://daveceddia.com/redux-connect-export/ (does not work!)
// this is a wrapped component with the GMS as part of it...
//
// Note: Connected components_to_delete MUST NEVER BE SUB-CLASSED!
// :: https://www.scrivito.com/connect-component-1c81c8d3b87493af
//
export default connect(mapStateToProps)(PrizesFields);//component must have a render method

//fetch from connect and find out whether the following items are available