import GMS from "../../../../parent-component/GMS";
import {connect} from "react-redux";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {BCLB, LOCAL_BACKUP, SUBTITLE,} from "../../../../general-components/redux/allowed-actions";

import {
    arrayOfJSONsToJSON,
    extractValueFromJSON,
    formatDate,
    notify,
    readFromLocalStorage,
} from "../../../../../MiscUtils";
import SelectField from "../../../../general-components/input-field/SelectField";
import TextField from "../../../../general-components/input-field/TextField";
import TextAreaField from "../../../../general-components/input-field/TextAreaField";
import {SectionColumn, SectionRow,} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import NumberField from "../../../../general-components/input-field/NumberField";
import React from "react";
import BankFieldSet from "../../operator-declaration/operator-collector-sections/micro-components/BankFieldSet";

/**
 *
 * Creates and provides callbacks to fields relating to the matrix/formulae used to select
 * winners of a given game or rewards schemes.
 * Fields include:
 *  1. formulae (drop-down list) for selection of what constitutes a win.
 *  2. description (text-area) for description of a custom formulae
 *
 */
class NatureFields extends GMS {
    constructor(props) {
        super(props);
        //this regular expression is used to look for the words default, custom or multiple, a
        //inherent signal that a textarea needs to be shown to accompany this field's selection
        this.decideIfDescriptionFieldIsNecessary = new RegExp(
            /^[default|custom|none|multiple]$/
        );
        this.banks = readFromLocalStorage('banks', BCLB);
    }

    /**
     *
     * Load the property game_formulae onto the selection field
     * @param nextProps the property list from local storage
     * @param nextContext
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        //'game_type', 'application_type', 'game_cause'
        this.setState(
            {
                e: nextProps.LOCAL_BACKUP,
                dataItem4: nextProps.application_type,
                dataItem3: nextProps.game,
                dataItem1: nextProps.run_reason,
            });
    };
    /**
     *
     * get data from the server, if it's not present in local storage
     *
     */
    componentDidMount = () => {
        // this.setState({
        //     mandatoryFields: ["application_type", "game_type", "game_cause"],
        // });
        //
        this.sendRequest('get', `selections/game/?lp_type=${this.props.applicationType}`, lpGameTypes => {
            this.setState({dataItem3: [...lpGameTypes.data]});
        }, {});
        this.setState(
            {
                e: Object.values(this.props.LOCAL_BACKUP).length > 0 ? {
                    ...this.props.LOCAL_BACKUP, 'application_type': this.props.applicationType
                } : {...this.state.e, 'application_type': this.props.applicationType},
                dataItem4: null,
                dateItem3: null,
                dataItem1: null,
            },
            () => {
                this.setState({
                    dataItem4: this.props.application_type,
                    dataItem3: this.props.game,
                    dataItem1: this.props.run_reason,
                });
            }
        );
        Dispatch({
            type: SUBTITLE,
            payload:
                " Select operation and reason backing its run",
        });
    };
    /**
     *
     * Update bank data
     *
     * @param bankFieldDataReference the field reference
     * @param belongingTo
     */
    updateBankData = (bankFieldDataReference, belongingTo = 0) => {

        let bankDataField = {}; //this is just a pre-construction for new elements within the array
        // check if the current field name exists in state e banks array elements for the belongingTo attribute
        if (this.state.e['banks'])
            if (belongingTo > this.state.e['banks'].length) {
                notify('You must start with the first bank when filling in their field data',
                    2,
                    false,
                    'Bank data sequencing error!');
                return false;
            }
        bankDataField[bankFieldDataReference.target.name] =
            bankFieldDataReference.target.type === "checkbox"
                ? bankFieldDataReference.target.checked
                : bankFieldDataReference.target.type === 'file'
                    ? bankFieldDataReference.target.files[0] : bankFieldDataReference.target.type === 'date' ?
                        formatDate(bankFieldDataReference.target.value, 'ymd', 'dmy', '-')
                        : bankFieldDataReference.target.value;
        // check whether this is the first shareholder
        if (Object.getOwnPropertyNames(this.state.e).includes('banks')) {
            let previousBanks = this.state.e['banks'] === null ? [] : this.state.e['banks'];
            try {
                previousBanks[belongingTo][bankFieldDataReference.target.name] =
                    bankFieldDataReference.target.type === "checkbox"
                        ? bankFieldDataReference.target.checked : bankFieldDataReference.target.type === 'file'
                            ? bankFieldDataReference.target.files[0] : bankFieldDataReference.target.type === 'date' ?
                                formatDate(bankFieldDataReference.target.value, 'ymd', 'dmy', '-')
                                : bankFieldDataReference.target.value;
            } catch (newFieldItemFound) {
                previousBanks.push(bankDataField);
            }
            this.setState(state => {
                // When at least one shareholder exists and that the
                // current shareholder to edit exists within the list
                // filled in so far, update that shareholder's data
                state.e['banks'] = null;
                return state;
            }, () => this.setState(state => {
                state.e['banks'] = previousBanks;
                return state;
            }));
        } else {
            //create a new bank field
            this.setState(state => {
                // ensure that they reside inside an array
                state.e['banks'] = [bankDataField];
                return state;
            }); //, () => //console.log(this.state.e)
        }
        // update data using the props.bufferStateData will be done
    };
    /**
     *
     * Create bank fieldsets
     *
     */
    createBankFieldSets = () => {
        //console.log("createbankfieldsets dataItem2", this.state.dataItem2)
        let x = 0;
        let y = [];
        const counted = this.state.e['banks'] ? this.state.e['banks'].length : this.state.dataItem2
        do {
            y.push(<BankFieldSet fieldData={this.state.e['banks'] && this.state.e['banks'][x]}
                                 updateBankData={this.updateBankData}
                                 banks={this.banks}
                                 branches={this.props['branches']}
                                 identifier={x + 1}
            />);
            x += 1;
        } while (x < counted)
        this.setState({dataItem5: [...y]});
    }
    render = () => {
        return (
            <SectionRow scrollable boxedColour>
                {/*// this is a spacer objet*/}
                <SectionColumn style={{width: 142}}/>
                <SectionColumn style={{width: "80%"}}>
                    <SectionRow>
                        <SectionColumn>
                            {/*this is the failing component, It is nullifying the current state*/}
                            <TextAreaField
                                bold
                                noResize
                                fontSize={14}
                                maxSize={1200}
                                isRequired
                                defaultValue={this.props.LOCAL_BACKUP["description"]}
                                style={{
                                    height: 240,
                                    border_radius: "2px solid black",
                                }}
                                name={"description"}
                                placeholder={"Concept of this Application"}
                                // changeCallback={e => this.collectFieldData([e.target.name, e.target.value])}
                                changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>&nbsp;</SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SelectField
                                bold
                                fontSize={14}
                                capitalize
                                defaultValue={this.props.LOCAL_BACKUP["game_type"]}
                                isRequired
                                name={"game_type"}
                                placeholder={"Nature of operation"}
                                options={this.state.dataItem3 ? this.state.dataItem3 : []}
                                // changeCallback={e => this.collectFieldData([e.target.name, e.target.options[e.target.selectedIndex].value])}
                                changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SelectField
                                bold
                                fontSize={14}
                                capitalize
                                defaultValue={this.props.LOCAL_BACKUP["game_cause"]}
                                isRequired
                                name={"game_cause"}
                                placeholder={"why is this being run?"}
                                options={this.props.run_reason ? this.props.run_reason : []}
                                // changeCallback={e => this.collectFieldData([e.target.name, e.target.options[e.target.selectedIndex].value])}
                                changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                    </SectionRow>
                    {
                        // check whether a license or permit is listed
                        this.props.applicationType ? (
                            extractValueFromJSON(
                                parseInt(this.props.applicationType),
                                arrayOfJSONsToJSON(this.props.application_type)
                            ).toLowerCase() === "permit" ? (
                                <SectionRow>
                                    <SectionColumn>
                                        <TextField
                                            bold
                                            fontSize={14}
                                            capitalize
                                            maxLength={60}
                                            defaultValue={
                                                this.props.LOCAL_BACKUP["game_trade_name"]
                                            }
                                            name={"game_trade_name"}
                                            placeholder={
                                                "Selling Name/phrase of the promotion"
                                            }
                                            // changeCallback={e => this.collectFieldData([e.target.name, e.target.value])}
                                            changeCallback={this.collectFieldData}
                                        />
                                    </SectionColumn>
                                </SectionRow>
                            ) : null
                        ) : null
                    }
                    <SectionRow boxedColour>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn>
                                    <legend className={"legend-section-title no-border"}>
                                        Source of funds
                                    </legend>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <TextAreaField bold
                                                   noResize
                                                   maxLetters={190}
                                        // isRequired
                                                   fontSize={14}
                                                   name={'source'}
                                                   defaultValue={this.state.e['source'] && this.state.e['source']}
                                                   placeholder={'Describe or list the sources of the company\'s funding'}
                                                   // changeCallback={e => this.collectFieldData([e.target.name, e.target.value])}
                                        changeCallback={this.collectFieldData}
                                    />
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow boxedColour>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn>
                                    <legend className={"legend-section-title no-border"}>
                                        Banking information
                                    </legend>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    <NumberField bold
                                        // defaultValue={Object.getOwnPropertyNames(this.props.LOCAL_BACKUP).includes('banks') ?
                                        //     this.props.LOCAL_BACKUP["banks"].length : 1}
                                                 name={'number_of_banks'}
                                                 placeholder={'State the number of banks to declare'}
                                                 changeCallback={e => {
                                                     this.setState({dataItem2: e.target.value},
                                                         () => this.createBankFieldSets(e.target.value)
                                                     )
                                                 }}
                                    />
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow>
                                <SectionColumn>
                                    {this.state.dataItem5}
                                </SectionColumn>
                            </SectionRow>
                        </SectionColumn>
                    </SectionRow>

                    <SectionRow/>
                </SectionColumn>
            </SectionRow>
        );
    };
}

/**
 *
 * MapViewer all state that is recorded in local storage to the state of the current component.
 * Any class that wields this state (through inheritance) has access to the mapped
 * state from local-storage.
 *
 * @param localStorageState the state as recorded in the local storage
 * Do not connect the parent class because connected classes MUST NEVER BE SUBCLASSED!
 *
 */
// load data from redux and populate it into the GMS state. Licensor is not used anywhere else
const mapStateToProps = (localStorageState) => {
    let p = {};
    //the jwt, email of agent, operator-registration details
    const itemsOfInterest = [
        "run_reason",
        "application_type",
        "game",
        "game_cause",
        LOCAL_BACKUP,
    ];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    // seems kila kitu hapa kinaloadiwa
    return p; //seems there are efficiency problems here...
};

// connect will return the GMS having been connected to the local storage.
// find a way for inheriting a connected component...
// learned from https://daveceddia.com/redux-connect-export/ (does not work!)
// this is a wrapped component with the GMS as part of it...
//
// Note: Connected components_to_delete MUST NEVER BE SUB-CLASSED!
//                      :: https://www.scrivito.com/connect-component-1c81c8d3b87493af
//
export default connect(mapStateToProps)(NatureFields); //component must have a render method

//fetch from connect and find out whether the following items are available
