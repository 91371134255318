import {Section, SectionColumn, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import {row} from "../../../MiscUtils";
import GMS from "../../parent-component/GMS";
import LoadingData from "../../general-components/controls/data-loading/LoadingData";
import DynamicTable from "../../general-components/tables/DynamicTable";

/**
 *
 * shows list of reports.
 *
 *
 */
export default class ListFileReturns extends GMS {
    constructor(props) {
        super(props);
        //console.log('list of returns filed ', props.items);
    }

    componentDidMount = () => {
        this.sendRequest('get', 'company/operator/returns', returnsList => {
            if (returnsList.type === 'Success')
                this.setState({dataItem1: [...returnsList.data]});
        }, {});
    }

    render = () => {
        return <Section noBoxShadow>
            <SectionTitle>
                Filed Returns
            </SectionTitle>
            <SectionRow>
                <SectionColumn>
                    {
                        this.state.dataItem1.length === 0 ?
                            <LoadingData hasData={this.state.dataItem1.length === 0} message={'Retrieving filed returns'}/> :
                            <DynamicTable context={'Returns list'} tableData={this.state.dataItem1}
                                          columnsOfInterest={[
                                              'id',
                                              'license_type',
                                              // 'sales',
                                              // 'revenue',
                                              'date_start',
                                              'date_end'
                                          ]}
                            />
                    }
                </SectionColumn>
            </SectionRow>
            {/*<div className={`${row} bottom-streak`} style={{height: 4, background: '#800080'}}/>*/}
            <div className={`${row} bottom-streak`} style={{height: 4, background: '#59788E'}}/>
            {/*{this.reportListView()}*/}
        </Section>
    }
}