import GMS from "../../../../parent-component/GMS";
import {arrayOfJSONsToJSON, extractValueFromJSON, notify, readFromLocalStorage} from "../../../../../MiscUtils";
import {BCLB, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import DocumentsList from "../../../document-printer/DocumentsListView";
import FileSelectField from "../../../../general-components/input-field/FileSelectField";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import React from "react";

/**
 *
 * Show shareholder data on a different component
 *
 */

/**
 *
 * Show shareholder data on a different component
 *
 */
export default class ShareholderView extends GMS {

    constructor(props) {
        super(props);
        this.storage = readFromLocalStorage('misc_id', BCLB);
        this.role = readFromLocalStorage('role', BCLB);
        const shareholderPoint = this.storage.type === 'individual' ? 'individual-shareholder' : 'company-shareholder';
        this.shareholderEndPoint = `company/operator/${shareholderPoint}/${this.storage.id}`;
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: `View shareholder - ${this.storage.shareholder_name}`});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        console.log('udpated state ', this.state.e)
    }

    render = () => {
        return <div className={'operator-view'}>

            <Section>
                <SectionTitle>
                    Shareholder {this.storage.id}: {this.storage['shareholder_name']}
                </SectionTitle>
                <SectionRow>
                    <SectionColumn>
                        <span>
                        <SimpleLabel text={'name'} bold capitalize colour/>
                        <SimpleLabel
                            text={`${this.storage['shareholder_name']}`}
                            colour/>
                        </span>
                    </SectionColumn>
                    <SectionColumn>
                        <span>
                            <SimpleLabel text={'national id/passport'} bold capitalize colour/>
                        <SimpleLabel text={this.storage['national_id']} colour/>
                        </span>
                    </SectionColumn>
                    <SectionColumn>
                        <span>
                            <SimpleLabel text={'phone number'} bold capitalize colour/>
                        <SimpleLabel text={this.storage['contact']} colour/>
                        </span>
                    </SectionColumn>
                    <SectionColumn>
                        <span>
                            <SimpleLabel text={'nationality'} bold capitalize colour/>
                        <SimpleLabel
                            text={
                                extractValueFromJSON(parseInt(this.storage['nationality']),
                                    arrayOfJSONsToJSON(readFromLocalStorage('country', BCLB)))
                            }
                            colour/>
                        </span>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                       <span>
                            <SimpleLabel text={'number of shares'} bold capitalize colour/>
                        <SimpleLabel text={this.storage['shares']} colour/>,
                        <SimpleLabel text={this.storage['shareholder_type']} colour/>
                       </span>
                    </SectionColumn>
                    <SectionColumn>
                        <span>
                            <SimpleLabel text={'type of shareholding'} bold capitalize colour/>
                        <SimpleLabel
                            text={this.storage['type']} colour/>
                        </span>
                    </SectionColumn>
                    <SectionColumn>
                        <span>
                            <SimpleLabel text={'tax pin number'} bold capitalize colour/>
                        <SimpleLabel text={this.storage['tax_pin']} colour/>
                        </span>
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <span>
                            <SimpleLabel text={'director'} bold capitalize colour/>
                        <SimpleLabel text={this.storage['director']} colour/>
                        </span>
                    </SectionColumn>
                </SectionRow>
                <div className={'spacer-row'}/>
                <SectionRow boxedColour>
                    <SectionColumn><a href={'#'} onClick={() => this.setState({visible: !this.state.visible})}>
                        {this.state.visible ? 'Hide' : 'Upload documents'}
                    </a>
                        {
                            this.state.visible && <SectionRow>
                                <SectionColumn>
                                    {this.storage.type === 'individual' ? <SectionRow>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                // isRequired
                                                             capitalize
                                                             name={'sh_passport_doc'}
                                                             placeholder={'Kenyan ID/Alien ID/Passport'}
                                                             changeCallback={this.collectFieldData}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                             isRequired
                                                             capitalize
                                                             name={'sh_kra_doc'}
                                                             placeholder={'KRA pin certificate'}
                                                             changeCallback={this.collectFieldData}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField
                                                isRequired
                                                bold
                                                fontSize={14}
                                                capitalize
                                                onlyPastDates
                                                name={"shareholder_kra_tcc_file"}
                                                placeholder={"Tax Compliance certificate"}
                                                changeCallback={this.collectFieldData}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField bold fontSize={14}
                                                             isRequired
                                                             capitalize
                                                             name={'sh_cogc_file'}
                                                             placeholder={'Good conduct'}
                                                             changeCallback={this.collectFieldData}
                                            />
                                        </SectionColumn>
                                    </SectionRow> : <SectionRow>
                                        <SectionColumn>
                                            <FileSelectField
                                                isRequired
                                                bold
                                                fontSize={14}
                                                capitalize
                                                onlyPastDates
                                                name={"company_kra_doc"}
                                                placeholder={"Company Tax Compliance document"}
                                                changeCallback={this.collectFieldData}
                                            />
                                        </SectionColumn>
                                        <SectionColumn>
                                            <FileSelectField
                                                isRequired
                                                bold
                                                fontSize={14}
                                                capitalize
                                                onlyPastDates
                                                name={"company_notarized_sh_doc"}
                                                placeholder={"Notarized list of shareholders"}
                                                changeCallback={this.collectFieldData}
                                            />
                                        </SectionColumn>
                                    </SectionRow>}
                                    <SectionRow>
                                        <SectionRow>
                                            <SectionColumn/>
                                            <SectionColumn>
                                                <SubmitButton
                                                    commandText={'Upload documents'}
                                                    callback={() => {
                                                        let target_point = this.storage.type === 'individual ' ? 'individual-shareholder' : 'company-shareholder';
                                                        console.log(this.state.e)
                                                        let fd = new FormData();
                                                        // get the file object sh_kra_doc, sh_cogc_file, company_kra_doc
                                                        // This is a individual shareholder document
                                                        if (Object.getOwnPropertyNames(this.state.e).includes('sh_kra_doc')
                                                            || Object.getOwnPropertyNames(this.state.e).includes('sh_cogc_file')
                                                            || Object.getOwnPropertyNames(this.state.e).includes('sh_passport_doc')
                                                            || Object.getOwnPropertyNames(this.state.e).includes('shareholder_kra_tcc_file')) {
                                                            if (Object.getOwnPropertyNames(this.state.e).includes('sh_passport_doc'))
                                                                fd.append('shareholder_id_file', this.state.e['sh_passport_doc']);
                                                            if (Object.getOwnPropertyNames(this.state.e).includes('sh_kra_doc'))
                                                                fd.append('shareholder_kra_file', this.state.e['sh_kra_doc']);
                                                            if (Object.getOwnPropertyNames(this.state.e).includes('sh_cogc_file'))
                                                                fd.append('shareholder_cogc_file', this.state.e['sh_cogc_file']);
                                                            // if (Object.getOwnPropertyNames(shareholderData).includes('sh_passport_doc'))
                                                            //     fd.append('sh_passport_doc', shareholderData['sh_passport_doc']);
                                                            if (Object.getOwnPropertyNames(this.state.e).includes('shareholder_kra_tcc_file'))
                                                                fd.append('shareholder_kra_tcc_file', this.state.e['shareholder_kra_tcc_file']);
                                                            // append data to it and remove the two items
                                                            // remove files from the locally-buffered data
                                                            delete this.state.e['sh_kra_doc'];
                                                            delete this.state.e['sh_cogc_file'];
                                                            delete this.state.e['shareholder_kra_tcc_file'];
                                                            // target_point = 'individual-shareholder';
                                                        } else if (Object.getOwnPropertyNames(this.state.e).includes('company_kra_doc')
                                                            || Object.getOwnPropertyNames(this.state.e).includes('company_notarized_sh_doc')) {
                                                            if (this.state.e['company_kra_doc'] !== undefined)
                                                                fd.append('company_kra_file', this.state.e['company_kra_doc']);
                                                            if (this.state.e['company_notarized_sh_doc'] !== undefined)
                                                                fd.append('company_notarized_sh_doc', this.state.e['company_notarized_sh_doc']);
                                                            // delete shareholderData['company_kra_doc'];
                                                        }
                                                        // append data to it and remove the two items
                                                        // remove files from the locally-buffered data
                                                        // fd.append('data', JSON.stringify(shareholderData));
                                                        // upload shareholders with their documents
                                                        this.sendRequest(
                                                            "post",
                                                            `company/operator/${target_point}`,
                                                            // the identity of the operator-registration that was created
                                                            response => {
                                                                if (response.type === 'Success') {
                                                                    notify(response.message, response.priority, true);
                                                                    // if (x === 1 || idx === x - 1) {
                                                                    this.nullPOSTData();
                                                                    this.navigateTo('../shareholders-list');
                                                                    // }
                                                                } else {
                                                                    // window.alert(x);
                                                                    notify(response.message, response.priority, true);
                                                                }
                                                            }, fd // the formData with the files and shareholder data in there...
                                                        );
                                                        this.nullPOSTData();
                                                    }
                                                    }
                                                />
                                            </SectionColumn>
                                            {this.state.currentRoute}
                                            <SectionColumn/>
                                        </SectionRow>
                                    </SectionRow>
                                </SectionColumn>

                            </SectionRow>
                        }

                    </SectionColumn>
                </SectionRow>
                <div className={'spacer-row'}/>
                <SectionRow>
                    <SectionColumn>
                        <DocumentsList documentLinks={this.state.dataItem1}/>
                    </SectionColumn>
                </SectionRow>
            </Section>
        </div>
    }
}