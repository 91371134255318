import GMS from "../../../../../parent-component/GMS";
import {SectionColumn, SectionRow, SectionTitle} from "../../../../../rotm-custom-views/view-utils/SectionUtils";
import NumberField from "../../../../../general-components/input-field/NumberField";
import SelectField from "../../../../../general-components/input-field/SelectField";
import CheckBoxField from "../../../../../general-components/input-field/CheckBoxField";
import {notify} from "../../../../../../MiscUtils";
import {connect} from "react-redux";
import TextField from "../../../../../general-components/input-field/TextField";
import {Dispatch} from "../../../../../general-components/redux/app-storage";
import {SUBTITLE} from "../../../../../general-components/redux/allowed-actions";

class PremiseFields extends GMS {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'type, name and layout of premise'});
        this.setState({e: {...this.props}});
        this.sendRequest('get', 'selections/officetype', response => {
            if (response.type === 'Success') {
                this.setState({dataItem1: [...response.data]});
            } else
                notify('No office types found!', 2, true);
        }, {});
        this.sendRequest('get', 'company/operator/license/license', licenses => {
                if (licenses.type === 'Success') {
                    let x = 0;
                    let p = [];
                    if (licenses.data.length > 0) {
                        do {
                            if (licenses.data[x]['application_type'] === 'License') {
                                let y = {};
                                y[parseInt(licenses['data'][x]['id'])] = `${licenses['data'][x]['game_type']} - (App. No. ${licenses['data'][x]['id']})`;
                                p.push(y);
                            }
                            x += 1;

                        } while (x < licenses.data.length);
                        this.setState({dataItem3: [...p]});
                    } else {
                        notify(<span>You must have a license application (either issued or an existing application)
                                first before declaring a premise.
                                <br/>
                                <br/>
                                <b>We have redirected you to the license application page</b>.
                                <br/>
                            Fill in and complete the application, then proceed to declare a premise.</span>,
                            3,
                            false,
                            'No license record declared!')
                        this.navigateTo('../../rep-license-application/license-prerequisites');
                    }
                } else
                    notify('Cannot get list of associated licenses', 2, true);
            }
            ,
            {}
        )
    }
    render = () => {
        return <SectionRow>
            <SectionColumn>
                <SectionRow>
                    {this.state.currentRoute}
                    <SectionColumn boxedColour>
                        <SectionRow>
                            <SectionColumn>
                                <SelectField name={'office_type'}
                                             bold
                                             isRequired
                                             defaultValue={this.state.e && this.state.e['office_type']}
                                             options={this.state.dataItem1}
                                             placeholder={'Type of premise'}
                                             changeCallback={this.collectFieldData}/>
                            </SectionColumn>
                        </SectionRow>
                        {
                            this.state.e['office_type'] === "2" && <SectionRow>
                                <SectionColumn>
                                    <TextField name={'name'}
                                               bold
                                               isRequired
                                               defaultValue={this.state.e && this.state.e['name']}
                                               options={this.state.dataItem1}
                                               placeholder={'Shop name'}
                                               changeCallback={this.collectFieldData}/>
                                </SectionColumn>
                            </SectionRow>
                        }
                        {
                            this.state.dataItem3.length > 0 && <SectionRow>
                                <SectionColumn>
                                    <SelectField name={'shop_and_premise_license'}
                                                 bold
                                                 defaultValue={this.state.e && this.state.e['shop_and_premise_license']}
                                                 options={this.state.dataItem3}
                                                 placeholder={'Applicable license'}
                                                 changeCallback={this.collectFieldData}/>
                                </SectionColumn>
                            </SectionRow>
                        }
                        <SectionRow>
                            <SectionColumn>
                                <CheckBoxField name={'is_franchise'}
                                               defaultValue={this.state.e && this.state.e['is_franchise']}
                                               fontSize={16}
                                               placeholder={'This is a franchised premise'}
                                               changeCallback={this.collectFieldData}/>
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                    <SectionColumn boxedColour>
                        <SectionRow>
                            <SectionTitle>
                                Floor area in square metres
                            </SectionTitle>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <NumberField name={'area'}
                                             fontSize={14}
                                             isRequired
                                             defaultValue={this.state.e && this.state.e['area']}
                                             placeholder={<>Area (M<sup>2</sup>)</>}
                                             changeCallback={this.collectFieldData}
                                />
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                    <SectionColumn boxedColour>
                        <SectionRow>
                            <SectionTitle>
                                Partitioning
                            </SectionTitle>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <NumberField
                                    isRequired
                                    fontSize={14}
                                    name={'partitions'}
                                    defaultValue={this.state.e ? this.state.e['partitions'] : 0}
                                    placeholder={'Number of partitions'}
                                    changeCallback={this.collectFieldData}
                                />
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                    <SectionColumn boxedColour>
                        <SectionRow>
                            <SectionTitle>
                                Ventilation
                            </SectionTitle>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <NumberField name={'windows'}
                                             defaultValue={this.state.e && this.state.e['windows']}
                                             isRequired
                                             fontSize={14}
                                             changeCallback={this.collectFieldData}
                                             placeholder={'Number of windows'}/>
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
        </SectionRow>
    }
}

const
    mapStateToProps = (reduxState) => {
        let data = {}
        if (reduxState.LOCAL_BACKUP)
            data = {...reduxState.LOCAL_BACKUP};
        return data;
    }
export default connect(mapStateToProps)

(
    PremiseFields
)
;