import GMS from "../../../parent-component/GMS";
import React from "react";
import {col12, col6, getStorageObject, row} from "../../../../MiscUtils";
import TextField from "../../../general-components/input-field/TextField";
import SubmitButton from "../../../general-components/controls/buttons/SubmitButton";
import {BCLB} from "../../../general-components/redux/allowed-actions";


/**
 *
 * Class for updating contact informaton. Only three items are supplied at this time:
 * this component expects three properties:
 *  phonenumber - the phone number to show
 *  emailaddress - email address to show
 *  postaddr - the postal address to show
 *
 */

export default class OperatorCompanyAndTradeNamesInfoUpdater extends GMS {
    constructor(props) {
        super(props);
        this.noFieldsSet = false;
        if (
            props.companyname !== undefined &&
            props.tradename !== undefined
        )
            this.noFieldsSet = true;
        else {
            if (props.companyname === undefined)
                console.warn(
                    `NOTE: phonenumber property is missing from  <${this.constructor.name} .../> component call. Not showing phone number!`
                );
            if (props.tradename === undefined)
                console.warn(
                    `NOTE: emailaddress property is missing from  <${this.constructor.name} .../> component call. Not showing email address!`
                );
        }
        //proceed to make sure that despite these items missing, either of them, that default values are shown
        this.companyname =
            props.companyname === undefined
                ? "--no-operator-registration-name--"
                : props.companyname;
        this.tradename =
            props.tradename === undefined
                ? "--no-trade-name--"
                : props.tradename;
        //
        //references used to set the input fields with data, but also disable them.
        //
        this.companyName = React.createRef();
        this.tradeName = React.createRef();
    }

    /**
     * this item will update the contact info
     */
    componentDidMount = () => {
        // fetch data from server regarding contact info, or from localStorage
        let contactInfo = getStorageObject(BCLB)
        if (contactInfo) {
            let k = JSON.parse(contactInfo);
            k = k.operator; // the operator-registration should be returned
            // get the contacts of the operator-registration
            // email, office contacts, postal address
        }
        //update title bar with new title
        //console.log('current phone number', this.phoneNumberReference.current);
        this.companyName.current.internalFieldReference.current.value =
            this.companyName;
        this.tradeName.current.internalFieldReference.current.value =
            this.tradeName;
        // /////////////////////////////
        // //nullify these input fields which have references.
        this.companyName.current.internalFieldReference.current.disabled = true;
        //console.log(this.phoneNumberReference.current.internalFieldReference.current.disabled)
        this.tradeName.current.internalFieldReference.current.disabled = true;
    };

    /**
     *
     * does preliminary checks for data existance in this.state.e (from the GMS class/component)
     *
     */
    finalizeContactInfo = () => {
        this.updateFormData("jwt", "some-jwt-string"); //collect this from the sendRequest method if necessary
        this.sendRequest("updatecontactinfo");
    };
    render = () => {
        return (
            <div className={row}>
                <div className={col12}>
                    <div className={row}>
                        <div className={col6}>
                            <div className={row}>
                                <div className={`${row} image-subtitle`}>
                                    current company and trade names information
                                </div>
                            </div>
                            <div className={row}>
                                <div className={col12}>
                                    <TextField bold fontSize={14}
                                               capitalize
                                               ref={this.companyName}
                                               name={""}
                                               callback={null}
                                               placeholder={"local operator-registration name"}
                                    />
                                </div>
                            </div>
                            <div className={row}>
                                <div className={col12}>
                                    <TextField bold fontSize={14}
                                               capitalize
                                               ref={this.tradeName}
                                               name={""}
                                               callback={null}
                                               placeholder={"local trade name"}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*//editable content here*/}
                        <div className={col6}>
                            <div className={`${row} image-subtitle`}>
                                <div className={col12}>new company and trade names</div>
                            </div>
                            <div className={row}>
                                <div className={col12}>
                                    <TextField bold fontSize={14}
                                               capitalize
                                               name={"company_name"}
                                               changeCallback={this.collectFieldData}
                                               placeholder={"local operator-registration name"}
                                    />
                                </div>
                            </div>
                            <div className={row}>
                                <div className={col12}>
                                    <TextField bold fontSize={14}
                                               capitalize
                                               name={"trade_name"}
                                               changeCallback={this.collectFieldData}
                                               placeholder={"local trade name"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={row}>
                        <div className={col6}/>
                        <div className={col6}>
                            <SubmitButton
                                callback={this.finalizeContactInfo}
                                commandText={"update name(s)"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
