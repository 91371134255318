import GMS from "../../../../parent-component/GMS";
import {connect} from "react-redux";
import {arrayOfJSONsToJSON, getStorageObject, readFromLocalStorage} from "../../../../../MiscUtils";
import SelectField from "../../../../general-components/input-field/SelectField";
import WebAddressField from "../../../../general-components/input-field/WebAddressField";
import {SectionColumn, SectionRow,} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {BCLB, LOCAL_BACKUP, SUBTITLE,} from "../../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import List from "../../../../general-components/list/List";

/**
 *
 * Creates and provides callbacks to fields relating to the matrix/formulae used to select
 * winners of a given game or rewards schemes.
 * Fields include:
 *  1. formulae (drop-down list) for selection of what constitutes a win.
 *  2. description (text-area) for description of a custom formulae
 *
 */
class JurisdictionAndAccessFields extends GMS {
    constructor(props) {
        super(props);
        this.data = null;
        this.storage = getStorageObject(BCLB);
    }

    /**
     *
     * Load the property game_formulae onto the selection field
     * @param nextProps the property list from local storage
     * @param nextContext
     *
     * NOTE: this only works in parent-child relationship
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        // populate dataItem1 with elements
        let jurisdictionNames = [];
        if (nextProps.LOCAL_BACKUP.jurisdiction && nextProps.LOCAL_BACKUP.jurisdiction.length > 0) {
            let p = 0;
            do {
                jurisdictionNames.push(arrayOfJSONsToJSON(this.storage.county)[nextProps.LOCAL_BACKUP.jurisdiction_array[p]]);
                p += 1;
            } while (p < nextProps.LOCAL_BACKUP.jurisdiction_array.length);
        }
        this.setState({e: nextProps.LOCAL_BACKUP, counties: nextProps.county, dataItem1: [...jurisdictionNames]});
    };
    componentDidMount = () => {
        //
        const jurisdictionArray = readFromLocalStorage(LOCAL_BACKUP, BCLB).jurisdiction_array;
        const countiesNames = arrayOfJSONsToJSON(readFromLocalStorage('county', BCLB));
        let jurisdictionNames = [];
        if (jurisdictionArray) {
            let i = 0;
            do {
                if (jurisdictionArray[i] !== null && jurisdictionArray[i] !== undefined)
                    jurisdictionNames.push(countiesNames[jurisdictionArray[i]]);
                i += 1;
            } while (i < jurisdictionArray.length);
        }
        this.setState(state => {
            state.dataItem1 = null;
            return state;
        }, () => {
            this.setState(state => {
                state.dataItem1 = jurisdictionNames;
                state.e = {...readFromLocalStorage(LOCAL_BACKUP, BCLB)};
                return state;
            });
        });
        Dispatch({
            type: SUBTITLE,
            payload: " - specify area of coverage/engagement",
        });
    };
    /**
     *
     * Remove from list within the state.e
     *
     * @param elementToRemove the name of the element to remove
     *
     */
    removeFromList = elementToRemove => {
        let y = this.state.e['jurisdiction_array'] === null || this.state.e['jurisdiction_array'] === undefined ?
            [] : this.state.e['jurisdiction_array'];
        let z = []; // jurisdiction ids
        let p = []; // jurisdiction names
        let t = 0;
        do {
            //console.log('Element to remove ', elementToRemove, ' y[t] ', y[t]);
            if (elementToRemove !== y[t]) {
                z.push(y[t]);
                p.push(this.state.dataItem1[t]);
            }
            t += 1;
        } while (t < y.length);
        //hii state haifanyi
        this.setState(state => {
            state.e['jurisdiction_array'] = [...z];
            state.dataItem1 = [...p];
            return state;
        });
    }
    /**
     *
     * Collect jurisdictions
     * @param jurisdictionObject
     *
     */
    collectJurisdictions = jurisdictionObject => {
        let y = this.state.e['jurisdiction_array'] !== undefined ? this.state.e['jurisdiction_array'] : [];
        if (!y.includes(jurisdictionObject.target.options[jurisdictionObject.target.selectedIndex].value)) {
            if (y.length === 0) {
                y = [jurisdictionObject.target.options[jurisdictionObject.target.selectedIndex].value];
            } else y = [...y, jurisdictionObject.target.options[jurisdictionObject.target.selectedIndex].value];
            this.collectFieldData(['jurisdiction_array', [...y]]).then(() => {
                this.setState({
                    dataItem1: [...this.state.dataItem1,
                        jurisdictionObject.target.options[jurisdictionObject.target.selectedIndex].textContent]
                });
            });
        }
    }
    /**
     *
     *
     *
     * @returns {JSX.Element}
     *
     */
    render = () => {
        return (
            <SectionRow boxedColour>
                {/*// this is a spacer objet*/}
                <SectionColumn style={{width: 142}}/>
                <SectionColumn style={{width: "80%"}}>
                    <SectionRow>
                        {/*if book making,*/}
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <SelectField
                                bold
                                fontSize={14}
                                capitalize
                                defaultValue={Object.getOwnPropertyNames(this.state.e).includes('jurisdiction_array') > 0 &&
                                this.state.e['jurisdiction_array'][this.state.e['jurisdiction_array'].length - 1]}
                                isRequired
                                name={"jurisdiction_array"}
                                placeholder={"what jurisdiction is it going to run in?"}
                                options={this.storage.county}
                                changeCallback={e => this.collectJurisdictions(e)}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <List title={'List of counties this operation will run in'}
                                  elements={this.state.dataItem1}
                                  elementIds={this.state.e['jurisdiction_array']}
                                  removeFromList={this.removeFromList}
                            />
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            <WebAddressField
                                bold
                                capitalize
                                defaultValue={this.state.e["promotion_url"]}
                                name={"promotion_url"}
                                placeholder={
                                    "what is the website address (for operator-registration or promotion) is in use?"
                                }
                                changeCallback={this.collectFieldData}
                            />
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
        );
    };
}

/**
 *
 * MapViewer all state that is recorded in local storage to the state of the current component.
 * Any class that wields this state (through inheritance) has access to the mapped
 * state from local-storage.
 *
 * @param localStorageState the state as recorded in the local storage
 * Do not connect the parent class because connected classes MUST NEVER BE SUBCLASSED!
 *
 */
// load data from redux and populate it into the GMS state. Licensor is not used anywhere else
const mapStateToProps = (localStorageState) => {
    let p = {};
    //the jwt, email of agent, operator-registration details
    const itemsOfInterest = ["county", "LOCAL_BACKUP"];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    // populate dataItem
    return p; //seems there are efficiency problems here...
};

// find a way for inheriting a connected component...
// learned from https://daveceddia.com/redux-connect-export/ (does not work!)
// this is a wrapped component with the GMS as part of it...
//
// Note: Connected components_to_delete MUST NEVER BE SUB-CLASSED!
//                      :: https://www.scrivito.com/connect-component-1c81c8d3b87493af
//
export default connect(mapStateToProps)(JurisdictionAndAccessFields); //component must have a render method
