import GMS from "../../parent-component/GMS";
import {Section, SectionColumn, SectionRow} from "../view-utils/SectionUtils";
import SelectField from "../../general-components/input-field/SelectField";
import {
    csvNotify,
    formatDate,
    notify,
    parseCsv,
    readFromLocalStorage,
    serializeDataWithColumns
} from "../../../MiscUtils";
import {BCLB, NULL_BACKUP, SUBTITLE} from "../../general-components/redux/allowed-actions";
import FileSelectField from "../../general-components/input-field/FileSelectField";
import {Dispatch} from "../../general-components/redux/app-storage";
import SubmitButton from "../../general-components/controls/buttons/SubmitButton";
import DynamicTable from "../../general-components/tables/DynamicTable";
import React from "react";
import {connect} from "react-redux";
import RadioField from "../../general-components/input-field/RadioField";

/**
 *
 * Upload a files returns
 *
 */
class FileReturns extends GMS {
    constructor(props) {
        super(props);
        Dispatch({type: NULL_BACKUP});
        this.serializingFields = {
            // Bookmaking: [
            //     'revenue',
            //     'sales',
            //     'payouts',
            //     'tables',
            //     'slots',
            //     'date_start',
            //     'date_end',
            //     // 'license_type'
            // ],
            Bookmaking: [
                'deposits',
                'total_sales',
                'excise',
                'ggr',
                'payouts',
                'wht',
                'betting_tax',
                'date_start',
                'date_end'
            ],
            'Public Gaming-Online': [
                'stakes',
                'payouts',
                'withholding_tax',
                'ggr',
                'gaming_tax',
                'date_start',
                'date_end'
                // 'license_type'
            ],
            'Public Gaming': [
                'tables',
                'slots',
                'ggr',
                'payouts',
                'date_start',
                'date_end'
                // 'license_type'
            ], 'Public Lottery': [
                'total_tickets',
                'sales',
                'payouts',
                'ggr',
                'ggr_tax',
                'wht',
                'date_start',
                'date_end'
                // 'license_type'
            ]
        };
        this.showableFields = {
            //     Bookmaking:['revenue',
            //     'sales',
            //     'payouts',
            //     'tables',
            //     'slots',
            //     'start_date',
            //     'end_date'
            // ],
            'Bookmaking': ['deposits',
                'total_sales',
                'excise_(12.5%)',
                'ggr',
                'payouts',
                'wht(20%)',
                'betting_tax(15%)',
                'start_date',
                'end_date'
            ],
            'Public Gaming-Online': [
                'stakes',
                'payouts',
                'withholding_tax',
                'ggr(sales_-_payouts)',
                'ggr_tax',
                'gaming_tax',
                'start_date',
                'end_date'
            ],
            'Public Gaming': [
                'tables',
                'slots',
                'ggr(tables_+_slots)',
                // 'ggr_tax',
                'start_date',
                'end_date'
            ],
            'Public Lottery': [
                'total_tickets',
                'sales',
                'winnings',
                'ggr(sales_-_payouts)',
                'ggr_tax',
                'wht',
                'start_date',
                'end_date'
            ],
        }; // doesnt need to show operation/license_type

        // this.bookmakersTemplateFieldsLine = 'REVENUE,SALES,PAYOUTS,TABLES,SLOTS,START DATE,END DATE';
        this.bookmakersTemplateFieldsLine = 'DEPOSITS,TOTAL STAKE/SALES,GGR,PAYOUTS,EXCISE (12.5%),WHT(20%),BETTING TAX(15%),START DATE,END DATE';
        this.onlinePublicGamingTemplateFieldsLine = 'STAKES,PAYOUTS,WITHOLDING TAX,GGR (Sales - Payouts),GGR TAX,GAMING TAX,START DATE,END DATE';
        this.publicGamingTemplateFieldsLine = 'TABLES,SLOTS,GGR (Tables + Slots),START DATE,END DATE';
        this.publicLotteryTemplateFieldsLine = 'TOTAL TICKETS,SALES,WINNINGS,GGR (Sales - Payouts),GGR TAX,WHT,START DATE,END DATE';
        // this.templateFieldsLine = 'REVENUE,SALES,PAYOUTS,TABLES,SLOTS,START DATE,END DATE';
        // template fieldlines map
        this.templateFieldLines = {
            'Bookmaking': this.bookmakersTemplateFieldsLine,
            'Public Gaming': this.publicGamingTemplateFieldsLine,
            'Public Lottery': this.publicLotteryTemplateFieldsLine
        };
        // allow tracking of date field format across all operations
        this.dateIndices = {
            'Bookmaking': [7, 8],
            'Public Gaming': [3, 4],
            'Public Lottery': [6, 7],
        };

    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'File/upload transactions returns'});
        // fetch the game types from storage
        let s = readFromLocalStorage('operator', BCLB)[0];
        const d = readFromLocalStorage('game', BCLB);
        // get list of all licenses for this operator
        this.sendRequest('get', 'company/operator/license/license', licenses => {
            if (licenses.type === 'Success') {
                let p = [];
                licenses.data.map((license, index) => {
                    let y = {};
                    y[license.id] = `${license['game_type']} ${license['application_type']} - ${license.id}`
                    // console.log(license['game_type'],license['application_type'],license.id)
                    p.push(y);
                });
                this.setState({dataItem1: [...p]});
            }
        }, {});
        // if (s) {
        //     // get the names of games from game
        //     let p = [];
        //     let x = 0;
        //     do {
        //         const f = {};
        //         f[s['game_type'][x]] = extractValueFromJSON(s['game_type'][x], arrayOfJSONsToJSON(d));
        //         p.push(f);
        //         x += 1;
        //     } while (x < s['game_type'].length);
        //     this.setState({dataItem1: [...p]});
        // }
    }
    render = () => {
        //
        return <Section>
            {this.state.currentRoute}
            <SectionRow>
                {/*<SectionColumn/>*/}
                <SectionColumn>
                    <SelectField options={this.state.dataItem1}
                                 placeholder={'Select operation whose returns are to be filed'}
                                 name={'license'}
                                 bold
                                 colon
                                 isRequired
                        // capitalize
                                 changeCallback={
                                     e => {
                                         this.collectFieldData([e.target.name, e.target.options[e.target.selectedIndex].value]).then(() => {
                                             // take what was chosen (the game) and use that to dictate the templates file to use.
                                             const names = {
                                                 'Bookmaking': 'bookmaker_returns_template.csv',
                                                 'Public Gaming': 'public-gaming_returns_template.csv',
                                                 'Public Lottery': 'public-lottery_returns_template.csv'
                                             };
                                             // console.log(e.target.options[e.target.selectedIndex].innerText)
                                             let operation = e.target.options[e.target.selectedIndex].innerText.split(' ');
                                             if (operation.length === 4)
                                                 operation = operation[0];
                                             else
                                                 operation = `${operation[0]} ${operation[1]}`;
                                             this.setState({dataItem3: names[operation], dataItem5: operation}, () => {
                                                 // if book making or public gaming,
                                                 // show
                                             });
                                         });
                                     }
                                 }/>
                </SectionColumn>
                <SectionColumn/>
            </SectionRow>
            {/*// show the type of operation, if it is online or brick-and-mortar*/}
            {
                this.state.dataItem3 === 'public-gaming_returns_template.csv' &&
                <SectionRow>
                    {/*// ask them if it is online or brick-and-mortar*/}
                    <SectionColumn>
                        <SectionRow>
                            <SectionColumn>
                                <b>
                                    Which platform is the {this.state.e['license']} operation presented?
                                </b>
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <span>
                                    <RadioField fontSize={14}
                                                name={'platform_type'}
                                                placeholder={'Online'}
                                                changeCallback={e => {
                                                    this.collectFieldData(e).then(r => {
                                                    });
                                                    // update dataItem5 with the correct
                                                    this.setState({dataItem3: 'online-public-gaming_returns_template.csv'});
                                                }}/>
                                    &ensp;
                                    <RadioField fontSize={14}
                                                name={'platform_type'}
                                                placeholder={'Brick-and-Mortar'}
                                                changeCallback={e => {
                                                    this.collectFieldData(e).then(r => {
                                                    });
                                                    this.setState({dataItem3: 'public-gaming_returns_template.csv'})
                                                }}/>
                                </span>
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>
            }
            {
                this.state.e['license'] && this.state.e['platform_type'] && <SectionRow>
                    <SectionColumn>
                        <SectionRow>
                            <SectionColumn>
                                In this section, you can upload your returns by populating a csv
                                file then uploading it here.
                                <br/>
                                Download and use the template below, fill it with your returns information according to
                                the format
                                within, then upload it.
                                <br/>
                                <span><b>Ensure</b> that the <b>date has the format DD-MM-YYYY</b>.</span>
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn>
                                <ol>
                                    <li>
                                        <SectionRow>
                                            <SectionColumn>
                                                <a href={`../downloads/${this.state.dataItem3}`}
                                                   download={`${this.props.operator[0].company_name}-${this.state.dataItem3}`}>
                                                    Download the returns CSV template
                                                </a>
                                            </SectionColumn>
                                        </SectionRow>
                                    </li>
                                    <li>
                                        <SectionRow>
                                            {/*<SectionColumn/>*/}
                                            <SectionColumn>
                                                <FileSelectField name={'returns_file'}
                                                                 fontSize={14}
                                                                 placeholder={'Upload the returns CSV file'}
                                                                 changeCallback={e => {
                                                                     // process the csv file and extract the following fields:
                                                                     // return_date
                                                                     // sales
                                                                     //payouts
                                                                     //revenue
                                                                     // license_type
                                                                     // tables
                                                                     // slots
                                                                     const resetArray = [];

                                                                     this.setState({
                                                                         dataItem2: [...resetArray],
                                                                         miscField: e.target.files[0]
                                                                     }, () => {
                                                                         parseCsv(e.target.files[0], fileData => {
                                                                             // get rid of the last character in fileData items
                                                                             let p = [];
                                                                             let i = 0;
                                                                             let cont = false;
                                                                             // remove the quotation marks
                                                                             fileData = fileData.replace("\"", '');
                                                                             fileData = fileData.replace("\'", '');
                                                                             fileData = fileData.split('\n');

                                                                             do {
                                                                                 // if()
                                                                                 if (fileData[i].length === 0 || fileData[i] === '\n')
                                                                                     break;
                                                                                 let fd = fileData[i];
                                                                                 if (this.templateFieldLines[this.state.dataItem5] !== fd) {
                                                                                     try {
                                                                                         fd = fd.replace('\n', '');
                                                                                         fd = fd.replace('\t', '');
                                                                                         let r = fd.split(',');
                                                                                         // check for the authenticity of the date at the specified indeces
                                                                                         console.log(this.state.dataItem5)
                                                                                         this.dateIndices[this.state.dataItem5].map((ind) => {
                                                                                             const d = formatDate(r[ind], 'dmy', 'ymd', '-');
                                                                                             // console.log(r[ind])
                                                                                             cont = !isNaN(Date.parse(d))
                                                                                         });
                                                                                         if (cont === true) {
                                                                                             let t = fd.split(',');
                                                                                             // reformat date to yyyy-mm-dd
                                                                                             this.dateIndices[this.state.dataItem5].map((indexDate, index) => {
                                                                                                 t[indexDate] = formatDate(t[indexDate], 'dmy', 'ymd', '-');
                                                                                             });
                                                                                             p.push(t);
                                                                                         } else {
                                                                                             csvNotify(
                                                                                                 <span>{fd} - <br/><br/><b>Invalid date format</b></span>, i)
                                                                                             // nullify dataitem 4 and 6
                                                                                             this.setState({
                                                                                                 dataItem4: [...resetArray],
                                                                                                 dataItem6: [...resetArray]
                                                                                             });

                                                                                             break;
                                                                                         }
                                                                                     } catch (InvalidString) {
                                                                                         console.log('invalid string ', InvalidString);
                                                                                         csvNotify(<span>Error while processing file:<br/>
                                                                                         Malformed line <br/>
                                                                                         <b>{fd}</b></span>, i);
                                                                                         this.setState({
                                                                                             dataItem4: [...resetArray],
                                                                                             dataItem6: [...resetArray]
                                                                                         });
                                                                                         break;
                                                                                     }
                                                                                 }
                                                                                 i += 1;
                                                                             } while (i < fileData.length);
                                                                             // console.log(this.showableFields[this.state.dataItem5], p)
                                                                             const tableData = serializeDataWithColumns(this.showableFields[this.state.dataItem5], p);
                                                                             const serializedData = serializeDataWithColumns(this.serializingFields[this.state.dataItem5], p);
                                                                             this.setState({
                                                                                 dataItem6: [...tableData],
                                                                                 dataItem4: [...serializedData]
                                                                             });
                                                                         });
                                                                     })
                                                                 }}/>
                                            </SectionColumn>
                                            <SectionColumn style={{width: 20}}/>
                                            <SectionColumn>
                                                &ensp;
                                                {this.state.dataItem4.length > 0 &&
                                                <SubmitButton
                                                    commandText={'upload'}
                                                    callback={
                                                        () => {
                                                            // console.log(this.state.dataItem4)
                                                            // let fd = new FormData();
                                                            // fd.append('operation', this.state.e['operation']);
                                                            // fd.append('1', this.state.e['returns_file']);
                                                            // this.sendRequest('post',
                                                            //     'account/upload/?obj=returns',
                                                            //     response => {
                                                            //         //console.log(response.data);
                                                            //     }, fd);
                                                            this.sendRequest('post',
                                                                `company/operator/returns/?license_id=${this.state.e['license']}`,
                                                                response => {
                                                                    // this.sendRequest('post', 'company/operator/returns', response => {
                                                                    if (response.type === 'Success') {
                                                                        notify('Uploaded returns', 4, true);
                                                                        window.setTimeout(() => {
                                                                            let u = new FormData();
                                                                            u.append('1', this.state.miscField)
                                                                            this.sendRequest('post', 'account/upload/?obj=returns',
                                                                                response => {
                                                                                    window.setTimeout(() => {
                                                                                        this.navigateTo('../list-return-uploads')
                                                                                    }, 3000)
                                                                                }, u);
                                                                        }, 2500);

                                                                    } else {
                                                                        notify(`Failed to upload returns. The reported error was: ${response.message}`,
                                                                            2,
                                                                            false,
                                                                            'Returns uploads failed');
                                                                    }
                                                                }, this.state.dataItem4);
                                                        }}/>
                                                }
                                            </SectionColumn>
                                        </SectionRow>
                                    </li>
                                </ol>
                            </SectionColumn>
                        </SectionRow>
                    </SectionColumn>
                </SectionRow>

            }

            {
                this.state.dataItem6.length > 0 &&
                <SectionRow>
                    <SectionColumn>
                        <DynamicTable
                            noSearch
                            context={'Returns uploaded'}
                            tableData={this.state.dataItem6}
                            columnsOfInterest={this.showableFields[this.state.dataItem5]}
                            pageSize={24}
                        />
                    </SectionColumn>
                </SectionRow>
            }
        </Section>
    }
}

const stateToProps = initialState => {
    let p = {};
    p['operator'] = initialState.operator;
    return p;
}
export default connect(stateToProps)(FileReturns);
