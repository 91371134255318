import './base/svg-controls.css'
import BaseControl from "./base/BaseControl";

/**
 * Class defines a svg-based buttons to remove content from something of interest.
 * The callback passed to it during its call defines its behaviour.
 */
export default class LineControl extends BaseControl {
    constructor(props) {
        super(props);
        this.callback = props.callback;
        this.style = props.style === undefined ? {width: 60, height: 60} : props.style;//square width and height of 60 px
        //original colour of the control
        // #141124
    }

    render = () => {
        return this.props.horizontal ?
            <svg style={{...this.style}} onClick={this.callback} className={`${this.className}`} width="48px"
                 height="48px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14"/>
            </svg>
            : <svg style={{...this.style}} onClick={this.callback} className={`${this.className}`}
                   width="48px" height="48px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.71,3.29a1,1,0,0,0-1.42,0l-18,18a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l18-18A1,1,0,0,0,21.71,3.29Z"/>
            </svg>
    }
}
