import GMS from "../../../../parent-component/GMS";
import {arrayOfJSONsToJSON, extractValueFromJSON, notify, readFromLocalStorage} from "../../../../../MiscUtils";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import {BCLB, LOCAL_BACKUP, NULL_BACKUP, PAYFOR, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import React from "react";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";
import {connect} from "react-redux";

class CompleteLicenseApplication extends GMS {
    constructor(props) {
        super(props);
        // this.props = getStorageObject(BCLB);

    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({dataItem1: nextProps.bufferData ? [...nextProps.bufferData] : []});
    }

    componentDidMount = () => {
        Dispatch({
            type: PAYFOR,
            payload: arrayOfJSONsToJSON(readFromLocalStorage('game', BCLB))[readFromLocalStorage(LOCAL_BACKUP, BCLB).game_type]
        })
        this.setState({
            passableData: this.props.passableData,
            dataItem1: this.props.bufferData ? [...this.props.bufferData] : this.state.dataItem1,
            county: this.props.county,
            email: this.props.email,
            runReasons: this.props.run_reason, //reasons for running this game
            gameTypes: this.props.game,
            e: {...this.props.LOCAL_BACKUP},
            applicationTypes: this.props.application_type // list of application types
        }, () => {
            //console.log('state post-update in UNSAFE -> ', this.state)
            /*
            * populate various state run-time variables
             */
            // do not do anything if any of these is not yet back
            if (this.state.county === undefined ||
                // this.state.runReasons === undefined ||
                this.state.gameTypes === undefined ||
                this.state.applicationTypes === undefined)
                return;
            this.setState({
                promotion_url: this.props.LOCAL_BACKUP.promotion_url,
                paybill_or_ussd_code: this.props.LOCAL_BACKUP.paybill_or_ussd_code,
                jurisdiction_array: extractValueFromJSON(this.props.LOCAL_BACKUP.jurisdiction_array, arrayOfJSONsToJSON(this.state.county)),
                // runReason: this.getAimOfGame(nextProps.LOCAL_BACKUP.game_cause), //this is a new variable introduced on-the-fly
                gameType: extractValueFromJSON(this.props.LOCAL_BACKUP.game_type, arrayOfJSONsToJSON(this.state.gameTypes)),
                applicationType: extractValueFromJSON(this.props.LOCAL_BACKUP.application_type, arrayOfJSONsToJSON(this.state.applicationTypes)),
            });
            Dispatch({type: SUBTITLE, payload: 'complete application'});
        });
    }
    /**
     *
     * Construct prize views encompassing all information on the prizes to be won
     *
     * @returns {[]}
     */
    constructPrizeViews = () => {
        let prizeViews = [];
        if (this.state.e['prizes']) {
            let k = 0;
            do {
                prizeViews.push(<SectionRow>
                    <SectionColumn>
                    <span>
                        <SimpleLabel text={`Prize ${k + 1} is `} bold fontSize={14} capitalize/>
                        <SimpleLabel
                            colour={'#1259d3'}
                            text={arrayOfJSONsToJSON(readFromLocalStorage('prize_category', BCLB))[this.state.e['prizes'][k]['winner_prize']]}/>
                        <SimpleLabel text={'Valued at'} fontSize={14}/>
                        <SimpleLabel bold text={`ksh. ${this.state.e['prizes'][k]['prize_valued_at']}`} fontSize={14}/>
                        </span>
                        <span>
                            <SimpleLabel text={'Details about prize'} bold fontSize={14}/>
                        <SimpleLabel text={this.state.e['prizes'][k]['prize_description']} fontSize={14}/>
                        </span>
                    </SectionColumn>
                </SectionRow>)
                k += 1
            } while (k < this.state.e['prizes'].length);
        }
        return prizeViews;
    }
    /**
     *
     * Construct list of jurisdictions
     * @param jurisdictionsList
     * @returns {[]}
     */
    listJurisdictions = (jurisdictionsList = this.state.e['jurisdiction_array']) => {
        let jurisdictions = [];
        let x = 0;
        do {
            jurisdictions.push(<SimpleLabel
                text={this.state.e ? extractValueFromJSON(jurisdictionsList[x], arrayOfJSONsToJSON(this.props.county)) : '-not-stated-'}
                colour/>)
            x += 1;
        } while (x < jurisdictionsList.length)
        return jurisdictions;

    }
    // do a section looking for data from all other items
    render = () => {
        const gameType = arrayOfJSONsToJSON(readFromLocalStorage('game', BCLB))[readFromLocalStorage(LOCAL_BACKUP, BCLB).game_type];
        const applicationType = arrayOfJSONsToJSON(readFromLocalStorage('application_type', BCLB))[this.state.e.application_type];
        //console.log(this.state.gameTypes, ' ', this.state.e)
        return <SectionColumn>
            <SectionRow boxedColour>
                {/*<SectionColumn>*/}
                {/*    <SimpleLabel text={'application type'} fontSize={14} capitalize bold/>*/}
                {/*    <SimpleLabel text={this.state.e ? arrayOfJSONsToJSON(this.stor) : '-not-selected-'}*/}
                {/*                 colour/>*/}
                {/*</SectionColumn>*/}
                <SectionColumn>
                    <SimpleLabel text={'Operation selected'} fontSize={14} capitalize bold/>
                    <SimpleLabel
                        text={this.state.e['game_type'] ? arrayOfJSONsToJSON(this.state.gameTypes)[this.state.e['game_type']] : '-not-selected-'}
                        colour/>
                </SectionColumn>
                <SectionColumn>
                    <SimpleLabel text={'Purpose of operation'} fontSize={14} capitalize bold/>
                    <SimpleLabel
                        text={this.state.e['game_cause'] ?
                            arrayOfJSONsToJSON(readFromLocalStorage('run_reason', BCLB))[this.state.e['game_cause']] : '-not-stated-'}
                        colour/>
                </SectionColumn>

                <SectionColumn>
                    <SimpleLabel
                        text={`${this.state.e['jurisdiction_array'] && this.state.e['jurisdiction_array'].constructor.name === [].constructor.name &&
                        this.state.e['jurisdiction_array'].length > 0 ? 'Jurisdictions ' : 'Jurisdiction'} of Operation`}
                        fontSize={14} capitalize bold/>
                    {
                        this.state.e['jurisdiction_array'] && this.state.e['jurisdiction_array'].constructor.name === [].constructor.name ?
                            this.state.e['jurisdiction_array'].length > 1 ? this.listJurisdictions(this.state.e['jurisdiction_array']) :
                                <SimpleLabel
                                    text={this.state.e ? extractValueFromJSON(this.state.e['jurisdiction_array'],
                                        arrayOfJSONsToJSON(this.props.county)) : '-not-stated-'}
                                    colour/> : <span>No jurisdictions listed</span>
                    }
                </SectionColumn>
            </SectionRow>
            {
                this.state.e['game_trade_name'] &&
                <SectionRow boxedColour>
                    <SectionColumn>

                        <>
                            <SimpleLabel text={'game name'} fontSize={14} capitalize bold/>
                            <SimpleLabel text={this.state.e ? this.state.e['game_trade_name'] : '-not-stated-'}
                                         colour/>
                        </>

                    </SectionColumn>
                    <SectionColumn>
                        {/*<SimpleLabel text={'Shareholder count'} fontSize={14} capitalize bold/>*/}
                        {/*<SimpleLabel*/}
                        {/*    text={this.state.e ? this.state.e['operator_shareholders'] ? this.state.e['operator_shareholders'].length : 0 : 0}*/}
                        {/*    colour/>*/}
                    </SectionColumn>
                </SectionRow>
            }
            <SectionRow boxedColour>
                <SectionColumn>
                    <SimpleLabel
                        text={`The concept`}
                        bold/>
                    <SimpleLabel
                        text={this.state.e ? this.state.e['description'] : '-not-stated-'}
                        colour/>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn/>
                <SectionColumn>
                    {
                        Object.getOwnPropertyNames(this.state.e).length > 4 &&
                        <SubmitButton
                            commandText={"Complete Application"}
                            callback={() => {
                                Dispatch({
                                    type: PAYFOR,
                                    payload: gameType
                                });
                                this.sendRequest(
                                    "post",
                                    `company/operator/license/license`, //?operator_id=${operator.data[0].id}
                                    (response) => {
                                        //console.log(response)
                                        // check if license response data is an int
                                        if (response.type === 'Fail')
                                            notify(Object.values(response.data[0]), 2, false, `Error while applying for ${gameType}`)
                                        else if (response.type === 'Success') {
                                            Dispatch({type: NULL_BACKUP});
                                            // empty the state.e object in order to stop everything from remembering the data
                                            // that was input!
                                            const emptyState = {};
                                            this.setState({e: {...emptyState}});
                                            // document uploads
                                            // if (this.state.dataItem1.length > 0) {
                                            //     let t = 0;
                                            //     let fd = new FormData();
                                            //     // for all documents that were sent, get or set the file
                                            //     do {
                                            //         const category = this.state.dataItem1[t].category;
                                            //         const fileContent = this.state.dataItem1[t].content;
                                            //         // append category?
                                            //         fd.append(category, fileContent);
                                            //         t += 1;
                                            //     } while (t < this.state.dataItem1.length)
                                            //     this.sendRequest(
                                            //         "post",
                                            //         `account/upload/?obj=license&operator_id=${response.data.id}`,
                                            //         // the identity of the operator-registration that was created
                                            //         response => {
                                            //             if (response.priority !== 1 && response.priority !== 4) {
                                            //                 notify(response.message, response.priority, true);
                                            //             } else
                                            //                 notify(response.message, response.priority, true);
                                            //
                                            //         }, fd // the formData with the files in there...
                                            //     );
                                            //     // Dispatch({type: NULL_BACKUP})
                                            // }
                                            notify(response.message, 4, true);
                                            window.setTimeout(() =>
                                                    this.navigateTo('../../license-permits-list')
                                                , 3000);
                                            // record id of license in redux under MISC_ID, then read it from that side
                                            // go and pay for it
                                            // this should be an array of jsons

                                            // Dispatch({type: MISC_ID, payload: response.data[0]}); // record the entire license object that is returned. Then
                                        } else {
                                            if (response.bigWindow) {
                                                // show extra large window
                                                notify(
                                                    response.message,
                                                    response.priority,
                                                    false,
                                                    response.title);
                                            } else
                                                notify(
                                                    response.message,
                                                    response.priority,
                                                    true
                                                );
                                        }
                                    },
                                );
                                /**
                                 * The code below should execute after the response from server is either
                                 * 200 or [not-recommended-but-still-workable, 304]. follow the steps below:
                                 *
                                 * 1. nullify the backup if the response is positive else LEAVE IT AS IS.
                                 * 2. allow the server to respond with operator-registration list as above and update
                                 *      redux to allow table to reload with fresh data. Else, have the
                                 *      table render data about the operator-registration in-memory to prevent redux
                                 *      memory limit crossing (currently, from forums its limited to
                                 *      5MB though can be over-riden by user manually.
                                 *
                                 */
                            }}
                        />
                    }
                </SectionColumn>
            </SectionRow>
            {this.state.currentRoute}
        </SectionColumn>
    }
}

const stateToProps = initialState => {
    return initialState;
}
export default connect(stateToProps)(CompleteLicenseApplication);