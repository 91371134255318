import {Component} from "react";
import {col12, paraphrase, row} from "../../../MiscUtils";

/**
 *
 * This class defines a thumb nail component.
 *
 */
export default class ThumbNail extends Component {
    constructor(props) {
        super(props);
        //console.log('---------------- props fileContent ', props);
        this.fileType = props.fileContent.split(';')[0].split(':')[1];
        this.callback = props.callback === undefined ? () => {
            console.warn('No callback method issued to ThumbNail component');
        } : props.callback;
        this.thumbnailStyle = props.isAttachment ? 'attachments-thumbnail' : 'thumbnail';
    }

    render = () => {
        return (
            this.props.isAttachment ?
                <object
                    width={60}
                    style={{margin: 2}}
                    type={this.fileType}
                    data={this.props.fileContent}
                /> :
                <div className={row}>
                    <div className={col12}
                        //      onClick={() => {
                        //     this.callback(this.props.filename)
                        // }}
                    >
                        <div className={`${row} ${this.thumbnailStyle}`}>
                            <div className={col12}>
                                <div className={row}>
                                    <div className={col12}>
                                        <object
                                            onClick={() => {
                                                this.callback(this.props.filename)
                                            }}
                                            width={'96%'}
                                            type={this.fileType}
                                            data={this.props.fileContent}
                                        />
                                    </div>
                                </div>
                                <div className={row}>
                                    <div className={`${col12} file-name-label`}>
                                        {
                                            paraphrase(this.props.filename, 26)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
