import GMS from "../../parent-component/GMS";
import {paraphrase} from "../../../MiscUtils";

/**
 *
 * Notification bubble that shows elements on/from menu items posted on the top 'toolsbar' of the UI
 *
 */
export default class NotificationBubble extends GMS {
    constructor(props) {
        super(props);
        // add an event listener for listen for removal
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.notifications)
            this.setState({dataItem4: [...nextProps.notifications]});
    }
    /**
     *
     * Callback for removal of notification bubble. This should be called
     * by a window event listener
     *
     */
    listenForRemoval = () => {
        // add an event listener that listens for notification-bubble component
        // getting mounted and allow the window to add an event listener
        // for removing it by calling props removeNotificationBubble method
        // to remove notifications by calling
        const bubble = document.getElementsByClassName('notification-bubble');
        if (bubble.length > 0) {
            // check the dimensions of the notification bubble
            const width = parseFloat(window.getComputedStyle(bubble[0]).width);
            const height = parseFloat(window.getComputedStyle(bubble[0]).height);
            //
            //
            //
            const top = parseFloat(window.getComputedStyle(bubble[0]).top);
            const left = parseFloat(window.getComputedStyle(bubble[0]).left);
            const right = parseFloat(window.getComputedStyle(bubble[0]).right);
            const bottom = parseFloat(window.getComputedStyle(bubble[0]).bottom);
            const windowWidth = parseFloat(window.innerWidth);
            const windowHeight = parseFloat(window.innerHeight);
            // calculate the outside dimensions
            // left and right based on the bubble width
            // window.alert(`width ${width} height ${height} left ${left} right ${right} bottom ${bottom}
            // screen width ${windowWidth} screen height ${windowHeight}`);
            window.addEventListener('click', e => {
                // get current mouse position
                const mouseX = e.clientX;
                const mouseY = e.clientY;
                //console.log('-----------------------')
                //console.log('mouseX ', mouseX, 'width', width, 'windowWidth ', windowWidth,
                //     'mouseX < (windowWidth- width)', `${mouseX} < (${windowWidth} - ${width})`
                //     , `mouseX ${mouseX} < (windowWidth - width) ${mouseX} < (${windowWidth} - ${width})}`,
                //     mouseX < (windowWidth - width), '<<|>> (mouseX < (windowWidth - width) || mouseX > (left + width)) ',
                //     (mouseX < (windowWidth - width) || mouseX > (left + width)));
                //                 //console.log('-----------------------');
                //
                //console.log('mouseY ', mouseY, 'height ', height, 'windowHeight ', windowHeight,
                //     'mouseY > (windowHeight- height)', `${mouseY} > (${windowHeight} - ${height})`
                //     , `mouseY ${mouseY} > (height + top) ${mouseY} > ${height + top}}`,
                //     mouseY > (height + top));
                //console.log('-----------------------');
                // || mouseX > (left + width)
                //(mouseY < (height - top) ||
                if ((mouseX < (windowWidth - width)) || mouseY > (height + top)) {
                    if (bubble.length > 0)
                        this.props.removeNotificationBubble();
                }
            });
            // window.removeEventListener('click', this.listenForRemoval);
        }
    }

    /**
     *
     * render menu elements
     * @param menuItems
     *
     */
    renderMenuItems = (menuItems = this.props.menuItems) => {
        let k = [];
        let y = 0;
        do {
            let element = menuItems[y];
            const key = Object.getOwnPropertyNames(element)[0];
            k.push(<div className={'notification-element'}
                        onClick={() => {
                            this.props.removeNotificationBubble();
                            this.props.queryNotifications && this.props.queryNotifications();
                            element[key]();
                        }}>
                <span>
                    {key}
                </span>
            </div>);
            y += 1;
        } while (y < menuItems.length)
        this.setState({dataItem1: [...k]}, this.listenForRemoval);
        // this.setState({dataItem1: [...k]});
    }
    /**
     *
     * Populate notifications if notifications are present
     *
     */
    populateNotifications = () => {
        let k = [];
        let y = 0;
        do {
            k.push(<div className={'notification-element'} onClick={this.props.viewNotificationCallback}>
                <span style={{color: '#3272da'}}>{paraphrase(this.props.notifications[y]['text'], 21)}</span>
                <br/>
                <sup style={{
                    textTransform: 'normal',
                    color: '#919396',
                    fontSize: 10
                }}>{this.props.notifications[y]['topic']}</sup>&nbsp;
                <sup style={{color: '#919396', fontSize: 10}}>
                    <i>from {this.props.notifications[y]['user_from']}</i></sup>

            </div>);
            y += 1;
        } while (y < this.props.notifications.length)
        this.setState({dataItem1: [...k]}, this.listenForRemoval);
        // this.setState({dataItem1: [...k]});
    }

    /**
     *
     *  Remove the listener when this component unmounts
     *
     */
    componentWillUnmount() {
        window.removeEventListener('click', this.listenForRemoval)
    }

    /**
     *
     * component did mount, set notifications or objects and callbacks for removing them.
     *
     */
    componentDidMount = () => {
        this.setState({dataItem4: this.props.notifications ? [...this.props.notifications] : []}, () => {
            this.props.notifications && this.state.dataItem4.length > 0 ? this.populateNotifications() :
                this.props.menuItems && this.renderMenuItems();
        }, () => window.addEventListener('click', this.listenForRemoval));
    }

    render = () => {
        return <div className={'notification-bubble'}
                    style={this.props.bubbleStyling || this.props.bubbleStyling && {top: '2vh'}}>
            {this.state.dataItem1}
        </div>
    }
}
