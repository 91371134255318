import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBan,
    faCirclePause,
    faClipboardList,
    faFire,
    faHistory,
    faListCheck,
    faMapLocationDot,
    faNoteSticky,
    faPlay,
    faPrint,
    faShare,
    faTrash,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import GMS from "../../parent-component/GMS";
import SimpleLabel from "../../general-components/labels/SimpleLabel";

import '../custom-views-css.css';
import {faLocationDot} from "@fortawesome/free-solid-svg-icons/faLocationDot";
import {SectionRow} from "../view-utils/SectionUtils";

/**
 *
 * Class concerns availing menu
 *
 */
export default class ContextMenu extends GMS {
    constructor(props) {
        super(props);
        // get all properties concerned with
        // task, delete, stop, destroy, chat
    }

    executeProcedure = (urlData, asCallback = false) => {
        if (urlData.constructor.name === [].constructor.name) {
            // check if the first element is a text in which case execute it first then
            if (urlData[0] instanceof String) {
                this.navigateTo(urlData[0], false);
            } else {
                let y = 0;
                do {
                    if (urlData[y] instanceof String)
                        this.navigateTo(urlData[y], false);
                    else
                        urlData[y]();
                    y += 1;
                } while (y < urlData.length)
            }
        } else if (typeof (urlData) === 'function') {
            urlData();
        } else
            this.navigateTo(urlData, false);
    }
    ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    /**
     *
     * SHow a clipboard for checklist data that executes a procedure
     * for (possibly?) showing a checklist floating window (with drag option)
     *
     * @param propsCallbackData
     *
     */
    showCheckList = (propsCallbackData = this.props.checkList) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-checklist');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-checklist');
                    t.setAttribute('style', 'display: none');
                }} onClick={e => this.executeProcedure(propsCallbackData)}
                className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faClipboardList}
                                 style={{height: 24, color: '#3d72ac', paddingLeft: 12}}
                />
                <span className={'tool-tip-text'} id={'show-checklist'}>
                checklist
            </span>
                </span>
        </>
    }
    /**
     * Start definition of fontawesomeicon methods
     *
     * @returns {JSX.Element}
     * @param propsCallbackData
     */
    showDestroy = (propsCallbackData = this.props.destroy) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-destroy');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-destroy');
                    t.setAttribute('style', 'display: none');
                }} onClick={e => this.executeProcedure(propsCallbackData)}
                className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faFire} style={{height: 20, color: '#d33917', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-destroy'}>
                destroy
            </span>
                </span>
        </>
    }
    /**
     * Start definition of fontawesomeicon methods
     *
     * @returns {JSX.Element}
     * @param propsCallbackData
     */
    showAssign = (propsCallbackData = this.props.assign) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-assign');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-assign');
                    t.setAttribute('style', 'display: none');
                }} onClick={e => this.executeProcedure(propsCallbackData)}
                className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faShare} style={{height: 20, color: '#5cd317', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-assign'}>
                Assign
            </span>
                </span>
        </>
    }
    /**
     *
     * Equivalent to delete
     * @returns {JSX.Element}
     *
     * @param propsCallbackData
     */
    showTrash = (propsCallbackData = this.props.delete) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-trash');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-trash');
                    t.setAttribute('style', 'display: none');
                }}
                onClick={e => this.executeProcedure(propsCallbackData)}
                className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faTrash} style={{height: 20, color: '#49464a', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-trash'}>
                delete
            </span>
                </span>

        </>
    }
    /**
     *
     * Show locations or enable locations
     *
     * @param propsCallbackData
     * @returns {JSX.Element}
     */
    showLocation = (propsCallbackData = this.props.location) => {
        let t;
        return <>
           <span
               onMouseOver={e => {
                   // get element by className tool tip text
                   t = document.getElementById('show-location');
                   t.setAttribute('style', 'display: inline');
               }}
               onMouseOut={e => {
                   t = document.getElementById('show-location');
                   t.setAttribute('style', 'display: none');
               }}
               onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
               <div>
                   <FontAwesomeIcon icon={faLocationDot} style={{height: 24, color: '#323235', paddingLeft: 12}}/>
               </div>
                <div>
                    <span className={'tool-tip-text'} id={'show-location'}>
                show locations
            </span>
                </div>
                </span>

        </>
    }

    /**
     *
     * Show activation procedure.
     *
     * @param propsCallbackData
     * @returns {JSX.Element}
     *
     */
    showActivate = (propsCallbackData = this.props.activate) => {
        let t;
        return <>
           <span
               onMouseOver={e => {
                   // get element by className tool tip text
                   t = document.getElementById('show-activate');
                   t.setAttribute('style', 'display: inline');
               }}
               onMouseOut={e => {
                   t = document.getElementById('show-activate');
                   t.setAttribute('style', 'display: none');
               }}
               onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
               <div>
                   <FontAwesomeIcon icon={faLocationDot} style={{height: 24, color: '#323235', paddingLeft: 12}}/>
               </div>
                <div>
                    <span className={'tool-tip-text'} id={'show-activate'}>
                activate
            </span>
                </div>
                </span>
        </>
    }
    /**
     *
     *
     * @returns {JSX.Element}
     *
     * @param propsCallbackData
     */
    showHistory = (propsCallbackData = this.props.history) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-history');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-history');
                    t.setAttribute('style', 'display: none');
                }} onClick={e => this.executeProcedure(propsCallbackData)}
                className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faHistory} style={{height: 20, color: '#107ae8', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-history'}>
                history
            </span>
                </span>
        </>
    }
    /**
     *
     * Map new locations/add new locations
     * @param propsCallbackData
     * @returns {JSX.Element}
     *
     */
    mapLocation = (propsCallbackData = this.props.mapLocation) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-map-location');
                    t.setAttribute('style', 'display: block');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-map-location');
                    t.setAttribute('style', 'display: none');
                }} onClick={e => this.executeProcedure(propsCallbackData)}
                className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faMapLocationDot} style={{height: 24, color: '#107ae8', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-map-location'}>
                add location
            </span>
                </span>
        </>
    }
    showTask = (propsCallbackData = this.props.task) => {
        let t;
        return <>
           <span
               onMouseOver={e => {
                   // get element by className tool tip text
                   t = document.getElementById('show-task');
                   t.setAttribute('style', 'display: inline');
               }}
               onMouseOut={e => {
                   t = document.getElementById('show-task');
                   t.setAttribute('style', 'display: none');
               }}
               onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faListCheck} style={{height: 24, color: '#49464a', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-task'}>
                task
            </span>
                </span>

        </>
    }
    showStop = (propsCallbackData = this.props.stop) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-stop');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-stop');
                    t.setAttribute('style', 'display: none');
                }} onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faBan} style={{height: 20, color: '#df0b0b', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-stop'}>
                stop
            </span>
                </span>
        </>
    }
    showSuspend = (propsCallbackData = this.props.suspend) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-suspend');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-suspend');
                    t.setAttribute('style', 'display: none');
                }} onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
            <FontAwesomeIcon icon={faCirclePause} style={{height: 20, color: '#cc9834', paddingLeft: 12}}/>
            <span className={'tool-tip-text'} id={'show-suspend'}>
                suspend
            </span>
                </span>
        </>
    }
    /**
     *
     * show start button that looks like play button.
     *
     * @returns {JSX.Element}
     *
     * @param propsCallbackData
     */
    showStart = (propsCallbackData = this.props.start) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-start');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-start');
                    t.setAttribute('style', 'display: none');
                }}
                onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faPlay} style={{height: 20, color: '#41750d', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-start'}>
                proceed/start
            </span>
                </span>
        </>
    }
    /**
     *
     * Print document
     * @returns {JSX.Element}
     *
     * @param propsCallbackData
     */
    showPrint = (propsCallbackData = this.props.print) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-print');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-print');
                    t.setAttribute('style', 'display: none');
                }}
                onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faPrint} style={{height: 20, color: '#49464a', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-print'}>
                print
            </span>
                </span>
        </>
    }
    /**
     *
     * Show the remove icon which is similar to strike off a list "fa-align-slash" of font-awesome icons
     * @returns {JSX.Element}
     *
     * @param propsCallbackData
     */
    removeFrom = (propsCallbackData = this.props.remove) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-remove');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-remove');
                    t.setAttribute('style', 'display: none');
                }}
                onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faXmark} style={{height: 24, color: '#800080', paddingLeft: 12}}/>
                {/*#da1414*/}
                <span className={'tool-tip-text'} id={'show-remove'}>
                remove
            </span>
                </span>
        </>
    }
    /**
     *
     * Show comments
     * @returns {JSX.Element}
     *
     * @param propsCallbackData
     */
    showComments = (propsCallbackData = this.props.comment) => {
        let t;
        return <>
            <span
                onMouseOver={e => {
                    // get element by className tool tip text
                    t = document.getElementById('show-sticky');
                    t.setAttribute('style', 'display: inline');
                }}
                onMouseOut={e => {
                    t = document.getElementById('show-sticky');
                    t.setAttribute('style', 'display: none');
                }}
                onClick={e => this.executeProcedure(propsCallbackData)} className={'contextual-menu-item'}>
                <FontAwesomeIcon icon={faNoteSticky} style={{height: 24, color: '#042ac2', paddingLeft: 12}}/>
                <span className={'tool-tip-text'} id={'show-sticky'}>
                make comment
            </span>
                </span>
        </>
    }
    /**
     *
     * Collect all menu specified
     */
    collectContextMenu = () => {
        let menu = []
        if (this.props.task)
            menu.push(this.showTask());
        if (this.props.start)
            menu.push(this.showStart());
        if (this.props.print)
            menu.push(this.showPrint());
        if (this.props.history)
            menu.push(this.showHistory());
        if (this.props.suspend)
            menu.push(this.showSuspend());
        if (this.props.stop)
            menu.push(this.showStop());
        if (this.props.destroy)
            menu.push(this.showDestroy());
        if (this.props.delete)
            menu.push(this.showTrash());
        if (this.props.remove)
            menu.push(this.removeFrom());
        if (this.props.location)
            menu.push(this.showLocation());
        if (this.props.mapLocation)
            menu.push(this.mapLocation())
        if (this.props.checkList)
            menu.push(this.showCheckList());
        if (this.props.comment)
            menu.push(this.showComments());
        if (this.props.assign)
            menu.push(this.showAssign());
        if (this.props.activate)
            menu.push(this.showActivate());

        return menu;
    }
    render = () => {
        return <SectionRow>
            <span style={{borderRight: '1px solid #903478', padding: '2px 2px'}}>
            {this.props.noOptionsText !== undefined &&
            <SimpleLabel text={'OPTIONS'} bold capitalize colour={'#3e404a'}/>}
        </span> {this.collectContextMenu()}{this.state.currentRoute}</SectionRow>

    }
}