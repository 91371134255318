import GMS from "../../parent-component/GMS";
import {Outlet, Route, Routes} from "react-router-dom";
import LicenseInformation from "../operator/operator-view-sections/LicenseInformation";
import ListOfShareholders from "../../interfaces/user-ui/shared-views/shareholding-viewer/ListOfShareholders";
import ListOfPayments from "../operator/operator-view-sections/ListOfPayments";
import ShopsOutletsList from "../operator/operator-view-sections/ShopsOutletsList";
import TransactionsData from "../operator/operator-view-sections/TransactionsData";
import {WinningsPayouts} from "../operator/operator-view-sections/WinningsPayouts";
import {BCLB, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {getStorageObject, readFromLocalStorage} from "../../../MiscUtils";
import {Dispatch} from "../../general-components/redux/app-storage";
import {SectionColumn, SectionRow} from "../view-utils/SectionUtils";
import React from "react";
import ShareholdersList from "../../interfaces/user-ui/representative/shareholders/ShareholdersList";
import ServicesMap from "../../general-features/eCitizen-integrator/ServicesMap";
import PremiseList from "../../interfaces/user-ui/representative/premises/PremiseList";

// let currentRecordView = {};
/**
 *
 *NOTE -> : In the event of the 2019 National Game Authority Act passes, all instances of the symbol BCLB shall henceforth
 * become NGA
 *
 * This component is either called from a menu click or it is loaded by default
 *
 * This class provides an interface for viewing operator-registration data such as:
 * 1. operator-registration name, business, location, OPTIONAL-PRIVILEGED: [shops, past licenses, payment information (including cheques)]
 * 2. List of games offered, OPTIONAL-PRIVILEGED: [associated instruments and where instruments are found]
 * 3. OPTIONAL-PRIVILEGED: [operator-registration payments (for licenses or other relative to the board)]
 * 4. OPTIONAL-PRIVILEGED: [operator-registration transactional records as per BCLB, targeted tax calculations etc]
 * 5. OPTIONAL-PRIVILEGED: [operator-registration documents]
 * 6. PRIVILEGED: [operator-registration representatives (if anyone within BCLB is accessing this record)
 *          - start to finish times
 *          - names
 *          - contacts
 *          - shareholder resolution document authorizing this representation
 *          - for the operator-registration in question
 *          - currently representing which operator-registration
 *          ]
 * 7. OPTIONAL--CONTEXT-PRIVILEGED: [communications/chats on this operator-registration: each chat representing a different topic/context]
 *
 * NOTE 2 -> on the "CAPITALIZED LABELS":
 *       PRIVILEGED - means that only members of BCLB can see this information.
 *       OPTIONAL-PRIVILEGED - means that depending on the clearance of BCLB staff and/or including the representative
 *                             in question, this information is available to them
 *       OPTIONAL-CONTEXT-PRIVILEGED - means that this information is available to the public but must be in context
 *                             (meaning that this information is availed as a result of a just-in-time requirement).
 *
 * It may also provide actions (based on the user who is logged in) on the said View
 * as a actions component that floats on top of the current view and can be minimized or closed
 * when action is in progress.
 *
 */
export default class LicensePermitView extends GMS {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.storage = getStorageObject(BCLB);
    }

    componentDidMount = () => {
        Dispatch({
            type: SUBTITLE,
            payload: `View document`
        });
        this.sendRequest("get", 'account/user', user => {
            //console.log(user)
            this.setState({dataItem2: [...user.data]});
            // record the following fields:
            // first_name last_name surname - as one field
            // user_identification_id
            // phone_number clientMSISDN
            // email
            // Dispatch({type: OPERATOR, payload: operator.data});
        });
        // console.log(this.storage.misc_id)
        // get the payment information for this license
        const payFor = readFromLocalStorage('pay_for', BCLB);
        this.sendRequest('get',
                `finance/potential-payment/?context=${payFor['context']}&context_id=${this.licenseId ? this.licenseId :
                    this.storage.misc_id['id']}`,//&billDesc=${this.storage.misc_id['billDesc']}
                payments => {
                    // get list of all items within the service map for a given game_type
                    const services = ServicesMap[this.storage.misc_id['game_type']];
                    let categorizedPayments = [];
                    if (services !== undefined) {
                        const gameTypePayments = Object.getOwnPropertyNames(services);
                        // loop through payments['data'] and check whether the current item is inside the
                        // gameTypePayments
                        let y = 0;
                        do {
                            if (gameTypePayments.includes(payments['data']['game_type']))
                                categorizedPayments.push(payments['data']);
                            y += 1;
                        } while (y < payments['data']);

                    } else {
                        // show everything...
                        categorizedPayments = [...payments['data']];
                    }
                    this.setState({dataItem1: [...categorizedPayments]});
                });
            this.sendRequest('get', 'account/upload', documents => {
            if (documents.type === 'Success') {
            this.setState({dataItem3: [...documents.data]});

                // let k = 0;
                // let actualDocs = [];
                // do {
                //     if (documents.data[k].file.length > 0 && documents.data[k].file_url.length > 0) {
                //         actualDocs.push({
                //             name: documents.data[k].file.split('.')[0], date_created: documents.data[k],
                //             context: documents.data[k].object, file: documents.data[k].file
                //         })
                //     }
                //     k += 1;
                // } while (k < documents.data.length);
                // this.setState({dataItem3: [...actualDocs]});
            }
            });
            }

    render = () => {
        // const shareholdersList = this.storage.misc_id['operator_individual_shareholders'] && this.storage.misc_id['operator_company_shareholders'] ?
        //     [...this.storage.misc_id['operator_individual_shareholders'],
        //         ...this.storage.misc_id['operator_company_shareholders']] :
        //     this.storage.misc_id['operator_company_shareholders'] ? this.storage.misc_id['operator_company_shareholders'] :
        //         this.storage.misc_id['operator_individual_shareholders'] ?
        //             [...this.storage.misc_id['operator_individual_shareholders']] : [];
        return <SectionRow>
            <SectionColumn>
                <>
                    {
                        this.props.enableRouting ? <>
                            // render routes configuration
                            <Routes>
                                <Route path={'license-registration-info'} element={<LicenseInformation/>}/>
                                <Route path={'payments-list'} element={<ListOfPayments/>}/>
                                <Route path={'list-shareholders'} element={<ListOfShareholders/>}/>
                                {/*<Route path={'application-payments'} element={<ListOfPayments/>}/>*/}
                                <Route path={'shops-on-license'} element={<ShopsOutletsList/>}/>
                                <Route path={'player-transactions'} element={<TransactionsData/>}/>
                                <Route path={'winnings-payouts'} element={<WinningsPayouts/>}/>
                            </Routes>
                            {/*// this is used to render a component using routes/ it is purely optional*/}
                            <Outlet/>
                        </> : <>
                            {/*payments={this.state.dataItem1}*/}
                            <LicenseInformation userData={this.state.dataItem2}/>
                            {
                                this.state.dataItem1.length > 0 &&
                                <ListOfPayments payments={this.state.dataItem1}/>
                            }
                            <ShareholdersList/>
                            <PremiseList/>
                            {/*{*/}
                            {/*    shareholdersList &&*/}
                            {/*    <ListOfShareholders shareholderList={shareholdersList}/>*/}
                            {/*}*/}
                            {
                                this.state.dataItem1['winnings_payouts'] &&
                                <WinningsPayouts article={this.storage.misc_id}/>
                            }{
                            // this.state.dataItem3.length > 0 && <DocumentsList documentLinks={this.state.dataItem3}/>
                        }
                        </>
                    }
                    {/*// this is used to render a navigate component in order to change the current route/view*/}
                    {this.state.currentRoute}
                </>
            </SectionColumn>
        </SectionRow>
    }
}