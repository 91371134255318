import BaseControl from "./base/BaseControl";

/**
 * Class defines a svg-based buttons to close and remove its host component from a given parent.
 * The callback passed to it during its call defines its behaviour.
 */
export default class CloseControl extends BaseControl {
    constructor(props) {
        super(props);
        this.callback = props.callback;
        this.closeControl = String(`close-control ${this.props.className && this.props.className}`);
    }

    render = () => {
        return (
            <svg style={{
                width: this.props.width ? this.props.width : 24,
                height: this.props.height ? this.props.height : 24,
                background: 'none',
                ...this.props.style
            }} onClick={this.callback}
                 className={`${this.className}`}
                 version="1.1"
                 x="0px" y="0px" viewBox="0 0 47.971 47.971">
                <g>
                    <path fill={this.props.colour}
                          d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
		c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
		C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
		s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
                </g>
            </svg>
        );
    }
}