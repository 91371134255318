import GMS from "../../../../../parent-component/GMS";
import {SectionColumn, SectionRow, SectionTitle} from "../../../../../rotm-custom-views/view-utils/SectionUtils";
import CheckBoxField from "../../../../../general-components/input-field/CheckBoxField";
import {connect} from "react-redux";
import {Dispatch} from "../../../../../general-components/redux/app-storage";
import {SUBTITLE} from "../../../../../general-components/redux/allowed-actions";

/**
 *
 *
 *
 */
export class UtilitiesFields extends GMS {
    constructor(props) {
        super(props);

    }

    componentDidMount = () => {
        this.setState({e: {...this.state.e}});
        Dispatch({type: SUBTITLE, payload: 'Utilities available/employed'});

    }

    render = () => {
        return <SectionRow>
            <SectionColumn boxedColour>
                <SectionRow>
                    <SectionTitle>
                        Utilities
                    </SectionTitle>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <b style={{fontSize: 13}}>Tick if applicable</b>
                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_ac'}
                                       placeholder={'HVAC utility room'}
                                       defaultValue={this.state.e && this.state.e['have_ac']}
                                       fontSize={14}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_storage'}
                                       placeholder={'Stores/storage units'}
                                       fontSize={14}
                                       defaultValue={this.state.e && this.state.e['have_storage']}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_server_room'}
                                       placeholder={'Server room'}
                                       fontSize={14}
                                       defaultValue={this.state.e && this.state.e['have_server_room']}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
            <SectionColumn boxedColour>
                <SectionRow>
                    <SectionTitle>
                        Amenities
                    </SectionTitle>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_sanitation'}
                                       placeholder={'Toilets/hygiene rooms'}
                                       defaultValue={this.state.e && this.state.e['have_sanitation']}
                                       fontSize={14}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_fire_assembly'}
                                       placeholder={'Fire assembly point'}
                                       defaultValue={this.state.e && this.state.e['have_fire_assembly']}
                                       fontSize={14}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
            <SectionColumn boxedColour>
                <SectionRow>
                    <SectionTitle>
                        Security
                    </SectionTitle>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_access_control'}
                                       placeholder={'Access control devices'}
                                       defaultValue={this.state.e && this.state.e['have_access_control']}
                                       fontSize={14}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_cctv'}
                                       placeholder={'Closed circuit surveillance'}
                                       fontSize={14}
                                       defaultValue={this.state.e && this.state.e['have_cctv']}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_vault'}
                                       placeholder={'Secure vault/saferooms'}
                                       defaultValue={this.state.e && this.state.e['have_vault']}
                                       fontSize={14}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />

                    </SectionColumn>
                </SectionRow>
                <SectionRow>
                    <SectionColumn>
                        <CheckBoxField name={'have_bugler_door'}
                                       placeholder={'Strong-doors (burglar proof doors)'}
                                       defaultValue={this.state.e && this.state.e['have_bugler_door']}
                                       fontSize={14}
                                       changeCallback={e => this.collectFieldData([e.target.name, e.target.checked])}
                        />
                    </SectionColumn>
                </SectionRow>
            </SectionColumn>
        </SectionRow>
    }
}

const mapStateToProps = (reduxState) => {
    let data = {}
    if (reduxState.LOCAL_BACKUP)
        data = {...reduxState.LOCAL_BACKUP};
    return data;
}
export default connect(mapStateToProps)(UtilitiesFields);