import GMS from "../../parent-component/GMS";
import {BCLB, MISC_ID, PREV_ROUTE, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {Dispatch} from "../../general-components/redux/app-storage";
import {readFromLocalStorage} from "../../../MiscUtils";
import DynamicTable from "../../general-components/tables/DynamicTable";
import {Section, SectionTitle} from "../view-utils/SectionUtils";

/**
 *
 * Have a dynamic table for listing issues.
 * Take note of the styling that's used here. A significant amount of it is CSS2,
 * especially work relating to the right-hand column that indicates data on the company that's involved
 *
 */
export default class ListIssues extends GMS {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        Dispatch({type: PREV_ROUTE, payload: 'list-issues'});
        // get the id of the company being viewed currently
        this.operatorId = readFromLocalStorage('misc_id', BCLB);
        // const url = this.operatorId ? `api/helpdesk/tickets` : 'operator';
        this.sendRequest('get', `helpdesk/tickets`, operator => {
            this.setState({dataItem1: operator.data}); // this is an object rather than an array
            Dispatch({type: SUBTITLE, payload: 'showing list of issues'});
        })
    }

    /**
     *
     * Fetch this company's data base on the issue in question. This is done by dispatching the id of the company in the
     * table entry that's clicked.
     *
     */
    componentDidMount = () => {
        // of course, load using the id of the company or license in question. Sanasana operator
        // when mounted, pass the data through miscellaneousItems variable
        Dispatch({type: SUBTITLE, payload: 'List issues raised'});
    }

    render = () => {
        return <Section>
            <SectionTitle>
                List of issues
            </SectionTitle>
            {this.state.currentRoute}
            {/*// do a dynamic table here to display data*/}
            <DynamicTable context={'issues'}
                          columnsOfInterest={[
                              'id',
                              'title',
                              'description',
                              'date_created',
                              'status'
                          ]}
                          tableData={this.state.dataItem1}
                          showRowData={(row) => {
                              // dispatch the issue id to view
                              // navigate to view-issue after dispatching the current
                              // issue you are viewing, and the company involved
                              Dispatch({type: MISC_ID, payload: row});
                              Dispatch({type: PREV_ROUTE, payload: `view-issue/${row.id}`})
                              this.navigateTo(`../view-issue/${row.title}`, false);
                          }}/>
        </Section>
    }
}