import {Component} from "react";

/**
 *
 * ImageViewer component draws an image component.
 * Its properties include
 *  1. src -> a base64 string, path string, or blob.
 *  2. callback -> a callback method called on clicking image
 */
export default class ImageViewer extends Component {
    constructor(props) {
        super(props);
        this.callback = props.callback;
        this.width = props.width === undefined ? 600 : props.width;
        this.height = props.height === undefined ? 440 : props.height;
        if (props.src !== null) {
            let image;
            let imageExtension;
            //test out the image format
            if (RegExp(/^data:image\/svg|png|jpg|jpeg;$/).test(props.src))
                image = 'base64';
            else {
                image = props.src.split('.');
                imageExtension = image[image.length - 1];
            }
            //check type of image
            if (props.src instanceof String || image === 'base64' || ['svg', 'jpg', 'jpeg', 'png'].includes(imageExtension)) {
                this.src = props.src;
            } else
                console.warn(TypeError("The 'src' property expects a blob, base64 or image path to show \
                    in the ImageViewer component"));
        } else
            // eslint-disable-next-line no-multi-str
            console.warn(ReferenceError("'src' property is undefined. Call this ImageViewer component with `src` as one of its properties.\
                See wiki on this matter."));
        this.state = {
            src: this.props.src && this.props.src
        }
    }

    componentWillReceiveProps = (nextProps, nextContext) => {
        if (nextProps.src !== null)
            this.setState({src: nextProps.src})
    }

    render = () => {
        return (
            this.state.src && <img width={this.width} height={this.height} onClick={this.callback} className={'_image'}
                                   src={this.state.src}
                                   alt={'no image to show'}/>
        );
    }
}