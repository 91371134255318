import React from "react";
import BaseField from "./base/BaseField";

let textFieldReference = Object();
/**
 *
 * NumberField defines a type where the props
 *
 */
export default class NameField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.nameExp = new RegExp(/^[a-zA-Z]{1,50}$/);
    }

    /**
     *
     * Checks if a name has been typed (without using numbers).
     * @param e element hosting the name
     * @returns {boolean} if its a name or not
     *
     */
    checkIfName = (e) => {
        return this.nameExp.test(e.target.value);
    }

    /**
     * registers a field reference to this instance of component
     */
    componentDidMount() {
        textFieldReference = this;
    }

    render = () => {
        /*
         * onFocus allows the legend to be changed
         */

        return (
            <div className={`form-group`} style={this.props.style}>
                <label className={`${this.state.selection} form-label`} style={this.textStyling}>
                    {this.fieldPlaceHolder}
                    {this.isRequired}
                </label>
                <input
                    className="form-control"
                    {...this.required}
                    ref={this.internalFieldReference}
                    name={this.name}
                    type={this.fieldType}
                    maxLength={this.props.maxLength ? this.props.maxLength : 30}
                    defaultValue={this.props.defaultValue}
                    onFocus={this.highlightOnFocus}
                    readOnly={this.props.readOnly}
                    onChange={e => {
                        if (this.checkIfName(e)) {
                            this.removeContextMessageWarning();
                            this.changeCallback(e);
                        } else {
                            this.showContextMessageWarning('type a proper name!');
                            // return false;
                            // this.stopTyping();
                            // return false;
                        }
                    }}
                    onBlur={() => {
                        this.evaluateControlOnRequired();
                        this.blurCallback();
                    }}
                />
                {this.state.possibleContextMessageBox}
            </div>
        );
    }
}