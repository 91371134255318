import GMS from "../../parent-component/GMS";
import {Section, SectionRow, SectionTitle} from "../view-utils/SectionUtils";
import DynamicTable from "../../general-components/tables/DynamicTable";
import React from "react";
import {BCLB, MISC_ID, RECORD_TO_TASK, SUBTITLE} from "../../general-components/redux/allowed-actions";
import {Dispatch} from "../../general-components/redux/app-storage";
import {getStorageObject, notify} from "../../../MiscUtils";
import LoadingData from "../../general-components/controls/data-loading/LoadingData";

/**
 *
 * Component shows list of licenses nad permits and allows nagivating to a specific
 * License/permits viewer
 *
 */
export default class LicenseRenewalsList extends GMS {
    constructor(props) {
        super(props);
        this.storage = getStorageObject(BCLB);
    }

    componentDidMount = () => {
        Dispatch({type: SUBTITLE, payload: 'List all licenses/permits'});
        // get the current operator
        let url = this.props.status === 'renewal' ? `company/operator/license/license/?status=${9}` :
            'company/operator/license/license';
        this.sendRequest('get', url, listOfLicenses => {
            // '' set state for licenses
            this.setState({visible: true});
            if (listOfLicenses.data.length === 0)
                notify('No licenses found!', 3, true)
            // Dispatch({type: MISC_ID, payload: operator.data[0].id});
            //console.log('list of licenses ', this.state.dataItem2, listOfLicenses.data)
            this.setState({dataItem2: [...listOfLicenses.data]});
        });
    }

    render = () => {
        return <>
            <Section>
                {this.state.currentRoute}
                <SectionTitle>
                    List of applications for renewals of licenses
                </SectionTitle>
                <SectionRow>
                    {
                        !this.state.visible ?
                            <LoadingData hasData={this.state.dataItem2.length === 0} message={'Loading license renewals list...'}/> :
                            <DynamicTable
                                context={'licenses/permits'}
                                columnsOfInterest={
                                    [
                                        'id',
                                        'application_type',
                                        'game_type',
                                        'operator',
                                        'application_status',
                                        // 'license_status',
                                        'status'
                                    ]
                                }
                                tableData={this.state.dataItem2}
                                showRowData={(licenseData) => {
                                    // navigate to the license and permits viewer
                                    // dispatch the id of the license to fetch
                                    // Dispatch({type: MISC_ID, payload: licenseData.operator})
                                    Dispatch({type: RECORD_TO_TASK, payload: licenseData.id});
                                    Dispatch({type: MISC_ID, payload: licenseData})
                                    Dispatch({type: SUBTITLE, payload: `- viewing license record`});
                                    this.navigateTo(`../license-view/${licenseData.operator}/${licenseData.application_type}`,
                                        false, {data: licenseData});
                                }}/>
                    }

                </SectionRow>
            </Section>
            {this.state.currentRoute}
        </>
    }
}