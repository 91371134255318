import React, {Component} from "react";
import {container,} from "../../../../../MiscUtils";

import {connect} from "react-redux";
import GMS from "../../../../parent-component/GMS";
import {COUNTS, MISC_ID, RECORD_TO_TASK, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {Section, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import DynamicTable from "../../../../general-components/tables/DynamicTable";

/**
 *
 * This is a utility class to initialize a Licenses view component
 *
 */
class DefaultDashboardList extends GMS {
    constructor(props) {
        super(props);
    }

    /**
     *
     * This item is not receiving updates: why?
     * @param nextProps
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //
        // variousCounts has the counts object {a:1,b:9,...}
        //
        // check all counts for:
        //
        //  1. all licenses
        //  2. all permits
        //  3. suspended licenses and permits
        //  4. extended licenses and permits
        //  5. active licenses and permits
        //  6. cancel licenses and permits
        //  7. pending licenses and permits
        //
        this.setState({counts: nextProps.counts});
    }

    /**
     *
     * mounts the desired component in local context. This bypasses the
     *  this.props.showTable callback which mounts data on a MinimizableWindow which currently implements
     * a close function when minimized.
     *
     * @param desiredComponent
     */
    mountDesiredComponent = (desiredComponent = Component) => {
        this.setState({dataItem3: null}, () => {
            this.setState({dataItem3: desiredComponent});
        });
    }
    /**
     *
     * get list of licenses
     *
     */
    componentDidMount = () => {
        // nullify counts
        Dispatch({
            type: SUBTITLE,
            payload: "Dash - list"
        });
        Dispatch({type: COUNTS, payload: {counts: 0}});
        // notify('getting list of all licenses and permits...');
        this.sendRequest("get", "company/operator", (operators) => {
            this.setState({dataItem1: operators.data})
        });
        this.sendRequest("get", "company/operator/license/license", (licenses) => {
            //host them in state
            this.setState({dataItem2: licenses.data})

        });
    };
    /**
     * hii haijulikani inafanya nini saa hii
     * @param todo
     */
    addToDo = (todo) => {
        this.setState({
            todos: [...this.state.todos, todo],
        });
    };

    render = () => {
        Dispatch({type: SUBTITLE, payload: 'Displaying lists of operators, licenses and permits'});
        return (
            // --------depth-changes-occurred-here-- (note: the 'container' class styling has been added)-----
            <div className={`${container} content-viewer-section`}>
                {this.state.currentRoute}
                {/*end depth-changes-occurred-here*/}
                {/*<div className={row}>*/}
                <Section>
                    {/* <SectionTitle style={{color: '#800080'}}>
                        Lists of operators and license applications respectively
                    </SectionTitle> */}
                    <SectionTitle>
                        Operators
                    </SectionTitle>
                    {
                        this.state.dataItem1 &&
                        <SectionRow>
                            <DynamicTable
                                columnsOfInterest={['company_name', 'operator_email', 'local_website_address', 'operator_status']}
                                tableData={this.state.dataItem1 ? this.state.dataItem1 : []}
                                showRowData={(operatorData) => {
                                    // navigate to the license and permits viewer
                                    // dispatch the id of the license to fetch
                                    // Dispatch({type: MISC_ID, payload: licenseData.operator})
                                    Dispatch({type: RECORD_TO_TASK, payload: operatorData.id});
                                    Dispatch({type: MISC_ID, payload: operatorData.id})
                                    Dispatch({type: SUBTITLE, payload: `- viewing license record`});
                                    // Dispatch({type: PREV_ROUTE, payload: `../operator-view`});
                                    this.navigateTo(`../operator-view${operatorData.company_name}`, false, {data: operatorData});
                                }}/>
                        </SectionRow>
                    }
                    <SectionTitle style={{marginTop: '4rem'}}>
                        Licenses/Permits
                    </SectionTitle>
                    <SectionRow>
                        <DynamicTable
                            context={'licenses/permits'}
                            columnsOfInterest={[
                                'operator',
                                'application_type',
                                'county_business',
                                // 'application_status',
                                'progress',
                            ]}
                            tableData={this.state.dataItem2 ? this.state.dataItem2 : []}
                            showRowData={(licenseData) => {
                                // navigate to the license and permits viewer
                                // dispatch the id of the license to fetch
                                // Dispatch({type: MISC_ID, payload: licenseData.operator})
                                Dispatch({type: RECORD_TO_TASK, payload: licenseData.id});
                                Dispatch({type: MISC_ID, payload: licenseData.id})
                                Dispatch({type: SUBTITLE, payload: `- viewing license record`});
                                // Dispatch({type: PREV_ROUTE, payload: `../license-view`});
                                this.navigateTo(`../license-view/${licenseData.operator}/${licenseData.application_type}`, false, {data: licenseData});
                            }}/>
                    </SectionRow>
                </Section>
                {/*</div>*/}

            </div>
        );
    };
}

/**
 *
 * MapViewer all state that is recorded in local storage to the state of the current component.
 * Any class that wields this state (through inheritance) has access to the mapped
 * state from local-storage.
 *
 * @param localStorageState the state as recorded in the local storage
 * Do not connect the parent class because connected classes MUST NEVER BE SUBCLASSED!
 *
 */
// load data from redux and populate it into the GMS state. Licensor is not used anywhere else
const mapStateToProps = (localStorageState) => {
    let props = {};
    const itemsOfInterest = ["counts"];
    for (const item of itemsOfInterest) {
        props[item] = localStorageState[item];
    }
    return props; //seems there are efficiency problems here...
};

// connect will return the GMS having been connected to the local storage.
// find a way for inheriting a connected component...
// learned from https://daveceddia.com/redux-connect-export/ (does not work!)
// this is a wrapped component with the GMS as part of it...
//
// Note: Connected components_to_delete MUST NEVER BE SUB-CLASSED!
//                      :: https://www.scrivito.com/connect-component-1c81c8d3b87493af
//
export default connect(mapStateToProps)(DefaultDashboardList); //component must have a render method
