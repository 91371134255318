import React from "react";
import BaseField from "./base/BaseField";

/**
 *
 * NumberField defines a type where the props
 *
 */
export default class CheckBoxField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.bordered = props.bordered ? {border: '1px solid #dddfe0', borderRadius: 4} : null;
    }


    toggleCheck = (e) => {
        // console.log(e.target.checked)
        this.setState({previousValue: e.target.checked});
    }


    render = () => {
        /*
         * onFocus allows the legend to be changed
         */
        // style={{position: 'relative'}}
        return (<div className={`form-group border`} style={{padding: 3, maxWidth: 'fit-content', ...this.bordered}}>
            <input {...this.required}
                   ref={this.internalFieldReference}
                   style={this.props.style}
                   name={this.name}
                // className={"form-check-input"}
                   type={"checkbox"}
                   onFocus={this.highlightOnFocus}
                   onClick={e => {
                       this.toggleCheck(e)
                       this.changeCallback(e);
                   }}
                   onChange={(e) => {
                       this.toggleCheck(e);
                       this.props.data ? this.changeCallback(e, this.props.data) :
                           this.changeCallback(e);
                   }}
                // checked={true}
                //    checked={this.state.previousValue}
                   onBlur={(e) => {
                       this.evaluateControlOnRequired()
                       this.blurCallback(e);
                   }}/>
            <label className={`${this.state.selection} form-label`}
                   style={{...this.textStyling, ...this.props.style}}>&ensp;{this.fieldPlaceHolder}{this.isRequired}</label>
        </div>);
    }
}