import {Component} from "react";
import './blur.css';
import {SectionRow} from "../../../rotm-custom-views/view-utils/SectionUtils";

/**
 *
 * a blur tab contents component
 *
 */
export default class BlurTabContents extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        if (this.props.executeAlternative)
            this.props.executeAlternative()
    }
    render = () => {
        return <SectionRow>
            <div className={'blur-curtain'}>
                Content unavailable!
            </div>
        </SectionRow>
    }
}