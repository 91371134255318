import {col4, container, getStorageObject, notify,} from "../../../../../MiscUtils";

import GMS from "../../../../parent-component/GMS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextField from "../../../../general-components/input-field/TextField";
import SelectField from "../../../../general-components/input-field/SelectField";
import PhoneNumberField from "../../../../general-components/input-field/PhoneNumberField";
import EmailField from "../../../../general-components/input-field/EmailField";
import DateField from "../../../../general-components/input-field/DateField";
import PasswordField from "../../../../general-components/input-field/PasswordField";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import {Dispatch} from "../../../../general-components/redux/app-storage";

import {
    BCLB,
    COMPANY_POSITIONS,
    COUNTRY,
    GENDER,
    LOCAL_BACKUP,
    LOGOUT,
    SHAREHOLDER_TYPE,
} from "../../../../general-components/redux/allowed-actions";

import {connect} from "react-redux";
import React from "react";
import {initialState} from "../../../../general-components/redux/reducer";
import OtpField from "../../../../general-components/input-field/OtpField";
import {Section, SectionColumn, SectionRow} from "../../../../rotm-custom-views/view-utils/SectionUtils";
//
import "../registrations.css";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import FileSelectField from "../../../../general-components/input-field/FileSelectField";

/**
 *
 * Agent registration component provides a means for self-registration of an agent.
 * Extends data collection provider GMS
 * Endpoint: something along the lines of --register-agent--
 *
 */
class AgentRegistration extends GMS {
    constructor(props) {
        super(props);
        this.updateTitle = null;
//         if (props.updateTitle === undefined)
//             console.warn(`No title manager is set for <${this.constructor.name} />.
// Pass a 'titleManager' property when calling this component.`);
//         else this.updateTitle = props.updateTitle;
    }

    /**
     *
     * update state with necessary component data from this component's props
     * @param nextProps
     * @param nextContext
     *
     */
    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            e: nextProps.LOCAL_BACKUP,
            countries: nextProps.country,
            genderList: nextProps.gender,
            dataItem3: nextProps.company_positions,
            //find a way to pre-select the shareholders such that a shareholder can be a agent...?
            // dataItem3: nextProps.shareholder_types === undefined ? [] : nextProps.shareholder_types, // used universally for multiple items
            // jwt: nextProps.jwt -> not needed here
        });
        //state updates but wont rerender
        //set misc
    };
    /**
     * Local remove message
     */
    removeMessage = () => {
        window.setTimeout(() => {
            this.setState({dataItem4: null});
        }, 9000);
    };

    /**
     *
     * gather data from either the server, or the local storage
     *
     */
    componentDidMount() {
        this.setState({
            buttonIsVisible: false,
            visible: {selected: true, colour: '#800080', border_bottom: '2px solid #800080'},
            variable: {selected: false, colour: '#8c898c'},
            // mandatoryFields: [
            //     'first_name',
            //     'last_name',
            //     // 'national_id',
            //     'user_identification_id',
            //     'nationality',
            //     'gender',
            //     'phone_number',
            //     'email',
            //     'since_when',
            //     'position',
            //     'otp_code'
            // ]
        });
        this.sendRequest('get', 'selections/country', country => {
            Dispatch({type: COUNTRY, payload: country.data})
        });
        this.sendRequest("get", "selections/companypositions", (data) => {
            //update local state with this data in the genderList
            Dispatch({type: COMPANY_POSITIONS, payload: data.data});
        });
        this.sendRequest('get', 'selections/gender', (data) => {
            Dispatch({type: GENDER, payload: data.data});
        });
        this.sendRequest('get', 'selections/shareholdertype', (data) => {
            Dispatch({type: SHAREHOLDER_TYPE, payload: data.data});
        });
        /**
         *
         * end test
         *
         */
            //
            //-------------DO NOT REMOVE THESE CODE------------------
            //get operator-registration positions
        const t = getStorageObject(BCLB);
        if (t) {
            this.setState({e: t.LOCAL_BACKUP});
        }
    }

    render = () => {
        return (
            <div className={container} style={{
                boxShadow: '1px 1px 16px -4px #5e595e',
                paddingBottom: 20,
                // borderRadius: 4,
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                padding: '12px 12px',
                // marginTop: '2vh',
                overflow: 'hidden',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                maxHeight: '90vh',
                background: '#FFFFFF'
            }}>
                {this.state.dataItem4}
                <div className={`row representative-spacing`}>
                    {/*<h1 className={"text-center title-header"}>REPRESENTATIVE SELF DECLARATION</h1>*/}
                    <h1 className={"text-center title-header"}>REPRESENTATIVE DECLARATION</h1>
                </div>
                <Section>
                    <SectionRow>
                        {
                            this.state.visible && this.state.visible['selected'] &&
                            <SectionColumn style={{
                                borderBottom: this.state.visible &&
                                Object.getOwnPropertyNames(this.state.visible).includes('border_bottom') ?
                                    this.state.visible['border_bottom'] : '1px solid #cfd2d7',
                                color: this.state.visible && this.state.visible['colour'],
                            }}
                                           className={'control-box'}
                                           onClick={() => {
                                               this.setState({
                                                   visible: {
                                                       selected: true,
                                                       colour: '#800080',
                                                       border_bottom: '2px solid #800080'
                                                   },
                                                   variable: {
                                                       selected: false,
                                                       colour: '#8c898c'
                                                   }
                                               });
                                           }}>
                                About this process
                            </SectionColumn>
                        }
                        <SectionColumn style={{width: 40}}/>
                        {
                            this.state.variable && this.state.variable['selected'] &&
                            <SectionColumn style={{
                                borderBottom: this.state.variable &&
                                Object.getOwnPropertyNames(this.state.variable).includes('border_bottom') ?
                                    this.state.variable['border_bottom'] : '1px solid #cfd2d7',
                                color: this.state.variable && this.state.variable['colour']
                            }}
                                           className={'control-box'}
                                           onClick={() => {
                                               this.setState({
                                                   visible: {
                                                       selected: false,
                                                       colour: '#8c898c'
                                                   },
                                                   variable: {
                                                       selected: true,
                                                       colour: '#800080',
                                                       border_bottom: '2px solid #800080'
                                                   }
                                               });
                                           }}>
                                {/*Self-declare*/}
                            </SectionColumn>
                        }
                    </SectionRow>
                </Section>
                {this.state.visible && this.state.visible['selected'] &&
                <div className={'description-box'}
                     style={{
                         textAlign: 'justify',
                         lineHeight: 1.8
                     }}
                >
                    <sub style={{color: '#800080', fontSize: 48}}>I</sub>n context of the &nbsp;
                    <a target={'_blank'} href={'https://bclb.go.ke'}
                       style={{color: '#800080', fontWeight: 700}}>board</a>,
                    a representative is a person who acts as a contact person between an operator and the board.
                    <br/>
                    &ensp;The representative acts on behalf of the operator. Their work may include:
                    <ul style={{color: '#4c4c52'}}>
                        <li>Declaration of an operator</li>
                        <li>Application of licenses/permits</li>
                        <li>Responding to queries and messages</li>
                        <li>Perform other relevant duties related to the board-operator interactions</li>
                    </ul>
                    <SectionRow>
                        {/*<SectionColumn style={{width: '90vw'}}/>*/}
                        <SectionColumn onClick={() => {
                            this.setState({
                                visible: {
                                    // selected: false,
                                    // colour: '#800080',
                                    colour: '#8c898c',
                                    border_bottom: '2px solid #800080'
                                },
                                variable: {
                                    selected: true,
                                    colour: '#800080',
                                    // colour: '#8c898c',
                                    border_bottom: '2px solid #800080'

                                }
                            });
                        }}>
                            To accomplish this, proceed to&ensp;
                            <u style={{cursor: 'pointer', color: '#800080', fontWeight: 400}}>
                                declare yourself&ensp;
                                <FontAwesomeIcon icon={faChevronRight}/>
                                <FontAwesomeIcon icon={faChevronRight}/>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </u>
                        </SectionColumn>
                    </SectionRow>
                </div>
                }
                {
                    this.state.variable && this.state.variable['selected'] &&
                    <Section className={'application-box'}>
                        <SectionRow boxedColour>
                            <SectionColumn>
                                {this.state.currentRoute}
                                <SectionRow>
                                    <SectionColumn>
                                        <a href={'#'}
                                           onClick={
                                               () => this.navigateTo('../`representative/login')}>
                                            Go to login if already having an account
                                        </a>
                                    </SectionColumn>
                                    <SectionColumn/>
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn>
                                        <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                            Representative Bio
                                        </h4>
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn>
                                        <TextField bold
                                                   fontSize={14}
                                                   capitalize
                                                   defaultValue={
                                                       this.state.e ? this.state.e["first_name"] : null
                                                   }
                                                   isRequired
                                                   name={"first_name"}
                                                   placeholder={"first name"}
                                                   changeCallback={(e) => {
                                                       this.collectFieldData(e).then(() => {
                                                           Dispatch({
                                                               type: LOCAL_BACKUP,
                                                               payload: this.state.e
                                                           });
                                                       });
                                                   }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        <TextField bold fontSize={14}
                                                   capitalize
                                                   defaultValue={this.state.e ? this.state.e["last_name"] : null}
                                                   isRequired
                                                   name={"last_name"}
                                                   placeholder={"middle name"}
                                                   changeCallback={(e) => {
                                                       this.collectFieldData(e).then(() => {
                                                           Dispatch({
                                                               type: LOCAL_BACKUP,
                                                               payload: this.state.e
                                                           });
                                                       });
                                                   }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        <TextField bold fontSize={14}
                                                   capitalize
                                                   defaultValue={this.state.e ? this.state.e["surname"] : null}
                                                   name={"surname"}
                                                   placeholder={"surname"}
                                                   changeCallback={(e) => {
                                                       this.collectFieldData(e).then(() => {
                                                           Dispatch({
                                                               type: LOCAL_BACKUP,
                                                               payload: this.state.e
                                                           });
                                                       });
                                                   }}
                                        />
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow>
                                    {/*<SectionColumn className={`row`}>*/}
                                    <SectionColumn>
                                        <TextField bold fontSize={14}
                                                   capitalize
                                                   defaultValue={
                                                       // this.state.e ? this.state.e["national_id"] : null
                                                       this.state.e ? this.state.e["user_identification_id"] : null
                                                   }
                                                   isRequired
                                            // name={"national_id"}
                                                   name={"user_identification_id"}
                                                   placeholder={"Kenyan ID/Alien ID/Passport"}
                                                   changeCallback={(e) => {
                                                       this.collectFieldData(e).then(() => {
                                                           Dispatch({
                                                               type: LOCAL_BACKUP,
                                                               payload: this.state.e
                                                           });
                                                       });
                                                   }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        <SelectField bold fontSize={14}
                                                     capitalize
                                                     defaultValue={this.state.e["country"]}
                                                     isRequired
                                                     options={this.state.countries ? this.state.countries : []}
                                                     name={"country"}
                                                     placeholder={"nationality"}
                                                     changeCallback={(e) => {
                                                         this.collectFieldData(e).then(() => {
                                                             Dispatch({
                                                                 type: LOCAL_BACKUP,
                                                                 payload: this.state.e
                                                             });
                                                         });
                                                     }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        <SelectField bold fontSize={14}
                                                     capitalize
                                                     defaultValue={this.state.e["gender"]}
                                                     isRequired
                                                     name={"gender"}
                                                     placeholder={"gender"}
                                                     options={this.state.genderList ? this.state.genderList : []}
                                                     changeCallback={(e) => {
                                                         this.collectFieldData(e).then(() => {
                                                             Dispatch({
                                                                 type: LOCAL_BACKUP,
                                                                 payload: this.state.e
                                                             });
                                                         });
                                                     }}
                                        />
                                    </SectionColumn>
                                </SectionRow>
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow boxedColour>
                            <SectionColumn>
                                <SectionRow>
                                    <SectionColumn>
                                        <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                            Position at company
                                        </h4>
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn className={col4}>
                                        <TextField bold
                                                   fontSize={14}
                                                   isRequired
                                                   maxLength={100}
                                                   name={"company_name"}
                                            // defaultValue={
                                            //     this.state.e && this.state.e["since_when"]
                                            // }
                                                   placeholder={"Company name"}
                                                   changeCallback={(e) => {
                                                       this.collectFieldData(e).then(() => {
                                                           Dispatch({
                                                               type: LOCAL_BACKUP,
                                                               payload: this.state.e
                                                           });
                                                       });
                                                   }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn className={col4}>
                                        <TextField bold
                                                   fontSize={14}
                                                   isRequired
                                                   maxLength={24}
                                                   name={"company_registration_number"}
                                                   placeholder={"Company registration number"}
                                                   changeCallback={(e) => {
                                                       this.collectFieldData(e).then(() => {
                                                           Dispatch({
                                                               type: LOCAL_BACKUP,
                                                               payload: this.state.e
                                                           });
                                                       });
                                                   }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        <DateField bold
                                                   onlyPastDates
                                                   fontSize={14}
                                                   isRequired
                                                   name={"since_when"}
                                                   placeholder={"Date of Appointment"}
                                                   changeCallback={(e) => {
                                                       this.collectFieldData(e).then(() => {
                                                           Dispatch({
                                                               type: LOCAL_BACKUP,
                                                               payload: this.state.e
                                                           });
                                                       });
                                                   }}
                                        />
                                    </SectionColumn>
                                    {/*<div className={col4}>*/}
                                    {/*    /!*this will be populated later*!/*/}
                                    {/*    <FileSelectField*/}
                                    {/*        bold*/}
                                    {/*        fontSize={14}*/}
                                    {/*        isRequired*/}
                                    {/*        name={"company_resolution"}*/}
                                    {/*        placeholder={"Upload company resolution"}*/}
                                    {/*        changeCallback={(e) => {*/}
                                    {/*            if (e.target.files.length > 0) {*/}
                                    {/*                // get the file base64*/}
                                    {/*                this.setState((state) => {*/}
                                    {/*                    state.e["company_resolution_file"] = e.target.files[0];*/}
                                    {/*                    return state;*/}
                                    {/*                });*/}
                                    {/*            }*/}
                                    {/*            // delete any company resolution data within*/}
                                    {/*            else*/}
                                    {/*                this.setState((state) => {*/}
                                    {/*                    state.hasAllFieldsFilled = false;*/}
                                    {/*                    state.miscellaneousItems = null;*/}
                                    {/*                    delete state.e["company_resolution"];*/}
                                    {/*                    return state;*/}
                                    {/*                });*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn>
                                        <SelectField bold fontSize={14}
                                                     capitalize
                                                     isRequired
                                                     defaultValue={this.state.e ? this.state.e["position"] : null}
                                                     name={"position"}
                                                     placeholder={"Current position"}
                                                     options={
                                                         this.state.dataItem3 ? this.state.dataItem3 : []
                                                     }
                                                     changeCallback={(e) => {
                                                         this.collectFieldData(e).then(() => {
                                                             Dispatch({
                                                                 type: LOCAL_BACKUP,
                                                                 payload: this.state.e
                                                             });
                                                         });
                                                     }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        {/*<div className={col4}>*/}
                                        {/*this will be populated later*/}
                                        <FileSelectField
                                            bold
                                            fontSize={14}
                                            isRequired
                                            name={"company_resolution_file"}
                                            placeholder={"Upload company resolution"}
                                            changeCallback={(e) => {
                                                if (e.target.files.length > 0) {
                                                    // get the file base64
                                                    this.setState((state) => {
                                                        state.e["company_resolution_file"] = e.target.files[0];
                                                        return state;
                                                    });
                                                }
                                                // delete any company resolution data within
                                                else
                                                    this.setState((state) => {
                                                        state.hasAllFieldsFilled = false;
                                                        state.miscellaneousItems = null;
                                                        delete state.e["company_resolution_file"];
                                                        return state;
                                                    });
                                            }}
                                        />
                                        {/*</div>*/}
                                    </SectionColumn>
                                    <SectionColumn/>
                                </SectionRow>
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow boxedColour>
                            <SectionColumn>
                                <SectionRow>
                                    <SectionColumn>
                                        <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                            {/*Password & Verification*/}
                                            Password
                                        </h4>
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn>
                                        <EmailField bold
                                                    fontSize={14}
                                                    capitalize
                                                    defaultValue={this.state.e ? this.state.e["email"] : null}
                                                    isRequired
                                                    name={"email"}
                                                    placeholder={"email address"}
                                                    changeCallback={(e) => {
                                                        this.collectFieldData(e).then(() => {
                                                            Dispatch({
                                                                type: LOCAL_BACKUP,
                                                                payload: this.state.e
                                                            });
                                                        });
                                                    }}
                                        />
                                    </SectionColumn>
                                    {/*//actual fields in col3*/}
                                    <SectionColumn className={col4}>
                                        <PasswordField bold fontSize={14}
                                                       capitalize
                                                       isRequired
                                            // disablePasswordTest
                                                       passwordCorrectCallback={this.registerIsPasswordValid}
                                                       name={"password"}
                                                       placeholder={"password"}
                                                       changeCallback={this.collectFieldData}
                                            // mouseOutCallback={this.registerIsPasswordValid}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        <PasswordField bold fontSize={14}
                                                       isRequired
                                                       capitalize
                                                       name={"password_confirm"}
                                                       placeholder={"confirm password"}
                                            // passwordCorrectCallback = {this.registerIsPasswordValid}
                                                       blurCallback={e => {
                                                           if (e.target.value === this.state.e.password) {
                                                               this.toggleSubmitButton(this.state.reload);
                                                               this.collectFieldData(e).then();
                                                           } else {
                                                               this.setState({miscellaneousItems: false});
                                                               notify('Passwords do not match!', 2, true);
                                                               this.toggleSubmitButton(false);
                                                           }
                                                           //
                                                       }}
                                        />
                                    </SectionColumn>

                                </SectionRow>
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow boxedColour>
                            <SectionColumn>
                                <SectionRow>
                                    <SectionColumn>
                                        <h4 className={"h2-section-title text-capitalize text-capitalize"}>
                                            Contact Info.
                                        </h4>
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn>
                                        <PhoneNumberField bold fontSize={14}
                                                          capitalize
                                                          defaultValue={
                                                              this.state.e ? this.state.e["phone_number"] : null
                                                          }
                                                          isRequired
                                                          name={"phone_number"}
                                                          placeholder={"phone number"}
                                                          changeCallback={(e) => {
                                                              this.collectFieldData(e).then(() => {
                                                                  Dispatch({
                                                                      type: LOCAL_BACKUP,
                                                                      payload: this.state.e
                                                                  });
                                                              });
                                                          }}
                                            // blurCallback={(e) => {
                                            //     this.sendRequest("post", 'account/otp', otp_response => {
                                            //         // notify if message type is success, and retrieve the message from data field. data field is now a object
                                            //         let feedback = otp_response.data.message;
                                            //         let priority = 5;
                                            //         if (otp_response.priority === 4) {
                                            //             priority = 4;
                                            //             feedback = `OTP code has been sent to the phone number ${e.target.value}`
                                            //         } else priority = 2;
                                            //         notify(feedback, priority, true);
                                            //         // hide this button until otp comes back
                                            //         // compare the otp codes with what is sent to the client
                                            //     }, {phone_number: e.target.value});
                                            // }}
                                        />
                                    </SectionColumn>
                                    <SectionColumn>
                                        <SubmitButton
                                            // disabled={this.state.visible}
                                            commandText={"request OTP"}
                                            style={{
                                                width: 160,
                                                marginTop: 32,
                                                // background: 'rgba(18,77,217,0.71)',
                                                background: '#59788E',
                                                // color: "#800080",
                                                // color: "#06163A",
                                                color: "#FFFFFF",
                                                // border: "1px solid #800080",
                                                border: "1px solid #06163A",
                                                textTransform: "capitalize",
                                            }}
                                            callback={() => {
                                                this.sendRequest("post", 'account/otp', otp_response => {
                                                    // notify if message type is success, and retrieve the message from data field. data field is now a object
                                                    let feedback = otp_response.data.message;
                                                    let priority = 5;
                                                    if (otp_response.priority === 4) {
                                                        priority = 4;
                                                        feedback = `OTP code has been sent to the phone number ${this.state.e['phone_number']}`
                                                    } else priority = 2;
                                                    notify(feedback, priority, true);
                                                    // hide this button until otp comes back
                                                    // compare the otp codes with what is sent to the client
                                                }, {phone_number: this.state.e['phone_number']});
                                                // this.state.e.otp_log = "otp";
                                                // this.sendRequest("post", `account/${LOGIN}`, response => {
                                                //     if (Object.getOwnPropertyNames(response).length === 0)
                                                //         notify(`Error! The user "${this.state.e['email']}" does not exist`, 2, false, 'User not found');
                                                //     else {
                                                //         notify(response.data.message, response.priority, true);
                                                //     }
                                                //     // hide this button until otp comes back
                                                //     // compare the otp codes with what is sent to the client
                                                // });
                                                // // illustrate a delayed response
                                                // window.setTimeout(() => {
                                                //     this.setState({visible: true});
                                                // }, 1600);
                                            }}
                                        />
                                    </SectionColumn>
                                </SectionRow>
                                <SectionRow>
                                    <SectionColumn>
                                        <OtpField
                                            isRequired
                                            capitalize
                                            fontSize={14}
                                            bold
                                            name={'otp_code'}
                                            placeholder={'OTP verification code'}
                                            changeCallback={e => {
                                                if (e.target.value.length >= 2 && e.target.value.length <= 5) {
                                                    this.setState({miscellaneousItems: true});
                                                    this.collectFieldData(e, true).then(() => {
                                                        this.setState({miscellaneousItems: true})
                                                    });
                                                } else
                                                    this.setState({miscellaneousItems: false})
                                            }}/>
                                        {/*paddin top used to make*/}
                                    </SectionColumn>
                                    <SectionColumn/>
                                    <SectionColumn/>
                                    {/*<SectionColumn>*/}
                                    {/*    <TextField bold fontSize={14}*/}
                                    {/*               capitalize*/}
                                    {/*               defaultValue={this.state.e ? this.state.e["post_addr"] : null}*/}
                                    {/*               name={"post_addr"}*/}
                                    {/*               placeholder={"postal address"}*/}
                                    {/*               changeCallback={(e) => {*/}
                                    {/*                   this.collectFieldData(e).then(() => {*/}
                                    {/*                       Dispatch({*/}
                                    {/*                           type: LOCAL_BACKUP,*/}
                                    {/*                           payload: this.state.e*/}
                                    {/*                       });*/}
                                    {/*                   });*/}
                                    {/*               }}*/}
                                    {/*    />*/}
                                    {/*</SectionColumn>*/}
                                </SectionRow>
                            </SectionColumn>
                        </SectionRow>
                        <SectionRow>
                            <SectionColumn/>
                            {
                                // this.state.buttonIsVisible &&
                                <SectionColumn>
                                    <SubmitButton
                                        style={{
                                            // background: 'rgba(18,77,217,0.71)',
                                            background: '#59788E'
                                        }}
                                        commandText={'Create account'}
                                        callback={
                                            () => {
                                                if (!this.state.miscellaneousItems) {
                                                    window.alert('fields are absent')
                                                } else
                                                    this.checkMandatoryFields().then(r => {
                                                        if (!r) {
                                                            if (this.state.missingMandatoryFields) {
                                                                if (!this.state.allMandatoryFieldsAreFulfilled) {
                                                                    // go through the list of names that are
                                                                    // missing in the missingMandatoryFields
                                                                    // notify and return
                                                                    notify(
                                                                        <div>
                                                                            {this.state.missingFieldNames}
                                                                        </div>,
                                                                        3,
                                                                        false,
                                                                        'Mandatory fields must be filled!');
                                                                    return false;
                                                                }
                                                            }
                                                        } else {
                                                            // add role to key to enable backend detect who is registering
                                                            this.setState({role: 4}, () => {
                                                                /*
                                                               * Store the file as a file object
                                                               * Whereas the data in question is String field
                                                               * (look for this.state.e)
                                                               */
                                                                const document = this.state.e["company_resolution_file"];
                                                                // delete the company document and role fields because the
                                                                // representative data needs to be free of these two
                                                                delete this.state.e["company_resolution_file"];
                                                                delete this.state.e["role"];
                                                                const representativeData = this.state.e;
                                                                // re-order data to enable endpoint access this data properly
                                                                const d = {
                                                                    company_resolution_file: document,
                                                                    data: representativeData,
                                                                };
                                                                //console.log('agent registration data ', d)
                                                                // create a form data to send data concerning this field
                                                                let fd = new FormData();
                                                                fd.append(
                                                                    "company_resolution_file",
                                                                    d.company_resolution_file
                                                                );
                                                                fd.append("data", JSON.stringify(d.data));
                                                                // send request to server
                                                                this.sendRequest(
                                                                    "post",
                                                                    "account/register/?type=public",
                                                                    (data) => {
                                                                        // the data representas representative. Use their ID to
                                                                        // send an upload of the resolution file
                                                                        // if(data)
                                                                        //
                                                                        // if data does not contain response or
                                                                        // notifications notify then redirect to another
                                                                        // page to login and see what should be had
                                                                        //
                                                                        if (data.type === 'Fail')
                                                                            notify(data.message, 2, false, 'Account not created!');
                                                                        else {
                                                                            notify(<span>
                                                                                <br/>
                                                                                Your account (or your self-declaration) is complete and successful.
                                                                                <br/>
                                                                                We ask that you be patient as the board reviews your account for activation.
                                                                                <br/>
                                                                                Thank you.
                                                                            </span>, 1, false, `Successful account creation`);
                                                                            if (data)
                                                                                Dispatch({
                                                                                    type: LOGOUT,
                                                                                    payload: initialState
                                                                                });
                                                                        }
                                                                        // notify(data.message, 1, true);
                                                                        window.setTimeout(() => {
                                                                            // refresh page
                                                                            window.location.reload(false);
                                                                        }, 60000);
                                                                    }, fd
                                                                );
                                                            });
                                                        }
                                                    });
                                            }}
                                    />
                                </SectionColumn>
                            }
                            <SectionColumn/>
                        </SectionRow>
                        <br/>
                    </Section>
                }
                <div className={'small-space-row'}/>
                {
                    this.state.dataItem1 !== null ? (
                            this.state.dataItem1.length > 0 ? (
                                <div className={`floating-note`}>
                                    <div className={`floating-note-title`}>
                                        rep's. {this.state.dataItem1.length} other companies
                                    </div>
                                    <div className={"y-scrollable"}>{this.state.dataItem1}</div>
                                </div>
                            ) : null
                        ) :
                        this.state.dataItem1
                }
                {/*</div>*/}
                {/*}*/}
            </div>
        );
    };
}

/**
 *
 * method is called when redux local-storage changes or updates.
 *
 * @param localStorageState the state from local-storage (having been parsed into a JSON object).
 * In this scenario, only countries are to be loaded
 *
 * See documentation of this section in the Licensor js component.
 *
 */
const mapStateToProps = (localStorageState) => {
    let p = {};
    const itemsOfInterest = [
        "gender",
        "country",
        "company_positions",
        "LOCAL_BACKUP",
    ];
    for (const item of itemsOfInterest) {
        p[item] = localStorageState[item];
    }
    return p;
};
export default connect(mapStateToProps)(AgentRegistration);
