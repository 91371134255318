import BaseField from "./base/BaseField";
import React from "react";

export default class KRAPinField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.companyFormula = new RegExp(/^P[0-9]{9}[A-Z]$/);
        this.individualFormula = new RegExp(/^A[0-9]{9}[A-Z]$/);
    }

    /**
     * registers a field reference to this instance of component
     */
    componentDidMount() {
    }

    render = () => {
        // check capitalize or uppercase
        /*
         * onFocus allows the legend to be changed
         */
        return (
            <div className={`form-group`} style={this.props.style}>
                <label className={`${this.state.selection} form-label`} style={this.textStyling}>
                    {this.fieldPlaceHolder}
                    {this.isRequired}
                </label>
                <input
                    className="form-control"
                    {...this.required}
                    ref={this.internalFieldReference}
                    name={this.name}
                    type={this.fieldType}
                    maxLength={this.props.maxLength ? this.props.maxLength : 16}
                    defaultValue={this.props.defaultValue}
                    onFocus={this.highlightOnFocus}
                    onChange={(e) => {
                        if (this.props.forIndividual) {
                            if (this.individualFormula.test(e.target.value)) {
                                this.changeCallback(e);
                                this.removeContextMessageWarning();
                            } else
                                this.showContextMessageWarning('KRA individual pin format is invalid!');
                        } else if (this.props.forCompany) {
                            if (this.companyFormula.test(e.target.value)) {
                                this.changeCallback(e);
                                this.removeContextMessageWarning();
                            } else {
                                // window.alert('ass');
                                this.showContextMessageWarning('KRA company pin format is invalid!');

                            }
                        }

                    }}
                    onBlur={() => {
                        this.evaluateControlOnRequired();
                        this.blurCallback();
                    }}
                />
                {this.state.possibleContextMessageBox}
            </div>
        );
    };
}