import GMS from "../../../../parent-component/GMS";
import {Section} from "../../../../rotm-custom-views/view-utils/SectionUtils";

export default class OddsProvidersList extends GMS{
    constructor(props){
        super(props);
    }
    componentDidMount = () => {
    }
    render = () => {
        return <Section>

        </Section>
    }
}