import {Section, SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";
import {Accordion} from "react-bootstrap";
import GMS from "../../parent-component/GMS";
import SimpleLabel from "../labels/SimpleLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faEnvelope, faMapLocation, faPhone} from "@fortawesome/free-solid-svg-icons";

/**
 *
 * Revised FaQInternal content
 *
 */
export default class FaQ extends GMS {
    constructor(props) {
        super(props);
        this.accordionItem = {display: 'block', alignItems: 'justify'};
        this.headerStyle = {border: 'none', fontSize: 16, fontWeight: 700, color: '#204ff5'};
        this.accordionBodyStyle = {lineHeight: 1.6};
        this.faqHeader = {
            color: '#373992',
            fontSize: 24,
            fontWeight: 600,
        };
    }

    faq = () => {
        this.setState({
            dataItem1: <SectionRow boxedColour>
                <SectionColumn>
                    <Accordion>
                        <Accordion.Item style={{...this.accordionItem}} eventKey={0}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                Why should I create account (register) an account in G.M.S?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>
                                Pursuant to the <a target={'_blank'}
                                                   href={'https://www.president.go.ke/wp-content/uploads/Executive-Order-No.-2-of-2023-.pdf'}>presidential
                                decree No. 2 of 2023
                            </a>
                                &nbsp;requiring all government agencies present
                                their services online, the board has undertaken steps to meet this
                                requirement.
                                The Gaming and Management system is the culmination of this effort in
                                part
                                to meet its obligations to the decree.
                                Further, the board is in the process of digitizing all its services,
                                clientele files among other information
                                for efficient use, dissemination and single-point-of-access for all
                                parties
                                involved.
                                <br/>
                                <br/>
                                The board requires, therefore, that moving forward any company
                                (operator) seeking
                                services from the board must have a registered and verified account.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item style={{...this.accordionItem}} eventKey={2}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                How do I create an account on G.M.S?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>

                                <ol>
                                    <li>
                                        Navigate to this website: <a
                                        href={'https://operator.bclb.go.ke'}>https://operator.bclb.go.ke</a>;
                                    </li>
                                    <li>
                                        Select the <a href={'#'}
                                                      onClick={() => {
                                                          this.navigateTo('../representative/self-declaration')
                                                      }}
                                    >
                                        create account</a> link at the bottom of the form to the right;
                                    </li>
                                    <li>
                                        In the information window provided, select the <b>"Proceed to
                                        declare yourself"</b> link;
                                    </li>

                                    <li>
                                        Fill in the online form provided to capture details about
                                        (you) the representative;
                                    </li>
                                    <li>
                                        Submit the details by selecting on the “create account button”
                                        If
                                        the details submitted are correct, a pop up message shows up
                                        indicating the account has been created succesfully;
                                    </li>
                                    <li>
                                        If the details captured are incorrect, an error message pops up
                                        indicating account creation failed. Hence, a repeat of the same
                                        process required.
                                    </li>
                                </ol>
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item style={{...this.accordionItem}} eventKey={3}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                How do I login to G.M.S?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>
                                Use the form to the right of this Q&A section while following the the
                                procedure below:
                                <br/>
                                <ol>
                                    <li>Type in your email address in the <b>email</b> (top-most) field
                                        provided (the email which you used to create your account in
                                        GMS);
                                    </li>
                                    <li>Type in your password in the <b>password</b> field;</li>
                                    <li>
                                        Click on <b>Request OTP</b> button. (and wait for a one-time
                                        passcode to
                                        show up in your phone as a text message);
                                    </li>
                                    <li>Type in the received code sent to your phone in to
                                        the <b>O.T.P</b> field;
                                    </li>
                                    <li>click the <b>Login</b> button.</li>
                                </ol>
                                <b>NOTE</b> - To login in to the system, an email, valid password and a
                                valid passcode are required.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item style={{...this.accordionItem}} eventKey={1}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                Is there a time limit for using an OTP?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>
                                Yes, <b>One Time Passcodes [OTP]</b> typically <b>have a limited
                                validity
                                period of 5 minutes</b>. After this
                                period, it expires, and a new one must be generated for authentication.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item style={{...this.accordionItem}} eventKey={4}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                What happens if I don't receive the OTP?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>
                                If you don't receive a OTP, It may be at least one of the following
                                reasons:<br/>
                                <ul>
                                    <li>
                                        It might be a network issue. Wait for 2 minutes or thereabout.
                                        If it
                                        is
                                        yet to arrive, contact BCLB support concerning this matter.
                                    </li>
                                    <li>
                                        The contact details used during the self-declaration
                                        (representative
                                        registration) and the phone
                                        which you may be wielding are different. This may lead to the
                                        'original' phone number receiving it in place of your current
                                        one.
                                        In this case, ensure that the number used at the time of
                                        registration is the same one on your person.
                                    </li>
                                </ul>
                                <br/>You may also have the option to request a resend.
                                If issues persist, <a href={'mailto:info@bclb.go.ke'}>contact BCLB
                                support</a>.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item style={{...this.accordionItem}} eventKey={5}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                Is it safe to stay logged in on a public computer?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>
                                The Betting Control and Licensing Board takes exceeding care during
                                collecting, securing, handling, maintaining and
                                disseminating client information to all relevant parties pursuant to
                                the <a
                                href={'https://www.odpc.go.ke/download/kenya-gazette-data-protection-act-2019/?wpdmdl=3235&refresh=65b78e6a6c5191706528362'}>Data
                                protection Act (R.P.K.) 2019</a>, recognising that indeed all companies
                                beholden to the board's guidance
                                fall under the parties protected by this act.
                                <br/>
                                <br/>
                                Owing to the sensitive nature of information belonging to your company,
                                it
                                is not recommended that such access be conducted in publicly accessible
                                devices.
                                However, in the event that urgency gains favour against privacy, always
                                make
                                sure to log
                                out after using any of those public devices to prevent
                                unauthorized access.
                                <br/>
                                <br/>
                                Finally, <b>You are in charge of your own data security</b> and any
                                unauthorized
                                access without legally binding consent would be in contravention of
                                the&nbsp;
                                <a href={'https://www.odpc.go.ke/download/kenya-gazette-data-protection-act-2019/?wpdmdl=3235&refresh=65b78e6a6c5191706528362'}>Data
                                    protection Act (R.P.K.) 2019 - Part IV (43)</a>. Further, such
                                matters
                                arising from (willful) negligence fall
                                outside of the board's mandate in effecting data privacy and discretion.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item style={{...this.accordionItem}} eventKey={6}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                How is my data secured in G.M.S?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>
                                G.M.S employs an assortment security measures with the primary
                                protection
                                being a <b>group of firewalls</b>, and secondary measures in the form
                                of&nbsp;
                                <b>2FA secured data accessibility</b>,
                                <b>long term storage</b>, <b>redundancy</b>, <b>protection against (and
                                recovery from)
                                AdHOC attacks</b>,
                                &nbsp;<b>encryption of data and domain access</b> and performance of
                                scheduled and unscheduled security
                                audits.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item style={{...this.accordionItem}} eventKey={7}>
                            <Accordion.Header style={{...this.headerStyle}}>
                                I'm experiencing technical issues. What should I do?
                            </Accordion.Header>
                            <Accordion.Body style={{...this.accordionBodyStyle}}>
                                <br/>
                                If you're facing technical issues, please:
                                <ul>
                                    <li>
                                        Check your internet connection (This system requires medium
                                        bandwidth averaging 1Mbps;
                                    </li>
                                    <b>or</b>
                                    <li>
                                        Clear your browser cache and cookies;
                                    </li>
                                    <b>or</b>
                                    <li>
                                        Ensure you are using an up-to-date browser.
                                    </li>
                                </ul>
                                If the above fails, please contact BCLB support at [<a
                                href={'mailto:info@bclb.go.ke'}>info@bclb.go.ke</a>] for further
                                assistance.
                                <br/>
                                <br/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </SectionColumn>
            </SectionRow>
        });
    }

    /**
     *
     * Show glossary of terms
     *
     */
    glossary = () => {
        this.setState({dataItem1: null})
    }
    /**
     *
     * Show contact information
     *
     */
    contactInfo = () => {
        this.setState({
            dataItem1: <SectionRow boxedColour>
                <SectionColumn boxedColour>
                    <SectionRow>
                        <SectionColumn>
                            Phone
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            contact info
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
                <SectionColumn boxedColour>
                    <SectionRow>
                        <SectionColumn>
                            email
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            email addresses
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
                <SectionColumn boxedColour>
                    <SectionRow>
                        <SectionColumn>
                            physical address
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            address values
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
        })
    }

    componentDidMount = () => {
        this.faq();
    }

    render = () => {
        return <Section className={'full-window casino-table-background'}>
            <SectionRow className={'p-2 top-banner'}>
                <SectionColumn className={'gms_title'}>
                    Gaming Management System: frequently asked questions
                </SectionColumn>
            </SectionRow>

            <SectionRow>
                {/*<SectionColumn/>*/}
                <SectionColumn>
                    <SectionRow>
                        <SectionColumn style={this.faqHeader}>
                            Contact us through
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow boxedColour>
                        <SectionColumn>
                                    <span>
                                        <FontAwesomeIcon icon={faPhone}
                                                         style={{height: 20, color: '#222434'}}/>

                                        {/*<SimpleLabel text={'phone'} capitalize bold fontSize={16}/>*/}

                                        &nbsp; +254-111021400 , +254-111021407
                                    </span>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow boxedColour>
                        <SectionColumn>
                                <span>
                                    <FontAwesomeIcon icon={faEnvelope}
                                                     style={{height: 20, color: '#222434'}}/>

                                    {/*<SimpleLabel text={'email'} capitalize bold fontSize={16}/>*/}

                                    &nbsp; info@bclb.go.ke
                                </span>
                        </SectionColumn>

                    </SectionRow>
                    <SectionRow boxedColour>
                        <SectionColumn>
                                    <span>
                                        <FontAwesomeIcon icon={faMapLocation}
                                                         style={{height: 20, color: '#222434'}}/>
                                        {/*<SimpleLabel text={'physical address'} capitalize bold fontSize={16}/>*/}

                                        &nbsp; ACK Gardens Annex , Bishop Road, 1st Ngong Ave,
                                    Nairobi – Kenya
                                    </span>
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow boxedColour>
                        <SectionColumn>
                                    <span>
                                        <FontAwesomeIcon icon={faClock}
                                                         style={{height: 20, color: '#222434'}}/>

                                        {/*<SimpleLabel text={'physical address'} capitalize bold fontSize={16}/>*/}

                                        &nbsp; Mon – Fri: 8:00 am – 5:00 pm
                                    </span>

                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
                <SectionColumn style={{borderLeft: '2px solid #545157', paddingLeft: 8}}>
                    <SectionRow>
                        <SectionColumn>
                            <SectionRow>
                                <SectionColumn>
                                    <SectionRow>
                                        <SectionColumn style={this.faqHeader}>
                                            Frequently Asked Questions
                                        </SectionColumn>
                                        {/*<SectionColumn style={this.faqHeader}*/}
                                        {/*               onClick={this.glossary}>*/}
                                        {/*    Glossary of terms*/}
                                        {/*</SectionColumn>*/}
                                        {/*<SectionColumn style={this.faqHeader}*/}
                                        {/*onClick={this.contactInfo}>*/}
                                        {/*    Contacts*/}
                                        {/*</SectionColumn>*/}
                                    </SectionRow>
                                </SectionColumn>
                            </SectionRow>
                            {this.state.currentRoute}
                            {this.state.dataItem1}
                            <SectionRow>
                                <SectionColumn>
                                    <SectionRow>
                                        <SectionColumn style={this.faqHeader}>
                                            Definition of terms
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Operator'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={'A registered company that promotes, brokers, franchises, ' +
                                                    'arranges or conducts any form of gambling activity.'}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'G.M.S'} fontSize={16} bold colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel text={'The Gaming and Management System. ' +
                                                'This is a system designed to digitize and subsequently automate all ' +
                                                'businesses processes of the board in an effort to enhance service ' +
                                                'delivery.'}
                                                             fontSize={14}
                                                             colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'O.T.P.'} fontSize={16} bold colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={'Stands for One Time Passcode. This is a numeric phrase' +
                                                    ' sent via asuitable means (in this case. SMS) required during performance' +
                                                    ' of the 2-Factor-Authentication (or 2 step login/authentication). For more' +
                                                    ' information, read about this under the FAQ section.'}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Representative'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={'A person duly appointed by a betting/gaming company' +
                                                    ' as per BCLB guidance with the express responsibility of conducting ' +
                                                    'business with the board on behalf of their respective company.'}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Employee'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={'A person who is paid to work for the company.'}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Odds Provider'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={'This is a company that provide numerical information on ' +
                                                    'probabilities of outcomes of games.'}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'License'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={<span>This is a document that grants gaming company permission, outlines
                                                        terms of engagement and fees payed for the document's acquisition, in order
                                                        to conduct business with punters , <b>valid for one year</b>.</span>}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Permit'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={<span>A document that grants permission.</span>}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Premise'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={'The building where primary administrative operations of a ' +
                                                    'company are conducted.'}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <span>
                                                <SimpleLabel text={'Shop'} fontSize={16} bold
                                                             colour={'#100b0b'}/>
                                                &nbsp;-&nbsp;
                                                <SimpleLabel
                                                    text={'A building (business premise) through which a company provides' +
                                                    ' punter engagement for money.'}
                                                    fontSize={14}
                                                    colour={'#20202c'}/>
                                            </span>
                                        </SectionColumn>
                                    </SectionRow>

                                </SectionColumn>
                            </SectionRow>

                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>
            <SectionRow>
                <SectionColumn>
                    <b>
                        <a href={'#'}
                           onClick={() => this.navigateTo('../representative/login')}>Back to
                            login
                        </a>
                    </b>
                </SectionColumn>
            </SectionRow>
        </Section>
    }
}