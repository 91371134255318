import {Section, SectionColumn, SectionRow, SectionTitle} from "../../../../rotm-custom-views/view-utils/SectionUtils";
// import React from "react";
import {connect} from "react-redux";
import SimpleLabel from "../../../../general-components/labels/SimpleLabel";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import EcitizenPaymentInitiator from "../../../../general-features/eCitizen-integrator/EcitizenPaymentInitiator";
import {PAYFOR} from "../../../../general-components/redux/allowed-actions";
import GMS from "../../../../parent-component/GMS";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import {arrayOfJSONsToJSON} from "../../../../../MiscUtils";
import React from "react";
import ListOfPayments from "../../../../rotm-custom-views/operator/operator-view-sections/ListOfPayments";

/**
 *
 * PremiseView component
 *
 *
 */
class PremiseView extends GMS {
    constructor(props) {
        super(props);
        // this.state = {randomData: null};
        // get the properties for the randomData and show the data
    }

    /**
     *
     *
     * Create the view for summary
     *
     */
    processSummaryView = () => {
        const deviceTypes = {
            'shop_and_premise_hardware_devices': 'hardware_devices',
            'shop_and_premise_software_devices': 'software_devices',
        };
        // go through the list of utilities/everything that has "on"
        // Loop through e and look for those that have values "on"
        let v = 0;
        let utilitiesList = [];
        let keys = Object.getOwnPropertyNames(this.state.randomData);
        do {
            if (this.state.randomData[keys[v]] === true)
                if (keys[v] !== 'is_franchise') // get rid of this since it is not required
                    utilitiesList.push(<span
                        style={{textTransform: 'capitalize'}}>{keys[v].replaceAll('_', ' ').replaceAll('have', '')}</span>);
            v += 1;
        } while (v < keys.length);
        this.setState({dataItem3: [...utilitiesList]});
        //
        // process devices
        //
        let k = [];
        if (keys.includes('shop_and_premise_hardware_devices')) {
            let p = 0;
            // console.log(this.props, this.state.randomData.devices[0].device_id)
            // console.log(this.props[deviceTypes[this.state.randomData.devices[0].device_id]])
            this.state.randomData['shop_and_premise_hardware_devices'].map((device, index) => {
                k.push(<SectionRow>
                    <SectionColumn style={{width: 40, fontWeight: 800, fontSize: 16, color: '#2837e1'}}>
                        {k.length + 1}.
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Type'}/>
                                <SimpleLabel
                                    text={'Hardware'}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Device'}/>
                                <SimpleLabel
                                    text={Object.getOwnPropertyNames(this.state.randomData['shop_and_premise_hardware_devices'][index]).includes('device_id') && arrayOfJSONsToJSON(this.props[deviceTypes[this.state.randomData['shop_and_premise_hardware_devices'][index].device_id]])[device.device]}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Serial number'}/>
                                <SimpleLabel text={device.serial_no}/>
                            </span>
                    </SectionColumn>
                </SectionRow>);
            });
        }
        if (keys.includes('shop_and_premise_software_devices')) {
            let p = 0;
            // let k = [];
            // console.log(this.props, this.state.randomData.devices[0].device_id)
            // console.log(this.props[deviceTypes[this.state.randomData.devices[0].device_id]])
            this.state.randomData['shop_and_premise_software_devices'].map((device, index) => {
                k.push(<SectionRow>
                    <SectionColumn style={{width: 40}}>
                        {k.length + 1}
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Type'}/>
                                <SimpleLabel
                                    text={'Software'}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Device'}/>
                                <SimpleLabel
                                    text={Object.getOwnPropertyNames(this.state.randomData['shop_and_premise_software_devices'][index]).includes('device_id') && arrayOfJSONsToJSON(this.props[deviceTypes[this.state.randomData['shop_and_premise_software_devices'][index].device_id]])[device.device]}/>
                            </span>
                    </SectionColumn>
                    <SectionColumn>
                            <span>
                                <SimpleLabel bold
                                             fontSiz={14}
                                             text={'Serial number'}/>
                                <SimpleLabel text={device.serial_no}/>
                            </span>
                    </SectionColumn>
                </SectionRow>);
            });
        }
        this.setState({dataItem1: [...k]});
    }
    /**
     *
     * Help disable button
     *
     */
    disableButton = () => {
        this.setState({variable: true});
    }

    componentDidMount = () => {
        this.sendRequest('get', 'account/user', user => {
            if (user.type === 'Success')
                this.setState({dataItem2: [...user.data]})
        });
        // get list of all licenses, then select the latest.
        this.sendRequest('get',
            `company/operator/license/license/${this.props.premise['shop_and_premise_license']}`,
            license => {
                if (license.type === 'Success') this.setState({dataItem4: license.data});
            }, {});
        // get list of employees
        this.sendRequest('get', 'company/operator/employees', employees => {
            let currentLicense = {};
            if (employees.type === 'Success') {
                this.setState({dataItem5: employees.data});
            }
        }, {});
        this.sendRequest('get',
            `finance/potential-payment/?context=PREM&context_id=${this.props.premise.id}`,
            payments => {
                let currentLicense = {};
                if (payments.type === 'Success') {
                    this.setState({dataItem6: payments.data});
                }
            }, {});
        this.setState({randomData: {...this.props.premise}}, this.processSummaryView);
    }

    render = () => {
        return <Section>
            {
                this.state.randomData && <SectionTitle>
                    {this.state.randomData.office_type} in {this.state.randomData.building} building, {this.state.randomData.county} county
                </SectionTitle>
            }
            {
                this.state.randomData !== undefined ?
                    <SectionRow>
                        <SectionColumn>
                            {
                                this.state.visible ? <SectionRow>
                                        <SectionColumn>
                                            <SectionRow>
                                                <SectionColumn/>
                                                <SectionColumn/>
                                                <SectionColumn>
                                                    <SubmitButton
                                                        style={{background: '#469821'}}
                                                        commandText={`Back`}
                                                        callback={() => {
                                                            this.setState({visible: !this.state.visible});
                                                        }}/>
                                                </SectionColumn>
                                            </SectionRow>
                                            {this.state.visible && this.state.dataItem2.length > 0 && <SectionRow>
                                                <SectionColumn>
                                                    <EcitizenPaymentInitiator
                                                        disableButton={this.disableButton}
                                                        service={'Premise'} //Bookmaking{New premise, Premise renewal, Premise relocation} or Public Gaming{Premise relocation}
                                                        userIdentity={{
                                                            clientMSISDN: this.state.dataItem2 && this.state.dataItem2[0].phone_number,
                                                            clientEmail: this.state.dataItem2 && this.state.dataItem2[0].email,
                                                            clientName: this.state.dataItem2 && `${this.state.dataItem2[0]['first_name']} ${this.state.dataItem2[0]['last_name']} ${this.state.dataItem2[0]['surname']}`,
                                                            clientIDNumber: this.state.dataItem2 && this.state.dataItem2[0]['user_identification_id']
                                                        }}/>
                                                </SectionColumn>
                                            </SectionRow>}
                                        </SectionColumn>
                                    </SectionRow> :
                                    this.state.randomData &&
                                    <SectionRow>
                                        <SectionColumn/>
                                        <SectionColumn/>
                                        <SectionColumn>
                                            {
                                                Object.getOwnPropertyNames(this.state.dataItem4).length > 0 &&
                                                <SubmitButton
                                                    commandText={`Initiate Premise Payment`}
                                                    callback={() => {
                                                        // console.log('data item 4 ' ,this.state.dataItem4)
                                                        Dispatch({
                                                            type: PAYFOR,
                                                            payload: {
                                                                name: this.state.dataItem4[0]['game_type'],
                                                                context: 'PREM'
                                                            }
                                                        }); // pay for book making or public gaming
                                                        this.setState({visible: !this.state.visible});
                                                        // get the operation for a particular
                                                        // get the license for this company
                                                        // console.log(this.state.dataItem4)

                                                    }}/>
                                            }
                                        </SectionColumn>
                                    </SectionRow>
                            }
                            <SectionRow>
                                <SectionColumn>
                                    {/*<SectionTitle>{this.state.randomData.office_type} setup</SectionTitle>*/}
                                    <SectionRow>
                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'Type of setup'} colour bold fontSize={16}/>
                                            <SimpleLabel text={this.state.randomData.office_type} colour={'#545157'}
                                                         fontSize={16}/>
                                        </span>
                                        </SectionColumn>
                                        {this.state.randomData.name !== '' && <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'Name'} colour bold fontSize={16}/>
                                            <SimpleLabel
                                                text={this.state.randomData.name}
                                                colour={'#545157'} fontSize={16}/>
                                        </span>
                                        </SectionColumn>}
                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'Franchise status'} colour bold fontSize={16}/>
                                            <SimpleLabel
                                                text={this.state.randomData.is_franchise ? 'Franchised' : 'Not franchised'}
                                                colour={'#545157'} fontSize={16}/>
                                        </span>
                                        </SectionColumn>
                                    </SectionRow>
                                    <SectionRow>
                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'Located in '} colour bold fontSize={16}/>
                                            <SimpleLabel text={<span>{this.state.randomData.county}&nbsp;county</span>}
                                                         colour={'#545157'} fontSize={16}/>
                                        </span>
                                        </SectionColumn>
                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'Host building'} colour bold fontSize={16}/>
                                            <SimpleLabel text={
                                                <span>{this.state.randomData.building}&nbsp;{this.state.randomData.floor &&
                                                <span>floor {this.state.randomData.floor} {this.state.randomData.room &&
                                                <span>, room {this.state.randomData.room}</span>}</span>}</span>}
                                                         colour={'#545157'} fontSize={16}/>
                                        </span>
                                        </SectionColumn>
                                        <SectionColumn>
                                            {
                                                this.state.randomData.lat && this.state.randomData.long &&
                                                <span>
                                               <SimpleLabel text={'Coordinates'} colour bold fontSize={16}/>
                                                <SimpleLabel
                                                    text={
                                                        <span>
                                                        {
                                                            this.state.randomData.lat
                                                        },
                                                            &nbsp;
                                                            {
                                                                this.state.randomData.long
                                                            }
                                                    </span>
                                                    }
                                                    colour={'#545157'}
                                                    fontSize={16}/>
                                            </span>
                                            }
                                        </SectionColumn>
                                    </SectionRow>
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow boxedColour>
                                <SectionColumn>
                                    <SectionTitle>Devices</SectionTitle>
                                    {/*<SectionRow>*/}
                                    {
                                        this.state.dataItem1
                                    }
                                    {/*</SectionRow>*/}
                                </SectionColumn>
                            </SectionRow>
                            <SectionRow boxedColour>
                                <SectionColumn>
                                    <SectionTitle>Utilities</SectionTitle>
                                    {/*<SectionRow>*/}
                                    {
                                        this.state.dataItem3
                                    }
                                    {/*</SectionRow>*/}
                                </SectionColumn>
                            </SectionRow>
                            {
                                this.state.dataItem5.length > 0 ?
                                    <SectionRow boxedColour>
                                        <SectionColumn>
                                            <SectionTitle>Employees</SectionTitle>
                                            {
                                                this.state.dataItem5.map((employee, idx) => {
                                                    return (<SectionRow>
                                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'Name'} bold capitalize fontSize={14}/>
                                            <SimpleLabel
                                                text={`${employee.e_first_name} ${employee.e_middle_name} ${employee.e_surname} `}
                                                capitalize fontSize={14}/>
                                        </span>
                                                        </SectionColumn>
                                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'National ID/Passport'} bold capitalize fontSize={14}/>
                                            <SimpleLabel text={employee.e_national_id} fontSize={14}/>
                                        </span>
                                                        </SectionColumn>
                                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'email'} bold capitalize fontSize={14}/>
                                            <SimpleLabel text={employee.e_email} fontSize={14}/>
                                        </span>
                                                        </SectionColumn>
                                                        <SectionColumn>
                                        <span>
                                            <SimpleLabel text={'is a contact person'} bold capitalize fontSize={14}/>
                                            <SimpleLabel
                                                text={employee.is_contact_person === undefined ? 'No' : employee.is_contact_person === true ? 'Yes' : 'No'}
                                                capitalize fontSize={14}/>
                                        </span>
                                                        </SectionColumn>

                                                    </SectionRow>)
                                                })}
                                        </SectionColumn>
                                    </SectionRow> :
                                    <span style={this.spanStyle}>No employees declared for this employee!</span>
                            }

                            {/*<SectionRow>*/}
                            {/*    <SectionColumn>*/}
                            {/*        <SectionTitle>reports</SectionTitle>*/}
                            {/*        <SectionRow>*/}
                            {/*            <SectionColumn>*/}

                            {/*            </SectionColumn>*/}
                            {/*        </SectionRow>*/}
                            {/*    </SectionColumn>*/}
                            {/*</SectionRow>*/}

                            {this.state.dataItem6.length > 0 && <SectionRow>
                                <SectionColumn>
                                    <ListOfPayments payments={this.state.dataItem6}/>
                                </SectionColumn>
                            </SectionRow>}
                        </SectionColumn>
                    </SectionRow> : 'Cannot view randomData'
            }
        </Section>
    }
}

const stateToProps = initialState => {
    if (initialState)
        return {premise: initialState.misc_id}; // the record of the randomData
}
export default connect(stateToProps)(PremiseView);

