import React from "react";
import BaseField from "./base/BaseField";

/**
 *
 * NumberField defines a type where the props
 *
 */
export default class RadioField extends BaseField {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.bordered = props.bordered ? {border: '1px solid #dddfe0', borderRadius: 4} : null;
    }

    render = () => {
        /*
         * onFocus allows the legend to be changed
         */
        return (<div className={`form-group border`} style={{padding: 3, maxWidth: 'fit-content', ...this.bordered}}>

            <input {...this.required}
                   ref={this.internalFieldReference}
                   style={{...this.props.style}}
                   name={this.name}
                // className={"form-check-input"}
                   type={"radio"}
                   checked={this.props.defaultValue && this.props.defaultValue}
                   onFocus={this.highlightOnFocus}
                   onChange={(e) => {
                       this.changeCallback(e);
                   }}
                // checked={this.state.previousValue}
                   onBlur={() => {
                       this.evaluateControlOnRequired()
                       this.blurCallback();
                   }}/>
            &ensp;
            <label
                className={`${this.state.selection} form-label`}
                style={{textAlign: "left", ...this.textStyling}}
            >
                {this.fieldPlaceHolder}
                {this.isRequired}
            </label>
        </div>);
    }
}