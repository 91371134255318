import {Component} from "react";
import {Section, SectionColumn, SectionRow} from "../../rotm-custom-views/view-utils/SectionUtils";

export default class ContactInfo extends Component {
    render = () => {
        return <Section>
            <SectionRow>
                <SectionColumn boxedColour>
                    <SectionRow>
                        <SectionColumn>
                            Phone
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            contact info
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
                <SectionColumn boxedColour>
                    <SectionRow>
                        <SectionColumn>
                            email
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            email addresses
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
                <SectionColumn boxedColour>
                    <SectionRow>
                        <SectionColumn>
                            physical address
                        </SectionColumn>
                    </SectionRow>
                    <SectionRow>
                        <SectionColumn>
                            address values
                        </SectionColumn>
                    </SectionRow>
                </SectionColumn>
            </SectionRow>

        </Section>
    }
}