import {Component} from "react";
import {col12, col2, col3, col5, col7, getDate, row} from '../../../MiscUtils';
import Tick from "../controls/svg-controls/Tick";
import DoubleTick from "../controls/svg-controls/DoubleTick";
import {AttachmentsThumbNailList} from "../document-viewer/ThumbNailList";

/**
 *
 * This class defines a row of messages styled as a char row.
 *
 */
export default class MessageRow extends Component {
    constructor(props) {
        super(props);
        /**
         *
         * Define what the row should do:
         *  1. mine|theirs - a property that tells the row which component side to show
         *                  when differentiating messages.
         *
         *  {
         *
         *      2. sent|seen|read - a property that tells the row how component renders this
         *                  message. to show double-tick or single tick and colours to show.
         *                  Just like whatsapp.
         *  }
         *
         *  3. delivered - a method reference for checking whether this message has been read.
         *                  if read property is present, this checkIfRead is ignored.
         *                  else, it will update the state of the message with regards to point 2.
         *
         *  4. message - the message string to show
         *
         *  5. attachments - shows the documents in a thumbnail list
         *
         *  6. attachmentscallback - the method to execute when a thumbnail is clicked for a given attachment
         *
         *  7. isDeleted - the message string being marked as deleted.
         *                  If the message is deleted, the message is replaced with a italicized and replaced
         *                  with 'message was deleted'.
         *                  If mine, replace with 'you deleted this message'
         *                      else replace with the default message as written above.
         *
         *
         */
        this.state = {
            messageStatus: props.sent, //by default, the message is sent
            whose: props.mine ? props.mine : props.theirs ? props.theirs : () => {
                console.warn(`the attribute, mine|theirs, has not been used as a property.`);
                //
                //console.log(`the attribute, mine|theirs, has not been used as a property. 
                //             The author of the message '${props.message}' is unknown at this time.`);
            }, //who owns this message
        }
    }

    /**
     *
     * Component updates if this message has been read if checkIfRead is absent
     * @param nextProps
     * @param nextContext
     * @constructor
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.checkIfRead) {
            // update state

        }
    }

    render = () => {
        return <>
            <div className={'space-row'}/>
            <div className={`${row} message-row`} style={this.props.style ? this.props.style : null}>
                {/*<div className={col1}/>*/}
                {
                    this.props.theirs ? <div className={col5}>
                            <div className={row}>
                                <div className={`${col12} message-container`}>
                                    {this.props.message}
                                </div>
                            </div>
                            {
                                this.props.attachments ?
                                    this.props.attachments.length > 0 ?
                                        <div className={row}>
                                            <div className={col12}>
                                                {
                                                    <AttachmentsThumbNailList
                                                        asAttachments
                                                        style={{width: '100%', height: 60}}
                                                        thumbnailcallback={this.props.attachmentscallback}
                                                        documents={this.props.attachments}/>
                                                }
                                            </div>
                                        </div> :
                                        null :
                                    null
                            }
                            <div className={`${row} message-time-read-bar`}
                                 style={this.props.style ? this.props.style : null}>
                                <div className={col3} style={{paddingTop: 8, color: '#456ab7'}}>
                                    {/*// date field access*/}
                                    {getDate(true)}
                                </div>
                                <div className={col7}/>
                                <div className={col2}>
                                    {
                                        this.props.delivered ?
                                            <DoubleTick style={{fontSize: '0.7em', height: 12}}
                                                        callback={() => {

                                                        }}/> :
                                            <Tick style={{fontSize: '0.7em', height: 12}}
                                                  callback={() => {

                                                  }}/>
                                    }
                                </div>
                            </div>
                            <div className={`${row} theirs-row-colour-code`}/>
                        </div>
                        : <div className={col5}/>
                }

                &emsp;
                &emsp;
                &emsp;
                &emsp;
                &emsp;
                {
                    this.props.mine ? <div className={col5} style={this.props.style ? this.props.style : null}>
                            <div className={row}>
                                <div className={`${col12} message-container`}>
                                    {this.props.message}
                                </div>
                            </div>
                            {
                                this.props.attachments ?
                                    this.props.attachments.length > 0 ?
                                        <div className={row}>
                                            <div className={col12}>
                                                <AttachmentsThumbNailList
                                                    asAttachments
                                                    thumbnailcallback={this.props.attachmentscallback}
                                                    documents={this.props.attachments}/>
                                            </div>
                                        </div> :
                                        null :
                                    null
                            }
                            <div className={`${row} message-time-read-bar`}
                                 style={this.props.style ? this.props.style : null}>
                                {/*<div className={col2}/>*/}
                                <div className={col3} style={{paddingTop: 8, color: '#456ab7'}}>
                                    {/*// date field access*/}
                                    {getDate(true)}
                                </div>
                                <div className={col7}/>
                                <div className={col2}>
                                    {
                                        this.props.delivered ?
                                            <DoubleTick style={{fontSize: '0.7em', height: 12}}
                                                        callback={() => {

                                                        }}/> :
                                            <Tick style={{fontSize: '0.7em', height: 12}}
                                                  callback={() => {

                                                  }}/>
                                    }
                                </div>
                            </div>
                            <div className={`${row} mine-row-colour-code`}/>
                        </div>
                        : <div className={col5}/>
                }
            </div>
        </>;
    }
}