import React from "react";
import GMS from "../../../../parent-component/GMS";
import {Section, SectionColumn, SectionRow, SectionTitle,} from "../../../../rotm-custom-views/view-utils/SectionUtils";
import {notify, readFromLocalStorage} from "../../../../../MiscUtils";
import {connect} from "react-redux";
import {BCLB, LOCAL_BACKUP, SUBTITLE} from "../../../../general-components/redux/allowed-actions";
import SubmitButton from "../../../../general-components/controls/buttons/SubmitButton";
import {Dispatch} from "../../../../general-components/redux/app-storage";
import SelectedLocation from "../../../maps/SelectedLocation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
//
const icon = <FontAwesomeIcon icon={faMapLocationDot} style={{height: 30, color: '#3f69d6'}}/>;

/**
 *
 * Declare premises using data captured from maps
 *
 */
class DeclarePremise extends GMS {
    constructor(props) {
        super(props);
        // declare mandatory fields
        // this.mandatoryFields = [
        //     "name",
        //     "location",
        //     "county",
        //     "area",
        //     "building_addr",
        //     "zip_code",
        //     "postal_code",
        //     "longitude_coordinate",
        //     "latitude_coordinate",
        //     "plot_number",
        // ];
        // this.getLocations(this.state.e['operator_shops']);
        this.county = readFromLocalStorage('county', BCLB);
        this.operator = readFromLocalStorage('operator', BCLB)[0];
    }

    /**
     *
     * load locations.
     *
     */
    componentDidMount = () => {
        // const premises = readFromLocalStorage(LOCAL_BACKUP, BCLB);
        // premises && this.getLocations(premises['gps_location']);
        Dispatch({type: SUBTITLE, payload: 'Declare/View selected premises (+ on map)'})
        //
        const premiseCard = [this.drawDeclaredPremises(1)];
        this.setState({dataItem3: [...premiseCard]});
        //
    }

    /**
     *
     * update premise data inside state.e.premise
     * @param premiseFieldReference the reference to the input field from which to extract
     *                                   the data belonging to a shareholder
     *
     * @param belongingTo Integer representing which shareholder is being edited.
     *                      this Integer is an indexer (but it is not array-based) for
     *                      the JSON that is currently being updated.
     *
     // * @param isCompany
     *
     * @param premiseFieldReference
     * @param belongingTo
     * @param data
     * @param toInteger
     */
    updatePremiseData = (premiseFieldReference, belongingTo = 0, data, toInteger = false) => {
        let premiseField = {};
        premiseField[premiseFieldReference.target.name] =
            premiseFieldReference.target.type === "checkbox"
                ? data ? data : premiseFieldReference.target.checked
                : toInteger ?
                    parseInt(premiseFieldReference.target.options[premiseFieldReference.target.selectedIndex].value) :
                    premiseFieldReference.target.value;
        if (Object.getOwnPropertyNames(this.state.e).includes('operator_shops')) {
            let previousPremise = this.state.e['operator_shops'];
            try {
                previousPremise[belongingTo][premiseFieldReference.target.name] = premiseFieldReference.target.type === "checkbox"
                && data !== undefined
                    ? data : premiseFieldReference.target.type === "checkbox" ? premiseFieldReference.target.checked
                        : toInteger ?
                            parseInt(premiseFieldReference.target.options[premiseFieldReference.target.selectedIndex].value) :
                            premiseFieldReference.target.value;
            } catch (newFieldItemFound) {
                previousPremise.push(premiseField)
            }
            this.setState(state => {
                state.e['operator_shops'] = [...previousPremise];
                return state;
            }, () => {
            })//console.log('operator previous premises ', this.state.e));
        } else this.setState(state => {
            state.e['operator_shops'] = [premiseField];
            return state;
        }, () => {
        })//console.log('new premise ', this.state.e));
    };

    /**
     *
     * Delete a given location based on its coordinates
     * @param locationId
     */

    removeLocation = (locationId) => {
        let y = this.state.dataItem3;
        let z = this.state.dataItem2;
        y.splice(locationId, 1); // react components that represent mapping data
        z.splice(locationId, 1); // actual mapping data
        // re-enable location capture button
        this.setState({dataItem3: [...y], dataItem2: [...z], disableLocationCapture: false},
            () => {
                this.setState(state => {
                    state.e['gps_location'] = [...this.state.dataItem2]
                    return state;
                }, () => {
                    // call the getLocationsMethod prop
                    if (this.props['getLocationsMethod'])
                        this.props['getLocationsMethod'](this.state.e['gps_location'])
                });
                // Dispatch({type: LOCAL_BACKUP, payload: {premises: this.state.dataItem2}});
            });
    }

    /**
     *
     *Construct views for locations, each location in its own viewer
     * @param locations
     * @returns {JSX.Element}
     */
    constructSelectedLocationsViews = (locations = []) => {
        let r = [];
        let k = 0;
        let c = '';
        let countyName = ''
        if (locations.length > 0) {
            do {
                // check county name can be split along a space and look for the last element.
                // If it has the name county in lower case, take the rest apart from the last two
                //console.log(locations[k].county, extractValueFromJSON(locations[k].county, arrayOfJSONsToJSON(this.county)))
                r.push(<SelectedLocation removeItem={this.removeLocation}
                                         locationIndex={k}
                                         chosenGameTypes={readFromLocalStorage((LOCAL_BACKUP, BCLB)['game_type'])}
                    // title={`${extractValueFromJSON(locations[k].county, arrayOfJSONsToJSON(this.county))}`}
                    //                      title={`${extractValueFromJSON(countyName, arrayOfJSONsToJSON(this.county))}`}
                    //                      title={`${extractValueFromJSON(locations[k].county, arrayOfJSONsToJSON(this.county, true))}`}
                                         title={locations[k].county}
                                         locationData={locations[k]}
                    // use locationIndex to justify editing
                                         updatePremiseData={this.updatePremiseData}
                                         updateLocationData={this.updateLocationData}
                />);
                k += 1;
            } while (k < locations.length);
        }
        // arrayOfJSONsToJSON(readFromLocalStorage('county', BCLB), true))
        this.setState({
            dataItem2: [...locations],
            // dataItem3: locations.length > 0 ? [...r] : <span>no locations selected</span>
            dataItem3: locations.length > 0 ? [...r] : <></>
        });
    }
    /**
     *
     * Get locations and include them in the premise UI then backup all data
     * @param locationData
     *
     */
    getLocations = (locationData = []) => {
        //console.log('location data ', locationData)
        this.constructSelectedLocationsViews(locationData);
        //     - kama ni hq
        this.setState(state => {
            let e = 0;

            state.e['operator_shops'] = locationData
            return state;
        }, () => {
            // check if there is a getLocationsMethod prop set
            if (locationData.length > 0) {
                if (this.props['getLocationsMethod'])
                    this.props['getLocationsMethod'](this.state.e['operator_shops']);
                else
                    Dispatch({
                        type: LOCAL_BACKUP,
                        payload: this.state.e
                    });
            }
        })
    }

    drawDeclaredPremises = (premiseNumber = 1) => {
        let premiseCards = [];
        let t = 0;
        do {
            premiseCards.push(<SelectedLocation removeItem={this.removeLocation}
                // locationIndex={k}
                // title={`${extractValueFromJSON(locations[k].county, arrayOfJSONsToJSON(readFromLocalStorage('county', BCLB)))}`}
                // locationData={locations[k]}
                                                updateLocationData={this.updateLocationData}
                                                updatePremiseData={this.updatePremiseData}
                // AdditionalLocationFieldSets={this.props.AdditionalLocationFieldSets}
            />);
            t += 1;
        } while (t < premiseNumber);
        return premiseCards;
    }
    render = () => {
        // #800080
        return (
            <Section>
                <SectionTitle>
                    {/*    <span*/}
                    {/*        style={{color: "#2c5ebc",}}>{this.state.dataItem3.length ?*/}
                    {/*        `${this.state.dataItem3.length} declared premises` :*/}
                    {/*        'Declare premises'}*/}
                    {/*</span>*/}
                </SectionTitle>
                {this.state.currentRoute}
                <div className={'flex-display-scrollable'}>
                    {
                        this.state.dataItem3
                    }
                </div>
                <SectionRow style={{borderTop: '2px solid #800080', background: '#FFFFFF'}}>
                    <SectionColumn/>

                    <SectionColumn>
                        {
                            this.props.disableDeclare ? null :
                                this.state.dataItem3.length > 0 ?
                                    <SubmitButton
                                        style={{
                                            border: "1px solid #2c5ebc",
                                            // color: "#2c5ebc",
                                            color: "#eeeeee",
                                            fontWeight: 700,
                                            // background: "#fff",
                                            background: "#3a3a44",
                                        }}
                                        // commandText={"Declare premises"}
                                        commandText={"Declare"}
                                        callback={() => {
                                            // this state e premises (this.state.e['gps_location'])
                                            this.sendRequest('post', `company/operator/outlets/?shop_operator=${this.operator.id}`, premiseCreationResponse => {
                                                //console.log("premise creation options => ", premiseCreationResponse);
                                                if (premiseCreationResponse.type === 'Fail')
                                                    notify('Premises not declared!', 2, false, 'Error declaring premises');
                                                else {
                                                    notify(`${this.state.e['operator_shops'].length} premise declared`, 4, true);
                                                    const x = {};
                                                    this.setState({e: {...x}}); // reset state in order to prevent backup storage of the same
                                                    // notify('Premises have been declared and applied for', 4, true);
                                                    this.navigateTo('../list-premises', false);
                                                }
                                                // });
                                            }, this.state.e['operator_shops']);
                                        }}
                                    /> : null
                        }
                    </SectionColumn>
                    <SectionColumn/>
                </SectionRow>
            </Section>
        );
    };
}

const mapStateToProps = (storage) => {
    return storage.LOCAL_BACKUP;
};

export default connect(mapStateToProps)(DeclarePremise);
